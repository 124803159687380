import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { MLText } from '../../components/common/MLInput';
import { MLPanelLoading } from '../../components/common/MLPanel';
import { MLFileSize } from '../../components/common/MLCommon';
import RMWidgetCount from '../widgets/RMWidgetCount';
import { useTranslation } from 'react-i18next';

const ProjectStatCard = ({ items }) => {
	const dispatch = useDispatch();
    const [t] = useTranslation(['translation']);
    const remainDay = 30;
    const remain = new Date().getTime() + (remainDay * 24 * 3600 * 1000);

	useEffect(() => {
	}, []);

  	return (<>
        {items && <div className='row'>
            <div className='col'>
                <RMWidgetCount title={t("전체 Case")} sub={t("누적")} value={items.length} iconName="folders" />
            </div>
            <div className='col'>
                <RMWidgetCount title={t("국가 정보")} sub={t("Case 사용 국가수")} value={[...new Set(items.map(x => x.countryCode))].length} iconName="world-pin" />
            </div>
            <div className='col'>
                <RMWidgetCount title={t("최근 7일 Case")} sub={t("최근 7일 생성된 Case")} value={items.filter(x => x.updated > (new Date().getTime() - (7 * 24 * 3600 * 1000))).length} iconName="calendar-week" />
            </div>
            <div className='col'>
                <RMWidgetCount title={t("Size")} sub={t("누적 Case 용량")} value={MLFileSize(items.map(x => x.size).reduce((sum, a) => sum + a, 0))} iconName="folder-down" />
            </div>
            <div className='col'>
                <RMWidgetCount title={t("당월 생성 Case")} sub={moment().format("MM") + t("월 생성된 케이스")} value={items.filter(x => moment(x.created).format("MMMMYYYY") === moment().format("MMMMYYYY")).length} iconName="calendar-month" />
            </div>
        </div>}
    </>
  	);
}

const mapState = (state) => {
	const region = state.AuthReducer.region;
	return { region };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, null)(ProjectStatCard);
