import axios from 'axios';
import { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { history } from '../../history';
import { InfoAction, ProductAction, WFAction } from '../../redux/actions';
import { GetCodeConfig, GetConfig } from '../../libs/appConfig';
import { BUTTONS, COLORS, INPUTS, SPAN } from '../../constants/FromInputs';
import { ProgramConstant } from '../../redux/reducers';
import { MLForm } from '../common/MLForm';
import { PRICEUNIT } from '../../constants/RMConstants';
import { FormAction } from '../../redux/actions/FormAction';
import { MLButton } from '../common/MLButton';
import { MLComboSingle, MLText } from '../common/MLInput';
import { RTAudit } from '../../libs/audit';
import { useTranslation } from 'react-i18next';
import { MLPanelNoData } from '../common/MLPanel';

const LabProductSpec = ({ categoryId }) => {
	const dispatch = useDispatch();
	const [t] = useTranslation(['translation']);
	const { _id } = useParams("");
	const [data, setData] = useState([]);
	const [prd, setPrd] = useState({});
	const [categoryInfo, setCategoryInfo] = useState({});
	const [codes, setCodes] = useState([]);
	const [readonly, setReadOnly] = useState(true);

	useEffect(() => {
		init();
	}, []);

	const init = async () => {
		const retPrd = await InfoAction.callDB({
			type: "scan", region: "ap-northeast-2", params: {
				TableName: "rayteams-product",
				FilterExpression: "#_id = :_id",
				ExpressionAttributeNames: { "#_id": "_id" },
				ExpressionAttributeValues: { ":_id": _id }
			}
		});
		if (retPrd.length > 0) {
			const _prd = retPrd[0];
			setPrd(_prd);
			setData(_prd.spec || []);
			if (_prd.category) {
				const ret = await InfoAction.callDB({
					type: "scan", region: "ap-northeast-2", params: {
						TableName: "rayteams-product",
						FilterExpression: "#_id = :_id",
						ExpressionAttributeNames: { "#_id": "_id" },
						ExpressionAttributeValues: { ":_id": _prd.category }
					}
				});
				setCategoryInfo(ret.length > 0 ? ret[0] : {});
			}
		}
	}

	const update = async () => {
		await InfoAction.callDB({
			type: "update", region: "ap-northeast-2", params: {
				TableName: "rayteams-product",
				UpdateExpression: 'SET #spec = :spec, #updated = :updated',
				ExpressionAttributeNames: {
					'#spec': 'spec',
					'#updated': 'updated'
				},
				ExpressionAttributeValues: {
					':spec': data,
					':updated': new Date().getTime()
				},
				Key: { _id: _id, sk: 'info' }
			}
		});
		RTAudit({ lvl: 5, msg: 'Update product spec' });
		setReadOnly(true);
	}

	const setSpecItem = (x, e) => {
		if (data.find(n => n.label === x)) {
			setData(data.map(n => n.label === x ? { ...n, value: e.target.value } : n))
		} else {
			setData([...data, { label: x, value: e.target.value }]);
		}
	}

	return <>
		<div className='d-flex justify-content-between align-items-center mb-2'>
			<div className='fw-bolder'>{t("Product spec")}</div>
			{readonly && <MLButton options={{
				code: "prod-specedit",
				label: t("Edit"),
				color: 'warning',
				size: 'sm',
				action: () => setReadOnly(false),
			}} />}
		</div>
		<div className='card'>
			{readonly && <>
				{categoryInfo?.specs?.length > 0 && <ul className='list-group'>
					{categoryInfo?.specs?.map((x, idx) => <li className='list-group-item' key={idx}>
						<div className='fw-bolder'>{x}</div>
						<div>{data.find(n => n.label === x)?.value || <span className='text-muted'><i>Undefined</i></span>}</div>
					</li>)}
				</ul>}
				{categoryInfo?.specs?.length === 0 && <MLPanelNoData />}
			</>}
			{!readonly && <div className='card-body'>
				{categoryInfo?.specs?.length > 0 && <ul className='list-group mb-2'>
					{categoryInfo?.specs?.map((x, idx) => <li className='list-group-item' key={idx}>
						{x}
						<MLText options={{
							value: data.find(n => n.label === x)?.value || ''
						}} handleChanged={(e) => setSpecItem(x, e)} />
					</li>)}
				</ul>}
				<div>
					<MLButton options={{
						label: t("Update"),
						color: 'primary',
						size: 'sm',
						action: () => update(),
					}} css='me-1' />
					<MLButton options={{
						label: t("Cancel"),
						color: 'light',
						size: 'sm',
						action: () => setReadOnly(true),
					}} />
				</div>
			</div>}
		</div>
	</>

}

const mapState = (state) => {
	return {};
};

const mapDispatch = (dispatch) => ({

});

export default connect(mapState, null)(LabProductSpec);
