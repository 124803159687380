import axios from 'axios';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { MLButton } from '../common/MLButton';
import MLTable from '../common/MLTable';
import { COLORS, SETTING } from '../../constants/FromInputs';
import { MLComboSingle, MLText } from '../common/MLInput';
import { LANGUAGE } from '../../constants/RMConstants';
import { MdSubdirectoryArrowRight } from "react-icons/md";
import { callDB } from '../../libs/db';
import { useTranslation } from 'react-i18next';
const RMBoardMenu = ({ user, board, contents }) => {

	const dispatch = useDispatch();
	const { _bid } = useParams();
	const [view, setView] = useState("");
	const [loading, setLoading] = useState(true);
	const [menus, setMenus] = useState([]);
	const [data, setData] = useState({});
	const [menu, setMenu] = useState({});
	const [langList, setLangList] = useState([]);
	const [langData, setLangData] = useState({});
	const { v4 } = require("uuid");
	const usersub = localStorage.getItem("usersub");
	const [t] = useTranslation(['translation']);

	useEffect(() => {
		getData();
	}, []);

	useEffect(() => {
		getLangList();
	}, [menu]);

	const getData = async () => {
		setLoading(true);
		try {
			const ret = await callDB({
				type : "scan",
				region : "ap-northeast-2",
				params : {
					TableName: "rayteams-manager-contents",
					FilterExpression: "#bid = :bid and begins_with(#sk, :sk)",
					ExpressionAttributeNames: { "#bid": "bid", "#sk": "sk" },
					ExpressionAttributeValues: { ":bid" : _bid, ":sk": "mn:" }
				},
				filter : {}
			});

			setMenus(ret.filter(x => !x.lang));
			setLangList(ret.filter(x => x.lang));
		} catch (err) {
			console.log(err);
		}
		setLoading(false);
	}

	const save = async () => {
		if (!data.title)
			return;
		const time = Date.now() / 1000 | 0;
		const _id = v4();
		const sk = data.sk === "" ? "mn:" + time : data.sk + ":" + time;
		delete data.sk;
		const ret = await callDB({
			type : "update",
			region : "ap-northeast-2",
			params : {
				TableName: "rayteams-manager-contents",
				Key: {
					_id: _id,
					sk: sk,
				},
				UpdateExpression:
					"SET #bid = :bid, #title = :title, #lang = :lang, #updated = :updated, #updator = :updator ",
				ExpressionAttributeNames: {
					"#bid": "bid",
					"#title": "title",
					"#lang" : "lang",
					"#updated": "updated",
					"#updator": "updator"
				},
				ExpressionAttributeValues: {
					":bid": _bid,
					":title": data.title,
					":lang" : "",
					":updated": new Date().getTime(),
					":updator": usersub
				},
			},
			filter : {}
		});

		getData();		
	}

	const removeMenu = async () => {
		const ret = await callDB({
			type : "delete",
			region : "ap-northeast-2",
			params : {
				TableName: "rayteams-manager-contents",
				Key: {
					_id: menu._id,
					sk: menu.sk,
				},
			},
			filter : {}
		});
		setView("");
		setMenu({});
		getData();
	}

	const removeLang = async (x) => {
		const ret = await callDB({
			type : "delete",
			region : "ap-northeast-2",
			params : {
				TableName: "rayteams-manager-contents",
				Key: {
					_id: x._id,
					sk: x.sk,
				},
			},
			filter : {}
		});
		getData();
	}

	const modifyData = async () => {
		const ret = await callDB({
			type : "update",
			region : "ap-northeast-2",
			params : {
				TableName: "rayteams-manager-contents",
				Key: {
					_id: menu._id,
					sk: menu.sk,
				},
				UpdateExpression:
					"SET #title = :title, #ico = :ico, #color = :color, #updated = :updated, #updator = :updator ",
				ExpressionAttributeNames: {
					"#title": "title",
					"#ico" : "ico",
					"#color": "color",
					"#updated": "updated",
					"#updator": "updator"
				},
				ExpressionAttributeValues: {
					":title": menu?.title || "",
					":ico" : menu?.ico || "",
					":color": menu?.color || "",
					":updated": new Date().getTime(),
					":updator": usersub
				},
			},
			filter : {}
		});

		getData();
	}

	const saveLang = async () => {
		if (!langData.title || !langData.lang)
			return;
		const ret = await callDB({
			type : "update",
			region : "ap-northeast-2",
			params : {
				TableName: "rayteams-manager-contents",
				Key: {
					_id: langData._id,
					sk: langData.sk,
				},
				UpdateExpression:
					"SET #bid = :bid, #title = :title, #content = :content, #ico = :ico, #lang = :lang, #updated = :updated, #updator = :updator ",
				ExpressionAttributeNames: {
					"#bid": "bid",
					"#title": "title",
					"#content": "content",
					"#ico" : "ico",
					"#lang": "lang",
					"#updated": "updated",
					"#updator": "updator"
				},
				ExpressionAttributeValues: {
					":bid": langData.bid,
					":title": langData?.title || "",
					":content": langData?.content || "",
					":ico" : langData?.ico || "",
					":lang": langData?.lang || "",
					":updated": new Date().getTime(),
					":updator": usersub
				},
			},
			filter : {}
		});

		getData();
	}
	
	const getParent = (x) => {
		console.log(x);
		if (x.sk.split(":").length == 2) {
			return "ROOT";
		} else {
			const psk = x.sk.split(":").slice(0, -1).join(':');
			console.log(psk);
			return menus.find(x => x.sk === psk);
		}
	}

	const tree = (a, b) => {
		const aParts = a.split(':');
		const bParts = b.split(':');
		for (let i = 0; i < Math.min(aParts.length, bParts.length); i++) {
			if (aParts[i] !== bParts[i]) {
				return aParts[i].localeCompare(bParts[i]);
			}
		}
		return aParts.length - bParts.length;
	}

	const depth = (sk) => {
		if (sk.split(":").length === 2)
			return <></>
		const ret = [];
		for (let i = 0; i < sk.split(":").length - 3; i++) {
			ret.push(<span className='ms-5'></span>)
		}
		ret.push(<MdSubdirectoryArrowRight className='me-2' />);
		return ret;
	}

	const getLangList = () => {
		const ret = langList.filter(m => m.sk.indexOf(menu.sk) > -1 && (m.sk.split(":").length === menu.sk.split(":").length + 1) && m.lang)
		return ret;
	}

	const canDelete = () => {
		const langItems = langList.filter(m => m.sk.indexOf(menu.sk) > -1 && (m.sk.split(":").length === menu.sk.split(":").length + 1) && m.lang)
		const submenus = menus.filter(m => m.sk.indexOf(menu.sk) > -1 && (m.sk.split(":").length >= menu.sk.split(":").length + 1))
		return langItems.length === 0 && submenus.length === 0;
	}

	return (
		<div className='row mt-4'>
			<div className='col-4'>
				<div className="card">
					<div className="card-header">
						<div className='d-flex justify-content-between align-items-center'>
							<div className='fw-bolder'>Menu</div>
							<div className='text-end d-flex align-items-center'>
								<MLButton options={{
									code: "cm-createmenu",
									label: t('Create Menu'),
									color: COLORS.PRIMARY,
									size: 'md',
									action: () => { setView("create"); setData({}) }
								}} />
							</div>
						</div>
					</div>
					{menus.length > 0 && <div className="list-group">
						{menus.map(x => x.sk).sort(tree).map((x, idx) => <div className='list-group-item cursor-pointer' key={idx}
							onClick={() => {
								setMenu(menus.find(m => m.sk === x));
								setLangData({ ...menus.find(m => m.sk === x) });
								setView("edit")
							}}>
							{depth(x)}{menus.find(m => m.sk === x).title}
						</div>)}
					</div>}
					{menus.length === 0 && <div className="card-body">메뉴를 생성해주세요.</div>}
				</div>
			</div>
			<div className='col-8'>
				{view == "edit" && <>
					<div className='card mb-2'>
						<div className='card-header'>
							<div className='d-flex justify-content-between align-items-center'>
								<div className='fw-bolder'>{menu.title}</div>
								<div className='text-end d-flex align-items-center'>
									{canDelete() && <MLButton options={{
										label: '삭제',
										color: COLORS.DANGER,
										size: 'md',
										action: () => { removeMenu(); }
									}} css="ms-1" />}
									<MLButton options={{
										label: '수정',
										color: COLORS.SUCCESS,
										size: 'md',
										action: () => { modifyData(); }
									}} css="ms-1" />
									<MLButton options={{
										label: '하위 메뉴 생성',
										color: COLORS.PRIMARY,
										size: 'md',
										action: () => { setData({ sk: menu.sk }); setView("create"); }
									}} css="ms-1" />
								</div>
							</div>
						</div>
						<div className='card-body'>
							<label>부모 메뉴</label>
							<div className='fw-bolder mb-4'>{getParent(menu).title || "ROOT"}</div>
							<MLText options={{
								label: "메뉴 Alias",
								value: menu.title,
							}} handleChanged={(e) => setMenu({ ...menu, title: e.target.value })} css="mb-2" />
							<MLText options={{
								label: "메뉴 대표 아이콘",
								value: menu.ico,
							}} handleChanged={(e) => setMenu({ ...menu, ico: e.target.value })} css="mb-2" />
							<MLText options={{
								label: "메뉴 대표 컬러",
								value: menu.color,
							}} handleChanged={(e) => setMenu({ ...menu, color: e.target.value })} css="mb-2" />
						</div>
					</div>
					<div className='card'>
						<div className='card-header'>
							<div className='card-title'>
								<span className='fw-bolder'>{menu.title}</span>
								<small className='ms-2'>사용자에게 노출되는 메뉴(카테고리) 이름</small>
							</div>
						</div>
						<div className='card-body row'>
							<div className='col'>
								<div className=''>
									<label className='mb-2 fw-bolder'>언어별 메뉴 이름</label>
								</div>
								{getLangList().length === 0 && <div>등록된 메뉴 이름 없음</div>}
								{getLangList().length > 0 && <div className='list-group'>
									{getLangList().map((x, idx) => <div key={idx} className="d-flex justify-content-between list-group-item align-items-center">
										<div>[{LANGUAGE.find(l => l.code === x.lang)?.label}] <strong>{x.title}</strong></div>
										<div>
											<MLButton options={{
												label: '삭제',
												color: COLORS.DANGER,
												size: 'sm',
												action: () => { removeLang(x); }
											}} css="py-1" />
										</div>
									</div>)}
								</div>}
							</div>
							<div className='col'>
								<MLComboSingle options={{
									list: LANGUAGE.map(l => ({ value: l.code, label: l.label })),
									value: langData.lang,
									label: "언어 선택"
								}} handleChanged={(e) => setLangData({ ...langData, sk: menu.sk + ":" + e.target.value, lang: e.target.value })} css="mb-2" />
								<MLText options={{
									label: "메뉴 이름",
									value: langData.title,
								}} handleChanged={(e) => setLangData({ ...langData, title: e.target.value })} css="mb-2" />
								<MLText options={{
									label: "메뉴 소개",
									value: langData.content,
								}} handleChanged={(e) => setLangData({ ...langData, content: e.target.value })} css="mb-2" />
								<MLButton options={{
									label: '언어 저장',
									color: COLORS.PRIMARY,
									size: 'md',
									action: () => saveLang()
								}} />
							</div>
						</div>
					</div>
				</>}
				{view == "create" && <div className='card'>
					<div className='card-header'>
						<div className='d-flex justify-content-between align-items-center'>
							<div className='fw-bolder'>메뉴 생성</div>
							<div className='d-flex align-items-center'>
								<MLButton options={{
									label: '취소',
									size: 'md',
									color: COLORS.DEFAULT,
									action: () => setView("")
								}} css="ms-1" />
								<MLButton options={{
									label: '저장',
									color: COLORS.PRIMARY,
									size: 'md',
									action: () => save()
								}} />
							</div>
						</div>
					</div>
					<div className='card-body'>
						<MLComboSingle options={{
							list: [{ value: "", label: "ROOT" }, ...menus.map(m => ({ value: m.sk, label: m.title }))],
							value: data.sk,
							label: "부모 메뉴"
						}} handleChanged={(e) => setData({ ...data, sk: e.target.value })} css="mb-2" />
						<MLText options={{
							label: "메뉴 Alias",
							value: data.title,
						}} handleChanged={(e) => setData({ ...data, title: e.target.value })} css="mb-2" />
						<MLText options={{
							label: "메뉴 대표 아이콘",
							value: data.ico,
						}} handleChanged={(e) => setData({ ...data, ico: e.target.value })} css="mb-2" />
						<MLText options={{
							label: "메뉴 대표 컬러",
							value: data.color,
						}} handleChanged={(e) => setData({ ...data, color: e.target.value })} css="mb-2" />
						<div>
						</div>
					</div>
				</div>}
			</div>
		</div>
	);
}

const mapState = (state) => {
	const user = state.AuthReducer.user;
	const region = state.AuthReducer.region;
	const board = state.BoardReducer.board;
	const contents = state.BoardReducer.contents;
	return { region, user, board, contents };
};

const mapDispatch = (dispatch) => ({
});

export default connect(mapState, mapDispatch)(RMBoardMenu);
