import axios from 'axios';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { MLText } from '../../components/common/MLInput';
import { MLPanelLoading } from '../../components/common/MLPanel';
import MLTable from '../../components/common/MLTable';
import { fetchData, putData } from '../../libs/db';
import { MLButton } from '../../components/common/MLButton';
import { RTButton } from '../../libs/buttons';
import { history } from '../../history';
import { COLORS } from '../../constants/FromInputs';
import { RMIconList, RMIconChart } from '../../components/icon/MLIcon';
import { MLPanelNoData } from '../../components/common/MLPanel';
import { ProgramConstant, PaymentConstant } from '../../redux/reducers';
import { Modal } from 'react-bootstrap';

const PaymentGatewayPage = ({ groups, region, regionusers, paymentgateways, page }) => {
	const dispatch = useDispatch();
	const [search, setSearch] = useState("");
	const [items, setItems] = useState([]);
	const [loading, setLoading] = useState(false);
	const [show, setShow] = useState(false);
	const [data, setData] = useState({});

	useEffect(() => {
		dispatch({
			type: ProgramConstant.SET_PAGE, page: {
				code: "paymentgateway",
				view: "summary",
				title: "Payment Gateway(PG)",
				small: "PG 관련 설정 및 관리",
				options: [
				]
			}
		})
	}, []);

	useEffect(() => {
		getData();
	}, []);

	useEffect(() => {
		genData();
	}, [search, paymentgateways])

	const headers = [
		{ link: "_id", linkpath: "/PaymentGateway", value: "title", label: "PG Name", css: "text-start", style: { width: 150 } },
		{ value: "desc", label: "Description" },
		{ value: "created", label: "Created", css: "text-end", type: "DATE" },
	]

	const getData = async () => {
		setLoading(true);
		const ScanFilter = {
		}
		try {
			const ret = await fetchData("rayteams-paymentgateway", ScanFilter);
			dispatch({ type: PaymentConstant.PG_GET_ALL, items: ret.Items });

			//setItems(list);
		} catch (e) {
			console.log(e);
		}
		setLoading(false);
	}

	const save = async () => {
		setLoading(true);
		const item = {
			_id: data.title.toLowerCase().replaceAll(" ", ""),
			sk: "info",
			title: data.title,
			created: new Date().getTime(),
		}

		const ret = await putData("rayteams-paymentgateway", item);
		dispatch({ type: PaymentConstant.CREATE_PG, item: item });
		setLoading(false);
		setData({});
		setShow(false);
	}

	const genData = async () => {
		setItems(search ? paymentgateways.filter(x => x.sk.includes("info") && x.name.indexOf(search) > -1 || x.title.indexOf(search) > -1 || x.title.indexOf(search) > -1) : (paymentgateways.filter(f => f.sk.includes("info")) || []));
	}
	return <>
		<div className='p-4'>
			<div className="card">
				<div className="card-header d-flex justify-content-between align-items-center">
					<div className="card-label fw-bold text-gray-800 fs-5">{items && items.length}</div>
					<div className="d-flex">
						<MLButton options={{
							code: "pg-addpg",
							color: COLORS.PRIMARY,
							size: "sm",
							action: () => setShow(true)
						}} css="me-2" />
						<MLText options={{
							placeholder: "검색어 입력",
							size: "sm",
							value: search,
						}} handleChanged={(e) => setSearch(e.target.value)} css="mb-0" />
					</div>
				</div>
				{items.length > 0 && <> {!loading && <MLTable headers={headers} items={items} noheader={true} />} </>}
				{items.length === 0 && <MLPanelNoData />}
				{loading && <MLPanelLoading />}
			</div>
		</div>
		<Modal show={show} size={"lg"} style={{ backgroundColor: "#42476c !important" }}>
			<Modal.Header className='modal-header header-bg'>
				<h4>Add Payment Gateway</h4>
			</Modal.Header>
			<Modal.Body className='flex-fill pt-0 formcard pt-2'>
				{<div>
					<div className='fs-6 my-2'>Payment Gateway Title</div>
					<div>
						<MLText options={{
							name: "title",
							value: data.title,
						}} handleChanged={(e) => setData({ ...data, title: e.target.value })} />
					</div>
				</div>}
				{loading && <MLPanelLoading />}
				<div className='mt-4 text-end'>
					<MLButton
						options={{ label: "Cancel", action: () => { setShow(false); setData({}); } }}
						css={'me-1'} />
					<RTButton options={{
						label: loading ? 'Please wait...' : 'Add Payment Gateway',
						ing: loading,
						needValid: true,
						action: () => save()
					}} valid={!loading && !!data.title} css="me-1" />
				</div>
			</Modal.Body>
		</Modal>
	</>
}

const mapState = (state) => {
	const groups = state.AuthReducer.groups;
	const region = state.AuthReducer.region;
	const regionusers = state.AuthReducer.regionusers;
	const paymentgateways = state.PaymentReducer.paymentgateways;
	const page = state.ProgramReducer.page;

	return { groups, region, regionusers, paymentgateways, page };
};

const mapDispatch = (dispatch) => ({

});

export default connect(mapState, null)(PaymentGatewayPage);
