import { useEffect, useState } from 'react';
import MLTable from '../common/MLTable';
import { countBy } from 'lodash';

const RMWidgetList = ({ data, title, target, options = {} }) => {
    const [widgetData, setWidgetData] = useState(null);
    useEffect(() => {
        if (data) {
            genData();
        }
    }, [data]);
    
    const genData = async () => {
        const countByList = countBy(data, target);
        const countGenList = [];
        Object.keys(countByList).map(x => {
            countGenList.push({ [target]: x, count: countByList[x] })
        });

        setWidgetData(countGenList);
    }

    const headers = [
        { value: [target], label: "Country", css: "text-start", type: "COUNTRY" },
        { value: "count", label: "Count", css: "text-start" },
    ]

    return <div className="card">
        <div className='card-header'>
            <h3 className='card-title'>
                <span className='card-label fs-5 fw-bolder'>{title}</span>
                <span></span>
            </h3>
            <div className='card-toolbar text-muted fs-6'>
                <span className='badge badge-secondary'>
                    {/* <RiProjectorLine style={{ width : 18, height : 18}} /> {projects.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} */}
                </span>
            </div>
        </div>
        <div className='pt-0 card-body'>
            {widgetData && <MLTable headers={headers} items={widgetData} noheader={true} notableheader={true} />}
        </div>
    </div>
}

export default RMWidgetList;