import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { COLORS } from '../../constants/FromInputs';
import { MLButton } from '../common/MLButton';
import MLCardSelector from '../common/MLCardSelector';
import MLWFAdd from './MLWFAdd';
import MLWFCombo from './MLWFCombo';
import MLWFEditor from './MLWFEditor';

const MLWFSelectOrCreate = ({ product, updated, workflows, callbackList }) => {

	const [list] = useState([
		{ type: "select", title: "Choose exist workflow", description: "" },
		{ type: "create", title: "Create new workflow", description: "" },
	]);
	const [type, setType] = useState({});
	const [workflow, setWorkflow] = useState({});
	const [selectedWf, setSelectedWf] = useState("");
	const [t] = useTranslation(['translation']);

	console.log("tt");

	useEffect(() => {
		setType(list.filter(x => x.type === "select")[0]);
	}, [list])

	useEffect(() => {
		const n = workflows.filter(x => x._id === selectedWf);
		setWorkflow(n.length > 0 ? n[0] : {});
	}, [selectedWf, workflows])

	return <div className='card'>
		<div className='card-header separate'>
			<div className='card-title'>
				{t('wfselectornew')}
			</div>
		</div>
		<div className='card-body'>
			<div className='row'>
				<div className='col-4 d-grid flex-grow-1'>
					<div>
						<MLCardSelector list={list} selected={type} onClick={(item) => setType(item)} />
						{type.type === "select" && <MLWFCombo options={{
							label: t('choosewf')
						}} handleChanged={(item) => setSelectedWf(item.target.value)} css='mt-3' />}
					</div>
				</div>
				<div className='col-8'>
					{(type.type === "select" && workflow._id) && <>
						<MLButton options={{
							label: t('usethisworkflow'),
							action: () => updated({ _id: product._id, workflowId: workflow._id }),
							color: COLORS.PRIMARY
						}} css='mb-3' />
						<MLWFEditor
							offsety={540}
							readonly={false}
							workflow={workflow}
							data={workflow} />
					</>}
					{type.type === "create" && <MLWFAdd
						callback={(newWf) => updated({ _id: product._id, workflowId: newWf._id })}
						callbackList={callbackList}
						title={t('information')} />}
				</div>
			</div>
		</div>
		<div className='card-footer'>
		</div>
	</div>
}

const mapState = (state) => {
	const workflows = state.WFReducer.items;
	return { workflows };
};

const mapDispatch = dispatch => ({
})

export default connect(mapState, mapDispatch)(MLWFSelectOrCreate);