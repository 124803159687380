import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { history } from '../../history';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { FaSave, FaEdit, FaWindowClose, FaCheckCircle, FaStopCircle } from 'react-icons/fa';
import { ProgramConstant } from '../../redux/reducers';
//import Clinic from '../components/clinic';
import Clinic from '../partner/clinic';
import { COLORS } from '../../config/color';
import { RTButton } from '../../libs/buttons';
import { fetchData } from '../../libs/db';
import RMGroup from '../manager/RMGroup';

const CompApplicationInfo = ({ user, title, application, applications, allgroups }) => {

	const dispatch = useDispatch();

	const [t] = useTranslation(['translation']);
	const [ items, setItems ] = useState([]);
	const [ edit, setEdit ] = useState(false);
	const [ data, setData ] = useState({});
	const [ process, setProcess ] = useState(false);

	useEffect(() => {
		if(application){
			setData({
				isactive : application.isactive,
				labs : application.labs,
				partnerId : application.partnerId
			});
		}
	}, [application]);

	return <div className='p-4'>
        <div className="pb-2">
            <label className="form-label fw-bolder">{t("Latest published version")}</label>
            <div>
                v{application.last_published_version}
            </div>
        </div>
        <div className="py-2">
            <label className="form-label fw-bolder">{t("Category")}</label>
            <div>
                {application.category}
            </div>
        </div>
        <div className="py-2">
            <label className="form-label fw-bolder">{t("Created")}</label>
            <div>
                {moment(application.created).format("LL")}
            </div>
        </div>
        <div className="py-2">
            <label className="form-label fw-bolder">{t("Updated")}</label>
            <div>
                {moment(application.updated).format("LL")}
            </div>
        </div>
        <div className="pt-2">
            <label className="form-label fw-bolder">{t("Description")}</label>
            <div>
                <div dangerouslySetInnerHTML={ {__html: application.desc} }></div>
            </div>
        </div>
    </div>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
    const applications = state.ApplicationReducer.applications;
	return { user, applications };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(CompApplicationInfo);
