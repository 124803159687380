import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import RMLicenseManagedAppList from '../components/license/RMLicenseManagedAppList';

const LicensePage = () => {
  	return (
        <>
            <RMLicenseManagedAppList />
        </>
  	);
}

const mapState = (state) => {
    const region = state.AuthReducer.region;
	const licenses =  state.LicenseReducer.licenses;
	return { region, licenses };
};

export default connect(mapState, null)(LicensePage);