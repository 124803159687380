import { useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import { MLPanelLoading } from '../common/MLPanel';
import { Line, Bar, getElementAtEvent } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { getRandomColors } from '../../libs/colors';
import { uniq, uniqBy } from 'lodash';
import { history } from '../../history';
import { UsageConstant } from '../../redux/reducers';

const RMWidgetLineChart = ({ data, type, title, target }) => {
	const dispatch = useDispatch();
	const [chartData, setChartData] = useState([]);
	const [loading, setLoading] = useState(true);
	const chartRef = useRef();

    useEffect(() => {
        data && generate(data, type, target);
    }, [data]);

	const generate = async (list, type) => {
        const target = "groupId";
		const clist = [];
		const barDataset = [];
		//const colorSet = getRandomColors(list.length, 0.8);
        
        list.reduce(function(res, value) {
            if (!res[value?.[target]]) {
                res[value?.[target]] = { week: value?.['created'] && moment(value?.['created']).format("YYYYMM") , name: value?.[target] === undefined ? "" : value?.[target], count: 0 };
                clist.push(res[value?.[target]])
            }
            res[value?.[target]].count += 1;
            return res;
        }, {});

        console.log(list)
        console.log(clist);
        const testlist = {};
        clist.map((x) => {
            
            if(typeof x.week != "undefined") {
                console.log(x.week)
                if(!testlist[x.week])
                    testlist[x.week] = { groupId : x.name, count : x.count}
            }
        })

        console.log(testlist);

        // var llist = {};
		// list.map(x => {
		// 	if(!llist[x.name]){
		// 		list[x.name] = { name : x.name, size : 0, count : 0 };
		// 	}
		// 	list[keytype].size += obj.size;
		// 	list[keytype].count++;
		// })

		// var arr = _.toArray(list);

        // barDataset.push(
        //     {
        //         data: clist.sort((a, b) => a.count > b.count ? -1 : 1).slice(0, 5).map((x) => x.count),
        //         barThickness: 28,
        //         backgroundColor: colorSet.map(x => x.opacityColor),
        //         borderColor: colorSet.map(x => x.color),
        //     }
        // )

		// setChartData({
        //     labels: clist.sort((a, b) => a.count > b.count ? -1 : 1).slice(0, 5).map((x) => x.name === "" ? type + " 없어요" : x.name ),
        //     datasets: barDataset
        // });
		setLoading(false);
	}

	const onClick = (event) => {
		const elem = getElementAtEvent(chartRef.current, event);
		var data = chartData.datasets[elem[0].datasetIndex];
		dispatch({ type : UsageConstant.GET_SELECTED_LOGS, items: chartData });
		history.push("/UsageInfoPage/" + data.label + "/" + type + "/" + period);
		console.log(data.label);
	}

  	return <>
		{!loading && <div className='card'>
			{chartData && <>
			<div className='card-header'>
				<div className='card-title'>{title}</div>
			</div>
			<div className='card-body'>
				<Bar 
					ref={chartRef}
					height={300}
					data={chartData}
					plugins={[ChartDataLabels]}
					options={{
						responsive: true,
						maintainAspectRatio: false,
						cutout: 0,
						animation: { animateScale: true },
						plugins: { 
							legend: { display : false }, 
							datalabels: {
								formatter: function (value) {
								  return value == 0 ? "" : value;
								},
								display: true,
								color: "gray",
								anchor: 'end',
								align: 'start',
								font: {
								  weight: 'bold',
								  size: 13,
								},
							},
						},
						scales: {
							y: { ticks: { color: '#666060', beginAtZero: true } },
							x: { ticks: { color: '#666060', beginAtZero: true } },
						},
					}}
					onClick={onClick}/>
			</div>
			</> }
		</div>}
		{loading && <MLPanelLoading />}
	</>
}

const mapState = (state) => {
    const logs = state.UsageReducer.logs;
	const dailylogs =  state.UsageReducer.dailylogs;
	const selectedlogs = state.UsageReducer.selectedlogs;
	const stats = state.UsageReducer.stats;
	
	return { logs, dailylogs, stats, selectedlogs };
};

const mapDispatch = (dispatch) => ({
});

export default connect(mapState)(RMWidgetLineChart);