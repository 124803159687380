import { ACTIONS } from "../../constants/FromInputs";
import { history } from "../../history";

const initialState = { 
	title : "Home",
	scope : "G",
	allclinics : [],
	widgetcache: [],
	allusers : [],
    allrayteams : [],
	alldevices : [],
	device: {},
	codes: [],
	config: {
		managertitle : 'RAYTeams Manager'
	},
	b2bconfig: {
		managertitle : 'RAYTeams Manager'
	},
	allpartner : [],
	filters : [],
	filterlist : [],
	page : {
		code : "dashboard"
	},
	pagestat : {
        'userlist' : {
            order : 'created',
            desc : 'desc',
			selCol: 'created',
        },
        'caselist' : {
            order : 'created',
            desc : 'desc',
			selCol: 'created',
        },
        'rayteamslist' : {
            order : 'updated',
            desc : 'desc',
			selCol: 'updated',
        },
        'devicelist' : {
            order : 'registered',
            desc : 'desc',
			selCol: 'registered',
        },
        'grouplist' : {
            order : 'created',
            desc : 'desc',
			selCol: 'created',
        },
		'licenselist' : {
			order : 'assigned',
			order2 : 'activated',
			desc : 'desc',
			desc2: 'desc',
			selCol: 'assigned',
		},
		'subscriptionlist' : {
			order : 'started',
			desc : 'desc',
			selCol: 'started',
		},
    },
};

export const ProgramConstant = {
	GO_LINK : "GO_LINK",
	WIDGET_CACHE_UPDATE: 'WIDGET_CACHE_UPDATE',
	GETCLINIC : "GETCLINIC",
	GETUSER : "PROGRAM_GETUSER",
	ALLRAYTEAMS : "PROGRAM_ALLRAYTEAMS",
	ALLDEVICE : "PROGRAM_ALLDEVICE",
	GETDEVICE : "GETDEVICE",
    PAGESTAT : "PROGRAM_PAGESTAT",
	GETPARTNER : "PROGRAM_GETPARTNER",
	SCOPE : "PROGRAM_SCOPE",
	SET_PAGE : "SET_PAGE",
	SET_SERVICE : "SET_SERVICE",
	SET_B2B_SERVICE : "SET_B2B_SERVICE",
	SET_CONFIG : "SET_CONFIG",
	SET_B2B_CONFIG : "SET_B2B_CONFIG",
	SET_FILTER : "SET_FILTER",
	SET_USER_FILTERLIST : "SET_USER_FILTERLIST",
};

const getTree = (data, pid = "") => {

	function toTree(data, pid = "") {
		return data.reduce((r, e) => {
			if (e.parentId == pid) {
				const obj = { ...e };
				const children = toTree(data, e._id);
				if (children.length) obj.children = children;
				r.push(obj);
			}
			return r;
		}, [])
	}

	const result = toTree(data, pid)
	return result;
}

export const ProgramReducer = (state = initialState, action) => {
	switch (action.type) {
		case ProgramConstant.SET_PAGE:
			return {...state, page : action.page };
		case ProgramConstant.SET_SERVICE:
			return {...state, config : action.item };
		case ProgramConstant.SET_B2B_SERVICE:
			return {...state, b2bconfig : action.item };
		case ProgramConstant.SET_CONFIG:
			return {...state, codes : action.items };
		case ProgramConstant.SET_B2B_CONFIG:
			return {...state, b2bcodes : action.items };
		case ProgramConstant.SCOPE:
			return {...state, scope : action.item };
		case ProgramConstant.GO_LINK:
			history.push(action.to);
			return {...state, title : action.title };
		case ProgramConstant.SET_USER_FILTERLIST:
			return {...state, filterlist: action.items}; //.map(x => ({...x, filter : JSON.parse(x.filter)})) };
		case ProgramConstant.SET_FILTER:
			const exist = state.filters.find(x => x.page === action.item.page);
			if(exist){
				return {...state, filters: state.filters.map(x => x.page === action.item.page ? action.item : x ) };
			}else{
				return {...state, filters: [...state.filters, action.item] };
			}
		case ProgramConstant.GETCLINIC:
			const existClinic = state.allclinics.find(x => x._id === action.clinic._id);
			if(existClinic){
				return {...state, allclinics: state.allclinics.map(x => x._id === action.clinic._id ? action.clinic : x) };
			}else{
				return {...state, allclinics: [...state.allclinics, action.clinic] };
			}
		case ProgramConstant.GETUSER:
			const existUser = state.allusers.find(x => x._id === action.user._id);
			if(existUser){
				return {...state, allusers: state.allusers.map(x => x._id === action.user._id ? {...x, ...action.user} : x) };
			}else{
				return {...state, allusers: [...state.allusers, action.user] };
			}
		case ProgramConstant.WIDGET_CACHE_UPDATE:
			const existWidget = state.widgetcache.find(x => x.key === action.item.key);
			return {...state,
				widgetcache: existWidget ?
					state.widgetcache.map(x => x.key === action.item.key ? action.item : x) :
					[...state.widgetcache, action.item]
			}
		case ProgramConstant.ALLRAYTEAMS:
			return {...state, allrayteams : action.items };
		case ProgramConstant.ALLDEVICE:
			return {...state, alldevices : action.items };
		case ProgramConstant.GETDEVICE:
			return {...state, device : action.item };
		case ProgramConstant.PAGESTAT:
			return {...state, pagestat : {...state.pagestat, ...action.item} };
		case ProgramConstant.GETPARTNER:
			const allp = action.items;
			//return {...state, allpartner : allp, partnertree : getTree(allp, action.my._id), mypartner : action.my };
			return {...state, allpartner : allp };
		default:
			return state;
	}
};
