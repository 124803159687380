import { connect, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BUTTONS, COLORS, INPUTS, SPAN } from '../../constants/FromInputs';
import { AlertConstant } from '../../redux/reducers';
import { MLForm } from '../../components/common/MLForm';
import { AuthConstant } from '../../redux/reducers/AuthReducer';
import { AWSAdminLoginPasswordUpdate } from '../../libs/aws';
import { Loader } from '../../components/common/Loader';
import { updateData, idData,queryData } from '../../libs/db';
import { RTAudit } from '../../libs/audit';
import { useTranslation } from 'react-i18next';

const RMPasswordUpdate = ({ adminUsers, user, loginuser, uData, myInfo = false }) => {
	const dispatch = useDispatch();
	const [t] = useTranslation(['translation']);
    const { _id } = useParams();
    const [data, setData] = useState({ password : "" });
	const [loading, setLoading] = useState(false);
	const [passConfirmErr, setPassConfirmErr] = useState('');

	const updatePassword = async () => {
		setLoading(true);
		if (adminUsers && !myInfo) {
            const adminUserInfo = adminUsers.filter(y => y.sub === _id)[0];
			await AWSAdminLoginPasswordUpdate(adminUserInfo.email, data.password);
			RTAudit({ lvl : 7, msg : '[' + uData.name + '] password updated by manager' });

			if (adminUserInfo.email === user?._id) {
				localStorage.clear();
				dispatch({ type : AuthConstant.LOGOUT });
			}
        }

		if (myInfo && uData) {
			await AWSAdminLoginPasswordUpdate(uData?.email, data.password);
			RTAudit({ lvl : 7, msg : '[' + uData.name + '] password updated' });
			console.log("1");
			dispatch({ type: AlertConstant.NOTICE, msg: t('Password 변경 완료'), show: true });
			console.log("1");
		}
		setLoading(false);
	}
    
    const controls = [
		{
			type : INPUTS.PASSWORD,
			options : {
				label : t('패스워드'),
				name : 'password',
				required : true,
				useToggle : true,
				isValidPassword: true
			}
		},
		{
			type : INPUTS.PASSWORD,
			options : {
				name: 'repassword',
				label: t('패스워드 확인'),
				err: passConfirmErr,
				required: true,
				useToggle: true,
				isMatch: true,
				isValidPassword: true,
				originPassword: data?.password,
			}
		},
		{
			type : BUTTONS.BUTTONS, 
			options : { },
			buttons : [{
				type : BUTTONS.NORMALBUTTON, 
				options: { 
					code: "ac-updatepw",
					label : t('암호변경'), 
					color : COLORS.PRIMARY,
					needValid : true,
                    action : () => updatePassword(),
				}
			}]
		}
	];
	
	return <div className=''>
		{loading && <Loader/>}
        {!loading && <MLForm controls={controls.filter(x => !x.perm)} data={data} onChanged={(e) => { console.log(e);setData(e);}} /> }
	</div>
}

const mapState = (state) => {
    const adminUsers = state.AuthReducer.adminUsers;
	const user = state.AuthReducer.user;
	return { adminUsers, user  };
};

const mapDispatch = dispatch => ({
})

export default connect(mapState, mapDispatch)(RMPasswordUpdate);
