import axios from 'axios';
import { useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import { MLPanelLoading } from '../../common/MLPanel';
import { JWT } from '../../../config';
import Chart from 'chart.js/auto';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { getRandomColors } from '../../../libs/colors';
import { uniq, uniqBy } from 'lodash';
import { history } from '../../../history';
import { UsageConstant } from '../../../redux/reducers';
import { ChartDays, ChartMonths } from '../../common/ChartDays';

const WidgetBarChartAppFuncUsage = ({ logs, period, type, title, appname, stats }) => {
	const dispatch = useDispatch();
	const [chartData, setChartData] = useState([]);
	const [loading, setLoading] = useState(true);
	const chartRef = useRef();

	useEffect(() => {
		if(stats && period) {
			generate(stats, type, period, appname);
		}
	}, [period, stats])

	const generate = async (list, type, period, appname) => {
		const clist = [];
		const barDataset = [];
		const colorSet = getRandomColors(list.length, 0.8);
		if(period === "Day" || period === "Week") {
			ChartDays(period == "Day" ? -1 : -2, "Weeks").map(x => {
				list.filter(y => moment(y.sk?.split(":")[1]).format("YYYY-MM-DD") == x && y?._id?.toLowerCase().includes(appname != "" ? appname?.toLowerCase() : type.toLowerCase())).map(z => {
					if(!clist.filter(y => y.name === z._id && y.label == moment(z.sk?.split(":")[1]).format("YYYY-MM-DD")).length == 0) {
						var objIndex = clist.findIndex((obj => obj.name == z._id && obj.label == moment(z.sk?.split(":")[1]).format("YYYY-MM-DD")));
						clist[objIndex].count += z.called;
					} else
						clist.push({ name : z._id, count: z.called, label: moment(z.sk?.split(":")[1]).format("YYYY-MM-DD") });
				});
			});

			ChartDays(period == "Day" ? -1 : -2, "Weeks").map((x, index) => {
				uniqBy(clist, "name").map((m, index) => {
					if(clist.filter(y => y.label == x && y.name == m.name).length == 0) {
						clist.push({ name : m.name, count: 0, label : x});
					}
				})
			})
		} else if(period === "Month") {
			ChartMonths(-6, "months").map(x => {
				list.filter(y => moment([y.sk?.split(":")[1].substring(0,4), parseInt(y.sk?.split(":")[1].substring(4) -1)]).format("YYYY-MM") == moment(x).format("YYYY-MM") && y?._id?.toLowerCase().includes(appname != "" ? appname?.toLowerCase() : type.toLowerCase())).map(z => {
					if(!clist.filter(y => y.name === z._id && y.label == moment([z.sk?.split(":")[1].substring(0,4), parseInt(z.sk?.split(":")[1].substring(4) -1)]).format("YYYY-MM")).length == 0) {
						var objIndex = clist.findIndex((obj => obj.name == z._id && obj.label == moment([z.sk?.split(":")[1].substring(0,4), parseInt(z.sk?.split(":")[1].substring(4) -1)]).format("YYYY-MM")));
						clist[objIndex].count += z.called;
					} else
						clist.push({ name : z._id, count: z.called, label: moment([z.sk?.split(":")[1].substring(0,4), parseInt(z.sk?.split(":")[1].substring(4) -1)]).format("YYYY-MM") });
				});
			});

			ChartMonths(-6, "months").map((x, index) => {
				uniqBy(clist, "name").map((m, index) => {
					if(clist.filter(y => y.label == moment(x).format("YYYY-MM") && y.name == m.name).length == 0) {
						clist.push({ name : m.name, count: 0, label : moment(x).format("YYYY-MM")});
					}
				})
			})
		} else 
			list.filter(x => x?.sk?.includes("M:") && x?._id?.toLowerCase().includes(appname != "" ? appname?.toLowerCase() : type.toLowerCase())).map(x => {
				clist.push({ name : x._id, count: x.called, label: x.sk?.split(":")[1] });
			});

		uniqBy(clist, "name").sort((a, b) => a.label > b.label ? 1 : -1).map((x, index) => {
			barDataset.push(
				{
					label: x.name,
					data: clist.filter(y => y.name === x.name).sort((a, b) => a.label > b.label ? 1 : -1).map((m) => m.count),
					barThickness: 28,
					backgroundColor: colorSet.map(x => x.opacityColor)[index],
					borderColor: colorSet.map(x => x.opacityColor)[index],
				}
			)
		})

		setChartData({
            labels: uniqBy(clist, "label").sort((a, b) => a.label > b.label ? 1 : -1).map(x => x.label),
            datasets: barDataset
        });
		setLoading(false);
	}

	const onClick = (event) => {
		const elem = getElementAtEvent(chartRef.current, event);
		var data = chartData.datasets[elem[0].datasetIndex];
		dispatch({ type : UsageConstant.GET_SELECTED_LOGS, items: chartData });
		history.push("/UsageInfoPage/" + data.label + "/" + type);
		console.log(data.label);
	}

  	return <>
		{!loading && <div className='card'>
			{chartData && <>
			<div className='card-header'>
				<div className='card-title'>{title} Usages</div>
			</div>
			<div className='card-body'>
				<Bar 
					ref={chartRef}
					height={300}
					data={chartData}
					plugins={[ChartDataLabels]}
					options={{
						responsive: true,
						maintainAspectRatio: false,
						cutout: 0,
						animation: { animateScale: true },
						plugins: { 
							legend: { display : true }, 
							datalabels: {
								formatter: function (value) {
								  return value == 0 ? "" : value;
								},
								display: true,
								color: "gray",
								anchor: 'end',
								align: 'start',
								font: {
								  weight: 'bold',
								  size: 13,
								},
							},
						},
						scales: {
							y: { ticks: { color: '#666060', beginAtZero: true } },
							x: { ticks: { color: '#666060', beginAtZero: true } },
						},
					}}
					onClick={onClick}/>
			</div>
			</> }
		</div>}
		{loading && <MLPanelLoading />}
	</>
}

const mapState = (state) => {
    const logs = state.UsageReducer.logs;
	const dailylogs =  state.UsageReducer.dailylogs;
	const selectedlogs = state.UsageReducer.selectedlogs;
	const stats = state.UsageReducer.stats;
	
	return { logs, dailylogs, stats, selectedlogs };
};

const mapDispatch = (dispatch) => ({
});

export default connect(mapState)(WidgetBarChartAppFuncUsage);