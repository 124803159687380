

class SyncObject {
    constructor(properties = {}) {
        this.id = properties.id;
        this.size = 0;
        this.lastModified = 0;
        this.excluded = false;
    }

    isIncluded() {
        return !this.excluded;
    }
}

export default SyncObject;