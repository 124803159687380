import axios from "axios";
import { connect, useDispatch } from 'react-redux';
import { useEffect, useState } from "react";
import { BUTTONS, COLORS, INPUTS, SPAN } from '../../constants/FromInputs';
import { MLButton } from '../../components/common/MLButton';
import { v4 } from 'uuid';
import { UploadS3 } from "../../libs/s3";
import TinyEditor from "../../components/common/TinyEditor";
import { MLEmail, MLText } from "../../components/common/MLInput";
import { MLForm } from "../../components/common/MLForm";
import MLTable from "../../components/common/MLTable";
import { ProgramConstant } from "../../redux/reducers";
import { InfoAction } from "../../redux/actions";
import { AWSGetUserById } from "../../libs/aws";
import { history } from "../../history";
import { AppConfig, JWT } from "../../config";
import { MLProgressBar } from "../../components/common/MLProgress";
import { RTAudit } from "../../libs/audit";
import { useTranslation } from 'react-i18next';
const VIEW_TYPE = {
    DEFAULT: 'default',
    LANGKEYS: 'lang_keys',
    TRANS: 'trans',
    TRANSUSER : 'transuser'
};

const getEnv = () => {
    if(location.href.indexOf("localhost") > -1 || location.href.indexOf("127.0.0.1") > -1){
        return "dev-local";
    }else if(location.href.indexOf("raydevelop") > -1){
        return "development";
    }
    return "production";
}

const MailingSendPage = ({ }) => {
    const dispatch = useDispatch();
    const [t] = useTranslation(['translation']);
    const [data, setData] = useState([]);
    const [sendingcnt, setSendingCnt] = useState({
        per : 0,
        cnt : 0,
        allcnt : 0,
    });
    const [rec, setRec] = useState([]);
    const [sending, setSending] = useState(false);
    const [newitem, setNewItem] = useState({
        key : "",
        value : "",
    });
    const env = (location.href.indexOf("localhost") > -1 || location.href.indexOf(".raydevelop.com") > -1) ? "development" : "production";
    const url = process.env.REACT_APP_COMPANY === 'graphy' ? 'https://api-manager-user.' + AppConfig().domain + '/sendemail' : ( env === 'production' ?
        'https://api-manager-user.rayteams.com/sendemail' : 
        'https://api-manager-user.raydevelop.com/sendemail' )

    useEffect(() => {
		dispatch({ type : ProgramConstant.SET_PAGE, page : { 
			code : "mailing", 
			view : "summary", 
			title : "Mailing", 
			small : t("사용자에게 Mailing 발송"), 
			options : [
			]
		}})
        getData();
        getReceivers();
    }, []);

    const getData = async () => {
        try{
            const ret = await InfoAction.callDB({ type : "scan", region : "ap-northeast-2", params : {
                TableName: "rayteams-info",
                FilterExpression: "#sk=:sk",
                ExpressionAttributeNames: { "#sk": "sk" },
                ExpressionAttributeValues: { ":sk": 'dm' },
            }});
            setData(ret.sort((a, b) => a.sent > b.sent ? -1 : 1) || []);
        }catch{
            setData([]);
        }
    }

    const getReceivers = async () => {
        try{
            const ret = await InfoAction.callDB({ type : "scan", region : "ap-northeast-2", params : {
                TableName: "rayteams-user",
                FilterExpression: "#marketingAgree=:marketingAgree",
                ExpressionAttributeNames: { "#marketingAgree": "marketingAgree" },
                ExpressionAttributeValues: { ":marketingAgree": true },
            }});
            setRec(ret);
            setSendingCnt({...sendingcnt, allcnt : ret.length});
        }catch(e){
            console.log(e);
            setRec([]);
        }
    }

    const sendemail = async () => {

        let received = 0;
        for(const r of rec){
            const u = await AWSGetUserById(r.region, r._id);
            const uEmail = u.UserAttributes.find(x => x.Name === 'custom:uEmail');
            const email = u.UserAttributes.find(x => x.Name === 'email');
            console.log(uEmail);
            console.log(email);
            if(uEmail){
                const sentRet = await axios.post(url, { subject : newitem.subject, content : newitem.content, toEmail : uEmail.Value }, JWT())
                received++;
            }else{
                const sentRet2 = await axios.post(url, { subject : newitem.subject, content : newitem.content, toEmail : email.Value }, JWT())
                received++;
            }
            setSendingCnt({...sendingcnt, cnt : received, per : (received * 100 / rec.length)});
        }
        await InfoAction.callDB({ type : "update", region : "ap-northeast-2", params : {
            TableName: "rayteams-info",
            UpdateExpression: 'SET #subject = :subject, #content = :content, #sent = :sent, #received = :received',
            ExpressionAttributeNames: { 
                '#subject' : 'subject', 
                '#received' : 'received', 
                '#content' : 'content', 
                '#sent' : 'sent', 
            },
            ExpressionAttributeValues: { 
                ':subject' : newitem.subject, 
                ':received' : received, 
                ':content' : newitem.content, 
                ':sent' : new Date().getTime(), 
            },
            Key: { _id : v4(), sk: 'dm' }
        }
        });
    }

    const sendtest = async () => {
        if(!newitem.subject || !newitem.content || !newitem.testemail){
            //setErr("모든 값을 입력해야합니다.");
            return;
        }
        await axios.post(url, { subject : newitem.subject, content : newitem.content, toEmail : newitem.testemail }, JWT())
        alert('Sent!');
    }

    const send = async () => {
        if(!newitem.subject || !newitem.content){
            setErr("모든 값을 입력해야합니다.");
            return;
        }
        setSendingCnt({...sendingcnt, cnt : 0, per : 0});
        setSending(true);
        await sendemail();
        RTAudit({ lvl : 8, msg : 'Sent email(DM), Recipients : ' + rec.length + '' }, JSON.stringify({ subject : newitem.subject }));
    }

    return <div className='p-4'>
        {sending && <>
            <MLProgressBar title={sendingcnt.allcnt === sendingcnt.cnt ? t("발송 완료") : t("보내는중")} val={sendingcnt.per} count={sendingcnt.cnt} />
            <MLButton options={{
                label : t("목록으로 돌아가기"),
                color : COLORS.PRIMARY,
                action : () => history.push('/MailingPage')
            }} />
        </>}
        {!sending && <>
            <div className="d-flex justify-content-between mb-2">
                <h3>
                </h3>
                <div className="card-toolbar">
                    <MLButton options={{
                        label : t("List"),
                        color : COLORS.PRIMARY,
                        action : () => history.push('/MailingPage')
                    }} />
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div>Send Email<small className="ms-2">{t("예상 수신 목록")} : {rec.length}</small></div>
                    <MLEmail
                        options={{
                            label : t('Test email'),
                            name : 'testemail',
                            placeholder : t('Enter test address'),
                            value : newitem.testemail,
                        }} handleChanged={(e) => setNewItem(prev => ({ ...prev, testemail : e.target.value }))} />
                    <MLText
                        options={{
                            label : t('Subject'),
                            name : 'subject',
                            value : newitem.subject,
                        }} handleChanged={(e) => setNewItem(prev => ({ ...prev, subject : e.target.value }))} />
                    <TinyEditor
                        originContent={""}
                        callbackOnChange={(content) => setNewItem(prev => ({ ...prev, content }))}
                    />
                    <div className="mt-2 text-end">
                        <MLButton options={{
                            label : t('Send'), 
                            color : COLORS.PRIMARY,
                            needValid : true,
                            action : () => send()
                        }}  />
                        <MLButton options={{
                            label : t('Send Test'), 
                            color : COLORS.SUCCESS,
                            needValid : true,
                            action : () => sendtest()
                        }} css="ms-1" />
                        <MLButton options={{
                            label : t('Cancel'), 
                            color : COLORS.SECONDARY,
                            action : () => history.push('/MailingPage')
                        }} css="ms-1" />
                    </div>
                </div>
            </div>
        </>}
    </div>
}

export default MailingSendPage;
