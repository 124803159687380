import axios from 'axios';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { history } from '../history';
import MLWFEditor from '../components/wf/MLWFEditor';
import { WFAction } from '../redux/actions';
import { ProgramConstant } from '../redux/reducers';
import { MLComboSingle, MLText } from '../components/common/MLInput';
import { GetCodeConfig, GetConfig } from '../libs/appConfig';
import { useTranslation } from 'react-i18next';
import { RTAudit } from '../libs/audit';
const WorkflowInfoPage = ({ user, region, product, workflows, Update }) => {
	const [t] = useTranslation(['translation']);
	const { _wid } = useParams();
	const [codes, setCodes] = useState([]);
	const [data, setData] = useState({
		title: '',
		flows: {}
	});
	const dispatch = useDispatch();

	useEffect(() => {
	}, []);

	useEffect(() => {
		dispatch({ type : ProgramConstant.SET_PAGE, page : { 
			code : "workflow", 
			view : "summary", 
			title : "Edit Workflow", 
			small : t("Workflow 편집"), 
			options : [ ]
		}})
		if(_wid)
			getData(_wid);
	}, [_wid])

	const getData = async () => {
		setCodes(await GetCodeConfig());
		setData(await WFAction.Get(_wid));
	}

	const updateFlow = async (e) => {
		Update({...data, flows : e.flows });
		history.push('/WorkflowPage');
		RTAudit({ lvl: 4, msg: 'Edit Workflow'}, JSON.stringify({ subject: data }));
	}

	return <>
		<div className='p-4'>
			<div className='post p-7'>
				<MLText options={{
					label: t('Title'),
					value: data.title,
					name: 'title'
				}} handleChanged={(e) => setData({ ...data, title: e.target.value })} />
				<MLComboSingle options={{
					label: t('상품 워크플로우'),
					name: 'type',
					value: data.wf,
					list: codes.filter(x => x.category === 'WF_TYPE'),
					required: true
				}} handleChanged={(e) => setData({ ...data, wf: e.target.value })} />
				{workflows && <MLWFEditor
					offsety={354}
					nocancel={true}
					data={data}
					save={(e) => updateFlow(e)}
				/>}
			</div>
		</div>
	</>
};

const mapState = (state) => {
	const user = state.AuthReducer.user;
	const region = state.AuthReducer.region;
	const products = state.ProductReducer.products;
	const workflows = state.WFReducer.items;
	return { region, user, products, workflows };
};

const mapDispatch = (dispatch) => ({
	Update: (data) => dispatch(WFAction.Update(data))
});

export default connect(mapState, mapDispatch)(WorkflowInfoPage);