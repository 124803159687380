import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { InfoAction, ProductAction, WFAction } from '../../redux/actions';
import { MLButton } from '../common/MLButton';
import { MLComboSingle, MLText } from '../common/MLInput';
import { RTAudit } from '../../libs/audit';
import { COLORS } from '../../config/color';

const LabProductCategoryProperty = ({ category }) => {
	const dispatch = useDispatch();

	const [t] = useTranslation(['translation']);
	const [newitem, setNewItem] = useState('');
	const [data, setData] = useState({});
	const [opts, setOpts] = useState([]);
	const [addFormShow, setAddFormShow] = useState(false);

	useEffect(() => {
		if (category?.options) {
			setOpts(category.options);
		}
	}, [category]);

	const update = async (options) => {
		await InfoAction.callDB({
			type: "update", region: "ap-northeast-2", params: {
				TableName: "rayteams-product",
				UpdateExpression: 'SET #options = :options, #updated = :updated',
				ExpressionAttributeNames: {
					'#options': 'options',
					'#updated': 'updated'
				},
				ExpressionAttributeValues: {
					':options': options,
					':updated': new Date().getTime()
				},
				Key: { _id: category._id, sk: 'category' }
			}
		});
		setOpts(options);
		RTAudit({ lvl: 5, msg: 'Add product category options' })
	}

	const addOptions = async () => {
		await update([...opts, data]);
		setData({});
		setAddFormShow(false);
	}

	return <>
		<div className='d-flex justify-content-between align-items-center mb-2'>
			<div className='fw-bolder'>{t("Product options")}</div>
			{!addFormShow && <MLButton options={{
				code: "prodcate-addopt",
				label: t("상품 옵션 추가"),
				color: 'primary',
				size: 'sm',
				action: () => setAddFormShow(true),
			}} />}
		</div>
		<div className='card'>
			{addFormShow && <div className='card-body'>
				<MLText options={{
					label: t('Option title'),
					value: data.title
				}} handleChanged={(e) => setData({ ...data, title: e.target.value })} />
				<div className='mb-2'>
					<h5>Items</h5>
					<MLText options={{
						label: t('Add item title'),
						value: newitem,
						enterSubmit: true
					}} enterSubmit={() => {
						console.log(newitem);
						setData({ ...data, items: [...(data.items || []), newitem] });
						setNewItem('');
					}} handleChanged={(e) => setNewItem(e.target.value)} />
					<div className='list-group mb-4'>
						{data.items && data.items.map((x, idx) => <div key={'opt' + idx} className='list-group-item d-flex justify-content-between'>
							<div>{x}</div>
							<div><MLButton options={{
								size: 'xs',
								color: COLORS.DANGER,
								label: t('delete'),
								action: () => setData({ ...data, items: data.items.filter(l => l !== x) })
							}} /></div>
						</div>)}
					</div>
				</div>
				<MLButton options={{
					label: t("저장"),
					color: 'primary',
					action: () => addOptions()
				}} />
				<MLButton options={{
					label: t("취소"),
					color: 'light',
					action: () => setAddFormShow(false)
				}} css='ms-1' />
			</div>}
			<ul className='list-group'>
				{opts?.map((x, idx) => <li className='list-group-item d-flex justify-content-between align-items-center' key={idx}>
					<div>
						<span className='fw-bolder'>{x.title}</span>
						<div>
							{x?.items && x.items?.map((i, idy) => <span key={idy} className='me-1'>{i}</span>)}
						</div>
					</div>
					<MLButton options={{
						code: "prodcate-delopt",
						label: t("삭제"),
						size: "sm",
						color: 'danger',
						action: () => update(opts.filter(f => f !== x))
					}} />
				</li>)}
			</ul>
		</div>
	</>

}

const mapState = (state) => {
	return {};
};

const mapDispatch = (dispatch) => ({

});

export default connect(mapState, null)(LabProductCategoryProperty);
