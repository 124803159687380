import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { MLText } from '../../components/common/MLInput';
import { MLPanelLoading } from '../../components/common/MLPanel';
import Select from 'react-select';
import MLTable from '../../components/common/MLTable';
import { AuthConstant, getAPI, getHeader } from '../../redux/reducers/AuthReducer';
import { fetchData } from '../../libs/db';
import WidgetBarChartAppUsage from '../../components/widgets/dashboard/WidgetBarChartAppUsage';
import RMWidgetBarChart from '../../components/widgets/RMWidgetBarChart';
import RMWidgetLineChart from '../../components/widgets/RMWidgetLineChart';
import { COUNTRIES } from '../../constants/Country';
import { MLButton, MLRefreshButton } from '../../components/common/MLButton';
import { history } from '../../history';
import { COLORS } from '../../constants/FromInputs';
import { RMIconList, RMIconChart } from '../../components/icon/MLIcon';
import { MLPanelNoData } from '../../components/common/MLPanel';

import { ProgramConstant, PaymentConstant } from '../../redux/reducers';
import RMWidgetCount from '../widgets/RMWidgetCount';
import { useTranslation } from 'react-i18next';

const UserStatCard = ({ items }) => {
	const dispatch = useDispatch();
    const [t] = useTranslation(['translation']);

    const day = 7;
    const daystamp = new Date().getTime() + (day * 24 * 3600 * 1000);

	useEffect(() => {
	}, []);

  	return (<>
        {items && <div className='row'>
            <div className='col'>
                <RMWidgetCount title={t("전체 사용자")} sub={t("누적")} value={items.filter(x => x.created).length} iconName="users" />
            </div>
            <div className='col'>
                <RMWidgetCount title={t("국가 정보")} sub={t("누적 가입 국가수")} value={[...new Set(items.map(x => x.created && x.countryCode))]?.length} iconName="world-pin" />
            </div>
            <div className='col'>
                <RMWidgetCount title={t("최근 7일 가입자")} sub={t("최근 ") + day + t("일 가입자")} value={items.filter(x => x.created > daystamp).length} iconName="calendar-week" />
            </div>
            <div className='col'>
                <div className="card">
                    <RMWidgetCount title={t("올해 가입자")} sub={moment().format("yyyy") + t("년 누적 가입 국가수")} value={items.filter(x => x.created && moment(x.created).format("YYYY") === moment().format("YYYY")).length} iconName="calendar-check" />
                </div>
            </div>
            <div className='col'>
                <div className="card">
                    <RMWidgetCount title={t("당월 가입자")} sub={moment().format("MM") + t("월 가입자 수")} value={items.filter(x => x.created && moment(x.created).format("MMMMYYYY") === moment().format("MMMMYYYY")).length} iconName="calendar-month" />
                </div>
            </div>
        </div>}
    </>
  	);
}

const mapState = (state) => {
	const region = state.AuthReducer.region;
	return { region };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, null)(UserStatCard);
