import { Modal } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BUTTONS, COLORS, INPUTS, SPAN } from '../constants/FromInputs';
import { MLButton } from '../components/common/MLButton';
import { MLForm } from '../components/common/MLForm';
import { AuthConstant, getAPI, getHeader, getAdminCreateHeader } from '../redux/reducers/AuthReducer';
import moment from 'moment';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { MLRadioButton, MLToggle } from '../components/common/MLInput';
import { RegionList } from '../libs/cogInfo';
import { AWSUserDelete, AWSAdminUserDelete, AWSLoginPasswordUpdate, AWSAdminLoginPasswordUpdate, AWSRMUserUpdate, AWSRMUserPwFlagUpdate } from '../libs/aws';
import { Loader } from '../components/common/Loader';
import { history } from '../history';
import MLModal from '../components/common/MLModal';

const AdminPwChangePage = ({group, adminUsers, user, regions, modalData, callbackClose }) => {
	const dispatch = useDispatch();
    const { _id } = useParams();
    const [data, setData] = useState({ name : "", email : "", password : "" });
	const [selectedRegion, setRegions] = useState([]);
	const [loading, setLoading] = useState(false);
    const [selectedType, setType] = useState(false);
	const [passConfirmErr, setPassConfirmErr] = useState('');
    
	useEffect(() => {
	}, [adminUsers]);

	const del = async () => {
		if (adminUsers) {
			setLoading(true);
            const adminUserInfo = adminUsers.filter(y => y.sub === _id)[0];
            adminUserInfo.region && adminUserInfo.region.split(",").map(async (x) => {
                await AWSUserDelete(x, adminUserInfo.email)
            });

			AWSAdminUserDelete(adminUserInfo.email);
			setLoading(false);
			history.push('/AdminUserPage');
        }
	}

	const updatePassword = async () => {
		if (user) {
			setLoading(true);
			await AWSAdminLoginPasswordUpdate(user._id, data.password);

            const ret = await AWSRMUserPwFlagUpdate(user._id);
            if(ret.$response.httpResponse.statusCode == 200) {
            	localStorage.clear();
				dispatch({ type : AuthConstant.LOGOUT });    
            }

			setLoading(false);
        }
	}

	const logout = () => {
		localStorage.clear();
		console.log('logout');
		dispatch({ type : AuthConstant.LOGOUT });
	}
    
    const controls = [
		{
			type : INPUTS.PASSWORD,
			options : {
				label : '패스워드',
				name : 'password',
				required : true,
				useToggle : true,
				isValidPassword: true
			}
		},
		{
			type : INPUTS.PASSWORD,
			options : {
				name: 'repassword',
				label: '패스워드 확인',
				err: passConfirmErr,
				required: true,
				useToggle: true,
				isMatch: true,
				originPassword: data?.password,
			}
		},
		{
			type : BUTTONS.BUTTONS, 
			options : { },
			buttons : [{
				type : BUTTONS.NORMALBUTTON, 
				options : { 
					label : '암호변경', 
					color : COLORS.PRIMARY,
					needValid : true,
                    action : () => updatePassword(),
				}
			},{
				type : BUTTONS.NORMALBUTTON, 
				options : { 
					label : '취소', 
					color : COLORS.SECONDARY,
                    action : () => logout(),
				}
			}]
		}
	];
	
	return <div className='d-flex justify-content-center'>
        {loading && <Loader />}
        {!loading && <>
            <div className='ray-content pt-10 w-700px'>
                <div className='card'>
                    <div className='card-body'>
                        <MLForm controls={controls} data={data} onChanged={(e) => { console.log(e);setData(e);}} />
                    </div>
                </div>
            </div>
        </>}
	</div>
}

const mapState = (state) => {
    const adminUsers = state.AuthReducer.adminUsers;
	const user = state.AuthReducer.user;
    const regions = state.AuthReducer.regions;
	return { adminUsers, user, regions };
};

const mapDispatch = dispatch => ({
})

export default connect(mapState)(AdminPwChangePage);
