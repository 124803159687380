import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { COUNTRIES } from '../../constants/Country';
import RMWidgetCount from '../widgets/RMWidgetCount';
import { useTranslation } from 'react-i18next';
const ProductStatCard = ({ items }) => {
	useEffect(() => {
    }, []);
    const [t] = useTranslation(['translation']);

  	return <div>
        {items && <div className='row'>
            <div className='col'>
                <RMWidgetCount title={t("전체 Product")} sub={t("누적")} value={items.filter(f => f.sk === "info").length} iconName="box" />
            </div>
            <div className='col'>
                <RMWidgetCount title={t("Country")} sub={t("누적")} value={[...new Set(items.filter(f => f.sk.includes("price")).map(x => x.sk.split(":")[2]))].filter(f => !!f && COUNTRIES.find(c => c.countryCode === f)).length} iconName="world-pin" />
            </div>
            <div className='col'>
                <RMWidgetCount title={t("Branch")} sub={t("누적")} value={[...new Set(items.filter(f => f.sk.includes("price")).map(x => x.sk.split(":")[1]))].filter(f => f != "ray").length} iconName="hierarchy" />
            </div>
            <div className='col'>
                <RMWidgetCount title={t("Group")} sub={t("누적")} value={[...new Set(items.filter(f => f.sk.includes("price")).map(x => x.sk.split(":")[3]))].filter(f => !!f).length} iconName="users-group" />
            </div>
            <div className='col'>
                <RMWidgetCount title={t("당월 구독")} sub={moment().format("MM") + t("월 구독")} value={items.filter(x => x.sk === "info" && moment(x.created).format("MMMMYYYY") === moment().format("MMMMYYYY")).length} iconName="calendar-month" />
            </div>
        </div>}
    </div>
}

const mapState = (state) => {
	const region = state.AuthReducer.region;
	return { region };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, null)(ProductStatCard);
