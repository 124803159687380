const initialState = { 
	product : {},
	products : [],
	productDetail : {},
	productDetails : [],
};

export const ProductConstant = {
	GET_ALL_PRODUCTS : "GET_ALL_PRODUCTS",
	GET_PRODUCT : "GET_PRODUCT",
	CREATE_PRODUCT_MAIN : "CREATE_PRODUCT_MAIN",
	CREATE_PRODUCT_DETAIL : "CREATE_PRODUCT_DETAIL",
	UPDATE_PRODUCT_DETAIL : "UPDATE_PRODUCT_DETAIL",
	GET_PRODUCT_DETAIL : "GET_PRODUCT_DETAIL"

};

export const ProductReducer = (state = initialState, action) => {
	switch (action.type) {
		case ProductConstant.GET_ALL_PRODUCTS:
			return {...state, products : action.items };
		case ProductConstant.GET_PRODUCT:
			return {...state, product : action.item };
		case ProductConstant.CREATE_PRODUCT_MAIN:
			return {...state, 
				product : action.item,
				products : [...state.products, action.item]
			};
		case ProductConstant.CREATE_PRODUCT_DETAIL:
			return {...state,
				product : action.item,
				products : [...state.products, action.item]
			}
		case ProductConstant.UPDATE_PRODUCT_DETAIL:
				return {...state,
					product : action.item,
					products :state.products.map(x => (x._id === action.item._id && x.sk === action.item.sk) ? action.item : x )
				}
		case ProductConstant.GET_PRODUCT_DETAIL:
			return {...state, 
				productDetail : action.item.filter(x => x.sk.includes("bid:"))[0],
				productDetails : action.item.filter(x => !x.sk.includes("bid:")),
			};
		default:
			return state;
	}
};
