import axios from 'axios';
import { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { connect, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { BiHelpCircle } from 'react-icons/bi';
import { fetchData, delData, updateData, insertData, idData } from '../../libs/db';
import { MLButton } from '../common/MLButton';
import { history } from '../../history';
import { BUTTONS, COLORS } from '../../constants/FromInputs';
import { MLPanelDelete } from '../common/MLPanel';
import { MLComboSingle, MLNumber, MLToggle } from '../common/MLInput';
import { COUNTRIES, getRegion } from '../../constants/Country';
import { LABPRODUCTPRICETYPE, LABPRODUCTUSETYPE, LAPPRODUCTINFOTYPE, LAPPRODUCTTYPE, PRICEUNIT, RMLICENSETYPE } from '../../constants/RMConstants';
import { MLText } from '../common/MLInput';
import MLWFEditor from '../wf/MLWFEditor';
import { ProductAction, WFAction } from '../../redux/actions';

const RMProductAdd = ({ workflows }) => {
	const dispatch = useDispatch();

	const { _id } = useParams("");
	const [help, setHelp] = useState(window.localStorage.getItem("helpmsg") === "N" ? false : true);
	const [data, setData] = useState({});
	const [process, setProcess] = useState(false);
	const [err, setErr] = useState("");
	const [delConfirm, setDelConfirm] = useState(false);

	useEffect(() => {
		init();
	}, []);

	useEffect(() => {
		window.localStorage.setItem("helpmsg", help ? "Y" : "N");
	}, [help])

	const init = async () => {
		dispatch(WFAction.GetAll());
		if (_id) {
			getProductData();
		}
	}

	const getProductData = async () => {
		try {
			const ret = await idData("rayteams-manager-product", _id);
			setData(ret.Items[0] || { flows: {} });
		} catch (error) {
			console.log(error);
		}
	}

	const CreateLabProduct = async () => {
		try {
			const pId = v4();
			dispatch(ProductAction.CreateProductDetail(pId, data));
		} catch (err) {
			console.log(err);
		}

		history.push("/ProductPage");
	}

	const UpdateLabProduct = async () => {
		if (_id) {
			try {
				const paramKey = {
					_id: _id,
					sk: data.sk
				}
				delete data._id;
				delete data.sk;
				dispatch(ProductAction.UpdateProductDetail(paramKey, data));
			} catch (err) {
				console.log(err);
			}
		}

		history.push("/ProductPage");
	}

	const UpdateF = async (e) => {
		setData({ ...data, flows: e.flows })
	}

	return <>
		{err && <div className="alert alert-dark shadow">{err}</div>}
		{delConfirm && <MLPanelDelete title={data.name + " 삭제하시겠습니까?"} contents={<>
			<div className='text-white'>삭제는 즉시 적용됩니다.</div>
		</>} buttons={[
			{
				type: BUTTONS.NORMALBUTTON,
				options: {
					label: "Cancel",
					color: COLORS.LIGHT,
					action: () => setDelConfirm(false),
				}
			},
			{
				type: BUTTONS.NORMALBUTTON,
				options: {
					label: "Remove permanently",
					color: COLORS.WARNING,
					action: () => delGroup(),
				}
			},
		]} />}
		{!delConfirm && <>
			<div className='p-4'>
				<div className="header align-items-stretch d-block" style={{ position: "initial" }}>
					<div className="d-flex w-100 justify-content-between align-items-center">
						<div className="d-flex align-items-center text-dark fw-bolder fs-3 mb-3 my-1">{_id ? "Edit Lab Product" : "Add Lab Product"}</div>
						<div className="d-flex align-items-center">
							<BiHelpCircle size="24" className="ms-2 text-primary cursor-pointer" onClick={() => setHelp(!help)} />
						</div>
					</div>
				</div>
				<div className='card-body pt-2'>
					<div className="card mb-2">
						<form>
							<div className="card-body row">
								<div className={"col-6"}>
									<div className="mb-4">
										<label className="form-label fs-4">Lab Product Information</label>
									</div>
									<div className="mb-4">
										<label className="form-label required fw-bolder">Use Type</label>
										{help && <div className="alert alert-dark mb-2">
											Use Type 은 Lab Product가 사용되는 형식 입니다.<br />
										</div>}
										{_id && <div className='fw-bolder fs-5 text-gray-500'>{data.sk}</div>}
										{!_id &&
											<MLComboSingle options={{
												value: data.sk,
												list: LABPRODUCTUSETYPE
											}} handleChanged={(e) => { setData({ ...data, sk: e.target.value }); }} css="w-100 me-2" />
										}
									</div>
									<div className="mb-4">
										<label className="form-label required fw-bolder">Lab Product Title</label>
										{help && <div className="alert alert-dark mb-2">
											Lab Product Title 은 Lab Product의 표시되는 명칭 입니다.<br />
										</div>}
										{_id && <div className='fw-bolder fs-5 text-gray-500'>{data.title}</div>}
										{!_id && <input
											type="text"
											className="form-control form-control-solid"
											placeholder="Enter Lab Product Title"
											value={data.title}
											onChange={(e) => setData({ ...data, title: e.target.value })} />}
									</div>
									<div className="mb-4">
										<label className="form-label required fw-bolder">Type</label>
										{help && <div className="alert alert-dark mb-2">
											Type 은 Lab Product의 상품 유형 입니다.<br />
											현재 orthodontics, prosthesis 의 Type 이 있습니다.<br />
										</div>}
										{_id && <div className='fw-bolder fs-5 text-gray-500'>{data.type}</div>}
										{!_id &&
											<MLComboSingle options={{
												value: data.type,
												list: LAPPRODUCTTYPE
											}} handleChanged={(e) => { setData({ ...data, type: e.target.value }); }} css="w-100 me-2" />
										}
									</div>
									<div className="mb-4">
										<label className="form-label required fw-bolder">Price Type</label>
										{help && <div className="alert alert-dark mb-2">
											Price Type 은 Lab Product의 가격 유형 입니다.<br />
										</div>}
										{_id && <div className='fw-bolder fs-5 text-gray-500'>{data.priceType}</div>}
										{!_id &&
											<MLComboSingle options={{
												value: data.priceType,
												list: LABPRODUCTPRICETYPE
											}} handleChanged={(e) => { setData({ ...data, priceType: e.target.value }); }} css="w-100 me-2" />
										}
									</div>
									<div className="mb-4">
										<label className="form-label required fw-bolder">Price</label>
										{help && <div className="alert alert-dark mb-2">
											Price Type 은 Lab Product의 가격 유형 입니다.<br />
										</div>}
										{
											<div className='d-flex justify-content-between'>
												<MLComboSingle options={{
													value: data.priceUnit || "USD",
													list: PRICEUNIT
												}} handleChanged={(e) => { setData({ ...data, priceUnit: e.target.value }); }} css="w-100 me-2" />
												<MLNumber options={{
													value: data.price || 0,
												}} handleChanged={(e) => { setData({ ...data, price: e.target.value }); }} css="w-100" />
											</div>
										}
									</div>
									<div className="mb-4">
										<div className='d-flex justify-content-between'>
											<div>
												<label className="form-label required fw-bolder">Delivery</label>
												{help && <div className="alert alert-dark mb-2">
													Delivery 는 배송 여부를 결정합니다.<br />
												</div>}
												{
													<MLToggle options={{
														value: data.delivery || false
													}} handleChanged={(e) => { setData({ ...data, delivery: e.target.value }); }} />

												}
											</div>
											<div>
												<label className="form-label required fw-bolder">Is Publish</label>
												{help && <div className="alert alert-dark mb-2">
													Is Publish 는 사용여부를 결정 합니다.<br />
												</div>}
												{
													<MLToggle options={{
														value: data.isPublish || false
													}} handleChanged={(e) => { setData({ ...data, isPublish: e.target.value }); }} />
												}
											</div>
											<div></div>
										</div>
									</div>

									<div className="mb-4">
										<label className="form-label fw-bolder">Workflow</label>
										<div className="card-body p-0">
											<div>
												<div>
													<div className='d-flex justify-content-between'>
														<MLComboSingle options={{
															value: data.workflowId,
															list: workflows ? workflows.map(x => ({ value: x._id, label: x.title })) : []
														}} handleChanged={(e) => {
															setData({ ...data, workflowId: e.target.value, flows: workflows.find(f => f._id === e.target.value).flows });
														}} css="w-100" />
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-6">
									<div className="mb-4">
										<label className="form-label fs-4">Workflow Information</label>
									</div>
									<div className='post'>
										<label className="form-label fw-bolder">Workflow Title</label>
										<MLText options={{
											value: data.workflowtitle,
											name: 'title'
										}} handleChanged={(e) => setData({ ...data, workflowtitle: e.target.value })} />
										<MLWFEditor
											copyandassign={false}
											offsety={520}
											readonly={false}
											nocancel={true}
											data={data}
											save={(e) => UpdateF(e)} />
									</div>
								</div>
							</div>
						</form>
						<div className="card-header">
							{/* <div className='card-title'>
							{_id ? "Edit " + data.title + " Lab Product information" : "Add Lab Product"}
							<small className='ms-2 text-gray-500'></small>
						</div> */}
							<div className="card-toolbar text-end">
								<MLButton options={{
									label: "Cancel",
									color: COLORS.DEFAULT,
									action: () => history.push("/ProductPage"),
								}} css="me-2" />
								<MLButton options={{
									label: _id ? "Update" : "Create",
									color: COLORS.PRIMARY,
									action: () => _id ? UpdateLabProduct() : CreateLabProduct(),
									needValid: true
								}} valid={_id ?
									(!!(data.title && data.type && data.priceType) && !process) :
									(!!(data.title && data.type && data.priceType) && !process)} />
							</div>
						</div>
					</div>
				</div>
			</div></>}

	</>
}

const mapState = (state) => {
	const workflows = state.WFReducer.items;
	return { workflows };
};

const mapDispatch = (dispatch) => ({

});

export default connect(mapState, null)(RMProductAdd);
