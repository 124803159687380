import axios from 'axios';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { queryData, updateData } from '../../libs/db';
import { MLButton } from '../common/MLButton';
import { MLText, MLToggle } from '../common/MLInput';
import { MLPanelLoading } from '../common/MLPanel';
import { GROUP_CFG } from '../../config/config';
import MLTable from '../common/MLTable';
import { AuthConstant, getAPI, getHeader } from '../../redux/reducers/AuthReducer';
import { fetchData } from '../../libs/db';
import MLSelectorTextArray from '../common/MLSelectorTextArray';
import { RTAudit } from '../../libs/audit';

const RMGroupConfig = ({ user, region, group, rayteams, devices, section }) => {

	const [t] = useTranslation(['translation']);
	const dispatch = useDispatch();
	const [search, setSearch] = useState("");
	const [items, setItems] = useState([]);
	const [data, setData] = useState([]);
	const [category, setCatetory] = useState("");
	const filter = [ ...new Set(GROUP_CFG.map(x => x.category))];
	const [loading, setLoading] = useState(false);

	useEffect(() => {
        if(group?._id)
            getData();
	}, [group]);

	const getData = async () => {
		setLoading(true);
		try{
			const params = {
				_id: {
					ComparisonOperator: "EQ",
					AttributeValueList: [group?._id],
				},
				sk: {
					ComparisonOperator: "BEGINS_WITH",
					AttributeValueList: ["cfg:"],
				},
			}
			const ret = await queryData("rayteams-group", params);
            setData(ret.Items);
		}catch(e){
            console.log(e);
		}
		setLoading(false);
    }

    const updateVal = async(e) => {
        try{
            console.log(e);
            if(data.find(x => x.sk === "cfg:" + e.target.name)){
                setData(data.map(x => x.sk === "cfg:" + e.target.name ? {...x, val : e.target.value} : x));
            }else{
                setData([...data, {
                    _id : group?._id,
                    sk : "cfg:" + e.target.name,
                    val : e.target.value
                }]);
            }
		}catch(e){
            console.log(e);
		}
    }

    const updateCfg = async() => {
        try{
            console.log(data);
            for(const d of data){
                const ret = await updateData("rayteams-group", { 
                    _id : group?._id,
                    sk : d.sk
                },{
                    val : d.val || ""
                });
            }
            RTAudit({
                lvl: 7,
                msg: 'Group config modified'
            }, JSON.stringify(data))
		}catch(e){
            console.log(e);
		}
    }
	
  	return (<>
		<div className="row p-4">
            <div className="col-3">
                <ul className="list-group">
                    <li onClick={() => setCatetory("")} 
                            className={"list-group-item cursor-pointer " + (category === "" ? " active" : "")}>{t('All')}</li>
                {filter.map(x => <li 
                    key={x}
                    onClick={() => setCatetory(x)} 
                    className={"list-group-item cursor-pointer " + (category === x ? " active": "")}>{t(x)}</li>)}
                </ul>
            </div>
            <div className="col-9">
                {(category === "" ? GROUP_CFG : GROUP_CFG.filter(x => x.category === category)).map(x => <div className="pb-4" key={x.code}>
                    <div className="d-flex justify-content-between">
                        <h5 className="card-title">{t(x.label)} </h5>
                        <div><i>code</i> : <b>{x.code}</b>, <i>default</i> : <b>{x.dval.toString()}</b></div>
                    </div>
                    <div>{t(x.description)}</div>
                    <div className="py-2">
                        {x.type === "BOOL" && <MLToggle handleChanged={(e) => updateVal(e)} options={{
                            value: (data?.find(d => d.sk === "cfg:" + x.code) ? data?.find(d => d.sk === "cfg:" + x.code).val : x.dval),
                            name : x.code
                        }}></MLToggle>}
                        {x.type === "TEXT" && <MLText handleChanged={(e) => updateVal(e)} options={{
                            value: data?.find(d => d.sk === "cfg:" + x.code)?.val || x.dval,
                            name : x.code
                        }}></MLText>}
                        {x.type === "TEXT_ARRAY" && <MLSelectorTextArray 
                            items={x?.items || []}
                            name={x.code}
                            closeMenuOnSelect={false}
                            value={data?.find(d => d.sk === "cfg:" + x.code)?.val || x.dval}
                            callbackOnChange={v => updateVal({ target: { name: x.code, value: v } })}
                        />}
                        {x.type === "TEXT_ARRAY_SINGLE" && <MLSelectorTextArray 
                            items={x?.items || []}
                            name={x.code}
                            isMulti={false}
                            closeMenuOnSelect={false}
                            value={data?.find(d => d.sk === "cfg:" + x.code)?.val || x.dval}
                            callbackOnChange={v => updateVal({ target: { name: x.code, value: v } })}
                        />}
                    </div>
                    <hr />
                </div>)}
                <MLButton options={{
                    label :  t("Apply"),
                    color : 'primary',
                    action : () => updateCfg()
                }}/>
            </div>
		</div>
    	</>
  	);
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const region =  state.AuthReducer.region;
	const rayteams =  state.AuthReducer.rayteams;
    const devices = state.AuthReducer.devices;
	return { region, user, rayteams, devices };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, null)(RMGroupConfig);
