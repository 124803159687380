const initialState = { 
	RTproduct : {},
	RTproducts : [],
	RTproductDetail : {},
	RTproductDetails : [],
};

export const RTProductConstant = {
	GET_ALL_RTPRODUCTS : "GET_ALL_RTPRODUCTS",
	GET_RTPRODUCT : "GET_RTPRODUCT",
	CREATE_RTPRODUCT_MAIN : "CREATE_RTPRODUCT_MAIN",
	CREATE_RTPRODUCT_DETAIL : "CREATE_RTPRODUCT_DETAIL",
	UPDATE_RTPRODUCT_DETAIL : "UPDATE_RTPRODUCT_DETAIL",
	GET_RTPRODUCT_DETAIL : "GET_RTPRODUCT_DETAIL"

};

export const RTProductReducer = (state = initialState, action) => {
	switch (action.type) {
		case RTProductConstant.GET_ALL_RTPRODUCTS:
			return {...state, RTproducts : action.items };
		case RTProductConstant.GET_RTPRODUCT:
			return {...state, RTproduct : action.item };
		case RTProductConstant.CREATE_RTPRODUCT_MAIN:
			return {...state, 
				RTproduct : action.item,
				RTproducts : [...state.RTproducts, action.item]
			};
		case RTProductConstant.CREATE_RTPRODUCT_DETAIL:
			return {...state,
				RTproduct : action.item,
				RTproducts : [...state.RTproducts, action.item]
			}
		case RTProductConstant.UPDATE_RTPRODUCT_DETAIL:
				return {...state,
					RTproduct : action.item,
					RTproducts :state.RTproducts.map(x => (x._id === action.item._id && x.sk === action.item.sk) ? action.item : x )
				}
		case RTProductConstant.GET_RTPRODUCT_DETAIL:
			return {...state, 
				RTproductDetail : action.item,
				RTproductDetails : action.items
			};
		default:
			return state;
	}
};
