import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsFillDiagram3Fill, BsFillTelephoneFill } from 'react-icons/bs';
import { GoDeviceMobile } from "react-icons/go";
import { GrLocation } from 'react-icons/gr';
import { Link } from 'react-router-dom';
import { BUTTONS, COLORS, INPUTS } from '../../constants/FromInputs';
import { MLButton } from './MLButton';
import { MLComboMulti } from './MLInput';

export const MLFileSize = (bytes, si = true, dp = 1) => {
	const thresh = si ? 1000 : 1024;
	if (Math.abs(bytes) < thresh) {
		return bytes + ' B';
	}
	const units = si
		? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
		: ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
	let u = -1;

	const r = 10 ** dp;
	do {
		bytes /= thresh;
		++u;
	} while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
	return bytes.toFixed(dp) + ' ' + units[u];
}

export const MLOrderActionIcon = ({ type, data }) => {
	if (type === "formsubmit") {
		return <span className='history_ico_formsubmit' />
	} else if (type.indexOf("attachment") > -1) {
		return <span className='history_ico_attachment' />
	} else if (type.indexOf("good") > -1) {
		return <span className='ecm006' />
	} else if (type.indexOf("estimate_") > -1) {
		return <span className='history_ico_estimate' />
	} else if (type.indexOf("task") > -1) {
		return <span className='history_ico_task' />
	} else if (type === "updatesuborder") {
		return <span className='history_ico_updatesuborder' />
	} else if (type === "removesuborder") {
		return <span className='history_ico_removesuborder' />
	} else if (type === "addsuborder") {
		return <span className='history_ico_addsuborder' />
	} else if (type === "updateaction") {
		return <span className='history_ico_updateaction' />
	} else if (type === "create") {
		return <span className='history_ico_ordercreate' />
	} else if (type === "closed") {
		return <span className='history_ico_closed' />
	} else if (type === "confirm") {
		if (data?.confirmtype === 'REQUEST') {
			return <span className='history_ico_request' />
		} else if (data?.confirmtype === 'ACCEPT') {
			return <span className='history_ico_response' />
		} else if (data?.confirmtype === 'DENY') {
			return <span className='history_ico_deny' />
		}
		return "";
	} else {
		return "";
	}
}

export const MLWorkflowButton = ({ workflow, css, color, onClick, permit }) => {

	return <>
		{workflow && <>
			<MLButton options={{
				label: workflow.name && workflow.name,
				size: 'sm',
				color: color || COLORS.INFO,
				iconobj: <BsFillDiagram3Fill className='me-2' />,
				action: onClick
			}} css={css} permit={permit} />
		</>}
	</>
}

export const MLWorkflow = ({ workflow, hidelab, onClick }) => {

	return <>
		{workflow && <>
			<div className='d-flex justify-content-between'>
				<div className='me-2 fw-bold'>
					{onClick && <div className='raycur' onClick={(e) => onClick(workflow)}>
						<BsFillDiagram3Fill className='me-2' />
						{workflow.name && workflow.name}
					</div>}
					{!onClick && <Link to={"/Dealer/DealerWF/" + workflow._id}>
						<BsFillDiagram3Fill className='me-2' />
						{workflow.name && workflow.name}
					</Link>}
				</div>
				{!hidelab && <div className='d-flex justify-content-end'>
					{workflow.labId && <Link to={"/Dealer/DealerLab/" + workflow.labId}>
						<MLLab id={workflow.labId} />
					</Link>}
				</div>}
			</div>
		</>}
	</>
}

export const MLEmailLink = ({ email, emailVerified, altermessage }) => {
	return <>
		{email && <a href={'mailto:' + email}>
			<MLEmail email={email} emailVerified={emailVerified} altermessage={altermessage} />
			{/* <AiOutlineMail className='me-1'/> {email} */}
		</a>}
	</>
}
export const MLEmail = ({ email, emailVerified, altermessage }) => {
	return <>
		<span>
			{altermessage ? altermessage : email}
		</span>
	</>
}

export const MLUserItemDetail = ({ user, notype, controls = [], onlyModal = false }) => {
	return <div className="d-flex align-items-center mb-5">
		<div className='me-5 position-relative'>
			<div className='d-flex symbol symbol-40px'>
				<span className='raycur'><MLUserAvatarById _id={user._id} onlyModal={onlyModal} /></span>
			</div>
		</div>
		<div className='fw-bold'>
			<div className='text-dark fw-bolder text-hover-primary fs-6'>{user.firstName} {user.lastName}</div>
			{!notype && <MLUserTypeTags user={user} onlyModal={onlyModal} />}
		</div>
		{user.now && <span className='badge badge-light ms-auto'>{user.now}</span>}
		{controls.length > 0 && <div className='d-flex justify-content-end ms-auto'>
			{controls.map((x, idx) => <React.Fragment key={idx}>
				{x.type === BUTTONS.NORMALBUTTON && <MLButton options={x.options} key={'btn-' + idx} />}
				{x.type === INPUTS.COMBOMULTI && <MLComboMulti options={x.options} obj={x} handleChanged={x.handleChanged} css='mb-0' />}
			</React.Fragment>
			)}
		</div>}
	</div>
}

export const MLUserListItem = ({ user, notype, controls = [] }) => {
	return <li className="list-group-item d-flex justify-content-between">
		<div className='d-flex symbol symbol-40px me-3'>
			{user}
			<div className='flex-grow ms-3'>
				<div className='text-dark fw-bolder text-hover-primary fs-6'>{user.firstName} {user.lastName}</div>
				{!notype && <MLUserTypeTags user={user} />}
			</div>
		</div>
		{controls.length > 0 && <div className='d-flex justify-content-end'>
			{controls.map((x, idx) => <React.Fragment key={idx}>
				{x.type === BUTTONS.NORMALBUTTON && <MLButton options={x.options} key={'btn-' + idx} />}
				{x.type === INPUTS.COMBOMULTI && <MLComboMulti options={x.options} obj={x} handleChanged={x.handleChanged} css='mb-0' />}
			</React.Fragment>
			)}
		</div>}
	</li>
}

export const MLVTabs = ({ tabs, tab, callback }) => {
	return <ul className="nav-tabs nav-pills fs-6 ps-0">
		<li className="nav-item me-0 d-grid min-w-200px gap-2 pe-8">
			{tabs.map((x, idx) => <button key={"set-" + idx}
				className={tab.code === x.code ? "nav-link active" : "nav-link"}
				data-bs-toggle="tab"
				onClick={() => callback(x)}>{x.title}</button>)}
		</li>
	</ul>
}

export const AddButton = ({ type, css = "", callback, permit }) => {
	const [t] = useTranslation(['translation']);
	return <></>;
}

export const ML2NR = ({ text, nodata = "N/A" }) => {
	var html = [];
	if (text) {
		text.split('\n').forEach((line, idx) => {
			html.push(<React.Fragment key={idx}>{line}<br /></React.Fragment>);
		})
	} else {
		return nodata;
	}
	return html;
}

export const MLTel = ({ tel, nodata = "N/A" }) => {
	var html = [];
	if (tel) {
		html.push(<span key="tel-1" className='me-2 fs-5'><BsFillTelephoneFill className='me-1' />{tel}</span>);
	} else {
		return <i className='me-2 fs-5'><BsFillTelephoneFill className='me-1' />{nodata}</i>;
	}
	return html;
}

export const MLHP = ({ tel, nodata = "N/A" }) => {
	var html = [];
	if (tel) {
		html.push(<span className='me-2 fs-5' key="tel"><GoDeviceMobile className='me-1' />{tel}</span>);
	} else {
		return <i className='me-2 fs-5'><GoDeviceMobile className='me-1' />{nodata}</i>;
	}
	return html;
}

export const MLAddress = ({ addr, detail, nodata = "N/A" }) => {
	var html = [];
	if (addr || detail) {
		html.push(<GrLocation key="addr-1" className='me-1' />);
		addr && html.push(<span key="addr-2">{addr}</span>);
		detail && html.push(<span key="addr-3" className='ms-2'>{detail}</span>);
	} else {
		return <i><GrLocation />{nodata}</i>;
	}
	return html;
}


export const MLUserById = ({ _id, css, readonly, onlyFirst = false, useCustomColor = false }) => {

	const users = useSelector(state => state.UserReducer.others);
	const [user, setUser] = useState(users.find(x => x._id === _id) ?? {});
	const dispatch = useDispatch();

	useEffect(() => {
		if (!!_id && !user?._id) {
			var value = users.find(x => x._id === _id);
			if(!value)
				value = Store.getState().UserReducer.items.find(x => x._id === _id);
			value && setUser(value);
		}
	}, [_id, user?._id, users]);

	useEffect(() => {
		if (_id) {
			const calledIds = Store.getState().UserReducer.calledLocaleUserIds;
			const reducerUsers = Store.getState().UserReducer.items;
			if (!calledIds.includes(_id) && !reducerUsers.find(x => x._id === _id)) {
				dispatch({ type: UserConstant.ADD_CALLED_USER_ID, item: _id })
				dispatch(UserAction.GetOther(_id))
			}
		}
	}, [_id])

	return <>
		{/* {user?._id && <MLUser user={user} css={css} readonly={readonly} />} */}
		{user?._id && <span className={(useCustomColor ? '' : 'text-primary ') + (css ? css : '') }>{onlyFirst ? (user?.name ? user?.name[0] : "U") : user?.name}</span>}
		{/* {!user?._id && <span className={'text-primary ' + (css ? css : '') }><i>Unknown</i></span>} */}
		{!user?._id && <span className={(useCustomColor ? '' : 'text-primary ') + (css ? css : '') }><i>{onlyFirst ? '...' : "Unknown"}</i></span>}
	</>
}

export const MLPhoto = ({ file, css, placeholder }) => {
	return <>
		{file && <>
			{file.s3path && <img src={AppConfig.S3_PATH + file.s3path} className={css && css} alt="" />}
			{!file.s3path && <img src={""} className={css && css} alt="" />}
		</>}
		{(!file && placeholder) && <img src={placeholder} className={css && css} alt="" />}
	</>
}