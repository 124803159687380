import axios from "axios";
import { useEffect, useState } from "react";
import { BUTTONS, COLORS, INPUTS, SPAN } from '../../constants/FromInputs';
import { MLButton } from '../../components/common/MLButton';
import { UploadS3 } from "../../libs/s3";
import { MLDate, MLText } from "../../components/common/MLInput";
import { MLForm } from "../../components/common/MLForm";
import MLTable from "../../components/common/MLTable";
import { ProgramConstant } from "../../redux/reducers";
import { InfoAction } from "../../redux/actions";
import moment from "moment";
import { useDispatch } from "react-redux";
import MLModal from "../../components/common/MLModal";
import { useTranslation } from "react-i18next";

const VIEW_TYPE = {
    DEFAULT: 'default',
    LANGKEYS: 'lang_keys',
    TRANS: 'trans',
    TRANSUSER: 'transuser'
};

const getEnv = () => {
    if (location.href.indexOf("localhost") > -1 || location.href.indexOf("127.0.0.1") > -1) {
        return "dev-local";
    } else if (location.href.indexOf("raydevelop") > -1) {
        return "development";
    }
    return "production";
}

const AuditPage = () => {
    const [t] = useTranslation(['translation']);
    const partnerKey = localStorage.getItem('partnerKey') || '';
    const [data, setData] = useState([]);
    const [showEx, setShowEx] = useState(false);
    const [modal, setModal] = useState({
        show: false,
        description: '',
    });
    const [st, setSt] = useState(new Date());
    const [dt, setDt] = useState(new Date());
    const [selectedService, setSelectedService] = useState('ALL');
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({
            type: ProgramConstant.SET_PAGE, page: {
                code: "audit",
                view: "summary",
                title: "Logs",
                small: "",
                options: []
            }
        })
        getData();
    }, [dt, st]);

    const getData = async () => {
        try {
            const ret = await InfoAction.callDB({
                type: "scan", region: "ap-northeast-2", params: {
                    TableName: "rayteams-audit",
                    FilterExpression: "#created > :gt and #created < :lt",
                    ExpressionAttributeNames: { "#created": "created" },
                    ExpressionAttributeValues: { ":gt": moment(st).startOf('day').unix(), ":lt": moment(dt).endOf('day').unix() },
                }
            });
            setData(ret.sort((a, b) => a.created > b.created ? -1 : 1).map(x => ({ ...x, svc: x.sk.split(':')[0].toUpperCase() })) || []);
        } catch {
            setData([]);
        }
    }

    const headers = showEx ? [
        { value: "_id", label: t("UID"), css: "text-start" },
        { value: "who", label: t("Who"), css: "text-start" },
        { value: "svc", label: t("Service"), css: "text-center" },
        { value: "lvl", label: t("Level"), css: "text-center" },
        { value: "msg", label: t("MSG"), css: "text-start" },
        { value: "extra", label: t("Extra"), css: "text-start" },
        { value: "created", label: t("Created"), css: "text-start", type: 'DATE_UNIX2' },
    ] : [
        { value: "_id", label: t("UID"), css: "text-start" },
        { value: "who", label: t("Who"), css: "text-start" },
        { value: "svc", label: t("Service"), css: "text-center" },
        { value: "lvl", label: t("Level"), css: "text-center" },
        { value: "msg", label: t("MSG"), css: "text-start" },
        {
            value: "extra", label: t("Extra"), css: "text-start", type: 'BUTTON_MODAL', btnClick: (item) => {
                setModal({
                    show: true,
                    body: <div style={{ overflowWrap: "anywhere" }}>{item.extra}</div>
                })
            }
        },
        { value: "created", label: t("Created"), css: "text-start", type: 'DATE_UNIX2' },
    ];

    const services = [...new Set(data.map(item => item.svc))];
    const serviceNames = {
        RT: 'RAYTeams',
        RM: 'RAYTeams Manager',
    };
    const filteredData = selectedService === 'ALL' ? data : data.filter(item => item.svc === selectedService);

    return (
        <div className='p-4'>
            <div className="d-flex justify-content-between mb-2">
                <div>
                    <button className={`me-2 btn ${selectedService === 'ALL' ? 'btn-primary' : 'btn-light'}`} onClick={() => setSelectedService('ALL')}>{t("All")}</button>
                    {services.map(service => (
                        <button key={service} className={`me-2 btn ${selectedService === service ? 'btn-primary' : 'btn-light'}`} onClick={() => setSelectedService(service)}>
                            {serviceNames[service] || service}
                        </button>
                    ))}
                </div>
                <div className="d-flex align-items-center">
                    <MLButton options={{
                        color : "light",
                        label : showEx ? t('Hide Extra') : t('Show Extra'),
                        size : 'sm',
                        action : () => setShowEx(!showEx)
                    }} css='me-1' />
                    <MLDate options={{ value: st }} handleChanged={(e) => setSt(e.target.value)} />
                    <span className='px-2'>~</span>
                    <MLDate options={{ value: dt }} handleChanged={(e) => setDt(e.target.value)} />
                </div>
            </div>

            <MLTable
                title={t("Count")}
                options={{
                    filterCols: [{ col: 'who', val: 'ALL' }]
                }}
                headers={headers}
                itemPerPage={100}
                items={filteredData} />
            <MLModal modalData={modal} footerbuttons={[{
                options: {
                    label: t('닫기'),
                    color: 'light',
                    action: () => setModal({ ...modal, show: false })
                }
            }]} />
        </div>
    );
}

export default AuditPage;
