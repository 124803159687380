import axios from 'axios';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { MLButton } from '../../components/common/MLButton';
import { MLPanelLoading } from '../../components/common/MLPanel';
import { COLORS, SETTING } from '../../constants/FromInputs';
import MLTable from '../../components/common/MLTable';
import { InfoAction } from '../../redux/actions';
import { ProgramConstant } from '../../redux/reducers';
import { history } from '../../history';
import { useTranslation } from 'react-i18next';
const LabProductPage = ({ user, region, products }) => {
	const [t] = useTranslation(['translation']);
	const [items, setItems] = useState([]);
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch({ type : ProgramConstant.SET_PAGE, page : { 
			code : "b2bproduct", 
			view : "summary", 
			title : "Products", 
			small : t("Product 관련 정보"), 
			options : [
			]
		}})
        getData();
	}, []);

    const getData = async () => {
		setLoading(true);
		const ret = await InfoAction.callDB({ type : "scan", region : "ap-northeast-2", params : {
			TableName: "rayteams-product",
			FilterExpression: "#sk=:sk",
			ExpressionAttributeNames: { "#sk": "sk" },
			ExpressionAttributeValues: { ":sk": "info" },
		}});
		setItems(ret);
		setLoading(false);
    }

    const headers = [
        { link : "_id", linkpath : "/LabProductInfoPage", value : "title", label : t("Title"), css : "text-start", style : { width : 450 } },
		{ value : 'type', label: t("Category"), type : 'CODE' },
		{ value : "price", label: t("Price") },
        { value : "consents", label: t("Consents"), css: "text-center", type : "CNT" },
        { value : "forms", label: t("Forms"), css: "text-center", type : "CNT" },
		{ value : "delivery", label: t("Delivery"), type : "TFVALUE" },
		{ value : "ispublished", label: t("Publish"), type : "TFVALUE" },
		{ value : "created", label : t("Created"), type : "DATE", css : "text-end" },
    ]

  	return <div className='p-4'>
		<div className="card">
			<div className="card-header d-flex justify-content-between align-items-center">
				<div className="card-label fw-bold text-gray-800 fs-5">{items.length}</div>
				<div className="d-flex">
						<MLButton options={{
						code: "prod-add",
						color : COLORS.PRIMARY,
						size : "sm",
						label : t("Add Product"),
						action : () => history.push("/LabProductAddPage"), //setModal({ show : true })
					}} css="me-2"/>
				</div>
			</div>
			{!loading && <MLTable 
				headers={headers} 
				items={items} noheader={true} />}
			{loading && <MLPanelLoading />}
		</div>
  	</div>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const region =  state.AuthReducer.region;
    const products = state.ProductReducer.products;
	return { region, user, products };
};

const mapDispatch = (dispatch) => ({
});

export default connect(mapState, mapDispatch)(LabProductPage);