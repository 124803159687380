import MaterialIcon from '@material/react-material-icon';
import React, { useEffect, useState } from 'react';
import { Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { COLORS } from '../../constants/FromInputs';
import { MLButton } from '../common/MLButton';
import { MLComboMulti, MLComboSingle, MLText } from '../common/MLInput';

export const MLFlowNodePanel = (({ users, codes, node, elements, forms, handleChanged, elRemove }) => {

	const [t] = useTranslation(['translation']);
	const [data, setData] = useState();

	useEffect(() => {
		setData(node.data);
	}, [node.data])

	const chgData = (e) => {
		if (Array.isArray(e.target.value)) {
			setData({ ...data, [e.target.name]: e.target.value });
			handleChanged({ target: { name: e.target.name, value: e.target.value } }, node);
		} else {
			setData({ ...data, [e.target.name]: e.target.value });
			handleChanged(e, node);
		}
	}

	const getElById = (id) => {
		var el = elements.filter(x => x.id === id);
		if (el.length === 0)
			return <></>;
		if (el[0].type === "action")
			return <Badge>{el[0].data.title}</Badge>
		if (el[0].type === "status")
			return <Badge bg="success">{el[0].data.title}</Badge>
		if (el[0].type === "input")
			return <Badge bg="dark">START</Badge>
		if (el[0].type === "output")
			return <Badge bg="dark">END</Badge>
	}

	const getUserLabel = (x) => {
		return x.firstName + x.lastName + '(' + x.email + ')';
	}

	const getUserComboItem = (x) => {
		return { value: x._id, label: getUserLabel(x) };
	}

	const pnStyle = {
		position:'absolute',
		top: 0,
		right: 0,
		marginTop: '100px',
		minWidth: '350px',
		zIndex: 1000
	}

	console.log(codes);

	return <>
		{data && <div className="aside raywfpanel card shadow-sm border mb-3 me-3 height-fit-content" style={data.eltype ? pnStyle : {...pnStyle, display: "none" }}>
			<div className='card-header separator'>
				<div className='card-title bold'>{t(node.data.type.charAt(0).toUpperCase() + node.data.type.slice(1))}</div>
			</div>
			<div className='card-body scroll bg-white'>
				<div className='mb-3'>
					<label className='mb-3 form-label'>{t('Information')}</label>
					{data.type === "action" && <div className='mb-1'>
						<label>{t('Flow')}</label>
						<div className='row fs24 ms-0 me-0'>
							<div className='col ps-0'>{getElById(node.source)}</div>
							<div className='col text-center'><MaterialIcon icon="trending_flat" style={{ verticalAlign: "middle" }} /></div>
							<div className='col text-end pe-0'>{getElById(node.target)}</div>
						</div>
					</div>
					}
					<MLText options={{
						size: "sm",
						name: "title",
						value: data.title,
						label: t('Name')
					}} handleChanged={chgData} />
				</div>
				{data.type === "status" && <div className='mb-3'>
					<label>{t('Code')}</label>
					<div className='d-grid'>
						<MLComboSingle options={{
							name: 's_code',
							isClearable: true,
							value: data.s_code || "",
							list: codes.filter(x => x.category === 'WF_STATUS_CODE')
						}} handleChanged={chgData} />
					</div>
				</div>}
				{data.type === "action" && <div className='mb-3'>
					<label>{t('Code')}</label>
					<div className='d-grid'>
						<MLComboSingle options={{
							name: 'a_code',
							isClearable: true,
							value: data.a_code || "",
							list: codes.filter(x => x.category === 'WF_ACTION_CODE')
						}} handleChanged={chgData} />
					</div>
				</div>}
				{/* {data.type === "action" && <div className='mb-3'>
					<label>{t('Form')}</label>
					<div className='d-grid'>
						<MLComboSingle options={{
							label: t('selectaform'),
							name: 'form',
							value: data.form || "",
							list: [{ value: "", label: "Not Assign" }, ...forms.filter(x => x.type === "form_workflow")
								.map(x => { return { value: x._id, label: x.title } })]
						}} handleChanged={chgData} />
					</div>
				</div>} */}

				<div className='mb-3'>
					<div className='d-grid'>
						<MLButton options={{
							label: t('remove' + (data.type === "action" ? " this action" : " this status")),
							color: COLORS.DANGER,
							action: () => {
								elRemove([node]);
								setData({});
							}
						}} css='mb-1' />
						<MLButton options={{
							label: t('close'),
							color: COLORS.SECONDARY,
							action: () => {
								setData({});
							}
						}} />
					</div>
				</div>
			</div>
		</div>
		}
	</>
});

const mapState = (state) => {
	
	const workflows = state.WFReducer.items;
	const site = state.ProgramReducer.site;
	const codes = state.ProgramReducer.codes;

	return { workflows, site, codes };
};

const mapDispatch = dispatch => ({
})

export default connect(mapState, mapDispatch)(MLFlowNodePanel);