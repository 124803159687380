import axios from 'axios';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import RMProductMainList from '../components/product/RMProductMainList';

const ProductPage = ({ user, region, products }) => {
  	return (
    	<div>
			<RMProductMainList />
    	</div>
  	);
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const region =  state.AuthReducer.region;
    const products = state.ProductReducer.products;
	return { region, user, products };
};

const mapDispatch = (dispatch) => ({
});

export default connect(mapState, mapDispatch)(ProductPage);