import { connect, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { history } from '../../history';
import { MdSubdirectoryArrowRight } from "react-icons/md";
import { useEffect, useState } from 'react';
import { MLComboSingle } from '../common/MLInput';
import { callDB } from '../../libs/db';
import { useTranslation } from 'react-i18next';

const RMBoardMenuComponents = ({ withall = true, selected = "", 
	callbackMenus,
	callback, isCombo = false }) => {

	const { _bid, _tab = "list" } = useParams();
	const [menus, setMenus] = useState([]);

	useEffect(() => {
        getData();
	}, []);
	const [t] = useTranslation(['translation']);

    const getData = async () => {
		try{
			const ret = await callDB({
				type : "scan",
				region : "ap-northeast-2",
				params : {
					TableName: "rayteams-manager-contents",
					FilterExpression: "#bid = :bid and begins_with(#sk, :sk)",
					ExpressionAttributeNames: { "#bid": "bid", "#sk": "sk" },
					ExpressionAttributeValues: { ":bid" : _bid, ":sk": "mn:" }
				},
				filter : {}
			});

			callbackMenus && callbackMenus(ret.filter(x => !x.lang))
			setMenus(ret.filter(x => !x.lang));
		}catch(err){
			console.log(err);
		}
    }

	const tree = (a, b) => {
		const aParts = a.split(':');
		const bParts = b.split(':');
		for (let i = 0; i < Math.min(aParts.length, bParts.length); i++) {
			if (aParts[i] !== bParts[i]) {
				return aParts[i].localeCompare(bParts[i]);
			}
		}
		return aParts.length - bParts.length;
	}

	const depth = (sk) => {
		if(sk.split(":").length === 2)
			return <></>
		const ret = [];
		for(let i = 0; i < sk.split(":").length - 3; i++){
			ret.push(<span className='ms-5'></span>)
		}
		ret.push(<MdSubdirectoryArrowRight className='me-2' />);
		return ret;
	}

  	return <>
		{isCombo && <>
			<MLComboSingle options={{
				list : menus
					.map(x => x.sk)
					.sort(tree)
					.map(x => ({ value: x, label: <>{depth(x)}{menus.find(m => m.sk === x).title}</> })),
				value : selected,
				label : t("Menu")
			}} handleChanged={(e) => callback(e.target.value)} />
		</>}
		{!isCombo && <>
			{menus.length > 0 && <div className="card mb-5" >
				<div className="list-group">
					{withall && <div className={'list-group-item cursor-pointer ' + (selected === "" ? " active" : "")}
						onClick={() => { callback && callback({ sk : "" }); }}>
							{t("모두(All)")}
					</div>}
					{menus.map(x => x.sk).sort(tree).map((x, idx) => <div key={idx}
						className={'list-group-item cursor-pointer ' + (selected === x ? " active" : "")}
						onClick={() => { callback && callback(menus.find(m => m.sk === x));  }}>
						{depth(x)}{menus.find(m => m.sk === x).title}
					</div>)}
				</div>
			</div>}
		</>}
	</>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const region =  state.AuthReducer.region;
	const board =  state.BoardReducer.board;
	const contents =  state.BoardReducer.contents;
	return { region, user, board, contents };
};

const mapDispatch = (dispatch) => ({
});

export default connect(mapState, mapDispatch)(RMBoardMenuComponents);
