const initialState = { 
	items : [],
	item : {},
	status : ""
};

export const WFConstant = {
	CREATE : "WF_CREATE",
	CREATED : "WF_CREATED",
	UPDATE : "WF_UPDATE",
	UPDATEFLOW : "WF_UPDATEFLOW",
	UPDATED : "WF_UPDATED",
	REMOVED : "WF_REMOVED",
	COPIED : "WF_COPIED",
	GETALL : "WF_GETALL",
	GET : "WF_GET",
	STATUSCLEAR : "CODE_STATUSCLEAR",
};

export const WFReducer = (state = initialState, action) => {
    switch (action.type) {
		case WFConstant.CREATE : 
			return {...state, 
				items : [...state.items, action.item], 
				item : action.item,
				status : WFConstant.CREATED
			};
		case WFConstant.GETALL : 
			return {...state, items : action.items };
		case WFConstant.UPDATE : 
			return {...state, 
				item : action.item,
				items : state.items.map(x => x._id === action.item._id ? action.item : x),
				status : WFConstant.UPDATED 
			};
		case WFConstant.UPDATEFLOW : 
			return {...state, 
				item : action.item,
				items : state.items.map(x => x._id === action.item._id ? action.item : x)
			};
		case WFConstant.REMOVED : 
			return {...state, 
				item : {},
				items : state.items.filter(x => x._id !== action.item._id),
				status : WFConstant.REMOVED 
			};
		case WFConstant.COPIED : 
			return {...state, 
				item : action.item,
				items : [...state.items, action.item], 
				status : WFConstant.COPIED 
			};
		case WFConstant.GET : 
			return {...state, 
				item : action.item,
				status : "" 
			};
		case WFConstant.STATUSCLEAR : 
			return {...state, 
				status : "" 
			};
        default:
            return state
    }
}