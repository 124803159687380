import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { OrderAction, ProductAction, WFAction } from '../redux/actions';
import { ProductConstant, ProgramConstant } from '../redux/reducers';

import { MLPanelNormalConfirm } from '../components/common/MLPanel';
import { history } from '../history';
import { MLButton } from '../components/common/MLButton';
import { COLORS } from '../constants/FromInputs';
import MLWFEditor from '../components/wf/MLWFEditor';
import MLWFSelectOrCreate from '../components/wf/MLWFSelectOrCreate';
import { MLComboSingle, MLText } from '../components/common/MLInput';
import { GetCodeConfig, GetConfig } from '../libs/appConfig';
import { RTAudit } from '../libs/audit';

const WorkflowAddPage = ({ auth, product, Get, orders, itemstatus, UpdateWF, productspecs,
	CopyWFAndAssign, Update, products, workflows, Create }) => {

	const [t] = useTranslation(['translation']);
	const [codes, setCodes] = useState([]);
	const [data, setData] = useState({
		title: '',
		flows: {}
	});
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch({ type : ProgramConstant.SET_PAGE, page : { 
			code : "workflow", 
			view : "summary", 
			title : "Add Workflow", 
			small : t("Workflow 생성"), 
			options : [ ]
		}})
		init();
	}, []);

	const init = async () => {
		const list = await GetCodeConfig();
		setCodes(list);
	}

	const copyandassignaction = () => {
		CopyWFAndAssign(product);
	}

	const UpdateF = async (e) => {
		Create({ ...data, flows : e.flows });
		history.push('/WorkflowPage');
		RTAudit({ lvl: 4, msg: 'Add Workflow'}, JSON.stringify({ subject: data }));
	}

	return <>
		{<>
			<div className='p-4'>
				<div className="header align-items-stretch d-block" style={{ position: "initial" }}>
					<div className="d-flex w-100 justify-content-between align-items-center">
						<div className="d-flex align-items-center text-dark fw-bolder fs-3 mb-3 my-1">Add Workflow</div>
						<div className="d-flex align-items-center"></div>
					</div>
				</div>
				<div className='post p-7'>
					<MLText options={{
						label: t('타이틀'),
						value: data.title,
						name: 'title'
					}} handleChanged={(e) => setData({ ...data, title: e.target.value })} />
					<MLComboSingle options={{
						label: t('워크플로우'),
						name: 'type',
						list: codes.filter(x => x.category === 'WF_TYPE'),
						value: data.wf,
						required: true
					}} handleChanged={(e) => setData({ ...data, wf: e.target.value })} />
					<MLWFEditor
						copyandassign={true}
						copyandassignaction={copyandassignaction}
						offsety={354}
						readonly={false}
						nocancel={true}
						data={data}
						save={(e) => UpdateF(e)} />
				</div>
			</div>
		</>}
	</>
}

const mapState = (state) => {
	const auth = state.AuthReducer.user;
	const product = state.ProductReducer.item;
	const products = state.ProductReducer.items;

	const workflows = state.WFReducer.items;
	const itemstatus = state.ProductReducer.status;
	return { auth, itemstatus, product, products, workflows };
};

const mapDispatch = dispatch => ({
	Create: (data) => dispatch(WFAction.Create(data)),
	Update: (data) => dispatch(WFAction.Update(data))
})

export default connect(mapState, mapDispatch)(WorkflowAddPage);