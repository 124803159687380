const initialState = { 
	project : {},
	projects : [],
	projectDetail : {},
	projectDetails : [],
};

export const ProjectConstant = {
	GET_ALL_PROJECTS : "GET_ALL_PROJECTS",
	GET_PROJECT : "GET_PROJECT",
	GET_PROJECT_DETAIL : "GET_PROJECT_DETAIL"

};

export const ProjectReducer = (state = initialState, action) => {
	switch (action.type) {
		case ProjectConstant.GET_ALL_PROJECTS:
			return {...state, projects : action.items };
		case ProjectConstant.GET_PROJECT:
			return {...state, project : action.item };
		case ProjectConstant.GET_PROJECT_DETAIL:
			return {...state, 
				productDetail : action.item.filter(x => x.sk.includes("bid:"))[0],
				productDetails : action.item.filter(x => !x.sk.includes("bid:")),
			};
		default:
			return state;
	}
};
