import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { COLORS } from '../../constants/FromInputs';
import { history } from '../../history';
import { RegionList } from '../../libs/cogInfo';
import { AuthConstant } from '../../redux/reducers';
import { MLButton } from '../common/MLButton';
import { MLComboSingle } from '../common/MLInput';

const RMHeader = ({ region, regions, title, regionlist }) => {

	const dispatch = useDispatch();
	const [regList, setRegList] = useState([]);

	useEffect(() => {
		//makeAvailRegion();
	}, []);

	const makeAvailRegion = () => {
		setRegList(regions && regions.map(x => x.region));
		updateRegion(regions && regions[0].region);
	}

    const logout = () => {
		localStorage.clear();
		dispatch({ type : AuthConstant.LOGOUT });
    }

	const updateRegion = (val) => {
		localStorage.setItem('region', val);
		dispatch({ type : AuthConstant.UPDATE_REGION, region : val });
	}

    return <div className='header align-items-stretch d-block' style={{ position : "initial"}}>
		<div className="d-flex w-100 justify-content-between p-5 align-items-center">
			<div className='d-flex align-items-center text-dark fw-bolder fs-2 my-1'>{title}</div>
		</div>
    </div>
}

const mapState = (state) => {
	const region =  state.AuthReducer.region;
	const regions =  state.AuthReducer.regions;
	const regionlist = state.AuthReducer.regionlist;
	const title =  state.ProgramReducer.title;
	return { region, regions, title, regionlist };
};

const mapDispatch = (dispatch) => ({ });

export default connect(mapState, mapDispatch)(RMHeader);
