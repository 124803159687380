export const GROUP_CFG = [
    {
        code : "stpage",
        category : "Account",
        label : "Start Page",
        description : "시작 페이지를 지정합니다.",
        type : "TEXT",
        dval : "/Dashboard"
    },
    {
        code : "caseall",
        category : "Case",
        label : "Show all cases",
        description : "모든 사용자가 모든 케이스를 볼 수 있습니다.",
        type : "BOOL",
        dval : true
    },
    {
        code : "searchcs",
        category : "Case",
        label : "Use search cases",
        description : "Search case 화면 사용여부",
        type : "BOOL",
        dval : false
    },
    {
        code : "hiddenlaunchapps",
        category : "Case",
        label : "Set hidden launch apps",
        description : "케이스 화면에서 실행할 실행되는 앱 중 노출되지 않을 앱을 설정합니다.",
        type : "TEXT_ARRAY",
        items: [
            { value: "rayface", label: "RAYFace" },
            { value: "smartortho", label: "SMARTOrtho" },
            { value: "ExocadConverter", label: "Converter for Exocad™" },
            { value: "ExoplanConverter", label: "Converter for Exoplan™" },
            { value: "dCam", label: "D+CAM" },
            { value: "RayDentDesigner", label: "RAYDENT Designer" },
            { value: "RayDentSplint", label: "RAYDENT Splint" },
            { value: "RayDentStudio", label: "RAYDENT Studio" },
        ],
        dval : "",
    },
    {
        code : "serviceworker",
        category : "Case",
        label : "Set service case manage",
        description : "RAYTeams Service로 신청된 케이스의 관리 여부를 설정합니다.",
        type : "TEXT_ARRAY_SINGLE",
        items: [
            { value: "dal", label: "Digital Art Lab" },
        ],
        dval : "",
    },
    {
        code : "umap",
        category : "Menu",
        label : "Use 'Map' menu",
        description : "Map Menu를 보여줄지 여부(기능 사용 여부 포함)",
        type : "BOOL",
        dval : false
    },
    {
        code : "uchat",
        category : "Menu",
        label : "Use 'Chat' menu",
        description : "Chat Menu를 보여줄지 여부(기능 사용 여부 포함)",
        type : "BOOL",
        dval : true
    },
    {
        code : "udashboard",
        category : "Menu",
        label : "Use 'Dashboard' menu",
        description : "Dashboard Menu를 보여줄지 여부",
        type : "BOOL",
        dval : true
    },
    {
        code : "ucases",
        category : "Menu",
        label : "Use 'Cases' menu",
        description : "Cases Menu를 보여줄지 여부",
        type : "BOOL",
        dval : true
    },
    {
        code : "usetunreg",
        category : "Account",
        label : "Support unregister",
        description : "로그인된 사용자가 직접 탈퇴할 수 있는지 여부",
        type : "BOOL",
        dval : true
    },
    {
        code : "usetmember",
        category : "Account",
        label : "Support user management",
        description : "매니저가 직접 사용자들을 관리할 수 있는지 여부",
        type : "BOOL",
        dval : true
    },
    {
        code : "usedrafttp",
        category : "Case",
        label : "Support Draft-treatment plan",
        description : "Draft TP 지원 여부",
        type : "BOOL",
        dval : false
    },
    {
        code : "usemultitp",
        category : "Case",
        label : "Support Multiple-treatment plan",
        description : "Multiple TP 지원 여부",
        type : "BOOL",
        dval : false
    },
    {
        code : "ushared",
        category : "Menu",
        label : "Shared Menu를 보여줄지 여부(기능 포함)",
        description : "Shared Menu/기능의 여부",
        type : "BOOL",
        dval : true
    },
    {
        code : "createcase",
        category : "Case",
        label : "Create case",
        description : "Case 생성 기능에 대한 사용여부",
        type : "BOOL",
        dval : true
    },
];
