import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { MLText } from '../components/common/MLInput';
import { RMIconList, RMIconChart } from '../components/icon/MLIcon';
import RMWidgetBarChart from '../components/widgets/RMWidgetBarChart';
import RMWidgetCount from '../components/widgets/RMWidgetCount';
import { MLPanelLoading } from '../components/common/MLPanel';
import { ProgramConstant } from '../redux/reducers';


const GroupDashBoardPage = ({ groups, region, regionusers }) => {
	const dispatch = useDispatch();
	const [search, setSearch] = useState("");
	const [items, setItems] = useState([]);
	const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(groups)
            genData();
    }, []);

    const genData = () => {
		setItems(search ? groups.filter(x => x.name.indexOf(search) > -1 || x.address.indexOf(search) > -1) : (groups || []));
	}

    const goLink = (to, title) => {
		dispatch({ type : ProgramConstant.GO_LINK, to, title });
	}

    return ( <>
		<div className="header align-items-stretch d-block" style={{position: "initial"}}>
			<div className="d-flex w-100 justify-content-between p-5 align-items-center">
				<div className="d-flex align-items-center text-dark fw-bolder fs-2 my-1">Groups</div>
				<div className="d-flex align-items-center">&nbsp;</div>
			</div>
		</div>
    	<div>
			<div className='row'>
				<div className='col-xl-12 mb-5'>
					<div className='d-flex justify-content-between align-items-end mb-3'>
						<h2 className='mb-4'><small className='ms-2'></small></h2>
						<div className='d-flex'>
							<div className='col-sm-auto'>
								<MLText options={{
									placeholder : "검색어 입력",
									size : "sm",
									value : search,
								}} handleChanged={(e) => setSearch(e.target.value)} css="w-200px" />
							</div>
							<div className="col-sm-auto ms-6 btn-tool-view">
								<a className="btn btn-bg-secondary btn-sm mb-1 btn-view-select" title="View as a list">
									<RMIconList onClick={() => goLink("/GroupPage", "Groups")}></RMIconList>
								</a>
								<a className="btn btn-bg-secondary btn-sm mb-1 btn-view-select active" title="View as a dashboard">
									<RMIconChart></RMIconChart>
								</a>
							</div>
						</div>
					</div>
				{!loading && <>
                    <div className='row mb-5'>
                        <div className='col-3'>
                            <RMWidgetCount data={items} title={"Group Count"} />
                        </div>
                        <div className='col-9'>
                            <RMWidgetBarChart data={items} period={"days"} target={"created"} type={"Group"}  title={"Group"}  />
                            
                        </div>
                    </div>
                    <div className='mb-5'>
                        <RMWidgetBarChart data={items} title={"국가별 사용자 생성일자"} period={"days"} targetdate={"created"} targetname={"countryCode"} chartType={true} />
					</div>
					<div>
						<RMWidgetBarChart data={items} type={"Type"} title={"사용자 Type Top 5"} target={"userType"} />
					</div>
				</>}
				{loading && <MLPanelLoading />}
				</div>
			</div>
    	</div>
		</>
  	);
}

const mapState = (state) => {
	const groups =  state.AuthReducer.groups;
	const region = state.AuthReducer.region;
	const regionusers = state.AuthReducer.regionusers;
	return { groups, region, regionusers };
};

export default connect(mapState, null)(GroupDashBoardPage);