import axios from 'axios';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import RMBoardInfoPaenl from './RMBoardInfoPaenl';
import RMModalAddBoard from './RMModalAddBoard';
import { MLButton } from '../common/MLButton';
import MLTable from '../common/MLTable';
import { COLORS, SETTING } from '../../constants/FromInputs';
import { MLPanelLoading } from '../common/MLPanel';
import { JWT } from '../../config';
import RMModalAddContent from './RMModalAddContent';
import { BoardConstant } from '../../redux/reducers';
import { BoardAction } from '../../redux/actions';
import { fetchData, idData,  } from '../../libs/db';
import RMBoardMenuComponents from './RMBoardMenuComponents';
import { callDB } from '../../libs/db';

const RMBoardContentList = ({ user, board }) => {

	const dispatch = useDispatch();
	const { _bid } = useParams();
	const [loading, setLoading] = useState(true);
	const [modal, setModal] = useState({ show : false });
	const [menu, setMenu] = useState({ sk : "" });
	const [menus, setMenus] = useState([]);
	const [contents, setContents] = useState([]);

	useEffect(() => {
        getData();
	}, []);

    const getData = async () => {
		setLoading(true);
		try{
			const ret = await callDB({
				type : "scan",
				region : "ap-northeast-2",
				params : {
					TableName: "rayteams-manager-contents",
					FilterExpression: "#bid = :bid and #sk = :sk",
					ExpressionAttributeNames: { "#bid": "bid", "#sk": "sk" },
					ExpressionAttributeValues: { ":bid" : _bid, ":sk": "info" }
				},
				filter : {}
			});
            const list = ret.sort((a, b) => a.created > b.created ? -1 : 1).map(x => ({...x,
                title : x.isDel > 0 ? <strike>삭제된 글입니다.</strike> : x.title,
                content : x.isDel > 0 ? <strike>삭제된 글입니다.</strike> : x.content,
            }));
			setContents(list);
		}catch(err){
			console.log(err);
		}
		setLoading(false);
    }

    const headers = [
        { link : "_id", linkpath : "/Content/" + _bid, value : "title", label : "Title", css : "text-start" },
        { value : "menutitle", label : "Menu", css : "text-start", style : { width : 200 } },
		//{ value : "israyteams", label : "RAYTeams", type : "TFVALUE", css : "text-end", style : { width : 100 }},
        { value : "updated", label : "Updated", type : "DATE", css : "text-start", style : { width : 200 } },
    ]

	const filter = () => {
		if(menu?.sk === "")
			return contents.map(x => ({...x, menutitle : menus.find(m => m.sk === x.menu)?.title }));

		return contents.filter(x => x.menu === menu.sk).map(x => ({...x, menutitle : menus.find(m => m.sk === x.menu)?.title }));
	}

  	return <div className='row mt-4'>
		<div className={menus.length > 0 ? 'col-3' : ''}>
			<RMBoardMenuComponents 
				callbackMenus={m => setMenus(m)}
				callback={(m) => setMenu(m)} selected={menu?.sk || ""} />
		</div>
		<div className={menus.length > 0 ? 'col-9' : ''}>
			{!loading && <>
                <div className="card">
                    <MLTable 
                        headers={headers} 
                        items={filter()} noheader={true} />
                    <RMModalAddContent 
                        modalData={modal} 
                        callbackClose={() => setModal({ show : false })} />
                </div>
			</>}
			{loading && <MLPanelLoading />}
    	</div>
	</div>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const region =  state.AuthReducer.region;
	const board =  state.BoardReducer.board;
	return { region, user, board };
};

const mapDispatch = (dispatch) => ({
});

export default connect(mapState, mapDispatch)(RMBoardContentList);
