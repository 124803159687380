export const VALUES = {
	WORKFLOW : "WORKFLOW",
}
export const ACTIONS = {
	DOWNLOAD : "Download",
	CALLCHAT : "Open Chat",
	VIEWER : "Open Viewer",
	ASSIGNUSER : "Assign User",
}
export const INPUTS = {
	GROUP : "div",
	TEXT : "text",
	ADDITEMS : "additems",
	NUMBER : "number",
	SINGLEFILE : "filesingle",
	SINGLEFILEFORORDER : "filesinglefororder",
	HTMLEDITOR : "htmleditor",
	HEADPHOTO8 : "headphoto8set",
	COMBO : "combo",
	HIDDEN : "hidden",
	COMBOLABS : "combolabs",
	DATE : "date",
	RADIOBUTTON : "radiobutton",
	WFCOMBO : "workflowcombo",
	COMBOBYCODE : "combobycode",
	TOGGLE : "toggle",
	COMBOMULTI : "combomulti",
	TEXTAREA : "textarea",
	EMAIL : "email",
	PASSWORD : "password",
	COMPONENT: 'component'
}
export const BUTTONS = {
	NORMALBUTTON : "normalbutton",
	FORMSUBMIT : "submit",
	BUTTONS : "buttons",
}
export const SPAN = {
	VERTICAL : "verticalspan",
	HR : "hr",
}
export const COLORS = {
	DEFAULT : "",
	PRIMARY : "primary",
	SECONDARY : "secondary",
	SUCCESS : "success",
	DANGER : "danger",
	WARNING : "warning",
	LIGHT : "light",
	INFO : "info",
	DARK : "dark",
	LIGHT_PRIMARY: 'light-primary',
	LIGHT_SUCCESS: 'light-success',
	LIGHT_DANGER: 'light-danger'
}
export const INPUTSIZE = {
	DEFAULT : "md",
	SMALL : "sm",
	LARGE : "lg",
}
export const ALIGN = {
	DEFAULT : "",
	CENTER : "text-center",
	RIGHT : "text-right",
}