import axios from 'axios';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { MLText } from '../../components/common/MLInput';
import { MLButton, MLRefreshButton, MLChartButton } from '../../components/common/MLButton';
import { COLORS } from '../../constants/FromInputs';
import { MLPanelLoading } from '../../components/common/MLPanel';
import MLTable from '../../components/common/MLTable';
import { AuthConstant, getAPI, getHeader } from '../../redux/reducers/AuthReducer';
import { queryDataWithIndex } from '../../libs/db';
import { ApplicationAction } from '../../redux/actions';
import WidgetBarChartAppUsage from '../../components/widgets/dashboard/WidgetBarChartAppUsage';
import RMWidgetBarChart from '../../components/widgets/RMWidgetBarChart';
import RMWidgetLineChart from '../../components/widgets/RMWidgetLineChart';
import { TbLayoutDashboard } from 'react-icons/tb';
import { ProgramConstant, ApplicationConstant } from '../../redux/reducers';
import { RMIconList, RMIconChart } from '../../components/icon/MLIcon';
import { history } from '../../history';
import { useTranslation } from 'react-i18next';
const ApplicationsPage = ({ groups, region, regionusers, applications, pagestat, page }) => {
	const dispatch = useDispatch();
	const [more, setMore] = useState(true);
	const [search, setSearch] = useState("");
	const [filter, setFilter] = useState(false);
	const [items, setItems] = useState([]);
	const [loading, setLoading] = useState(false);
	const [t] = useTranslation(['translation']);

	useEffect(() => {
		dispatch({ type : ProgramConstant.SET_PAGE, page : { 
			code : "softwares", 
			view : "summary", 
			title : "Softwares", 
			small : t("Software 관련 정보"), 
			options : [
			]
		}})
	}, []);

	useEffect(() => {
        getData();
	}, []);

	useEffect(() => { 
		console.log('items==================================');
		console.log(items);
	}, [items]);

	useEffect(() => {
		genData();
        dispatch({ type: ProgramConstant.PAGESTAT, item: { grouplist : { search, filter, 
            order : pagestat.order, 
            desc : pagestat.desc 
         }} });
	}, [search, applications, filter, pagestat.order, pagestat.desc]);

	const goLink = (to, title) => {
		dispatch({ type : ProgramConstant.GO_LINK, to, title });
	}

    const headers = [
		{ value : "title", label : t("Name"), css : "text-start", css : "w-300px" },
        { value : "category", label : t("Category"), css : "text-center" },
        { value : "updated", label : t("Updated"), css : "text-center", type : "DATESHORT" },
	]

	// country 선택
	const getData = async () => {
		setLoading(true);
        dispatch(ApplicationAction.GetApplications());
		setLoading(false);
	}

    const fLabel = (code, value) => {
        if(value === true || value === false){
            return value === true ? t("Active") : t("Expired");
        }else if(code === "countryCode"){
            return COUNTRIES.find(x => x.countryCode === value)?.name;
        }
        return value;
    }

	const genFilter = () => {
        setFilter( filter.map(x => {
            const list = [...new Set(applications.map(m => m[x.code]))];
            return {...x, list : [{ value : "", label : t("ALL") }, ...list.map(n => ({ 
                value : n, 
                label : fLabel(x.code, n)
            }))] }
        }));
	}

	const genDataUsed = (x) => {
        return filter ? x.ownerId : true;
	}

	const genData = () => {
		setItems(applications
            .filter(x => genDataUsed(x))
            .filter(x => search ? (x.sn?.indexOf(search) > -1) : true)
			.sort((a, b) => pagestat.desc === "desc" ? (a[pagestat.order] > b[pagestat.order] ? -1 : 1) : (b[pagestat.order] > a[pagestat.order] ? -1 : 1))
        )
	}

	const handleFilter = (e, item) => {
        setFilter( filter.map(x => x.code === item.code ? {...x, value : e.value } : x));
	}

	const headerClick = () => {
		dispatch({ type: ProgramConstant.PAGESTAT, item: { devicelist : { search, filter, 
            order : pagestat.order, 
            desc : (pagestat.desc === "desc" ? "asc" : "desc") }} });
	}

  	return <div className='p-4'>
		<div className="card">
			<div className="card-header d-flex justify-content-between align-items-center">
				<div className="card-label fw-bold text-gray-800 fs-5">{items && items.length}</div>
				<div className="d-flex">
					<MLButton options={{
						label : filter ? t("Used(RAYTeams)") : t("전체"),
						size : "sm",
						color : COLORS.PRIMARY,
						action : () => setFilter(!filter)
					}} css="me-2" />
					<MLText options={{
							placeholder : t("검색어 입력"),
							size : "sm",
							value : search,
						}} handleChanged={(e) => setSearch(e.target.value)} css="mb-0" />
					<div className="col-sm-auto ms-6 pt-1 btn-tool-view" style={{display: "none"}}>
						<a className="btn btn-bg-secondary btn-sm mb-1 btn-view-select active" title="View as a list">
							<RMIconList></RMIconList>
						</a>
						<a className="btn btn-bg-secondary btn-sm mb-1 btn-view-select" title="View as a dashboard">
							<RMIconChart onClick={() => goLink("/UsersDashBoardPage", "Users")}></RMIconChart>
						</a>
					</div>
				</div>
			</div>
			{!loading && <MLTable 
				headers={headers} 
				items={items} 
				headerClick={headerClick}
				order={pagestat?.order || "created"} 
				desc={pagestat?.desc || "desc"} 
				noheader={true} />}
			{loading && <MLPanelLoading />}
		</div>
    </div>
}

const mapState = (state) => {
	const groups =  state.AuthReducer.groups;
	const region = state.AuthReducer.region;
	const regionusers = state.AuthReducer.regionusers;
    const applications = state.ApplicationReducer.applications;
	const pagestat = state.ProgramReducer.pagestat["applicationlist"] || {};
	const page = state.ProgramReducer.page;

	return { groups, region, regionusers, applications, pagestat, page };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, null)(ApplicationsPage);
