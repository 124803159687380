import AWS, { CodeArtifact } from "aws-sdk";
import { COGADMIN, COGNITO, CONFIG_REGION_SET } from "./cogInfo";
import { DEFAULT_SET_GRAPHY, COGNITO_GRAPHY, COGADMIN_GRAPHY } from "./cognito-graphy";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import { S3Client, PutObjectCommand, ListObjectsCommand } from "@aws-sdk/client-s3";
import S3SyncClient from "./s3-sync-client";
import { Buffer } from "buffer";
import { fetchData, queryData } from "./db";

const bucketPrefix = "raylink-data";

const env = (location.href.indexOf("localhost") > -1 || location.href.indexOf(".raydevelop.com") > -1) ? "development" : "production";

const getClientId = () => {
    if(process.env.REACT_APP_COMPANY === 'graphy'){
        return DEFAULT_SET_GRAPHY.TEAMS_MAN.appId;
    }

    return env === "production" ? "5ks6k9hpog3qv2gnqn318af8b7" : "5nnscnp9ps0rhgt576ne9pebg";
}

const configAWSRegion = (region) => {
    const _region = CONFIG_REGION_SET[region]
      ? CONFIG_REGION_SET[region]
      : region;
    AWS.config.update({
      region: _region,
    });
};

export const AWSLogin = async (email, password) => {
	AWS.config.update({ region: 'ap-northeast-2' });
    const cognito = new AWS.CognitoIdentityServiceProvider({apiVersion: '2016-04-18'});
    let params = {
        AuthFlow: 'USER_PASSWORD_AUTH',
        ClientId: getClientId(), //env === "production" ? "5ks6k9hpog3qv2gnqn318af8b7" : "5nnscnp9ps0rhgt576ne9pebg",
        AuthParameters: {
            USERNAME: email,
            PASSWORD: password
        }
    };
    console.log(params);
    const ret = await cognito.initiateAuth(params).promise();
    if(ret.AuthenticationResult.IdToken){
        const arr = ret.AuthenticationResult.IdToken.split(".");
        const uData = JSON.parse(Buffer.from(arr[1], "base64").toString('utf8'));
        console.log(uData['custom:type']);
		var list = [];
        localStorage.setItem("regionlist", JSON.stringify(list));
        localStorage.setItem("type", uData["type"] || "");
        localStorage.setItem("pwchange", uData["pwchange"] || "");
        localStorage.setItem("usersub", uData["sub"] || "");
		localStorage.setItem('token', ret.AuthenticationResult.IdToken);
		localStorage.setItem('user', ret.AuthenticationResult.IdToken);
        const rData = {
            _id : uData.email,
            name : uData.name,
            email : uData.email,
            valid : uData.valid,
            manager : uData['custom:type'] === 'manager',
            regions: list, 
            sub : uData._id, 
            exp : uData.exp,
        };
        localStorage.setItem('userdata', JSON.stringify(rData));
		return rData;
	}
	return null;
}

export const AWSLoginRegion = async (region, email, password) => {
    const cog = COGNITO[region];
	AWS.config.update({ region });
    const cognito = new AWS.CognitoIdentityServiceProvider({apiVersion: '2016-04-18'});
    const params = {
        AuthFlow: 'USER_PASSWORD_AUTH',
        ClientId: cog.appId,
        AuthParameters: {
            USERNAME: email,
            PASSWORD: password
        }
    };
    const ret = await cognito.initiateAuth(params).promise();
    if(ret.AuthenticationResult.IdToken){
        if(region == "ap-northeast-2") {
            localStorage.setItem('user', ret.AuthenticationResult.IdToken);
        }
		localStorage.setItem('token-' + region, ret.AuthenticationResult.IdToken);
		localStorage.setItem('token-refresh-' + region, ret.AuthenticationResult.RefreshToken);
		return true;
	}
	return false;
}

export const getS3KeyInfo = async (region, groupid) => {
    if(!region)
        return;

    var s3keypath = "userdata/" + groupid + "/";
    let COGNITO_ID = COGNITO[region].cogId;
    let loginData = {
        [COGNITO_ID]: localStorage.getItem("token-" + region),
    };
    
    const client = new S3Client({
        region: region,
        credentials: fromCognitoIdentityPool({
            clientConfig: { region: 'ap-northeast-2' },
            identityPoolId : COGNITO[region].cogPoolId,
            logins: loginData
        })
    });

    const { listBucketObjects } = new S3SyncClient({ client: client });

    var list = {};
    var response;
    try{
        response = await listBucketObjects(bucketPrefix + (region ? `-${region}` : ''), { prefix : s3keypath });
        response.map(x => {
            var obj = JSON.parse(JSON.stringify(x));
            var keytype = obj.id.substring(obj.id.lastIndexOf(".") + 1, obj.id.length).toUpperCase();
            if(!list[keytype]){
                list[keytype] = { name : keytype, size : 0, count : 0 };
            }
            list[keytype].size += obj.size;
            list[keytype].count++;
        })
    }catch(err){
        console.log('getS3KeyInfo ERROR');
        console.log(err);
    }
    return list;
}

export const getCogAdmin = () => {
    if(process.env.REACT_APP_COMPANY === 'graphy'){
        console.log(COGADMIN_GRAPHY);
        return COGADMIN_GRAPHY;
    }else{
        return COGADMIN;
    }
}

export const AWSAdminGetUser = async (email) => {
    const cred = fromCognitoIdentityPool({
        clientConfig: { region: 'ap-northeast-2' },
        identityPoolId : getCogAdmin().cogPoolId,
        logins: { [getCogAdmin().cogId]: localStorage.getItem("token") }
    })
    AWS.config.update({ region: 'ap-northeast-2', credentials: await cred() });
    const cognito = new AWS.CognitoIdentityServiceProvider({apiVersion: '2016-04-18'});
    const params = {
        Username: email,
        UserPoolId: getCogAdmin().poolId
    };
    const ret = await cognito.adminGetUser(params).promise();
    return ret;
}

export const AWSAdminEnableUser = async (region, sub) => {
    const cred = fromCognitoIdentityPool({
        clientConfig: { region: 'ap-northeast-2' },
        identityPoolId : getCogAdmin().cogPoolId,
        logins: { [getCogAdmin().cogId]: localStorage.getItem("token") }
    })
    const cog = process.env.REACT_APP_COMPANY === 'graphy' ? COGNITO_GRAPHY[region] : COGNITO[region];
    AWS.config.update({ region, credentials: await cred() });
    const cognito = new AWS.CognitoIdentityServiceProvider({apiVersion: '2016-04-18'});
    const params = {
        Username: sub,
        UserPoolId: cog.poolId
    };
    const ret = await cognito.adminEnableUser(params).promise();
    if(ret.$response.httpResponse.statusCode == 200) {
        return true;
    }
    return false;
}

export const AWSAdminDisableUser = async (region, sub) => {
    const cred = fromCognitoIdentityPool({
        clientConfig: { region: 'ap-northeast-2' },
        identityPoolId : getCogAdmin().cogPoolId,
        logins: { [getCogAdmin().cogId]: localStorage.getItem("token") }
    })
    const cog = process.env.REACT_APP_COMPANY === 'graphy' ? COGNITO_GRAPHY[region] : COGNITO[region];
    AWS.config.update({ region, credentials: await cred() });
    const cognito = new AWS.CognitoIdentityServiceProvider({apiVersion: '2016-04-18'});
    const params = {
        Username: sub,
        UserPoolId: cog.poolId
    };
    console.log(cognito);
    const ret = await cognito.adminDisableUser(params).promise();
    if(ret.$response.httpResponse.statusCode == 200) {
        return true;
    }
    return false;
}

export const AWSLoginPasswordUpdate = async (region, email, password) => {
    const cog = process.env.REACT_APP_COMPANY === 'graphy' ? COGNITO_GRAPHY[region] : COGNITO[region];
    AWS.config.update({ region });
    const cognito = new AWS.CognitoIdentityServiceProvider({apiVersion: '2016-04-18'});
    const params = {
        Password: password,
        Permanent: true,
        Username: email,
        UserPoolId: cog.poolId
    };
    const ret = await cognito.adminSetUserPassword(params).promise();
    if(ret.$response.httpResponse.statusCode == 200) {
        return true;
    }
    return false;
}

export const AWSAdminLoginPasswordUpdate = async (email, password) => {
    AWS.config.update( { region: 'ap-northeast-2' } );
    const cognito = new AWS.CognitoIdentityServiceProvider({apiVersion: '2016-04-18'});
    const params = {
        Password: password,
        Permanent: true,
        Username: email,
        UserPoolId: getCogAdmin().poolId
    };
    const ret = await cognito.adminSetUserPassword(params).promise();
    return ret;
}

export const AWSUserListRegion = async (region) => {
    const cog =  COGNITO[region];
    const cred = fromCognitoIdentityPool({
        clientConfig: { region },
        identityPoolId : getCogAdmin().cogPoolId,
        logins: { [getCogAdmin().cogId]: localStorage.getItem("token") }
    })
    AWS.config.update({ region, credentials: await cred() });
    const cognito = new AWS.CognitoIdentityServiceProvider({apiVersion: '2016-04-18'});
    const params = {
        UserPoolId: getCogAdmin().poolId
    };
    const ret = await cognito.listUsers(params).promise();
    return ret;
}

export const AWSUserListAdmin = async (region) => {
    const cred = fromCognitoIdentityPool({
        clientConfig: { region },
        identityPoolId : getCogAdmin().cogPoolId,
        logins: { [getCogAdmin().cogId]: localStorage.getItem("token") }
    })
    AWS.config.update({ region, credentials: await cred() });
    const cognito = new AWS.CognitoIdentityServiceProvider({apiVersion: '2016-04-18'});
    let params = {
        UserPoolId: getCogAdmin().poolId
    };

    const listResults = { Items : [], Count : 0 };
    let items;
    do{
        items = await cognito.listUsers(params).promise();
        items.Users.forEach((item) => { listResults.Items.push(item); listResults.Count++; });
        params.PaginationToken = items.PaginationToken;
    }while(typeof items.PaginationToken !== "undefined");
    return listResults;
}

export const AWSGetAdminUserById = async (userId) => {
    const adminregion = 'ap-northeast-2';
    const cog =  COGNITO[adminregion];
    const cred = fromCognitoIdentityPool({
        clientConfig: { region : adminregion },
        identityPoolId : getCogAdmin().cogPoolId,
        logins: { [getCogAdmin().cogId]: localStorage.getItem("token") }
    })
	AWS.config.update({ region: 'ap-northeast-2', credentials: await cred() });
    const cognito = new AWS.CognitoIdentityServiceProvider({apiVersion: '2016-04-18'});
    const paramsCognito = {
        UserPoolId: getCogAdmin().poolId,
        Username: userId,
    };
    const ret = await cognito.adminGetUser(paramsCognito).promise();
    return ret;
}

export const AWSAdminUserUpdate = async (userId, data) => {
    const adminregion = 'ap-northeast-2';
    const cog =  COGNITO[adminregion];
    const cred = fromCognitoIdentityPool({
        clientConfig: { region : adminregion },
        identityPoolId : getCogAdmin().cogPoolId,
        logins: { [getCogAdmin().cogId]: localStorage.getItem("token") }
    })
	AWS.config.update({ region: 'ap-northeast-2', credentials: await cred() });
    const cognito = new AWS.CognitoIdentityServiceProvider({apiVersion: '2016-04-18'});
    const params = {
        UserPoolId: getCogAdmin().poolId,
        Username: userId,
        UserAttributes: data
    };
    const ret = await cognito.adminUpdateUserAttributes(params).promise();
    console.log(ret);
    return ret;
}

export const AWSGetUserById = async (region, userId) => {
    const cog =  COGNITO[region];
    AWS.config.update({ region });
    const cognito = new AWS.CognitoIdentityServiceProvider({apiVersion: '2016-04-18'});
    const paramsCognito = {
        UserPoolId: cog.poolId,
        Username: userId,
      };
      const ret = await cognito.adminGetUser(paramsCognito).promise();
      return ret;
}

export const AWSRMUserUpdate = async (region, regionlist, email, name) => {
    const cog =  COGNITO[region];
    AWS.config.update({ region });
    const cognito = new AWS.CognitoIdentityServiceProvider({apiVersion: '2016-04-18'});
    const attributeList = {
        'custom:regions' : regionlist
    }
    const params = {
        UserPoolId: getCogAdmin().poolId,
        Username: email,
        UserAttributes: [
            {
                Name: 'custom:regions',
                Value: regionlist
            },
            {
                Name: 'name',
                Value: name
            }
        ]
    };
    const ret = await cognito.adminUpdateUserAttributes(params).promise();
    return ret;
}

export const AWSRMUserPwFlagUpdate = async (email) => {
    AWS.config.update( { region: 'ap-northeast-2' } );
    const cognito = new AWS.CognitoIdentityServiceProvider({apiVersion: '2016-04-18'});
    const params = {
        UserPoolId: getCogAdmin().poolId,
        Username: email,
        UserAttributes: [
            {
                Name: 'custom:pwchange',
                Value: 'yes'
            },
        ]
    };
    const ret = await cognito.adminUpdateUserAttributes(params).promise();
    return ret;
}

export const AWSUserDelete = async (region, email) => {
    const cred = fromCognitoIdentityPool({
        clientConfig: { region: 'ap-northeast-2' },
        identityPoolId : getCogAdmin().cogPoolId,
        logins: { [getCogAdmin().cogId]: localStorage.getItem("token") }
    })
    const cog = process.env.REACT_APP_COMPANY === 'graphy' ? COGNITO_GRAPHY[region] : COGNITO[region];
    AWS.config.update({ region, credentials: await cred() });
    const cognito = new AWS.CognitoIdentityServiceProvider({apiVersion: '2016-04-18'});
    const params = {
        UserPoolId: cog.poolId,
        Username: email
    };
    const ret = await cognito.adminDeleteUser(params).promise();
    return ret;
}

export const AWSAdminUserDelete = async (email) => {
    AWS.config.update({ region: 'ap-northeast-2' });
    const cognito = new AWS.CognitoIdentityServiceProvider({apiVersion: '2016-04-18'});
    const params = {
        UserPoolId: getCogAdmin().poolId,
        Username: email,
    };
    const ret = await cognito.adminDeleteUser(params).promise();
    return ret;
}

export const GetUserInfo = async (_id) => {
    let region = '';
    const uauth = await fetchData("rayteams-auth", { sub: { ComparisonOperator: "EQ", AttributeValueList: [_id], } });
    if(uauth.Items.length > 0){
        region = uauth.Items[0]?.sk.replace("region:", "");
    }else{
        return null;
    }
    const params = {
        Username: _id,
        UserPoolId: process.env.REACT_APP_COMPANY === 'graphy' ? COGNITO_GRAPHY[region].poolId : COGNITO[region].poolId,
    };
    configAWSRegion(region);

    var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
    try {
        var item = await cognitoidentityserviceprovider
            .adminGetUser(params)
            .promise();
        var ret = {};
        item?.UserAttributes.map((x) => {
            ret[x.Name.replace("custom:", "")] = x.Value;
        });
        ret.UserStatus = item.UserStatus;
        ret.Enabled = item.Enabled;
        ret._id = ret.sub;        
        const uinfo = await queryData("rayteams-user", {
            _id: {
                ComparisonOperator: "EQ",
                AttributeValueList: [ret._id],
            },
            sk: {
                ComparisonOperator: "EQ",
                AttributeValueList: ["info"],
            },
        });
        if(uinfo.Items.length > 0){
            return {...uinfo.Items[0], ...ret};
        }
    } catch (err) {
        console.log(err);
    }
    return null
}

const getS3Client = (region = 'ap-northeast-2') => {
    let COGNITO_ID = COGNITO[region].cogId;
    let loginData = {
        [COGNITO_ID]: localStorage.getItem("token-" + region),
    };
    
    const client = new S3Client({
        region,
        credentials: fromCognitoIdentityPool({
            clientConfig: { region },
            identityPoolId : COGNITO[region].cogPoolId,
            logins: loginData
        })
    });
    return client;
}

export const S3Upload = async (bucket, key, data, ctype) => {
    var ret = {};
    const client = getS3Client();
    const command = new PutObjectCommand({
        Bucket: bucket,
        Key: key,
        Body: data,
        ContentType : ctype
    });
    
    try {
        const response = await client.send(command);
        console.log(response);
    } catch (err) {
        console.error(err);
    }
    return ret;
}

export const S3Download = async (path) => {
    var ret = {};
    const myBucket = new AWS.S3({
        params: { Bucket: "rayapps"},
        region: "ap-northeast-2",
    })
    return ret;
}

export const S3Keys = async (bucket, key) => {
    var ret = {};
    const client = getS3Client();
    const command = new ListObjectsCommand({
        Bucket: bucket,
        Delimiter: '/',
        Prefix: key
    });
    
    try {
        const response = await client.send(command);
        return response;
    } catch (err) {
        console.error(err);
    }
    return ret;
}
