import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { history } from '../../history';
import { ProgramConstant } from '../../redux/reducers';
import LabProductAdd from '../../components/labproduct/LabProductAdd';
import LabProductForms from '../../components/labproduct/LabProductForms';
import { MLButton } from '../../components/common/MLButton';
import LabProductConsent from '../../components/labproduct/LabProductConsent';
import { MLPanelNoData } from '../../components/common/MLPanel';
import { InfoAction } from '../../redux/actions';
import LabProductCategoryProperty from '../../components/labproduct/LabProductCategoryProperty';
import LabProductCategoryAttr from '../../components/labproduct/LabProductCategoryAttr';
import LabProductCategoryAdd from '../../components/labproduct/LabProductCategoryAdd';
import _ from 'lodash';
import { Badge } from 'react-bootstrap';
import { MLToolTip } from '../../components/common/MLToolTip';
import { COUNTRIES } from '../../constants/Country';

const LabProductCategoryInfoPage = ({ }) => {
	const dispatch = useDispatch();

	const [t] = useTranslation(['translation']);
	const { _id } = useParams("");
	const [loading, setLoading] = useState(false);
	const [readonly, setReadOnly] = useState(true);
	const [prd, setPrd] = useState({});
	const [prds, setProducts] = useState([]);

	useEffect(() => {
		dispatch({
			type: ProgramConstant.SET_PAGE, page: {
				code: "b2bproductcategoryinfo",
				view: "summary",
				title: "Product Category",
				small: t("Product Category 정보"),
				options: [
				]
			}
		})

		getInfo();
	}, []);

	const getInfo = async () => {
		setLoading(true);
		const ret = await InfoAction.callDB({
			type: "scan", region: "ap-northeast-2", params: {
				TableName: "rayteams-product",
				FilterExpression: "#_id=:_id and #sk=:sk",
				ExpressionAttributeNames: { "#_id": "_id", "#sk": "sk" },
				ExpressionAttributeValues: { ":_id": _id, ":sk": "category" },
			}
		});
		setPrd(ret.length > 0 ? ret[0] : {});

		let retprds = await InfoAction.callDB({
			type: "scan", region: "ap-northeast-2", params: {
				TableName: "rayteams-product",
				FilterExpression: "#category=:category",
				ExpressionAttributeNames: { "#category": "category" },
				ExpressionAttributeValues: { ":category": _id },
			}
		});
		retprds = _.orderBy(retprds, ["order"], ["asc"]);
		setProducts(retprds);
		setLoading(false);
	}

	return <div className='p-4'>
		<div className='card mb-3'>
			<div className='card-header pb-0'>
				<div className='d-flex justify-content-between align-items-center'>
					<h4 className='mb-0'>{prd.title}</h4>
					<div className="d-flex">
						<MLButton options={{
							label: t("list"),
							color: 'light',
							action: () => history.push('/LabProductCategoryPage')
						}} css='ms-1' />
					</div>
				</div>
			</div>
			<div className='card-body'></div>
		</div>
		<div className='mt-2'>
			<div className='row'>
				<div className='col-6'>
					<div className='mb-4'>
						<div className='d-flex justify-content-between align-items-center mb-2'>
							<div className='fw-bolder'>{t("Info.")}</div>
							<MLButton options={{
								code: "prodcate-infoedit",
								label: t("Edit"),
								color: 'warning',
								size: 'sm',
								action: () => setReadOnly(false)
							}} />
						</div>
						<LabProductCategoryAdd
							readonly={readonly}
							callBackCancel={() => setReadOnly(true)}
							callbackUpdate={() => setReadOnly(true)}
							callBackInfo={(x) => setPrd(x)} />
					</div>
					<div className='d-flex justify-content-between align-items-center mb-2'>
						<div className='fw-bolder'>{t("Products")}</div>
						<MLButton options={{
							code: "prodcate-addprod",
							label: t("상품 추가"),
							color: 'primary',
							size: 'sm',
							action: () => history.push('/LabProductAddPage')
						}} />
					</div>
					<div className='card'>
						{prds.length > 0 && <div className='list-group'>
							{prds.map((x, idx) => <div key={idx}
								onClick={() => history.push('/LabProductInfoPage/' + x._id)}
								className='cursor-pointer list-group-item d-flex align-items-center justify-content-between'>
								<div>
									<div className='text-black fw-bolder'>{x.title}</div>
									<div className='small'>
										{!x?.pubcountry || x?.pubcountry?.length === 0 ? <>
											<div>
												{t("출시 국가")} : {t("제한 없음")}
											</div>
										</> : <>
											<MLToolTip
												tooltip={<div className='text-start'>
													{x?.pubcountry.map(cc => <div key={cc}>
														{COUNTRIES.find(x => x.countryCode === cc)?.name ?? cc}
													</div>)}
												</div>}
											>
												<div>
													{t("출시 국가")} : {_.chunk(x?.pubcountry, 2)[0].join(", ")} {x?.pubcountry?.length > 2 ? "..." : ""}
												</div>
											</MLToolTip>
											
										</>}
									</div>
								</div>


								<div>
									<Badge bg={x.ispublished ? "success" : "secondary"}>
										{x.ispublished ? t("published") : t("Unpublished")}
									</Badge>
									<div className='small text-end'>
										<span>{t("order")} : </span>
										<span>{x.order}</span>
									</div>
								</div>

							</div>)}
						</div>}
					</div>
					{prds.length === 0 && <MLPanelNoData />}
				</div>
				<div className='col-6'>
					<div className='mb-4'>
						<LabProductCategoryProperty category={prd} />
					</div>
					<div>
						<LabProductCategoryAttr category={prd} />
					</div>
				</div>
			</div>
		</div>
	</div>
}

const mapState = (state) => {
	return {};
};

const mapDispatch = (dispatch) => ({

});

export default connect(mapState, null)(LabProductCategoryInfoPage);
