import axios from "axios";
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from "react";
import { BUTTONS, COLORS, INPUTS, SPAN } from '../../constants/FromInputs';
import { MLButton } from '../common/MLButton';
import { UploadFileS3, UploadS3 } from "../../libs/s3";
import { MLText } from "../common/MLInput";
import { MLForm } from "../common/MLForm";
import MLTable from "../common/MLTable";
import { ProgramConstant } from "../../redux/reducers";
import { COUNTRIES } from "../../constants/Country";
import { LANGUAGE } from "../../constants/RMConstants";
import { RTAudit } from '../../libs/audit';
const VIEW_TYPE = {
    DEFAULT: 'default',
    LANGKEYS: 'lang_keys',
    TRANS: 'trans',
    TRANSUSER : 'transuser'
};

const getEnv = () => {
    if(location.href.indexOf("localhost") > -1 || location.href.indexOf("127.0.0.1") > -1){
        return "dev-local";
    }else if(location.href.indexOf("raydevelop") > -1){
        return "development";
    }
    return "production";
}

const ServiceConfigEmailSetting = ({ UpdateApplication }) => {
	const [t] = useTranslation(['translation']);
	const dispatch = useDispatch();
    const partnerKey = localStorage.getItem('partnerKey') || '';
    const [data, setData] = useState({});
    const [err, setErr] = useState("");
    const [edit, setEdit] = useState(false);
    const [newitem, setNewItem] = useState({
        key : "",
        value : "",
    });
    const { _id = process.env.REACT_APP_COMPANY } = useParams();

    useEffect(() => {
        getConfig();
    }, []);

    const getConfigPath = () => {
        if(getEnv() === "production"){
            return "https://ray-data.s3.ap-northeast-2.amazonaws.com/rayrnd/rayteams-service/"+ _id + "/config.json";
        }else{
            return "https://ray-data-development.s3.ap-northeast-2.amazonaws.com/rayrnd/rayteams-service/"+ _id + "/config.json";
        }
    }

    const getConfig = async () => {
        try{
            const conf = await axios.get(getConfigPath());
            setData(conf?.data || {});
            dispatch({ type : ProgramConstant.SET_B2B_SERVICE, item : conf?.data || {} });
        }catch{
            setData([]);
        }
    }

    const update = async () => {
        const conf = await axios.get(getConfigPath());
        const ori_config = conf?.data || {};
        const dt = new Date().getTime();
        const bucket = getEnv() === "production" ? 'ray-data' : 'ray-data-development';
        let newData = {...data};
        if(data.servicefavicon?.path){
            if(await UploadFileS3(bucket, data.servicefavicon, 'rayrnd/rayteams-service/' + _id + '/fav-' + dt +'.' + data.servicefavicon.path.split('.').pop())){
                newData = {
                    ...newData, 
                    servicefavicon : 'https://' + bucket + '.s3.ap-northeast-2.amazonaws.com/rayrnd/rayteams-service/' 
                        + _id
                        + '/fav-' + dt +'.' + data.servicefavicon.path.split('.').pop()
                }
            }
        }
        if(data.servicelogo?.path){
            if(await UploadFileS3(bucket, data.servicelogo, 'rayrnd/rayteams-service/' + _id + '/logo-' + dt +'.' + data.servicelogo.path.split('.').pop())){
                newData = {
                    ...newData, 
                    servicelogo : 'https://' + bucket + '.s3.ap-northeast-2.amazonaws.com/rayrnd/rayteams-service/' 
                        + _id
                        + '/logo-' + dt +'.' + data.servicelogo.path.split('.').pop()
                }
            }
        }
        await UploadS3(
            getEnv() === "production" ?  "ray-data" : "ray-data-development",
            "rayrnd/rayteams-service/"+ _id + "/config.json",
            {...ori_config, ...newData}
        );
        await getConfig();
        RTAudit({ lvl: 4, msg: 'Update Email Configuration'});
        setEdit(false);
    }

    const controls = [
		{
			type : INPUTS.TEXT,
			options : {
				label : t('SMTP 서버'),
				name : 'email_smtpserver'
			}
		},
		{
			type : INPUTS.TEXT,
			options : {
				label : t('SMTP Port'),
				name : 'email_smtpport',
			}
		},
		{
			type : INPUTS.TOGGLE,
			options : {
				label : t('TLS/SSL'),
				name : 'email_ssl',
				truemsg : t('사용'),
				falsemsg : t('사용 안함')
			}
		},
		{
			type : INPUTS.EMAIL,
			options : {
				label : t('사용자 이름(Email)'),
				name : 'email_smtpemail',
			}
		},
		{
			type : INPUTS.PASSWORD,
			options : {
				label : t('사용자 비밀번호'),
				name : 'email_smtppassword',
			}
		},
		{
			type : SPAN.VERTICAL,
			options : {
				size : 20,
			}
		},
		{
			type : BUTTONS.BUTTONS, 
			options : { },
			buttons : [{
				type : BUTTONS.NORMALBUTTON, 
				options : { 
					label : t('적용'), 
					color : COLORS.PRIMARY,
                    action : () => update()
				}
			},{
				type : BUTTONS.NORMALBUTTON, 
				options : { 
					label : t('취소'), 
					color : COLORS.LIGHT,
                    action : () => setEdit(!edit)
				}
            }]
		}
    ]

    return <div>
        <div className="d-flex justify-content-between mb-2">
            <h5>{t('Email SMTP Configuration')}</h5>
            <div>
                {!edit && <MLButton options={{
                    code: "ptn-email",
                    label : t('편집'), 
                    color : COLORS.PRIMARY,
                    action : () => setEdit(!edit)
                }} />}
            </div>
        </div>
        <MLForm controls={controls} readonly={!edit} data={data} nocard={true} onChanged={(e) => { setData(e) }} />
    </div>
}

export default ServiceConfigEmailSetting;
