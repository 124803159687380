import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { XName, XEmail } from '../common/MLString';
import { COGNITO } from '../../libs/cogInfo'
import { COGNITO_GRAPHY } from '../../libs/cognito-graphy';
import { idData } from '../../libs/db'
import { FaCrown, FaStore, FaUserTie } from 'react-icons/fa';
import { connect, useDispatch } from 'react-redux';
import { ProgramConstant } from '../../redux/reducers';
import { aws_con } from '../../libs/db';
import AWS from 'aws-sdk';
import { queryData } from '../../libs/db';

const PartnerUser = ({ _id, allusers, region = "ap-northeast-2", card = false }) => {

	const dispatch = useDispatch();

	const [ info, setInfo ] = useState({});
	const [ edit, setEdit ] = useState(false);
	const [ data, setData ] = useState({
		name : "",
	});
	const [ processes, setProcess ] = useState(false);

	useEffect(() => {
		if(_id){
			getUser();
		}
	},[_id]);

	const getCognito = (region) => {
		if(process.env.REACT_APP_COMPANY === 'graphy'){
			console.log(COGNITO_GRAPHY);
			return COGNITO_GRAPHY[region];
		}else{
			return COGNITO[region];
		}
	}

	const getUser = async () => {
		const user = allusers.find(x => x._id === _id && x.email);
		if(user){
			setInfo(user);
			return;
		}

		setProcess(true);

		let userData = {};
		const retUser = await queryData("rayteams-user", {
			_id : {
				ComparisonOperator: "EQ", 
				AttributeValueList: [_id]
			},
			sk : {
				ComparisonOperator: "EQ",
				AttributeValueList: ["info"]
			}
		});

		if(retUser.Count == 0) {
			const retCNUser = await queryData("rayteams-user", {
				_id : {
					ComparisonOperator: "EQ", 
					AttributeValueList: [_id]
				},
				sk : {
					ComparisonOperator: "EQ",
					AttributeValueList: ["info"]
				}
			}, "ap-east-1");
			if(retCNUser.Count > 0) {
				userData = retCNUser.Items[0];
				if(!retCNUser.Items[0]?.region && retCNUser.Items[0]?.groupId) {
					const retCNGroup = await queryData("rayteams-group", {
						_id : {
							ComparisonOperator: "EQ", 
							AttributeValueList: [retCNUser.Items[0].groupId]
						},
						sk : {
							ComparisonOperator: "EQ",
							AttributeValueList: ["info"]
						}	
					}, "ap-east-1");
					region = retCNGroup.Items[0]?.region || "ap-northeast-2";
				} else {
					region = retCNUser.Items[0]?.region || "ap-northeast-2";
				}
			} else {
				region = "ap-northeast-2"
			}
		} else {
			if(!retUser.Items[0]?.region && retUser.Items[0]?.groupId) {
				const retGroup = await queryData("rayteams-group", {
					_id : {
						ComparisonOperator: "EQ", 
						AttributeValueList: [retUser.Items[0].groupId]
					},
					sk : {
						ComparisonOperator: "EQ",
						AttributeValueList: ["info"]
					}	
				})
	
				region = retGroup.Items[0]?.region || "ap-northeast-2";
			} else {
				region = retUser.Items[0]?.region || "ap-northeast-2";
			}
		}

		aws_con();
		
		const params = {
			Username: _id,
			UserPoolId: getCognito(region).poolId,
		};

		AWS.config.update({ region: region == "ap-east-1" ? "ap-northeast-2" : region });

		var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
		try {
			var item = await cognitoidentityserviceprovider
				.adminGetUser(params)
				.promise();
			var ret = {};
			item?.UserAttributes.map((x) => {
				ret[x.Name.replace("custom:", "")] = x.Value;
			});
			ret._id = ret.sub;
            //const uinfo = await idData("rayteams-user", ret._id);
            if(userData){
                setInfo({...ret, ...userData});
                dispatch({ type : ProgramConstant.GETUSER, user : {...ret, ...userData} });
            }else{
                setInfo(ret);
                dispatch({ type : ProgramConstant.GETUSER, user : ret });
            }
		} catch (err) {
			console.log(err);
		}

		setProcess(false);
	};

  	return <>
		{processes && <div className="d-flex align-items-center fw-bolder fs-4">
			...
		</div>}
		{!processes && <div>
			<strong>{info.grouptype === "partner" ? info?.name : XName(info?.name)}</strong>
			<span className='ms-2 text-gray-600'>{info.grouptype === "partner" ? info?.email : XEmail(info?.email)}</span>
		</div>}
	</>
}

const mapState = (state) => {
	const allusers =  state.ProgramReducer.allusers;
	return { allusers };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(PartnerUser);
