import axios from 'axios';
import { JWT } from '../../config';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import RMChartDailyCall from '../../components/usage/RMChartDailyCall';
import RMUsageAppList from '../../components/usage/RMUsageAppList';
import RMUsageList from '../../components/usage/RMUsageList';
import WidgetBarChartAppUsage from '../../components/widgets/dashboard/WidgetBarChartAppUsage';
import WidgetListAppUsage from '../../components/widgets/dashboard/WidgetListAppUsage';
import WidgetPieChartAppUsage from '../../components/widgets/dashboard/WidgetPieChartAppUsage';
import WidgetTopRateUsage from '../../components/widgets/dashboard/WidgetTopRateUsage';
import { MLComboSingle } from '../../components/common/MLInput';
import { UsageConstant } from '../../redux/reducers';
import { fetchData } from '../../libs/db';
import { SEARCHPERIOD } from '../../constants/RMConstants';

const UsagePage = ({stats}) => {
	const dispatch = useDispatch();
    const [period, setPeriod] = useState("days");
    const [loading, setLoading] = useState(true);
    const periodlist = SEARCHPERIOD;

    useEffect(() => {
        getData();
    }, [period])

    const getData = async () => {
		setLoading(true);
        
        var scanfilter = {};
        if(period === "all" || period === "months") {
            scanfilter = {
                sk: {
                    ComparisonOperator: "BEGINS_WITH",
                    AttributeValueList: ["M:"],
                }
            }
        } else {
            scanfilter = {
                sk: {
                    ComparisonOperator: "BEGINS_WITH",
                    AttributeValueList: ["D:"],
                }
            }
        }

		try {
            const ret = await fetchData("rayteams-manager-actionlog", scanfilter);
            if (ret.Items) {
                dispatch({ type: UsageConstant.GET_STATS, items: ret.Items });
            } else {
                dispatch({ type: UsageConstant.GET_STATS, items: [] });
            }
		} catch(err) {
			console.log(err);
		}
		setLoading(false);
    }

  	return (
        <>
        <div className="header align-items-stretch d-block" style={{position: "initial"}}>
            <div className="d-flex w-100 justify-content-between p-5 align-items-center">
                <div className="d-flex align-items-center text-dark fw-bolder fs-2 my-1">Usages</div>
                <div className="d-flex align-items-center">
                <MLComboSingle options={{
					name : "period",
					value : period,
					list : periodlist,
				}} css={"w-150px"} handleChanged={(e) => setPeriod(e.target.value) } />
                </div>
            </div>
        </div>
        {stats && <>
            <div className="d-flex flex-column flex-column-fluid">
			    <div className="post p-7">
                    <div className='row'>    
                        <div className='col-12 col-xxl-2'>
                            <div className='row align-items-center g-3 g-xxl-0 mt-5'>
                                <WidgetTopRateUsage period={period} type={"app"} title={"Top Usage App"} /> 
                                
                                <div className='mt-10'>
                                <WidgetTopRateUsage period={period} type={"fun"} title={"Top Usage Function"} /> 
                                </div>
                            </div>
                        </div>
                        <div className='col-sm mb-5'>
                            <WidgetBarChartAppUsage period={period} type={"app"} title={"App " + periodlist.filter(y => y.value === period)[0].label} appname={""} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm mb-5'>
                            <WidgetBarChartAppUsage period={period} type={"func"} title={"Function " + periodlist.filter(y => y.value === period)[0].label} appname={""} />
                        </div>
                        <div className='col-sm mb-5'>
                            <WidgetPieChartAppUsage period={period} type={"app"} title={"APP " + periodlist.filter(y => y.value === period)[0].label} appname={""} /> 
                        </div>
                        <div className='col-sm mb-5'>
                            <WidgetPieChartAppUsage period={period} type={"fun"} title={"Function " + periodlist.filter(y => y.value === period)[0].label} appname={""} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm mb-5'>
                            <WidgetListAppUsage title={"Top 5 App Usage By Group"} groupType={"Group"} type={"App"} period={period} appname={""} appfunc={false}/> 
                        </div>
                        <div className='col-sm mb-5'>
                            <WidgetListAppUsage title={"Top 5 App Usage By Country"} groupType={"Country"} type={"App"} period={period} appname={""} appfunc={false}/>
                        </div>
                        <div className='col-sm mb-5'>
                            <WidgetListAppUsage title={"Top 5 App Usage By Function"} groupType={"Function"} type={"func"} period={period} appname={""} appfunc={false}/>
                        </div>
                    </div>
                </div>
            </div>
        </>}
        {/* <div className='row'>
            <div className='mb-4'>
                <RMChartDailyCall />
            </div>
            <RMUsageAppList />
            <RMUsageList />
        </div> */}
        </>
  	);
}

const mapState = (state) => {
    const logs = state.UsageReducer.logs;
	const dailylogs =  state.UsageReducer.dailylogs;
    const statsdaily = state.UsageReducer.statsdaily;
    const statsmonthly = state.UsageReducer.statsmonthly;
    const stats = state.UsageReducer.stats;
	return { logs, dailylogs, statsdaily, statsmonthly, stats };
};

const mapDispatch = (dispatch) => ({
});

export default connect(mapState)(UsagePage);