import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import axios from 'axios';
import queryString from 'query-string';
import { COGNITO } from '../libs/cogInfo';
var Buffer = require('buffer/').Buffer;

const CogRedirect = ({ user, loginresult }) => {

	const dispatch = useDispatch();
    const [show, setShow] = useState(true);

	useEffect(() => {
        const code = queryString.parseUrl(location.href).query?.code
        if(code){
            getToken(code);
        }
	}, []);

    const getToken = async (code) => {
        const reg = localStorage.getItem("requestGetlogin");
        const appUrl = "http://localhost:3000/CogRedirect";
        const cofInfo = COGNITO[reg];
        const base64data = Buffer.from(code + ":").toString('base64');
        const url = "https://" + (reg === "ap-northeast-2" ? "mylab" : "mylab-prd") + ".auth." + reg + ".amazoncognito.com/oauth2/token";
        const ret = await axios.post(url, "grant_type=authorization_code&client_id=" + cofInfo.appId + "&code=" + code + "&redirect_uri=" + appUrl, {
            headers : {
                "Content-Type" : "application/x-www-form-urlencoded",
                "Authorization" : "Basic " + base64data
            }
        });
        console.log(ret);
        localStorage.removeItem("requestGetlogin");
    }

  	return (
    	<div>
            <h2>CogRedirect</h2>
            <div className='card'>
            </div>
    	</div>
  	);
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;

	return { user };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(CogRedirect);