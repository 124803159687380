import axios from 'axios';
import { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { connect, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { BiHelpCircle } from 'react-icons/bi';
import { fetchData, delData, updateData, insertData } from '../../libs/db';
import { MLButton } from '../../components/common/MLButton';
import { history } from '../../history';
import { BUTTONS, COLORS } from '../../constants/FromInputs';
import { MLPanelDelete } from '../../components/common/MLPanel';
import { MLComboSingle, MLNumber } from '../../components/common/MLInput';
import { COUNTRIES, getRegion } from '../../constants/Country';
import { useTranslation } from 'react-i18next';
import { RTAudit } from '../../libs/audit';
const ManagerGroupAddPage = () => {
	const dispatch = useDispatch();
	const [t] = useTranslation(['translation']);
	const { _id } = useParams("");
	const [help, setHelp] = useState(window.localStorage.getItem("helpmsg") === "N" ? false : true);
	const [data, setData] = useState({});
	const [process, setProcess] = useState(false);
	const [err, setErr] = useState("");
	const [delConfirm, setDelConfirm] = useState(false);
	const [userData, setUserData] = useState({});
	const [selectedCountries, setSelectedCountries] = useState([]);
	const [selectCountry, setSelectCountry] = useState("");

	useEffect(() => {
		init();
	}, []);

	useEffect(() => {
		window.localStorage.setItem("helpmsg", help ? "Y" : "N");
	}, [help])

	const init = async () => {
		if (_id) {
			await getManagerGroupData();
		}
	}

	const getManagerGroupData = async () => {
		const ScanFilter = {
			_id: {
				ComparisonOperator: "EQ",
				AttributeValueList: [_id],
			},
			sk: {
				ComparisonOperator: "EQ",
				AttributeValueList: ["info"],
			},
		}
		const ret = await fetchData("rayteams-manager-group", ScanFilter);
		if (ret.Items.length > 0) {
			setData(ret.Items[0]);
			setUserData({ ...userData, mGroupId: _id, groupName: ret.Items[0]?.name });
			setSelectedCountries(ret.Items[0]?.countries ? ret.Items[0].countries : [])
		} else {
			history.push("/ManagerGroup");
		}
	}

	const createGroup = async () => {
		setProcess(true);
		//Manager Group create
		const okCountries = []
		selectedCountries.map(x => {
			okCountries.push({ countryCode: x.countryCode, countryName: x.countryName, region: x.region })
		})
		const managerGroupId = v4();
		const newManagerGroup = {
			_id: managerGroupId,
			sk: "info",
			name: data.name,
			created: new Date().getTime(),
			type: "",
			countries: okCountries
		};

		await insertData("rayteams-manager-group", newManagerGroup);

		setProcess(false);

		history.push("/ManagerGroup");
		RTAudit({ lvl: 6, msg: '[' + data.name + '] Create Manager Group'});
	}

	const delGroup = async () => {
		setProcess(true);

		await delData("rayteams-manager-group", {
			_id: _id,
			sk: "info",
		});
		setProcess(false);
		history.push("/ManagerGroup");
		RTAudit({ lvl: 6, msg: 'Delete Manager Group'});
	}

	const updateGroup = async () => {
		setProcess(true);
		const okCountries = []
		selectedCountries.map(x => {
			okCountries.push({ countryCode: x.countryCode, countryName: x.countryName, region: x.region })
		})

		await updateData("rayteams-manager-group", {
			_id: _id,
			sk: "info",
		}, {
			countries: okCountries
		});

		setProcess(false);

		history.push("/ManagerGroup");
		RTAudit({ lvl: 6, msg: 'Update Manager Group'});
	}

	return <>
		{err && <div className="alert alert-warning shadow">{t(err)}</div>}
		{delConfirm && <MLPanelDelete title={data.name + t(" 파트너를 삭제하시겠습니까?")} contents={<>
			<div className='text-white'>{t("파트너 삭제는 즉시 적용됩니다.")}</div>
		</>} buttons={[
			{
				type: BUTTONS.NORMALBUTTON,
				options: {
					label: t("Cancel"),
					color: COLORS.LIGHT,
					action: () => setDelConfirm(false),
				}
			},
			{
				type: BUTTONS.NORMALBUTTON,
				options: {
					label: t("Remove permanently"),
					color: COLORS.WARNING,
					action: () => delGroup(),
				}
			},
		]} />}
		{!delConfirm && <>
			<div className="header align-items-stretch d-block" style={{ position: "initial" }}>
				<div className="d-flex w-100 justify-content-between p-4 align-items-center">
					<div className="d-flex align-items-center text-dark fw-bolder fs-3 pt-3">{t("Manager Group")}</div>
					<div className="d-flex align-items-center">
						<BiHelpCircle size="24" className="ms-2 text-primary cursor-pointer" onClick={() => setHelp(!help)} />
					</div>
				</div>
			</div>
			<div className='card-body p-4'>
				<div className="card mb-2">
					<form>
						<div className="card-body row">
							<div className={"col-6"}>
								<div className="mb-4">
									<label className="form-label fs-3">{t("Manager Group Information")}</label>
									{help && <div className="alert alert-dark mb-2">
										{t("Manager Group(법인) 정보를 입력합니다.")}<br />
										{t("Manager Group Key는 한번 생성하면 변경이 불가합니다.")}
									</div>}
								</div>
								<div className="mb-5">
									<label className="form-label required fw-bolder">Manager Group Name</label>
									{help && <div className="alert alert-dark mb-2">
										{t("Manager Group Name 은 법인의 이름 입니다.")}<br />
									</div>}
									{_id && <div className='fw-bolder fs-4 text-gray-500'>{data.name}</div>}
									{!_id && <input
										type="text"
										className="form-control form-control-solid"
										placeholder={t("Enter Manager Group Name")}
										value={data.name}
										onChange={(e) => setData({ ...data, name: e.target.value })} />}
								</div>
								<div className="mb-5">
									<label className="form-label fw-bolder">{t("Managed Country")}</label>
									{help && <div className="alert alert-dark mb-2">
										{t("Managed Country는 법인이 관리하는 국가 입니다.")}<br />
										{t("아무것도 선택하지 않으면, 모든 국가를 관리하는 그룹이 됩니다.")}
									</div>}
									<div className="card-body p-0">
										<div>
											<div>
												<div className='d-flex justify-content-between'>
													<MLComboSingle options={{
														value: selectCountry,
														list: COUNTRIES.map(x => {
															return { value: x.countryCode, label: t(x.name) }
														})
													}} handleChanged={(e) => { setSelectCountry(e.target.value); }} css="w-100 me-2" />
													<MLButton options={{
														label: t("Add"),
														color: COLORS.PRIMARY,
														action: () => setSelectedCountries([...selectedCountries, { countryCode: selectCountry, region: getRegion(selectCountry)?.region, countryName: COUNTRIES.find(f => f.countryCode === selectCountry)?.name }])
													}} css="me-2" />
												</div>
											</div>
										</div>
										<div className="pt-5">
											<div className="mb-5">
												<ul className='list-group'>
													{selectedCountries && selectedCountries.map((x, idx) => <li className='list-group-item  d-flex justify-content-between align-items-center' key={idx}>
														{<>
															{x.countryCode && <>
																[{x.countryCode}] {x?.countryName}
																<span className='btn-xs btn text-danger shadow-none' onClick={() => setSelectedCountries(selectedCountries.filter(u => u.countryCode !== x.countryCode))}>remove</span>
															</>}
														</>}
													</li>)}
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-6">

							</div>
						</div>
					</form>
					<div className="card-header">
						{/* <div className='card-title h5'>
							{_id ? "Edit " + data.name + " Manager Group information" : "Add a Manager Group"}
							<small className='ms-2 text-gray-500'></small>
						</div> */}
						<div className="card-toolbar text-end">
							<MLButton options={{
								label: t("Cancel"),
								color: COLORS.DEFAULT,
								action: () => _id ? history.push("/ManagerGroupInfo/" + _id) : history.push("/ManagerGroup"),
							}} css="me-2" />
							{/* {_id && <MLButton options={{
								label : "Remove",
								color : COLORS.DANGER,
								action : () => setDelConfirm(true),
							}} css="me-2" />} */}
							<MLButton options={{
								label: _id ? t("Update") : t("Create"),
								color: COLORS.PRIMARY,
								action: () => _id ? updateGroup() : createGroup(),
								needValid: true
							}} valid={_id ?
								(!!(data.name && selectedCountries) && !process) :
								(!!(data.name && selectedCountries) && !process)} />
						</div>
					</div>
				</div>
			</div></>}

	</>
}

const mapState = (state) => {
	return {};
};

const mapDispatch = (dispatch) => ({

});

export default connect(mapState, null)(ManagerGroupAddPage);
