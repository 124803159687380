import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { MLIconFile } from '../icon/MLIcon';
import { MLFileSize } from '../common/MLCommon';
import { MLPanelNoData } from '../common/MLPanel';

const RMProjectAttachments = ({ projectId, project }) => {
    const [t] = useTranslation();
    const [hasNoFile, setHasNoFile] = useState(false);

    useEffect(() => {
        setHasNoFile(!project?.attachments || project?.attachments?.length === 0);
    }, [project?.attachments])

    return <div className="position-relative h-100 ml-container pt-4">
        {project?._id === projectId && <div
            className={!hasNoFile ? "project-attachments-wrap d-flex flex-column position-relative" : ""}>
            {project.attachments?.length > 0 && <div className="rounded mb-4">
                <div>
                    <div className="d-flex flex-column rayfiles">
						<>
						{project?.attachments.map(x => <React.Fragment key={x.uk}>
							<div className='list-group-item p-0' style={{ cursor: "initial" }}>
								<div className="p-4" style={{ position: "relative", zIndex: 2 }} >
									<div className="d-flex align-items-center">
										<div>
											{<MLIconFile size={40} />}
										</div>
										<div>
											<div className="ms-2 fs-6 flex-fill fw-bolder text-break ray-attach-text">
												{x?.file?.name || x?.name}</div>
											<div className='ms-2'>
												{/* {<MLUserById _id={data?.creator} css="me-2" />} */}
												{x?.creator}
												{MLFileSize(x?.file?.size || x.size)}
												<span className="text-muted inline ms-2">( {moment(x.created).format('LLL')} )</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</React.Fragment>)}
						</>
                    </div>
                </div>
            </div>}
            {hasNoFile && <MLPanelNoData hgt="80" />}
        </div>}

    </div>
};


export default connect(null)(RMProjectAttachments);