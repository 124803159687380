import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { MLComboSingle, MLText } from '../../components/common/MLInput';
import { MLPanelLoading } from '../../components/common/MLPanel';
import { LANGUAGE } from '../../constants/RMConstants';
import { ProgramConstant, PaymentConstant } from '../../redux/reducers';
import { fetchData, putData, updateData, delData } from '../../libs/db';
import { MLButton } from '../../components/common/MLButton';
import { useTranslation } from 'react-i18next';
import { RTAudit } from '../../libs/audit';

const ManagerFeedbacks = ({ region, usersub, page }) => {
	const dispatch = useDispatch();
	const [t] = useTranslation(['translation']);
	const [data, setData] = useState({});
	const [feedbacks, setFeedbacks] = useState([]);
	const [newItemLang, setNewItemLang] = useState("");
	const [itemlang, setItemLang] = useState([]);
	const [newItem, setNewItem] = useState("");
	const [newItemList, setNewItemList] = useState([]);
	const [items, setItems] = useState([]);
	const [loading, setLoading] = useState(false);
	const [itemLoading, setItemLoading] = useState({ lang: "", show: false });
	const [view, setView] = useState("list");
	const { v4 } = require("uuid");

	useEffect(() => {
		dispatch({
			type: ProgramConstant.SET_PAGE, page: {
				code: "feedback",
				view: "summary",
				title: "User feedbacks",
				small: t("User feedbacks 관련 정보"),
				options: [
				]
			}
		})
	}, []);

	useEffect(() => {
		getData();
	}, []);

	useEffect(() => {
		if (data?._id && data?.sk)
			getItemData();
	}, [data?._id, data?.sk]);

	const getData = async () => {
		try {
			const ret = await fetchData("rayteams-manager-contents", {
				sk: {
					ComparisonOperator: "BEGINS_WITH",
					AttributeValueList: ["mf:"],
				},
			});
			console.log(ret.Items);
			setFeedbacks(ret.Items.filter(x => x.sk.split(":").length === 2));
		} catch (e) {
		}
	}

	const getItemData = async () => {
		//setLoading(true);
		try {
			const ret = await fetchData("rayteams-manager-contents", {
				sk: {
					ComparisonOperator: "BEGINS_WITH",
					AttributeValueList: [data.sk + ":"],
				},
			});
			setItems(ret.Items);
			setItemLang([...new Set(ret.Items.map(x => x.lang))]);
		} catch (e) {
			console.log(e);
		}
		//setLoading(false);
	}

	const save = async () => {
		const _id = v4();
		const time = new Date().getTime();
		const put = {
			_id: _id,
			sk: "mf:" + time,
			...data,
			created: time,
			creator: usersub
		}
		await putData("rayteams-manager-contents", put);
		getData();
		setData(put);
		setView("edit");
		RTAudit({ lvl: 4, msg: 'Save User Feedback' });
	}

	const addnewitemlist = () => {
		if (!newItem.trim())
			return;
		setNewItemList([...new Set([...newItemList, newItem])]);
		setNewItem("");
		RTAudit({ lvl: 4, msg: 'Added Language item to User Feedback' });
	}

	const addLangItem = async (val) => {
		const time = new Date().getTime();
		const put = {
			...val,
			vote: 0,
			created: time,
			creator: usersub
		}
		//console.log(put);
		await putData("rayteams-manager-contents", put);
		getItemData();
	}

	const removeLangItem = async (key, lang) => {
		await updateData("rayteams-manager-contents", key, { isdel: true });
		getItemData();
		RTAudit({ lvl: 4, msg: 'Removed Language Item to User Feedback' });
	}

	const removeLang = async (lang) => {
		for (const im of items.filter(x => x.lang === lang)) {
			await delData("rayteams-manager-contents", {
				_id: im._id,
				sk: im.sk
			})
		}
		getItemData();
		RTAudit({ lvl: 4, msg: 'Removed Language to User Feedback' });
	}

	const addLang = async () => {
		if (newItemLang === "") {
			alert(t("언어 없음"));
			return;
		}
		if (newItemList.length === 0) {
			alert(t("아이템 없음"));
			return;
		}
		let i = 0;
		for (const im of newItemList) {
			const time = new Date().getTime();
			const put = {
				item: im,
				lang: newItemLang,
				order: i,
				vote: 0,
				created: time,
				isdel: false,
				creator: usersub
			}
			await updateData("rayteams-manager-contents", {
				_id: data._id,
				sk: data.sk + ":" + newItemLang + ":" + time,
			}, put);
			i++;
		}
		getItemData();
		setNewItemLang("");
		setNewItem("");
		setNewItemList([]);
		RTAudit({ lvl: 4, msg: 'Added Language to User Feedback' });
	}

	const remove = async () => {
		if (!confirm("정말?"))
			return;
		for (const im of items) {
			await delData("rayteams-manager-contents", {
				_id: im._id,
				sk: im.sk
			})
		}
		await delData("rayteams-manager-contents", {
			_id: data._id,
			sk: data.sk
		})
		getData();
		setData({ title: '' });
		setView("list");
		RTAudit({ lvl: 4, msg: 'Removed User Feedback' });
	}

	const update = async () => {
		await updateData("rayteams-manager-contents", {
			_id: data._id,
			sk: data.sk
		}, { title: data.title })
		getData();
		//getItemData();
		RTAudit({ lvl: 4, msg: 'Updated User Feedback' });
	}

	return <div className='p-4'>
		<div className="card">
			<div className="card-header d-flex justify-content-between align-items-center">
				<div className="card-label fw-bold text-gray-800 fs-5">{items.length}</div>
				<div className="d-flex">
					{view === "list" && <MLButton options={{
						code: "fb-add",
						label: t("Add"),
						color: "primary",
						size: "md",
						action: () => { setData({ title: "" }); setView("new"); }
					}} />}
					{view === "new" && <>
						<MLButton options={{
							label: t("Save"),
							color: "primary",
							size: "md",
							action: () => save()
						}} />
						<MLButton options={{
							label: t("List"),
							color: "dark",
							size: "md",
							action: () => setView("list")
						}} css="ms-1" />
					</>}
					{view === "edit" && <>
						<MLButton options={{
							code: "fb-update",
							label: t("Update"),
							color: "primary",
							size: "md",
							action: () => update()
						}} />
						<MLButton options={{
							code: "fb-remove",
							label: t("Remove"),
							color: "danger",
							size: "md",
							action: () => remove()
						}} css="ms-1" />
						<MLButton options={{
							label: t("List"),
							color: "dark",
							size: "md",
							action: () => setView("list")
						}} css="ms-1" />
					</>}
				</div>
			</div>
			{view === "list" && <table className='table table-row-dashed table-row-gray-300 gy-4 gx-4 mb-0 table'>
				<thead>
					<tr className='fw-bolder fs-6 text-gray-800'>
						<th>{t("Title")}</th>
						<th className='w-250px'>{t("Create Date")}</th>
					</tr>
				</thead>
				<tbody>
					{feedbacks.map(x => <tr>
						<td><span className='cursor-pointer text-primary' onClick={() => { setData(x); setView("edit"); }}>{x.title}</span></td>
						<td>{moment(x.created).format("LLL")}</td>
					</tr>)}
				</tbody>
			</table>}
			{view === "new" && <div className='card-body pt-2'>
				<MLText options={{
					label: t("Title(Alias)"),
					value: data.title,
				}} handleChanged={(e) => setData({ ...data, title: e.target.value })} />
			</div>}
			{view === "edit" && <div className='card-body pt-2'>
				<MLText options={{
					label: t("Title(Alias)"),
					value: data.title,
				}} handleChanged={(e) => setData({ ...data, title: e.target.value })} />
			</div>}
			{loading && <MLPanelLoading />}
		</div>
		{view === "edit" && <div className='p-2'>
			<div className='w-100'>
				<h3 className='pt-2'>{t("Items")}</h3>
				<div className='row'>
					<div className='col-4 p-1'>
						<div className='card'>
							<div className='card-body'>
								<MLComboSingle options={{
									label: t("Language"),
									list: LANGUAGE.map(x => ({ value: x.code, label: x.label })),
									value: newItemLang,
								}} handleChanged={(e) => setNewItemLang(e.target.value)} css="" />
								<div className='w-100'>
									{newItemList.map((x, idx) => <div className='d-flex justify-content-between py-2 w-100 align-items-center'>
										<div className='w-100'><span className='me-2 fw-bolder'>{idx + 1}</span>{x}</div>
										<MLButton options={{
											label: t("Del"),
											color: "danger",
											size: "sm",
											action: () => setNewItemList(newItemList.filter(n => n !== x))
										}} css="" />
									</div>)}
									<MLText options={{
										value: newItem,
										enterSubmit: true
									}} handleChanged={(e) => setNewItem(e.target.value)} enterSubmit={() => addnewitemlist()} css="mt-2" />
								</div>
								<MLButton options={{
									label: t("Save Language"),
									color: "primary",
									action: () => addLang()
								}} css="mt-2 w-100" />
							</div>
						</div>
					</div>
					{itemlang.map(lang => <div className='col-4 p-1'>
						{itemLoading.lang === lang && itemLoading.show && <MLPanelLoading />}
						{!(itemLoading.lang === lang && itemLoading.show) && <div className='card'>
							<div className='card-body'>
								<h4 className='py-2'>{t(LANGUAGE.find(x => x.code === lang)?.label)}</h4>
								<hr />
								{items.filter(x => !x.isdel && x.lang === lang)?.map((x, idx) => <div className='d-flex justify-content-between py-3 w-100 align-items-center'>
									<div className='w-100'><span className='me-2 fw-bolder'>{idx + 1}</span>{x.item}</div>
									<div className='me-2 fw-bolder'>{x.vote || 0}</div>
									<MLButton options={{
										label: t("Del"),
										color: "label-danger",
										size: "sm",
										action: () => removeLangItem({ _id: x._id, sk: x.sk }, lang)
									}} css="" />
								</div>)}
								<input type="text"
									className="form-control form-control-sm"
									onKeyPress={(e) => {
										if (e.key === "Enter") {
											e.preventDefault();
											const newnum = Math.max(...items.filter(j => j.lang === lang).map(n => n.order)) + 1;
											const time = new Date().getTime();
											addLangItem({
												_id: data._id,
												sk: data.sk + ":" + lang + ":" + time,
												item: e.target.value,
												isdel: false,
												lang: lang,
												order: newnum,
											});
											e.target.value = "";
										}
									}} />
								<hr />
								<MLButton options={{
									label: t("Remove Language"),
									color: "danger",
									action: () => removeLang(lang)
								}} css="mt-2 w-100" />
							</div>
						</div>}
					</div>)}
				</div>
			</div>
		</div>}
	</div>

}

const mapState = (state) => {
	const groups = state.AuthReducer.groups;
	const usersub = state.AuthReducer.usersub;
	const page = state.ProgramReducer.page;

	return { groups, usersub, page };
};

const mapDispatch = (dispatch) => ({

});

export default connect(mapState, null)(ManagerFeedbacks);
