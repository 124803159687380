import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { MLText } from '../../components/common/MLInput';
import { MLButton } from '../../components/common/MLButton';
import { MLPanelLoading } from '../../components/common/MLPanel';
import { COLORS } from '../../constants/FromInputs';
import Select from 'react-select';
import MLTable from '../../components/common/MLTable';
import { fetchData } from '../../libs/db';
import { COUNTRIES } from '../../constants/Country';
import { MLPanelNoData } from '../../components/common/MLPanel';

import { ProgramConstant, NotifiCationConstant } from '../../redux/reducers';


const NotifyEmailPage = ({ page, notifications }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
	const [search, setSearch] = useState("");
	const [modal, setModal] = useState({
		show : false
	});
	const [items, setItems] = useState([]);
    useEffect(() => {
		dispatch({ type : ProgramConstant.SET_PAGE, page : { 
			code : "notifyemail", 
			view : "summary", 
			title : "Notification Email", 
			small : "이메일 노티 관련 정보", 
			options : [
			]
		}})
	}, []);

    useEffect(() => {
        getData();
	}, []);

	useEffect(() => {
		genData();
	}, [search, notifications]);

    const getData = async () => {
		setLoading(true);
		try{
			const ret = await fetchData("rayteams-manager-notification", {});
			dispatch({ type: NotifiCationConstant.GET_ALL_NOTIFICATIONS, items: ret.Items });
		}catch(err){
			console.log(err);
		}
		setLoading(false);
    }

	const genData = () => {
		setItems(search ? notifications.filter(x => x?.title.indexOf(search) > -1) : (notifications || []));
	}

    const headers = [
        { link : "_id", linkpath : "/Board", value : "title", label : "Title", css : "text-start", style : { width : 450 } },
		{ value : "type", label : "Type", css : "text-end", type : "BOARDTYPES" }
    ]

	console.log(items);

  	return <div className='p-4'>
		<div className="card">
			<div className="card-header d-flex justify-content-between align-items-center">
				<div className="card-label fw-bold text-gray-800 fs-5">{items.length}</div>
				<div className="d-flex">
					<MLButton options={{
						label : 'Email Noti 생성',
						color : COLORS.PRIMARY,
						size : 'md',
						action : () => setModal({ show : true }),
					}} />
				</div>
			</div>
			{items.length > 0 && <>
				<MLTable 
					headers={headers} 
					items={items} 
					nocard={true}
					noheader={true} />
			</>}
			{items.length === 0 && <MLPanelNoData />}
			{loading && <MLPanelLoading />}
        </div>
		{/* <RMModalAddBoard 
			modalData={modal} 
			callbackClose={() => setModal({ show : false })} /> */}
    </div>
}


const mapState = (state) => {
	const page = state.ProgramReducer.page;
    const notifications = state.NotificationReducer.notifications;
	return { page, notifications };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, null)(NotifyEmailPage);