import axios from 'axios';
import { AppConfig, JWT } from '../../config';
import { BoardConstant } from '../reducers/BoardReducer';

const getAPI = () =>{
    const env = (location.href.indexOf("localhost") > -1 || location.href.indexOf(".raydevelop.com") > -1) ? "development" : "production";
    if(process.env.REACT_APP_COMPANY === 'graphy'){
        return "https://api-manager-contents." + AppConfig().domain;
    }else{
        if(env === "production"){
            return "https://api-manager-contents.rayteams.com";
        }else{
            return "https://api-manager-contents.raydevelop.com";
        }
    }
};

const GetBoards = () => async (dispatch) => {
    const ret = await axios.get(getAPI() + "/boards", JWT());
    if (ret.data.status === "success") {
        dispatch({ type: BoardConstant.GET_ALL_BOARDS, items: ret.data.data });
    }
}

const GetBoard = (_id) => async (dispatch) => {
    const ret = await axios.get(getAPI()+ "/board/" + _id, JWT());
    if (ret.data.status === "success") {
        dispatch({ type: BoardConstant.GET_BOARD, item: ret.data.data });
    }
}

const CreateBoard = (data) => async (dispatch) => {
    const ret = await axios.post(getAPI() + "/board", { data }, JWT());
    if (ret.data.status === "success") {
        dispatch({ type: BoardConstant.CREATE_BOARD, item: ret.data.data });
    }
}

const UpdateBoard = (data) => async (dispatch) => {
    const ret = await axios.post(getAPI() + "/board/" + data._id, { data }, JWT());
    if (ret.data.status === "success") {
        dispatch({ type: BoardConstant.UPDATE_BOARD, item: ret.data.data });
    }
}

const GetContents = (_bid) => async (dispatch) => {
    const ret = await axios.get(getAPI() + "/contentsbyboardid/" + _bid, JWT());
    if (ret.data.status === "success") {
        dispatch({ type: BoardConstant.GET_ALL_CONTENTS, items: ret.data.data });
    }
}

const GetContent = (_cid) => async (dispatch) => {
    const ret = await axios.get(getAPI() + "/content/" + _cid, JWT());
    if (ret.data.status === "success") {
        dispatch({ type: BoardConstant.GET_CONTENT, item: ret.data.data });
    }
}

const CreateContentInBoard = (_bid, data) => async (dispatch) => {
    const ret = await axios.post(getAPI() + "/contentinboard/" + _bid, { data }, JWT());
    if (ret.data.status === "success") {
        dispatch({ type: BoardConstant.CREATE_CONTENT, item: ret.data.data });
    }
}

const UpdateContent = (data) => async (dispatch) => {
    const ret = await axios.post(getAPI() + "/content/" + data.sk.replace("bid:", "") + "/" + data._id, { data }, JWT());
    if (ret.data.status === "success") {
        dispatch({ type: BoardConstant.UPDATE_CONTENT, item: ret.data.data });
    }
}

const DeleteContentInBoard = (data) => async (dispatch) => {
    const ret = await axios.post(getAPI() + "/contentdel/" + data._id, { data }, JWT());
    if (ret.data.status === "success") {
        dispatch({ type: BoardConstant.DELETE_CONTENT, item: data });
    }
}

const CreateUserContent = (_cid, data) => async (dispatch) => {
    const ret = await axios.post(getAPI() + "/usercontent/" + _cid, { data }, JWT());
    if (ret.data.status === "success") {
        dispatch({ type: BoardConstant.CREATE_USER_CONTENT, item: ret.data.data });
    }
}

const UpdateUserContent = (_cid, data) => async (dispatch) => {
    const ret = await axios.post(getAPI() + "/usercontentupdate/" + _cid, { data }, JWT());
    if (ret.data.status === "success") {
        dispatch({ type: BoardConstant.UPDATE_USER_CONTENT, item: ret.data.data });
    }
}

const DeleteUserContent = (_cid, data) => async (dispatch) => {
    const ret = await axios.post(getAPI() + "/usercontentdel/" + _cid, { data }, JWT());
    if (ret.data.status === "success") {
        dispatch({ type: BoardConstant.DELETE_USER_CONTENT, item: {cid : _cid, lang : data.lang } });
    }
}

export const BoardAction = {
    getAPI,
    GetBoards,
    GetBoard,
    CreateBoard,
    UpdateBoard,
    GetContents,
    GetContent,
    CreateContentInBoard,
    UpdateContent,
    DeleteContentInBoard,
    CreateUserContent,
    UpdateUserContent,
    DeleteUserContent
};
