const initialState = {
	items : [],
	item: {},
	licenses: [],
	license: {},
	status : "",
	poollicenses: [],
	poollicense: {},
	applications: [],
	availablelcs: [],
	pools: [],
	licenseapp: {},
	grouplicenses: [],
	lcwhitelist: [],
	lctypemanagergroup: [],
	lcblacklist: [],
};

//manager-license
export const getAPI = (type) =>{
    const env = (location.href.indexOf("localhost") > -1 || location.href.indexOf(".raydevelop.com") > -1) ? "development" : "production";
	if(env === "production"){
		return "https://api-" + type + ".rayteams.com";
	}else{
		return "https://api-" + type + ".raydevelop.com";
	}
};

export const LicenseConstant = {
	GET_ALL_LICENSE : "GET_ALL_LICENSE",
	ADD_LICENSE : "ADD_LICENSE",
	DEL_LICENSE : "DEL_LICENSE",
	UPDATE_LICENSE : "UPDATE_LICENSE",
	CREATE_LICENSE : "CREATE_LICENSE",
	GET_LICENSE : "GET_LICENSE",
	REGIST_LICENSE : "REGIST_LICENSE",
	GET_ALL_POOL_LICENSE : "GET_ALL_POOL_LICENSE",
	GET_POOL_LICENSE : "GET_POOL_LICENSE",
	GET_ALL_POOL : "GET_ALL_POOL",
	GET_POOL : "GET_POOL",
	CREATE_POOL_LICENSE : "CREATE_POOL_LICENSE",
	CREATE_POOL : "CREATE_POOL",
	GET_ALL_APPLICATIONS : "GET_ALL_APPLICATIONS",
	GET_AVAILABLE_LICENSES : "GET_AVAILABLE_LICENSES",
	GET_LICENSE_APP: "GET_LICENSE_APP",
	GET_GROUP_LICENSES: "GET_GROUP_LICENSES",
	GET_LC_WHITELIST: "GET_LC_WHITELIST",
	GET_LC_MG_TYPES: "GET_LC_MG_TYPES",
	GET_LC_BLACKLIST: "GET_LC_BLACKLIST",
};

export const LicenseReducer = (state = initialState, action) => {
	switch (action.type) {
		case LicenseConstant.GET_ALL_LICENSE :
			return {...state, licenses : action.items };
		case LicenseConstant.GET_LICENSE :
			return {...state, licenses : action.items };
		case LicenseConstant.CREATE_LICENSE : 
			return {...state, licenses : action.items };
		case LicenseConstant.REGIST_LICENSE : 
			return {...state, licenses : action.items };
		case LicenseConstant.UPDATE_LICENSE :
			return {...state, licenses : action.items };
		case LicenseConstant.GET_ALL_POOL_LICENSE :
			return {...state, poollicenses : action.items };
		case LicenseConstant.GET_POOL_LICENSE :
			return {...state, poollicense : action.item };
		case LicenseConstant.GET_ALL_POOL : 
			return {...state, pools : action.items };
		case LicenseConstant.GET_POOL :
			return {...state, pool : action.item };
		case LicenseConstant.CREATE_POOL_LICENSE : 
			return {...state, poollicenses : [...state.poollicenses, action.items] };
		case LicenseConstant.CREATE_POOL : 
			return {...state, pools : [...state.pools, action.item] };
		case LicenseConstant.GET_ALL_APPLICATIONS : 
			return {...state, applications : action.items };
		case LicenseConstant.GET_AVAILABLE_LICENSES : 
			return {...state, availablelcs : action.items };
		case LicenseConstant.GET_LICENSE_APP :
			return {...state, licenseapp : action.item };
		case LicenseConstant.GET_GROUP_LICENSES :
			return {...state, grouplicenses : action.items };
		case LicenseConstant.GET_LC_WHITELIST : 
			return {...state, lcwhitelist : action.items };
		case LicenseConstant.GET_LC_MG_TYPES:
			return {...state, lctypemanagergroup : action.items };
		case LicenseConstant.GET_LC_BLACKLIST :
			return {...state, lcblacklist : action.items };
		default:
			return state;
	}
};
