import { connect, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { BUTTONS, COLORS, INPUTS, SPAN } from '../../constants/FromInputs';
import { MLForm } from '../../components/common/MLForm';
import RMPasswordUpdate from '../../components/admin/RMPasswordUpdate';
import { AWSGetAdminUserById, AWSAdminUserUpdate } from '../../libs/aws';
import { Loader } from '../../components/common/Loader';
import { fetchData } from '../../libs/db';
import { AuthConstant, ProgramConstant } from '../../redux/reducers';
import { RTAudit } from '../../libs/audit';
import { useTranslation } from 'react-i18next';

const RMAdminInfo = ({ loginuser }) => {
	const dispatch = useDispatch();
	const [t] = useTranslation(['translation']);
    const { _id } = useParams();
    const [data, setData] = useState({ name : "", email : "", password : "", managergroupid: "" });
	const [loading, setLoading] = useState(false);
	const [tab, setTab] = useState("info");
	const [managerGroupList, setManagerGroupList] = useState([]);

    useEffect(() => {
		dispatch({ type : ProgramConstant.SET_PAGE, page : { 
			code : "manageruserinfo", 
			view : "summary", 
			title : "Login User Info", 
			small : t("계정 정보"), 
			options : [
			]
		}})
    }, [_id]);

	useEffect(() => {
		getData();
	}, [_id]);

	const getData = async () => {
        if (_id) {
            var ret = await AWSGetAdminUserById(_id);
			if(ret.UserAttributes){
				let d = {};
				for(const attr of ret.UserAttributes){
					d[attr.Name.replace('custom:', '')] = attr.Value;
				}
				setData({...d, 
					adminType : d?.type || "user", 
					enable : ret.Enable, 
					created : moment(ret.UserCreateDate).format('LLL'), 
					updated : moment(ret.UserLastModifiedDate).format('LLL'),
					status : ret.UserStatus 
				});
			}

            await getManagerGroup();
        };
	};

    const getManagerGroup = async () => {
        setLoading(true);
        try {
            const ret = await fetchData("rayteams-manager-group", { sk: {
                ComparisonOperator: "EQ",
                AttributeValueList: ["info"]
            }});
            if(ret?.Items[0]) {
				setManagerGroupList(ret.Items.map(x => {
					return { value: x._id, label: x.name }
				}));
			}
			const uRet = await fetchData("rayteams-manager-user", { _id: {
				ComparisonOperator: "EQ",
				AttributeValueList: [_id]
			},
			sk: {
				ComparisonOperator: "EQ",
				AttributeValueList: ["info"]
			}});
			if(uRet?.Items[0] && ret?.Items[0]) {
				setData((prevState) => { return {...prevState, managergroupid: uRet.Items[0]?.managergroupid}});
			}
			
        } catch(err) {
            console.log(err);
        }
        setLoading(false);
    }

	const updateManagerGroup = async () => {
		setLoading(true);
		RTAudit({ lvl : 7, msg : '[' + data.name + ', '+ (data.adminType) +'] Account Updated' })
		await AWSAdminUserUpdate(_id, [{
                Name: 'custom:type',
                Value: data.adminType
            },
            {
                Name: 'name',
                Value: data.name
            }
		]);
        dispatch({type: AuthConstant.UPDATE_LOGINUSER_INFO, newname: data.name });
		setLoading(false);
	};
    
    const controls = [
		{
			type : INPUTS.EMAIL,
			options : {
				label : t('이메일'),
				name : 'email',
				required : true,
				readonly : true
			}
		},
		{
			type : INPUTS.TEXT,
			options : {
				label : t('이름'),
				name : 'name',
                value : data?.name,
				required : true
			}
		},
		{
			type : INPUTS.TEXT,
			options : {
				label : t('생성일자'),
				name : 'created',
                value : data?.created,
				readonly : true
			}
		},
		{
			type : data?.managergroupid && data?.managergroupid != "" ? INPUTS.COMBO : INPUTS.HIDDEN,
			options : {
				label: t('Manager Group'),
				name: 'managergroupid',
				value: data?.managergroupid,
				list: managerGroupList,
                readonly: true
			}
		},
        {
			type : INPUTS.TEXT,
			options : {
				label : t('최근 수정일자'),
				name : 'updated',
                value : data?.updated,
				readonly : true
			}
		},
		{
			type : INPUTS.TEXT,
			options : { 
				label: t('사용자 타입'),
				name: 'adminType',
				value: data?.adminType ? t('관리자') : t('일반사용자'),
                readonly: true
			}
		},
		{ 
			type : SPAN.VERTICAL, 
			options : { val : 20 } 
		},
		{
			type : BUTTONS.BUTTONS, 
			options : { },
			buttons : [{
				type : BUTTONS.NORMALBUTTON, 
				options : { 
					label : t('수정'), 
					color : COLORS.INFO,
                    action : () => updateManagerGroup()
				}
			}]
		}
	];
    return <div className='p-4'>
        {loading && <Loader />}
        {!loading && <>
			<div className="d-flex justify-content-between">
				<h3>
					{data?.name} <small> <span className="text-light ms-1"> </span> </small>
				</h3>
			</div>
			<div className='card'>
				<div className='card-header py-0'>
					<ul className="nav nav-tabs nav-stretch border-top" role="tablist">
						<li className="nav-item cursor-pointer" role="presentation" onClick={() => {setTab("info");}}>
							<a className={"nav-link " + ('info' === tab ? 'active' : '')}><span className='nav-text'>{t("Info")}</span></a>
						</li>
						<li className="nav-item cursor-pointer" role="presentation" onClick={() => {setTab("pass");}}>
							<a className={"nav-link " + ('pass' === tab ? 'active' : '')}><span className='nav-text'>{t("Password")}</span></a>
						</li>
					</ul>
				</div>
				{tab === "info" && <MLForm controls={controls.filter(x => !x.perm)} data={data} onChanged={(e) => { console.log(e);setData(e);}} />}
				{tab === "pass" && <RMPasswordUpdate uData={data} myInfo={true} />}
			</div>
		</>}
    </div>
}

const mapState = (state) => {
    const loginuser = state.AuthReducer.loginuser;
	return { loginuser };
};

const mapDispatch = dispatch => ({
})

export default connect(mapState, mapDispatch)(RMAdminInfo);
