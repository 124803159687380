import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { LicenseConstant } from '../../redux/reducers';
import { fetchData_prod } from '../../libs/db';
import { Link } from 'react-router-dom';

const RTProductAppInfo = ({ user, applications, aid, card = false, icon = false }) => {

	const dispatch = useDispatch();

	const [t] = useTranslation(['translation']);
	const [ info, setInfo ] = useState({});
	const [ process, setProcess ] = useState(false);

	useEffect(() => {
		if(aid){
			getitem();
		}
	},[aid]);

	const getitem = async () => {
		const clinic = applications.find(x => x.licenseId === aid);
		if(clinic){
			setInfo(clinic);
			return;
		}

		setProcess(true);

		const retApplications = await fetchData_prod('rayrnd-applications', { 
			type : { ComparisonOperator: "EQ", AttributeValueList : ["app"] },
		});

		if(retApplications.Items.length > 0){
			setInfo(retApplications.Items.find(x => x.licenseId === aid && x.is_active));
			//dispatch({ type : LicenseConstant ProgramConstant.GETCLINIC, clinic : retClinic.Items[0] });
		}else{
			setInfo({});
		}

		setProcess(false);
	}
	
  	return <>
		{!card && <>
				{/* <Link to={'/GroupInfo/' + info._id} className="me-2">{info.name}</Link> */}
                {icon && <img className='ms-1 rounded-1 me-2' style={{height: "35px", width: "35px"}} src={info?.app_icon?.direct_link} alt=""></img>}
                {!icon && <>
					<img className='ms-1 rounded-1' style={{height: "35px", width: "35px"}} src={info?.app_icon?.direct_link} alt=""></img>
					<span className='ms-2 text-gray-600'>{info?.title}</span>
					</>
				}
		</>}
		{card && <></>}
	</>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
    const applications = state.LicenseReducer.applications;
	return { user, applications };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(RTProductAppInfo);
