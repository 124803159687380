import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import RMUserInfo from '../../components/user/RMUserInfo';
import { history } from '../../history';

const UsersInfoPage = ({ loginuser }) => {
    const { _id } = useParams();
    const page = "users";
	useEffect(() => {
		if(loginuser?.menurole){
			if(!loginuser?.menurole?.find(f => f === page)){
				console.log("-------------> Perm. Error");
				history.push("/");
			}
		}
	}, [loginuser]);

    return (
        <div>
            <RMUserInfo userid={_id}></RMUserInfo>
        </div>
    )
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const groups =  state.AuthReducer.groups;
	const region =  state.AuthReducer.region;
	const regions = state.AuthReducer.regions;
	const regionusers = state.AuthReducer.regionusers;
	const regionlist = state.AuthReducer.regionlist;
	const loginuser = state.AuthReducer.loginuser;
	return { region, regions, user, groups, regionusers, regionlist, loginuser };
};

export default connect(mapState, null)(UsersInfoPage);