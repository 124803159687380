import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { MLText, MLToggle } from '../../components/common/MLInput';
import { MLPanelLoading } from '../../components/common/MLPanel';
import Select from 'react-select';
import MLTable from '../../components/common/MLTable';
import { fetchData, queryData } from '../../libs/db';
import { COUNTRIES } from '../../constants/Country';
import { MLPanelNoData } from '../../components/common/MLPanel';

import { ProgramConstant, PaymentConstant } from '../../redux/reducers';
import PayStatCard from '../../components/payments/PayStatCard';
import { useTranslation } from 'react-i18next';
import { SUBSTATUS, PGTYPE } from '../../constants/RMConstants';
import DatePicker from "react-datepicker";
import PaymentsExportWrapper from './PaymentsExportWrapper';

const PaymentsPage = ({ payments, paymentsExcelList, pagestat, page}) => {
    const dispatch = useDispatch();
    const [t] = useTranslation(['translation']);
	const [search, setSearch] = useState(pagestat?.search || "");
	const [filter, setFilter] = useState(pagestat?.filter || [
        { code : "title", value : "", list : [] },
        { code : "ccode", value : "", list : [] },
        { code : "pg", value : "", list : [] },
    ]);
	const [items, setItems] = useState([]);
	const [loading, setLoading] = useState(false);
    const [yearMonth, setYearMonth] = useState(new Date());
    const [toggleFilter, setToggleFilter] = useState(false);
    const [excelData, setExcelData] = useState([]);
    const [yearItems, setYearItems] = useState([]);

	useEffect(() => {
		dispatch({ type : ProgramConstant.SET_PAGE, page : { 
			code : "payments", 
			view : "summary", 
			title : "Payments", 
			small : t("결제 관련 정보"), 
			options : [
			]
		}})
	}, []);

	useEffect(() => {
        if(!payments)
            getData();
	}, []);

	useEffect(() => {
        if(payments)
            genFilter();
	}, [payments])

	useEffect(() => {
        if(payments){
            genData();
            dispatch({ type: ProgramConstant.PAGESTAT, item: { paymentlist : { search, filter }} });
        }
	}, [search, filter, yearMonth, toggleFilter])

	const getData = async () => {
		setLoading(true);
		const ScanFilter = {
			sk: {
				ComparisonOperator: "BEGINS_WITH",
				AttributeValueList: ["paylog:"],
			},
		}
        // 구독 취소 시 취소일 표시
        const prodScanFilter = {
            sk: {
                ComparisonOperator: "BEGINS_WITH",
                AttributeValueList: ["product:"],
            }
        }
        const userScanFilter = {
            sk: {
                ComparisonOperator: "BEGINS_WITH",
                AttributeValueList: ["info"],
            }
        }
		try {
			const ret = await fetchData("rayteams-user", ScanFilter);
            const prdRet = await fetchData("rayteams-user", prodScanFilter);
            const userRet = await fetchData("rayteams-user", userScanFilter);
            const paymentItems = ret.Items.map(x => {
                const substatus = prdRet && SUBSTATUS.find(s => s.value == prdRet.Items.find(f => f.sk === x?.userProductSk)?.statustype)?.label || ""
                // product: ccode
                if(x.ccode){
                    return {...x, 
                        payid : "PR-" + x.when, 
                        subStatus : substatus,
                        expired : prdRet && prdRet.Items.find(f => f.sk === x?.userProductSk)?.expired,
                        pgname : PGTYPE.find(p => p.value === x?.pg)?.label || "RAY"
                    }
                }else{
                    return {...x, 
                        payid : "PR-" + x.when, 
                        prdid : x.product?._id, 
                        title : x.product?.title, 
                        ccode : userRet && userRet.Items.find(f => f._id === x._id)?.countryCode || "",
                        punit : x.product?.punit || "USD",
                        realprice : x.product?.realprice,
                        period : x.product?.period,
                        count : x.product?.count,
                        subStatus : substatus,
                        expired : prdRet && prdRet.Items.find(f => f.sk === x?.userProductSk)?.expired,
                        pgname : PGTYPE.find(p => p.value === x?.pg)?.label || "RAY"
                    }
                }
            }) 

            const excelItems = [];
            paymentItems && paymentItems.map(x => {
                excelItems.push({...x});
                // 환불 건수 있으면 push
                const refundInfo = prdRet.Items && prdRet.Items.filter(r => r.sk === x?.userProductSk && r?.rfstatus === "done")[0];
                if(refundInfo) {
                    const rfItem = {...x, 
                        payid : "PR-" + x.when, 
                        prdid : x._id, 
                        title : refundInfo?.title, 
                        ccode : refundInfo?.ccode,
                        punit : refundInfo?.punit || "USD",
                        realprice : refundInfo?.realprice,
                        period : refundInfo?.period,
                        count : refundInfo?.count,
                        subStatus : "refund",
                        expired : refundInfo?.expired,
                        pgname : PGTYPE.find(p => p.value === x?.pg)?.label || "RAY",
                        rfstatus: refundInfo?.rfstatus,
                        rfamount: refundInfo?.rfamount,
                        rfreqtime: refundInfo?.rfreqtime,
                        rfdonetime: refundInfo?.rfdonetime
                    }
                    excelItems.push(rfItem);
                }
            });

            dispatch({ type: PaymentConstant.PAY_GET_ALL, items: paymentItems, excelItems: excelItems});
		} catch (e) {
			console.log(e);
		}
		setLoading(false);
	};

	const genFilter = () => {
        setFilter( filter.map(x => {
            const list = [...new Set(payments.map(m => m[x.code]))];
            return {...x, list : [{ value : "", label : "ALL" }, ...list.map(n => ({ 
                value : n, 
                label : fLabel(x.code, n)
            }))] }
        }));
	};

    const fLabel = (code, value) => {
        if(value === true || value === false){
            return value === true ? "Active" : "Expired";
        }else if(code === "ccode"){
            return COUNTRIES.find(x => x.countryCode === value)?.name;
        }
        return value;
    }

	const genData = () => {
        setItems(payments
            .filter(x => x.pgname != "RAY")
            .filter(x => search ? (x.title.indexOf(search) > -1) : true)
            .filter(x => filterCheck(x))
            .filter(x => toggleFilter && yearMonth ? (moment(x.when).format("yyyy-MM") == moment(yearMonth).format("yyyy-MM")) : true));
        setExcelData(paymentsExcelList && paymentsExcelList
            .filter(x => x.pgname != "RAY")
            .filter(x => search ? (x.title.indexOf(search) > -1) : true)
            .filter(x => filterCheck(x))
            .filter(x => toggleFilter && yearMonth ? (moment(x.when).format("yyyy-MM") == moment(yearMonth).format("yyyy-MM")) : true));
        setYearItems(payments
            .filter(x => x.pgname != "RAY")
            .filter(x => search ? (x.title.indexOf(search) > -1) : true)
            .filter(x => filterCheck(x)));
	}

    const filterCheck = (item) => {
        var tf = true;
        filter.map(x => {
            if(tf)
                tf = (item[x.code] === x.value || x.value === "");
        });
        return tf;
    }

	const handleFilter = (e, item) => {
        setFilter( filter.map(x => x.code === item.code ? {...x, value : e.value } : x));
	}
    
	const logClicked = (e) => {
        dispatch({ type: PaymentConstant.PAY_LOG_MODAL, item : { show : true, paylog : e }})
        console.log(e);
	}

    const headers = [
		{ value : "payid", label : t("PayNo"), type : "TEXTBUTTON", clicked : logClicked, css : "text-start", style : { width : 170 } },
		{ link : "prdid", linkpath : "/RTProductInfo", value : "title", label : t("Product"), css : "text-start" },
		{ value : "_id", label : t("User"), type : "USER",  css : "text-start" },
		{ value : "ccode", label : t("Country"), type : "COUNTRY", style : { width : 150 } },
		{ value : "realprice", label : t("Price"), type : "CUR", isZero : t("무료"), css : "text-end", curtype : "punit", style : { width : 100 } },
		{ value : "period", label : t("Period"), css : "text-end", style : { width : 80 } },
		{ value : "pgname", label : t("PG"), css : "text-center", style : { width : 80 } },
		{ value : "when", label : t("When"), type : "DATESHORT", style : { width : 130 } },
        { value : "subStatus", label : t("Status"), style : { width : 160} },
        { value : "expired", label : t("Expired"), type : "DATESHORT", style : { width : 130 } }
    ]

  	return <div className='p-4'>
		<div className='mb-4'>
            <PayStatCard items={items} yearItems={yearItems}/>
		</div>
		<div className="card">
			<div className="card-header d-flex justify-content-between align-items-center">
				<div className="card-label fw-bold text-gray-800 fs-5">{items.length}</div>
				<div className="d-flex align-items-center">
                    {toggleFilter && <div className='me-2'>
                        <PaymentsExportWrapper
                            list={excelData} 
                            buttonProps={{ css: "ms-1" }}
                        />
                    </div>}
                    <div className='d-flex me-2'>
                        {t("년/월")} <MLToggle options={{
                            name: 'pg',
                            value: toggleFilter,
                            readonly: false,
                        }} handleChanged={(e) => {setToggleFilter(e.target.value);}} css={"ms-3"} />
                    </div>
                    <div className='me-2'>
                    <DatePicker
                        showMonthYearPicker
                        dateFormat={"yyyy-MM"}
                        selected={yearMonth} 
                        onChange={setYearMonth}
                        className={"form-control"}
                    /></div>
                    {filter.map((x, idx) => <Select
                        key={"f-" + x.code + idx}
                        onChange={(e) => handleFilter(e, x)}
                        value={x.list.filter(y => y.value === x.value)}
                        options={x.list}
                        data-dropdown-css-className="w-200px"
                        className="form-select-transparent me-2 w-150 mw-130" />)}
                    <MLText options={{
                        placeholder : t("검색어 입력"),
                        size : "sm",
                        value : search,
                    }} handleChanged={(e) => setSearch(e.target.value)} css="mb-0" />
				</div>
			</div>
            {items.length > 0 && <> {!loading && <MLTable headers={headers} items={items.sort((a, b) => a.created > b.created ? -1 : 1)} noheader={true} />} </>}
            {items.length === 0 && <MLPanelNoData />}
            {loading && <MLPanelLoading />}
        </div>
    </div>
}

const mapState = (state) => {
	const region = state.AuthReducer.region;
	const payments = state.PaymentReducer.payments;
    const paymentsExcelList = state.PaymentReducer.paymentsExcelList;
	const regionusers = state.AuthReducer.regionusers;
	const pagestat = state.ProgramReducer.pagestat["paymentlist"] || {};
	const page = state.ProgramReducer.page;

	return { region, payments, paymentsExcelList, regionusers, pagestat, page };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, null)(PaymentsPage);
