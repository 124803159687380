import SyncObject from './sync-object';

class BucketObject extends SyncObject {
    constructor(properties = {}) {
        super(properties);
        this.bucket = properties.bucket;
        this.key = properties.key;
        this.size = properties.size;
        this.lastModified = properties.lastModified;
        this.ETag = properties.ETag;
    }
}

export default BucketObject;