import "../../assets/page-auth.css"
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { MLForm } from '../../components/common/MLForm';
import { INPUTS, BUTTONS, COLORS, SPAN } from '../../constants/FromInputs';
import { AWSLogin, AWSLoginRegion, AWSAdminGetUser, AWSLoginPasswordUpdate } from '../../libs/aws';
import { COGNITO, RegionList } from '../../libs/cogInfo';
import { AuthConstant, ProgramConstant } from '../../redux/reducers';
import MLToast from '../../components/common/MLToast';
import { AlertConstant } from '../../redux/reducers';
import { Modal } from 'react-bootstrap';
import { MLEmailLink } from '../../components/common/MLCommon';
import { MLButton } from '../../components/common/MLButton';
import { fetchData } from '../../libs/db';
import { Buffer } from 'buffer';
import { RTButton } from '../../libs/buttons';
import { RTAudit } from '../../libs/audit';
import { GetCodeConfig, GetConfig } from "../../libs/appConfig";

const LoginPage = ({regions, regionlist, user, config}) => {
    const [data, setData] = useState({ email : "", password : ""});
    const [loginmsg, setLoginMsg] = useState("");
	const [modalshow, setModalShow] = useState(false);
	const [process, setProcess] = useState(false);
	const dispatch = useDispatch();
	const [t] = useTranslation(['translation']);

	useEffect(() => {
		setProcess(false);
		return () => {
			setProcess(false);
		};
	}, []);

    const controls = [
		{ 
			type : INPUTS.EMAIL, 
			options : { 
				label : 'Email address',
				name : 'email',
				value: ''
			},
		},
		{ 
			type : INPUTS.PASSWORD, 
			options : { 
				label : 'Password',
				name : 'password',
				enterSubmit: true,
				tooltiphide: true,
				forgot : true,
				forgotmsg : "Forgot your password?",
				forgotlink : () => setModalShow(true),
			},
		},
		{
			type : INPUTS.COMPONENT,
			options : {},
			component : <>
				<RTButton options={{
					label : process ? 'Please wait...' : 'Login',
					size : 'lg',
					ing : process,
					needValid : true,
					action : () => login()
				}} valid={!process && data.email && data.password} css="w-100" />
			</>
		}
	]

    const login = async () => {
		setProcess(true);
		try {
			var ret = await AWSLogin(data.email, data.password);
			if(ret?._id) {
				RTAudit({ lvl : 5, msg : 'Logged in.' });
				dispatch({ type : AuthConstant.LOGIN_SUCCESS, user : ret, regions: ret.regions });
				// getData_dGroups();
				getData_UserRole(localStorage.getItem("usersub"));
				const codeConfig = await GetCodeConfig();
				const serviceConfig = await GetConfig();
				dispatch({ type : ProgramConstant.SET_CONFIG, items : codeConfig })
				dispatch({ type : ProgramConstant.SET_SERVICE, item : serviceConfig })
			}
		} catch(e) {
			dispatch({ type: AlertConstant.ERROR, msg: e.message, show: true });
        	console.log(e.message);
		}
		setProcess(false);
    }

	const getData_dGroups = async () => {
		const ScanFilter = {
			sk: {
				ComparisonOperator: "EQ",
				AttributeValueList: ["info"],
			},
			hasMember: {
				ComparisonOperator: "EQ",
				AttributeValueList: [true],
			},
		}
		try {
			const ret = await fetchData("rayteams-group", ScanFilter);
			// localStorage.setItem("groups", JSON.stringify(ret.Items));
			dispatch({ type: AuthConstant.GET_ALL_GROUPS, items : ret.Items });
		} catch (e) {
			dispatch({ type: AlertConstant.ERROR, msg: e.message, show: true });
			dispatch({ type: AuthConstant.GET_ALL_GROUPS, items : [] });
		}
	}

	const getData_UserRole = async (usersub) => {
		const ScanFilter ={
			_id : {
				ComparisonOperator: "EQ",
				AttributeValueList: [usersub]
			},
			sk : {
				ComparisonOperator: "EQ",
				AttributeValueList: ["info"]
			}
		}
		
		const ret = await fetchData("rayteams-manager-user", ScanFilter);

		const CountryFilter = {
			_id : {
				ComparisonOperator: "EQ",
				AttributeValueList: [ret.Items[0]?.managergroupid || ""]
			},
			sk : {
				ComparisonOperator: "EQ",
				AttributeValueList: ["info"]
			}
		}
		const retCountries = await fetchData("rayteams-manager-group", CountryFilter);
        localStorage.setItem("mgroup", JSON.stringify(retCountries?.Items[0] || []));
		dispatch({ type: AuthConstant.GET_LOGINUSER_ROLE, items : { menu :  ret.Items[0]?.menu || [], perms : ret.Items[0]?.perms || [], countries: retCountries?.Items[0]?.countries || [], managergroupid: ret.Items[0]?.managergroupid || "" } })
	}

	// console.log(config);
    return <>
		<div className="card">
			<div className='card-body'>
				{config.servicelogo && <div className='app-brand justify-content-center mb-4 mt-2 fw-bolder'>
					<img src={config.servicelogo} className='me-2' style={{ height :48 }} />
				</div>}
				{!config.servicelogo && <span className='demo mt-3'><img src="../../assets/img/branding/bi_manager_purple.svg" className="h-px-30" /></span>}
				<h5 className="mb-1 pt-2">Welcome to {config.managertitle || 'RAYTeams'} </h5>
				<p className="mb-4">{t('Improve user experience, make users feel valued.')}</p>
				<form className='mb-3 fv-plugins-bootstrap5 fv-plugins-framework'>
					<MLForm 
						controls={controls} 
						data={data} 
						nocard={true}
						submit={() => login()} onChanged={(e) => setData(e)} />
				</form>
			</div>
		</div>
		<Modal show={modalshow} size={"lg"} style={{ backgroundColor: "#42476c !important"}}>
			<Modal.Header className='modal-header header-bg'>
				<h2>비밀번호 분실
					<small className={"ms-2 fs-7 fw-normal opacity-50 "}></small>
				</h2>
				<div className='d-flex justify-content-end mb-2'>
					<MLButton options={{
						label : '닫기',
						color : COLORS.PRIMARY,
						size : 'sm',
						action : () => setModalShow(false),
					}} css="btn btn-primary d-grid w-100 waves-effect waves-light" />
				</div>
			</Modal.Header>
			<Modal.Body className='flex-fill pt-0 formcard pt-6'>
				비밀번호 초기화는 관리자에게 <MLEmailLink email={"help@rayteams.com"} altermessage={'이메일로 문의'} /> 해주시기 바랍니다.
			</Modal.Body>
		</Modal>
    </>
}

const mapState = (state) => {
	const regions = state.AuthReducer.regions;
	const regionlist = state.AuthReducer.regionlist;
	const user = state.AuthReducer.user;
	const loginuser = state.AuthReducer.loginuser;
	const config = state.ProgramReducer.config;
	return { regions, regionlist, user, loginuser, config };
};

export default connect(mapState)(LoginPage);
