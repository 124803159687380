import axios from 'axios';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { MLText } from '../../components/common/MLInput';
import { MLPanelLoading } from '../../components/common/MLPanel';
import Select from 'react-select';
import MLTable from '../../components/common/MLTable';
import { AuthConstant, getAPI, getHeader } from '../../redux/reducers/AuthReducer';
import { fetchData } from '../../libs/db';
import WidgetBarChartAppUsage from '../../components/widgets/dashboard/WidgetBarChartAppUsage';
import RMWidgetBarChart from '../../components/widgets/RMWidgetBarChart';
import RMWidgetLineChart from '../../components/widgets/RMWidgetLineChart';
import { COUNTRIES } from '../../constants/Country';
import { MLButton, MLRefreshButton } from '../../components/common/MLButton';
import { history } from '../../history';
import { COLORS } from '../../constants/FromInputs';
import { RMIconList, RMIconChart } from '../../components/icon/MLIcon';
import { MLPanelNoData } from '../../components/common/MLPanel';
import { ProgramConstant, PaymentConstant } from '../../redux/reducers';
import { useTranslation } from 'react-i18next';

const UserPay = ({ _id }) => {
	const [t] = useTranslation(['translation']);
	const dispatch = useDispatch();
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
        if(_id)
            getData();
	}, [_id]);

    const btnClick = (item) => {
        console.log(item._id);
        console.log(item.prdid);
    }

	const getData = async () => {
		setLoading(true);
		const ScanFilter = {
			_id: {
				ComparisonOperator: "EQ",
				AttributeValueList: [_id],
			},
			sk: {
				ComparisonOperator: "BEGINS_WITH",
				AttributeValueList: ["paylog:"],
			},
		}
		try {
			const ret = await fetchData("rayteams-user", ScanFilter);
            console.log(ret.Items)
            setData(ret.Items.
                sort((a, b) => a.when > b.when ? -1 : 1).
                map(x => ({...x, payid : "PR-" + x.when})) 
            );
		} catch (e) {
			console.log(e);
		}
		setLoading(false);
	}

	const logClicked = (e) => {
        dispatch({ type: PaymentConstant.PAY_LOG_MODAL, item : { show : true, paylog : e }})
        console.log(e);
	}

    const headers = [
		{ value : "payid", label : t("PayNo"), type : "TEXTBUTTON", clicked : logClicked, css : "text-start", style : { width : 180 } },
		{ link : "prdid", linkpath : "/RTProductInfo", value : "title", label : t("Product"), css : "text-start", style : { width : 180 } },
		{ value : "_id", label : t("User"), type : "USER",  css : "text-start" },
		{ value : "ccode", label : t("Country"), type : "COUNTRY", style : { width : 100 } },
		{ value : "realprice", label : t("Price"), css : "text-end", style : { width : 100 } },
		{ value : "punit", label : t("Unit"), css : "text-center", style : { width : 100 } },
		{ value : "period", label : t("Period"), css : "text-end", style : { width : 80 } },
		{ value : "count", label : t("Count"), css : "text-end", style : { width : 80 } },
		{ value : "pg", label : t("PG"), css : "text-center", style : { width : 80 } },
		{ value : "when", label : t("When"), type : "DATE", style : { width : 180 } },
    ]

  	return (<>
            {data.length > 0 && <> {!loading && <MLTable headers={headers} items={data} noheader={true} nocard={true} />} </>}
            {data.length === 0 && <MLPanelNoData noborder={true} />}
		</>
  	);
}

const mapState = (state) => {
	const region = state.AuthReducer.region;
	const subscriptions = state.PaymentReducer.subscriptions;
	const regionusers = state.AuthReducer.regionusers;
	const pagestat = state.ProgramReducer.pagestat["subscriptionlist"] || {};
	return { region, subscriptions, regionusers, pagestat };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, null)(UserPay);
