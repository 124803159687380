import { ACTIONS } from "../../constants/FromInputs";
import { history } from "../../history";

const initialState = { 
	title : "ManagerGroup",
	managerGroups : [],
};

export const ManagerGroupConstant = {
	GO_LINK : "GO_LINK",
	GET_MANAGER_GROUPS : "GET_MANAGER_GROUPS",
};

export const ManagerGroupReducer = (state = initialState, action) => {
	switch (action.type) {
		case ManagerGroupConstant.GET_MANAGER_GROUPS:
			return {...state, managerGroups : action.items };
		default:
			return state;
	}
};
