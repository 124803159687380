export const COLORS = {
	DEFAULT : "",
	PRIMARY : "primary",
	SECONDARY : "secondary",
	SUCCESS : "success",
	DANGER : "danger",
	WARNING : "warning",
	LIGHT : "light",
	INFO : "info",
	DARK : "dark",
	LIGHT_PRIMARY: 'light-primary',
	LIGHT_SUCCESS: 'light-success',
	LIGHT_DANGER: 'light-danger'
}
