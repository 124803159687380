import axios from 'axios';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { MLButton, MLRefreshButton, MLChartButton } from '../common/MLButton';
import { MLPanelLoading } from '../common/MLPanel';
import MLTable from '../common/MLTable';
import { history } from '../../history';
import { COLORS } from '../../constants/FromInputs';
import { ProgramConstant } from '../../redux/reducers';
import { FormAction } from '../../redux/actions/FormAction';
import { useTranslation } from 'react-i18next';

const RTFormsMainList = ({ GetAll, items }) => {
	const dispatch = useDispatch();
	const [t] = useTranslation(['translation']);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		dispatch({
			type: ProgramConstant.SET_PAGE, page: {
				code: "form",
				view: "summary",
				title: "Form",
				small: t("Form 관련 정보"),
				options: []
			}
		})
		getData();
	}, []);

	const getData = async () => {
		setLoading(true);
		await GetAll();
		setLoading(false);
	}

	const headers = [
		{ link: "_id", linkpath: "/FormInfo", value: "title", label: t("Title"), css: "text-start", style: { width: 250 } },
		{ value: "type", label: t("Type"), type: 'CODE', css: "text-start", style: { width: 250 } },
		{ value: "controls", label: t("Controls"), css: "text-center", type: "CNT" },
		{ value: "created", label: t("Created"), css: "text-end", type: "DATE" },
	]

	return <div className='p-4'>
		<div className="card">
			<div className="card-header d-flex justify-content-between align-items-center">
				<div className="card-label fw-bold text-gray-800 fs-5">{items.length}</div>
				<div className="d-flex">
					<MLButton options={{
						code: "form-add",
						color: COLORS.PRIMARY,
						size: "sm",
						label: t("Form 등록"),
						action: () => history.push("/FormsAdd")
					}} css="me-2" />
				</div>
			</div>
			{!loading && <MLTable
				headers={headers}
				items={items} noheader={true} />}
			{loading && <MLPanelLoading />}
		</div>
	</div>
}

const mapState = (state) => {
	const items = state.RTFormReducer.items;
	const page = state.ProgramReducer.page;
	return { items, page };
};

const mapDispatch = (dispatch) => ({
	GetAll: () => dispatch(FormAction.GetAll())
});

export default connect(mapState, mapDispatch)(RTFormsMainList);