export const MLProgressBar = ({ title, val, count, color = "success", size, notext, css }) => {
	return <div className={"d-flex align-items-center flex-column " + (css ? css : "")}>
		{title && <div className="d-flex justify-content-between w-100 mt-auto">
			<span className="fw-bold fs-6 text-gray-600">{title}</span>
			{(count && !notext) && <span className="fw-bolder fs-6">{count}</span>}
			{(!count && !notext && val) && <span className="fw-bolder fs-6">{val}%</span>}
		</div>}
		<div className={"w-100 bg-light" + (size === "small" ? " " : " mb-3") + (size === "small" ? " h-2px" : " h-5px")} style={{height : '20px'}}>
			<div className={"bg-" + color + (size === "small" ? " h-2px" : " h-5px")} role="progressbar" style={{ width: val + "%", height : '20px'}} 
				aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
		</div>
	</div>
}