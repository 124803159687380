import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { BiHelpCircle } from 'react-icons/bi';
import { idData, fetchData, updateData, updateData_withExpression, queryData } from '../../libs/db';
import MLTable from '../../components/common/MLTable';
import { history } from '../../history';
import { MLButton } from '../../components/common/MLButton';
import { COLORS } from '../../constants/FromInputs';
import { RMLICENSETYPE, RMLICENSETYPE_MOCKUP } from '../../constants/RMConstants';
import { MLComboSingle, MLNumber } from '../../components/common/MLInput';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { RTAudit } from '../../libs/audit';
import { Store } from '../../store';
const ManagerGroupInfoPage = ({ adminUsers = [] }) => {
	const dispatch = useDispatch();
	const [t] = useTranslation(['translation']);
	const { _id, tab = "users" } = useParams("");
	const [data, setData] = useState({});
	const [help, setHelp] = useState(window.localStorage.getItem("helpmsg") === "N" ? false : true);
	const [process, setProcess] = useState(false);
	const [managerGroup, setManagerGroup] = useState({});
	const [err, setErr] = useState("");
	const [selectLicense, setSelectLicense] = useState({});
	const [licenseList, setLicenseList] = useState([]);
	const [items, setItems] = useState([]);
	const [selectedLicenses, setSelectedLicenses] = useState([]);
	const [remainTypes, setRemainTypes] = useState([]);
	const [modal, setModal] = useState({
		show: false
	});
	const perms = Store.getState().AuthReducer?.loginuser?.perms || [];
	const tabs = [
		{ value: "users", label: "Users" },
		{ value: "license", label: "License", code: "mgr-license" },
	]

	useEffect(() => {
		if (_id)
			init();
	}, [_id]);

	useEffect(() => {
		window.localStorage.setItem("helpmsg", help ? "Y" : "N");
	}, [help])

	const init = async () => {
		const ret = await idData('rayteams-manager-group', _id);
		if (ret.Items.length > 0) {
			setManagerGroup(ret.Items.find(f => f.sk === "info"));
			await getLicenseData();
			await getLicenseModalData();
		} else {
			setManagerGroup({});
		}
	}

	const getLicenseData = async () => {
		setProcess(true);
		try {
			const ScanFilter = {
				sk: {
					ComparisonOperator: "EQ",
					AttributeValueList: ["info:" + _id]
				}
			}
			const ret = await fetchData("rayteams-license", ScanFilter);
			setItems(ret.Items);
		} catch (err) {
			console.log(err);
		}
		setProcess(false);
	}

	const getLicenseModalData = async () => {
		setProcess(true);
		try {
			const ScanFilter = {
				sk: {
					ComparisonOperator: "BEGINS_WITH",
					AttributeValueList: ["info"]
				}
			}
			const ret = await fetchData("rayteams-license", ScanFilter);
			const retLicense = [];
			ret.Items && ret.Items.map(x => {
				retLicense.push({
					licenseId: x._id,
					licenseName: x.title,
					managerGroupId: x?.sk.split(":")[1] ? x?.sk.split(":")[1] : "",
				})

			});
			setLicenseList(ret.Items.filter(f => f.sk === "info") || []);
		} catch (err) {
			console.log(err);
		}
		setProcess(false);
	}

	const getTypePeriod = async (_id) => {
		setProcess(true);
		const queryFilter = {
			_id: {
				ComparisonOperator: "EQ",
				AttributeValueList: [_id],
			},
			sk: {
				ComparisonOperator: "BEGINS_WITH",
				AttributeValueList: ["type:"],
			},
		}
		try {
			const ret = await queryData("rayteams-license", queryFilter);
			console.log("getTypePeriod : ====", ret);
			const RMLCTYPES = _id && _id.includes("DigitalSmileDesignInAppExportMockUp") ? RMLICENSETYPE_MOCKUP : RMLICENSETYPE;
			const lcTypes = ret.Items.map(m => { return { value: m.sk.split(":")[1], label: RMLCTYPES.find(f => f.value == m.sk.split(":")[1]).label } }) || [];
			//const uniqueArray = uniqBy(lcTypes, "value");
			console.log("라이선스 갯수 부여 확인 : ", lcTypes);
			// 기존 부여된 라이선스와 credit 라이선스 수량 부여 어떻게 할 것인지 생각 해보자...
			setRemainTypes(lcTypes);
		} catch (err) {
			console.log(err);
		}
		setProcess(false);
	}

	const updateLicense = async () => {
		setProcess(true);
		for (let index = 0; index < selectedLicenses.length; index++) {
			const lc = selectedLicenses[index];
			const paramsUpdate = {
				TableName: "rayteams-license",
				Key: {
					_id: lc.licenseId,
					sk: "info:" + _id,
				},
				UpdateExpression:
					"SET #title = :title, #total = if_not_exists(#total, :initial) + :num, #created = if_not_exists(#created, :created), #updated = :updated",
				ExpressionAttributeValues: {
					":num": parseInt(lc?.licenseCount) || 0,
					":initial": 0,
					":title": lc.licenseName,
					":updated": new Date().getTime(),
					":created": new Date().getTime()
				},
				ExpressionAttributeNames: {
					"#total": `t_${lc.licenseType}`,
					"#updated": "updated",
					"#created": "created",
					"#title": "title"
				},
				ReturnValues: "ALL_NEW"
			};

			const upData = await updateData_withExpression(paramsUpdate);
		}

		setProcess(false);

		history.push("/ManagerGroup");
		RTAudit({ lvl: 4, msg: 'Update Manager Group License' });
	}

	const mGruopUserHeaders = [
		{ value: "name", label: t("Name"), css: "text-start", style: { width: 200 } },
		{ value: "email", label: t("Email"), css: "text-start" },
		{ value: "type", label: t("Admin"), css: "text-end", type: "TFVALUE" }
	]

	return <>

		<div className='p-4'>
			<div className="card">
				<div className="card-header d-flex justify-content-between align-items-center">
					<div>
						<h3 className="card-title">
							<span className="card-label fw-bold text-gray-800">
								<span className="align-middle">
									{/* <a href="#" title="Prev" onClick={() => history.push("/ManagerGroup")}>
										<span className="badge bg-secondary badge-circle badge-lg me-4">
											<svg width="24" height="24" className="text-hover-info" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
												<rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="currentColor" />
												<path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="currentColor" />
											</svg></span></a> */}
									{managerGroup.name}
								</span>
								{/* <a href="#" className="mx-3 btn btn-label-primary" style={{ width: 36, height: 36 }} title="Edit" onClick={() => history.push("/ManagerGroupEdit/" + _id)}>
									<i class="ti ti-edit fs-4 text-primary"></i>
								</a> */}
							</span>
						</h3>
					</div>
					<div className="d-flex">
						{/* <MLButton options={{
							code: "mgr-editgroup",
							label: "Edit",
							color: COLORS.SECONDARY,
							action: () => history.push("/ManagerGroupEdit/" + _id)
						}} /> */}
						<button className="btn btn-secondary btn-md me-1 shadow-none" type="button" onClick={() => history.push("/ManagerGroupEdit/" + _id)}>Edit
						</button>
						<button className="btn btn-dark btn-md shadow-none ms-2" type="button" onClick={() => history.push("/ManagerGroup")}>List
						</button>
					</div>
				</div>
				<div className="card-body py-0">
					<ul className="nav nav-tabs nav-stretch border-top mt-4 pt-2" style={{ borderRadius: "0" }}>
						{tabs.map((x, idx) => {
							if (x.value === "users" || perms.includes(x.code)) {
								return <li className="nav-item  mx-2" key={idx} onClick={() => history.push("/ManagerGroupInfo/" + _id + "/" + x.value)}>
									<Link to="#" className={"nav-link " + (tab === x.value ? 'active' : '')}>{t(x.label)}</Link>
								</li>
							}
							return null;
						})}
					</ul>


					{/* <ul className="nav nav-tabs nav-stretch border-top pt-2" style={{ borderRadius: 0 }}>
						{tabs.map((x, idx) => {
							return <li className="nav-item  mx-2" key={idx} onClick={() => history.push("/ManagerGroupInfo/" + _id + "/" + x.value)}>
								<Link to="#" className={"nav-link " + (tab === x.value ? 'active' : '')}>{t(x.label)}</Link>
							</li>
						})}
					</ul> */}
				</div>

				{tab === "users" && <>
					{/* {help && <p className="px-4 mb-2">
						{t("Manager Group의 User 정보를 확인할 수 있습니다.")}
					</p>} */}
					<div>
						<div className="tab-content" id="">
							<div className="tab-pane fade show active" id="kt_tab_pane_7" role="tabpanel">
								<div className='table-responsive'>
									<MLTable headers={mGruopUserHeaders} noheader={true}
										items={adminUsers.filter(f => f?.managergroupid === _id)}
									/>
								</div>
								{/* <div className="text-muted text-start card-toolbar mt-4" onClick={() => history.push("/ManagerGroup")}>
									<button className="btn btn-secondary btn-sm opacity-50 text-hover-gray-900" type="button">
										<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="ms-n3 mt-n1">
											<path d="M11.2657 11.4343L15.45 7.25C15.8642 6.83579 15.8642 6.16421 15.45 5.75C15.0358 5.33579 14.3642 5.33579 13.95 5.75L8.40712 11.2929C8.01659 11.6834 8.01659 12.3166 8.40712 12.7071L13.95 18.25C14.3642 18.6642 15.0358 18.6642 15.45 18.25C15.8642 17.8358 15.8642 17.1642 15.45 16.75L11.2657 12.5657C10.9533 12.2533 10.9533 11.7467 11.2657 11.4343Z" fill="currentColor" />
										</svg>
										이전</button>
								</div> */}
							</div>
						</div>
					</div></>}
				{tab === "license" && <>
					{/* {help && <p className="px-4 mb-2">
						{t("Manager Group의 License 정보를 확인할 수 있습니다.")}
					</p>} */}
					<div className="bg-light">
						<div className="tab-content" id="">
							<div className="tab-pane fade show active">
								<div className="header align-items-stretch d-block" style={{ position: "initial" }}>
									<div className="d-flex w-100 justify-content-between align-items-center">
										<div className="d-flex align-items-center text-dark fw-bolder fs-3 mb-4">{t("Licenses 사용현황")}</div>
										<div className="d-flex align-items-center">
											{tab === "license" && <MLButton options={{
												code: "mgr-addlicense",
												label: t("Add a Group"),
												color: COLORS.PRIMARY,
												size: "md",
												action: () => setModal({ show: true })
											}} />}
										</div>
									</div>
								</div>
								<div className="flex-wrap flex-stack mt-6">
									<div className="row">
										{items && items.map((x, idx) => {
											const RMLCTYPE = x._id && x._id.includes("DigitalSmileDesignInAppExportMockUp") ? RMLICENSETYPE_MOCKUP : RMLICENSETYPE;
											let usedCnt = 0;
											usedCnt = RMLICENSETYPE.map(m => x.hasOwnProperty(`u_${m.value}`) ? parseInt(x?.[`u_${m.value}`]) : 0).reduce((prev, curr) => prev + curr, 0);
											let totalCnt = 0;
											totalCnt = RMLICENSETYPE.map(m => x.hasOwnProperty(`t_${m.value}`) ? parseInt(x?.[`t_${m.value}`]) : 0).reduce((prev, curr) => prev + curr, 0);
											let p = 0;
											p = (isNaN(usedCnt) ? 0 : usedCnt) / (isNaN(totalCnt) ? 0 : totalCnt) * 100;
											return (
												<div className="col" title={x._id} style={{ maxWidth: "360px" }}>
													<div className="card card-flush mb-5 mb-xl-10">
														<div className="card-header bg-muted pb-0">
															<div className="card-title d-flex flex-column">
																<div className="d-flex align-items-center">
																	<span className="fs-4 pt-1 fw-bold lh-1 ls-n2">{x?.title}</span>
																</div>
															</div>
														</div>
														<div className="card-body pt-2 pb-8 d-flex flex-wrap align-items-center">
															<div className="d-flex flex-column content-justify-center flex-row-fluid">
																<div className="d-flex align-items-center flex-column mb-8 w-100">
																	<div className="d-flex justify-content-between fw-bold fs-5 w-100 mt-auto mb-2">
																		<span>{t("사용량")}</span>
																		<span>{isNaN(usedCnt) ? 0 : usedCnt} / {isNaN(totalCnt) ? 0 : totalCnt}</span>
																	</div>
																	<div className="h-8px mx-3 w-100 bg-secondary rounded">
																		<div className="bg-info rounded h-8px" role="progressbar" style={{ width: "" + p + "%" }}></div>
																	</div>
																</div>
																{RMLCTYPE.map(r => {
																	return (x.hasOwnProperty(`t_${r.value}`) && <>
																		<div className="d-flex fw-semibold align-items-center my-1" id={r._id}>
																			<div className="bullet w-8px h-3px rounded-2 bg-gray me-3"></div>
																			<div className="fs-6 flex-grow-1 me-4">{r.label}</div>
																			<div className="fw-bolder fs-5 text-info">
																				{x.hasOwnProperty(`u_${r.value}`) ? x?.[`u_${r.value}`] : 0}
																				<span className="text-muted fs-5 fw-normal"> / {x.hasOwnProperty(`t_${r.value}`) ? x?.[`t_${r.value}`] : 0}
																				</span>
																			</div>
																		</div>
																	</>
																	)
																})}

															</div>
														</div>
													</div>
												</div>
											)
										})}
									</div>
								</div>

							</div>
						</div>

					</div>
				</>}
			</div>
		</div>

		<div>
			<Modal show={modal.show} size={"lg"} style={{ backgroundColor: "#42476c !important" }}>
				<Modal.Header className='modal-header header-bg'>
					<h2>{t("License")}</h2>
				</Modal.Header>
				<Modal.Body className='flex-fill pt-2 formcard'>
					<div>
						<div className="mb-3">
							<label className="form-label fw-bolder">{t("Add License Group")}</label>
							{help && <div className="alert alert-warning mb-2">
								{t("법인이 관리하는 License 를 선택 할 수 있습니다.")}<br />
							</div>}
							<div className="card-body p-0">
								<div>
									<div className='d-flex justify-content-between'>
										<div className='d-flex justify-content-between'>
											<MLComboSingle options={{
												value: selectLicense?.id,
												list: licenseList.map(x => {
													return { value: x._id, label: x.title }
												})
											}} handleChanged={(e) => { getTypePeriod(e.target.value); setSelectLicense({ ...selectLicense, id: e.target.value }); }} css="w-100 me-2" />
											{remainTypes && <MLComboSingle options={{
												value: selectLicense?.type,
												list: remainTypes.filter(f => f.value != "normal"),
											}} handleChanged={(e) => { setSelectLicense({ ...selectLicense, type: e.target.value }); }} css="w-100 me-2" />
											}
											{/* <MLNumber options={{
											value: 
										}} */}
											<MLNumber options={{
												value: data?.licenseCount || 0
											}} handleChanged={(e) => setData({ ...data, licenseCount: e.target.value })} />
										</div>
										<div>
											<a href="#" title="Add" onClick={() => setSelectedLicenses([...selectedLicenses, { licenseId: selectLicense.id, licenseType: selectLicense.type, licenseName: licenseList.find(f => f._id === selectLicense.id).title, licenseCount: data?.licenseCount }])}>
												<span className="svg-icon svg-icon-2hx svg-icon-info">
													<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
														<rect fill="currentColor" rx="5" height="20" width="20" y="2" x="2" opacity="0.3"></rect>
														<rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"></rect>
														<rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"></rect>
													</svg>
												</span>
											</a>
										</div>
									</div>
								</div>
								<div>
									<ul className='list-group mt-4 form-group'>
										{selectedLicenses && selectedLicenses.map((x, idx) => <li className="d-flex justify-content-between align-items-center form-control mb-2" style={{ background: "#f2f1f5", borderColor: "#f2f1f5" }} key={idx}>
											{<>
												{x.licenseId && <>
													[{x.licenseName}] - {x?.licenseType} - count : {x?.licenseCount}
													<a href="#" title="Remove" onClick={() => setSelectedLicenses(selectedLicenses.filter(u => u.licenseId !== x.licenseId))}>
														<span className="svg-icon svg-icon-muted svg-icon-2hx">
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"></rect>
																<rect x="7" y="15.3137" width="12" height="2" rx="1" transform="rotate(-45 7 15.3137)" fill="currentColor"></rect>
																<rect x="8.41422" y="7" width="12" height="2" rx="1" transform="rotate(45 8.41422 7)" fill="currentColor"></rect>
															</svg>
														</span>
													</a>
												</>}
											</>}
										</li>)}
									</ul>
								</div>
							</div>
						</div>
					</div>

					<div className='text-end'>
						<MLButton
							options={{ label: t("Cancel"), size: "md", color: COLORS.DEFAULT, action: () => setModal({ ...modal, show: false }) }}
							css={'me-1'} />
						<MLButton
							valid={!!selectedLicenses && !!selectedLicenses.length > 0}
							options={{ label: t("Add License"), size: "md", needValid: true, color: COLORS.PRIMARY, action: () => { setModal({ ...modal, show: false }); updateLicense() } }}
							css={'me-1'} />
					</div>
				</Modal.Body>
			</Modal>
		</div>
	</>
}

const mapState = (state) => {
	const adminUsers = state.AuthReducer.adminUsers
	return { adminUsers };
};

export default connect(mapState, null)(ManagerGroupInfoPage);