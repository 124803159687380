import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import RMWidgetCount from '../widgets/RMWidgetCount';
import { useTranslation } from 'react-i18next';

const DeviceStatCard = ({ items }) => {
	const dispatch = useDispatch();
    const [t] = useTranslation(['translation']);
    const remainDay = 30;
    const remain = new Date().getTime() + (remainDay * 24 * 3600 * 1000);

	useEffect(() => {
	}, []);

  	return (<>
        {items && <div className="d-flex flex-column flex-column-fluid">
            <div className="post p-7">
                <div className='row'>
                    <div className='col'>
                        <RMWidgetCount title={t("전체 Devices")} sub={t("누적 설치 Devices")} value={items.length} iconName="device-desktop" />
                    </div>
                    <div className='col'>
                        <RMWidgetCount title={t("Use RAYTeams")} sub={t("RAYTeams 사용 Devices")} value={items.filter(x => x.ownerId)?.length} iconName="device-desktop-star" />
                    </div>
                    <div className='col'>
                        <RMWidgetCount title={t("설치 국가")} sub={t("Deivce 설치 국가수")} value={[...new Set(items.map(x => x.countryCode))]?.length} iconName="world-pin" />
                    </div>
                    <div className='col'>
                        <RMWidgetCount title={moment().format("YYYY")} sub={t("올해 설치 Devices")} value={items.filter(x => x.registered && moment(x.registered).format("YYYY") === moment().format("YYYY")).length} iconName="calendar-check" />
                    </div>
                    <div className='col'>
                        <RMWidgetCount title={t("당월 설치 Deivce")} sub={moment().format("MM") + t("월 설치된 Device")} value={items.filter(x => x.registered && moment(x.registered).format("MMMMYYYY") === moment().format("MMMMYYYY")).length} iconName="calendar-month" />
                    </div>
                </div>
            </div>
        </div>}
    </>
  	);
}

const mapState = (state) => {
	const region = state.AuthReducer.region;
	return { region };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, null)(DeviceStatCard);
