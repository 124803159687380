import moment from 'moment';
import { useEffect, useState } from 'react';
import { XName, XEmail } from '../common/MLString';
import { COGNITO, CONFIG_REGION_SET } from '../../libs/cogInfo'
import { DEFAULT_SET_GRAPHY, COGNITO_GRAPHY, COGADMIN_GRAPHY } from "../../libs/cognito-graphy";
import { queryData, fetchData } from '../../libs/db'
import { history } from '../../history';
import { FaCrown, FaStore, FaUserTie } from 'react-icons/fa';
import { connect, useDispatch } from 'react-redux';
import { ProgramConstant } from '../../redux/reducers';
import { aws_con } from '../../libs/db';
import MLManagerUser from './MLManagerUser';
import AWS from 'aws-sdk';

const User = ({ _id, hide = true, allusers, region = "ap-northeast-2", noemail = false }) => {
	const dispatch = useDispatch();
	const [ info, setInfo ] = useState({});
	const [ loading, setLoading ] = useState(true);

	useEffect(() => {
		return () => {
			setInfo({});
			setLoading(false);
		};
	}, []);

	useEffect(() => {
		if(_id){
			getUser();
		}
	},[_id]);

	const getUser = async () => {

		const user = allusers.find(x => x._id === _id && x.email);
		if(user){
            setLoading(false);
			setInfo(user);
			return;
		}
		setLoading(true);
		aws_con();

        const uauth = await fetchData("rayteams-auth", { sub: { ComparisonOperator: "EQ", AttributeValueList: [_id], } });
        if(uauth.Items.length > 0){
            region = uauth.Items[0]?.sk.replace("region:", "");
            // console.log(region);
        }else{
            setInfo({ name : "Unknown", email : "?" });
            setLoading(false);
            return;
        }

		// console.log("=======================");
		// console.log(process.env);
		// console.log("=======================");
		const params = {
			Username: _id,
			UserPoolId: process.env.REACT_APP_COMPANY === 'graphy' ? COGNITO_GRAPHY[region].poolId : COGNITO[region].poolId,
		};

		//AWS.config.update({ region: region });
		configAWSRegion(region)

		// console.log("=======================");
		// console.log(params);
		// console.log("=======================");

		var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
		try {
			var item = await cognitoidentityserviceprovider
				.adminGetUser(params)
				.promise();
			var ret = {};
			item?.UserAttributes.map((x) => {
				ret[x.Name.replace("custom:", "")] = x.Value;
			});
			ret._id = ret.sub;
            const uinfo = await queryData("rayteams-user", {
                _id: {
                    ComparisonOperator: "EQ",
                    AttributeValueList: [ret._id],
                },
                sk: {
                    ComparisonOperator: "EQ",
                    AttributeValueList: ["info"],
                },
            });
            if(uinfo.Items.length > 0){
                setInfo({...ret, ...uinfo.Items[0]});
                dispatch({ type : ProgramConstant.GETUSER, user : {...ret, ...uinfo.Items[0]} });
            }else{
                setInfo(ret);
                dispatch({ type : ProgramConstant.GETUSER, user : ret });
            }
		} catch (err) {
			console.log(err);
            setInfo({ name : "Unknown", email : "?" });
        }

		setLoading(false);
	};

	const configAWSRegion = (region) => {
		const _region = CONFIG_REGION_SET[region]
		  ? CONFIG_REGION_SET[region]
		  : region;
		AWS.config.update({
		  region: _region,
		});
	  };
	  

  	return <>
		{loading && <div className="d-flex align-items-center fw-bolder fs-4">
			...
		</div>}
		{!loading && <>
            {info?._id && <a href="#" onClick={() => history.push("/UsersInfo/" + info?._id)}>
                <strong>{!hide ? info?.name : XName(info?.name)}</strong>
                {!noemail && <small className='ms-1'>({!hide ? info?.email : XEmail(info?.email)})</small>}
            </a>}
            {!info?._id && <span className='text-muted'><i>Unregistered</i></span>}
        </>}
	</>
}

const mapState = (state) => {
	const allusers =  state.ProgramReducer.allusers || [];
	return { allusers };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(User);
