import MaterialIcon from '@material/react-material-icon';
import { t } from 'i18next';
import moment from 'moment';
import { LANGUAGE, RMSUBTYPES, USERTYPE } from '../../constants/RMConstants';
import { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { MLFileSize } from '../../components/common/MLCommon';
import { BsCheckCircle, BsCircle } from 'react-icons/bs';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { COLORS } from '../../constants/FromInputs';
import { history } from '../../history';
import { ProgramAction } from '../../redux/actions';
import { MLButton } from './MLButton';
import { MLEmail } from './MLCommon';
import { MLToggle } from './MLInput';
import MLPagination from './MLPagination';
import { MLPanelNoData } from './MLPanel';
import RMGroup from '../manager/RMGroup';
import { RegionList } from '../../libs/cogInfo';
import { COUNTRIES } from '../../constants/Country';
import PartnerUser from '../../components/partner/partneruser';
import User from '../../components/common/MLUser';
import ManagerUser from '../../components/common/MLManagerUser';
import { XEmail, XName } from '../../components/common/MLString';
import { RMIconCircle, RMIconCheckCircle } from '../icon/MLIcon';
import Clinic from '../clinic';
import RTProductAppInfo from '../rayteamsproduct/RTProductAppInfo';
import RMManagerGroup from './RMManagerGroup';
import MLModal from './MLModal';
import { Store } from '../../store';
import 'moment/min/locales';

const navigatorLanguage = navigator.language || "en";
const MLTable = ({ headers, items, filters, linkprefix, paidCallback, patients,wfdata, delKey, maxval, headerClick, itemPerPage = 15,
	callback, noheader, notableheader, products, workflows, nocard, showall, fillHeight, rowkey, selected, selectedKey, order, order2, desc, desc2, selectedColumn,
	codes, orgs, clinics, title, simple, options = {}, holePageWhenUpdate = false, tableWrapCss, callbackClickRow }) => {

	const [filterCols, setFilterCols] = useState(options.filterCols || []);
	const [currentPage, setCurrentPage] = useState(1);
	const [allList, setAllList] = useState([]);
	const [nowList, setNowList] = useState([]);
	moment.locale(navigatorLanguage);
	const getVal = (item, header) => {
		var ret = item[header.value];
		if (header.type === "DATE") {
			ret = ret ? moment.unix(ret / 1000).format("yyyy-MM-DD HH:mm:ss") : "";
		} else if (header.type === 'DATE_TIME_TWO_LINE') {
			const date = moment.unix(ret / 1000);
			ret = <>
				<div>{date.format('yyyy.MM.DD')}</div>
				<small className="text-muted">{date.format('HH:mm:ss')}</small>
			</>
		} else if (header.type === "DATE_UNIX") {
			ret = ret ? moment.unix(ret).format("yyyy-MM-DD") : "";
		} else if (header.type === "DATE_UNIX2") {
			ret = ret ? moment.unix(ret).format("yyyy-MM-DD HH:mm:ss") : "";
		} else if (header.type === "DATE2") {
			ret = ret ? moment(ret).format("yyyy-MM-DD HH:mm:ss") : "";
		} else if (header.type === "DATESHORT") {
			ret = ret ? 
                moment(ret).format("yyyy-MM-DD") : 
                (header.nodatamsg ? <span className="font-italic text-secondary">{header.nodatamsg}</span> : "");
		} else if (header.type === "CODE") {
			ret = ret ? (Store.getState().ProgramReducer.codes.find(x => ret === x.value)?.label || ret) : "";
		} else if (header.type === "TIMESPAN") {
			ret = ret ? moment.unix(ret / 1000).fromNow() : "";
		} else if (header.type === "TIMESPAN_UNIX") {
			ret = ret ? moment.unix(ret).fromNow() : "";
		} else if (header.type === "DATE_REMAIN") {
			ret = ret ? "(" + moment.unix(ret / 1000).fromNow() + ")  " + moment.unix(ret / 1000).format("yyyy-MM-DD HH:mm:ss") : "";
		} else if (header.type === "WORKFLOW") {
			ret = <>
				{wfdata.find(x => x._id === ret)?.title || ret}
			</>
		} else if (header.type === "USERBYID") {
			ret = <>
			</>
		} else if (header.type === "USERBYIDFORBOARD") {
			ret = <>
			</>
		} else if (header.type === "BAR") {
			ret = <>
                <div style={{ width : Math.round((ret / maxval) * 100) + "%"}} className="bg-primary" >&nbsp;</div>
			</>
		} else if (header.type === "COUNT") {
			ret = <>
				{item.comments.length}
			</>
		} else if (header.type === "SIZE") {
			ret = <>
                {MLFileSize(ret)}
			</>
		} else if (header.type === "XName") {
			ret = <>
                {XName(ret)}
			</>
		} else if (header.type === "XEmail") {
			ret = <>
				{XEmail(ret)}
			</>
		} else if (header.type === "CUR") {
            if((!ret || ret === 0) && header.isZero){
                ret = <>{header.isZero}</>
            }else{
                ret = <>
                    {header.curtype && (getPUnit(item[header.curtype]) + '' || "")}
                    {ret && ret.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </>
            }
		} else if (header.type === "CNT") {
			ret = <>
				{ret ? ret.length : 0}
			</>
		} else if (header.type === "TFVALUE") {
			ret = <>
				{ret && <RMIconCheckCircle/>}
				{!ret && <RMIconCircle/>}
			</>
		} else if (header.type === "MUSER") {
			ret = <>
				<ManagerUser _id={ret} hide={false} noemail={header.noemail} />
			</>
		} else if (header.type === "USER") {
			ret = <>
				<User _id={ret} hide={header?.hide ?? true} />
			</>
		} else if (header.type === "USERMODAL") {
			ret = <>
			</>;
		} else if (header.type === "ORDERPHOTO") {
			if (!ret) {
				const p = patients.find(x => x.patientId === item?.patient?.patientId);
				ret = p ? p.photo : ret;
				ret = <>
					<div className="symbol symbol-50px">
					</div>
				</>;
			} else {
				ret = <>
					<div className="symbol symbol-50px">
					</div>
				</>;
			}
		} else if (header.type === "MODAL") {
			if(ret){
				<>
					<div className="symbol symbol-50px" onClick={() => {}}>
					</div>
				</>
			}
		} else if (header.type === "PATIENTPHOTO") {
			ret = <>
				<div className="symbol symbol-50px symbol-circle">
				</div>
			</>;
		} else if (header.type === "PHOTO") {
			ret = <>
				<div className="symbol symbol-35px symbol-circle">
				</div>
			</>;
		} else if (header.type === "PRODUCTOBJ") {
			ret = <>
				<MLProduct product={item} />
			</>;
		} else if (header.type === "PRODUCT") {
			ret = <>
				<MLProductById id={ret} />
			</>;
		} else if (header.type === "LANGUAGE") {
			ret = <>
				{LANGUAGE.find(x => x.code === ret)?.label || ret}
			</>;
		} else if (header.type === "AGE") {
			ret = <>
				{ret} ~ {parseInt(ret) + 9}
			</>;
		} else if (header.type === "INVOICE_KEY") {
			ret = <>
				<span>{ret}</span>
				<span className='ms-1 fs-7'>({item?.orders?.length ?? 0})</span>
			</>;
		} else if (header.type === "CLINICOBJ") {
			ret = <>
				<Clinic id={ret._id} path={header.path} />
			</>;
		} else if (header.type === "CLINIC") {
			ret = <>
				<Clinic cid={ret} />
			</>;
		} else if (header.type === "RAYTEAMSOWNER") {
			ret = <>
				{ret.split(":")[1] !== "undefined" ?
				<div>
					<a onClick={() => history.push("/GroupInfo/" + item?.groupId?.split(":")[0])} className="cursor-pointer me-2">{ret.split(":")[1]}</a>
					<span className='text-gray-600'>{COUNTRIES.find(x => x.countryCode === ret.split(":")[2])?.name}</span>
				</div> :
				<div>
					<div className="font-italic text-secondary">Not assigned</div>
				</div>
				}
			</>;
		}
		else if (header.type === "BADGE") {
			ret = <>
				<span className='badge bg-label-info'>{ret}</span>
			</>;
		} else if (header.type === "SUBORDERTYPE") {
			ret = <>
				<span className='badge bg-label-success'>{ret}</span>
			</>;
		} else if (header.type === "GENDER") {
			ret = <>
				{ret === "M" && <span className='badge badge-success'>{t('Male')}</span>}
				{ret === "F" && <span className='badge badge-primary'>{t('Female')}</span>}
			</>;
		} else if (header.type === "LAB") {
			ret = <>
				<MLLab id={ret} nodata='LAB' />
			</>;
		} else if (header.type === "PRODUCTSEARCH") {
			let ns = products.filter(x => x.orderforms.includes(item._id));
			if (ns.length === 0) {
				const wf = workflows.filter(y => y.flows?.elements?.filter(x => x.data.form === item._id)?.length > 0);
				if (wf.length > 0) {
					ns = products.filter(x => x.workflowId === wf[0]._id || x.prices.filter(y => y.workflowId === wf[0]._id).length > 0);
				}
			}
			ret = <>
				{ns.length > 0 && <MLProductById id={ns[0]._id} />}
				{ns.length === 0 && ret}
			</>;
		} else if (header.type === "FORMTITLE") {
			ret = <>
				<MaterialIcon icon={item.ispop > 0 ? "open_in_new" : "article"} style={{ marginRight: 4 }} />{ret}
			</>;
		} else if (header.type === "ORDERKEY") {
			ret = <div className='d-flex align-items-center'>
				<div>{ret}</div>
				{item.needConfirm && <img src='/icons/general/gen048.svg' alt="need-confirm-img" className='ms-1 gen048' />}
				
			</div>;
		} else if (header.type === "ORDERKEY_SUBORDER") {
			ret = <div className='d-flex align-items-center'>
				<div>{ret}</div>
			</div>;
		} else if (header.type === "PRODUCTPUB") {
			ret = <>
				{ret ? <span className='badge bg-label-success'>PUBLISHED</span> :
					<span className='badge bg-label-warning'>DRAFT</span>}
			</>;
		} else if (header.type === "PRODUCTTYPE" || header.type === "FORMTYPE" || header.type === "SITETYPE") {
			ret = <>
				{getValByCode(ret)}
			</>;
		} else if (header.type === "SITENAME") {
			const ns = clinics.filter(x => x._id === item.clinicId);
			ret = <>
				{ns.length > 0 && <Link to={'/Admin/AdminSiteInfo/' + item.clinicId}>{ns[0].name}</Link>}
				{ns.length === 0 && ret}
			</>;
		} else if (header.type === "USERTYPE") {
			ret = <> {ret ? USERTYPE.find(x => x.value === ret)?.label : ""} </>
		} else if (header.type === "BOARDTYPE") {
			ret = <>
				{ret === "board_qna" && <span className='badge badge-danger'>
					{ret.replace("board_", "")}
				</span>}
				{ret === "board_normal" && <span className='badge badge-info'>
					{ret.replace("board_", "")}
				</span>}
			</>;
		} else if (header.type === 'BOARDTYPES') {
			ret = <>
				{Store.getState().ProgramReducer.codes.filter(x => x.category === 'CMS_TYPE').filter(x => x.value == ret)[0]?.label}
			</>
		} else if (header.type === "CODETITLE") {
			ret = <>
				{item.icon && <MaterialIcon icon={item.icon} style={{ marginRight: 2 }} />}
				{ret}
			</>;
		} else if (header.type === "ORDERPRODUCTFORLAB") {
			ret = <div>
				<MLPatient patient={item.patient} />
				<MLProductById id={item.product._id} orderId={item._id} nolink={true} />
			</div>;
		} else if (header.type === "ORDERPRODUCT") {
			ret = <>
				<MLProductById id={item.product._id} orderId={item._id} />
			</>;
		} else if (header.type === "MEMBERS") {
			ret = <div className='symbol-group symbol-hover'>
			</div>;
		} else if (header.type === "MEMBERSFORCLINIC") {
			ret = <div className='symbol-group symbol-hover symbol-column'>
			</div>;
		} else if (header.type === "ORDERSTATUS") {
			ret = <>
			</>;
		} else if (header.type === "ORDERPROGRESS") {
			ret = <>
			</>;
		} else if (header.type === "ORGTYPE") {
			ret = <>
				{getValByCode(ret)}
			</>;
		} else if (header.type === "ORGNAME") {
			if (!item.orgId) {
				ret = <>Global/Default</>;
			} else {
				const ns = orgs.filter(x => x._id === item.orgId);
				ret = <>
					{ns.length > 0 && <Link to={'/Admin/AdminOrgInfo/' + item.orgId}>{ns[0].name}</Link>}
					{ns.length === 0 && ret}
				</>;
			}
		} else if (header.type === "PUSER") {
			ret = <>
				<PartnerUser _id={ret} />
			</>
		} else if (header.type === "GROUP") {
			ret = <>
				<RMGroup groupId={ret} />
			</>
		} else if (header.type === "LABS") {
			ret = <>
				{ret && ret.map(x => <RMGroup groupId={x} />)}
			</>
		} else if (header.type === "MEDIA") {
			ret = <>
				{ret.length}
			</>
		} else if (header.type === "VIEWMEDIA_STARTORDER") {
			ret = <div className='text-end'>
				<MLButton options={{
					label: t('viewmedia'),
					color: COLORS.WARNING,
					action: () => history.push('/Media/' + item._id)
				}} />
				<MLButton options={{
					label: t('order'),
					color: COLORS.INFO,
				}} css="ms-2" />
			</div>
		} else if (header.type === "VIEWMEDIA") {
			ret = <>
				<MLButton options={{
					label: t('viewmedia'),
					color: COLORS.WARNING,
					action: () => history.push('/Media/' + item._id)
				}} />
			</>
		} else if (header.type === "STARTORDER") {
			ret = <>
				<MLButton options={{
					label: t('order'),
					color: COLORS.INFO,
				}} />
			</>
		} else if (header.type === "PAIDFORLAB") {
			ret = <>
				{ret === 0 && <MLButton options={{
					label: t('complete'),
					size: 'sm',
					color: COLORS.DANGER,
					action: () => paidCallback(item)
				}} />}
				{ret > 0 && moment.unix(ret / 1000).format("yyyy-MM-DD HH:mm:ss")}
			</>
		} else if (header.type === "PAID") {
			ret = <>
			</>
		} else if (header.type === "SITE") {
			const icon = getValByCode(item.type, "");
			ret = <>
				{icon && <MaterialIcon icon={icon} style={{ marginRight: 2 }} />}
				{ret}
			</>
		} else if (header.type === 'TOGGLE') {
			ret = <>
				<MLToggle options={item[header.value]?.options}
					handleChanged={item[header.value]?.handleChanged}
					readonly={item[header.value]?.readonly}
					css={item[header.value]?.css}
				/>
			</>
		} else if (header.type === 'EMAIL') {
			ret = <MLEmail email={ret} emailVerified={item?.emailVerified} />
		} else if (header.type === 'TEXTBUTTON') {
			ret = <a href="#" onClick={() => header.clicked(item)}>{ret}</a>
		} else if (header.type === 'BUTTON_MODAL') {
			ret = ret ? <button className={'btn btn-sm ' + header.btncss} onClick={() => header.btnClick(item)}>{header.label}</button> : <></>;
		} else if (header.type === 'BUTTON') {
			ret = <button className={'btn btn-sm ' + header.btncss} onClick={() => header.btnClick(item)}>{header.label}</button>
		} else if (header.type === 'BUTTON2') {
            ret = <MLButton options={{
                code : header.code,
                label: header.buttonLabel,
                size : "sm",
                color: header.btncss,
				visible: item,
				b2b: header?.b2b,
                action: () => header.btnClick(item)
            }} />
		} else if (header.type === 'BUTTONRENEWAL') {
			const time = new Date().getTime();
			ret = <MLButton options={{
				code : header.code,
				label: header.label,
				size : "sm",
				color: header.btncss,
				disabled: ((item?.activated && ((item?.expiredNumber - time) <= (14 * 1000 * 60 * 60 * 24) && (item?.expiredNumber - time) > 0))) && !item?.renewal && item?.licenseType.toLowerCase() == "normal" ? false : true,
				action: () => header.btnClick(item)
			}} />
		} else if (header.type === "REFUNDBUTTON") {
			ret = <>
				{item?.refund && <button className={'btn btn-sm ' + header.btncss} onClick={() => header.btnClick(item)}>{header.label}</button>}
			</>
		} else if (header.type === 'REGION') {
			if (item[header.value]) {
				const sItem = item[header.value].split(",");
				const rItems = [];
				for (const c of sItem) {
					rItems.push(<span key={c} className='badge badge-success' style={{ marginRight: 4 }}>{RegionList.filter(x => x.value == c)[0]?.label}</span>)
				}
				ret = <>
					{rItems}
				</>
			} else {
				ret = <></>
			}

		} else if (header.type === 'COUNTRY') {
			if (item[header.value]) {
                if (item[header.value].indexOf(",") > -1) {
                    const sItem = item[header.value].split(",");
                    const rItems = [];
                    for (const c of sItem) {
                        rItems.push(<span key={c} className='badge badge-success' style={{ marginRight: 4 }}>{COUNTRIES.filter(x => x.countryCode === c)[0]?.name}</span>)
                    }
                    ret = <>
                        {rItems}
                    </>
                }else{
                    ret = <>
                        {COUNTRIES.filter(x => x.countryCode === ret)[0]?.name}
                    </>
                }
			} else {
				ret = <></>
			}
		} else if (header.type === 'BRANCH') {
			if (item[header.value]) {
				const sItem = item[header.value].split(",");
				const rItems = [];
				for (const c of sItem) {
					rItems.push(<RMManagerGroup _id={c} icon={true}/>)
				}
				ret = <>
					{rItems}
				</>
			} else {
				ret = <></>
			}
		} else if (header.type === 'RTAPP') {
			if (item[header.value]) {
				const rItems = [];
				item[header.value].map(x => {
					rItems.push(<RTProductAppInfo aid={x} icon={true}/>);
				});
				ret = <>
					<div className='d-flex align-items-center'>
						{rItems}
					</div>
				</>
			} else {
				ret =<></>
			}
		}
		else if (header.type === 'APPARRAY') {
            if(ret){
                const rItems = [];
                ret.map((x, idx) => {
                    rItems.push(<div className="badge bg-label-info rounded-0 me-1 ps-2 pe-1 fs-7 mb-1" id={`${idx}-${x.name}`}>{x.name} <span className="bg-white text-primary px-2">{x.version}</span></div>)
                });
                ret = <>{rItems}</>
            }else{
                ret = <span className="text-muted">Not Installed</span>
            }
        }
		else if (header.type === 'LINK') {
			ret = <span className='cursor-pointer' onClick={() => header.callback(item)}>{ret}</span>
        }
		else if (header.type === 'ARRAY') {
            const rItems = [];
            ret.map(x => {
                rItems.push(<span className="badge bg-label-info me-1">{x[0].toUpperCase() + x.slice(1)}</span>)
            });
			ret = <>{rItems}</>
        }
		else if (header.type === 'ARRAYVALUE') {
			var splitItem = header.value.split('.');
			ret = <>{item[splitItem[0]]?.[splitItem[1]]}</>
		} else if (header.type === 'REMOVE') {
			ret = <>
				{ret.replace(header.target, "")}
			</>
		} else if (header.type === "APPROVED") {
			ret = <>
				<span className={[
					"badge",
					(ret ? "bg-label-primary" : "bg-label-warning")
				].join(" ")}>
					{ret ? t("Approved") : t("pending") }
				</span>
			</>
		} else if (header.type === "HIDE"){
			ret = <></>
		}

		if (header.link) {
			if(Array.isArray(header.link)){
				const addPath = header.link.map(x => item[x]).join("/");
				ret = <Link to={(linkprefix ? linkprefix : "") + header.linkpath + '/' + addPath}>{ret}</Link>
			}else{
				if (header.linkappend) {
					ret = <Link to={(linkprefix ? linkprefix : "") + header.linkappend + item[header.link]}>{ret}</Link>
				} else if (header.linkMultivalue) {
					const sItem = ret.split(",");
					const rItems = [];
					for (const c of sItem) {
						rItems.push(<Link to={(linkprefix ? linkprefix : "") + header.linkpath + '/' + c.trim()}>{c + ", "}</Link>)
					}
					ret = <>
						{rItems}
					</>
				} else {
					if(header.type === 'LINKARRAYVALUE') {
						var splitItem = header.value.split('.');
						var splitItemLink = header.link.split('.');
						ret = <> <Link to={(linkprefix ? linkprefix : "") + header.linkpath + '/' + item[splitItemLink[0]]?.[splitItemLink[1]]}>{item[splitItem[0]]?.[splitItem[1]]}</Link> </>
					} else {
						ret = <> <Link to={(linkprefix ? linkprefix : "") + header.linkpath + '/' + item[header.link]}>{ret}</Link> </>
					}
				}
			}
		} else if (header.callback && callback) {
			ret = <Link to="#" onClick={() => callback(item)}>{ret}</Link>
		}
		return ret;
	}

	const getPUnit = (punit) => {
		if(punit === "USD")
			return "$";
		if(punit === "KRW")
			return "₩";
		if(punit === "EUR")
			return "€";
		return punit;
	}

	const getRow = (item) => {
		var ret = [];
		headers.filter(x => simple ? !x.FULL : true).map((x, idx) => ret.push(<td key={'td-' + idx + (item?._id)} className={x.css || ''}>{getVal(item, x)}</td>));
		return ret;
	}

	useEffect(() => {
		if (options.filterCols)
			setFilterCols(options.filterCols || []);
	}, [options]);

	useEffect(() => {
		var list = filterItems(items);
		setAllList(list);
	}, [items]);

	useEffect(() => {
		!holePageWhenUpdate && setCurrentPage(1);
		setNowList(filteredData(allList));
	}, [allList, filterCols]);

	const paginate = (list) => {
		return showall ? list : list.slice((currentPage - 1) * itemPerPage, currentPage * itemPerPage);
	};

	const filterItems = () => {
		if (filters) {
			var tmp = [];
			Object.keys(filters).forEach(x => {
				var it = items.filter(y => {
					if (Array.isArray(filters[x])) {
						return filters[x].includes(y[x]);
					} else {
						return y[x] === filters[x];
					}
				});
				it.forEach(m => {
					if (tmp.filter(f => f._id === m._id).length === 0)
						tmp.push(m);
				})
			})
			return tmp;
		}
		return items;
	}

	const getColGroup = (f) => {
		return [
			...new Set(
				allList.map((item) => {
					return item[f.col];
				}, [])
			),
		].sort((a, b) => a < b ? -1 : 1);
	};

	const handleFilter = (e, idx) => {
		const newFilter = [...filterCols];
		if (e.value === "true" || e.value === "false") {
			newFilter[idx].val = e.value === "true" ? true : false;
		} else {
			newFilter[idx].val = e.value;
		}
		setFilterCols(newFilter);
	};

	const filteredData = (originData) => {
		var nData = [];
		var nFilter = [...filterCols.filter(x => x.val !== "ALL")];
		if (nFilter.length > 0) {
			nData = originData.filter(x => {
				const filterResult = [];
				for (var i = 0; i < nFilter.length; i++) {
					filterResult.push(x[nFilter[i].col] === nFilter[i].val)
				}
				return filterResult.filter(Boolean).length === nFilter.length;
			})
			return nData;
		} else {
			return originData;
		}
	};

	const getValByCode = (val, nullVal = "") => {
		if (Array.isArray(val)) {
			const ns = Store.getState().ProgramReducer.codes.filter(x => val.includes(x.value));
			return ns.length > 0 ? (ns.map(m => m.title).join(", ") || (nullVal || val)) : (nullVal || val);
		} else {
			const ns = Store.getState().ProgramReducer.codes.filter(x => val === x.value);
			return ns.length > 0 ? (ns[0].title || (nullVal || val)) : (nullVal || val);
		}
	}

	const getValByPublished = (val, nullVal = "") => {
		return val ? "PUBLISHED" : "DRAFT";
	}

	const getOptions = (item, x) => {
		var ret = x;
		if (item.type === "CODE") { ret = getValByCode(x) }
		if (item.type === "PUBLISHED") { ret = getValByPublished(x) }
		return {
			value: x,
			label: ret
		}
	}

	const getOptionList = (item, options) => {
		var ret = [{ value: "ALL", label: "ALL" }];
		return ret.concat(options.map(x => getOptions(item, x)));
	}

	const sel = (x) => {
		if(selected){
			return x[selectedKey] === selected[selectedKey] ? " bg-primary text-white " : "";
		}
		return "";
	}

    const trCss = (item) => {
        const fl = headers.find(x => x.falsecss)
        if(fl){
            return item[fl.value] ? "" : fl.falsecss;
        }
        return "";
    }

	return <>
		{(!noheader && nowList.length > 0) && <>
			<div className='d-flex justify-content-between pb-2'>
				<div className='align-items-center my-1'>
					<h3 className="fw-bolder me-5 my-1">{title ? title + "(" + nowList.length + ")" : nowList.length}</h3>
				</div>
				<div style={{ width: '200px'}}>
					{options.filterCols && options.filterCols.map((item, idx) => {
						const list = getOptionList(item, getColGroup(item));
						return (
							<Select
								key={idx}
								onChange={(e) => handleFilter(e, idx)}
								value={list.filter(x => x.value === item.val)}
								options={list}
								className="form-select-transparent ms-1" />
						);
					})}
				</div>
			</div>
		</>}
		{nowList.length > 0 && <div className={"datatables-users table dataTable no-footer dtr-column " +  (nocard ? '' : 'card ') + (tableWrapCss ? tableWrapCss : '') + (fillHeight ? ' flex-fill' : '')}>
			<div className={(nocard ? '' : 'card-body p-0') + (fillHeight ? ' flex-fill d-flex flex-column' : '')}>
				{nowList.length > 0 && <Table className='table table-row-dashed table-row-gray-300 gy-4 gx-4 mb-0'>
					{!notableheader && <thead className='border-top'>
						<tr className='fw-bolder fs-6 text-gray-800'>{headers.filter(x => simple ? !x.FULL : true).map((x, idx) =>
							<th key={"header-" + idx} className={(x.css || '') + (order === x.value || order2 === x.value ? " cursor-pointer" : "")} 
								style={x.style && x.style}
								onClick={() => (order === x.value || order2 === x.value ? (headerClick && headerClick(x)) : "")} >
								{x.label}
								{order === x.value && selectedColumn === x.value ? (desc === "desc" ? <MdOutlineKeyboardArrowDown /> : <MdOutlineKeyboardArrowUp />) 
								: order === x.value ? (desc === "desc" ? <MdOutlineKeyboardArrowDown /> : <MdOutlineKeyboardArrowUp />) : ""}
								{order2 === x.value && selectedColumn === x.value ? (desc2 === "desc" ? <MdOutlineKeyboardArrowDown /> : <MdOutlineKeyboardArrowUp />) 
								: order2 === x.value ? (desc2 === "desc" ? <MdOutlineKeyboardArrowDown /> : <MdOutlineKeyboardArrowUp />) : ""}
							</th>)}
						</tr>
					</thead>}
					<tbody>
						{paginate(nowList).map((x, idx) => <tr key={'tbody-' + (rowkey ? x[rowkey] : idx)} 
							className={(callbackClickRow ? " cursor-pointer " + sel(x) : "") + " " + trCss(x) + (idx % 2 === 0 ? " odd " : " even ")} 
							onClick={() => callbackClickRow && callbackClickRow(x)}>
							{getRow(x)}
						</tr>)}
					</tbody>
				</Table>}
				{fillHeight && <div className='flex-fill' />}
				{(!showall && nowList.length > itemPerPage) && <div className='mt-3 d-flex justify-content-end'>
					<MLPagination
						pageSize={itemPerPage}
						totalItems={nowList.length}
						curPage={options?.useapi ? 1 : currentPage}
						paginate={setCurrentPage} ></MLPagination>
				</div>}
			</div>
		</div>}
		{nowList.length === 0 && <MLPanelNoData noborder={true} />}
	</>
}

const mapState = (state) => {
};

const mapDispatch = dispatch => ({
});

export default connect(null, mapDispatch)(MLTable);
