import { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import RMFeedBackInfo from '../../components/feedbacks/RMFeedBackInfo';
import RMFeedBackContent from '../../components/feedbacks/RMFeedBackContent';
import { ProgramConstant, PaymentConstant } from '../../redux/reducers';

const FeedBacksInfoPage = ({ page }) => {

    const { _id } = useParams();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch({ type : ProgramConstant.SET_PAGE, page : { 
			code : "feedbacks", 
			view : "summary", 
			title : "Payments", 
			small : "사용자 피드백에 대한 관리", 
			options : []
		}})
	}, []);

  	return <div className='p-4'>
        <RMFeedBackInfo />
        <RMFeedBackContent />
    </div>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const groups =  state.AuthReducer.groups;
	const region =  state.AuthReducer.region;
	const regions = state.AuthReducer.regions;
	const regionusers = state.AuthReducer.regionusers;
	const regionlist = state.AuthReducer.regionlist;
	const page = state.ProgramReducer.page;

	return { region, regions, user, groups, regionusers, regionlist, page };
};

export default connect(mapState, null)(FeedBacksInfoPage);