const initialState = { 
	item : [],
	items : [],
	list_loading : false,
	item_loading : false
};

export const RTFormConstant = {
	GET_ALL : "GET_FORMS",
	GET_ALL_LOADING : "GET_ALL_LOADING",
	GET : "GET_FORM",
	CREATE : "CREATE_FORM",
	UPDATE : "UPDATE_FORM",
	REMOVE : "REMOVE_FORM",
};

export const RTFormReducer = (state = initialState, action) => {
	switch (action.type) {
		case RTFormConstant.GET_ALL:
			return {...state, items : action.items, list_loading : false };
		case RTFormConstant.GET_ALL_LOADING:
			return {...state, list_loading : action.item };
		case RTFormConstant.GET:
			return {...state, 
				item : action.item,
				items :state.items.map(x => (x._id === action.item._id && x.sk === action.item.sk) ? action.item : x )
			};
		case RTFormConstant.CREATE:
			return {...state };
		case RTFormConstant.UPDATE:
				return {...state }
		case RTFormConstant.REMOVE:
			return {...state, items : items.filter(x => x._id !== action.item._id)};
		default:
			return state;
	}
};
