import MaterialIcon from '@material/react-material-icon';
import { useCallback, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { GrBarChart } from 'react-icons/gr';
import { Store } from '../../store';
import { useTranslation } from 'react-i18next';
import { BsCheckLg } from 'react-icons/bs';
import { COLORS } from '../../constants/FromInputs';
import { RMBTNS } from '../../constants/RMConstants';
import { history } from '../../history';

export const MLDropDownButton = ({ title, color = "", buttons, css }) => {

	return <Dropdown className={css && css} as={'ul'}>
		<Dropdown.Toggle variant={color} size='sm' as={'li'}>
			{title}
		</Dropdown.Toggle>
		<Dropdown.Menu as={'li'}>
			{buttons.map((x, idx) => <Dropdown.Item onClick={x.callback} key={idx} className="dropdown-item">
				{x.label}
			</Dropdown.Item>)}
		</Dropdown.Menu>
	</Dropdown>
}

export const MLChartButton = ({ onClick, css }) => {

	return <a href="#" className={"btn btn-icon pulse " + css} onClick={onClick}>
        <GrBarChart />
    </a>
}

export const MLRefreshButton = ({ onClick }) => {

	return <a href="#" className="btn btn-icon btn-light pulse" onClick={onClick}>
        <span className="cursor-pointer">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.5 20.7259C14.6 21.2259 14.2 21.826 13.7 21.926C13.2 22.026 12.6 22.0259 12.1 22.0259C9.5 22.0259 6.9 21.0259 5 19.1259C1.4 15.5259 1.09998 9.72592 4.29998 5.82592L5.70001 7.22595C3.30001 10.3259 3.59999 14.8259 6.39999 17.7259C8.19999 19.5259 10.8 20.426 13.4 19.926C13.9 19.826 14.4 20.2259 14.5 20.7259ZM18.4 16.8259L19.8 18.2259C22.9 14.3259 22.7 8.52593 19 4.92593C16.7 2.62593 13.5 1.62594 10.3 2.12594C9.79998 2.22594 9.4 2.72595 9.5 3.22595C9.6 3.72595 10.1 4.12594 10.6 4.02594C13.1 3.62594 15.7 4.42595 17.6 6.22595C20.5 9.22595 20.7 13.7259 18.4 16.8259Z" fill="currentColor"></path><path opacity="0.3" d="M2 3.62592H7C7.6 3.62592 8 4.02592 8 4.62592V9.62589L2 3.62592ZM16 14.4259V19.4259C16 20.0259 16.4 20.4259 17 20.4259H22L16 14.4259Z" fill="currentColor"></path>
            </svg>
        </span>
    </a>
}

export const MLMoreButton = ({ text = 'more', link }) => {

	const [t] = useTranslation(['translation']);

	return <MLButton options={{
		label: t(text),
		color: COLORS.PRIMARY,
		size: 'sm',
		action: () => history.push(link)
	}} />
}

export const MLButton = ({ options, valid = true, css = "", permit, formaction, style }) => {

	const visible = true;
	const [disabled, setDisabled] = useState(false);
    const perms = Store.getState().AuthReducer?.loginuser?.perms || [];

	const b2b = options?.b2b || "ray";

	const clicked = () => {
		options.clickdisbled && setDisabled(true);
		if(formaction){
			formaction()
		}else{
			options.action && options.action();
		}
	}

	return <>
		{(visible && (options.code ? perms.includes(options.code) : true)) && <>
			{options.img && <button className='rayiconbtn'>
				<img src={options.img}
					className={'raycur'} alt=""
					onClick={clicked} />
			</button>}
			{!options.img && b2b == "ray" &&
				<button className={"shadow-none btn " +
					(RMBTNS.find(x => x.code === options.code)?.color || (options.color ? ' btn-' + options.color : '')) +
					(options.icon ? ' btn-icon' : '') +
					(options.size ? ' btn-' + options.size : '') +
					(css && ' ' + css)}
					style={style}
					onClick={clicked}
					type="button" disabled={(options.needValid && !valid) || options.disabled || disabled || options.visible?.activated || options.visible?.rfstatus == "done" || options.visible?.rfstatus == "fail" }>
					{options.icon && <MaterialIcon icon={options.icon} className={options.iconCss ?? ''} />}
					{options.iconobj && options.iconobj}
					{RMBTNS.find(x => x.code === options.code)?.title || options.label}
				</button>
			}
			{!options.img && b2b != "ray" &&
				<button className={"shadow-none btn " +
					(RMBTNS.find(x => x.code === options.code)?.color || (options.color ? ' btn-' + options.color : '')) +
					(options.icon ? ' btn-icon' : '') +
					(options.size ? ' btn-' + options.size : '') +
					(css && ' ' + css)}
					style={style}
					onClick={clicked}
					type="button" disabled={(options.needValid && !valid)}>
					{options.icon && <MaterialIcon icon={options.icon} className={options.iconCss ?? ''} />}
					{options.iconobj && options.iconobj}
					{RMBTNS.find(x => x.code === options.code)?.title || options.label}
				</button>
			}
		</>}
	</>
}

export const MLDownloadButton = ({ options, valid = true, css = "", permit, formaction, filePath, fileName, callback }) => {
	const [btnOptions, setBtnOptions] = useState(options);
	const [downloadProgress, setDownloadProgress] = useState(0);
	const [loading, setLoading] = useState(false);

	const onDownloadProgress = (event) => {
		const { loaded, total } = event
		const percent = Math.ceil(loaded / total * 100);
		setDownloadProgress(percent < 100 ? percent : 0)
	}

	const downloadFile = useCallback(async () => {
		if (!filePath) {
			return;
		}
		try {
			setLoading(true);
			let downLoadFileName = fileName;
			if (!downLoadFileName) {
				const pathArr = filePath.split('/');
				downLoadFileName = pathArr[pathArr.length - 1];
			}
			//await saveFile(filePath, downLoadFileName, onDownloadProgress);
			callback && callback(true);	
		} catch (error) {
			callback && callback(false);	
		} finally {
			setLoading(false);	
		}
	}, [callback, filePath, fileName]);

	useEffect(() => {
		setBtnOptions({ ...options, action: downloadFile, disabled: (loading ? true : options.disabled) })
	}, [downloadFile, options, loading]);

	return <div className='position-relative d-grid'>
		<MLButton options={btnOptions} valid={valid} css={css} permit={permit} formaction={formaction} />
		{downloadProgress > 0 && <div className='position-absolute start-0 end-0 top-0 bottom-0 bg-red'>
			<div className="progress position-absolute flex-fill"  style={{
				bottom: 4,
				left: 8,
				right: 8,
				height: 2
			}}>
				<div className="progress-bar" style={{ width: `${downloadProgress}%` }} />
			</div>
		</div>}
	</div>
}

export const MLCheckButton = ({ options, valid = true, css = "" }) => {

	const getCss = () => {
		var ret = "btn";
		if (options.checked) {
			ret += options.color ? ' btn-' + options.color : '';
		} else {
			ret += options.color ? ' btn-outline btn-outline-' + options.color : '';
		}
		ret += options.size ? ' btn-' + options.size : '';
		ret += css ? ' ' + css : '';
		return ret;
	}

	return <button className={getCss()}
		onClick={options.action && options.action}
		type="button" disabled={options.needValid && !valid}>
		{options.checked && <BsCheckLg className='me-2' />}
		{options.label}
	</button>
}

export const MLSubmit = ({ options, valid = true, callback, disabled }) => {

	return <>
		{options.block && <div className="d-grid gap-2 mt-2">
			<button className={"btn" +
				(options.color ? ' btn-' + options.color : '') +
				(options.size ? ' btn-' + options.size : '')}
				onClick={callback && callback}
				type="button" disabled={disabled || (options.needValid && !valid)}>
				{options.icon && <MaterialIcon icon={options.icon} />}
				{options.label}
			</button>
		</div>}
		{!options.block && <button className={"btn" +
			(options.color ? ' btn-' + options.color : '') +
			(options.size ? ' btn-' + options.size : '')}
			onClick={callback && callback}
			type="button" disabled={disabled || (options.needValid && !valid)}>
			{options.icon && <MaterialIcon icon={options.icon} />}
			{options.label}
		</button>}
	</>
}
