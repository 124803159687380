import axios from 'axios';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { MLText } from '../../components/common/MLInput';
import { MLPanelLoading } from '../../components/common/MLPanel';
import Select from 'react-select';
import MLTable from '../../components/common/MLTable';
import { AuthConstant, getAPI, getHeader } from '../../redux/reducers/AuthReducer';
import { fetchData } from '../../libs/db';
import WidgetBarChartAppUsage from '../../components/widgets/dashboard/WidgetBarChartAppUsage';
import RMWidgetBarChart from '../../components/widgets/RMWidgetBarChart';
import RMWidgetLineChart from '../../components/widgets/RMWidgetLineChart';
import { COUNTRIES } from '../../constants/Country';
import { MLButton, MLRefreshButton } from '../../components/common/MLButton';
import { history } from '../../history';
import { COLORS } from '../../constants/FromInputs';
import { RMIconList, RMIconChart } from '../../components/icon/MLIcon';
import { MLPanelNoData } from '../../components/common/MLPanel';
import { ProgramConstant, PaymentConstant } from '../../redux/reducers';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
const UserSub = ({ _id }) => {
	const [t] = useTranslation(['translation']);
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
        if(_id)
            getData();
	}, [_id]);

    const btnClick = (item) => {
        console.log(item._id);
        console.log(item.prdid);
    }

	const getData = async () => {
		setLoading(true);
		const ScanFilter = {
			_id: {
				ComparisonOperator: "EQ",
				AttributeValueList: [_id],
			},
			sk: {
				ComparisonOperator: "BEGINS_WITH",
				AttributeValueList: ["product:"],
			},
		}
		try {
			const ret = await fetchData("rayteams-user", ScanFilter);
            setData(ret.Items.
                sort((a, b) => a.expired > b.expired ? -1 : 1).
                map(x => ({...x, 
                    active : (x.expired > (new Date().getTime())), 
                    prdid : x.sk.replace("product:", ""),
                    subid : "SUB-" + x.started,
                })) 
            );
		} catch (e) {
			console.log(e);
		}
		setLoading(false);
	}

    const headers = [
		{ value : "active", label : "", type : "TFVALUE", style : { width : 30 }, falsecss: "text-muted" },
		{ value : "subid", label : t("SubID"), css : "text-start", style : { width : 180 } },
		{ link : "prdid", linkpath : "/RTProductInfo", value : "title", label : t("Product"), css : "text-start", style : { width : 180 } },
		{ value : "_id", label : t("User"), type : "USER",  css : "text-start" },
		{ value : "ccode", label : t("Country"), type : "COUNTRY", style : { width : 100 } },
		{ value : "realprice", label : t("Price"), css : "text-end", type : "CUR", isZero : "Free",  style : { width : 80 } },
		{ value : "punit", label : t("Unit"), css : "text-center", style : { width : 100 } },
		{ value : "started", label : t("Started"), type : "DATESHORT", style : { width : 120 } },
		{ value : "lastrenew", label : t("Last-renew"), type : "DATESHORT", style : { width : 120 } },
		{ value : "expired", label : t("Expired"), type : "DATESHORT", style : { width : 120 } },
		{ value : "expired", label : "Remained", type : "TIMESPAN", style : { width : 120 } },
		{ value : "", label : t("Logs"), type : "BUTTON", style : { width : 120 }, 
            btncss : "btn-secondary", 
            btnClick : btnClick },
    ]

  	return (<>
            {data.length > 0 && <> {!loading && <MLTable headers={headers} items={data} noheader={true} nocard={true} />} </>}
            {data.length === 0 && <MLPanelNoData noborder={true} />}
		</>
  	);
}

const mapState = (state) => {
	const region = state.AuthReducer.region;
	const subscriptions = state.PaymentReducer.subscriptions;
	const regionusers = state.AuthReducer.regionusers;
	const pagestat = state.ProgramReducer.pagestat["subscriptionlist"] || {};
	return { region, subscriptions, regionusers, pagestat };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, null)(UserSub);
