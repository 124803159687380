import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { history } from '../../history';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { FaSave, FaEdit, FaWindowClose, FaCheckCircle, FaStopCircle } from 'react-icons/fa';
import { ProgramConstant } from '../../redux/reducers';
//import Clinic from '../components/clinic';
import Clinic from '../partner/clinic';
import { COLORS } from '../../config/color';
import { RTButton } from '../../libs/buttons';
import { fetchData } from '../../libs/db';
import RMGroup from '../manager/RMGroup';

const CompDeviceInfo = ({ user, title, device, allusers, allgroups }) => {

	const dispatch = useDispatch();

	const [t] = useTranslation(['translation']);
	const [ items, setItems ] = useState([]);
	const [ edit, setEdit ] = useState(false);
	const [ data, setData ] = useState({});
	const [ process, setProcess ] = useState(false);

	useEffect(() => {
		if(device){
			setData({
				isactive : device.isactive,
				labs : device.labs,
				partnerId : device.partnerId
			});
		}
	}, [device]);

	const update = async () => {
		setProcess(true);

		// const ret = await updateData("rayteams-device", { sn : device.sn, sk : "info" }, data);
		// dispatch({ 
		// 	type : ProgramConstant.UPDATEDEVICE, 
		// 	item : ret
		// });

		// setProcess(false);

		setEdit(false);
	}

	const getLabs = () => {
		const gItems = [];
		device.labs?.map((x, idx) => {
			gItems.push(<RMGroup gid={x} readonly={edit} />);
			if(idx < device.labs.length - 1)
				gItems.push(<span>, </span>);
		});
		return gItems;
	}

	return <>
		{device && <div className="card-body">
			<div className="py-2">
				<label className="label fw-bolder">{t("Partner")}</label>
				<div>
                    {device.partnerKey || "Ray"}
				</div>
			</div>
			<div className="py-2">
				<label className="label fw-bolder">{t("Lab")}</label>
				<div>
					{!edit && getLabs()}
				</div>
			</div>
			<div className="py-2">
				<label className="label fw-bolder">{t("Sharing")}</label>
				<div>
					{!edit && (data.isactive ? <><FaCheckCircle size="20" /> {t("Enabled")}</> : <><FaStopCircle size="20" /> {t("Disabled")}</>)}
					{edit && <div className="form-check form-switch form-check-custom form-check-solid">
						<input className="form-check-input" 
							type="checkbox" 
							value={data.isactive} 
							checked={data.isactive ? "checked" : ""} 
							onChange={() => setData({...data, isactive : !data.isactive})}/>
						<label className="form-check-label fs-6 text-gray-800">
						</label>
					</div>}
				</div>
			</div>
			<div className="py-2">
				<label className="label fw-bolder">{t("Clinic")}</label>
				<div>
					<Clinic cid={device.ownerId} />
				</div>
			</div>
			<div className="py-2">
				<label className="label fw-bolder">{t("Created")}</label>
				<div>
					{moment(device.created).format("LL")}
				</div>
			</div>
			<div className="py-2">
				<label className="label fw-bolder">{t("Activated")}</label>
				<div>
					{moment(device.activated).format("LL")}
				</div>
			</div>
		</div>}
	</>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;

	return { user };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(CompDeviceInfo);
