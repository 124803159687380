import moment from 'moment';
import { useEffect, useState } from 'react';
import { XName, XEmail } from '../common/MLString';
import { COGNITO } from '../../libs/cogInfo'
import { COUNTRIES } from '../../constants/Country'
import { idData } from '../../libs/db'
import { history } from '../../history';
import { FaCrown, FaStore, FaUserTie } from 'react-icons/fa';
import { connect, useDispatch } from 'react-redux';
import { ProgramConstant } from '../../redux/reducers';
import { aws_con } from '../../libs/db';
import AWS from 'aws-sdk';

const ProductTitle = ({ info }) => {
	const dispatch = useDispatch();
	const [ process, setProcess ] = useState(false);

	useEffect(() => {
	},[]);

    const getCountry = (c) => {
        const r = c?.split(":")[2] || c;
        return COUNTRIES.find(x => x.countryCode === r)?.name || c;
    }

  	return <>
        {info?.title && <a href="#" onClick={() => history.push("/RTProductInfo/" + info?.prdid)}>
            <strong>{info?.title}</strong>
            {<span className='ms-2 text-gray-600'>{getCountry(info?.prdsk)}</span>}
        </a>}
	</>
}

const mapState = (state) => {
	const allusers =  state.ProgramReducer.allusers || [];
	return { allusers };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(ProductTitle);
