import { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { history } from '../../history';
import { ProgramConstant } from '../../redux/reducers';
import LabProductAdd from '../../components/labproduct/LabProductAdd';
import LabProductForms from '../../components/labproduct/LabProductForms';
import { MLButton } from '../../components/common/MLButton';
import LabProductConsent from '../../components/labproduct/LabProductConsent';
import LabProductSpec from '../../components/labproduct/LabProductSpec';

const LabProductInfoPage = ({ }) => {
	const dispatch = useDispatch();

	const [t] = useTranslation(['translation']);
	const { _id } = useParams("");
	const [readonly, setReadOnly] = useState(true);
	const [prd, setPrd] = useState({});
	const [updatedFlag, setUpdatedFlag] = useState(false);

	const noticeUpdated = useCallback(() => {
		setUpdatedFlag(true);
		setTimeout(() => {
			setUpdatedFlag(false);
		}, 200);
	}, []);

	useEffect(() => {
		dispatch({
			type: ProgramConstant.SET_PAGE, page: {
				code: "b2bproductinfo",
				view: "summary",
				title: "Product",
				small: t("Product 정보"),
				options: [
				]
			}
		})
	}, []);

	const pub = async () => {
	}

	return <div className='p-4'>
		<div className='card mb-3'>
			<div className='card-header pb-0'>
				<div className='d-flex justify-content-between align-items-center'>
					<h4 className='mb-0'>{prd.title}</h4>
					<div className="d-flex">
						<MLButton options={{
							label: t("list"),
							color: 'light',
							action: () => history.push('/LabProductPage')
						}} css='ms-1' />
					</div>
				</div>
			</div>
			<div className='card-body'></div>
		</div>
		<div className='mt-2'>
			<div className='row'>
				<div className='col-6'>
					<div className='d-flex justify-content-between align-items-center mb-2'>
						<div className='fw-bolder'>{t("Product Info")}</div>
						{readonly && <MLButton options={{
							code: "prod-infoedit",
							label: t("Edit"),
							color: 'warning',
							size: 'sm',
							action: () => setReadOnly(false)
						}} />}
					</div>
					<LabProductAdd
						readonly={readonly}
						callBackCancel={() => setReadOnly(true)}
						callbackUpdate={() => {
							setReadOnly(true);
							noticeUpdated();
						}}
						callBackInfo={(x) => setPrd(x)} />
				</div>
				<div className='col-6'>
					{!updatedFlag && <>
						{prd.category && <div className='mb-4'>
							<LabProductSpec />
						</div>}
					</>}
					<div className='mb-4'>
						<LabProductForms />
					</div>
					<div className=''>
						<LabProductConsent />
					</div>
				</div>
			</div>
		</div>
	</div>
}

const mapState = (state) => {
	return {};
};

const mapDispatch = (dispatch) => ({

});

export default connect(mapState, null)(LabProductInfoPage);
