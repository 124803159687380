import { Editor } from '@tinymce/tinymce-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';

const TinyEditor = ({ originContent = "", callbackOnChange, placeholder, insertHTML = "" }) => {
    const [value, setValue] = useState(originContent);
    useEffect(() => {
        setValue(originContent);
    }, [originContent])
    const editorRef = useRef(null);
    useEffect(() => {
        callbackOnChange && callbackOnChange(value);
    }, [value]);
    const onEditorChange = (newValue, editor) => {
        setValue(newValue)
    };
    useEffect(() => {
        if (insertHTML) {
            editorRef.current.execCommand("mceInsertContent", false, insertHTML);
        }
    }, [insertHTML]);

    return <>
        <Editor
            tinymceScriptSrc="/js/tinymce/tinymce.min.js"
            onInit={(evt, editor) => editorRef.current = editor}
            textareaName={'content'}
            value={value}
            onEditorChange={onEditorChange}
            init={{
                height: 500,
                skin: 'oxide', // oxide, oxide-dark, tinymce-5, tinymce-5-dark
                menubar: false,
                placeholder: placeholder,
                statusbar: false,
                fullscreen_native: true,
                selector: 'textarea',
                browser_spellcheck: true,
                plugins: [
                    'link', 'code', 'lists'
                ],
                toolbar: 'h1 h2 h3 h4 h5 | formatselect | fontsizeselect | ' +
                    'bold italic underline forecolor backcolor | alignleft aligncenter ' +
                    'alignright alignjustify | link | bullist numlist outdent indent | ' +
                    'removeformat | preview | code',
                fontsize_formats: '10px 14px 18px 24px 36px',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }'
            }}
        />
    </>
};

export default TinyEditor;
