import axios from 'axios';
import { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { connect, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { BiHelpCircle } from 'react-icons/bi';
import { fetchData, delData, updateData, insertData } from '../../libs/db';
import { MLButton } from '../../components/common/MLButton';
import { history } from '../../history';
import { BUTTONS, COLORS } from '../../constants/FromInputs';
import { MLPanelDelete } from '../../components/common/MLPanel';
import { useTranslation } from 'react-i18next';
import { RTAudit } from '../../libs/audit';

const PartnerAddPage = ({ groups, region, regionusers }) => {

	const dispatch = useDispatch();
	const [t] = useTranslation(['translation']);
	const { _id } = useParams("");
	const [parentPartner, setParentPartner] = useState({});
	const [help, setHelp] = useState(window.localStorage.getItem("helpmsg") === "N" ? false : true);
	const [data, setData] = useState({});
	const [process, setProcess] = useState(false);
	const [err, setErr] = useState("");
	const [delConfirm, setDelConfirm] = useState(false);

	useEffect(() => {
		init();
	}, []);

	useEffect(() => {
		window.localStorage.setItem("helpmsg", help ? "Y" : "N");
	}, [help])

	const init = async () => {
		if (_id) {
			await getParentData();
		}
	}

	const getParentData = async () => {
		const ScanFilter = {
			_id: {
				ComparisonOperator: "EQ",
				AttributeValueList: [_id],
			},
			sk: {
				ComparisonOperator: "EQ",
				AttributeValueList: ["info"],
			},
		}
		const ret = await fetchData("rayteams-partner", ScanFilter);
		if (ret.Items.length > 0) {
			setData(ret.Items[0]);
		} else {
			history.push("/Partners");
		}
	}

	const createGroup = async () => {
		setProcess(true);

		//user create
		const region = 'ap-northeast-2';
		const partnerId = v4();
		const newUser = {
			name: data.username,
			email: data.useremail,
			password: data.userpassword,
			region,
			grouptype: "partner",
			groupId: data.partnerKey,
			partnerId,
			partnerKey: data.partnerKey,
		}
		const domain = location.href.indexOf(".rayteams.com") > -1 ? ".rayteams.com" : ".raydevelop.com";
		var retMember = await axios.post("https://api-" + region + "-user" + domain + "/user", {
			data: newUser
		}, {
			headers: {
				'x-ray-device': "RAYLINK"
			}
		}
		);

		if (retMember.data.status === "fail") {
			setProcess(false);
			setErr(retMember.data.error);
			return;
		}

		//partner create
		const newPartner = {
			_id: partnerId,
			sk: "info",
			created: new Date().getTime(),
			logo: "",
			name: data.name,
			partnerKey: data.partnerKey,
			type: "dealer",
			parentId: "",

			adminname: data.adminname,
			adminemail: data.adminemail,
			tel: data.tel,
			logo: data.logo,
			website: data.website,
		};

		await insertData("rayteams-partner", newPartner);

		setProcess(false);

		history.push("/Partners");
	}

	const delGroup = async () => {
		setProcess(true);

		await delData("rayteams-partner", {
			_id: _id,
			sk: "info",
		});
		setProcess(false);
		history.push("/Partners");
	}

	const updateGroup = async () => {
		setProcess(true);

		console.log({
			name: data.name,
			adminname: data.adminname,
			adminemail: data.adminemail,
			tel: data.tel,
			logo: data.logo,
			website: data.website,
		});

		await updateData("rayteams-partner", {
			_id: _id,
			sk: "info",
		}, {
			name: data.name,
			adminname: data.adminname,
			adminemail: data.adminemail,
			tel: data.tel || "",
			logo: data.logo || "",
			website: data.website || "",
		});

		setProcess(false);

		history.push("/Partners");
	}

	return <>
		{err && <div className="alert alert-dark shadow">{err}</div>}
		{delConfirm && <MLPanelDelete title={data.name + t(" 파트너를 삭제하시겠습니까?")} contents={<>
			<div className='text-white'>{t("파트너 삭제는 즉시 적용됩니다.")}</div>
		</>} buttons={[
			{
				type: BUTTONS.NORMALBUTTON,
				options: {
					label: t("Cancel"),
					color: COLORS.DEFAULT,
					action: () => setDelConfirm(false),
				}
			},
			{
				type: BUTTONS.NORMALBUTTON,
				options: {
					label: t("Remove permanently"),
					color: COLORS.WARNING,
					action: () => delGroup(),
				}
			},
		]} />}
		{!delConfirm && <>
			<div className='p-4'>
				<div className="header align-items-stretch d-block" style={{ position: "initial" }}>
					<div className="d-flex w-100 justify-content-between align-items-center">
						<div className="d-flex align-items-center text-dark fw-bolder fs-3 mb-3 my-1">{t("Partners")}</div>
						<div className="d-flex align-items-center">
							<BiHelpCircle size="24" className="ms-2 text-primary cursor-pointer" onClick={() => setHelp(!help)} />
						</div>
					</div>
				</div>
				<div className='card-body p-7 pt-2'>
					<div className="card mb-2">
						<form>
							<div className="card-body row">
								<div className={_id ? "col-12" : "col-6"}>
									<div className="mb-4">
										<label className="form-label fs-3">Partner Information</label>
										{help && <div className="alert alert-dark mb-2">
											{t("Partner 정보를 입력합니다. 추후에 대부분 수정이 가능합니다.")}<br />
											{t("Partner Key는 한번 생성하면 변경이 불가합니다.")}
										</div>}
									</div>
									<div className="mb-4">
										<label className="form-label required fw-bolder">Partner Key(English Only)</label>
										{help && <div className="alert alert-dark mb-2">
											{t("영문만 가능하며 어떠한 특수문자도 허용안됩니다. 물론, 공백도 안됩니다.")}<br />
											{t("가급적이면, 개발팀에 연락하여 키를 발급 받기를 권장합니다.")}
										</div>}
										{_id && <div className='fw-bolder fs-4 text-gray-500'>{data.partnerKey}</div>}
										{!_id && <input
											type="text"
											className="form-control form-control-solid"
											placeholder={t("Enter partner-key")}
											value={data.partnerKey}
											onChange={(e) => setData({ ...data, partnerKey: e.target.value })} />}
									</div>
									<div className="mb-4">
										<label className="form-label required fw-bolder">{t("Partner name")}</label>
										{help && <div className="alert alert-dark mb-2">
											{t("Parnter Name은 파트너사의 이름입니다.")}<br />
											{t("고객의 Company/Service name을 사용하는 것을 권장합니다.(예, Sheep Medical, Trio Clear 등)")}
										</div>}
										<input
											type="text"
											className="form-control form-control-solid"
											placeholder={t("Enter sub-partner name")}
											value={data.name}
											onChange={(e) => setData({ ...data, name: e.target.value })} />
									</div>
									<div className="mb-4">
										<label className="form-label required fw-bolder">{t("Partner administrator name (NOT RAYTeams Account)")}</label>
										{help && <div className="alert alert-dark mb-2">
											{t("Parnter administrator name은 파트너사의 관리자 이름입니다.")}<br />
											{t("어떤 서비스와고도 연관되지 않습니다.")}
										</div>}
										<input
											type="text"
											className="form-control form-control-solid"
											placeholder={t("Enter admin name")}
											value={data.adminname}
											onChange={(e) => setData({ ...data, adminname: e.target.value })} />
									</div>
									<div className="mb-4">
										<label className="form-label required fw-bolder">{t("Partner administrator email (NOT RAYTeams Account)")}</label>
										{help && <div className="alert alert-dark mb-2">
											{t("Parnter administrator email은 파트너사와 연락을 주고 받을 수 있는 파트너 관리자의 Email 주소를 말합니다.")}<br />
											{t("어떤 서비스와고도 연관되지 않습니다.")}
										</div>}
										<input
											type="email"
											className="form-control form-control-solid"
											placeholder={t("Enter admin email address")}
											value={data.adminemail}
											onChange={(e) => setData({ ...data, adminemail: e.target.value })} />
									</div>
									<div className="mb-4">
										<label className="form-label fw-bolder">{t("Partner tel no.")}</label>
										{help && <div className="alert alert-dark mb-2">
											{t("Parnter와 통화할 수 있는 전화번호입니다.")}<br />
											{t("이 정보 또한 RAYTeams Client와는 무관합니다.")}
										</div>}
										<input
											type="text"
											className="form-control form-control-solid"
											placeholder={t("Enter tel no.")}
											value={data.tel}
											onChange={(e) => setData({ ...data, tel: e.target.value })} />
									</div>
									<div className="mb-4">
										<label className="form-label fw-bolder">{t("Partner logo")}</label>
										{help && <div className="alert alert-dark mb-2">
											{t("Parnter Portal에 노출되는 파트너의 로고입니다.")}<br />
											{t("로고 이미지의 Web URL을 등록하면 됩니다.")}
										</div>}
										<input
											type="text"
											className="form-control form-control-solid"
											placeholder={t("Enter logo path")}
											value={data.logo}
											onChange={(e) => setData({ ...data, logo: e.target.value })} />
									</div>
									<div className="mb-4">
										<label className="form-label fw-bolder">Partner website</label>
										{help && <div className="alert alert-dark mb-2">
											Parnter 사의 홈페이지/서비스 페이지 주소입니다.
										</div>}
										<input
											type="text"
											className="form-control form-control-solid"
											placeholder="Enter website"
											value={data.website}
											onChange={(e) => setData({ ...data, website: e.target.value })} />
									</div>
								</div>
								{!_id && <div className="col-6">
									<div className="mb-4">
										<label className="form-label fs-3" fw-bolder>Partner Portal Account</label>
										{help && <div className="alert alert-dark mb-2">
											이 계정은 Partner Portal에 고객이 접속할 수 있는 계정입니다.<br />
											RAYTeams Account와는 다릅니다. 생성하고 파트너에게 계정 정보를 전달해주세요.<br />
											Partner manager account는 Partner의 최고 관리자라고 보면 됩니다.
										</div>}
										{/*<div className="alert alert-primary">
							{"This account has administrative privileges under the partner"} {data.name ? <span className="fw-bolder">({data.name})</span> : ""} {" being created."}
						</div>*/}
									</div>
									<div className="mb-4">
										<label className="form-label required fw-bolder">Partner portal name</label>
										{help && <div className="alert alert-dark mb-2">
											Parnter manager name은 RAYTeams account와는 무관하며, Partner Portal에 로그인했을때 보여지는 사용자 이름입니다.
										</div>}
										<input
											type="text"
											className="form-control form-control-solid"
											placeholder="Manager Name"
											value={data.username}
											onChange={(e) => setData({ ...data, username: e.target.value })} />
									</div>
									<div className="mb-4">
										<label className="form-label required fw-bolder">Partner portal email (Partner Portal Account)</label>
										{help && <div className="alert alert-dark mb-2">
											Parnter manager email은 RAYTeams account와는 무관하며, Partner Portal에 로그인할 때 사용하는 Email 입니다.
										</div>}
										<input
											type="email"
											className="form-control form-control-solid"
											placeholder="Manager Email"
											value={data.useremail}
											onChange={(e) => setData({ ...data, useremail: e.target.value })} />
									</div>
									<div className="mb-4">
										<label className="form-label required fw-bolder">Partner portal password (Partner Portal Account)</label>
										{help && <div className="alert alert-dark mb-2">
											Parnter manager password은 RAYTeams account와는 무관하며, Partner Portal에 로그인할 때 사용하는 Password 입니다.
										</div>}
										<input type="password"
											className="form-control form-control-solid"
											placeholder="Password"
											value={data.userpassword || ""}
											onChange={(e) => setData({ ...data, userpassword: e.target.value })} />
									</div>
									<div className="mb-4">
										<label className="form-label required fw-bolder">Partner portal password confirm</label>
										<input type="password"
											className="form-control form-control-solid"
											placeholder="Password"
											value={data.userpassword2 || ""}
											onChange={(e) => setData({ ...data, userpassword2: e.target.value })} />
									</div>
								</div>}
							</div>
						</form>
						<div className="card-header">
							{/* <div className='card-title'>
								{_id ? "Edit " + data.name + " partner information" : "Add a partner and partner portal account"}
								<small className='ms-2 text-gray-500'></small>
							</div> */}
							<div className="card-toolbar text-end">
								<MLButton options={{
									label: "Cancel",
									color: COLORS.DEFAULT,
									action: () => _id ? history.push("/Partner/" + _id) : history.push("/Partners"),
								}} css="me-2" />
								{_id && <MLButton options={{
									label: "Remove",
									color: COLORS.DANGER,
									action: () => setDelConfirm(true),
								}} css="me-2" />}
								<MLButton options={{
									label: _id ? "Update" : "Create",
									color: COLORS.PRIMARY,
									action: () => _id ? updateGroup() : createGroup(),
									needValid: true
								}} valid={_id ?
									(!!(data.partnerKey && data.adminname && data.adminemail && data.name) && !process) :
									(!!(data.partnerKey && data.adminname && data.adminemail && data.name && data.username && data.useremail && data.userpassword) && !process)} />
							</div>
						</div>
					</div>
				</div>
			</div></>}
	</>
}

const mapState = (state) => {
	const groups = state.AuthReducer.groups;
	const region = state.AuthReducer.region;
	const regionusers = state.AuthReducer.regionusers;
	return { groups, region, regionusers };
};

const mapDispatch = (dispatch) => ({

});

export default connect(mapState, null)(PartnerAddPage);
