import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import RMWidgetCount from '../widgets/RMWidgetCount';
import { useTranslation } from 'react-i18next';
const SubStatCard = ({ items }) => {
	const dispatch = useDispatch();
    const [t] = useTranslation(['translation']);
    const remainDay = 30;
    const remain = new Date().getTime() + (remainDay * 24 * 3600 * 1000);

	useEffect(() => {
	}, []);

  	return <>
        {items && <div className='row'>
            <div className='col'>
                <RMWidgetCount title={t("전체 구독")} sub={t("누적")} value={items.length} iconName="hand-click" />
            </div>
            <div className='col'>
                <RMWidgetCount title={t("구독자")} sub={t("누적")} value={[...new Set(items.map(x => x._id))]?.length} iconName="users-group" />
            </div>
            <div className='col'>
                <RMWidgetCount title={t("판매 국가")} sub={t("누적")} value={[...new Set(items.map(x => x.ccode))]?.length} iconName="world-pin" />
            </div>
            <div className='col'>
                <RMWidgetCount title={t("만료됨")} sub={t("만료된 구독")} value={items.filter(x => x.expired < (new Date().getTime()))?.length} iconName="clock-cancel" />
            </div>
            <div className='col'>
                <RMWidgetCount title={t("만료 예정")} sub={t("만료일이 30일 남은 구독 수")} value={items.filter(x => x.expired < remain).length} iconName="calendar-time" />
            </div>
        </div>}
    </>
}

const mapState = (state) => {
	const region = state.AuthReducer.region;
	return { region };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, null)(SubStatCard);
