import { useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import { MLPanelLoading } from '../common/MLPanel';
import { Bar, Line, getElementAtEvent } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { getRandomColors } from '../../libs/colors';
import { slice, uniq, uniqBy } from 'lodash';
import { history } from '../../history';
import { UsageConstant } from '../../redux/reducers';
import { ChartDays, ChartMonths } from '../common/ChartDays';
import { SEARCHPERIOD } from '../../constants/RMConstants';
import { COUNTRIES } from '../../constants/Country';
import { MLComboSingle } from '../common/MLInput';
import { TbChartLine } from 'react-icons/tb';
import { MLToggle } from '../common/MLInput';

const RMWidgetBarChart = ({ data, type, title, height = 300, target, targetdate, targetname, chartType, horizental = false, top = true, period, max = 5 }) => {
	const dispatch = useDispatch();
	const [ chartData, setChartData ] = useState([]);
	const [ loading, setLoading ] = useState(true);
	const [ stacked, setStacked ] = useState(false);
	const [ lineChart, setLineChart ] = useState(false);
	const chartTypeList = [
        { value : false, label : "Bar Chart" },
        { value : true, label : "Stack Chart" },
    ]
	const chartRef = useRef();

    useEffect(() => {
		if( data && chartType) {
			generateGroup(data, period, targetdate, targetname);
		} else {
			data && generate(data, period, type, target);
		}
    }, [data]);

	const generate = async (list, period, type, target) => {
		// console.log("generate: =======", list);
		const clist = [];
		const barDataset = [];
		const colorSet = getRandomColors(list.length, 0.8);
		if(target === "created" && period) {
			var searchItems = SEARCHPERIOD.find(y => y.value === period);
			ChartDays(searchItems.duration, searchItems.mcode, searchItems.dFormat).map((x, idx) => {
				clist.push( {name: x, count: 0});
			});
			list.map(x => {
				var foundIndex = clist.findIndex(y => y.name == moment(x.created).format(searchItems.dFormat));
                if(clist[foundIndex]){
                    clist[foundIndex].count += 1;
                }
			});
		} else if(target === "sum") {
			list.map(x => {
				clist.push({
					name: x.product,
					count: x.sells
				});
			});
		} else {
			list.reduce(function(res, value) {
				if (!res[value?.[target]]) {
					res[value?.[target]] = { name: value?.[target] === undefined ? "" : value?.[target], count: 0 };
					clist.push(res[value?.[target]])
				}
				res[value?.[target]].count += 1;
				return res;
			}, {});
		}

        barDataset.push(
            {
                data: top ? clist.sort((a, b) => a.count > b.count ? -1 : 1).map((x) => x.count).slice(0, max) : clist.sort((a, b) => a.name > b.name ? 1 : -1).map((x) => x.count),
				backgroundColor: "#887feb",
				borderRadius: 10
            }
        )

		setChartData({
            labels: top ? clist.sort((a, b) => a.count > b.count ? -1 : 1).map((x) => x.name === "" ? type + " 없어요" : x.name ).slice(0, max) : clist.sort((a, b) => a.name > b.name ? 1 : -1).map((x) => x.name === "" ? type + " 없어요" : x.name ),
            datasets: barDataset
        });
		setLoading(false);
	}

	const generateGroup = async (list, period, targetdate, targetname) => {
		const clist = [];
		const barDataset = [];
		const colorSet = getRandomColors(list.length, 0.8);

		var searchItems = SEARCHPERIOD.find(y => y.value === period);
		
		ChartDays(searchItems.duration, searchItems.mcode, searchItems.dFormat).map(x => {
			list.filter(y => moment(y?.[targetdate]).format(searchItems.dFormat) == moment(x).format(searchItems.dFormat)).map(z => {
				if(!clist.filter(y => y.name === z?.[targetname] && y.label == moment(z?.[targetdate]).format(searchItems.dFormat)).length == 0) {
					var objIndex = clist.findIndex((obj => obj.name == z?.[targetname] && obj.label == moment(z?.[targetdate]).format(searchItems.dFormat)));
					clist[objIndex].count += 1;
				} else {
					clist.push({ name : z?.[targetname], count: 0, label: moment(z?.[targetdate]).format(searchItems.dFormat) });
				}
			});
		});

		console.log("object", clist);

		ChartDays(searchItems.duration, searchItems.mcode, searchItems.dFormat).map((x, index) => {
			uniqBy(clist, "name").map((m, index) => {
				if(clist.filter(y => y.label == moment(x).format(searchItems.dFormat) && y.name == m.name).length == 0) {
					clist.push({ name : m.name, count: 0, label : moment(x).format(searchItems.dFormat)});
				}
			})
		})
		
		uniqBy(clist, "name").sort((a, b) => a.label > b.label ? 1 : -1).map((x, index) => {
			barDataset.push(
				{
					label: x.name,
					data: clist.filter(y => y.name === x.name).sort((a, b) => a.label > b.label ? 1 : -1).map((m) => m.count),
					backgroundColor: "#887feb",
					borderRadius: 10
				}
			)
		})

		setChartData({
            labels: uniqBy(clist, "label").sort((a, b) => a.label > b.label ? 1 : -1).map(x => x.label),
            datasets: barDataset
        });
		setLoading(false);
	}

	const onClick = (event) => {
		const elem = getElementAtEvent(chartRef.current, event);
		var data = chartData.datasets[elem[0].datasetIndex];
		dispatch({ type : UsageConstant.GET_SELECTED_LOGS, items: chartData });
		history.push("/UsageInfoPage/" + data.label + "/" + type + "/" + period);
		console.log(data.label);
	}

  	return <>
		{!loading && <div><div className='card'>
			{chartData && <>
			<div className='card-header header-elements'>
                <h5 className='card-title mb-0'>{title}</h5>
                <div className='card-action-element ms-auto py-0'>
					{!lineChart && chartType &&
						<MLComboSingle options={{
							name: "period",
							value: stacked,
							list: chartTypeList,
						}} css={"ms-2 w-120px"} handleChanged={(e) => setStacked(e.target.value) } />
					}
					{chartType && <>
						<div>
							<MLToggle
								options={{
									label: "",
									name: "lineChart",
									value: lineChart,
									readonly: false,
									inline: false
								}}
								handleChanged={(e) => {setLineChart(e.target.value);}} />
						</div>
						<div>
							<TbChartLine style={{ width : 22, height : 22}}></TbChartLine>
						</div>
					</>}
				</div>
			</div>
			<div className='card-body'>
				{lineChart &&
				<Line
					height={height}
					data={chartData}
					options={{
						responsive: true,
						maintainAspectRatio: false,
						cutout: 0,
						animation: { animateScale: true },
						plugins: { legend: { display : true, position: "right" }, },
						scales: {
							y: { grid: { display: false } },
							x: { grid: { display: false } }
						}					
					}}
				/>
				}
				{!lineChart &&
				<Bar 
					ref={chartRef}
					height={height}
					data={chartData}
					plugins={[ChartDataLabels]}
					options={{
						indexAxis: horizental ? 'y' : 'x',
						responsive: true,
						maintainAspectRatio: false,
						cutout: 0,
						animation: { animateScale: true },
						plugins: { 
							legend: { display : false, position: "right" },
							datalabels: {
								formatter: function (value) {
								  return value == 0 ? "" : value;
								},
								display: true,
								color: "gray",
								anchor: 'end',
								align: 'end',
								font: {
								  weight: 'bold',
								  size: 13,
								},
							},
						},
						scales: {
							y: { stacked: stacked, ticks: { color: '#666060', beginAtZero: true }, grid: { display: false } },
							x: { stacked: stacked, ticks: { color: '#666060', beginAtZero: true, callback: function(value, index, ticks) {
                                if(target === "countryCode")
                                    return COUNTRIES.find(x => x.countryCode === this.getLabelForValue(value))?.name || 'Unknown';
                                return this.getLabelForValue(value);
                            } }, grid: { display: false } },
						},
					}}/>
				}
			</div>
			</> }
		</div></div>}
		{loading && <MLPanelLoading />}
	</>
}

const mapState = (state) => {
    const logs = state.UsageReducer.logs;
	const dailylogs =  state.UsageReducer.dailylogs;
	const selectedlogs = state.UsageReducer.selectedlogs;
	const stats = state.UsageReducer.stats;
	
	return { logs, dailylogs, stats, selectedlogs };
};

const mapDispatch = (dispatch) => ({
});

export default connect(mapState)(RMWidgetBarChart);
