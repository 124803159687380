import axios from 'axios';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { MLText } from '../common/MLInput';
import { MLPanelLoading } from '../common/MLPanel';
import MLTable from '../common/MLTable';
import { AuthConstant, getAPI, getHeader } from '../../redux/reducers/AuthReducer';
import { fetchData } from '../../libs/db';

const RMRayTeamsDevice = ({ user, region, group, rayteams, devices, section }) => {

	const dispatch = useDispatch();
	const [search, setSearch] = useState("");
	const [items, setItems] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
        getData();
	}, [group]);

    const headers = [
        { link : "sn", value : "sn", label : "SN", css : "text-start", linkpath : "/device", value : "sn" },
        { value : "DeviceName", label : "Device Name", css : "text-start" },
		{ value : "updated", label : "Updated", css : "text-start", type : "DATE"}
    ]

	const getData = async () => {
		setLoading(true);
		try{
			const params = {
				ownerId: {
					ComparisonOperator: "BEGINS_WITH",
					AttributeValueList: [group?._id],
				},
			}
			const ret = await fetchData("rayteams-device", params);
            setItems(ret.Items);
		}catch(err){
            console.log(err);
		}
		setLoading(false);
    }
	
  	return (<>
		<div className="d-flex flex-column flex-column-fluid">
			<div className="card card-flush h-md-100">
				{!loading &&<>
					<MLTable headers={headers} items={items} noheader={true} />
				</>}
				{loading && <MLPanelLoading />}
			</div>
		</div>
    	</>
  	);
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const region =  state.AuthReducer.region;
	const rayteams =  state.AuthReducer.rayteams;
    const devices = state.AuthReducer.devices;
	return { region, user, rayteams, devices };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, null)(RMRayTeamsDevice);
