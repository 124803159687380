import { ApplicationConstant } from '../reducers/ApplicationReducer';
import { pkData, fetchData, queryData, queryDataWithIndex } from '../../libs/db';
import { history } from '../../history';

const GetApplications = () => async (dispatch) => {
    const ret = await queryDataWithIndex("rayrnd-applications", {
      type: {
        ComparisonOperator: "EQ",
        AttributeValueList: ["app"],
      }
    }, "type-name-index", "ap-northeast-2");
    var list = [];
    ret.Items.filter(x => x.is_active === true).map(x => {
      list.push({
        ...x,
        title: <>
          <img src={x.app_icon?.direct_link} style={{ width: 36, height: 36, marginRight: 4 }} />
          <a href='#' onClick={e => history.push("/Application/" + x.name)}>{x.title}</a>
        </>,
        titleText: x.title
      })
    });
    dispatch({ type: ApplicationConstant.GET_APPLICATIONS, items : list });
};

const GetApplicationVersions = (name) => async (dispatch) => {
    const ret = await queryData("rayrnd-applications", {
      name: {
        ComparisonOperator: "EQ",
        AttributeValueList: [name],
      },
      type: {
        ComparisonOperator: "BEGINS_WITH",
        AttributeValueList: ["v:production"],
      }
    }, "ap-northeast-2");
    var list = [];
    ret.Items.filter(x => x.type.split(":").length === 3).map(x => {
        list.push({
            ...x,
            version : x.type.replace("v:production:", "v"),
            size : x?.full_file?.file_size ? humanFileSize(x?.full_file?.file_size) : "-",
            desc : <div dangerouslySetInnerHTML={ {__html: x.desc} }></div>,
            link : x?.full_file?.direct_link ? <a href={x?.full_file?.direct_link} target='_blank'>Download</a> : <></>
        })
    });
    dispatch({ type: ApplicationConstant.GET_APPLICATION_VERSIONS, items : list });
};

const GetApplicationConfigs = (name) => async (dispatch) => {
    const ret = await queryData("rayrnd-applications", {
      name: {
        ComparisonOperator: "EQ",
        AttributeValueList: [name],
      },
      type: {
        ComparisonOperator: "BEGINS_WITH",
        AttributeValueList: ["cfg:"],
      }
    }, "ap-northeast-2");
    var list = [];
    ret.Items.map(x => {
        list.push({
            ...x
        })
    });
    dispatch({ type: ApplicationConstant.GET_APPLICATION_CONFIGS, items : list });
};

const humanFileSize = (size) => {
    var i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
    return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
}

export const ApplicationAction = {
	GetApplications,
    GetApplicationVersions,
    GetApplicationConfigs
};
