import axios from 'axios';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import RTProductMainList from '../../components/rayteamsproduct/RTProductMainList';

const RTProductPage = ({ user, region, RTproducts }) => {
  	return (
    	<div>
			<RTProductMainList />
    	</div>
  	);
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const region =  state.AuthReducer.region;
	const RTproducts = state.RTProductReducer.RTproducts;
	return { region, user, RTproducts };
};

const mapDispatch = (dispatch) => ({
});

export default connect(mapState, mapDispatch)(RTProductPage);