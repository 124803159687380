import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import MLTable from '../common/MLTable';
import { MLPanelLoading } from '../common/MLPanel';
import { fetchData, countData } from '../../libs/db';
import { MLText } from '../common/MLInput';
import { MLFileSize } from '../common/MLCommon';
import { Link } from 'react-router-dom';
import { ProjectConstant } from '../../redux/reducers/ProjectReducer';
import RMWidgetBarChart from '../../components/widgets/RMWidgetBarChart';
import RMProjectTopRate from './RMProjectTopRate';
import { MLComboSingle } from '../common/MLInput';
import { TbLayoutDashboard } from 'react-icons/tb';
import { ProgramConstant } from '../../redux/reducers';
import { SEARCHPERIOD } from '../../constants/RMConstants';
import moment from 'moment';
import { RMIconList, RMIconChart } from '../icon/MLIcon';

const RMProjectsList = ({ groups, projects, loginuser }) => {
	const dispatch = useDispatch();
	const [ search, setSearch ] = useState("");
	const [ items, setItems ] = useState([]);
	const [ allItems, setAllItems ] = useState([]);
	const [ loading, setLoading ] = useState(false);
	const [ tabs, setTabs ] = useState("All");
	const [ period, setPeriod ] = useState("days");
	const mgroup = JSON.parse(localStorage.getItem("mgroup"));
	const periodlist = SEARCHPERIOD;
	
	useEffect(async () => {
		//var totalCount = await getTotalCount();
		//if(totalCount != (projects && projects.length))
        	getData_dProjects();
	}, [])

	useEffect(() => {
		genData();
	}, [projects])

	useEffect(() => {
		genData();
	}, [search, tabs, period]);

	const genData = () => {
		if(projects) {
			var lists = projects.filter(x => {
				if(tabs == "All") return true;
				if(tabs == "Complete" && x.isarchive) return true;
				if(tabs == "Trash" && x.istrash) return true;
				if(tabs == "Deleted" && x.isDel) return true;})
			.filter(x => {
				if(period == "all") {
					return true;
				} else {
					if(x.created >= moment().add(SEARCHPERIOD.filter(x => x.value == period)[0]?.duration , SEARCHPERIOD.filter(x => x.value == period)[0]?.mcode).valueOf()) return true;
				}
			});

			var allLists = projects.filter(x => {
				if (period == "all") {
					return true;
				} else {
					if(x.created >= moment().add(SEARCHPERIOD.filter(x => x.value == period)[0]?.duration , SEARCHPERIOD.filter(x => x.value == period)[0]?.mcode).valueOf()) return true;
				}
			});

			setItems(search ? lists.filter(x => x.ownergroup?.indexOf(search) > -1 || x.lab?.indexOf(search) > -1) : lists || []);
			setAllItems(search ? allLists.filter(x => x.ownergroup?.indexOf(search) > -1 || x.lab?.indexOf(search) > -1) : allLists || []);
		}
	};

	const getData_dProjects = async () => {
		setLoading(true);
		let ScanFilter = {
			sk: {
				ComparisonOperator: "EQ",
				AttributeValueList: ["project"],
			},
			
		}
		if(mgroup?.countries?.length > 0){
            ScanFilter = {...ScanFilter,
                region: {
					ComparisonOperator: "IN",
					AttributeValueList: mgroup?.countries?.map(x => x.region)
				}
			}
        }

		try {
			const ret = await fetchData("rayteams-project", ScanFilter);
			var list = [];
			list = ret.Items.map(x => {
				return {...x,
					patientname : x.subject?.name || "",
					ownergroup: groups.filter(y => y._id === x?.ownerGroupId)[0]?.name || "",
					lab: groups.filter(y => y._id === x?.labId)[0]?.name || "",
					countryCode: groups.filter(y => y._id === x?.ownerGroupId)[0]?.countryCode || "",
					filesize: MLFileSize(x?.size)
				}
			});
			dispatch({ type : ProjectConstant.GET_ALL_PROJECTS, items : list });
		} catch (e) {
			console.log(e)
			dispatch({ type : ProjectConstant.GET_ALL_PROJECTS, items : [] });
		}
		setLoading(false);
	}

	const getTotalCount = async () => {
		try	{
			const ScanFilter = {
				sk: {
					ComparisonOperator: "EQ",
					AttributeValueList: ["project"],
				}
			}
			const ret = await countData("rayteams-project", ScanFilter);
			return ret.Count;
		} catch {
			return 0;
		}
	}

	const goLink = (to, title) => {
		dispatch({ type : ProgramConstant.GO_LINK, to, title });
	}

    const headers = [
		{ value : "name", label : "Project Name", css : "text-start" },
		{ value : "projecttype", label : "Project Type", type : "BADGE" },
		{ link : "ownerGroupId", linkpath : "/GroupInfo", value : "ownergroup", label : "Owner Group", css : "text-start" },
		{ link : "labId", linkpath : "/GroupInfo", value : "lab", label : "Related Group", css : "text-start" },
        { value : "count", label : "Files", style : { width : 80 }, css : "text-center" },
		{ value : "filesize", label : "Size"},
        { value : "created", label : "Created", type : "DATESHORT", style : { width : 120 }, css : "text-center" },
        { value : "isarchive", label : "Completed", type : "TFVALUE", style : { width : 100 }, css : "text-center" },
        { value : "istrash", label : "Trash", type : "TFVALUE", style : { width : 100 }, css : "text-center" },
        { value : "isDel", label : "Deleted", type : "TFVALUE", style : { width : 100 }, css : "text-center" },
    ]

  	return <>
		<div className="header align-items-stretch d-block" style={{ position : "initial"}}>
			<div className="d-flex w-100 justify-content-between p-5 align-items-center">
				<div className="d-flex align-items-center text-dark fw-bolder fs-2 my-1">Cases</div>
				<div className="d-flex align-items-center"></div>
			</div>
		</div>
		<div className="d-flex flex-column flex-column-fluid">
			<div className="post p-7">
				<div className="card card-flush h-md-100">
					<div className="card-header border-bottom-1 pt-5 mb-4">
						<ul className="nav nav-stretch nav-pills nav-pills-custom d-flex" role="tablist">
							<li className="nav-item p-0 ms-0" role="presentation" onClick={() => {setTabs("All");}}>
								<a className={"nav-link btn btn-color-gray-400 flex-center px-3 " + ('All' === tabs ? 'active' : '')}>
									<span className="nav-text fw-semibold fs-5 mb-3">All<span className="badge badge-secondary ms-2">{allItems && allItems.length}</span></span>
									<span className="bullet-custom position-absolute z-index-2 w-100 h-1px top-100 bottom-n100 bg-info rounded"></span>
								</a>
							</li>
							<li className="nav-item p-0 ms-0" role="presentation" onClick={() => {setTabs("Complete");}}>
								<a className={"nav-link btn btn-color-gray-400 flex-center px-3 " + ('Complete' === tabs ? 'active' : '')}>
									<span className="nav-text fw-semibold fs-5 mb-3">Complete<span className="badge badge-secondary ms-2">
										{allItems && allItems.filter(x => x.isarchive).length}
									</span></span>
									<span className="bullet-custom position-absolute z-index-2 w-100 h-1px top-100 bottom-n100 bg-info rounded"></span>
								</a>
							</li>
							<li className="nav-item p-0 ms-0" role="presentation" onClick={() => {setTabs("Trash");}}>
								<a className={"nav-link btn btn-color-gray-400 flex-center px-3 " + ('Trash' === tabs ? 'active' : '')}>
									<span className="nav-text fw-semibold fs-5 mb-3">Trash<span className="badge badge-secondary ms-2">
										{allItems && allItems.filter(x => x.istrash).length}
									</span></span>
									<span className="bullet-custom position-absolute z-index-2 w-100 h-1px top-100 bottom-n100 bg-info rounded"></span>
								</a>
							</li>
							<li className="nav-item p-0 ms-0" role="presentation" onClick={() => {setTabs("Deleted");}}>
								<a className={"nav-link btn btn-color-gray-400 flex-center px-3 " + ('Deleted' === tabs ? 'active' : '')}>
									<span className="nav-text fw-semibold fs-5 mb-3">Deleted<span className="badge badge-secondary ms-2">
										{allItems && allItems.filter(x => x.isDel).length}
									</span></span>
									<span className="bullet-custom position-absolute z-index-2 w-100 h-1px top-100 bottom-n100 bg-info rounded"></span>
								</a>
							</li>
						</ul>
						
						<div className="card-toolbar">
							<div className='col-sm-auto'><div className="w-200px">
								<MLText options={{
									placeholder : "검색어 입력",
									size : "sm",
									value : search,
								}} handleChanged={(e) => setSearch(e.target.value)} />
							</div></div>
							<div className="col-sm-auto ms-3"><div className="w-150px">
								<MLComboSingle options={{
									name : "period",
									value : period,
									list : periodlist,
								}} handleChanged={(e) => setPeriod(e.target.value) } />
							</div></div>
							<div className="col-sm-auto ms-6 pt-1 btn-tool-view">
								<a className="btn btn-bg-secondary btn-sm mb-1 btn-view-select active" title="View as a list">
									<RMIconList></RMIconList>
								</a>
								<a className="btn btn-bg-secondary btn-sm mb-1 btn-view-select" title="View as a dashboard">
									<RMIconChart onClick={() => goLink("/ProjectsDashBoardPage", "Projects")}></RMIconChart>
								</a>
							</div>
						</div>
					</div>
					{!loading && <>
					<div className="card-body pt-2">
						<MLTable headers={headers} items={items} noheader={true} />
					</div>
					</>}
					{loading && <MLPanelLoading />}
				</div>
			</div>
		</div>
	</>
}

const mapState = (state) => {
	const region =  state.AuthReducer.region;
	const projects = state.ProjectReducer.projects;
	const loginuser = state.AuthReducer.loginuser;
	return { region, projects, loginuser };
};

export default connect(mapState)(RMProjectsList);
