import axios from 'axios';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { MLText } from '../components/common/MLInput';
import { MLPanelLoading } from '../components/common/MLPanel';
import { history } from '../history';
import { AuthConstant, getAPI, getHeader } from '../redux/reducers/AuthReducer';
import RMRayteamsApp from '../components/rayteams/RMRayteamsApp';
import RMRayteamsDevice from '../components/rayteams/RMRayteamsDevice';
import RMWidgetCount from '../components/widgets/RMWidgetCount';
import RMWidgetList from '../components/widgets/RMWidgetList';
import { countBy } from 'lodash';

const RAYTeamsPage = ({ user, region, rayteams, devices }) => {

	const dispatch = useDispatch();
	const [search, setSearch] = useState("");
	const [items, setItems] = useState([]);
	const [loading, setLoading] = useState(true);
	const [genListDeviceCountry, setGenListDeviceCountry] = useState([]);
	const [genListDeviceRegion, setGenListDeviceRegion] = useState([]);
	const { tab = "app" } = useParams();

	const tabs = [
		{value : "app", label : "App"},
		{value : "device", label : "Device"},
	]

	useEffect(() => {
        getData_App();
		getData_Device();
	}, []);

	useEffect(() => {
		if(rayteams && devices)
			genData_Device();
	}, [rayteams, devices]);

	const getData_App = async () => {
		try{
			const ret = await axios.get(getAPI() + "/rayteams", getHeader("rm"));
			dispatch({ type : AuthConstant.GET_ALL_RAYTEAMS, items : ret.data.data})
		}catch{
			dispatch({ type : AuthConstant.GET_ALL_RAYTEAMS, items : []})
		}
    }

	const getData_Device = async () => {
		try{
			const ret = await axios.get(getAPI() + "/rayteamsdevices", getHeader("rm"));
			dispatch({ type : AuthConstant.GET_ALL_DEVICES, items : ret.data.data})
		}catch{
			dispatch({ type : AuthConstant.GET_ALL_DEVICES, items : []})
		}
    }

	const headers = [
        { value: "country", label: "Country", css: "text-start", type: "COUNTRY" },
        { value: "count", label: "Count", css: "text-start" },
    ]

	const headers_region = [
		{ value: "region", label: "Region", css: "text-start", type: "REGION" },
		{ value: "count", label: "Count", css: "text-start" },
	]

	const genData_Device = async () => {
		const genList = [];
		if(rayteams && devices){
			devices.map((x) => {
				genList.push({
					...x, 
					group: rayteams.filter(y => y?._id === x?._id)[0]?.group?.name, 
					country: rayteams.filter(c => c?._id === x?._id)[0]?.group?.countryCode,
					region: rayteams.filter(r => r?._id === x?._id)[0]?.group?.region
				});
			});

			const countByList = countBy(genList, 'country');
			const countByRegion = countBy(genList, 'region');
			const countGenList = [];
			const countGenListRegion = [];
			Object.keys(countByList).map(x => {
				countGenList.push({ country: x, count: countByList[x] })
			});
			setGenListDeviceCountry(countGenList);

			Object.keys(countByRegion).map(x => {
				countGenListRegion.push({ region: x, count: countByRegion[x]});
			});
			setGenListDeviceRegion(countGenListRegion);
		}
		setLoading(false);
	}

  	return (
    	<div>
			{loading && <>
				<h2 className='mb-4'> RAYTeams Info </h2>
				<MLPanelLoading />
			</>}
			{!loading && <>
				<div className='row'>
					<div className='col-sm mb-5'>
						<RMWidgetCount data={rayteams} options={{ filter: "isOn", filterValue: true }} title={'RAYTeams Live'} />
					</div>
					<div className='col-sm mb-5'>
						<RMWidgetCount data={rayteams} options={{ filter: "isOn", filterValue: false }} title={'RAYTeams Off'} />
					</div>
					<div className='col-sm mb-5'>
						<RMWidgetCount data={devices} title={'RAYFace'} options={{ Deduplication: true }} />
					</div>
				</div>
				<div className='row'>
					<div className='col-xl-6 mb-5'>
						{genListDeviceCountry && <RMWidgetList data={genListDeviceCountry} headers={headers} title={'국가별 RAYFace'} />}
					</div>
					<div className='col-xl-6 mb-5'>
						{genListDeviceRegion && <RMWidgetList data={genListDeviceRegion} headers={headers_region} title={'Region별 RAYFace'} />}
					</div>
				</div>
				<div className='card'>
					<div className="card-header">
						<ul className="detail-tab nav nav-line-tabs nav-stretch">
							{tabs.map((x, idx) => {
								return <li className="nav-item" key={idx} onClick={() => history.push("/RAYTeamsPage/" + x.value)}>
									<Link to="#" className={"nav-link " + (tab === x.value ? 'active' : '')}>{x.label}</Link>
								</li>
							})}
						</ul>
					</div>
				</div>
				<div className='mt-5'>
					{tab === "app" && <RMRayteamsApp rayteams={rayteams}/>}
					{tab === "device" && <RMRayteamsDevice rayteams={rayteams} devices={devices} />}
				</div>
			</>}
    	</div>
  	);
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const region =  state.AuthReducer.region;
	const rayteams =  state.AuthReducer.rayteams;
	const devices = state.AuthReducer.devices;
	return { region, user, rayteams, devices };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, null)(RAYTeamsPage);