import axios from 'axios';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { MLPanelLoading } from '../../components/common/MLPanel';
import RMWidgetCount from '../widgets/RMWidgetCount';
import { useTranslation } from 'react-i18next';
import { InfoAction } from '../../redux/actions';
import LicenseStatList from './LicenseStatList';
import LicenseChart from './LicenseChart';
import { RMLICENSENAME_SUB } from '../../constants/RMConstants';

const LicenseStatCard = ({ items }) => {
	const dispatch = useDispatch();
    const [t] = useTranslation(['translation']);
    const [genItems, setGenItems] = useState([]);
    const [genListItems, setGenListItems] = useState([]);
    const [currentTrial, setCurrentTrial] = useState(0);
    const [currentNormal, setCurrentNormal] = useState(0);
    const [paidConversionRate, setPaidConversionRate] = useState(0);
    const [cancellationRate, setCancellationRate] = useState(0);
    const [loading, setLoading] = useState(true);
    const { _id } = useParams();
    const day = 7;
    const daystamp = new Date().getTime() + (day * 24 * 3600 * 1000);

	useEffect(async () => {
        const curTrial = 
        _id === "App-RAYFusion" ? items.filter(c => c.sk.includes("pl:trial") && !!c?.relu_license_id && items.find(e => e.sk.includes(c.sk.replace("pl:", ""))).expired >= new Date().getTime())
        : items.filter(c => c.sk.includes("lcuse:") && c.sk.includes("trial:") && c.expired >= new Date().getTime());
        setCurrentTrial(curTrial.length);
        const curNormal = 
        _id === "App-RAYFusion" ? items.filter(c => c.sk.includes("pl:normal") && !!c?.relu_license_id && items.find(e => e.sk.includes(c.sk.replace("pl:", ""))).expired >= new Date().getTime())
        : items.filter(c => c.sk.includes("lcuse:") && c.sk.includes("normal:") && c.expired >= new Date().getTime());
        setCurrentNormal(curNormal.length);
        const plList = 
        _id === "App-RAYFusion" ? items.filter(f => (f.sk.includes("pl:trial") || f.sk.includes("pl:normal") || (f.sk.includes("pl:annual") && f?.activated)) && !!f?.relu_license_id ) 
        : items.filter(f => (f.sk.includes("pl:trial") || f.sk.includes("pl:normal") || f.sk.includes("pl:annual")) && f.used != "" )
        const licUserData = [];
        const genLicData = [];
        let userIdList = [];
        for (const plItem of plList) {
            if(plItem?.used && plItem.used.split(":").length > 1) {
                userIdList.push(plItem.used.split(":")[1])
                // const userData = await getLicenseUserData(plItem.used.split(":")[1])
                // licUserData.push(...userData.filter(f => !f.sk.includes("prdLog:")))
            }
        }

        const ret  = await InfoAction.callDB({ type : "scan", region : "ap-northeast-2", params : {
			TableName: "rayteams-user",
			FilterExpression: "#canceled > :canceled and #title = :title",
			ExpressionAttributeNames: { "#canceled": "canceled", "#title": "title"},
			ExpressionAttributeValues: { ":canceled" : 0, ":title" : RMLICENSENAME_SUB.find(f => f.value === _id).label }
		}});

        licUserData.push(...ret.filter(f => !f.sk.includes("prdLog:")));

        if (plList && plList.length > 0) {
            plList.map(x => {
                const lcData = items.find(f => f.sk.includes(x.sk.replace("pl:", "")) && f.sk.includes("lcuse:"))
                const userId = x?.used && x.used.split(":").length > 1 && x.used.split(":")[1];
                const date = moment(x.assigned);
                const yearMonth = date.format('YYYY-MM');

                // cancel 이 있으면 취소 건수
                // pl:normal 이 있으면 갱신 건수
                if(lcData) {
                    genLicData.push({ 
                        _id : x._id,
                        plSk: x?.sk,
                        lcSk: lcData?.sk,
                        pid: lcData?.pid,
                        userId: userId,
                        assigned: x?.assigned,
                        created: x?.created,
                        //canceled: licUserData.find(f => f._id === userId && f.sk.includes(`product:${lcData?.pid}:`))?.canceled,
                        canceled: licUserData.find(f => f._id === userId && f?.appsLcuse && f.appsLcuse[0].sk === x.sk)?.canceled,
                        relu_license_id: x?.relu_license_id,
                        yearMonth: yearMonth,
                    })
                }
            })
        }
        console.log("genLicData : ", genLicData);

        const CumulativeNewUserList = await genCumulativeNewUsers(genLicData);
        setGenListItems(CumulativeNewUserList);
        // 0월에 발생한
        // 신규(무료)
        // 재가입(유료)
        // 유료전환
        // 구독취소

        // 0월 말일이 기준
        //// 누적 무료 가입자
        // 누적 재가입자
        // 누적 유료 전환수
        //// 누적 구독 취소수
        // 누적 유료 구독자 수(누적 재가입자 + 누적 유료 전환수)
        // Credit
	}, []);

    useEffect(() => {
        const cumulRenew = genListItems.sort((a, b) => new Date(b.date) - new Date(a.date))[0]?.cumulativeRenewSum;
        const cumulSum = genListItems.sort((a, b) => new Date(b.date) - new Date(a.date))[0]?.cumulativeSum;
        const cumulcancel = genListItems.sort((a, b) => new Date(b.date) - new Date(a.date))[0]?.cumulativeCancelSum;
        // 유료 구독 전환율 (PaidConversionRate)
        ////// (유료 구독 전환 사용자 수 / 무료 사용자 수) * 100
        setPaidConversionRate((cumulRenew / cumulSum) * 100);
        // 구독 취소율
        ////// (구독 취소자 수 / 전체 구독자 수) * 100
        setCancellationRate((cumulcancel / cumulSum) * 100);
        
        // 고객 유지율
        ////// (구독을 유지한 사용자 수 / 전체 사용자 수) * 100
        
        // 평균 구독 기간
        ////// (총 구독 유지 기간 합계 / 전체 구독자 수)
    }, [genListItems])

    // 신규 누적 사용자
    const genCumulativeNewUsers = async (data) => {
        const monthlySums = {};
        // 신규 사용자
        data.filter(f => f?.plSk && f?.assigned && f?.assigned != 99999999999999 && f.plSk.includes("pl:trial:")).forEach(item => {
            const date = moment(item.assigned);
            const yearMonth = date.format('YYYY-MM');
            if (!monthlySums[yearMonth]) {
                monthlySums[yearMonth] = { monthlyPayedUsers: 0, yearlyPayedUsers: 0, newUsers: 0, cancelUsers: 0, renewUsers: 0 };
            }
            monthlySums[yearMonth].newUsers += 1;
        });

        // 취소
        data.filter(f => f?.canceled && f.canceled > 0).forEach(item => {
            const date = moment(item.assigned);
            const yearMonth = date.format('YYYY-MM');
            if (!monthlySums[yearMonth]) {
                monthlySums[yearMonth] = { monthlyPayedUsers: 0, yearlyPayedUsers: 0, newUsers: 0, cancelUsers: 0, renewUsers: 0 };
            }
            monthlySums[yearMonth].cancelUsers += 1;
        });

        // normal payed user
        data.filter(f => f?.plSk.includes("pl:normal")).forEach(item => {
            const date = moment(item.assigned);
            const yearMonth = date.format('YYYY-MM');
            if (!monthlySums[yearMonth]) {
                monthlySums[yearMonth] = { monthlyPayedUsers: 0, yearlyPayedUsers: 0, newUsers: 0, cancelUsers: 0, renewUsers: 0 };
            }
            monthlySums[yearMonth].monthlyPayedUsers += 1;
        });

        // RAYFusion annual 라이선스는 activated 되고 30일 후에 차감한다.
        data.filter(f => f.plSk.includes("pl:annual")).forEach(item => {
            // activated 이후 30일 지나야 credit 차감
            if (item?.activated) {
                const millisecondsInADay = 24 * 60 * 60 * 1000; // 하루의 밀리초 값
                const date = moment(item.activated + millisecondsInADay);
                console.log("date : date : date : date : ", date);
                const yearMonth = date.format('YYYY-MM');
                if (!monthlySums[yearMonth]) {
                    monthlySums[yearMonth] = { monthlyPayedUsers: 0, yearlyPayedUsers: 0, newUsers: 0, cancelUsers: 0, renewUsers: 0 };
                }
                monthlySums[yearMonth].yearlyPayedUsers += 1;
            }
        });

        // 유료 전환 사용자
        // 갱신 리스트 중복 제거
        // pl:normal이고 userid, relu_id 가 같고 canceled 가 없는 건이  여러 건이 있는 경우
        const uniqList = {};
        data.filter(x => x?.plSk && x.plSk.includes("pl:normal")).forEach(item => {
            const { userId, assigned, relu_license_id } = item;
            if (!uniqList[`${userId}:${relu_license_id}`] || uniqList[`${userId}:${relu_license_id}`].assigned > assigned) {
                uniqList[`${userId}:${relu_license_id}`] = { userId, assigned };
            }
        });
        const filteredList = Object.values(uniqList)
        filteredList.forEach(item => {
            const date = moment(item.assigned);
            const yearMonth = date.format("YYYY-MM");
            if (!monthlySums[yearMonth]) {
                monthlySums[yearMonth] = { monthlyPayedUsers: 0, yearlyPayedUsers: 0, newUsers: 0, cancelUsers: 0, renewUsers: 0 };
            }
            monthlySums[yearMonth].renewUsers += 1;
        })

        // 누적 값을 계산
        const resultArray = [];
        let cumulativeSum = 0;
        let cumulativeCancelSum = 0;
        let cumulativeRenewSum = 0;
        let cumulativeMonthlyPayed = 0;
        let cumulativeYearlyPayed = 0;
        let cumulativeReSub = 0;

        Object.keys(monthlySums).sort().forEach(date => {
            const { monthlyPayedUsers, yearlyPayedUsers, newUsers, cancelUsers, renewUsers } = monthlySums[date];

            // 누적 값 업데이트
            cumulativeSum += newUsers;
            cumulativeCancelSum += cancelUsers;
            cumulativeRenewSum += renewUsers;
            cumulativeMonthlyPayed += monthlyPayedUsers;
            cumulativeYearlyPayed += yearlyPayedUsers;

            // 0월에 발생한
            //// 신규(무료)
            // 재가입(유료)
            //// 유료전환
            // 유료 전환은 취소 와 상관 없이 해당 월에 첫 결제한 사용자를 말합니다.
            //// 구독취소

            // 0월 말일이 기준
            //// 누적 무료 가입자
            // 누적 재가입자
            //// 누적 유료 전환수
            //// 누적 구독 취소수
            // 누적 유료 구독자 수(누적 재가입자 + 누적 유료 전환수)
            
            resultArray.push({
                date,
                newUsers,               // 신규(무료)
                cumulativeSum,          // 누적 무료 가입자
                cancelUsers,            // 구독 취소
                cumulativeCancelSum,    // 누적 구독 취소
                renewUsers,             // 유료 전환
                cumulativeRenewSum,     // 누적 유료 전환
                monthlyPayedUsers,
                cumulativeMonthlyPayed,
                yearlyPayedUsers,
                cumulativeYearlyPayed,
                resubUsers: 0,
                cumulativeReSub
            });
        });

        return resultArray;
    }

    const genCumulativeUsers = async (monthlySums, data) => {
        
    }

    const getLicenseUserData = async (_id) => {
        const ret  = await InfoAction.callDB({ type : "query", region : "ap-northeast-2", params : {
			TableName: "rayteams-user",
			KeyConditionExpression: "#_id = :_id",
			ExpressionAttributeNames: { "#_id": "_id" },
			ExpressionAttributeValues: { ":_id": _id }
		}});
		if(ret.length > 0){
			return ret
		}
	}

    // 유료 구독 전환율
    ////// (유료 구독 전환 사용자 수 / 무료 사용자 수) * 100
    // 구독 취소율
    ////// (구독 취소자 수 / 전체 구독자 수) * 100
    // 고객 유지율
    ////// (구독을 유지한 사용자 수 / 전체 사용자 수) * 100
    
    // 평균 구독 기간
    ////// (총 구독 유지 기간 합계 / 전체 구독자 수)
  	return (<>
        {items && <> <div className='row'>
            <div className='col'>
                <RMWidgetCount title={t("누적 무료 사용자")} sub={t("누적")} value={genListItems.sort((a, b) => new Date(b.date) - new Date(a.date))[0]?.cumulativeSum || 0} iconName="users" />
            </div>
            <div className='col'>
                <RMWidgetCount title={t("현재 무료 사용자")} sub={t("Trial 사용자")} value={currentTrial} iconName="users" />
            </div>
            <div className='col'>
                <RMWidgetCount title={t("현재 유료 사용자")} sub={t("Commercial 사용자")} value={currentNormal} iconName="users" />
            </div>
            <div className='col'>
                <RMWidgetCount title={t("유료 전환율")} sub={"무료 사용 후 월간 구독으로 전환"} value={`${Math.floor(paidConversionRate || 0)} %` } iconName="percentage" />
            </div>
            <div className='col'>
                <RMWidgetCount title={t("구독 취소율")} sub={"전체 구독자 중 취소 비율"} value={`${Math.floor(cancellationRate || 0)} %` } iconName="percentage" />
            </div>
            </div>
            <div className='pt-4'>
                {genListItems && genListItems.length > 0 && <LicenseChart items={genListItems} /> }
            </div>
                {genListItems && <LicenseStatList licenseStatData={genListItems} pools={items}/> }
        </>}
    </>
  	);
}

const mapState = (state) => {
	const region = state.AuthReducer.region;
	return { region };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, null)(LicenseStatCard);
