import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import MaterialIcon from '@material/react-material-icon';

const MLWFCombo = ({ workflows, options = {}, handleChanged, readonly = false, css = "" }) => {

	const [focus, setFocus] = useState(false);
	const [val, setVal] = useState("");
	const [list, setList] = useState([]);
	const [t] = useTranslation(['translation']);

	useEffect(() => {
		var nList = [{ value : "", label : "Not Assigned"}];
		setList(nList.concat(workflows.map(x => { return { value : x._id, label : x.name } })))
	}, [workflows])

	useEffect(() => {
		if(list && options.value){
			setVal(list.find(x => x.value === options.value));
		}
	}, [options, list])

	const onChanged = (e) =>{
		setVal(e);
		handleChanged && handleChanged({ target : { name : options.name, value : e.value }});
	} 

	return <div className={"mb-5" + 
			(css && " " + css) +
			(focus ? " focused" : "") +
			(options.err ? " has-error" : "") }>
		{(!options.readonly && !readonly) && <>
			{options.label && <label className={options.required ? 'form-label required' : 'form-label'}>{options.label}<span/></label>}
			<Select 
				name={options.name} 
				onFocus={() => setFocus(true)}
				onBlur={() => setFocus(false)}
				value={val} 
				options={options.excludes ? list.filter(m => !options.excludes.includes(m.value)) : list}
				onChange={onChanged} />
			{options.err && <label className="error" for={options.name}>{options.errmsg}</label>} 
			{options.hint && <label className="help" for={options.name}>{options.hint}</label>} 
		</>}
		{(options.readonly || readonly) && <>
			{options.label && <label className='form-label'>{options.label}<span/></label>}
			<p className='fs-16'>
				<Link to={"/Admin/AdminWFInfo/" + options.value}>
					<MaterialIcon icon='account_tree' style={{ fontSize : 16, marginRight : 2 }}/>
					{val && val.label}
				</Link>
			</p>

		</>}
	</div>
}

const mapState = (state) => {
	const workflows = state.WFReducer.items;
	return { workflows };
};

const mapDispatch = dispatch => ({
})

export default connect(mapState)(MLWFCombo);