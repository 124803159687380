import { AppConfig } from '../../config';
import { AppConstant } from '../reducers/AppReducer';

const getAPI = () =>{
    const env = (location.href.indexOf("localhost") > -1 || location.href.indexOf(".raydevelop.com") > -1) ? "development" : "production";
    if(env === "production"){
        return "https://api-manager-license.rayteams.com";
    }else{
        return "https://api-manager-license.raydevelop.com";
    }
};

const UpdateVersion = (version) => async (dispatch) => {
    dispatch({ type: AppConstant.VERSION_UPDATE, value: version });
};
const UpdateInfo = (payload) => async (dispatch) => {
    dispatch({ type: AppConstant.INFO_UPDATE, value: payload });
};

const CallMenuChange = () => (dispatch) => {
    dispatch({ type: AppConstant.SET_MENU_TRACKING });
}

export const AppAction = {
	UpdateVersion,
	UpdateInfo,
    CallMenuChange,
    getAPI,
};