import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { TbDental } from 'react-icons/tb';
import { MLPanelLoading } from '../../components/common/MLPanel';
import { AuthConstant, getAPI, getHeader } from '../../redux/reducers/AuthReducer';
import { history } from '../../history';
import RMProjectPosts from '../../components/project/RMProjectPosts';
import RMProjectMemo from '../../components/project/RMProjectMemo';
import RMProjectAttachments from '../../components/project/RMProjectAttachments';
import RMProjectHistory from '../../components/project/RMProjectHistory';
import { idData, queryData } from '../../libs/db';
import { ProjectConstant } from '../../redux/reducers';
import { MLFileSize } from '../../components/common/MLCommon';


const ProjectsInfoPage = ({ groups, project }) => {
	
	const dispatch = useDispatch();
	const { _id, tab = "posts" } = useParams();
	const [loading, setLoading] = useState(true);

	const tabs = [
		{value : "posts", label : "Posts"},
		{value : "attachments", label : "Attachments"},
		{value : "history", label : "History"},
	]

	useEffect(() => {
		getData_dProject();
	}, [_id]);

    const getData_dProject = async () => {
		setLoading(true);
		try {
			const ret = await idData("rayteams-project", _id);
			var list = [];
			list = ret.Items.filter(y => y?.sk === "project").map(x => { 
				return {...x, 
					isowner : x.ownerGroupId === groups._id,
					patientname : x.subject?.name || "",
					ownergroup: groups.filter(y => y._id === x?.ownerGroupId)[0]?.name || "",
					lab: groups.filter(y => y._id === x?.labId)[0]?.name || "",
					filesize: MLFileSize(x?.size)
				}
			});
			const paramsGetAttachments = {
				_id: {
					ComparisonOperator: "EQ",
					AttributeValueList: [_id],
				  },
				sk: {
					ComparisonOperator: "BEGINS_WITH",
					AttributeValueList: ["attachments:"],
				}
			}
			const attachments = await queryData("rayteams-project", paramsGetAttachments);
			list[0].attachments = attachments.Items;
            // comments
			const paramsGetComments = {
				_id: {
					ComparisonOperator: "EQ",
					AttributeValueList: [_id],
				},
				sk: {
					ComparisonOperator: "BEGINS_WITH",
					AttributeValueList: ["memo:"],
				},
			};
	  		const comments = await queryData("rayteams-project", paramsGetComments);
	  		list[0].memo = comments.Items;
			// histories
			const paramsGetHistories = {
				_id: {
					ComparisonOperator: "EQ",
					AttributeValueList: [_id],
				},
				sk: {
					ComparisonOperator: "BEGINS_WITH",
					AttributeValueList: ["his:"],
				},
			};
			const histories = await queryData("rayteams-project", paramsGetHistories);
			list[0].histories = histories.Items;

			dispatch({ type : ProjectConstant.GET_PROJECT, item : list[0] });
		} catch (e) {
			dispatch({ type : ProjectConstant.GET_PROJECT, item : {} });
		}
		setLoading(false);
	}
    
  	return (
    	<div>
			{loading && project?._id && <>
				<h2 className='mb-4'> Case Info </h2>
				<MLPanelLoading />
			</>}
			{!loading && (project?._id === _id) && <>
				<div className='card'>
					<div className='card-body'>
						<div className='d-flex justify-content-between'>
							<h2 className='mb-4'>
								<span><TbDental style={{ width : 22, height : 22}} /></span>
								{project.name ? project.name : "Project Info"}
							</h2>
							<div>
								<span className='ms-2 text-muted'> Created : {moment(project.created).format('LL')}</span>
							</div>
						</div>
						<div>
							<span className='text-muted'>
								<span className='badge badge-success me-1'>
                                    {project?.projecttype}
								</span>
                                Owner Group : {project?.ownergroup},  Related Group : {project?.lab}
							</span>
						</div>
					</div>
					<hr className='mb-0'></hr>
					<div className="card-header">
						<ul className="detail-tab nav nav-line-tabs nav-stretch">
							{tabs.map((x, idx) => {
								return <li className="nav-item" key={idx} onClick={() => history.push("/ProjectsInfo/" + project._id + "/" + x.value)}>
									<Link to="#" className={"nav-link " + (tab === x.value ? 'active' : '')}>{x.label}</Link>
								</li>
							})}
						</ul>
					</div>
				</div>
				<div className='mt-5'>
					{tab === "posts" && <RMProjectMemo projectId={project._id} project={project} />}
					{tab === "attachments" && <RMProjectAttachments projectId={project._id} project={project} />}
					{tab === "history" && <RMProjectHistory projectId={project._id} project={project} />}
				</div>
			</>}
    	</div>
  	);
}

const mapState = (state) => {
	const groups =  state.AuthReducer.groups;
    const project = state.ProjectReducer.project;
    const projects = state.ProjectReducer.projects;
	return { groups, project, projects };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState)(ProjectsInfoPage);
