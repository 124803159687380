import axios from 'axios';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { MLText } from '../../components/common/MLInput';
import { MLPanelLoading } from '../../components/common/MLPanel';
import MLTable from '../../components/common/MLTable';
import { AuthConstant, getAPI, getHeader } from '../../redux/reducers/AuthReducer';
import { fetchData } from '../../libs/db';
import WidgetBarChartAppUsage from '../../components/widgets/dashboard/WidgetBarChartAppUsage';
import RMWidgetBarChart from '../../components/widgets/RMWidgetBarChart';
import RMWidgetLineChart from '../../components/widgets/RMWidgetLineChart';
import { MLButton, MLRefreshButton } from '../../components/common/MLButton';
import { history } from '../../history';
import { COLORS } from '../../constants/FromInputs';
import { TbList } from 'react-icons/tb';
import { ProgramConstant } from '../../redux/reducers';
import RMWidgetCount from '../../components/widgets/RMWidgetCount';
import { RMIconList, RMIconChart } from '../../components/icon/MLIcon';

const SubscriptionsDashboard = ({ subscriptions }) => {
	const dispatch = useDispatch();
	const [search, setSearch] = useState("");
	const [items, setItems] = useState([]);
	const [loading, setLoading] = useState(false);

    const remainDay = 30;
    const remain = new Date().getTime() + (remainDay * 24 * 3600 * 1000);

	useEffect(() => {
	}, []);

  	return ( <>
        <div className="header align-items-stretch d-block" style={{position: "initial"}}>
            <div className="d-flex w-100 justify-content-between p-5 align-items-center">
                <div className="d-flex align-items-center text-dark fw-bolder fs-2 my-1">Subscriptions</div>
                <div className="d-flex align-items-center">
                    <MLButton options={{
                        label : "Show List",
                        color : COLORS.PRIMARY,
                        size : "sm",
                        action : () => history.push("/Subscriptions")
                    }} css="me-2" />
                    <MLRefreshButton onClick={() => getData()} />
                </div>
            </div>
        </div>
        {subscriptions && <div className="d-flex flex-column flex-column-fluid">
            <div className="post p-7">
                <div className='row'>
                    <div className='col'>
                        <RMWidgetCount data={subscriptions} title={"Total"} />
                    </div>
                    <div className='col'>
                        <RMWidgetCount data={[...new Set(subscriptions.map(x => x._id))]} title={"Users"} />
                    </div>
                    <div className='col'>
                        <RMWidgetCount data={[...new Set(subscriptions.map(x => x.ccode))]} title={"Country"} />
                    </div>
                    <div className='col'>
                        <RMWidgetCount data={subscriptions.filter(x => x.expired < (new Date().getTime()))} title={"Expired"} />
                    </div>
                    <div className='col'>
                        <RMWidgetCount data={subscriptions.filter(x => x.expired < remain)} title={"Expired(" + remainDay +"D)"} />
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <RMWidgetBarChart data={subscriptions} type={"Group"} title={"Best seller top 5"} target={"title"} />
                    </div>
                    <div className='col'>
                        <RMWidgetBarChart data={subscriptions.map(x => ({...x, created : x.lastrenew}))} title={"구독 신규/갱신"} period={"days"} targetdate={"created"} targetname={"ccode"} chartType={true} />
                    </div>
                </div>
            </div>
        </div>}
    </>
  	);
}

const mapState = (state) => {
	const subscriptions =  state.PaymentReducer.subscriptions;
	return { subscriptions };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, null)(SubscriptionsDashboard);
