import saveAs from 'file-saver';
import _ from "lodash";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Spreadsheet from "react-spreadsheet";
import { MLButton } from "../../components/common/MLButton";
import { MLPanelLoadingOverlay } from "../../components/common/MLPanel";
import { XEmail, XName } from "../../components/common/MLString";
import { RTAudit } from "../../libs/audit";
import { InfoAction } from "../../redux/actions";

const UsersExport = ({ list, groups, allusers, config, callbackClose, CheckUser }) => {
    const [t] = useTranslation();
    const [loading, setLoading] = useState(false);
    const sampleList = useMemo(() => _.chunk(list, 14)[0], list);
    const useAllViewUserData = useMemo(() => config?.showalludata ?? false, [config?.showalludata]);
    const useApproved = useMemo(() => config?.approvedaccountonly ?? false, [config?.approvedaccountonly]);
    const groupsObjById = useMemo(() => _.keyBy(groups, "_id"), [groups]);
    const checkedUsersById = useMemo(() => _.keyBy(allusers, "_id"), [allusers]);

    const genData = useCallback((_list, userObj) => {
        let headerItems = [
            { value: t("NAME"), readOnly: true },
            { value: t("EMAIL"), readOnly: true },
            { value: t("GROUP NAME"), readOnly: true },
            { value: t("COUNTRY"), readOnly: true },
            { value: t("TYPE"), readOnly: true },
            { value: t("APPROVED"), readOnly: true, key: "approved" },
            { value: t("APPROVED AT"), readOnly: true, key: "approved" },
            { value: t("REGISTERED"), readOnly: true },
            { value: t("LAST LOGGED"), readOnly: true },
        ];
        if (!useApproved) {
            headerItems = headerItems.filter(x => x.key !== "approved");
        }
        const _data = [];
        _list.forEach(_item => {
            const row = [];
            const user = userObj[_item?._id];
            const group = groupsObjById[_item?.groupId];
            const userName = user?.name || "";
            const userEmail = user?.email || "";
            row.push({ value: useAllViewUserData ? userName : XName(userName), readOnly: true });
            row.push({ value: useAllViewUserData ? userEmail : XEmail(userEmail), readOnly: true });
            row.push({ value: group?.name || "", readOnly: true });
            row.push({ value: group?.countryCode || "", readOnly: true });
            row.push({ value: user?.utype?.join(", ") || "", readOnly: true });
            useApproved && row.push({ value: user?.approved ? t("Approved") : t("Pending"), readOnly: true });
            useApproved && row.push({ value: user?.approved ? moment(user.approved).format("YYYY-MM-DD") : "", readOnly: true });
            row.push({ value: user?.created ? moment(user.created).format("YYYY-MM-DD") : "", readOnly: true });
            row.push({ value: user?.lastlogged ? moment(user.lastlogged).format("YYYY-MM-DD HH:mm") : "", readOnly: true });
            _data.push(row);
        });
        return [headerItems, ..._data];
    }, [t, useApproved, useAllViewUserData, groupsObjById]);

    const data = useMemo(() => {
        return genData(sampleList, checkedUsersById);
    }, [sampleList, genData, checkedUsersById, loading]);

    const checkAllUsers = useCallback(async () => {
        const _userObj = {}
        const fncList = [];
        for (const _item of list) {
            fncList.push(async () => {
                const _user = await CheckUser(_item?._id);
                if (_user?._id) {
                    _userObj[_user._id] = _user;
                }
            });
        }
        const fncChunk = _.chunk(fncList, 10);
        for (const fncChunkItem of fncChunk) {
            await Promise.all(fncChunkItem.map(async x => await x()));
        }
        return _userObj;
    }, [list, checkedUsersById]);

    const exportData = useCallback(async () => {
        setLoading(true);
        try {
            let allUserObjById = {}
            try {
                allUserObjById = await checkAllUsers();
            } catch (error) {
                //
            }
            const sheetData = genData(list, allUserObjById);
            let csvContent = "\ufeff";
            sheetData.forEach(function (rowArray) {
                let row = rowArray?.map(x => `"${x.value || ""}"`).join(",");
                csvContent += row + "\r\n";
            });
            const content = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const name = `user_list_${new Date().toISOString()}.csv`;
            saveAs(content, name);
            const auditExtra = {
                fileName: name,
                count: list.length,
                ids: list?.map(x => x?._id) || [],
            };
            await RTAudit({ lvl: 4, msg: 'Download User List' }, JSON.stringify(auditExtra));
        } catch (error) {
            console.log("ERROR[exportData]", error.toString());
        } finally {
            setLoading(false);
        }
    }, [list]);

    return <>
        {loading && <MLPanelLoadingOverlay position="absolute" />}
        <div className="pt-1 pb-1" style={{ overflowX: "auto" }}>
            <Spreadsheet
                data={data}
                hideColumnIndicators={true}
                hideRowIndicators={true}
            />
        </div>
        <div className="d-flex align-items-center justify-content-between mt-2" style={{ position: "sticky", bottom: 0 }}>
            <div className="text-muted">
                {t("Examples are displayed with a maximum of 15 items.")}
            </div>
            <div className="d-flex align-items-center gap-2">
                <MLButton
                    options={{
                        label: t("Cancel"),
                        action: callbackClose,
                    }}
                />
                <MLButton
                    options={{
                        label: t("Export"),
                        color: "primary",
                        action: exportData,
                        disabled: loading,
                    }}
                />
            </div>
        </div>
    </>
};

const mapState = (state) => {
    const groups = state.AuthReducer.groups;
    const allusers = state.ProgramReducer.allusers || [];
    const config = state.ProgramReducer.config;
    return { groups, allusers, config };
};

const mapDispatch = (dispatch) => ({
    CheckUser: (_id) => dispatch(InfoAction.CheckUser(_id)),
});

export default connect(mapState, mapDispatch)(UsersExport);
