import { useEffect, useState } from 'react';
import moment from 'moment';
import { connect, useDispatch } from 'react-redux';
import MLTable from '../common/MLTable';
import { AuthConstant, getAPI, getHeader } from '../../redux/reducers/AuthReducer';
import { MLPanelLoading } from '../common/MLPanel';
import WidgetProjectSummary from './WidgetProjectSummary';
import WidgetStorageUsage from './WidgetStorageUsage';
import { fetchData } from '../../libs/db';

const RMProjectList = ({ group }) => {

	const dispatch = useDispatch();
	const [ items, setItems ] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if(group._id) {
			getData();
		}
	}, [group]);

    const getData = async () => {
		try{
			var list = []
			const paramsGetMyProjectIds = {
				sk: {
					ComparisonOperator: "BEGINS_WITH",
					AttributeValueList: ["project:institute"],
				  },
				  group_id: {
					ComparisonOperator: "EQ",
					AttributeValueList: [group._id],
				  },
			}

			const groupIdsObj = await fetchData("rayteams-project", paramsGetMyProjectIds);
			const pIds = groupIdsObj.Items.map((x) => x._id);

			const paramsAllProject = {
				_id: {
				  ComparisonOperator: "IN",
				  AttributeValueList: pIds,
				},
				sk: {
				  ComparisonOperator: "EQ",
				  AttributeValueList: ["project"],
				},
				isDel: {
				  ComparisonOperator: "EQ",
				  AttributeValueList: [false],
				},
			};
			const projectsObj = await fetchData("rayteams-project", paramsAllProject);
			const projects = projectsObj.Items;

			list = projects.map(x => { 
				return {...x, 
					duration : x.isarchive ? moment(x.updated).diff(moment(x.created), "days") : moment().diff(moment(x.created), "days"),
					isowner : x.ownerGroupId === group._id,
					toId : x.ownerGroupId === group._id ? x.labId : x.ownerGroupId,
					patientname : x.subject?.name || "",
				}
			});
			dispatch({ type : AuthConstant.GET_GROUP_PROJECTS, items : list });
			setItems(list);
		}catch{
			setItems([]);
		}
		setLoading(false);
    }

    const headers = [
        { value : "toId", label : "To/From", type : "GROUP",  css : "text-start" },
        { value : "projecttype", label : "Type", css : "text-start" },
        { value : "name", label : "Project Name", css : "text-start", type : "XName" },
        { value : "count", label : "Files", style : { width : 80 }, css : "text-center" },
        { value : "size", label : "Size", type : "SIZE",  style : { width : 100 }, css : "text-center" },
        { value : "created", label : "Created", type : "DATESHORT", style : { width : 120 }, css : "text-center" },
        { value : "updated", label : "Updated", type : "DATESHORT", style : { width : 120 }, css : "text-center" },
        { value : "isarchive", label : "Done", type : "TFVALUE", style : { width : 80 }, css : "text-center" }
    ]

  	return <>
		{loading && <MLPanelLoading noborder={true} />}
		{!loading && <MLTable headers={headers} items={items.sort((a, b) => a.created - b.created > 0 ? -1 : 1)} nocard={true} noheader={true} /> }
	</>
}

export default connect(null)(RMProjectList);
