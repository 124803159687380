import axios from "axios";
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { useEffect, useState } from "react";
import { BUTTONS, COLORS, INPUTS, SPAN } from '../../constants/FromInputs';
import { MLButton } from '../common/MLButton';
import { UploadFileS3, UploadS3 } from "../../libs/s3";
import { MLText } from "../common/MLInput";
import { MLForm } from "../common/MLForm";
import MLTable from "../common/MLTable";
import { ProgramConstant } from "../../redux/reducers";
import { Link, useParams } from 'react-router-dom';
import { InfoAction } from "../../redux/actions";

const getEnv = () => {
    if(location.href.indexOf("localhost") > -1 || location.href.indexOf("127.0.0.1") > -1){
        return "dev-local";
    }else if(location.href.indexOf("raydevelop") > -1){
        return "development";
    }
    return "production";
}

const ServiceConfigConsent = ({ UpdateApplication }) => {
	const [t] = useTranslation(['translation']);
	const dispatch = useDispatch();
    const partnerKey = localStorage.getItem('partnerKey') || '';
    const [data, setData] = useState({});
    const [err, setErr] = useState("");
    const [edit, setEdit] = useState(false);
    const [terms, setTerms] = useState([]);
    const [privacy, setPrivacy] = useState([]);
    const [setconsent, setConsent] = useState([]);
    const [extraForms, setExtraForms] = useState([]);
	const partner = process.env.REACT_APP_COMPANY || 'ray';
    const { _id = process.env.REACT_APP_COMPANY } = useParams();

    useEffect(() => {
        getConfig();
        getConsent();
    }, []);

    const getConfigPath = () => {
        if(getEnv() === "production"){
            return "https://ray-data.s3.ap-northeast-2.amazonaws.com/rayrnd/rayteams-service/"+ _id + "/config.json";
        }else{
            return "https://ray-data-development.s3.ap-northeast-2.amazonaws.com/rayrnd/rayteams-service/"+ _id + "/config.json";
        }
    }

    const getConfig = async () => {
        try{
            const conf = await axios.get(getConfigPath());
            setData(conf?.data || {});
            dispatch({ type : ProgramConstant.SET_B2B_SERVICE, item : conf?.data || {} });
        }catch{
            setData({});
        }
    }

    const getConsent = async () => {
        try{
            const ret_terms = await InfoAction.callDB({ type : "scan", region : "ap-northeast-2", params : {
                TableName: "rayteams-manager-contents",
                FilterExpression: "#sk = :sk and #type = :type",
                ExpressionAttributeNames: { "#sk": "sk",  "#type": "type" },
                ExpressionAttributeValues: { ":sk": 'info', ":type": 'CONSENT_TERMS' },
            }});
            setTerms(ret_terms);
            const ret_privacy = await InfoAction.callDB({ type : "scan", region : "ap-northeast-2", params : {
                TableName: "rayteams-manager-contents",
                FilterExpression: "#sk = :sk and #type = :type",
                ExpressionAttributeNames: { "#sk": "sk",  "#type": "type" },
                ExpressionAttributeValues: { ":sk": 'info', ":type": 'CONSENT_PRIVACY' },
            }});
            setPrivacy(ret_privacy);
            const ret_consent = await InfoAction.callDB({ type : "scan", region : "ap-northeast-2", params : {
                TableName: "rayteams-manager-contents",
                FilterExpression: "#sk = :sk and #type = :type",
                ExpressionAttributeNames: { "#sk": "sk",  "#type": "type" },
                ExpressionAttributeValues: { ":sk": 'info', ":type": 'CONSENT' },
            }});
            setConsent(ret_consent);
            const ret_form = await  InfoAction.callDB({ type : "scan", region : "ap-northeast-2", params : {
                TableName: "rayteams-product",
                FilterExpression: "#sk = :sk and #type = :type",
                ExpressionAttributeNames: { "#sk": "sk",  "#type": "type" },
                ExpressionAttributeValues: { ":sk": 'form', ":type": 'FORM_REGISTER' },
            }});
            setExtraForms(ret_form);
        }catch{
            setTerms([]);
            setPrivacy([]);
            setExtraForms([]);
        }
    }

    const update = async () => {
        const conf = await axios.get(getConfigPath());
        const ori_config = conf?.data || {};
        const dt = new Date().getTime();
        const bucket = getEnv() === "production" ? 'ray-data' : 'ray-data-development';
        let newData = {...data};
        if(data.servicefavicon?.path){
            if(await UploadFileS3(bucket, data.servicefavicon, 'rayrnd/rayteams-service/' + _id + '/fav-' + dt +'.' + data.servicefavicon.path.split('.').pop())){
                newData = {
                    ...newData, 
                    servicefavicon : 'https://' + bucket + '.s3.ap-northeast-2.amazonaws.com/rayrnd/rayteams-service/' 
                        + _id 
                        + '/fav-' + dt +'.' + data.servicefavicon.path.split('.').pop()
                }
            }
        }
        if(data.servicelogo?.path){
            if(await UploadFileS3(bucket, data.servicelogo, 'rayrnd/rayteams-service/' + _id + '/logo-' + dt +'.' + data.servicelogo.path.split('.').pop())){
                newData = {
                    ...newData, 
                    servicelogo : 'https://' + bucket + '.s3.ap-northeast-2.amazonaws.com/rayrnd/rayteams-service/' 
                        + _id 
                        + '/logo-' + dt +'.' + data.servicelogo.path.split('.').pop()
                }
            }
        }
        await UploadS3(
            getEnv() === "production" ?  "ray-data" : "ray-data-development",
            "rayrnd/rayteams-service/"+ _id + "/config.json",
            {...ori_config, ...newData}
        );
        await getConfig();
        setEdit(false);
    }

    const controls = [
		{
			type : INPUTS.COMBO,
			options : {
				label : t('이용 약관'),
				name : 'consentterms',
				value : data.consentterms,
				list : terms.map(x => ({
                    value : x._id,
                    label : x.title
                })),
			}
		},
		{
			type : INPUTS.COMBO,
			options : {
				label : t('개인정보 처리방침'),
				name : 'consentprivacy',
				value : data.consentprivacy,
				list : privacy.map(x => ({
                    value : x._id,
                    label : x.title
                })),
			}
		},
    ]

    const controlsad = [
		{
			type : INPUTS.COMBOMULTI,
			options : {
				label : t('동의 항목'),
				name : 'consentmore',
				value : data.consentmore,
				list : setconsent.map(x => ({
                    value : x._id,
                    label : x.title
                })),
			}
		},
		{
			type : SPAN.VERTICAL,
			options : {
				size : 20,
			}
		},
		{
			type : BUTTONS.BUTTONS, 
			options : { },
			buttons : [{
				type : BUTTONS.NORMALBUTTON, 
				options : { 
					label : t('적용'), 
					color : COLORS.PRIMARY,
                    action : () => update()
				}
			},{
				type : BUTTONS.NORMALBUTTON, 
				options : { 
					label : t('취소'), 
					color : COLORS.LIGHT,
                    action : () => setEdit(!edit)
				}
            }]
		}
    ]

    const controlsSignupForm = [
        {
			type : INPUTS.COMBO,
			options : {
				label : t('추가 입력 폼'),
				name : 'signupregisterform',
				value : data.signupregisterform,
				list : extraForms.map(x => ({
                    value : x._id,
                    label : x.title
                })),
			}
		},
    ];

    return <div className='p-4'>
        <div className="d-flex justify-content-between mb-2">
            <h5>{t('사용자 동의 항목')}</h5>
        </div>
        <MLForm controls={controls} readonly={!edit} data={data} nocard={true} onChanged={(e) => { setData({...data, ...e}) }} />
        <div className="d-flex justify-content-between mb-2">
            <h5>{t('가입시 추가 입력')}</h5>
        </div>
        <MLForm controls={controlsSignupForm} readonly={!edit} data={data} nocard={true} onChanged={(e) => { setData({...data, ...e}) }} />
        <div className="d-flex justify-content-between my-2 mt-4">
            <h5>{t('가입시 추가 동의')}</h5>
        </div>
        <MLForm controls={controlsad} readonly={!edit} data={data} nocard={true} onChanged={(e) => { setData({...data, ...e}) }} />
        <div className="text-end">
            {!edit && <MLButton options={{
                code: "ptn-uc",
                label : t('편집'), 
                color : COLORS.PRIMARY,
                action : () => setEdit(!edit)
            }} />}
        </div>
    </div>
}

export default ServiceConfigConsent;
