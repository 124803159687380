import { useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import { fetchData, countData } from '../libs/db';
import { ProgramConstant } from '../redux/reducers';
import { InfoAction } from '../redux/actions';
import RMWidgetBarChart2 from '../components/widgets/RMWidgetBarChart2.';
import RMBigChart from '../components/common/RMBigChart';
import RMWidgetPieChart2 from '../components/widgets/RMWidgetPieChart2';
import RMStatChart from '../components/common/RMStatChart';
import { useTranslation } from 'react-i18next';
import { MLComboSingle } from '../components/common/MLInput';

const StatPages = ({users, user, page}) => {
	const dispatch = useDispatch();
    const [t] = useTranslation(['translation']);
    const [loading, setLoading] = useState(false);
    const [section, setSection] = useState("");
    const [chartData, setChartData] = useState({ list : [], xOptions : { xperiod : "days", dformat : "YYYY-MM-DD" } });

    const sections = [
        { value : 'usr', label : 'User account' },
        { value : 'case', label : 'Case' },
        { value : 'rvs', label : 'Ray Virtual Smile' },
        { value : 'rayfusion', label : 'RAYFusion' },
        { value : 'trioclear', label : 'Trio Clear' },
        //{ value : 'mockup', label : 'Mock-up exporter' }
    ]

	useEffect(() => {
		dispatch({ type : ProgramConstant.SET_PAGE, page : { 
			code : "stats", 
			view : "summary", 
			title : "Statistics", 
			small : "", 
			options : [
			]
		}})
	}, []);

    return <div>
        <div className='mt-4 mx-3 d-flex justify-content-end'>
            <div style={{ width : 300 }}>
                <MLComboSingle options={{
                    value: section,
                    list : sections,
                }} handleChanged={(e) =>{setSection(e.target.value); } } css=" me-2" />
            </div>
        </div>
        {section === "usr" && <>
            <RMStatChart title={t('국가별 가입자 현황')} query={{ type : "scan", region : "ap-northeast-2", params : {
                TableName: "rayteams-user",
                FilterExpression: "#sk=:sk",
                ExpressionAttributeNames: { "#sk": "sk" },
                ExpressionAttributeValues: { ":sk": "info" },
                ProjectionExpression : "#sk, ownerGroupId"
            }}} datekey={'created'} groups={'countryCode'} />
            <RMStatChart title={t('기관 생성 현황')} query={{ type : "scan", region : "ap-northeast-2", params : {
                TableName: "rayteams-group",
                FilterExpression: "#sk=:sk",
                ExpressionAttributeNames: { "#sk": "sk", "#grouptype": "grouptype" },
                ExpressionAttributeValues: { ":sk": "info" },
                ProjectionExpression : "#sk, #grouptype"
            }}} datekey={'created'} groups={'grouptype'} />
        </>}
        {section === "case" && <>
            <RMStatChart title={t('국가별 생성 케이스 현황')} query={{ type : "scan", region : "ap-northeast-2", params : {
                TableName: "rayteams-project",
                FilterExpression: "#sk=:sk",
                ExpressionAttributeNames: { "#sk": "sk" },
                ExpressionAttributeValues: { ":sk": "project" },
                ProjectionExpression : "#sk, ownerGroupId"
            }}} datekey={'created'} groups={'countryCode'} />
            <RMStatChart title={t('소스별 케이스 현황')} query={{ type : "scan", region : "ap-northeast-2", params : {
                TableName: "rayteams-project",
                FilterExpression: "#sk=:sk",
                ExpressionAttributeNames: { "#sk": "sk", "#projecttype": "projecttype" },
                ExpressionAttributeValues: { ":sk": "project" },
                ProjectionExpression : "#sk, #projecttype"
            }}} datekey={'created'} groups={'projecttype'} />
            <RMStatChart title={t('일별 업로드 파일 개수')} query={{ type : "scan", region : "ap-northeast-2", params : {
                TableName: "rayteams-project",
                FilterExpression: "#sk=:sk",
                ExpressionAttributeNames: { "#sk": "sk", "#count": "count"},
                ExpressionAttributeValues: { ":sk": 'project' },
                ProjectionExpression: "sk, #count"
            }}} datekey={'created'} cal={'count'} />
            <RMStatChart title={t('일별 업로드 용량(GB)')} query={{ type : "scan", region : "ap-northeast-2", params : {
                TableName: "rayteams-project",
                FilterExpression: "#sk=:sk",
                ExpressionAttributeNames: { "#sk": "sk", "#size": "size"},
                ExpressionAttributeValues: { ":sk": 'project' },
                ProjectionExpression: "sk, #size"
            }}} datekey={'created'} cal={'size'} labeltype={'size'} />
        </>}
        {section === "rvs" && <>
            <RMStatChart title={t('Ray Virtual Smile 생성')} query={{ type : "scan", region : "ap-northeast-2", params : {
                TableName: "rayteams-project",
                FilterExpression: "#sk=:sk and #status=:status",
                ExpressionAttributeNames: { "#sk": "sk", "#status": "status" },
                ExpressionAttributeValues: { ":sk": "qr:u", ":status" : "CONFIRMED" },
                ProjectionExpression : "#sk, ownerGroupId"
            }}} datekey={'updated'} groups={'countryCode'} />
            <RMStatChart title={t('Ray Virtual Smile 상태')} query={{ type : "scan", region : "ap-northeast-2", params : {
                TableName: "rayteams-project",
                FilterExpression: "#sk=:sk",
                ExpressionAttributeNames: { "#sk": "sk", "#status": "status" },
                ExpressionAttributeValues: { ":sk": "qr:u"},
                ProjectionExpression : "#sk, #status"
            }}} datekey={'updated'} groups={'status'} />
        </>}
        {section === "rayfusion" && <>
            <RMStatChart title={t('RAYFusion 사용')} query={{ type : "scan", region : "ap-northeast-2", params : {
                TableName: "rayteams-manager-actionlog",
                FilterExpression: "#_id=:_id and begins_with(#sk, :sk) ",
                ExpressionAttributeNames: { "#_id": "_id", "#sk": "sk", "#called": "called"},
                ExpressionAttributeValues: { ":_id": "App-RAYFusion", ":sk": 'D:' },
                ProjectionExpression: "sk, #called"
            }}} datekey={'lastcalled'} cal={'called'} />
        </>}
        {section === "trioclear" && <>
            <RMStatChart title={t('TrioClear Case 생성 추이')} query={{ type : "scan", region : "ap-northeast-2", params : {
                TableName: "rayteams-project",
                FilterExpression: "#svc=:svc and #sk=:sk",
                ExpressionAttributeNames: { "#svc": "svc", "#sk": "sk" },
                ExpressionAttributeValues: { ":svc": "trioClearConnector", ":sk": "project" },
                ProjectionExpression : "#sk, ownerGroupId"
            }}} datekey={'created'} groups={'countryCode'} />
        </>}
        {section === "mockup" && <></>}
    </div>
}

const mapState = (state) => {
    const users = state.AuthReducer.users;
    const user = state.AuthReducer.user;
	const page = state.ProgramReducer.page;

	return { users, user, page };
};

export default connect(mapState, null)(StatPages);
