const initialState = { 
	application : {},
	applications : [],
	applicationVersions : [],
	applicationConfigs : [],
};

export const ApplicationConstant = {
	GET_APPLICATIONS : "GET_APPLICATIONS",
	GET_APPLICATION : "GET_APPLICATION",
	GET_APPLICATION_CONFIGS : "GET_APPLICATION_CONFIGS",
    GET_APPLICATION_VERSIONS : "GET_APPLICATION_VERSIONS"
};

export const ApplicationReducer = (state = initialState, action) => {
	switch (action.type) {
		case ApplicationConstant.GET_APPLICATIONS:
			return {...state, applications : action.items };
		case ApplicationConstant.GET_APPLICATION:
			return {...state, application : action.item };
		case ApplicationConstant.GET_APPLICATION_VERSIONS:
			return {...state, applicationVersions : action.items };
		case ApplicationConstant.GET_APPLICATION_CONFIGS:
			return {...state, applicationConfigs : action.items };
		default:
			return state;
	}
};
