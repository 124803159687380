import { AppConfig } from "../../config";

var token = null;
var userdata = null;
try {
	token = localStorage.getItem('token');
	userdata = localStorage.getItem('userdata');
} catch (error) {
	//
}
if(userdata)
	userdata = JSON.parse(userdata);

const env = (location.href.indexOf("localhost") > -1 || location.href.indexOf(".raydevelop.com") > -1) ? "development" : "production";
//const env = "production";
const initialState = token ? 
	{ 
		licenses : [],
		perms : [],
		allgroups : null,
		regions : JSON.parse(localStorage.getItem('regionlist')),
		group : {},
		groups : [],
		projects : [],
		rayteams : [],
		rayteam : [],
		devices : [],
		device : [],
		user : userdata || {},
		users : [],
		userToken : token, 
		loginresult : true,
		region : localStorage.getItem('region'),
		admintype : localStorage.getItem('type'),
		pwchange : localStorage.getItem('pwchange'),
		usersub : localStorage.getItem('usersub'),
		loginuser : userdata || {}
	} : { 
    perms : [],
	allgroups : null,
	licenses : [],
	rayteams : [],
	rayteam : [],
	devices : [],
	device : [],
	group : {},
	groups : [],
	projects : [],
	users : [],
	regions : [],
	user : { _id : "",  userId : "tester" },
	loginresult : false,
	region : "ap-northeast-2",
	admintype : "user",
	pwchange : "",
	usersub : "",
	loginuser : {},
};

export const AuthConstant = {
	GET_ALL_GROUPS : "GET_ALL_GROUPS",
	GET_ALL_GROUPS_TOAL : "GET_ALL_GROUPS_TOAL",
	GET_ALL_RAYTEAMS : "GET_ALL_RAYTEAMS",
	GET_ALL_DEVICES : "GET_ALL_DEVICES",
	GET_GROUP : "GET_GROUP",
	GET_RAYTEAMS : "GET_RAYTEAMS",
	ADD_GROUP_LIC : "ADD_GROUP_LIC",
	UPDATE_GROUP_LIC : "UPDATE_GROUP_LIC",
	DEL_GROUP_LIC : "DEL_GROUP_LIC",
	GET_ALL_LICENSE : "GET_ALL_LICENSE",
	GET_GROUP_USERS : "GET_GROUP_USERS",
	GET_GROUP_PROJECTS : "GET_GROUP_PROJECTS",
	GET_ADMIN_USERS : "GET_ADMIN_USERS",
	ADD_ADMIN_USERS : "ADD_ADMIN_USERS",
	UPDATE_REGION : "UPDATE_REGION",
	UPDATE_USERTYPE: "UPDATE_USERTYPE",
	LOGIN_SUCCESS : "AUTH_LOGIN_SUCCESS",
	LOGIN_SUCCESS_REGION : "LOGIN_SUCCESS_REGION",
	LOGIN_FAIL : "AUTH_LOGIN_FAIL",
	UPDATED : "AUTH_UPDATED",
	LOGOUT : "AUTH_LOGOUT",
	GET_REGION_USERS : "GET_REGION_USERS",
	GET_REGION_USER : "GET_REGION_USER",
	GET_LOGINUSER_ROLE : "GET_LOGINUSER_ROLE",
	UPDATE_LOGINUSER_INFO : "UPDATE_LOGINUSER_INFO",
};

export const getHeader = (type) =>{
	return {
		headers : {
			token : localStorage.getItem("token")
		}
	};
}

export const getAdminCreateHeader = () => {
	return {
		headers : {
			token : localStorage.getItem("token")
		}
	};
}

export const getAPI = (type) =>{
	if(process.env.REACT_APP_COMPANY === 'graphy'){
        return "https://api-manager-" + type + '.' + AppConfig().domain;
    }else{
		if(env === "production"){
			return "https://api-manager-" + type + ".rayteams.com";
		}else{
			return "https://api-manager-" + type + ".raydevelop.com";
		}
    }
}

export const getApiUrl = (type, region = null) => {
	if(type == 'license') {
		if(env === "production"){
			return "https://api-manager-" + type + ".rayteams.com";
		}else{
			return "https://api-manager-" + type + ".raydevelop.com";
		}
	}
	
	if(process.env.REACT_APP_COMPANY === 'graphy'){
        return "https://api-manager-" + type + '.' + AppConfig().domain;
    }else{
		if(env === "production"){
			return "https://api-manager-" + type + ".rayteams.com";
		}else{
			return "https://api-manager-" + type + ".raydevelop.com";
		}
    }
}

export const AuthReducer = (state = initialState, action) => {
	switch (action.type) {
		case AuthConstant.LOGIN_SUCCESS:
			return {...state, logged : true, loginresult: true, region : "ap-northeast-2", user : action.user, loginuser : action.user, regions: action.regions, roles : action.items };
		case AuthConstant.LOGIN_SUCCESS_REGION:
			return {...state, regions : [...state.regions, action.region]};
		case AuthConstant.UPDATE_USERTYPE:
			return {...state, admintype: action.admintype, pwchange: action.pwchange, usersub: action.usersub, user: action.user, regionlist : action.regions };
		case AuthConstant.LOGIN_FAIL:
			return { 
				loginresult: true, 
				logged : false 
			};
		case AuthConstant.GET_ALL_GROUPS_TOAL:
			return { ...state, allgroups: action.items };
		case AuthConstant.GET_GROUP_PROJECTS:
			return { ...state, projects: action.items };
		case AuthConstant.GET_GROUP_USERS:
			return { ...state, users: action.items };
		case AuthConstant.GET_REGION_USERS:
			return { ...state, regionusers: action.items };
		case AuthConstant.GET_REGION_USER:
			return { ...state, regionuser: action.item };
		case AuthConstant.GET_ALL_RAYTEAMS:
			return { ...state, rayteams: action.items };
		case AuthConstant.GET_RAYTEAMS:
			return { ...state, rayteam: action.item };
		case AuthConstant.GET_ALL_DEVICES:
			return { ...state, devices: action.items};	
		case AuthConstant.GET_ADMIN_USERS:
			return { ...state, adminUsers: action.items };
		case AuthConstant.ADD_ADMIN_USERS:
			return { ...state, adminUsers: [...state.adminUsers, action.item] };
		case AuthConstant.DEL_GROUP_LIC:
			return { ...state, group: {...state.group, extra : state.group.extra.filter(x => x.sk !== action.item)}};
		case AuthConstant.UPDATE_GROUP_LIC:
			return { ...state, group: {...state.group, extra : state.group.extra.map(x => x.sk === action.item.sk ? action.item : x)}};
		case AuthConstant.ADD_GROUP_LIC:
			return { ...state, group: {...state.group, extra : [...state.group.extra, action.item]}};
		case AuthConstant.GET_GROUP:
			return { ...state, group: action.item };
		case AuthConstant.GET_ALL_GROUPS:
			return { ...state, groups: action.items };
		case AuthConstant.GET_ALL_LICENSE:
			return { ...state, licenses: action.items };
		case AuthConstant.UPDATE_REGION:
			return { ...state, region: action.region };
		case AuthConstant.UPDATED:
			return { ...state, user: action.user };
		case AuthConstant.GET_LOGINUSER_ROLE:
			return { ...state, loginuser: {...state.loginuser, 
                menurole : action.items.menu || [],
                perms : action.items.perms || [],
				countries : action.items.countries || [],
				managergroupid : action.items.managergroupid || ""
            } };
		case AuthConstant.UPDATE_LOGINUSER_INFO:
			return { ...state, loginuser: {
				...state.loginuser,
				name: action.newname
			} };
		case AuthConstant.LOGOUT:
            localStorage.clear();
            console.log('logout');
			return { ...initialState, userToken : "", logged: false, loginresult: false };
		default:
			return state;
	}
};
