import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { MLText } from '../../components/common/MLInput';
import { MLPanelLoading } from '../../components/common/MLPanel';
import Select from 'react-select';
import MLTable from '../../components/common/MLTable';
import { AuthConstant, getAPI, getHeader } from '../../redux/reducers/AuthReducer';
import { fetchData } from '../../libs/db';
import WidgetBarChartAppUsage from '../../components/widgets/dashboard/WidgetBarChartAppUsage';
import RMWidgetBarChart from '../../components/widgets/RMWidgetBarChart';
import RMWidgetLineChart from '../../components/widgets/RMWidgetLineChart';
import SimpleDayChart from '../../components/widgets/SimpleDayChart';

import { COUNTRIES } from '../../constants/Country';
import { MLButton, MLRefreshButton } from '../../components/common/MLButton';
import { history } from '../../history';
import { COLORS } from '../../constants/FromInputs';
import { RMIconList, RMIconChart } from '../../components/icon/MLIcon';
import { MLPanelNoData } from '../../components/common/MLPanel';

import { ProgramConstant, PaymentConstant } from '../../redux/reducers';

const RayteamsChart = ({ items }) => {
	const dispatch = useDispatch();

    const [groups, setGroups] = useState([]);
    const remainDay = 30;
    const remain = new Date().getTime() + (remainDay * 24 * 3600 * 1000);


	useEffect(() => {
        getGroups();
	}, [items]);

    const getGroups = () => {
        let list = [];
        items.map(x => {
            const ex = list.find(l => l.DeviceName === x.DeviceName);
            if(ex){
                list = list.map(l => l.DeviceName === ex.DeviceName ? {...l, count : l.count + 1} : l); 
            }else{
                list.push({ DeviceName : x.DeviceName, count : 1});
            }
        });

        setGroups(list);
    }

    const headers = [
		{ value : "DeviceName", label : "Device Name", css : "text-start", style : { width : 250 } },
		{ value : "count", label : "Count", css : "text-end" },
    ]

  	return (<>
        {items && <div className="d-flex flex-column flex-column-fluid">
            <div className="post p-7">
                <div className='row mb-5'>
                    <div className='col'>
                        <SimpleDayChart data={items} title={"설치 현황"} period={"days"} target={"registered"} max={30} />
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <RMWidgetBarChart data={items} type={"Country"} title={"국가별 설치수(Top 10)"} target={"countryCode"} max={10} />
                    </div>
                    <div className='col'>
                        <div className="card">
                            <div className="card-header">
                                <div className='card-title'>그룹별 사용자수(Top 5)</div>
                            </div>
                            <MLTable headers={headers} items={groups.sort((a, b) => a.count > b.count ? -1 : 1).slice(0,5)} noheader={true} />
                        </div>
                    </div>
                </div>
            </div>
        </div>}
    </>
  	);
}

const mapState = (state) => {
	const region = state.AuthReducer.region;
	return { region };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, null)(RayteamsChart);
