import axios from 'axios';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { MLText, MLComboSingle, MLNumber, MLToggle } from '../../components/common/MLInput';
import { MLPanelLoading } from '../../components/common/MLPanel';
import Select from 'react-select';
import MLTable from '../../components/common/MLTable';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { fetchData } from '../../libs/db';
import { COUNTRIES } from '../../constants/Country';
import { MLButton, MLRefreshButton, MLChartButton } from '../../components/common/MLButton';
import { history } from '../../history';
import { COLORS } from '../../constants/FromInputs';
import { RMIconList, RMIconChart } from '../../components/icon/MLIcon';
import { MLPanelNoData } from '../../components/common/MLPanel';
import { ProgramConstant, PaymentConstant } from '../../redux/reducers';
import SubStatCard from '../../components/subscriptions/SubStatCard';
import SubChart from '../../components/subscriptions/SubChart';
import { JWT } from '../../config';
import { SUBSTATUS, PGTYPE, REFUND_STATUS } from '../../constants/RMConstants';
import { useTranslation } from 'react-i18next';
import { RTAudit } from '../../libs/audit';
import { getEmailTemplate, getProductEmailInfo, sendEmail } from '../../libs/sendMail';

const env = (location.href.indexOf("localhost") > -1 || location.href.indexOf(".raydevelop.com") > -1) ? "development" : "production";

const SubscriptionsPage = ({ region, regionusers, subscriptions, pagestat, page }) => {
    const dispatch = useDispatch();
    const [t] = useTranslation(['translation']);
    const [search, setSearch] = useState(pagestat?.search || "");
    const [filter, setFilter] = useState(pagestat?.filter || [
        { code: "active", value: "", list: [] },
        { code: "title", value: "", list: [] },
        { code: "ccode", value: "", list: [] },
    ]);
    const [toggleFilter, setToggleFilter] = useState(true);
    const [data, setData] = useState([]);
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [more, setMore] = useState(true);
    const remainDay = 30;
    const remain = new Date().getTime() + (remainDay * 24 * 3600 * 1000);
    const [tab, setTabs] = useState("subs");
    const tabs = [
        { value: "subs", label: t("All") },
        { value: "refunds", label: t("Refunds") },
    ];
    const rfStatus = [
        { value: "done", label: t("환불 완료") },
        { value: "fail", label: t("환불 불가") }
    ]
    const [refundData, setRefundData] = useState({ show: false });
    const [lError, setlError] = useState(false);

    useEffect(() => {
        dispatch({
            type: ProgramConstant.SET_PAGE, page: {
                code: "subscriptions",
                view: "summary",
                title: "Subscriptions",
                small: t("구독 관련 정보"),
                options: [
                    { view: "summary" },
                    { view: "list" }
                ]
            }
        })
    }, []);

    useEffect(() => {
        if (page?.view)
            setMore(page.view === "list");
    }, [page]);

    useEffect(() => {
        if (!subscriptions)
            getData();
    }, []);

    useEffect(() => {
        if (subscriptions)
            genFilter();
    }, [subscriptions])

    useEffect(() => {
        if (subscriptions) {
            genData();
            dispatch({
                type: ProgramConstant.PAGESTAT, item: {
                    subscriptionlist: {
                        search, filter,
                        selCol: pagestat.selCol,
                        order: pagestat.order,
                        desc: pagestat.desc
                    }
                }
            });
        }
    }, [search, filter, pagestat.order, pagestat.desc, toggleFilter])

    const btnClick = (item) => {
        console.log(item);
    }

    const getData = async () => {
        setLoading(true);
        const ScanFilter = {
            sk: {
                ComparisonOperator: "BEGINS_WITH",
                AttributeValueList: ["product:"],
            },
        }
        try {
            const ret = await fetchData("rayteams-user", ScanFilter);
            dispatch({
                type: PaymentConstant.SUB_GET_ALL,
                items: ret.Items.sort((a, b) => a.expired > b.expired ? -1 : 1).
                    map(x => {
                        if (x.ccode) {
                            return {
                                ...x,
                                active: (x.expired > (new Date().getTime())),
                                pg: x.lastrenewdata?.pg && PGTYPE.find(f => f.value == x.lastrenewdata?.pg)?.label || "RAY",
                                prdid: x.sk.split(":")[1],
                                refund: true,
                                statustype: SUBSTATUS.find(f => f.value == x?.statustype)?.label || "RAY",
                                rfstatusLabel: x?.rfstatus && REFUND_STATUS.find(r => r.value == x?.rfstatus)?.label || ""
                            }
                        } else {
                            return {
                                ...x,
                                active: (x.expired > (new Date().getTime())),
                                prdid: x.sk.split(":")[1],
                                ccode: x.lastrenewdata?.product?.sk?.split(":")[2] || "",
                                punit: "USD",
                                pg: x.lastrenewdata?.pg && PGTYPE.find(f => f.value == x.lastrenewdata?.pg)?.label || "RAY",
                                realprice: x.lastrenewdata?.product?.realprice,
                                count: x.lastrenewdata?.product?.count,
                                refund: true,
                                statustype: SUBSTATUS.find(f => f.value == x?.statustype)?.label || "RAY",
                                rfstatusLabel: x?.rfstatus && REFUND_STATUS.find(r => r.value == x?.rfstatus)?.label || ""
                            }
                        }
                    })
            });
        } catch (e) {
            console.log(e);
        }
        setLoading(false);
    }

    const genFilter = () => {
        setFilter(filter.map(x => {
            const list = [...new Set(subscriptions.map(m => m[x.code]))];
            return {
                ...x, list: [{ value: "", label: "ALL" }, ...list.map(n => ({
                    value: n,
                    label: fLabel(x.code, n)
                }))]
            }
        }));
    }

    const fLabel = (code, value) => {
        if (value === true || value === false) {
            return value === true ? "Active" : "Expired";
        } else if (code === "ccode") {
            return COUNTRIES.find(x => x.countryCode === value)?.name;
        }
        return value;
    }

    const genData = () => {
        setItems(subscriptions
            .filter(x => search ? (x.title.indexOf(search) > -1) : true)
            .filter(x => filterCheck(x))
            .filter(x => toggleFilter ? x.pg != "RAY" : true)
            .sort((a, b) => pagestat.desc === "desc" ? (a[pagestat.order] > b[pagestat.order] ? -1 : 1) : (b[pagestat.order] > a[pagestat.order] ? -1 : 1))
        );
    }

    const filterCheck = (item) => {
        var tf = true;
        filter.map(x => {
            if (tf)
                tf = (item[x.code] === x.value || x.value === "");
        });
        return tf;
    }

    const handleFilter = (e, item) => {
        setFilter(filter.map(x => x.code === item.code ? { ...x, value: e.value } : x));
    }

    const headerClick = (e) => {
        dispatch({
            type: ProgramConstant.PAGESTAT, item: {
                subscriptionlist: {
                    search, filter,
                    selCol: e.value,
                    order: pagestat.order,
                    desc: (pagestat.desc === "desc" ? "asc" : "desc")
                }
            }
        });
    }

    const headers = [
        { link: "prdid", linkpath: "/RTProductInfo", value: "title", label:  t("Product"), css: "text-start", style: { width: 240 } },
        { value: "_id", label:  t("User"), type: "USER", css: "text-start" },
        { value: "ccode", label:  t("Country"), type: "COUNTRY", style: { width: 160 } },
        { value: "statustype", label:  t("Status") },
        { value: "started", label:  t("Started"), type: "DATESHORT", style: { width: 130 } },
        { value: "lastrenew", label:  t("Last-renew"), type: "DATESHORT", style: { width: 140 } },
        { value: "expired", label:  t("Expired"), type: "DATESHORT", style: { width: 130 } },
        { value: "expired", label:  t("Remained"), type: "TIMESPAN", style: { width: 125 } },
    ]

    const refundHeaders = [
        { link: "prdid", linkpath: "/RTProductInfo", value: "title", label:  t("Product"), css: "text-start" },
        { value: "_id", label:  t("User"), type: "USER", css: "text-start" },
        { value: "ccode", label:  t("Country"), type: "COUNTRY", style: { width: 140 } },
        { value: "realprice", label:  t("Price"), css: "text-end", type: "CUR", curtype: "punit", isZero: "Free", style: { width: 80 } },
        { value: "started", label:  t("Started"), type: "DATESHORT", style: { width: 130 } },
        { value: "lastrenew", label:  t("Last-renew"), type: "DATESHORT", style: { width: 135 } },
        { value: "rfstatusLabel", label: t("Status")},
        { value: "rfcomment", label:  t("Comment"), css: "text-end" },
        { value: "rfamount", label:  t("Refund Amount"), type: "CUR", curtype: "punit", isZero: "0", style: { width: 65 } },
        { value: "rfreqtime", label:  t("Requested"), type: "DATESHORT", style: { width: 130 } },
        { value: "rfdonetime", label:  t("Done"), type: "DATESHORT", style: { width: 135 } },
        {
            value: "", label: t("Refund"), buttonLabel: t("Refund"), type: "BUTTON2", style: { width: 100 }, btncss: "danger",
            btnClick: async (item) => {
                setRefundData({ items: item, show: true });
                console.log("selected Item : =============", item)
                setData({ rfamount: item.realprice, rfcomment: "" });
            }
        },
    ]

    const refundClick = async () => {
        const params = {
            _id: refundData.items._id,
            sk: refundData.items.sk,
            data: {
                status: data.rfstatus,
                rfcomment: data.rfcomment || "",
                rfamount: data.rfstatus == "done" ? parseInt(data.rfamount || 0) : 0,
            }
        }
        try {
            setLoading(true);
            const ret = await axios.post("https://" +
                (env === "production" ? "api-manager-license.rayteams.com" : "api-manager-license.raydevelop.com") +
                "/subscription/refunddone", params, JWT());
            const doneResult = ret?.data?.data;
            const prdId = refundData?.items.prdid || refundData?.items?.lastrenewdata?.product?._id;
            const emailCid = await getProductEmailInfo(prdId);
            const emailContents = {
                user: {
                    _id: refundData.items._id,
                    paylog: refundData?.items?.lastrenewkey,
                },
                product: {
                    title: refundData?.items?.title,
                    rfamount: doneResult?.rfamount,
                    rfcomment: doneResult?.rfcomment,
                    rfdonetime: doneResult?.rfdonetime,
                    rfreqtime: doneResult?.rfreqtime
                }
            };

            const sendRet = await sendEmail(emailCid?.subRefDone, doneResult?.userLang || "en", emailContents);
            dispatch({
                type: PaymentConstant.UPDATE_SUB,
                item: {
                    ...refundData.items, ...params.data,
                    rfstatus: doneResult?.rfstatus,
                    rfdonetime: doneResult?.rfdonetime,
                    rfamount: doneResult?.rfamount,
                    rfcomment: doneResult?.rfcomment
                }
            });
            setData({ rfdonetime: doneResult?.rfdonetime });
            setLoading(false)
        } catch (e) {
            setlError(true);
        }
        console.log("refundClick after : ==============", params);
        RTAudit({ lvl: 7, msg: 'Refund'}, JSON.stringify({ subject: data }));
    }

    return <>
        <div className='p-4'>
            <div className='mb-4'>
                <SubStatCard items={items} />
            </div>
            {more && <SubChart items={items.filter(f => f.status == "sub")} />}
            {!more && <div className="card">
                <div className="card-header pb-0">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="card-label fw-bold text-gray-800 fs-5">{items.length}</div>
                        <div className="d-flex align-items-center">
                            <div className='d-flex me-2'>
                                {t("실사용자")} <MLToggle options={{
                                    name: 'pg',
                                    value: toggleFilter,
                                    readonly: false,
                                }} handleChanged={(e) => setToggleFilter(e.target.value)} css={"ms-3"} />
                            </div>
                            {filter.map((x, idx) => <Select
                                key={"f-" + x.code + idx}
                                onChange={(e) => handleFilter(e, x)}
                                value={x.list.filter(y => y.value === x.value)}
                                options={x.list}
                                data-dropdown-css-className="w-200px"
                                className="form-select-transparent me-2 w-150px" />)}
                            <MLText options={{
                                placeholder: "검색어 입력",
                                size: "sm",
                                value: search,
                            }} handleChanged={(e) => setSearch(e.target.value)} css="mb-0" />
                        </div>
                    </div>
                    <ul className="nav nav-tabs nav-stretch border-top mt-4 pt-2" style={{ borderRadius: "0" }}>
                        {tabs.map((x, idx) => <li className="nav-item" role='presentation' key={idx} onClick={() => setTabs(x.value)}>
                            <Link to="#" className={"nav-link " + (tab === x.value ? 'active' : '')}>
                                <span className="nav-text ">{t(x.label)}
                                    {x.value === "subs" && <span className="badge bg-secondary ms-2">{items && items.length}</span>}
                                    {x.value === "refunds" && <span className="badge bg-secondary ms-2">{items && items.filter(f => f.rfstatus).length}</span>}
                                </span>
                            </Link>
                        </li>)}
                    </ul>
                </div>
                {items.length > 0 && tab == "subs" && <> {!loading && <MLTable
                    headers={headers}
                    items={items}
                    noheader={true}
                    headerClick={headerClick}
                    order={pagestat?.order || "started"}
                    desc={pagestat?.desc || "desc"}
                    selectedColumn={pagestat?.selCol} />}
                </>}
                {items.length > 0 && tab === "refunds" && <> {!loading && <MLTable headers={refundHeaders} items={items.filter(f => f.rfstatus)} noheader={true} />} </>}
                {items.length === 0 && <MLPanelNoData />}
                {loading && <MLPanelLoading />}
            </div>}
        </div>
        <Modal show={refundData.show} size={"lg"} style={{ backgroundColor: "#42476c !important" }}>
            <Modal.Header className='modal-header header-bg'>
                <h2>{t("Subscript Refund")}</h2>
            </Modal.Header>
            <Modal.Body className='flex-fill pt-0 formcard pt-6'>
                <div>
                    <h5>{refundData?.items?.title}</h5>
                    <span>
                        {t("해당 구독 상품의 환불 처리를 승인 합니다.")}
                    </span>
                    <div className="card-body pt-7">
                        {loading && <MLPanelLoading contents={t("구독 상품의 환불을 승인 하고 있습니다.")}/>}
                        {!loading && lError && <>
                            <div className="d-flex align-items-center w-100 text-center">
                                <div className='mx-auto'>
                                    <div className='mb-2'>
                                        <h2>Error</h2>
                                        <div className='text-comment'>{t("구독 상품 환불 승인 중 문제가 발생했습니다. 관리자에게 문의 해주시기 바랍니다.")}</div>
                                    </div>
                                </div>
                            </div>
                        </>
                        }
                        {!loading && !lError && <>
                            <div>
                                <div className='fs-6 mt-3'>{t("환불 상태")}</div>
                                <MLComboSingle options={{
                                    value: data?.rfstatus || "",
                                    name: "status",
                                    list: rfStatus
                                }} handleChanged={(e) => setData({ ...data, rfstatus: e.target.value })} />
                            </div>
                            <div className='fs-6 mt-3 mb-3'>{t("환불 금액")} - {refundData?.items && refundData.items?.punit}</div>
                            <MLNumber options={{
                                value: data.rfstatus == "done" ? data?.rfamount : 0 || 0,
                                name: "rfamount",
                                readonly: data.rfstatus == "fail",
                                nolabel: true,
                            }} handleChanged={(e) => setData({ ...data, rfamount: e.target.value })} />
                            {data && data.rfstatus === "fail" && <>
                                <div className='fs-6 mt-3 required'>{t("환불 사유")}</div>
                                <MLText options={{
                                    placeholder: t("사유 입력"),
                                    size: "sm",
                                    value: data.rfcomment || "",
                                }} handleChanged={(e) => setData({ ...data, rfcomment: e.target.value })} />
                            </>}
                        </>}
                    </div>
                </div>
                <div className='mt-4 text-end'>
                    <MLButton
                        options={{ label: t("Cancel"), size: "md", color: COLORS.DEFAULT, action: () => setRefundData({ ...refundData, show: false }) }}
                        css={'me-1'} />
                    <MLButton
                        options={{ label: t("Refund Approval"), size: "md", color: COLORS.DANGER, needValid: true, action: () => refundClick() }}
                        valid={data.rfstatus === "done" || (data.rfstatus === "fail" && data.rfcomment != "")}
                        css={'me-1'} />
                </div>
            </Modal.Body>
        </Modal>
    </>
}

const mapState = (state) => {
    const region = state.AuthReducer.region;
    const subscriptions = state.PaymentReducer.subscriptions;
    const regionusers = state.AuthReducer.regionusers;
    const pagestat = state.ProgramReducer.pagestat["subscriptionlist"] || {};
    const page = state.ProgramReducer.page;

    return { region, subscriptions, regionusers, pagestat, page };
};

const mapDispatch = (dispatch) => ({

});

export default connect(mapState, null)(SubscriptionsPage);
