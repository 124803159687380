import { connect, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { InfoAction } from '../../redux/actions';
import { DASHBOARD_GRAPTH_TYPES } from '../../constants/RMConstants';
import RMWidgetBarChart2 from '../widgets/RMWidgetBarChart2.';
import { useTranslation } from 'react-i18next';
import { MLPanelLoading } from './MLPanel';

const RMStatChart = ({ title, query, datekey, cal = "", groups, allgroups, labeltype = "" }) => {
    const dispatch = useDispatch();
    const [t] = useTranslation(['translation']);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const [period, setPeriod] = useState("c");
    const [chartData, setChartData] = useState({ list : [], xOptions : { xperiod : "days", dformat : "YYYY-MM-DD" } });

    useEffect(() => {
        if(allgroups)
    		getData();
    }, [period, allgroups]);
    
    const getData = async () => {
        let min, duration, xperiod, dformat;
        setLoading(true)
		if(period === "a"){
			min =  moment().add(-7, "day").utc().valueOf();
            duration = 7;
            xperiod = "days";
            dformat = "YYYY-MM-DD";
		}else if(period === "c"){
			min =  moment().add(-30, "day").utc().valueOf();
            duration = 30;
            xperiod = "days";
            dformat = "YYYY-MM-DD";
		}else if(period === "d"){
			min =  moment().add(-90, "day").utc().valueOf();
            duration = 90;
            xperiod = "days";
            dformat = "YYYY-MM-DD";
		}else if(period === "e"){
			min =  moment().add(-365, "day").utc().valueOf();
            duration = 12;
            xperiod = "month";
            dformat = "YYYY-MM";
		}else if(period === "f"){
			min =  0;
            duration = 12;
            xperiod = "month";
            dformat = "YYYY-MM";
		}
        let ex_names = {};
        ex_names['#' + datekey] = datekey;
        let ex_values = {};
        ex_values[':n'] = min;
        let ret =  await InfoAction.callDB({...query, 
            params : {...query.params,
                FilterExpression: query.params.FilterExpression +  ' and #' + datekey + ' > :n',
                ExpressionAttributeNames: {...query.params.ExpressionAttributeNames, ...ex_names },
                ExpressionAttributeValues: {...query.params.ExpressionAttributeValues, ...ex_values },
                ProjectionExpression: query.params.ProjectionExpression + ", " + datekey
            }
        });
        if(groups && allgroups){
            let ret2 = [];
            if(groups === 'countryCode' && allgroups.length > 0){
                ret2 = ret.map(r => ({...r, 
                    countryCode : (allgroups.find(g => g._id === r.ownerGroupId)?.countryCode ) 
                }));
            }else{
                ret2 = [...ret]
            }
            setChartData({
                keyname : "dt",
                cal : cal,
                list : ret2?.map(x => ({...x, dt : x[datekey]})) || [],
                xOptions : {
                    min,
                    duration,
                    xperiod,
                    dformat
                }
            })
        }else{
            setChartData({
                keyname : "dt",
                cal : cal,
                list : ret?.map(x => ({...x, dt : x[datekey]})) || [],
                xOptions : {
                    min,
                    duration,
                    xperiod,
                    dformat
                }
            })
        }
        setLoading(false);
    }

    return <div>
        <div className="p-4 pb-0 row">
            <div className='col-12'>
                {!loading && <div className='card p-2'>
                    <div className='card-header'>
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="fw-bolder">{t(title)}</div>
                            <div>
                                <span onClick={() => setPeriod("a")} className={"badge ms-1 cursor-pointer " + (period === "a" ? " bg-primary " : " bg-light text-gray ")}>{t("최근 7일")}</span>
                                <span onClick={() => setPeriod("c")} className={"badge ms-1 cursor-pointer " + (period === "c" ? " bg-primary " : " bg-light text-gray ")}>{t("최근 한달")}</span>
                                <span onClick={() => setPeriod("d")} className={"badge ms-1 cursor-pointer " + (period === "d" ? " bg-primary " : " bg-light text-gray ")}>{t("최근 3개월")}</span>
                                <span onClick={() => setPeriod("e")} className={"badge ms-1 cursor-pointer " + (period === "e" ? " bg-primary " : " bg-light text-gray ")}>{t("최근 1년")}</span>
                            </div>
                        </div>
                    </div>
                    <div className='card-body'>
                        <RMWidgetBarChart2 data={chartData} groups={groups} labeltype={labeltype} />
                    </div>
                </div>}
                {loading && <MLPanelLoading />}
            </div>
        </div>
    </div>
}

const mapState = (state) => {
    const users = state.AuthReducer.users;
    const user = state.AuthReducer.user;
	const page = state.ProgramReducer.page;
	const allgroups = state.AuthReducer.allgroups;

	return { users, user, page, allgroups };
};

export default connect(mapState, null)(RMStatChart);
