import moment from 'moment';
import { useEffect, useState } from 'react';
import { idData, fetchData } from '../../libs/db'
import { connect, useDispatch } from 'react-redux';

const ManagerGroup = ({ _id, hide = true, managerGroups, countryCode }) => {
	const dispatch = useDispatch();
	const [ info, setInfo ] = useState({});

	const [ process, setProcess ] = useState(false);

	useEffect(() => {
		if(_id){
			getManagerGroup();
		}
	},[_id]);

	useEffect(() => {
		if(countryCode) {
			//getManagerGroup_CountryCode();
		}
	},[countryCode]);

	const getManagerGroup = async () => {
		const managergroup = managerGroups.find(x => x._id === _id);
		if(managergroup){
			setInfo(managergroup);
			return;
		}
		setProcess(true);

		try {		
            const mginfo = await idData("rayteams-manager-group", _id);
            setInfo(mginfo.Items[0]);
		} catch (err) {
			console.log(err);
		}

		setProcess(false);
	};

	const getManagerGroup_CountryCode = async () => {
		try {
			const mginfo = await fetchData("rayteams-manager-group", {sk : {
				ComparisonOperator: "EQ",
				AttributeValueList: ["info"]
			}});
			console.log("getManagerGroup_CountryCode");
			console.log(mginfo.Items.countries?.map(x=> x.countryCode));
			console.log(mginfo.Items.filter(x => x.countries) );

			mginfo.map(x => ({...x
				
			}))

		} catch (err) {
			console.log(err);
		}
	}

  	return <>
		{process && <div className="d-flex align-items-center fw-bolder fs-4">
			...
		</div>}
		{!process && <div>
			<strong>{info?.name}</strong>
		</div>}
	</>
}

const mapState = (state) => {
    const managerGroups = state.ManagerGroupReducer.managerGroups || [];
	return { managerGroups };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(ManagerGroup);
