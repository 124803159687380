import axios from 'axios';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { MLButton } from '../components/common/MLButton';
import { MLText } from '../components/common/MLInput';
import MLModal from '../components/common/MLModal';
import { MLPanelLoading } from '../components/common/MLPanel';
import MLTable from '../components/common/MLTable';
import { JWT } from '../config';
import { COLORS, SETTING } from '../constants/FromInputs';
import { history } from '../history';
import { WFAction } from '../redux/actions';
import { AuthConstant, WFConstant } from '../redux/reducers';
import MLWFSelectOrCreate from '../components/wf/MLWFSelectOrCreate';
import { RMIconChart, RMIconList } from '../components/icon/MLIcon';
import { fetchData } from '../libs/db';
import { ProgramConstant } from '../redux/reducers';
import { useTranslation } from 'react-i18next';

const WorkflowPage = ({ user, region, products, wfItems, page }) => {
	const dispatch = useDispatch();
	const [t] = useTranslation(['translation']);
	const [loading, setLoading] = useState(true);
	const [modal, setModal] = useState({
		show: false
	});
	const [modalData, setModalData] = useState({
		wfId: "",
		show: false,
		title: "",
		body: <></>,
	});
	const [search, setSearch] = useState("");
	const [items, setItems] = useState([]);

	useEffect(() => {
		dispatch({
			type: ProgramConstant.SET_PAGE, page: {
				code: "workflow",
				view: "summary",
				title: "Workflows",
				small: t("Workflows 관련 정보"),
				options: [
				]
			}
		})
	}, []);

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		setItems(await WFAction.GetAll());
		setLoading(false);
	}

	const headers = [
		{ link: "_id", linkpath: "/Workflow", value: "title", label: t("Title"), css: "text-start", style: { width: 450 } }
	]

	const updateWorkFlow = async (suborder, _data) => {
		const formData = {
			_id: product._id,
			posttype: 'updatesuborder',
			suborderid: suborder._id,
			data: {
				...suborder,
				workflowId: _data.workflowId
			}
		};
		//Update(formData);
	}

	const changeOrNewWorkflow = (suborder) => {
		history.push("/WorkflowAdd/");
	}

	return <div className='p-4'>
		<div className="card">
			<div className="card-header d-flex justify-content-between align-items-center">
				<div className="card-label fw-bold text-gray-800 fs-5">{items.length}</div>
				<div className="d-flex">
					<MLButton options={{
						code: "wf-add",
						color: COLORS.PRIMARY,
						size: "sm",
						label: t("Add Workflow"),
						action: () => changeOrNewWorkflow()
					}} css="me-2" />
					<MLText options={{
						placeholder: t("검색어 입력"),
						size: "sm",
						value: search,
					}} handleChanged={(e) => setSearch(e.target.value)} css="mb-0" />
				</div>
			</div>
			{!loading && <MLTable
				headers={headers}
				items={items}
				noheader={true} />}
			{loading && <MLPanelLoading />}
		</div>
	</div>
};

const mapState = (state) => {
	const user = state.AuthReducer.user;
	const region = state.AuthReducer.region;
	const products = state.ProductReducer.products;
	const wfItem = state.WFReducer.item;
	const wfItems = state.WFReducer.items;
	const page = state.ProgramReducer.page;

	return { region, user, products, wfItem, wfItems, page };
};

const mapDispatch = (dispatch) => ({
});

export default connect(mapState, mapDispatch)(WorkflowPage);