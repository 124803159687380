import axios from 'axios';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { MLText } from '../../components/common/MLInput';
import { MLPanelLoading } from '../../components/common/MLPanel';
import MLTable from '../../components/common/MLTable';
import { AuthConstant, getAPI, getHeader } from '../../redux/reducers/AuthReducer';
import { fetchData } from '../../libs/db';
import WidgetBarChartAppUsage from '../../components/widgets/dashboard/WidgetBarChartAppUsage';
import RMWidgetBarChart from '../../components/widgets/RMWidgetBarChart';
import RMWidgetLineChart from '../../components/widgets/RMWidgetLineChart';
import { MLButton } from '../../components/common/MLButton';
import { history } from '../../history';
import { COLORS } from '../../constants/FromInputs';
import { RMIconList, RMIconChart } from '../../components/icon/MLIcon';
import { MLPanelNoData } from '../../components/common/MLPanel';
import { ProgramConstant } from '../../redux/reducers';
import { useTranslation } from 'react-i18next';

const PartnersPage = ({ groups, region, regionusers, allpartner, page }) => {
	const dispatch = useDispatch();
	const [t] = useTranslation(['translation']);
	const [search, setSearch] = useState("");
	const [items, setItems] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		dispatch({ type : ProgramConstant.SET_PAGE, page : { 
			code : "partners", 
			view : "summary", 
			title : "B2B", 
			small : t("B2B 관련 정보"), 
			options : [
			]
		}})
	}, []);

	useEffect(() => {
		getData();
	}, []);

	useEffect(() => {
		genData();
	}, [search, allpartner])

    const headers = [
		{ link : "partnerKey", linkpath : "/Partner", value : "name", label : t("Client"), css : "text-start", style : { width : 150 } },
		{ value : "partnerKey", label : t("Partner Key"), css : "text-start", style : { width : 250 } },
		{ value : "created", label : t("Created"), css : "text-end", type: "DATE" },
    ]

	const getData = async () => {
		setLoading(true);
		const ScanFilter = {
			parentId: {
				ComparisonOperator: "EQ",
				AttributeValueList: [""],
			},
		}
		try {
			const ret = await fetchData("rayteams-partner", ScanFilter);
			dispatch({ type: ProgramConstant.GETPARTNER, items: ret.Items });
			
			//setItems(list);
		} catch (e) {
			console.log(e);
		}
		setLoading(false);
	}

	const genData = async () => {
		setItems(search ? allpartner.filter(x => x.name.indexOf(search) > -1 || x.adminname.indexOf(search) > -1 || x.adminemail.indexOf(search) > -1) : (allpartner || []));
	}

  	return <div className='p-4'>
		<div className="card">
			<div className="card-header d-flex justify-content-between align-items-center">
				<div className="card-label fw-bold text-gray-800 fs-5">{items.length}</div>
				<div className="d-flex">
					{/*<MLButton options={{
						code : "ptn-addpartner",
						label : t("Add a Partner"),
						color : COLORS.PRIMARY,
						size : "sm",
						action : () => history.push("/PartnerAdd")
					}} css="me-2" />
					<MLText options={{
						placeholder : t("검색어 입력"),
						size : "sm",
						value : search,
					}} handleChanged={(e) => setSearch(e.target.value)} css="mb-0" />*/}
				</div>
			</div>
			{!loading && <>
				{items.length > 0 && <MLTable headers={headers} items={items} noheader={true} />}
				{items.length === 0 && <MLPanelNoData />}
			</>}
			{loading && <MLPanelLoading />}
		</div>
	</div>
}

const mapState = (state) => {
	const groups =  state.AuthReducer.groups;
	const region = state.AuthReducer.region;
	const regionusers = state.AuthReducer.regionusers;
	const allpartner = state.ProgramReducer.allpartner;
	const page = state.ProgramReducer.page;

	return { groups, region, regionusers, allpartner, page };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, null)(PartnersPage);
