import { useEffect, useState } from 'react';
import moment from 'moment';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { FaHome, FaPhoneAlt, FaWindowClose, FaEnvelope } from 'react-icons/fa';
import { history } from '../../history';
import CodeConfigPage from "../code/CodeConfigPage";
import ServiceConfigBasic from "../../components/serviceconfig/ServiceConfigBasic";
import ServiceConfigEmail from "../../components/serviceconfig/ServiceConfigEmail";
import ServiceConfigLang from "../../components/serviceconfig/ServiceConfigLang";
import ServiceConfigConsent from "../../components/serviceconfig/ServiceConfigConsent";
import { ProgramConstant } from '../../redux/reducers';
import ServiceConfigEmailTemplate from '../../components/serviceconfig/ServiceConfigEmailTemplate';
import B2BDevicePage from '../b2bdevices/B2BDevicePage';

const getEnv = () => {
    if(location.href.indexOf("localhost") > -1 || location.href.indexOf("127.0.0.1") > -1){
        return "dev-local";
    }else if(location.href.indexOf("raydevelop") > -1){
        return "development";
    }
    return "production";
}

const PartnerInfoPage = ({ page, b2bconfig }) => {

	const dispatch = useDispatch();

    const { _id, _tab = 'info' } = useParams();
	const [help, setHelp] = useState(window.localStorage.getItem("helpmsg") === "N" ? false : true);
	const [partner, setPartner] = useState({});

	useEffect(() => {
		dispatch({
			type: ProgramConstant.SET_PAGE, page: {
				code: "partners",
				view: "summary",
				title: "B2B Client",
				small: "B2B Client 관련 정보",
				options: [
				]
			}
		})
	}, []);

    const tabs = [
        { code  : 'info', label : 'Basic'},
        { code  : 'email', label : 'Email'},
        { code  : 'emailtemp', label : 'Email Template'},
        { code  : 'lang', label : 'Languages'},
        //{ code  : 'links', label : 'Links'},
        { code  : 'consent', label : 'User Consents'},
        { code  : 'code', label : 'Codes'},
        { code  : 'device', label : 'Devices'},
    ]

	useEffect(() => {
		if (_id)
			init();
	}, [_id]);

    const getConfigPath = () => {
        if(getEnv() === "production"){
            return "https://ray-data.s3.ap-northeast-2.amazonaws.com/rayrnd/rayteams-service/"+ _id + "/config.json";
        }else{
            return "https://ray-data-development.s3.ap-northeast-2.amazonaws.com/rayrnd/rayteams-service/"+ _id + "/config.json";
        }
    }

	const init = async () => {
        try{
            const conf = await axios.get(getConfigPath());
            dispatch({ type : ProgramConstant.SET_B2B_SERVICE, item : conf?.data || {} });
        }catch{
        }
	}

	return <div className='p-4'>
		<div className='card'>
			<div className="card-header pb-0">
				<div className="d-flex justify-content-between align-items-center">
					<div className="w-100">
						<div className='d-flex align-items-center'>
							{b2bconfig.servicelogo && <img src={b2bconfig.servicelogo} alt="" className='mh-50px me-2' style={{ height : 48 }} />}
							{b2bconfig.rayteamstitle && <div className='h4 mb-0'>{b2bconfig.rayteamstitle}</div>}
						</div>
						<div className='d-flex justify-content-between align-items-center'>
							<div>
								{b2bconfig?.homepageurl && <span className='small me-2'>
									<i class="ti ti-home fs-6 mx-1"></i>
									<a href={b2bconfig?.homepageurl} className="text-light-primary" target="_blank">{b2bconfig?.homepageurl}</a>
								</span>}
								{b2bconfig?.tel && <span className='small me-2'>
									<FaPhoneAlt className='me-1' />
									{b2bconfig?.tel}
								</span>}
								{b2bconfig?.email && <span className='small me-2'>
									<FaEnvelope className='me-1' />
									{b2bconfig?.email}
								</span>}
							</div>
							<div>
								<small className='me-2'>Registered : {moment(partner.created).format('LL')}</small>
							</div>
						</div>
					</div>
				</div>
				<ul className="nav nav-tabs nav-stretch border-top mt-4 pt-2" style={{ borderRadius: "0" }}>
					{tabs.map((x, idx) => {
						return <li className="nav-item" key={idx} onClick={() => history.push("/Partner/" + _id + "/" + x.code)} >
							<a className={"cursor-pointer nav-link " + (_tab === x.code ? ' active ' : '')}>{x.label}</a>
						</li>
					})}
				</ul>
			</div>
            {_tab === "info" && <ServiceConfigBasic /> }
            {_tab === "email" && <ServiceConfigEmail />}
            {_tab === "emailtemp" && <ServiceConfigEmailTemplate />}
            {_tab === "lang" && <ServiceConfigLang />}
            {_tab === "links" && <></>}
            {_tab === "consent" && <ServiceConfigConsent />}
            {_tab === "code" && <CodeConfigPage />}
            {_tab === "device" && <B2BDevicePage />}
		</div>
	</div>
}

const mapState = (state) => {
	const user = state.AuthReducer.user;
	const allgroups = state.ProgramReducer.allgroups;
	const mypartner = state.ProgramReducer.mypartner;
	const allpartner = state.ProgramReducer.allpartner;
	const page = state.ProgramReducer.page;
	const b2bconfig = state.ProgramReducer.b2bconfig;
	
	return { user, allgroups, mypartner, allpartner, page, b2bconfig };
};

const mapDispatch = (dispatch) => ({

});

export default connect(mapState, null)(PartnerInfoPage);
