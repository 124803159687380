/* eslint-disable jsx-a11y/alt-text */
import * as tmImage from '@teachablemachine/image';
import { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { GrClose } from 'react-icons/gr';
import { useDispatch, useSelector } from 'react-redux';
import { AppConfig } from '../../config';
import { COLORS, INPUTSIZE } from '../../constants/FromInputs';
import { upperFistChar } from '../../libs/string';
import { ProgramConstant } from '../../redux/reducers';
import { Store } from '../../store';
import { MLIconFile } from '../icon/MLIcon';
import { MLButton } from './MLButton';
import { MLFileSize, MLPhoto } from './MLCommon';
import { MLComboSingle } from './MLInput';

export const MLFile = {
	TO: {
		MYLAB: 'MYLAB',
		RAY_CLOUD: 'RAYCLOUD'
	},
	UPTYPE: {
		MYLAB_PATIENTFILES: 'MYLAB-PATIENTFILES'
	},
	MEDIA: {
		IMPORT_TYPE: {
			INNER_BUTTON: 'inner_button',
			DROPDOWN: 'dropdown'
		}
	}
}

const getCheckNullView = (options, val) => {
	return val ?
		<div className='fs-16 form-value'>{val}</div> : 
		(val === 0 ? <div className='fs-16 form-value'>0</div> :
			<div><i className='fs-16 form-value'>N/A</i></div>) ;
}

const MLThumbnail = ({ fileInfo, placeholder, maxwh, onClick, noment, readonly }) => {
	return <>
		{(fileInfo.name || fileInfo?.path?.s3path) && <div className='text-center'>
			{fileInfo?.path?.s3path && <div onClick={onClick} className={onClick ? "raycur" : ""}>
				{fileInfo.path.mimetype.indexOf('image') > -1 && <img src={fileInfo?.path?.directLink} className="w-200 mx-auto" alt="" style={{ maxWidth : (maxwh || 200) + 'px', maxHeight : (140) + 'px', display: 'block' }}/>}
				{fileInfo.path.mimetype.indexOf('image') === -1 && <MLIconFile size={42} alt="" style={{ width : '150px' }} />}
				{!noment && <div>
					<div className='fs-7 text-gray-800'>{fileInfo.path.original}</div>
					<div className='fs-7 text-gray-600'>{MLFileSize(fileInfo.path.size)}</div>
				</div>}
			</div>}

			{!fileInfo?.path?.s3path && <div className='text-center'>
				{fileInfo.type.indexOf('image') > -1 && <img src={URL.createObjectURL(fileInfo)} className="w-200 mx-auto" alt="" style={{ maxWidth : (maxwh || 200) + 'px', maxHeight : (140) + 'px', display: 'block' }}/>}
				{fileInfo.type.indexOf('image') === -1 && <MLIconFile size={42} alt="" style={{ width : '150px' }} />}
				{!noment && <div>
					<div className='fs-7 text-gray-800'>{fileInfo.name}</div>
					<div className='fs-7 text-gray-600'>{MLFileSize(fileInfo.size)}</div>
				</div>}
			</div>}
		</div>}
		{(!readonly && !fileInfo.name && !fileInfo?.path?.s3path) && <img src={placeholder || "/images/upload.svg"} className="mx-auto" alt="" style={{ maxWidth : ((maxwh || 200) - 2) + 'px', display: 'block' }}/> }
	</>
}

const MLThumbnailPhoto = ({ url }) => {
	return <>
		<img src={url} className="w-200" alt="" style={{ maxHeight : (140) + 'px', display: 'block', marginRight: 'auto' }}/>
	</>
}

export const MLFileSingle = ({ options = {}, handleChanged, readonly = false, css = "", noment, placeholder, maxwh, fid, medias }) => {

	const [t] = useTranslation(['translation']);
	const dispatch = useDispatch();
	const [focus, setFocus] = useState(false);
	const [fileInfo, setFileInfo] = useState({});
	const [useCustomAttach , setUseCustomAttach] = useState(false);
	const [mediaModal, setMediaModal] = useState({ show: false, title: 'Attach Media' });
	const [selectedMedia, setSelectedMedia] = useState(null);
	const site = useSelector(state => state.ProgramReducer.site);

	useEffect(() => {
		if(options.value){
			setFileInfo(options.value);
		}else if(options.path){
			setFileInfo(options);
		}
	}, [options])

	useEffect(() => {
		setUseCustomAttach(!!options?.mediaImportType && !!medias && medias.length > 0);
	}, [options, medias])

	const onChanged = (e) =>{
		onFiles(e.target.files);
	} 

	const onFiles = useCallback((files) => {
		if(files.length > 0){
			const fileUploaded = files[0];
			fileUploaded.uppath = options.uppath || '';
			fileUploaded.uptype = options.uploadtype ? getUpType(options.uploadtype) : '';
			fileUploaded.to = options.to || '';
			setFileInfo(fileUploaded);
			handleChanged && handleChanged({ target : { 
				name : options.name, 
				type : "file", 
				value : fileUploaded
			}});
		}
	}, [handleChanged, options]);

	const getUpType = (up) => {
		const codes = Store.getState().CodeReducer.items;
		var n = codes.find(x => x.category === 'UPLOAD_TYPE' && x.code === up);
		return n ? n.value : "";
	}

	const onDrop = useCallback(acceptedFiles => {
		onFiles(acceptedFiles);
	}, [onFiles])

	const {getRootProps, getInputProps, isDragActive} = useDropzone({
		accept: options.filter || '',
		onDrop : onDrop
	})

	const getDropzoneProps = () => {
		return getRootProps({
			onClick : (event) => options?.mediaImportType === MLFile.MEDIA.IMPORT_TYPE.INNER_BUTTON && event.stopPropagation()
		})
	}

	const photoClick = file => {
		if(file){
			if(file.type.indexOf('image/') > -1){
				dispatch({ 
					type : ProgramConstant.MODAL_SHOW, 
					modal : {
						show : true,
						title : file.path.original,
						body : <MLPhoto file={file.path} />
					}
				});
			}else{
				window.open(AppConfig.S3_PATH + file.path.s3path, "_blank");
			}
		}
	};

	const getH = () => fid ? " h-200px " : (" mh-100px ");

	return <div className='mb-3'>
		{options.label && <label className={options.required ? 'form-label fw-bolder required' : 'form-label fw-bolder'}>{options.label || upperFistChar(options.name)}<span/></label>}
		<div className={"position-relative" +  (css && " " + css) + (focus ? " focused" : "") + (options.err ? " has-error" : "") }>
			{(!options.readonly && !readonly) && <>
				<div className={options.size ? getH() + options.size : getH()} {...getDropzoneProps()}>
					<div className={(isDragActive ? "bg-primary" : "bg-light-primary") + " px-2 py-4 card h-100 min-h-100px flex-center border-primary border border-dashed"} style={{ background: 'none' }}>
						<MLThumbnail fileInfo={fileInfo} placeholder={placeholder} maxwh={maxwh}  noment={noment}/>
						{!options.noment && (!noment && !fileInfo.name) && <>
							{options.uptitle && <div className="text-primary fs-5 fw-bolder text-center" htmlFor="input-file">
								{options.uptitle}
							</div>}
							{options.filter && <div className='text-gray-400 fs-7 fw-bolder mt-2'>
								{t('Accept')} {options.filter?.split(',')?.map(x => x.trim().toUpperCase().replace('.', ''))?.join(', ')}
							</div>}
						</>}
						{isDragActive && <>
							<div className="position-absolute w-100 h-100 d-flex align-items-center justify-content-center">
								<span className='fs-1 fw-bolder text-white'>
									{t('Drop the files here ...')}
								</span>
							</div>
						</>}
					</div>
					<input  {...getInputProps({ onChange: onChanged })} id={fid || `input-upload-${options.name}`} name={options.name} />
				</div>
				{options.err && <label className="error" htmlFor={options.name}>{options.errmsg}</label>} 
				{options.hint && <label className="help" htmlFor={options.name}>{options.hint}</label>} 
			</>}
			{(options.readonly || readonly) && fileInfo && <>
				<div className={"overflow-hidden " + getH()}>
					<MLThumbnailPhoto url={fileInfo} />
					{/*
					<MLThumbnail fileInfo={fileInfo} placeholder={placeholder} maxwh={maxwh} onClick={() => photoClick(fileInfo)} noment={noment} readonly={options.readonly || readonly} />
					{Object.keys(fileInfo).length === 0 && <div className='fs-16 mb-0'><i>{t('empty')}</i></div>}
					*/}
				</div>
			</>}
			{useCustomAttach && <>
				{options?.mediaImportType === MLFile.MEDIA.IMPORT_TYPE.INNER_BUTTON && <>
					<div 
						id={'overlay-btn-wrap-' + options.name}
						className='position-absolute bottom-0 p-2'
						style={{ right: 0, opacity: 0.5, transition: 'all 0.2s' }}
						onMouseOver={(e) => document.getElementById('overlay-btn-wrap-' + options.name).style.opacity = 1}
						onMouseOut={(e) => document.getElementById('overlay-btn-wrap-' + options.name).style.opacity = 0.5}
					>
						<MLButton options={{
							label: t('Media'),
							color: COLORS.PRIMARY,
							size: INPUTSIZE.SMALL,
							action: () => setMediaModal(prev => ({ ...prev, show: true }))
						}} css="me-1 opacity-1" />
						<MLButton options={{
							label: t('PC'),
							color: COLORS.SECONDARY,
							size: INPUTSIZE.SMALL,
							action: () => getInputProps()?.ref?.current?.click()
						}} css="opacity-1" />
					</div>
				</>}
				{options?.mediaImportType === MLFile.MEDIA.IMPORT_TYPE.DROPDOWN && <>
					<div className='position-absolute m-2 w-250px top-0 end-0' onClick={(e) => e.preventDefault()}>
						<MLComboSingle options={{
							name: 'media-selector',
							value: selectedMedia?.fileId || '',
							placeholder: t('Select Media file'),
							list: medias.map(x => ({ value: x.fileId, label: x.original }))
						}} handleChanged={(e) => {
							const targetMedia = medias.find(x => x.fileId === e.target.value);
							setSelectedMedia(targetMedia || null);
							const _value = { name: targetMedia.original, type: targetMedia.mimetype, size: targetMedia.size, path: targetMedia }
							handleChanged && handleChanged({ target : { 
								name : options.name, 
								type : "file", 
								value : _value
							}});
						}}/>
					</div>
				</>}
			</>}
		</div>
		{useCustomAttach && <Modal show={mediaModal.show} size="lg" dialogClassName={('modal-ray' + site) + ' modal-dialog modal-dialog-scrollable p-9'}>
				<Modal.Header className='modal-header header-bg py-9'>
					<h2 className="text-white">{mediaModal.title}</h2>
					<div onClick={() => setMediaModal(prev => ({ ...prev, show: false }))} className="raycur text-light" data-bs-dismiss="modal">
						<GrClose className='fs-3'/>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div className="row g-5">
						{medias?.map(media => <div key={media.fileId} className="col-4 raycur position-relative" >
							<div 
								className={"card w-100 h-100 border-3 border-dashed p-2 align-items-center justify-content-center " + (selectedMedia?.fileId === media.fileId ? 'border-primary' : 'border-secondary')}
								onClick={() => {
									if (selectedMedia?.fileId === media.fileId) {
										setSelectedMedia(null)
									} else {
										setSelectedMedia(media)
									}
								}}
							>
								<MLThumbnail 
									fileInfo={{ name: media.original, type: media.mimetype, size: media.size, path: media }}
									placeholder={placeholder} 
									maxwh={maxwh} 
								/>
								{(selectedMedia?.fileId === media.fileId) && <div className='position-absolute p-2 bg-white rounded opacity-75'>
									<MLButton options={{
										label: t('Attach'),
										color: COLORS.PRIMARY,
										size: INPUTSIZE.SMALL,
										action: () => {
											const _value = { name: selectedMedia.original, type: selectedMedia.mimetype, size: selectedMedia.size, path: selectedMedia }
											handleChanged && handleChanged({ target : { 
												name : options.name, 
												type : "file", 
												value : _value
											}});
											setMediaModal(prev => ({ ...prev, show: false }))
										}
									}} />
								</div>}
							</div>
						</div>)}
					</div>
				</Modal.Body>
		</Modal>}
	</div>
}

let model;

export const MLFileHead8Set = ({ options = {}, handleChanged, readonly = false, css = "", medias }) => {

	const modelURL = window.location.origin + '/models/patientPhotos/model.json';
	const metadataURL = window.location.origin + '/models/patientPhotos/metadata.json';

	const [focus, setFocus] = useState(false);
	const [fileInfos, setFileInfos] = useState([
		{ name : "fc", filter : 'image/*', uploadtype: 'upload_patientphotos', to: MLFile.TO.MYLAB, point : 0, mediaImportType: options?.mediaImportType },
		{ name : "fs", filter : 'image/*', uploadtype: 'upload_patientphotos', to: MLFile.TO.MYLAB, point : 0, mediaImportType: options?.mediaImportType },
		{ name : "fr", filter : 'image/*', uploadtype: 'upload_patientphotos', to: MLFile.TO.MYLAB, point : 0, mediaImportType: options?.mediaImportType },
		{ name : "tt", filter : 'image/*', uploadtype: 'upload_patientphotos', to: MLFile.TO.MYLAB, point : 0, mediaImportType: options?.mediaImportType },
		{ name : "tb", filter : 'image/*', uploadtype: 'upload_patientphotos', to: MLFile.TO.MYLAB, point : 0, mediaImportType: options?.mediaImportType },
		{ name : "tc", filter : 'image/*', uploadtype: 'upload_patientphotos', to: MLFile.TO.MYLAB, point : 0, mediaImportType: options?.mediaImportType },
		{ name : "tl", filter : 'image/*', uploadtype: 'upload_patientphotos', to: MLFile.TO.MYLAB, point : 0, mediaImportType: options?.mediaImportType },
		{ name : "tr", filter : 'image/*', uploadtype: 'upload_patientphotos', to: MLFile.TO.MYLAB, point : 0, mediaImportType: options?.mediaImportType }
	]);
	const [t] = useTranslation(['translation']);

	useEffect(() => {
		if(options.value)
			setFileInfos(options.value);
	}, [options])

	const [fileList, setFileList] = useState([]);
	const [predicting, setPredicting] = useState(false);

	const onChanged = useCallback((val) => {
		var newval = fileInfos.map(x => x.name === val.target.name ? {...x, ...val.target, point : 1} : x);
		setFileInfos(newval);
		handleChanged && handleChanged({ target : { name : options.name, value : newval }});
	}, [handleChanged, options, fileInfos])

	const onFiles = useCallback((files) => {
		if(files.length > 0){
			setFileList(files.map(x => {
				const oFile = x;
				oFile.uppath = options.uppath || '';
				oFile.uptype = options.uptype || '';
				oFile.to = options.to || '';
				oFile.base64 = '';
				oFile.point = 0;
				return oFile;
			}));
		}
	}, [handleChanged, options]);

	const onDrop = useCallback(acceptedFiles => {
		onFiles(acceptedFiles);
	}, [onFiles])

	useEffect(() => {
		if(fileList.length > 0){
			goCheckStart();
		}
	}, [fileList])

	const goCheckStart = async () => {
		if (!model) {
			setPredicting(true);
			await init();
		}
		try {
			doCheckAll(0);
		} catch (error) {
			setPredicting(false);
		}
	}

	async function init() {
		model = await tmImage.load(modelURL, metadataURL);
	}

	const predict = async (img, idx, file, list) => {
		const prediction = await model.predict(img, false);
		prediction.sort((a, b) => parseFloat(b.probability) - parseFloat(a.probability));
		list.push({ file, prediction });
		if ((idx + 1) === fileList.length) {
			insertPredictedImages(list);
		} else {
			doCheckAll(idx + 1, list);
		}
	}

	const findMatching = (item, emptyFileInfos, list) => {
		let define = false;
		for (const pre of item.prediction){
			if (define) {
				break;
			}
			const targetFileInfo = emptyFileInfos.find(x => x.name === pre.className && (!x.point || x.point < pre.probability));
			if (targetFileInfo) {
				const old = { ...targetFileInfo };
				targetFileInfo.value = item.file;
				targetFileInfo.point = pre.probability;
				define = true;
				if (old) {
					const recentItem = list.find(x => x.file === old.value);
					recentItem && findMatching(recentItem, emptyFileInfos, list);
				}
			}
		}
	}

	const insertPredictedImages = (list) => {
		const emptyFileInfos = fileInfos.filter(f => !f.value).length > 0 ? fileInfos.filter(f => !f.value) : [...fileInfos];
		for (const item of list) {
			findMatching(item, emptyFileInfos, list);
		}
		const filledFileInfos = emptyFileInfos.filter(x => !!x.value);
		const tempFileInfos = fileInfos.map(x => {
			const target = filledFileInfos.find(y => y.name === x.name);
			return target ? { ...x, value: target.value, point: target.point } : x;
		});
		setFileInfos(tempFileInfos);
		setFileList([]);
		handleChanged && handleChanged({ target : { name : options.name, value : tempFileInfos }});
		setPredicting(false);
	}

	const doCheckAll = async(pos = 0, list = []) => {
		if(pos < fileList.length){
			await doCheck(fileList[pos], pos, list);
		}
	}

	const doCheck = async(x, idx, list) => {
		if(x.name){
			const reader = new FileReader();
			reader.onloadend = () => {
				const base64 = reader.result;
				if (base64) {
					const image = new Image();
					image.onload = async () => {
						await predict(image, idx, x, list);
					};
					image.src = base64.toString();
				}
			}
			reader.readAsDataURL(x);
		}
	}

	const {getRootProps, getInputProps, isDragActive} = useDropzone({
		accept: options.filter || '',
		onDrop : onDrop
	})

	const onChangedCenter = (e) =>{
		onFiles(e.target.files);
	} 

	return <div className={"" + 
		(css && " " + css) +
		(focus ? " focused" : "") +
		(options.err ? " has-error" : "") }>
		{options.label && <label className={options.required ? 'form-label required' : 'form-label'}>{options.label || upperFistChar(options.name)}<span/></label>}
		<div className='row g-2 patientphoto'>
			<div className="col-4"><MLFileSingle noment={true} readonly={readonly} handleChanged={onChanged} medias={medias} preview={true} placeholder={"/img/fc.png"} fid={options.name + '-fc'} maxwh={options.size || 200} options={fileInfos.filter(x => x.name === "fc")[0]} /></div>
			<div className="col-4"><MLFileSingle noment={true} readonly={readonly} handleChanged={onChanged} medias={medias} preview={true} placeholder={"/img/fs.png"} fid={options.name + '-fs'} maxwh={options.size || 200} options={fileInfos.filter(x => x.name === "fs")[0]} /></div>
			<div className="col-4"><MLFileSingle noment={true} readonly={readonly} handleChanged={onChanged} medias={medias} preview={true} placeholder={"/img/fr.png"} fid={options.name + '-fr'} maxwh={options.size || 200} options={fileInfos.filter(x => x.name === "fr")[0]} /></div>
			<div className="col-4"><MLFileSingle noment={true} readonly={readonly} handleChanged={onChanged} medias={medias} preview={true} placeholder={"/img/tt.png"} fid={options.name + '-tt'} maxwh={options.size || 200} options={fileInfos.filter(x => x.name === "tt")[0]} /></div>
			<div className="col-4">
				{(!options.readonly && !readonly) && <div className={options.size ? "h-200px" + options.size : "h-200px"} {...getRootProps()}>
					<div className={(isDragActive ? "bg-primary" : "bg-light-primary") + " card h-100 flex-center border-primary border border-dashed"} style={{ background: 'none' }}>
						<MLThumbnail fileInfo={{}} />
						{(!isDragActive) && <>
							<span className="text-primary fs-5 fw-bolder mb-2 text-center" htmlFor="input-file">
								{options.uptitle}
								{!!options.filter && <>
									<br />
									<span className='text-gray-400 fs-7'>
										{t('Accept')} {options?.filter?.split(',')?.map(x => x.trim().toUpperCase().replace('.', ''))?.join(', ')}
									</span>
								</>}
							</span>
						</>}
						{isDragActive && <>
							<div className="fs-2 fw-bold text-white">{t('Drop the files here ...')}</div>
						</>}
						{predicting && <div className='d-inline-block fs-2 fw-bolder text-primary'>
							{t('Predicting')}
						</div>}
					</div>
					<input  {...getInputProps()}
						onChange={onChangedCenter} 
						name={options.name}
						type="file" 
						style={{ display : "none" }} />
				</div>}
			</div>
			<div className="col-4"><MLFileSingle noment={true} readonly={readonly} handleChanged={onChanged} medias={medias} preview={true} placeholder={"/img/tb.png"} fid={options.name + '-tb'} maxwh={options.size || 200} options={fileInfos.filter(x => x.name === "tb")[0]} /></div>
			<div className="col-4"><MLFileSingle noment={true} readonly={readonly} handleChanged={onChanged} medias={medias} preview={true} placeholder={"/img/tl.png"} fid={options.name + '-tl'} maxwh={options.size || 200} options={fileInfos.filter(x => x.name === "tl")[0]} /></div>
			<div className="col-4"><MLFileSingle noment={true} readonly={readonly} handleChanged={onChanged} medias={medias} preview={true} placeholder={"/img/tc.png"} fid={options.name + '-tc'} maxwh={options.size || 200} options={fileInfos.filter(x => x.name === "tc")[0]} /></div>
			<div className="col-4"><MLFileSingle noment={true} readonly={readonly} handleChanged={onChanged} medias={medias} preview={true} placeholder={"/img/tr.png"} fid={options.name + '-tr'} maxwh={options.size || 200} options={fileInfos.filter(x => x.name === "tr")[0]} /></div>
		</div>
		{options.err && <label className="error" htmlFor={options.name}>{options.errmsg}</label>} 
		{options.hint && <label className="help" htmlFor={options.name}>{options.hint}</label>} 
	</div>
}