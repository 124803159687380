import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FaPowerOff, FaPlay } from 'react-icons/fa';
import { MLPanelLoading } from '../components/common/MLPanel';
import { AuthConstant, getAPI, getHeader } from '../redux/reducers/AuthReducer';
import { idData } from '../libs/db';

const RAYTeamsInfoPage = ({ rayteam }) => {

	const dispatch = useDispatch();
	const { _id } = useParams();
	const [loading, setLoading] = useState(true);
	const [info, setInfo] = useState({});
	const [device, setDevice] = useState({});

	useEffect(() => {
		getData();
	}, [])

	useEffect(() => {
		genData();
	}, [rayteam])

    const getData = async () => {
		try{
			const ret = await idData("rayteams-info", _id);

			dispatch({ type : AuthConstant.GET_RAYTEAMS, item : ret.Items})
		}catch{
			dispatch({ type : AuthConstant.GET_RAYTEAMS, item : {}})
		}
		setLoading(false);
    }

    const genData = async () => {
		setInfo(rayteam.find(x => x.sk === "info") || {});
		setDevice(rayteam.find(x => x.sk.indexOf("device:") > -1) || {});
    }

  	return (<>
			<div className="header align-items-stretch d-block" style={{position: "initial"}}>
				<div className="d-flex w-100 justify-content-between p-5 align-items-center">
					<div className="d-flex align-items-center text-dark fw-bolder fs-2 my-1">RAYTeams Info</div>
					<div className="d-flex align-items-center">&nbsp;</div>
				</div>
			</div>
			{loading && info?._id && <>
				<div className='post p-7'>
					<MLPanelLoading />
				</div>
			</>}
			{!loading && (info?._id === _id) && <>
				<div className="post p-7">
				<div className='card'>
					<div className='card-body'>
						<div className='d-flex justify-content-between'>
							<h2 className='d-flex align-items-center'>
								{info.isOn && <FaPlay style={{ color : "brown", height:"18px" }}/>}
								{!info.isOn && <FaPlay style={{ color : "#bfbfbf", height:"18px" }}/>}
								<span className='ms-1'>{info.raylinkAppID}</span>
							</h2>
							<div>
								<span className='ms-2 text-muted'> Updated : {moment(info.updated).format('LL')}</span>
							</div>
						</div>
					</div>
				</div>
				<div className='card mt-5'>
					<div className='card-header'>
						<div className='card-title fs-4 fw-bolder'>RAYTeams Application Info</div>
					</div>
					<hr className='my-0'/>
					<div className='card-body p-8'>
						<div className='pb-4'>
							<div className='label fw-bolder'>Remote IP Address</div>
							<div className=''><span>{info.remoteIP}</span></div>
						</div>
						<div className='pb-4'>
							<div className='label fw-bolder'>Local IP Address</div>
							<div className=''><span>{info.localIP}</span></div>
						</div>
					</div>
				</div>
				{device.SN && <div className='card mt-5'>
					<div className='card-header'>
						<div className='card-title fs-4 fw-bolder'>Device Info</div>
					</div>
					<hr className='my-0'/>
					<div className='card-body p-8'>
						<div className='pb-4'>
							<div className='label fw-bolder'>Device Name</div>
							<div className=''><span>{device.DeviceName}</span></div>
						</div>
						<div className='pb-4'>
							<div className='label fw-bolder'>SN</div>
							<div className=''><span>{device.SN}</span></div>
						</div>
						{Object.keys(device).map((x, idx) => {
							if(x !== "_id" && x !== "updated" && x !== "sk" && x !== "SN" && x !== "DeviceName"){
								return <div className='pb-4' key={idx}>
									<div className='label fw-bolder'>{x}</div>
									<div className=''><span>{device[x]}</span></div>
								</div>
							}
						})}
						<div className='pb-4'>
							<div className='label fw-bolder'>Updated</div>
							<div className=''><span>{moment(device.updated).format("LLL")}</span></div>
						</div>
					</div>
				</div>}
			</div></>}
    	</>
  	);
}

const mapState = (state) => {
	const rayteam =  state.AuthReducer.rayteam;
	return { rayteam };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(RAYTeamsInfoPage);