import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { InfoAction, ProductAction, WFAction } from '../../redux/actions';
import { MLButton } from '../common/MLButton';
import { MLComboSingle, MLText } from '../common/MLInput';
import { RTAudit } from '../../libs/audit';
import { COLORS } from '../../config/color';

const LabProductCategoryAttr = ({ category }) => {
	const dispatch = useDispatch();

	const [t] = useTranslation(['translation']);
	const [newitem, setNewItem] = useState('');
	const [data, setData] = useState([]);
	const [specs, setSpecs] = useState([]);
	const [addFormShow, setAddFormShow] = useState(false);

	useEffect(() => {
		if (category?.specs) {
			setSpecs(category.specs);
		}
	}, [category]);

	const update = async (specs) => {
		await InfoAction.callDB({
			type: "update", region: "ap-northeast-2", params: {
				TableName: "rayteams-product",
				UpdateExpression: 'SET #specs = :specs, #updated = :updated',
				ExpressionAttributeNames: {
					'#specs': 'specs',
					'#updated': 'updated'
				},
				ExpressionAttributeValues: {
					':specs': specs,
					':updated': new Date().getTime()
				},
				Key: { _id: category._id, sk: 'category' }
			}
		});
		setSpecs(specs);
		RTAudit({ lvl: 5, msg: 'Add product category specs' })
	}

	const addOptions = async () => {
		await update([...specs, newitem]);
		setData([]);
		setAddFormShow(false);
		setNewItem("");
	}

	return <>
		<div className='d-flex justify-content-between align-items-center mb-2'>
			<div className='fw-bolder'>{t("Product specs")}</div>
			{!addFormShow && <MLButton options={{
				code: "prodcate-addspec",
				label: t("상품 스펙 추가"),
				color: 'primary',
				size: 'sm',
				action: () => setAddFormShow(true),
			}} />}
		</div>
		<div className='card'>
			{addFormShow && <div className='card-body'>
				<MLText options={{
					label: t('Spec title'),
					value: newitem
				}} handleChanged={(e) => setNewItem(e.target.value)} />
				<MLButton options={{
					label: t("저장"),
					color: 'primary',
					action: () => addOptions()
				}} />
				<MLButton options={{
					label: t("취소"),
					color: 'light',
					action: () => setAddFormShow(false)
				}} css='ms-1' />
			</div>}
			<ul className='list-group'>
				{specs?.map((x, idx) => <li className='list-group-item d-flex justify-content-between' key={idx}>
					<div>
						<span className='fw-bolder'>{x}</span>
					</div>
					<MLButton options={{
						code: "prodcate-delspec",
						label: t("삭제"),
						size: "sm",
						color: 'danger',
						action: () => update(specs.filter(f => f !== x))
					}} />
				</li>)}
			</ul>
		</div>
	</>

}

const mapState = (state) => {
	return {};
};

const mapDispatch = (dispatch) => ({

});

export default connect(mapState, null)(LabProductCategoryAttr);
