const savedEtag = window?.localStorage?.getItem('RM_CLIENT_ETAG') || '';

const initialState = {
	name: 'RAY MANAGER',
	etag: savedEtag,
	info: {},
	version: '',
	toolbar: <div className="toolbar" style={{ height: 55 }} />,
	contentTitle: <></>,
	menus: [],
	menuTracking: 0,
	modal: {
		show: false,
		title: null,
		body: null,
		footer: null,
		options: {}
	},
	loading: true,
	loadingStack: 0,
	loadingConfig: {
		isOVerLay: false,
		color: '#009ef7',
		isFixed: true,
	},
	swUpdate: false,
	swSuccess: false,
};

export const AppConstant = {
	VERSION_UPDATE: "ACTION_APP_VERSION_UPDATE",
	INFO_UPDATE: "ACTION_APP_INFO_UPDATE",
	UPDATE_TOOLBAR: "ACTION_APP_UPDATE_TOOLBAR",
	UPDATE_CONTENT_TITLE: "ACTION_APP_UPDATE_CONTENT_TITLE",
	SET_MENU: 'ACTION_APP_SET_MENU',
	SET_MODAL_SHOW: 'ACTION_APP_SET_MODAL_SHOW',
	SET_MODAL_CLOSE: 'ACTION_APP_SET_MODAL_CLOSE',
	SET_MODAL: 'ACTION_APP_SET_MODAL',
	SET_MODAL_OPTIONS: 'ACTION_APP_SET_MODAL_OPTIONS',
	ADD_LOAD_STACK: 'ACTION_APP_ADD_LOAD_STACK',
	SUB_LOAD_STACK: 'ACTION_APP_SUB_LOAD_STACK',
	CLEAR_LOAD_STACK: 'ACTION_APP_CLEAR_LOAD_STACK',
	SET_ETAG: 'ACTION_APP_SET_ETAG',
	SET_MENU_TRACKING: 'ACTION_APP_SET_MENU_TRACKING',
	SW_UPDATE: "ACTION_APP_SW_UPDATE",
	SW_SUCCESS: "ACTION_APP_SW_SUCCESS",
};

export const AppReducer = (state = initialState, action) => {
	switch (action.type) {
		case AppConstant.SW_UPDATE:
			return {
				...state,
				swUpdate: action.registration,
			};
		case AppConstant.SW_SUCCESS:
			return {
				...state,
				swSuccess: action.registration,
				swUpdate: false,
			};
		case AppConstant.SET_MENU_TRACKING:
			return {
				...state,
				menuTracking: state.menuTracking + 1
			}
		case AppConstant.SET_ETAG:
			return {
				...state,
				etag: action.item
			}
		case AppConstant.CLEAR_LOAD_STACK:
			return {
				...state,
				loadingStack: 0
			}
		case AppConstant.ADD_LOAD_STACK:
			return {
				...state,
				loadingStack: state.loadingStack + 1
			}
		case AppConstant.SUB_LOAD_STACK:
			return {
				...state,
				loadingStack: state.loadingStack - 1 < 0 ? 0 : state.loadingStack - 1
			}
		case AppConstant.SET_MODAL_OPTIONS:
			return {
				...state,
				modal: {
					...state.modal,
					options: {
						...state.modal.options,
						...action.payload.options
					}
				}
			}
		case AppConstant.SET_MODAL:
			return {
				...state,
				modal: {
					...state.modal,
					...action.payload
				}
			}
		case AppConstant.SET_MODAL_SHOW:
			return {
				...state,
				modal: {
					...action.payload,
					show: true,
					title: action.payload?.title || null,
					body: action.payload?.body || null,
					footer: action.payload?.footer || null,
					options: action.payload?.options || {}
				}
			}
		case AppConstant.SET_MODAL_CLOSE:
			return {
				...state,
				modal: {
					...state.modal,
					show: false
				}
			}
		case AppConstant.VERSION_UPDATE:
			return {
				...state,
				version: action.value
			};
		case AppConstant.INFO_UPDATE:
			return {
				...state,
				info: action.value
			};
		case AppConstant.UPDATE_TOOLBAR:
			return {
				...state,
				toolbar: action.payload
			};
		case AppConstant.UPDATE_CONTENT_TITLE:
			return {
				...state,
				contentTitle: action.payload
			};
		case AppConstant.SET_MENU:
			return {
				...state,
				menus: action.payload
			};
		default:
			return state
	}
}