import { Form, FormControl } from 'react-bootstrap';
import { MLComboSingle, MLComboMulti, MLToggle, MLEmail, MLPassword, 
	MLSpanV, MLText, MLTextArea, MLHidden, MLNumber, MLHR, MLDate, MLRadioButton, MLGroup } from '../common/MLInput';
import { MLFileHead8Set, MLFileSingle } from '../common/MLFileInputs';
import { MLButton, MLSubmit } from '../common/MLButton';
import { INPUTS, BUTTONS, SPAN, COLORS } from '../../constants/FromInputs';
import { useTranslation } from 'react-i18next';
import { Card } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import  MLComboSingleByCode from '../common/MLComboSingleByCode';
import { MLPanelNoData } from '../common/MLPanel';
import { BsArrowDown, BsArrowUp, BsX } from 'react-icons/bs';

export const FormPreview = ({ controls, data = {}, submit = null, update, selRow, delRow,
	usecollapse = false, useclose = false, nocard = false, onChanged,
	lock, readonly = false, onlyhasval = true, title="" }) => {

	const [t] = useTranslation(['translation']);
	const [ formData, setFormData ] = useState({});
	const [ fromControls, setFormControls ] = useState([]);
	const [ collapse, setCollapse ] = useState(false);
	const [ closecard, setClosecard ] = useState(false);

	useEffect(() => {
		makeData();
	},[data, controls]);

	useEffect(() => {
		makeContols();
	},[formData]);

	const valid = () => {
		var ret = 0;
		fromControls.map(x => (x.options.required && !x.options.value) && ret++)
		return ret == 0;
	}

	const makeContols = () => {
		var ctrls = [];
		controls.map(x => {
			if(x.options.hasOwnProperty("name")){
				ctrls.push({...x, options : {...x.options, value : formData[x.options.name]}});
			}else{
				ctrls.push({...x});
			}
		})
		setFormControls(ctrls);
	}

	const makeData = () => {
		var obj = {};
		controls.map(x => {
			if(x.options.hasOwnProperty("name")){
				if(data[x.options.name] === false){
					obj[x.options.name] = false;
				}else{
					obj[x.options.name] = data[x.options.name] || "";
				}
			}
		})
		if(data._id)
			obj["_id"] = data._id;

		setFormData(obj);
	}

	const handleChanged = (e) => {
		const { name, value } = e.target;
		setFormData({...formData, [name] : value});
		onChanged && onChanged({...formData, [name] : value});
	}

	const callbackSubmit = () => {
		if(formData.hasOwnProperty("password") && formData.hasOwnProperty("repassword")){
			if(formData.password != formData.repassword){
				var nf = [...fromControls];
				nf.map(x => {
					if(x.options.name === "repassword"){
						x.options.err = t('doestnotmatchpassword');
					}
				});
				setFormControls(nf);
				return;
			}
		}
		submit && submit(formData);
	}

	const onSortEnd = ({oldIndex, newIndex, collection, isKeySorting}, e) => {
		if(oldIndex !== newIndex){
			if(fromControls[collection - 1].controls){
				const newcols = arrayMoveImmutable(fromControls[collection - 1].controls || fromControls, oldIndex, newIndex);
				update && update(fromControls.map((x, idx) => idx === collection - 1 ? {...x, controls : newcols} : x));
			}else{
				update && update(arrayMoveImmutable(fromControls, oldIndex, newIndex));
			}
		}
	};

	return <>
		{nocard && <>
			{fromControls.length > 0 && <div>
				<SortableList
					onSortEnd={onSortEnd} 
					fromControls={fromControls} 
					handleChanged={handleChanged} 
					readonly={readonly}
					selRow={selRow}
					lock={lock}
					delRow={delRow}
					onlyhasval={onlyhasval} 
					valid={valid} 
					callbackSubmit={callbackSubmit} />
			</div>}
			{fromControls.length === 0 && <MLPanelNoData title={t('norecord')} noborder={true}/>}
		</>}
		{fromControls.filter(x => x.type === INPUTS.GROUP).length > 0 && <>
			{fromControls.filter(x => x.type === INPUTS.GROUP).map((x, idx) =><div className='bg-white border p-2 px-4 mb-1'>
				<label className='text-muted'>{x.options.grouptitle}</label>
				{!lock && <div className='d-flex flex-wrap flex-stack pb-2'>
					<div className='d-flex flex-wrap align-items-center my-1'></div>
					<div className='d-flex flex-wrap my-1'>
						<div className='d-flex my-0'>
							<MLButton options={{ size : "sm", label : "Edit", color : COLORS.SUCCESS, action : () => selRow({collection : idx + 1}) }} css='ms-1'/>
							{x.controls?.length === 0 && <MLButton options={{ size : "sm", label : "Remove", color : COLORS.DANGER, action : () => delRow({collection : idx + 1}) }} css='ms-1'/>}
						</div>
					</div>
				</div>}
				<SortableList
					onSortEnd={onSortEnd} 
					collection={idx + 1}
					fromControls={x.controls} 
					handleChanged={handleChanged} 
					readonly={readonly}
					selRow={selRow}
					lock={lock}
					axis={x.options.horizontal ? "x" : "y"}
					horizontal={x.options.horizontal}
					delRow={delRow}
					onlyhasval={onlyhasval} 
					valid={valid} 
					callbackSubmit={callbackSubmit} />
			</div>)}
		</>}
		{fromControls.filter(x => x.type !== INPUTS.GROUP && !x.options.groupList).length > 0 && <>
			<div className='bg-white'>
				<SortableList
					onSortEnd={onSortEnd} 
					fromControls={fromControls.filter(x => x.type !== INPUTS.GROUP && !x.options.groupList)} 
					handleChanged={handleChanged} 
					collection={0}
					readonly={readonly}
					selRow={selRow}
					lock={lock}
					delRow={delRow}
					onlyhasval={onlyhasval} 
					valid={valid} 
					callbackSubmit={callbackSubmit} />
			</div>
		</>}
		{fromControls.length === 0 && <div className='py-5'><MLPanelNoData title={t('norecord')} noborder={true}/></div>}
	</>
}
const SortableList = SortableContainer(({ fromControls, handleChanged, readonly, valid, callbackSubmit, horizontal, onlyhasval, selRow, delRow, lock, collection }) => {
	return <>
		{<div className={horizontal ? 'row' : 'list-group'}>
			{fromControls.map((x, index) => <SortableItem 
				handleChanged={handleChanged} 
				readonly={readonly}
				valid={valid} 
				selRow={selRow}
				delRow={delRow}
				disabled={lock}
				horizontal={horizontal}
				collection={collection}
				lock={lock}
				onlyhasval={onlyhasval} 
				callbackSubmit={callbackSubmit}
				key={`preview-${index}`} 
				index={index} 
				val={{collection, index}} 
				x={x} />)}
		</div>}
	</>
});
const SortableItem = SortableElement(({x, index, val, handleChanged, readonly, valid, callbackSubmit, onlyhasval, selRow, delRow, lock, horizontal}) => {

	const showItem = (x, type) => {
		if(readonly && onlyhasval){
			return x.type === type && x.options.value;
		}
		return x.type === type;
	}

	return <div className={!lock ? (horizontal ? 'col border' : 'list-group-item cursor-pointer') : (horizontal ? 'col border' : 'list-group-item')}>
		{!lock && <div className='d-flex flex-wrap flex-stack pb-2'>
			<div className='d-flex flex-wrap align-items-center my-1'></div>
			<div className='d-flex flex-wrap my-1'>
				<div className='d-flex my-0'>
					<MLButton options={{ size : "sm", label : "Edit", color : COLORS.SUCCESS, action : () => selRow(val) }} css='ms-1'/>
					<MLButton options={{ size : "sm", label : "Remove", color : COLORS.DANGER, action : () => delRow(val) }} css='ms-1'/>
				</div>
			</div>
		</div>}
		{showItem(x, INPUTS.GROUP) && <MLGroup options={x.options} />}
		{showItem(x, INPUTS.HIDDEN) && <MLHidden options={x.options} handleChanged={handleChanged} readonly={readonly}/>}
		{showItem(x, INPUTS.TEXT) && <MLText options={x.options} handleChanged={handleChanged} readonly={readonly} css='mb-4'/>}
		{showItem(x, INPUTS.DATE) && <MLDate options={x.options} handleChanged={handleChanged} readonly={readonly} css='mb-4'/>}
		{showItem(x, INPUTS.RADIOBUTTON) && <MLRadioButton options={x.options} handleChanged={handleChanged} readonly={readonly} css='mb-4'/>}
		{showItem(x, INPUTS.TOGGLE) && <MLToggle options={x.options} handleChanged={handleChanged} readonly={readonly} css='mb-4'/>}
		{showItem(x, INPUTS.SINGLEFILE) && <MLFileSingle options={x.options} handleChanged={handleChanged} readonly={readonly} css='mb-4'/>}
		{showItem(x, INPUTS.SINGLEFILEFORORDER) && <MLFileSingle options={x.options} handleChanged={handleChanged} readonly={readonly} css='mb-4'/>}
		{showItem(x, INPUTS.HEADPHOTO8) && <MLFileHead8Set options={x.options} handleChanged={handleChanged} readonly={readonly} css='mb-4'/>}
		{showItem(x, INPUTS.NUMBER) && <MLNumber options={x.options} handleChanged={handleChanged} readonly={readonly} css='mb-4'/>}
		{showItem(x, INPUTS.COMBO) && <MLComboSingle options={x.options} handleChanged={handleChanged} readonly={readonly} css='mb-4'/>}
		{showItem(x, INPUTS.COMBOBYCODE) && <MLComboSingleByCode options={x.options} handleChanged={handleChanged} readonly={readonly} css='mb-4'/>}
		{showItem(x, INPUTS.TEXTAREA) && <MLTextArea options={x.options} handleChanged={handleChanged} readonly={readonly} css='mb-4'/>}
		{showItem(x, INPUTS.EMAIL) && <MLEmail options={x.options} handleChanged={handleChanged} readonly={readonly} css='mb-4'/>}
		{showItem(x, INPUTS.PASSWORD) && <MLPassword options={x.options} handleChanged={handleChanged} readonly={readonly} css='mb-4'/>}
		{showItem(x, INPUTS.COMBOMULTI) && <MLComboMulti options={x.options} handleChanged={handleChanged} readonly={readonly} css='mb-4'/>}

		{!readonly && x.type == BUTTONS.NORMALBUTTON && <MLButton options={x.options} valid={valid} />}
		{!readonly && x.type == BUTTONS.FORMSUBMIT && <MLSubmit options={x.options} valid={valid} callback={callbackSubmit} />}
		{!readonly && x.type == SPAN.VERTICAL && <MLSpanV options={x.options}/>}
		{!readonly && x.type == SPAN.HR && <MLHR options={x.options}/>}
		{!readonly && x.type == BUTTONS.BUTTONS && <div className={'btns ' + (x.options.align ? x.options.align : "")}>
			{x.buttons.map((y, cdx) => <React.Fragment key={cdx}>
				{y.type == BUTTONS.NORMALBUTTON && <MLButton options={y.options} valid={valid} />}
				{y.type == BUTTONS.FORMSUBMIT && <MLSubmit options={y.options} valid={valid} callback={callbackSubmit} />}
			</React.Fragment>)}
		</div>}
	</div>
});