import React from 'react';
import {
  getBezierPath,
  getEdgeCenter,
  getMarkerEnd,
} from 'react-flow-renderer';
import MaterialIcon from '@material/react-material-icon';
const foreignObjectSize = 40;

const onEdgeClick = (evt, id) => {
	evt.stopPropagation();
	alert(`remove ${id}`);
};

export default function MLWFButtonEdge({
	id,
	sourceX,
	sourceY,
	targetX,
	targetY,
	sourcePosition,
	targetPosition,
	style = {},
	data,
	arrowHeadType,
	markerEndId,
}) {
	const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  console.log(data);

  return <>
	<path
		id={id}
		style={style}
		className="react-flow__edge-path"
		d={edgePath}
		markerEnd={markerEnd} />
		<text>
			<textPath href={`#${id}`} style={{ fontSize: '12px' }} startOffset="50%" textAnchor="middle">
				{ data.title }
			</textPath>
		</text>
    </>
}