import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MSGAlarm } from "../../config/MSGAlarm";
import { MLUserById } from "../common/MLCommon";

const ProjectHistoryItem = ({ projectId, project, item }) => {
    const [t] = useTranslation(['translation']);
    const [updateType, setUpdateType] = useState('');

    useEffect(() => {
        setUpdateType(item.updateType)
    }, [item]);

    return <>
        {updateType && <div className="p-3 form-control mb-4 mt-2">
            <div className="d-flex">
                <div className="flex-fill">
                    {/* <MLUserById _id={item.creator} css='me-2' readonly={true} /> */}
                    {item.creator}
                    <span className="text-muted inline">( {moment(item.created).format('LLL')} )</span>
                </div>
            </div>
            <div className="mt-2">
                {updateType === MSGAlarm.RAY_LINK_PROJECT_TRASH && <div className="d-flex align-items-start">
                    <div className="text-muted me-2 min-w-150px">{t('Change Project')}</div>
                    <div className="text-white">{t('To Trash')}</div>
                </div>}
                {updateType === MSGAlarm.RAY_LINK_PROJECT_RESTORE && <div className="d-flex align-items-start">
                    <div className="text-muted me-2 min-w-150px">{t('Change Project')}</div>
                    <div className="text-white">{t('To Restore')}</div>
                </div>}
                {updateType === MSGAlarm.RAY_LINK_PROJECT_COMPLETE && <div className="d-flex align-items-start">
                    <div className="text-muted me-2 min-w-150px">{t('Change Project')}</div>
                    <div className="text-white">{t('To Complete')}</div>
                </div>}
                {updateType === MSGAlarm.RAY_LINK_PROJECT_ACTIVE && <div className="d-flex align-items-start">
                    <div className="text-muted me-2 min-w-150px">{t('Change Project')}</div>
                    <div className="text-white">{t('To Active')}</div>
                </div>}

                {updateType === MSGAlarm.RAY_LINK_PROJECT_STATUS && <>
                    <div className="d-flex align-items-center py-3">
                        <div className="text-muted me-2 min-w-150px">{t('Change Status')}</div>
                        <div className="d-flex align-items-center">
                            <span className={`me-2 badge-circle badge badge-${item.data.status.color}`}></span>
                            <span className="text-white">{item.data.status.text}</span>
                        </div>
                    </div>
                </>}

                {updateType === MSGAlarm.RAY_LINK_PROJECT_CONFIRM && <div className="d-flex align-items-start">
                    <div className="text-muted me-2 min-w-150px">{t('Design')}</div>
                    <div className="text-white">{t('Confirm Design')}</div>
                </div>}

                {updateType === MSGAlarm.RAY_LINK_PROJECT_ADDATTACHEMENT && <div className="d-flex align-items-start">
                    <div className="text-muted me-2 min-w-150px">{t('Add Attachment File')}</div>
                    <div className="text-white">{item?.data?.data?.attachment?.name || t('unknown file')}</div>
                </div>}
                {updateType === MSGAlarm.RAY_LINK_PROJECT_REMOVEATTACHEMENT && <div className="d-flex align-items-start">
                    <div className="text-muted me-2 min-w-150px">{t('Delete Attachment File')}</div>
                    <div className="text-white">{item?.data?.data?.attachment?.name || t('unknown file')}</div>
                </div>}
                
                {updateType === MSGAlarm.RAY_LINK_PROJECT_ADDMEMO && <div className="d-flex flex-column align-items-start">
                    <div className="text-muted me-2 min-w-150px pt-2">{t('Create Post')}</div>
                    <div className="text-white w-100 mt-2 p-2 rounded border-1 bg-input-content">
                        <div className="htmlcontent" dangerouslySetInnerHTML={{ __html: item?.data?.content || t('empty')  }} />
                    </div>
                </div>}
                {updateType === MSGAlarm.RAY_LINK_PROJECT_UPDATEMEMO && <div className="d-flex flex-column align-items-start">
                    <div className="text-muted me-2 min-w-150px pt-2">{t('Modify Post')}</div>
                    <div className="text-white w-100 mt-2 p-2 rounded border-1 bg-input-content">
                        <div className="htmlcontent" dangerouslySetInnerHTML={{ __html: item?.data?.data?.content || t('empty')  }} />
                    </div>
                </div>}
                {updateType === MSGAlarm.RAY_LINK_PROJECT_REMOVEMEMO && <div className="d-flex flex-column align-items-start">
                    <div className="text-muted me-2 min-w-150px pt-2">{t('Delete Post')}</div>
                    <div className="text-white w-100 mt-2 p-2 rounded border-1 bg-input-content">
                        <div className="htmlcontent" dangerouslySetInnerHTML={{ __html: item?.data?.memo?.content || t('empty')  }} />
                    </div>
                </div>}

                
                {updateType === MSGAlarm.RAY_LINK_PROJECT_ADDCOMMENT && <div className="d-flex flex-column align-items-start">
                    <div className="text-muted me-2 min-w-150px pt-2">{t('Create Comment')}</div>
                    <div className="text-white w-100 mt-2 p-2 rounded border-1 bg-input-content">
                        <div className="htmlcontent" dangerouslySetInnerHTML={{ __html: item?.data?.data.content || t('empty')  }} />
                    </div>
                </div>}
                {updateType === MSGAlarm.RAY_LINK_PROJECT_UPDATECOMMENT && <div className="d-flex flex-column align-items-start">
                    <div className="text-muted me-2 min-w-150px pt-2">{t('Modify Comment')}</div>
                    <div className="text-white w-100 mt-2 p-2 rounded border-1 bg-input-content">
                        <div className="htmlcontent" dangerouslySetInnerHTML={{ __html: item?.data?.data.content || t('empty')  }} />
                    </div>
                </div>}
                {updateType === MSGAlarm.RAY_LINK_PROJECT_REMOVECOMMENT && <div className="d-flex flex-column align-items-start">
                    <div className="text-muted me-2 min-w-150px pt-2">{t('Delete Comment')}</div>
                    <div className="text-white w-100 mt-2 p-2 rounded border-1 bg-input-content">
                        <div className="htmlcontent" dangerouslySetInnerHTML={{ __html: item?.data?.comment?.content || t('empty') }} />
                    </div>
                </div>}
            </div>
        </div>}
    </>
};

export default ProjectHistoryItem;