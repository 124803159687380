import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { MLPanelLoadingOverlay } from '../common/MLPanel';
import { RiMoreLine } from "react-icons/ri";

const POST_MAX_HEIGHT = 100;

const RMProjectPosts = ({ project, post, isRoot = false, hideIcon = false }) => {
	const [loading, setLoading] = useState(true);
	const [contentRef, setContentRef] = useState(null);
    const [hasMoreContent, setHasMoreContent] = useState(false);
    const [viewAllContent, setViewAllContent] = useState(false);

	useEffect(() => {
        if (!contentRef) return;
        setHasMoreContent(contentRef.scrollHeight > contentRef.clientHeight);
		setLoading(false);
    }, [contentRef, viewAllContent]);

    return <div className={`p-3 ${isRoot ? "mb-4" : ""} position-relative`}>
        {loading && <MLPanelLoadingOverlay position="absolute" opacity={0} />}
        <div className="d-flex align-items-start">
            <div className="flex-fill form-control p-0">
                <div className={`d-flex ${isRoot ? "p-3" : "pb-1"} align-items-start`}>
                    <div className="flex-fill line-height-1 d-flex align-items-start justify-content-between">
                        <div>
                            {/* <MLUserById _id={post.creator} readonly={true} /> */}

							{post.creator}
                            {post?.isEdit && <span className="ms-2 fs-9 text-muted">{t('Edited')} {post.updated && moment(post.updated).format('LLL')}</span>}
                        </div>
                        {post.created && <span className="text-muted inline fs-8">{moment(post.created).format('MM.DD LT')}</span>}
                    </div>
                </div>
                <div className={`${isRoot ? "px-3" : ""} mb-2`}>
                    {<>
                        <div
                            ref={setContentRef}
                            className="htmlcontent"
                            dangerouslySetInnerHTML={{ __html: post?.content }}
                            style={{ overflowY: 'hidden', maxHeight: viewAllContent ? undefined : POST_MAX_HEIGHT }}
                        >
                            {/* {contentHtml} */}
                        </div>
                        {(contentRef && (hasMoreContent || viewAllContent)) && <>
                            <div className="fs-8">
                                {!viewAllContent && <RiMoreLine />}
                                <i className="mx-1 fs-8 raycur" onClick={() => setViewAllContent(prev => !prev)}>
                                    {viewAllContent ? t('Hide') : t('More')}
                                </i>
                            </div>
                        </>}
                    </>}
                </div>
            </div>
        </div>
    </div>
}

export default RMProjectPosts;