import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { history } from '../../history';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { FaSave, FaEdit, FaWindowClose, FaCheckCircle, FaStopCircle } from 'react-icons/fa';
import { ProgramConstant } from '../../redux/reducers';
//import Clinic from '../components/clinic';
import Clinic from '../partner/clinic';
import { COLORS } from '../../config/color';
import { RTButton } from '../../libs/buttons';
import { fetchData } from '../../libs/db';
import { MLButton } from '../common/MLButton';
import { COUNTRIES } from '../../constants/Country';
import { MLComboSingle, MLComboMulti } from '../common/MLInput';
import MLUserSearch from '../common/MLUserSearch';
import User from '../common/MLUser';

const MLScope = ({ groups, edit, value, clickEdit, handleChanged, sim = false }) => {

    const dispatch = useDispatch();

    const [t] = useTranslation(['translation']);
    const [items, setItems] = useState([]);
    const [scope, setScope] = useState("g");
    const [cc, setCC] = useState("");
    const [uList, setUList] = useState([]);
    const [grp, setGrp] = useState("");
    const [data, setData] = useState({});
    const [process, setProcess] = useState(false);

    useEffect(() => {

    }, []);

    useEffect(() => {
        handleChanged && makeUpdate();
    }, [scope, cc, uList, grp]);

    useEffect(() => {
        if (value) {
            makeValue();
        } else {
            setScope("a");
            setCC("");
            setGrp("");
        }
    }, [value]);

    const makeUpdate = () => {
        if (scope === "a")
            return handleChanged("a");
        if (scope === "c")
            return handleChanged("c:" + cc);
        if (scope === "g")
            return handleChanged("g:" + grp);
        if (scope === "u")
            return handleChanged("u:" + uList.filter(x => x._id).map(x => x._id));
    }

    const scopes = [
        { value: "a", label: "All" },
        { value: "c", label: "Country" },
        { value: "g", label: "Group(Institute)" },
        { value: "u", label: "Specific user" }
    ]

    const getTitle = () => {
        const val = value?.type.replace("cfg:", "");
        let item = [];
        if (val.startsWith("a")) {
            item.push(<div className="d-flex fs-5">
                <label className="me-2">전체</label>
            </div>)
        }
        if (val.startsWith("c:")) {
            const ccs = val.replace("c:", "").split(",");
            item.push(<div className="d-flex fs-6">
                <label className="me-2">국가 : </label>
                {ccs.map(x => COUNTRIES.find(c => c.countryCode === x).name).join(" ,")}
            </div>)
        }
        if (val.startsWith("g:")) {
            const ccs = val.replace("g:", "").split(",");
            item.push(<div className="d-flex fs-6">
                <label className="me-2">기관 : </label>
                <div className="d-flex">
                    {ccs.map((x, idx) => {
                        if (idx === ccs.length - 1) {
                            return <div className="me-2">
                                <a href='' onClick={() => history.push("/GroupInfo/" + x)} >{groups.find(c => c._id === x).name}</a>
                            </div>
                        } else {
                            return <div className="me-2">
                                <a href='' onClick={() => history.push("/GroupInfo/" + x)} >{groups.find(c => c._id === x).name}</a>,
                            </div>
                        }
                    })}
                </div>
            </div>)
        }
        if (val.startsWith("u:")) {
            const ccs = val.replace("u:", "").split(",");
            item.push(<div className="d-flex fs-6">
                <label className="me-2">사용자 : </label>
                <div className="d-flex">
                    {ccs.map((x, idx) => {
                        if (idx === ccs.length - 1) {
                            return <div className="me-2">
                                <User _id={x} />
                            </div>
                        } else {
                            return <div className="me-2">
                                <User _id={x} />,
                            </div>
                        }
                    })}
                </div>
            </div>)
        }
        return <div className="d-flex justify-content-between h-40px align-items-center">
            {item}
            <div>
                <div className="d-flex align-items-center">
                    <label className="fs-6 me-4">{value.values.length}건</label>
                    <MLButton options={{
                        label: "Edit",
                        size: "sm",
                        color: COLORS.SECONDARY,
                        action: () => clickEdit(value)
                    }} />
                </div>
            </div>
        </div>;
    }

    const makeValue = () => {
        const val = value?.type?.replace("cfg:", "").split(":");
        if (val?.length > 0) {
            setScope(val[0]);
            if (val[0] === "c")
                setCC(val[1]);
            if (val[0] === "g")
                setGrp(val[1]);
        }
    }

    return <div>
        {!edit && <>
            {getTitle()}
        </>}
        {edit && <>
            <div className="mb-3">
                <label className="fw-bolder me-3 mb-1">Scope</label>
                <MLComboSingle options={{
                    list: scopes,
                    value: scopes.find(x => x.value === scope)?.value
                }} css="w-300px" handleChanged={(e) => setScope(e.target.value)} />
            </div>
            <div className="mb-3">
                {scope === "a" && <div> 모두 </div>}
                {scope === "p" && <div>특정 법인/딜러</div>}
                {scope === "c" && <>
                    <label className="fw-bolder me-3 mb-1">국가 선택</label>
                    {sim ? <MLComboSingle options={{
                        list: COUNTRIES.map(x => ({
                            value: x.countryCode,
                            label: x.name
                        })),
                        value: cc
                    }} handleChanged={(e) => setCC(e.target.value)} /> :
                        <MLComboMulti options={{
                            list: COUNTRIES.map(x => ({
                                value: x.countryCode,
                                label: x.name
                            })),
                            value: cc
                        }} handleChanged={(e) => setCC(e.target.value)} />}
                </>}
                {scope === "g" && <>
                    <label className="fw-bolder me-3 mb-1">기관 선택</label>
                    {sim ? <MLComboSingle options={{
                        list: groups.map(x => ({
                            value: x._id,
                            label: x.name
                        })),
                        value: grp
                    }} handleChanged={(e) => setGrp(e.target.value)} /> :
                        <MLComboMulti options={{
                            list: groups.map(x => ({
                                value: x._id,
                                label: x.name
                            })),
                            value: grp
                        }} handleChanged={(e) => setGrp(e.target.value)} />}
                </>}
                {scope === "u" && <>
                    <label className="fw-bolder me-3 mb-1">사용자 선택</label>
                    <MLUserSearch
                        onlyone={sim}
                        handleChanged={(e) => setUList(e)}
                        values={value?.type?.indexOf('u:') > -1 ? value?.type?.replace("cfg:", "").replace("u:", "") : ""} />
                    {/* <MLUserSearch
                        onlyone={sim}
                        handleChanged={(e) => setUList(e)}
                        values={value?.type?.replace("cfg:", "").replace("u:", "")} /> */}
                </>}
            </div>
        </>}
    </div>
}

const mapState = (state) => {
    const groups = state.AuthReducer.groups;

    return { groups };
};

const mapDispatch = (dispatch) => ({

});

export default connect(mapState, mapDispatch)(MLScope);
