import { useEffect, useRef, useState } from 'react';
import ReactFlow, {
	addEdge, Background, Controls, MiniMap, ReactFlowProvider, removeElements
} from 'react-flow-renderer';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { COLORS } from '../../constants/FromInputs';
import { UserAction } from '../../redux/actions';
import { MLButton } from '../common/MLButton';
import MLFlowNodePanel from './MLFlowNodePanel';
import MLWFButtonEdge from './MLWFButtonEdge.js';
import { WFNodeAction, WFNodeStatus } from './MLWFNodes';

const MLWFSideBar = ({ save, cancel, nocancel, copyandassign, copyandassignaction }) => {

	const [t] = useTranslation(['translation']);
	const onDragStart = (event, nodeType) => {
		event.dataTransfer.setData('application/reactflow', nodeType);
		event.dataTransfer.effectAllowed = 'move';
	};

	return <div className='d-flex justify-content-between'>
		<div className=''>
			<div className="description">{t('You can drag a status')}</div>
			<div
				className="dndnode status float-left"
				onDragStart={(event) => onDragStart(event, 'status')} draggable>
				Status
			</div>
		</div>
		<div className='d-flex flex-shrink-0'>
			<MLButton options={{
				color: COLORS.PRIMARY,
				size: "lg",
				label: t('save'),
				action: save
			}} css="btn btn-flex flex-center ms-1" />
			{/*copyandassign && <MLButton options={{
				color: COLORS.SUCCESS,
				size: "lg",
				label: t('copyandassign'),
				action: copyandassignaction
			}} css="d-flex ms-1" />*/}
			{!nocancel && <MLButton options={{
				color: COLORS.LIGHT,
				size: "lg",
				label: t('cancel'),
				action: cancel
			}} css="d-flex ms-1" />}
		</div>
	</div>
};
const MLWFEditor = ({ data, save, cancel, readonly = false,
	copyandassign = false, copyandassignaction,
	offsety = 300, nocancel = false }) => {
	const nodeTypes = {
		action: WFNodeAction,
		status: WFNodeStatus,
	};

	const initialElements = [
		{
			id: 'ewb-1',
			type: 'input',
			data: { label: 'Start' },
			position: { x: 360, y: 150 },
		},
		{
			id: 'ewb-4',
			type: 'output',
			data: { label: 'End' },
			position: { x: 350, y: 600 }
		}
	];

	const [selNode, setSelNode] = useState({});
	const [vHg, setVHg] = useState(0);
	const getHeight = () => setVHg(window.innerHeight);
	const reactFlowWrapper = useRef(null);
	const [reactFlowInstance, setReactFlowInstance] = useState(null);
	const [elements, setElements] = useState(initialElements);
	const onConnect = (params) => makeConnect(params);
	const onElementsRemove = (elementsToRemove) => {
		setElements((els) => removeElements(elementsToRemove, els));
	}
	const onConnectStart = (event, { nodeId, handleType }) => console.log('on connect start', { nodeId, handleType });
	const onConnectStop = (event) => console.log('on connect stop', event);
	const onConnectEnd = (event) => console.log('on connect end', event);

	useEffect(() => {
		if (data.flows) {
			setElements(data.flows.elements || initialElements);
		}
	}, [data.flows]);

	useEffect(() => {
		window.addEventListener('resize', getHeight);
		setVHg(window.innerHeight);
	}, [])

	const onLoad = (_reactFlowInstance) => {
		_reactFlowInstance.fitView();
		setReactFlowInstance(_reactFlowInstance);
	}

	const onElementClick = (e, el) => {
		if (el.data?.type) {
			setSelNode(el);
		}
	}
	const onDragOver = (event) => {
		event.preventDefault();
		event.dataTransfer.dropEffect = 'move';
	};

	const getNewId = () => {
		return `node_${(Date.now())}`
	}

	const getLabel = (type) => {
		return type === "output" ? "End" : type.charAt(0).toUpperCase() + type.slice(1);
	}

	const makeConnect = (params) => {
		setElements((els) => addEdge({
			...params,
			animated: true,
			label: "Action",
			data: {
				eltype: "line",
				type: "action",
				title: 'Action',
				form: null,
				trigger: null,
				roleusers: [],
				roleusertypes: [],
			},
			labelBgPadding: [8, 4],
			labelBgBorderRadius: 4,
			arrowHeadType: 'arrowclosed',
		}, els))
	}

	const modDot = (e, node, tp) => {
		e.preventDefault();
		setElements((els) =>
			els.map((el) => {
				return el;
			})
		);
	}

	const makeNode = (event) => {
		const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
		const type = event.dataTransfer.getData('application/reactflow');
		const position = reactFlowInstance.project({
			x: event.clientX - reactFlowBounds.left,
			y: event.clientY - reactFlowBounds.top,
		});
		return {
			id: getNewId(),
			type,
			position,
			data: {
				eltype: type,
				type: type,
				title: getLabel(type)
			},
		};
	}

	const handleChanged = (e, node) => {
		setElements((els) =>
			els.map((el) => {
				if (el.id === node.id) {
					el = {
						...el,
						data: {
							...el.data,
							[e.target.name]: e.target.value,
						},
					}
					if (e.target.name === "title")
						el = { ...el, label: e.target.value }
				}
				return el;
			})
		);
	}

	const onDrop = (event) => {
		event.preventDefault();
		setElements((es) => es.concat(makeNode(event)));
	};

	const saveFlows = () => {
		const flows = reactFlowInstance.toObject();
		save && save({ ...data, flows: flows });
	};

	return <div className='wfpanel card card-secondary mb-0'>
		{!readonly && <div className='p-3 border border-bottom'>
			<MLWFSideBar save={saveFlows} cancel={cancel} nocancel={nocancel}
				copyandassignaction={copyandassignaction}
				copyandassign={copyandassign} /> </div>}
		{vHg > 0 && <div className='dndflow' style={{ height: vHg - (readonly ? offsety : offsety - 30) }}>
			<ReactFlowProvider>
				<div className="reactflow-wrapper" ref={reactFlowWrapper} style={{ height: vHg - (readonly ? offsety : offsety - 30) }}>
					<ReactFlow
						onConnect={onConnect}
						onElementsRemove={onElementsRemove}
						nodeTypes={nodeTypes}
						snapToGrid={true}
						nodesDraggable={!readonly}
						onElementClick={onElementClick}
						snapGrid={[15, 15]}
						edgeTypes={{ buttonedge: MLWFButtonEdge }}
						onConnectStart={onConnectStart}
						onConnectStop={onConnectStop}
						onConnectEnd={onConnectEnd}
						onLoad={onLoad}
						onDrop={onDrop}
						onDragOver={onDragOver}
						elements={elements} >
						<MiniMap />
						<Background />
						<Controls />
					</ReactFlow>
				</div>
			</ReactFlowProvider>
			{!readonly && <MLFlowNodePanel
				node={selNode}
				workflow={data}
				modDot={modDot}
				elements={elements}
				elRemove={onElementsRemove}
				handleChanged={handleChanged} />}
		</div>}
	</div>
}

const mapState = (state) => {
};

const mapDispatch = dispatch => ({
})

export default MLWFEditor;