import { useCallback, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MLButton } from "../common/MLButton";
import { InfoAction } from "../../redux/actions";
import { MLPanelLoadingOverlay } from "../common/MLPanel";
import moment from "moment";

const ProjectPaidColumn = ({ project, callbackRefresh }) => {
    const [t] = useTranslation();
    const [show, setShow] = useState(false);
    const isOuterPaid = useMemo(() => typeof(project?.outerpaid) === "number" && project?.outerpaid > 0, [project?.outerpaid]);
    const [loading, setLoading] = useState(false);

    const handlerClickTogglePaid = useCallback(async () => {
        try {
            setLoading(true);
            const outerPaidValue = isOuterPaid ? 0 : new Date().getTime();
            const payload = {
                type: "update",
                region: project?.region || "ap-northeast-2",
                params: {
                    TableName: "rayteams-project",
                    Key: {
                        _id: project?._id,
                        sk: "project",
                    },
                    UpdateExpression: "SET #outerpaid = :outerpaid",
                    ExpressionAttributeNames: {
                        "#outerpaid": "outerpaid",
                    },
                    ExpressionAttributeValues: {
                        ":outerpaid": outerPaidValue,
                    },
                },
            };
            await InfoAction.callDB(payload);
            callbackRefresh && callbackRefresh();
            setShow(false);
        } catch (error) {
            console.log("ERROR[handlerClickTogglePaid]", error?.response.data || error?.toString());
        } finally {
            setLoading(false);
        }
    }, [project?._id, project?.outerpaid]);

    return <>
        <span
            className={[
                "badge",
                "cursor-pointer",
                "user-select-none",
                (isOuterPaid ? "bg-label-primary" : "bg-label-warning")
            ].join(" ")}
            onClick={() => setShow(true)}
        >
            {isOuterPaid ? t("Paid") : t("Unpaid")}
        </span>
        {isOuterPaid && <div>
            {moment(project.outerpaid).format("YYYY-MM-DD")}
        </div>}
        {loading && <MLPanelLoadingOverlay />}
        <Modal
            show={show}
            centered={true}
            onHide={() => setShow(false)}
        >
            <Modal.Header>
                <Modal.Title>
                    {t("결제 상태 변경")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isOuterPaid && <>
                    {t("결제 상태를 미결제 상태로 변경하시겠습니까?")}
                </>}
                {!isOuterPaid && <>
                    {t("결제 상태를 결제 완료 상태로 변경하시겠습니까?")}
                </>}
            </Modal.Body>
            <Modal.Footer>
                <MLButton
                    options={{
                        label: t("Cancel"),
                        action: () => setShow(false),
                    }}
                />
                <MLButton
                    options={{
                        label: isOuterPaid ? t("To Unpaid") : t("To Paid"),
                        color: isOuterPaid ? "primary" : "warning",
                        action: handlerClickTogglePaid,
                    }}
                />
            </Modal.Footer>
        </Modal>
    </>
};

export default ProjectPaidColumn;
