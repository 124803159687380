import axios from "axios";
import { SUBINTERVAL } from "../constants/RMConstants";

// 개발 테스트
// const PAYPAL_CLIENT_ID = "AcgQywJgoHlnFcQSkg94GU9lDEEQWmedVEun33I0WQ0HpZrgC27w1OO8q6WzXx4yZaunhU49gbQuHjda";
// const PAYPAL_SECRET = "EOdBgVtKLl6BrlsRKuPd3kjpm7mc0DAob2qKaCO-C1Mb3jH22xPukeIXfbOxOIQaXxx0UFrCPEmMwIP9";
// APAC
// 0:6:AU 등록
// const PAYPAL_CLIENT_ID = "ASDuo8M7QvV9EXyLC4yvmuT72Cz5pY86dyEmmBaOZ1qVnV4Q3S7IbVx-3603gYmh4ZIRXwVs6Z5pAsBq";
// const PAYPAL_SECRET = "EM9prVAj6dsTwHgST2f_7wt3rsqbat-MGpiC-G8DADSWbYUikaEeIZ1Sg_xjiwFnecKioFX6UBhHe08c";

// HQ 본사
// 0:8:DE 등록
const PAYPAL_CLIENT_ID = "ASUWl34oRkEzbjwn0Bj3hsmxSffQBvoPmXdJVRNQfV8Ujsg4xYOfNKKruKU6VZ50DTvABSO2kM1nPznj";
const PAYPAL_SECRET = "EIBrmUcr2oIcjiumQjMIN0lsIRz8tlVJIJy6mG18Se0hRu7fsyp2G-S85EKdG7EJvHjXmn1-PuRdkuRj";

const STRIPE_SECRET_KEY = "sk_test_51MmR7NCBIqssotRkG74ulSjsUJl9vkfwLj3UM3zlMkderNTYWBlDcj1o3Nua5hokXDA9JtMQXNe1KEs1kbn8nd9W00mC3Sip5r";

//const env = (location.href.indexOf("localhost") > -1 || location.href.indexOf(".raydevelop.com") > -1) ? ".sandbox" : "";
const env = ".sandbox";

export const PayPalAccessToken = async () => {
    const response = await axios({
        url: `https://api-m${env}.paypal.com/v1/oauth2/token`,
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        auth: {
            username: PAYPAL_CLIENT_ID,
            password: PAYPAL_SECRET
        },
        data: {
            grant_type: "client_credentials"
        }
    });
    return response.data?.access_token;
}

export const PayPalGetCatalogProduct = async () => {
    const access_token = await PayPalAccessToken();

    var pageNum = 1;
    var pageSize = 20;
    const searchResults = { Items : [], Count : 0 };
    let items;
    do{
        items =  await axios({
            url: `https://api-m${env}.paypal.com/v1/catalogs/products?page_size=${pageSize}&page=${pageNum}&total_required=true`,
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + access_token,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Prefer': 'return=representation'
            },
        });
        items.data.products.forEach((item) => { searchResults.Items.push(item); searchResults.Count++; });
        pageNum++;
    }while(typeof items.data.total_items == items.data.total_pages);
      
    return searchResults;
}

export const PayPalCreateCatalogProduct = async (pParams) => {
    const access_token = await PayPalAccessToken();
    const response = await axios({
        url: `https://api-m${env}.paypal.com/v1/catalogs/products`,
        method: 'post',
        headers: {
            'Authorization': 'Bearer ' + access_token,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Prefer': 'return=representation'
        },
        data: {
            id: pParams._id,
            name: pParams.title,
            type: "DIGITAL",
            category: "SOFTWARE"
        }
    });
    
    return {prdId: response.data?.id, access_token: access_token}
}

// https://developer.paypal.com/docs/api/subscriptions/v1/#plans_create
export const PayPalCreateSubscription = async (pParams) => {
    console.log("PayPalCreateSub start ================");

    let billingCyclesData = [];
    if(pParams.trial) {
        billingCyclesData.push({
            frequency: {
                interval_unit: SUBINTERVAL.find(f => f.type === "PAYPAL" && f.subType === pParams.trialperiodtype).value || "DAY",
                interval_count: pParams.trialperiod || 1
            },
            tenure_type: "TRIAL",
            sequence: 1,
            pricing_scheme: {
                fixed_price: {
                    value: pParams.trialprice || 0,
                    currency_code: pParams.trialunit || "USD"
                }
            }
        })
    }

    billingCyclesData.push({
        frequency: {
            interval_unit: pParams?.interval_unit || "MONTH",
            interval_count: pParams?.interval_count || 1
        },
        tenure_type: "REGULAR",
        sequence: pParams.trial ? 2 : 1,
        total_cycles: 0,
        pricing_scheme: {
            fixed_price: {
                value: pParams.realprice,
                currency_code: pParams.punit
            }
        }
    })

    const access_token = await PayPalAccessToken();
    const response = await axios({
        url: `https://api-m${env}.paypal.com/v1/billing/plans`,
        method: 'post',
        headers: {
            'Authorization': 'Bearer ' + access_token,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Prefer': 'return=representation'
        },
        data: {
            product_id: pParams._id,
            name: pParams.title,
            status: "ACTIVE",
            billing_cycles: billingCyclesData,
            payment_preferences: {
                auto_bill_outstanding: true,
                setup_fee_failure_action: "CONTINUE",
                payment_failure_threshold: 3
            },
        }
    });
    console.log("PayPalCreateSubscription ===============", response);
    return response;
}

export const StripeSearchProduct = async (productid) => {
    const response = await axios({
        url: 'https://api.stripe.com/v1/products',
        method: 'get',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${STRIPE_SECRET_KEY}`
        },
        data: {
            ids: productid,
            limit: 100
        }
    });

    return response.data
}

export const StripeCreateProduct = async (pParams, customer_id, price_id, paymentMethodId) => {
    const response = await axios({
        url: 'https://api.stripe.com/v1/products',
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${STRIPE_SECRET_KEY}`
        },
        data: {
            id: pParams._id,
            name: pParams.title
        }
    });

    return response.data
}

export const StripeCreatePrice = async (prdId, pParams) => {
    const response = await axios({
        url: 'https://api.stripe.com/v1/prices',
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${STRIPE_SECRET_KEY}`
        },
        data: {
            product: prdId,
            unit_amount: pParams.realprice,
            currency: pParams.punit.toLowerCase(),
            recurring: {
                interval: SUBINTERVAL.find(f => f.type === "STRIPE").value,
                interval_count: 1
            },
        }
    });

    return response.data
}