import axios from 'axios';
import { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { history } from '../../history';
import { InfoAction, ProductAction, WFAction } from '../../redux/actions';
import { GetCodeConfig, GetConfig } from '../../libs/appConfig';
import { BUTTONS, COLORS, INPUTS, SPAN } from '../../constants/FromInputs';
import { ProgramConstant } from '../../redux/reducers';
import { MLForm } from '../common/MLForm';
import { PRICEUNIT } from '../../constants/RMConstants';
import { useTranslation } from 'react-i18next';
import { RTAudit } from '../../libs/audit';
import { COUNTRIES } from '../../constants/Country';
const LabProductCategoryAdd = ({ readonly = false, callBackInfo, callBackCancel, callbackUpdate}) => {
	const dispatch = useDispatch();
	const [t] = useTranslation(['translation']);
	const { _id } = useParams("");
	const [help, setHelp] = useState(window.localStorage.getItem("helpmsg") === "N" ? false : true);
	const [data, setData] = useState({
		priceType : 'negotiable',
		priceUnit : 'USD'
	});
	const [wfs, setWfs] = useState([]);
	const [codes, setCodes] = useState([]);

	useEffect(() => {
		init();
	}, []);

	const init = async () => {
		setCodes(await GetCodeConfig());
		const retWf = await WFAction.GetAll();
		setWfs(retWf);
		if(_id){
			getData();
		}
	}

	const getData = async () => {
		const ret  = await InfoAction.callDB({ type : "query", region : "ap-northeast-2", params : {
			TableName: "rayteams-product",
			KeyConditionExpression: "#_id = :_id and #sk = :sk",
			ExpressionAttributeNames: { "#_id": "_id", "#sk": "sk" },
			ExpressionAttributeValues: { ":_id": _id, ":sk": "category" }
		}});
		if(ret.length > 0){
			setData(ret[0]);
			callBackInfo && callBackInfo(ret[0])
		}
	}

	const create = async () => {
		if(_id){
			const UpdateExpression = `SET #title = :title, #desc = :desc, #updated = :updated, #order = :order, #visible = :visible`;
			await InfoAction.callDB({ type : "update", region : "ap-northeast-2", params : {
				TableName: "rayteams-product",
				UpdateExpression,
				ExpressionAttributeNames: { 
					'#title' : 'title', 
					'#desc' : 'desc', 
					'#updated' : 'updated',
					"#order": "order",
					"#visible": "visible",
				},
				ExpressionAttributeValues: { 
					':title' : data.title, 
					':desc' : data.desc, 
					':updated' : _id ? new Date().getTime() : 0,
					":order": isNaN(data?.order) ? 0 : Number(data?.order),
					":visible": data?.visible ?? false, 
				},
				Key: { _id, sk: 'category' }
			}});
			callbackUpdate && callbackUpdate()
			RTAudit({ lvl: 7, msg: '[' + data.title + '] Update Product Category' }, JSON.stringify({ subject: data }))
		}else{
			const newId = v4();
			const UpdateExpression = `SET #title = :title, #desc = :desc, #created = :created, #order = :order, #visible = :visible`;
			await InfoAction.callDB({ type : "update", region : "ap-northeast-2", params : {
				TableName: "rayteams-product",
				UpdateExpression,
				ExpressionAttributeNames: { 
					'#title' : 'title', 
					'#desc' : 'desc', 
					'#created' : 'created',
					"#order": "order",
					"#visible": "visible",
				},
				ExpressionAttributeValues: { 
					':title' : data.title, 
					':desc' : data.desc, 
					':created' : new Date().getTime(),
					":order": isNaN(data?.order) ? 0 : Number(data?.order),
					":visible": data?.visible ?? false, 
				},
				Key: { _id: newId, sk: 'category' }
			}});
			history.push('/LabProductCategoryInfoPage/' + newId)
			RTAudit({ lvl: 7, msg: '[' + data.title + '] Add Product Category' }, JSON.stringify({ subject: data }))
		}
	}

	const controls = [
		{
			type: INPUTS.TEXT,
			options: {
				label: t('제목'),
				name: 'title',
				required: true,
				hint: t("사용자에게 노출되는 분류의 이름")
			}
		},
		{
			type: SPAN.VERTICAL,
			options: { size: 10 }
		},
		{
			type: INPUTS.TEXTAREA,
			options: {
				label: t('Description'),
				name: 'desc',
				hint: t("분류의 설명(이 정보는 사용자에게 노출되지 않음)")
			}
		},
		{
			type: INPUTS.TOGGLE,
			options: {
				label: t('Visible'),
				name: 'visible',
				hint: t("실제 사용자에게 노출할지 여부")
			}
		},
		{
			type: INPUTS.NUMBER,
			options: {
				label: t('Order'),
				name: 'order',
				hint: t("노출되는 순서(오름차순)")
			}
		},
		{
			type: SPAN.VERTICAL,
			options: { size: 10 }
		},
		{
			type: BUTTONS.BUTTONS,
			options: {},
			buttons: [{
				type: BUTTONS.NORMALBUTTON,
				options: {
					label: t('Cancel'),
					action: () => callBackCancel ? callBackCancel() : history.push('/LabProductCategoryPage')
				}
			}, {
				type: BUTTONS.FORMSUBMIT,
				options: {
					label: _id ? t('Update') : t('Add'),
					color: COLORS.PRIMARY,
					action: () => create()
				}
			}]
		}
	];

	return <MLForm 
		controls={controls} 
		data={data} 
		readonly={readonly}
		submit={() => create()}
		onChanged={(e) => setData(e)} />
}

const mapState = (state) => {
	return {  };
};

const mapDispatch = (dispatch) => ({

});

export default connect(mapState, null)(LabProductCategoryAdd);
