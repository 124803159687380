import moment from 'moment';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { history } from '../../history';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { putData, delData } from '../../libs/db';
import { FaSave, FaEdit, FaWindowClose, FaCheckCircle, FaStopCircle } from 'react-icons/fa';
import { ProgramConstant } from '../../redux/reducers';
import { getApiUrl } from '../../redux/reducers/AuthReducer';
import { JWT } from '../../config';
import { ApplicationAction } from '../../redux/actions';
import Clinic from '../partner/clinic';
import { COLORS } from '../../config/color';
import { RTButton } from '../../libs/buttons';
import { fetchData } from '../../libs/db';
import RMGroup from '../manager/RMGroup';
import MLScope from '../common/MLScope';
import { MLText } from '../common/MLInput';
import { MLButton } from '../common/MLButton';

const CompAppConfig = ({ application, cfg, scope, applicationConfigs, groups }) => {

	const dispatch = useDispatch();

	const [t] = useTranslation(['translation']);
	const [ process, setProcess ] = useState(false);
	const [ customA, setCustomA ] = useState([]);
	const [ customC, setCustomC ] = useState([]);
	const [ customG, setCustomG ] = useState([]);
	const [ customU, setCustomU ] = useState([]);

	useEffect(() => {
        console.log(scope);
        setProcess(scope.length < 3);
        if(scope.length > 2)
            getCustom();
	}, [scope, cfg]);
    console.log(process);

    const getCustom = async () => {
        const base = getMatchA();
        if(scope === "a"){
        }else if(scope.indexOf("c:") > -1){
            getMatchC(base, scope.replace("c:", ""));
        }else if(scope.indexOf("g:") > -1){
            const grp = groups.find(x => x._id === scope.replace("g:", ""));
            getMatchG(getMatchC(base, grp?.countryCode), scope.replace("g:", ""));
        }else if(scope.indexOf("u:") > -1){
            const user = await GetUserById(scope.replace("u:", ""));
            const grp = groups.find(x => x._id === user.groupId);
            getMatchU(getMatchG(getMatchC(base, "c:" + grp?.countryCode), user.groupId), scope.replace("u:", ""));
        }
        setProcess(false);
    }

    const getMatchA = () => {
        let nList = [...cfg];
        applicationConfigs.find(x => x.type === "cfg:a")?.values?.map(x => {
            nList = nList.map(c => c.key === x.key ? x : c);
        });
        setCustomA(nList);
        return nList;
    }

    const getMatchC = (nList, cc) => {
        let nListC = [...nList];
        applicationConfigs.find(x => x.type.indexOf("cfg:c:") > -1 && x.type.indexOf(cc) > -1)?.values?.map(x => {
            nListC = nListC.map(c => c.key === x.key ? x : c);
        });
        setCustomC(nListC);
        return nListC;
    }

    const getMatchG = (nList, group) => {
        let nListG = [...nList];
        applicationConfigs.find(x => x.type.indexOf("cfg:g:") > -1 && x.type.indexOf(group) > -1)?.values?.map(x => {
            nListG = nListG.map(c => c.key === x.key ? x : c);
        });
        setCustomG(nListG);
        return nListG;
    }

    const getMatchU = (nList, user) => {
        let nListU = [...nList];
        applicationConfigs.find(x => x.type.indexOf("cfg:u:") > -1 && x.type.indexOf(user) > -1)?.values?.map(x => {
            nListU = nListU.map(c => c.key === x.key ? x : c);
        });
        setCustomU(nListU);
    }

	const GetUserById = async (id) => {
		const API_URL = getApiUrl('user');
		const ret = await axios.get(API_URL + '/getuserbyid/' + id, JWT());
		if (ret.data.status === 'success') {
			return ret.data.data;
		}else{
			return {};
		}
	}
    console.log(scope);

	return <div>
        <table className="table table-bordered">
            <thead>
                <tr>
                    <th className="fw-bolder w-300px" rowspan="2">Key</th>
                    <th className="fw-bolder" rowspan="2">Default</th>
                    <th className="fw-bolder" colspan="4">Customized</th>
                </tr>
                <tr>
                    <th className="fw-bolder">Global</th>
                    {!process && scope !== "" && scope.length > 0 && <>
                        {(scope.charAt(0) === "c" || scope.charAt(0) === "g" || scope.charAt(0) === "u") && <th className="fw-bolder">Country</th>}
                        {(scope.charAt(0) === "g" || scope.charAt(0) === "u") &&  <th className="fw-bolder">Institute</th>}
                        {(scope.charAt(0) === "u") && <th className="fw-bolder">User</th>}
                    </>}
                </tr>
            </thead>
            <tbody>
            {cfg?.map(x => <tr key={x.key}>
                <td className="fw-bolder">{x.key}</td>
                <td style={{ wordWrap: "break-word", minWidth: "200px", maxWidth: "200px" }}>{x.value}</td>
                <td style={{ wordWrap: "break-word", minWidth: "200px", maxWidth: "200px" }}>{customA?.find(c => c.key === x.key)?.value !== x.value ?
                    <span className='fw-bolder text-danger'>{customA?.find(c => c.key === x.key)?.value}</span> : 
                    <span className='text-muted'>{x.value}</span>
                }</td>
                {!process && scope !== "" && scope.length > 0 && <>
                    {(scope.charAt(0) === "c" || scope.charAt(0) === "g" || scope.charAt(0) === "u") && <td className="wbr">
                        {customC?.find(c => c.key === x.key)?.value !== x.value ?
                        <span className='fw-bolder text-danger'>{customC?.find(c => c.key === x.key)?.value}</span> : 
                        <span className='text-muted'>{x.value}</span>}
                    </td>}
                    {(scope.charAt(0) === "g" || scope.charAt(0) === "u") && <td className="wbr">
                        {customG?.find(c => c.key === x.key)?.value !== x.value ?
                        <span className='fw-bolder text-danger'>{customG?.find(c => c.key === x.key)?.value}</span> : 
                        <span className='text-muted'>{x.value}</span>}
                    </td>}
                    {(scope.charAt(0) === "u") && <td className="wbr">
                        {customU?.find(c => c.key === x.key)?.value !== x.value ?
                        <span className='fw-bolder text-danger'>{customU?.find(c => c.key === x.key)?.value}</span> : 
                        <span className='text-muted'>{x.value}</span>}
                    </td>}
                </>}
            </tr>)}
            </tbody>
        </table>
    </div>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
    const applications = state.ApplicationReducer.applications;
    const applicationConfigs = state.ApplicationReducer.applicationConfigs;
	const groups =  state.AuthReducer.groups;
	return { user, applications, applicationConfigs, groups };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(CompAppConfig);
