import moment from 'moment';
import { useEffect, useState } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

const MLToast = ({ alert, pos = "center" }) => {
	const [t] = useTranslation(['translation']);
	const [data, setData] = useState({});
	const [show, setShow] = useState(false);

	useEffect(() => {
		setData(alert);
        // console.log(alert);
	}, [alert]);

	useEffect(() => {
		setShow(data.show);
	}, [data]);

	return <ToastContainer position={pos} className='mb-5 position-fixed' style={{ zIndex: 1100 }}>
		<Toast show={show || false} delay={5000} autohide bg={data.type} onClose={() => setShow(false)}>
			<Toast.Header>
				<strong className="me-auto">{'RAYTeams Manager'}</strong>
				{/*<small className="text-muted">{moment().format('yyyy-MM-DD HH:mm:ss')}</small>*/}
			</Toast.Header>
			<Toast.Body>
				{data?.useTrans ? t(data.msg) : data.msg}
			</Toast.Body>
		</Toast>
	</ToastContainer>
}

const mapState = (state) => {
	const alert = state.AlertReducer.alert;
	return { alert };
};

const mapDispatch = dispatch => ({
})

export default connect(mapState, mapDispatch)(MLToast);
