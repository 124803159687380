import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { fetchData, fetchData_prod } from '../../libs/db';
import RTProductAdd from '../../components/rayteamsproduct/RTProductAdd';
//RTModalAddProductMain

const RTProductAddPage = ({ _id, user, region, RTproducts }) => {
    return (<>
        <RTProductAdd/>
    </>);
};


const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const region =  state.AuthReducer.region;
    const RTproducts = state.RTProductReducer.RTproducts;

	return { region, user, RTproducts };
};

const mapDispatch = (dispatch) => ({

});

export default connect(mapState, null)(RTProductAddPage);