import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import MLTable from '../../components/common/MLTable';
import { MLButton } from '../../components/common/MLButton';
import { MLText } from '../../components/common/MLInput';
import { COLORS } from '../../config/color';
import { ProgramConstant } from '../../redux/reducers';
import { fetchData } from '../../libs/db';
import { MLPanelLoading } from '../../components/common/MLPanel';
import { useTranslation } from 'react-i18next';

const NotificationList = ({ pagestat }) => {
    const dispatch = useDispatch();
    const [t] = useTranslation(['translation']);
    const env = (location.href.indexOf("localhost") > -1 || location.href.indexOf(".raydevelop.com") > -1) ? "development" : "production";
    const _bid = (env === "development") ? "7ba42cce-eb4b-40db-a3f3-44e79874d852" : "2a331a32-3c28-4b02-9de7-71244650dfcc";

    const [search, setSearch] = useState("");
    const [filter, setFilter] = useState(false);
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [contents, setContents] = useState([]);

    useEffect(() => {
        dispatch({
            type: ProgramConstant.SET_PAGE,
            page: {
                code: "notifications", view: "summary", title: "Notifications", small: t("Notifications 정보"), options: []
            }
        })

        getData();
    }, []);

    const headers = [
        { link: "_id", linkpath: "/NotificationsInfo", value: "title", label: t("Title"), css: "text-start" },
        { value: "updated", label: t("Updated"), type: "DATE", css: "text-start", style: { width: 200 } },
    ]

    const headerClick = () => {
        dispatch({
            type: ProgramConstant.PAGESTAT, item: {
                devicelist: {
                    search, filter,
                    order: pagestat.order,
                    desc: (pagestat.desc === "desc" ? "asc" : "desc")
                }
            }
        });
    }

    const getData = async () => {
        setLoading(true);
        try {
            const scanFilter = {
                bid: {
                    ComparisonOperator: "EQ",
                    AttributeValueList: [_bid],
                },
                sk: {
                    ComparisonOperator: "EQ",
                    AttributeValueList: ["info"],
                },
            }
            const ret = await fetchData("rayteams-manager-contents", scanFilter);
            const list = ret.Items.sort((a, b) => a.updated > b.updated ? -1 : 1).map(x => ({
                ...x,
                title: x.isDel > 0 ? <strike>{t("삭제된 글입니다.")}</strike> : x.title,
                content: x.isDel > 0 ? <strike>{t("삭제된 글입니다.")}</strike> : x.content,
            }));
            setItems(list);
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    }


    return (
        <div className='p-4'>
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <div className="card-label fw-bold text-gray-800 fs-5">{items && items.length}</div>
                    <div className="d-flex">
                        <MLButton options={{
                            label: filter ? "Used(RAYTeams)" : "전체",
                            size: "sm",
                            color: COLORS.PRIMARY,
                            action: () => setFilter(!filter)
                        }} css="me-2" />
                        <MLText options={{
                            placeholder: "검색어 입력",
                            size: "sm",
                            value: search,
                        }} handleChanged={(e) => setSearch(e.target.value)} css="mb-0" />
                    </div>
                </div>
                {!loading && <MLTable
                    headers={headers}
                    items={items}
                    headerClick={headerClick}
                    order={pagestat?.order || "created"}
                    desc={pagestat?.desc || "desc"}
                    noheader={true} />}
                {loading && <MLPanelLoading />}
            </div>
        </div>
    );
}

const mapState = (state) => {
    const pagestat = state.ProgramReducer.pagestat["notificationlist"] || {};
    return { pagestat };
};

export default connect(mapState, null)(NotificationList);