import moment from 'moment';
import { useEffect, useState } from 'react';
import { InfoAction } from '../../redux/actions';
import RMWidgetSmallCount from '../widgets/RMWidgetSmallCount';
import { useTranslation } from 'react-i18next';

const RMCountPanel = ({ options }) => {
	const [t] = useTranslation(['translation']);
    const [period, setPeriod] = useState("c");
    const [day, setDay] = useState(30);

    useEffect(() => {
		updatePeriod();
    }, [period]);

	const updatePeriod = () => {
		if(period === "a"){
			return moment().startOf('day').utc().valueOf();
		}else if(period === "b"){
			return moment().add(-7, "day").utc().valueOf();
		}else if(period === "c"){
			return moment().startOf('month').utc().valueOf();
		}else if(period === "d"){
			return moment().startOf('year').utc().valueOf();
		}else if(period === "e"){
			return 0;
		}
	}

    return <div className='card' style={{ height : 230 }}>
		<div className='card-header'>
			<div className="d-flex align-items-center justify-content-between">
				<div className='fw-bolder'>{t("전체 통계")}</div>
				<div>
					<span onClick={() => setPeriod("a")} className={"badge ms-1 cursor-pointer " + (period === "a" ? "bg-primary" : 'bg-light text-gray ')}>{t("오늘")}</span>
					<span onClick={() => setPeriod("b")} className={"badge ms-1 cursor-pointer " + (period === "b" ? "bg-primary" : 'bg-light text-gray ')}>{t("최근 7일")}</span>
					<span onClick={() => setPeriod("c")} className={"badge ms-1 cursor-pointer " + (period === "c" ? "bg-primary" : 'bg-light text-gray ')}>{t("이번달")}</span>
					<span onClick={() => setPeriod("d")} className={"badge ms-1 cursor-pointer " + (period === "d" ? "bg-primary" : 'bg-light text-gray ')}>{t("이번해")}</span>
					<span onClick={() => setPeriod("e")} className={"badge ms-1 cursor-pointer " + (period === "e" ? "bg-primary" : 'bg-light text-gray ')}>{t("전체 누적")}</span>
				</div>
			</div>
		</div>
		<div className='card-body'>
			<div className='row mb-4'>
			{(process.env.REACT_APP_COMPANY === 'ray' || !process.env.REACT_APP_COMPANY) && <div className='col'>
					<RMWidgetSmallCount options={{ icon : "user-plus", color : "info", cacehname : "all-user-" + period, title : t("신규 유저"),
						filter : {
							type : "scan",
							region : "ap-northeast-2",
							params : {
								TableName: "rayteams-user",
								FilterExpression: "#sk=:sk and #created > :n",
								ExpressionAttributeNames: { "#sk": "sk", "#created": "created" },
								ExpressionAttributeValues: { ":sk": "info", ":n": updatePeriod() },
								ProjectionExpression: "sk"
							}
						}
					}} />
				</div>}
				<div className='col'>
					<RMWidgetSmallCount options={{ icon : "folder-plus", color : "info", cacehname : "all-case-" + period, title : t("신규 케이스"),
						filter : {
							type : "scan",
							region : "ap-northeast-2",
							params : {
								TableName: "rayteams-project",
								FilterExpression: "#sk=:sk and #created > :n",
								ExpressionAttributeNames: { "#sk": "sk", "#created": "created" },
								ExpressionAttributeValues: { ":sk": "project", ":n": updatePeriod() },
								ProjectionExpression: "sk"
							}
						}
					}} />
				</div>
				<div className='col'>
					<RMWidgetSmallCount options={{ icon : "building-hospital", color : "info", cacehname : "all-gclinic-" + period, title : t("신규 클리닉"),
						filter : {
							type : "scan",
							region : "ap-northeast-2",
							params : {
								TableName: "rayteams-group",
								FilterExpression: "#sk=:sk and #created > :n and contains(grouptype, :groupType)",
								ExpressionAttributeNames: { "#sk": "sk", "#created": "created" },
								ExpressionAttributeValues: { ":sk": "info", ":n": updatePeriod(), ":groupType" : "clinic" },
								ProjectionExpression: "sk"
							}
						}
					}} />
				</div>
				{(process.env.REACT_APP_COMPANY === 'ray' || !process.env.REACT_APP_COMPANY) && <div className='col'>
					<RMWidgetSmallCount options={{ icon : "building-community", color : "info", cacehname : "all-glab-" + period, title : t("신규 기공소"),
						filter : {
							type : "scan",
							region : "ap-northeast-2",
							params : {
								TableName: "rayteams-group",
								FilterExpression: "#sk=:sk and #created > :n and contains(grouptype, :groupType)",
								ExpressionAttributeNames: { "#sk": "sk", "#created": "created" },
								ExpressionAttributeValues: { ":sk": "info", ":n": updatePeriod(), ":groupType" : "lab" },
								ProjectionExpression: "sk"
							}
						}
					}} />
				</div>}
				<div className='col'>
					<RMWidgetSmallCount options={{ icon : "building", color : "info", cacehname : "all-gother-" + period, title : t("신규 기타"),
						filter : {
							type : "scan",
							region : "ap-northeast-2",
							params : {
								TableName: "rayteams-group",
								FilterExpression: "#sk=:sk and #created > :n and contains(grouptype, :groupType)",
								ExpressionAttributeNames: { "#sk": "sk", "#created": "created" },
								ExpressionAttributeValues: { ":sk": "info", ":n": updatePeriod(), ":groupType" : "other" },
								ProjectionExpression: "sk"
							}
						}
					}} />
				</div>
			</div>
			<div className='row'>
				<div className='col'>
					<RMWidgetSmallCount options={{ icon : "device-desktop", color : "success", cacehname : "all-device-" + period, title : t("팀즈 실행수"),
						filter : {
							type : "scan",
							region : "ap-northeast-2",
							params : {
								TableName: "rayteams-info",
								FilterExpression: "#sk=:sk and #updated > :n",
								ExpressionAttributeNames: { "#sk": "sk", "#updated": "updated" },
								ExpressionAttributeValues: { ":sk": "info", ":n": updatePeriod() },
								ProjectionExpression: "sk"
							}
						}
					}} />
				</div>
				<div className='col'>
					<RMWidgetSmallCount options={{ icon : "hand-click", color : "success", cacehname : "all-sub-" + period, title : t("신규 구독"),
						filter : {
							type : "scan",
							region : "ap-northeast-2",
							params : {
								TableName: "rayteams-user",
								FilterExpression: "begins_with(#sk, :sk) and #started > :n",
								ExpressionAttributeNames: { "#sk": "sk", "#started": "started" },
								ExpressionAttributeValues: { ":sk": "product:", ":n": updatePeriod() },
								ProjectionExpression: "sk"
							}
						}
					}} />
				</div>
				<div className='col'>
					<RMWidgetSmallCount options={{ icon : "currency-dollar", color : "success", sum : true, iscurrency : true, sumkey : "realprice", cacehname : "all-pay-" + period, title : t("신규 매출"),
						filter : {
							type : "scan",
							region : "ap-northeast-2",
							params : {
								TableName: "rayteams-user",
								FilterExpression: "begins_with(#sk, :sk) and #when > :n",
								ExpressionAttributeNames: { "#sk": "sk", "#when": "when" },
								ExpressionAttributeValues: { ":sk": "paylog:", ":n": updatePeriod() },
								ProjectionExpression: "sk, realprice, punit"
							}
						}
					}} />
				</div>
				{(process.env.REACT_APP_COMPANY === 'ray' || !process.env.REACT_APP_COMPANY) && <>
					<div className='col'>
					</div>
					<div className='col'>
				</div>
				</>}
			</div>
		</div>
	</div>
}

export default RMCountPanel;
