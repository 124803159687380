import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { MLButton } from '../../components/common/MLButton';
import MLFilter, { CreateScanFilter } from '../../components/common/MLFilter';
import MLFilterList from '../../components/common/MLFilterList';
import { MLText } from '../../components/common/MLInput';
import { MLPanelLoading } from '../../components/common/MLPanel';
import MLTable from '../../components/common/MLTable';
import UserChart from '../../components/user/UserChart';
import UserStatCard from '../../components/user/UserStatCard';
import { COUNTRIES } from '../../constants/Country';
import { fetchData } from '../../libs/db';
import { InfoAction } from '../../redux/actions';
import { ProgramConstant } from '../../redux/reducers';
import { AuthConstant } from '../../redux/reducers/AuthReducer';
import UsersExportWrapper from './UsersExportWrapper';

const UsersPage = ({ groups, region, regionusers, loginuser, pagestat, page, filters, config, allgroups }) => {

	const [t] = useTranslation(['translation']);
	const dispatch = useDispatch();
	const [more, setMore] = useState(true);
	const [search, setSearch] = useState(pagestat?.search || "");
	const [filter, setFilter] = useState(pagestat?.filter || [{ code: "countryCode", value: "", list: [] }]);
	const [items, setItems] = useState([]);
	const [loading, setLoading] = useState(false);
	const [fs] = useState([]);
	const [showFilter, setShowFilter] = useState(false);
	const mgroup = useMemo(() => {
		try { // localStorage는 유저가 수정할 수 있음으로 JSON.parse를 사용하는 경우 오류가 발생하면 서비스 자체에 문제가 생김으로 예외식 추가
			const _mgroup = JSON.parse(localStorage.getItem("mgroup"));
			return _mgroup;
		} catch (error) {
			return {};
		}
	}, []);

	useEffect(() => {
		dispatch({
			type: ProgramConstant.SET_PAGE, page: {
				code: "users",
				view: "summary",
				title: "Users",
				small: t("RAYTeams 사용자 관련 정보"),
				options: [
					{ view: "summary" },
					{ view: "list" }
				]
			}
		})
		getData();
	}, []);
	useEffect(() => {
		if (groups) {
			getData();
		}
	}, [filters, groups]);

	useEffect(() => {
		dispatch(InfoAction.GetUserFilters("users"));
		if (page.view)
			setMore(page.view === "list");
	}, [page]);

	useEffect(() => {
		if (regionusers)
			genFilter();
	}, [regionusers]);

	useEffect(() => {
		if (regionusers) {
			genData();
			dispatch({
				type: ProgramConstant.PAGESTAT, item: {
					userlist: {
						search, filter,
						order: pagestat.order,
						desc: pagestat.desc
					}
				}
			});
		}
	}, [search, filter, regionusers, pagestat.order, pagestat.desc])

	const headers = useMemo(() => {
		const hideUser = typeof (config?.showalludata) === "boolean" ? !config?.showalludata : true;
		let _headers = [
			{ value: "_id", label: "Name/Email", type: "USER", hide: hideUser, css: "text-start", style: { width: 400 } },
			{ link: "groupId", linkpath: "/GroupInfo", value: "groupname", label: "Group", css: "text-start", style: { width: 250 } },
			{ value: "userType", label: "Type", type: "USERTYPE" },
			{ value: "countryCode", label: "Country", type: "COUNTRY" },
			{ value: "approved", label: "Approved", type: "APPROVED" },
			{ value: "created", label: "Registered", css: "text-end", type: "DATE" },
			{ value: "lastlogged", label: "Last Logged", css: "text-end", type: "DATE" },
		];
		if (!config?.approvedaccountonly) {
			_headers = _headers.filter(x => x.value !== "approved");
		}
		return _headers;
	}, [config?.approvedaccountonly, config?.showalludata]);

	// country 선택
	const getData = async () => {
		// 총 사용자 숫자, 
		// 그룹별 사용자 숫자, 
		// User type 별 사용자
		setLoading(true);
		let ScanFilter = {
			sk: {
				ComparisonOperator: "EQ",
				AttributeValueList: ["info"],
			}
		}
		if (mgroup?.countries?.length > 0) {
			ScanFilter = {
				...ScanFilter,
				countryCode: {
					ComparisonOperator: "IN",
					AttributeValueList: mgroup.countries?.map(x => x.countryCode)
				}
			}
		}
		const pageFilter = filters.find(x => x.page === page.code);
		if (pageFilter) {
			pageFilter.fs.map(x => CreateScanFilter(ScanFilter, x));
		}
		try {
			const ret = await fetchData("rayteams-user", ScanFilter);
			var list = [];
			ret.Items.map(x => {
				const g = groups?.find(y => y._id === x.groupId);
				if (g?.name) {
					list.push({
						...x,
						grouptype: Array.isArray(g.grouptype) ? g.grouptype : [],
						groupname: g.name,
						region: g.region,
						countryCode: g.countryCode,
					})
				} else {
					// console.log("undefined", x);
				}
			})
			if (process.env.REACT_APP_COMPANY !== 'ray') {
				dispatch({ type: AuthConstant.GET_REGION_USERS, items: list.filter(x => x.grouptype?.includes('clinic')) });
			} else {
				dispatch({ type: AuthConstant.GET_REGION_USERS, items: list });
			}
		} catch (e) {
			console.log("e", e);
			dispatch({ type: AuthConstant.GET_REGION_USERS, items: [] });
		}
		setLoading(false);
	}

	const fLabel = (code, value) => {
		if (value === true || value === false) {
			return value === true ? "Active" : "Expired";
		} else if (code === "countryCode") {
			return COUNTRIES.find(x => x.countryCode === value)?.name || "UNKNOWN";
		}
		return value;
	}

	const genFilter = () => {
		setFilter(filter.map(x => {
			const list = [...new Set(regionusers.map(m => m[x.code]))];
			return {
				...x, list: [{ value: "", label: "ALL" }, ...list.map(n => ({
					value: n,
					label: fLabel(x.code, n)
				}))]
			}
		}));
	}

	const filterCheck = (item) => {
		var tf = true;
		filter.map(x => {
			if (tf)
				tf = (item[x.code] === x.value || x.value === "");
		});
		return tf;
	}

	const genData = () => {
		setItems(regionusers
			.filter(x => search ? (x.groupname.indexOf(search) > -1) : true)
			.filter(x => filterCheck(x))
			.sort((a, b) => pagestat.desc === "desc" ? (a[pagestat.order] > b[pagestat.order] ? -1 : 1) : (b[pagestat.order] > a[pagestat.order] ? -1 : 1))
		);
	}

	const headerClick = () => {
		dispatch({
			type: ProgramConstant.PAGESTAT, item: {
				userlist: {
					search, filter,
					order: pagestat.order,
					desc: (pagestat.desc === "desc" ? "asc" : "desc")
				}
			}
		});
	}

	return <div className='p-4'>
		{(process.env.REACT_APP_COMPANY === 'ray' || !process.env.REACT_APP_COMPANY) && <div className='mb-4'>
			<UserStatCard items={items} />
		</div>}
		{more && <UserChart items={items} />}
		{!more && <div className="card">
			<div className="card-header d-flex justify-content-between align-items-center">
				<div className="card-label fw-bold text-gray-800">
					{items && items.length}
				</div>
				<div className="d-flex align-items-center">
					<MLFilterList page={page} filter={fs} />
					<MLButton
						options={{
							label: t("Filter"),
							color: "primary",
							action: () => setShowFilter(!showFilter)
						}}
						css="me-1"
					/>
					<MLText
						options={{
							placeholder: t("검색어 입력"),
							size: "sm",
							value: search,
						}}
						handleChanged={(e) => setSearch(e.target.value)}
						css="mb-0"
					/>
					<UsersExportWrapper 
						list={items} 
						buttonProps={{ css: "ms-1" }}
					/>
				</div>
			</div>
			<MLFilter show={showFilter} page={page} callback={() => setShowFilter(false)} />
			{!loading && <MLTable
				headers={headers}
				items={items}
				headerClick={headerClick}
				order={pagestat?.order || "created"}
				desc={pagestat?.desc || "desc"}
				noheader={true} />}
			{loading && <MLPanelLoading />}
		</div>}
	</div>
}

const mapState = (state) => {
	const groups = state.AuthReducer.groups;
	const allgroups = state.AuthReducer.allgroups;
	const region = state.AuthReducer.region;
	const regionusers = state.AuthReducer.regionusers;
	const loginuser = state.AuthReducer.loginuser;
	const pagestat = state.ProgramReducer.pagestat["userlist"] || {};
	const page = state.ProgramReducer.page;
	const filters = state.ProgramReducer.filters;
	const config = state.ProgramReducer.config;
	return { groups, region, regionusers, loginuser, pagestat, page, filters, config, allgroups };
};

const mapDispatch = (dispatch) => ({

});

export default connect(mapState, null)(UsersPage);
