import { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { pkData, fetchData } from '../../libs/db';
import MLTable from '../../components/common/MLTable';
import { FaHome, FaPhoneAlt, FaWindowClose } from 'react-icons/fa';
import { RiCloseFill } from 'react-icons/ri';
import Devices from '../../components/partner/devices';
import { history } from '../../history';
import { MLButton } from '../../components/common/MLButton';
import { COLORS } from '../../constants/FromInputs';
import PartnerUserList from '../../components/partner/partneruserlist';
import CompDeviceInfo from '../../components/device/deviceinfo';

const RayteamsInfoPage = ({ alldevices }) => {

	const dispatch = useDispatch();
	const { _id, _tabname } = useParams("overview");

	const [t] = useTranslation(['translation']);
	const [ info, setInfo ] = useState({});
	const [ items, setItems ] = useState([]);
	const [ list, setList ] = useState([]);
	const [ process, setProcess ] = useState(false);
	const [ err, setErr ] = useState("");
	const [ edit, setEdit ] = useState(false);
	const [ search, setSearch ] = useState("");
	
	const [ related, setRelated ] = useState([]);

	useEffect(() => {
		if(_id){
			getitems(_id);
			setPage();
		}
	},[_id]);

	const setPage = () => {
		const item = alldevices.find(g => g.sn === _id);
		if(!item)
			return;
		// dispatch({ type : ProgramConstant.PAGE, item : {
		// 	label : <>{t("Device")} : {item.sn}</>,
		// 	caption : <>
		// 		<span className="me-2 fw-bolder">
		// 			{item.DeviceName}
		// 		</span>
		// 	</>
		// }});
	}

	const getitems = async (_id) => {
		setErr("");

		setProcess(true);

		const ret = await pkData('rayteams-device', "sn", _id);
		setItems(ret.Items);
		setInfo(ret.Items.find(x => x.sk === "info"));

		setProcess(false);
	}

  	return <>
        <div className="header align-items-stretch d-block" style={{position: "initial"}}>
            <div className="d-flex w-100 justify-content-between p-5 align-items-center">
                <div className="d-flex align-items-center text-dark fw-bolder fs-2 my-1">Device Info</div>
                <div className="d-flex align-items-center"></div>
            </div>
        </div>
		<div className="d-flex flex-column flex-column-fluid">
			<div className="post p-7">            	
                <CompDeviceInfo device={alldevices.find(x => x.sn === _id)} title={"Information"} />
            
            {/* <div className="card col-6 mb-5 shadow">
                <div className="card-header">
                    <div className="card-title">{t("Acqusitions")}</div>
                </div>
                <div className="card-body py-2 px-0">
                    ~
                </div>
            </div> */}
			</div>
		</div>
    </>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const allgroups =  state.ProgramReducer.allgroups;
	const alldevices =  state.ProgramReducer.alldevices;

	return { user, allgroups, alldevices };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, null)(RayteamsInfoPage);
