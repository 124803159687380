import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import MLTable from '../../common/MLTable';
import { MLPanelLoading } from '../../common/MLPanel';
import moment from 'moment';
import { ChartDays, ChartMonths } from '../../common/ChartDays';
import { SEARCHPERIOD } from '../../../constants/RMConstants';

const WidgetListAppUsage = ({ data, title, groupType, type, period, options = {}, appname, appfunc, stats }) => {
    const [widgetData, setWidgetData] = useState(null);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
		if(stats && period) {
			genData_Function(period, stats, type, groupType, appname);
		}
	}, [period, stats]);

    const headers = [
        { value: "_id", label: type, css: "text-start" },
        { value: "called", label: "Count", css: "text-start" },
    ]

    const headers_group = [
        { value: "_id", label: type, css: "text-start", type: "GROUP" },
        { value: "called", label: "Count", css: "text-start" },
    ]

    const genData_Function = async (period, data, type, groupType) => {
        const searchPeriodDay = moment().utc().format("YYYYMMDD");
		const searchwPeriodWeek = moment().add(-7, 'days').format("YYYYMMDD");
        const searchtwoWeek = moment().add(-14, 'days').format("YYYYMMDD");
		const searchPeriodMonth = moment().utc().format("YYYYM");
        var clist = [];
        if(period === "days") {
            if (appfunc){
                clist = data.filter(x => x?.sk?.split(":")[1] >= searchwPeriodWeek && x?.from?.toLowerCase().includes(appname != "" ? appname?.toLowerCase() : type.toLowerCase()));
            } else
                clist = data.filter(x => x?.sk?.split(":")[1] >= searchwPeriodWeek && x?._id?.toLowerCase().includes(appname != "" ? appname?.toLowerCase() : type.toLowerCase()));
        } else if (period === "weeks") {
            if (appfunc)
                clist = data.filter(x => x?.sk?.split(":")[1] <= searchPeriodDay && x?.sk?.split(":")[1] >= searchtwoWeek && x?.from?.toLowerCase().includes(appname != "" ? appname?.toLowerCase() : type.toLowerCase()));
            else
                clist = data.filter(x => x?.sk?.split(":")[1] <= searchPeriodDay && x?.sk?.split(":")[1] >= searchtwoWeek && x?._id?.toLowerCase().includes(appname != "" ? appname?.toLowerCase() : type.toLowerCase()));
        } else if (period === "month") {
            if (appfunc)
                clist = data.filter(x => x?.sk?.includes("M:" + searchPeriodMonth) && x?.from?.toLowerCase().includes(appname != "" ? appname?.toLowerCase() : type.toLowerCase()));
            else
                clist = data.filter(x => x?.sk?.includes("M:" + searchPeriodMonth) && x?._id?.toLowerCase().includes(appname != "" ? appname?.toLowerCase() : type.toLowerCase()));
        } else {
            if (appfunc)
                clist = data.filter(x => x?.sk?.includes("M:") && x?.from?.toLowerCase().includes(appname != "" ? appname?.toLowerCase() : type.toLowerCase()));
            else    
                clist = data.filter(x => x?.sk?.includes("M:") && x?._id?.toLowerCase().includes(appname != "" ? appname?.toLowerCase() : type.toLowerCase()));
        }

        var result = [];
        if (groupType == "Function") {
            clist.reduce(function(res, value) {
                if (!res[value._id]) {
                    res[value._id] = { _id: value._id, called: 0 };
                    result.push(res[value._id])
                }
                res[value._id].called += value.called;
                return res;
            }, {});
        } else if (groupType == "Country") {
            clist.reduce(function(res, value) {
                if (!res[value.sk.split(":")[2]]) {
                    res[value.sk.split(":")[2]] = { _id: value.sk.split(":")[2], called: 0 };
                    result.push(res[value.sk.split(":")[2]])
                }
                res[value.sk.split(":")[2]].called += value.called;
                return res;
            }, {});
        } else if (groupType == "Group") {
            clist.reduce(function(res, value) {
                if (!res[value.sk.split(":")[3]]) {
                    res[value.sk.split(":")[3]] = { _id: value.sk.split(":")[3], called: 0 };
                    result.push(res[value.sk.split(":")[3]])
                }
                res[value.sk.split(":")[3]].called += value.called;
                return res;
            }, {});
        }
        
        setWidgetData(result.sort((a, b) => a.called > b.called ? -1 : 1).slice(0, 5));
		setLoading(false);
	}

    return <> {!loading && <div className="card">
        <div className='card-header'>
            <h3 className='card-title'>
                <span className='card-label fs-5 fw-bolder'>{title}</span>
                <span></span>
            </h3>
            <div className='card-toolbar text-muted fs-6'>
                <span className='badge badge-secondary'>
                    {/* <RiProjectorLine style={{ width : 18, height : 18}} /> {projects.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} */}
                </span>
            </div>
        </div>
        
        <div className='pt-0 card-body'>
            {widgetData && <MLTable headers={groupType === "Group" ? headers_group : headers} items={widgetData} noheader={true} />}
        </div>
        </div>
        }
        {loading &&
        <><MLPanelLoading></MLPanelLoading></>}
    </>
}

const mapState = (state) => {
    const logs = state.UsageReducer.logs;
	const dailylogs =  state.UsageReducer.dailylogs;
	const selectedlogs = state.UsageReducer.selectedlogs;
	const stats = state.UsageReducer.stats;
	
	return { logs, dailylogs, stats, selectedlogs };
};

export default connect(mapState)(WidgetListAppUsage);