import React, { useEffect, useState } from 'react';
import { history } from '../../history';
import { queryData } from '../../libs/db';

const RMGroup = ({ groupId, readonly = false }) => {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if(groupId)
			getGroup();
	}, [groupId]);

	const getGroup = async () => {
		const queryFilter = {
			_id: {
				ComparisonOperator: "EQ",
				AttributeValueList: [groupId],
			},
			sk: {
				ComparisonOperator: "EQ",
				AttributeValueList: ["info"]
			}
		}
		const ret = await queryData("rayteams-group", queryFilter);
		if (ret.Count == 0 ) {
			const cnRet = await queryData("rayteams-group", queryFilter, "ap-east-1");
			setData(cnRet.Items[0]);
		} else {
			setData(ret.Items[0]);
		}
		
		setLoading(false);
	}

    return <>
		{!loading && !readonly && <>
			{data && <a className='raycur' onClick={() => history.push('/GroupInfo/' + groupId)}>{data.name}</a>}
			{!data && <span className='text-muted'>{groupId}</span>}
		</>}
		{!loading && readonly && <>
			{data && <span>{data.name}</span>}
			{!data && <span className='text-muted'>{groupId}</span>}
		</>}
		{loading && <span className='text-muted'>...</span>}
	</>
}

export default RMGroup;