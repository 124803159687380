const initialState = { 
    modalPayData : { show : false, paylog : {} },
	payments : null,
	subscriptions : null,
	paymentgateways : [],
	paymentgateway : {},
	paymentsExcelList : null,
};

export const PaymentConstant = {
	PAY_GET_ALL : "GET_ALL_PAYMENTS",
	SUB_GET_ALL : "GET_ALL_SUBSCRIPTIONS",
	PAY_LOG_MODAL : "PAY_LOG_MODAL",
	PG_GET_ALL : "GET_ALL_PG",
	PG_GET : "GET_PG",
	CREATE_PG : "CREATE_PG",
	UPDATE_PG : "UPDATE_PG",
	UPDATE_SUB : "UPDATE_SUBSCRIPTION",
};

export const PaymentReducer = (state = initialState, action) => {
	switch (action.type) {
		case PaymentConstant.PAY_GET_ALL:
			return {...state, payments : action.items, paymentsExcelList : action.excelItems };
		case PaymentConstant.SUB_GET_ALL:
			return {...state, subscriptions : action.items };
		case PaymentConstant.PAY_LOG_MODAL:
			return {...state, modalPayData : action.item };
		case PaymentConstant.PG_GET_ALL:
			return {...state, paymentgateways : action.items };
		case PaymentConstant.PG_GET:
			return {...state, paymentgateway : action.item };
		case PaymentConstant.CREATE_PG:
			return {...state,
				paymentgateway: action.item,
				paymentgateways: [...state.paymentgateways, action.item]
			};
		case PaymentConstant.UPDATE_PG:
			return {...state,
				paymentgateways: state.paymentgateways.map(x => (x._id === action.item._id && x.sk === action.item.sk) ? action.item : x)
			};
		case PaymentConstant.UPDATE_SUB:
			return {
				...state,
				subscriptions: state.subscriptions.map((x) => (x._id === action.item._id && x.prdid === action.item.prdid) ? {...x, ...action.item} : x)
			};
		default:
			return state;
	}
};
