import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import { GrClose } from 'react-icons/gr';
import { connect } from 'react-redux';
import { MLButton } from '../common/MLButton';
import ProductTitle from '../product/ProductTitle';
import User from '../common/MLUser';
import { COLORS } from '../../constants/FromInputs';
import { MLPanelLoading } from '../common/MLPanel';
import html2pdf from 'html2pdf.js';
import { PRICEUNIT } from '../../constants/RMConstants';
import { convertToKoreanNumber } from '../common/MLString';
import { useTranslation } from 'react-i18next';
import { RTAudit } from '../../libs/audit';
import { XName } from '../common/MLString';
import { COUNTRIES } from '../../constants/Country';

const ModalPayInfo = ({ modalData, paylog, CloseModal, sizecss = "modal-fullscreen", allusers, allgroups }) => {
    const [t] = useTranslation(['translation']);
    const [data, setData] = useState({ productname : "" });
    const [ loading, setLoading ] = useState(false);
    const [ viewInvoice, setViewInvoice ] = useState(true);

    const userInfo = allusers && allusers.find(f => f._id === paylog?._id)
    const userGroupInfo = userInfo && allgroups && allgroups.find(g => g._id === userInfo.groupId);
    const userPUNIT = paylog && PRICEUNIT.find(f => f.value === paylog?.punit)?.label;

    const exportPDF = async () => {
        setLoading(true);
        let generatorPdf = document.getElementsByClassName(`pr-invoice-${paylog?.pg}`)[0];
        const opt = {
            margin: 0.4,
            filename: `PR-${paylog.when}`,
            image: { type: 'jpeg', quality: 0.98 }, // Exported image quality and format
            html2canvas: { scrollY: 0, scale: 3, useCORS: true }, // useCORS is very important to solve the problem of cross-domain images in the document
            jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait', useCORS: true },
        };
        console.log("generatorPdf : ======", generatorPdf);
        if (generatorPdf) {
            html2pdf().set(opt).from(generatorPdf).save().then(() => {
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
        await RTAudit({ lvl: 4, msg: 'Export Invoice PDF'});
    };

    const pSum = (realprice, per) => {
        let p = realprice * per/100
        return p;
    };

	return <Modal show={modalData.show} size={"lg"} style={{ backgroundColor: "#42476c !important"}}>
		<Modal.Header className='modal-header header-bg'>
			<h3>{"PR-" + paylog.when}
				<small className={"ms-2 fs-7 fw-normal opacity-50 "}>{moment.unix(paylog.when / 1000).format("yyyy-MM-DD HH:mm:ss")}</small>
			</h3>
                {loading && <MLPanelLoading contents={t("Invoice 를 다운로드 하고 있습니다.")} /> }
				{!loading && <MLButton
					options={{
                        color : COLORS.PRIMARY,
                        label : !viewInvoice ? t("View Invoice") : t("Export Pdf"),
                        action : () => !viewInvoice ? setViewInvoice(true) : exportPDF()
                    }}
					css={'me-1'} />
                }
		</Modal.Header>
		<Modal.Body className='flex-fill pt-0 formcard pt-6'>
            {!viewInvoice && <> <div>
                <table className="table">
                    <tr className="border border-3">
                        <th className="ps-2 bg-gray-100 fw-bolder w-200px">{t("Pay-user")}</th>
                        <td className=""><User _id={paylog.who} /></td>
                    </tr>
                    <tr className="border border-3">
                        <th className="ps-2 bg-gray-100 fw-bolder w-200px">{t("User")}</th>
                        <td className=""><User _id={paylog._id} /></td>
                    </tr>
                    <tr className="border border-3">
                        <th className="ps-2 bg-gray-100 fw-bolder">{t("Product")}</th>
                        <td className=""><ProductTitle info={paylog} /></td>
                    </tr>
                    <tr className="border border-3">
                        <th className="ps-2 bg-gray-100 fw-bolder">{t("Period")}</th>
                        <td className=""><div>{paylog.period}</div></td>
                    </tr>
                    <tr className="border border-3">
                        <th className="ps-2 bg-gray-100 fw-bolder">{t("Count")}</th>
                        <td className=""><div>{paylog.count}</div></td>
                    </tr>
                    <tr className="border border-3">
                        <th className="ps-2 bg-gray-100 fw-bolder">{t("Amount")}</th>
                        <td className=""><div>{paylog.realprice === 0 ? "Free" : paylog?.realprice?.toLocaleString()}</div></td>
                    </tr>
                    <tr className="border border-3">
                        <th className="ps-2 bg-gray-100 fw-bolder">{t("PG")}</th>
                        <td className=""><div>{paylog.pg === "RAY" ? "MANUAL" : paylog.pg}</div></td>
                    </tr>
                    <tr className="border border-3">
                        <th className="ps-2 bg-gray-100 fw-bolder">{t("Currency")}</th>
                        <td className=""><div>{paylog.punit}</div></td>
                    </tr>
                    <tr className="border border-3">
                        <th className="ps-2 bg-gray-100 fw-bolder">{t("Paydate")}</th>
                        <td className="">
                            <div>
                                {moment.unix(paylog.when / 1000).format("yyyy-MM-DD HH:mm:ss")}
                                <span className="text-muted">({moment.unix(paylog.when / 1000).fromNow()})</span>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
			</>}
            {viewInvoice && paylog?.pg === "toss" && <>
                <div className="pr-invoice-toss" style={{ fontSize: '15px', border: '1px solid #000' }}>
                    <div style={{ padding: '20px'}}>
                        <div style={{ paddingBottom: '40px'}}>
                            <div style={{ paddingTop: '20px', fontWeight: 'bold', width: '100%', textAlign: 'center', fontSize: '30px' }} >
                                {t("거래명세서(공급받는자용)")}
                            </div>
                            <img src='/assets/img/img_wm.png' style={{ width: '90px', height: 'auto', position: 'absolute', 'right': 50, 'top': 30 }}></img>
                        </div>
                        <div>
                            <table>
                                <tbody style={{textAlign: 'center'}}>
                                    <tr style={{ borderLeft: '2px solid #000', borderRight: '2px solid #000' }}>
                                        <td rowSpan={4} style={{ width: '5%', borderTop: '2px solid #000', borderBottom: '2px solid #000', rowSpan: '4', textAlign: 'center'}}>공<br></br>급<br></br>자<br></br></td>
                                        <td style={{ borderLeft: '1px solid #000', borderRight: '1px solid #000' , borderTop: '2px solid #000'}}>등록<br></br>번호</td>
                                        <td colSpan={3} style={{ borderTop: '2px solid #000' }}>135 - 81 - 73282</td>
                                        <td style={{ borderLeft: '2px solid #000', borderRight: '2px solid #000', borderTop: '0px' }}>
                                        </td>
                                        <td rowSpan={4} style={{ width: '5%', borderTop: '2px solid #000', borderBottom: '2px solid #000', rowSpan: '4', textAlign: 'center'}}>공<br></br>급<br></br>받<br></br>는<br></br>자<br></br></td>
                                        <td style={{ borderTop: '2px solid #000', borderLeft: '1px solid #000' }}>등록<br></br>번호</td>
                                        <td colSpan={3} style={{ borderTop: '2px solid #000', borderLeft: '1px solid #000' }}></td>
                                    </tr>
                                    <tr style={{ borderLeft: '2px solid #000', borderRight: '2px solid #000' }}>
                                        <td style={{ border: '1px solid #000'}}>상호<br></br>(법인명)</td>
                                        <td style={{ border: '1px solid #000'}}>(주) 레이 </td>
                                        <td style={{ border: '1px solid #000'}}>성<br></br>명</td>
                                        <td style={{ border: '1px solid #000'}}>이상철</td>
                                        <td style={{ borderLeft: '2px solid #000', borderRight: '2px solid #000' }}>
                                        </td>
                                        <td style={{ border: '1px solid #000'}}>상호<br></br>(법인명)</td>
                                        <td style={{ border: '1px solid #000'}}>{userGroupInfo?.name}</td>
                                        <td style={{ border: '1px solid #000'}}>성<br></br>명</td>
                                        <td style={{ border: '1px solid #000'}}>{XName(userInfo?.name)}</td>
                                    </tr>
                                    <tr style={{ borderLeft: '2px solid #000', borderRight: '2px solid #000' }}>
                                        <td style={{ border: '1px solid #000'}}>사업장<br></br>주소</td>
                                        <td colSpan={3} style={{ borderTop: '2px solid #000', border: '1px solid #000' }}>경기도 화성시 삼성1로 332-7</td>
                                        <td style={{ borderLeft: '2px solid #000', borderRight: '2px solid #000' }}>
                                        </td>
                                        <td style={{ border: '1px solid #000'}}>사업장<br></br>주소</td>
                                        <td colSpan={3} style={{ borderTop: '2px solid #000', border: '1px solid #000' }}>{XName(userGroupInfo?.address)}</td>
                                    </tr>
                                    <tr style={{ borderLeft: '2px solid #000', borderRight: '2px solid #000' }}>
                                        <td style={{ width: '10%', borderBottom: '2px solid #000', borderLeft: '1px solid #000', borderReft: '1px solid #000'}}>업 태</td>
                                        <td style={{ width: '14%', borderBottom: '2px solid #000', borderLeft: '1px solid #000', borderReft: '1px solid #000'}}>제조, 서비스업</td>
                                        <td style={{ width: '5%', borderBottom: '2px solid #000', borderLeft: '1px solid #000', borderReft: '1px solid #000'}}>종<br></br>목</td>
                                        <td style={{ width: '17%', borderBottom: '2px solid #000', borderLeft: '1px solid #000', borderReft: '1px solid #000'}}>전자의료기기제조<br></br>개발 및 공급업</td>
                                        <td style={{ borderLeft: '2px solid #000', borderRight: '2px solid #000' }}>
                                        </td>
                                        <td style={{ width: '10%', borderBottom: '2px solid #000', borderLeft: '1px solid #000', borderReft: '1px solid #000'}}>업 태</td>
                                        <td style={{ width: '14%', borderBottom: '2px solid #000', borderLeft: '1px solid #000', borderReft: '1px solid #000'}}></td>
                                        <td style={{ width: '5%', borderBottom: '2px solid #000', borderLeft: '1px solid #000', borderReft: '1px solid #000'}}>종<br></br>목</td>
                                        <td style={{ width: '17%', borderBottom: '2px solid #000', borderLeft: '1px solid #000', borderReft: '1px solid #000'}}></td>
                                    </tr>
                                </tbody>
                            </table>
                            <br></br>
                            <table style={{width: '100%'}}>
                                <tbody>
                                    <tr style={{ border: '1px solid #000' }}>
                                        <td >금 액:</td>
                                        <td >일금</td>
                                        <td style={{fontWeight: 'bold'}}>{convertToKoreanNumber(paylog?.realprice || 0)}</td>
                                        <td>원정</td>
                                        <td>(부가세 포함)</td>
                                        <td>{userPUNIT}</td>
                                        <td style={{ textAlign: 'center', width: '20%'}}>{paylog?.realprice?.toLocaleString() || 0}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <br></br>
                            <table style={{width: '100%'}}>
                                <thead>
                                    <tr style={{ textAlign: 'center', backgroundColor: 'lightgray', borderTop: '2px solid #000', borderLeft: '2px solid #000', borderRight: '2px solid #000', borderBottom: '1px double #000' }}>
                                        <td style={{ borderLeft: '1px solid #000' }}>No</td>
                                        <td style={{ borderLeft: '1px solid #000' }}>월</td>
                                        <td style={{ borderLeft: '1px solid #000' }}>일</td>
                                        <td style={{ borderLeft: '1px solid #000' }}>품 목</td>
                                        <td style={{ borderLeft: '1px solid #000' }}>규 격</td>
                                        <td style={{ borderLeft: '1px solid #000' }}>수 량</td>
                                        <td style={{ borderLeft: '1px solid #000' }}>단 가</td>
                                        <td style={{ borderLeft: '1px solid #000' }}>공급가액</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr style={{ borderLeft: '2px solid #000', borderRight: '2px solid #000', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                        <td style={{ borderLeft: '1px solid #000' }}>1</td>
                                        <td style={{ borderLeft: '1px solid #000' }}>{moment.unix(paylog.when / 1000).format("M")}</td>
                                        <td style={{ borderLeft: '1px solid #000' }}>{moment.unix(paylog.when / 1000).format("D")}</td>
                                        <td style={{ borderLeft: '1px solid #000' }}>{paylog?.title}</td>
                                        <td style={{ borderLeft: '1px solid #000' }}>ea</td>
                                        <td style={{ borderLeft: '1px solid #000' }}>{paylog?.count}</td>
                                        <td style={{ borderLeft: '1px solid #000' }}>{userPUNIT} {((paylog?.realprice || 0) / 1.10).toLocaleString()}</td>
                                        <td style={{ borderLeft: '1px solid #000' }}>{userPUNIT} {((paylog?.realprice || 0) / 1.10).toLocaleString()}</td>
                                    </tr>
                                    <tr style={{ textAlign: 'center', borderLeft: '2px solid #000', borderRight: '2px solid #000', borderBottom: '1px solid #000' }}>
                                        <td colSpan={5} style={{ backgroundColor: 'lightgray', borderRight: '1px solid #000' }}>공급 가액</td>
                                        <td>{userPUNIT}</td>
                                        <td colSpan={2}>{((paylog?.realprice || 0) / 1.10).toLocaleString()}</td>
                                    </tr>
                                    <tr style={{ textAlign: 'center', borderLeft: '2px solid #000', borderRight: '2px solid #000', borderBottom: '1px solid #000'  }}>
                                        <td colSpan={5} style={{ backgroundColor: 'lightgray', borderRight: '1px solid #000'  }}>부 가 세</td>
                                        <td>{userPUNIT}</td>
                                        <td colSpan={2}>{((paylog?.realprice || 0) - ((paylog?.realprice || 0) / 1.10)).toLocaleString()}</td>
                                    </tr>
                                    <tr style={{ textAlign: 'center', borderLeft: '2px solid #000', borderRight: '2px solid #000', borderBottom: '2px solid #000' }}>
                                        <td colSpan={5} style={{ backgroundColor: 'lightgray', borderRight: '1px solid #000'  }}>합계 금액</td>
                                        <td>{userPUNIT}</td>
                                        <td colSpan={2}>{((paylog?.realprice || 0)).toLocaleString()}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </>}
            {viewInvoice && paylog?.pg === "paypal" && <>
                <div className='pr-invoice-paypal' style={{ fontSize: '15px', border: '1px solid lightgray' }}>
                    <div style={{ padding: '20px'}}>
                        <div style={{ paddingBottom: '40px'}}>
                            <img src='/assets/img/Ray_CI_logotype.png' style={{ width: '90px', height: 'auto', position: 'absolute', 'left': 50, 'top': 50 }}></img>
                            <div style={{ paddingTop: '20px', fontWeight: 'bold', width: '100%', textAlign: 'right', fontSize: '13px'}} >
                                Invoice No : {"PR-" + paylog.when}<br></br>
                                Payment Date : {moment.unix(paylog.when / 1000).format("yyyy-MM-DD HH:mm:ss")}<br></br>
                                Service Duration : {moment.unix(paylog.when / 1000).format("yyyy-MM-DD")} ~ {moment.unix((paylog.when + (30 * 1000 * 60 * 60 * 24)) / 1000).format("yyyy-MM-DD")}<br></br>
                                Country : {COUNTRIES.find(c => c.countryCode == userInfo?.countryCode)?.name || ""}
                            </div>
                        </div>
                        <div style={{ marginTop: '40px', paddingTop: '10px', borderTop: "2px solid lightgray"}}>
                            <table style={{width: '100%'}}>
                                <thead>
                                    <th style={{width: '50%', fontSize: '16px'}}>
                                       From
                                    </th>
                                    <th style={{width: '50%', fontSize: '16px'}}>
                                        To
                                    </th>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Ray Co., Ltd.</td>
                                        <td>{XName(userGroupInfo?.name)}</td>
                                    </tr>
                                    <tr>
                                        <td>12F, 221, Pangyoyeok-ro, Bundang-gu,</td>
                                        <td>{XName(userGroupInfo?.address)}</td>
                                    </tr>
                                    <tr>
                                        <td>Seongnam-si, Gyeonggi-do, 13494,<br></br> Republic of Korea</td>
                                        <td>{XName(userInfo?.name)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div style={{ marginTop: '40px', paddingTop: '10px', borderTop: "2px solid lightgray"}}>
                            <table style={{width: '100%'}}>
                                <thead style={{fontSize: '14px'}}>
                                    <th>DESCRIPTION OF GOODS</th>
                                    <th style={{textAlign: 'right'}}>QUANTITY</th>
                                    <th style={{textAlign: 'right'}}>PRICE</th>
                                    <th style={{textAlign: 'right'}}>AMOUNT({paylog?.punit})</th>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{paylog?.title}</td>
                                        <td style={{textAlign: 'right'}}>{paylog?.count}</td>
                                        <td style={{textAlign: 'right'}} >{userPUNIT} {paylog?.realprice?.toLocaleString()}</td>
                                        <td style={{textAlign: 'right'}}>{userPUNIT} {paylog?.realprice?.toLocaleString()}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </>}
            <div className='mt-4 text-end'>
				<MLButton
					options={{
                        color : COLORS.DEFAULT,
                        label : viewInvoice ? t("Close Invoice") : t("Close"),
                        action: () => CloseModal()
                        //action : () => viewInvoice ? setViewInvoice(false) : CloseModal()
                    }}
					css={'me-1'} />
			</div>
		</Modal.Body>
	</Modal>
}

const mapState = (state) => {
    const allusers =  state.ProgramReducer.allusers || [];
    const allgroups = state.AuthReducer.groups || [];
	return { allusers, allgroups };
};

const mapDispatch = dispatch => ({
})

export default connect(mapState, mapDispatch)(ModalPayInfo);
