import axios from 'axios';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { MLButton } from '../common/MLButton';
import MLTable from '../common/MLTable';
import { COLORS, SETTING } from '../../constants/FromInputs';
import { ProductAction, WFAction } from '../../redux/actions';
import { AuthConstant, ProductConstant, WFConstant } from '../../redux/reducers';

import { history } from '../../history';
import { JWT } from '../../config';
import { MLPanelLoading } from '../common/MLPanel';
import RMModalAddProductDetail from './RMModalAddProductDetail';
import moment from 'moment';
import { fetchData } from '../../libs/db';

const RMProductDetailList = ({ user, product, products, workflows }) => {

	const dispatch = useDispatch();
	const { _id } = useParams();
	const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState({
		show : false,
        _id : "",
        productname: ""
	});
    const [productInfo, setProductInfo] = useState({ _id: "", productname: "" });

	useEffect(() => {
        getWF();
	}, []);

	useEffect(() => {
        if(products) {
            setModal({ _id : _id, productname : products.filter((x) => x._id === _id)[0].productname });
        }
	}, [_id]);

	const getWF = async () => {
		try{
			//const ret = await axios.get(WFAction.getAPI() + "/workflow", JWT());
			const ret = await fetchData("rayteams-manager-workflow", {} );
			dispatch({ type: WFConstant.GETALL, items: ret.Items });
		}catch(err){
			console.log(err);
		}
	}

    const headers = [
        { value : "ispublished", label : "Pub", type : "TFVALUE", css : "text-center", style : { width : 80 } },
		{ link : ["_id", "sk"], linkpath : "/ProductDetail", value : "productname", label : "Product Name", css : "text-start" },
        { value : "workflowId", label : "workflow", type : 'WORKFLOW', style : { width : 200 } },
        { value : "price", label : "Price", style : { width : 200 } },
        { value : "currency", label : "Currency", style : { width : 200 } },
        { value : "discount", label : "Discount", style : { width : 200 } },
        { value : "created", label : "Created", type : "DATE", css : "text-start", style : { width : 200 } },
        { value : "updated", label : "Updated", type : "DATE", css : "text-start", style : { width : 200 } },
    ]
	console.log(workflows);

  	return (
    	<div>
		{!loading && <>
        
            <div className='card mb-5'>
                <div className='card-body'>
                    <div className='d-flex justify-content-between'>
                        <h2 className='mb-4'>
                            {modal.productname}
                        </h2>
                        <div className='text-end'>
                            <div className='ms-2 text-muted'> Registered : {moment(product?.created).format('LL')}</div>
                            <div className='ms-2 text-muted'> product Id : {modal?._id}</div>
                        </div>
                    </div>
                </div>
            </div>


			<div className='d-flex justify-content-end mb-2'>
				<MLButton options={{
					label : 'Product Detail 등록',
					color : COLORS.PRIMARY,
					size : 'sm',
					action : () => setModal({...modal,  show : true }),
				}} />
			</div>
			<MLTable 
				headers={headers} 
				wfdata={workflows}
				items={products && products.filter((i) => i._id === _id ).filter((x) => x.sk.includes('cid:'))} noheader={true} />
            <RMModalAddProductDetail 
                modalData={modal} 
                callbackClose={() => setModal({...modal, show : false })} />
		</>}
		{loading && <MLPanelLoading />}
    	</div>
  	);
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const region =  state.AuthReducer.region;
    const product = state.ProductReducer.product;
    const products = state.ProductReducer.products;
	const workflows = state.WFReducer.items;
	return { region, user, product, products, workflows };
};

const mapDispatch = (dispatch) => ({
});

export default connect(mapState, mapDispatch)(RMProductDetailList);