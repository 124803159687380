import axios from 'axios';
import { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { history } from '../../history';
import { InfoAction, ProductAction, WFAction } from '../../redux/actions';
import { GetConfig } from '../../libs/appConfig';
import { BUTTONS, COLORS, INPUTS, SPAN } from '../../constants/FromInputs';
import { ProgramConstant } from '../../redux/reducers';
import { MLForm } from '../../components/common/MLForm';
import { PRICEUNIT } from '../../constants/RMConstants';
import LabProductAdd from '../../components/labproduct/LabProductAdd';
import { useTranslation } from 'react-i18next';
const LabProductAddPage = ({ }) => {
	const dispatch = useDispatch();
	const [t] = useTranslation(['translation']);
	const { _id } = useParams("");

	useEffect(() => {
		dispatch({ type : ProgramConstant.SET_PAGE, page : { 
			code : "b2bproduct", 
			view : "summary", 
			title : "Add Product", 
			small : t("Product 생성"), 
			options : [
			]
		}})
	}, []);

  	return <div className='p-4'>
		<LabProductAdd />
  	</div>
}

const mapState = (state) => {
	return {  };
};

const mapDispatch = (dispatch) => ({

});

export default connect(mapState, null)(LabProductAddPage);
