import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import RMAdminInfo from '../../components/admin/RMAdminInfo';

const ManagerUserInfoPage = () => {
    const { _id } = useParams();
    return (
        <div>
            <RMAdminInfo userid={_id}></RMAdminInfo>
        </div>
    )
}

const mapState = (state) => {
	return { };
};

export default connect(mapState, null)(ManagerUserInfoPage);