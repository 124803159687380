import { AppConfig } from "../../config";

const initialState = {
	licenses : [],
	items : [],
	item: {},
	apps: [],
	app: [],
	status : "",
	managedApps : [],
	managedApp : {},
};

//manager-license
export const getAPI = (type) =>{
	const env = location.href.indexOf("localhost") > -1 ? "-development" : "";
	if(type){
		return "https://api-" + type + env + ".rayteams.com";
	}else{
		return AppConfig().info;
		return "https://api-teams-info" + env + ".rayteams.com";
	}
};

export const AppCategoryConstant = {
	GET_ALL_LICENSE : "GET_ALL_LICENSE",
	GET_ALL_APP : "GET_ALL_APP",
	ADD_APP : "ADD_APP",
	DEL_APP : "DEL_APP",
	UPDATE_APP : "UPDATE_APP",
	CREATE_APP : "CREATE_APP",
	GET_APP : "GET_APP",
	REGIST_APP : "REGIST_APP",
	CREATE_APP_LICENSE : "CREATE_APP_LICENSE",
	GET_ALL_MANAGED_APP : "GET_ALL_MANAGED_APP",
	GET_MANAGED_APP : "GET_MANAGED_APP",
	CREATE_MANAGED_APP : "CREATE_MANAGED_APP",
	GET_MANAGED_APP_PRODUCT : "GET_MANAGED_APP_PRODUCT"
};

export const AppCategoryReducer = (state = initialState, action) => {
	switch (action.type) {
		case AppCategoryConstant.GET_ALL_APP :
			return {...state, apps : action.items };
		case AppCategoryConstant.GET_ALL_LICENSE :
			return {...state, licenses : action.items };
		case AppCategoryConstant.GET_APP :
			return {...state, app : action.items };
		case AppCategoryConstant.CREATE_APP : 
			return {...state, 
				apps : [...state.apps, action.item]
			};
		case AppCategoryConstant.REGIST_APP : 
			return {...state, apps : action.items };
		case AppCategoryConstant.UPDATE_APP :
			return {...state, app : action.items };
		case AppCategoryConstant.CREATE_APP_LICENSE :
			return {...state, 
				license : action.item,
				licenses : [...state.licenses, action.item]
			};
		case AppCategoryConstant.GET_MANAGED_APP :
			return {...state, managedApp : action.item };
		case AppCategoryConstant.GET_MANAGED_APP_PRODUCT :
			return { ...state, managedApp: {product : [...state.managedApp.product, action.items]}};
			//return { ...state, group: {...state.group, extra : [...state.group.extra, action.item]}};
		case AppCategoryConstant.GET_ALL_MANAGED_APP : 
			return {...state, managedApps : action.items };
		case AppCategoryConstant.CREATE_MANAGED_APP :
			return {...state, managedApps : [...state.managedApps, action.item] };
		default:
			return state;
	}
};