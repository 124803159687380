import axios from 'axios';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import RMModalAddBoard from '../components/board/RMModalAddBoard';
import { MLButton } from '../components/common/MLButton';
import { MLText } from '../components/common/MLInput';
import { MLPanelLoading, MLPanelNoData } from '../components/common/MLPanel'
import MLTable from '../components/common/MLTable';
import { JWT } from '../config';
import { COLORS, SETTING } from '../constants/FromInputs';
import { history } from '../history';
import { BoardAction } from '../redux/actions';
import { ProgramConstant, BoardConstant } from '../redux/reducers';
import { fetchData } from '../libs/db';
import { RMIconChart, RMIconList  } from '../components/icon/MLIcon';
import { callDB } from '../libs/db';
import { useTranslation } from 'react-i18next';
const BoardPage = ({ user, region, boards, page }) => {
	const dispatch = useDispatch();
	const [t] = useTranslation(['translation']);
	const [loading, setLoading] = useState(true);
	const [search, setSearch] = useState("");
	const [modal, setModal] = useState({
		show : false
	});
	const [items, setItems] = useState([]);

	useEffect(() => {
		dispatch({ type : ProgramConstant.SET_PAGE, page : { 
			code : "contentsmanager", 
			view : "summary", 
			title : "CMS", 
			small : t("Content Management System"), 
			options : [
			]
		}})
	}, []);

	useEffect(() => {
        getData();
	}, []);

	useEffect(() => {
		genData();
	}, [search, boards]);

    const getData = async () => {
		setLoading(true);
		try{
			const ret = await callDB({
				type : "scan",
				region : "ap-northeast-2",
				params : {
					TableName: "rayteams-manager-boards",
					FilterExpression: "#sk = :sk",
					ExpressionAttributeNames: { "#sk": "sk" },
					ExpressionAttributeValues: { ":sk": "info" }
				},
				filter : {}
			});
			dispatch({ type: BoardConstant.GET_ALL_BOARDS, items: ret });
		}catch(err){
			console.log(err);
		}
		setLoading(false);
    }

	const genData = () => {
		setItems(search ? boards.filter(x => x?.title.indexOf(search) > -1) : (boards || []));
	}

    const headers = [
        { link : "_id", linkpath : "/Board", value : "title", label : t("Title"), css : "text-start", style : { width : 450 } },
		{ value : "type", label : t("Type"), css : "text-end", type : "BOARDTYPES" }
    ]

	console.log(items);

  	return <div className='p-4'>
		<div className="card">
			<div className="card-header d-flex justify-content-between align-items-center">
				<div className="card-label fw-bold text-gray-800 fs-5">{items.length}</div>
				<div className="d-flex">
						<MLButton options={{
						code: "cm-addpool",
						label : t('Add pool'),
						color : COLORS.PRIMARY,
						size : 'md',
						action : () => setModal({ show : true }),
					}} />
				</div>
			</div>
			{!loading && <>
				{items.length > 0 && <>
					<MLTable 
						headers={headers} 
						items={items} 
						nocard={true}
						noheader={true} />
				</>}
				{items.length === 0 && <MLPanelNoData />}
			</>}
			{loading && <MLPanelLoading />}
        </div>
		<RMModalAddBoard 
			modalData={modal} 
			callbackClose={() => setModal({ show : false })} />
    </div>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const region =  state.AuthReducer.region;
	const boards =  state.BoardReducer.boards;
	const page = state.ProgramReducer.page;

	return { region, user, boards, page };
};

const mapDispatch = (dispatch) => ({
});

export default connect(mapState)(BoardPage);
