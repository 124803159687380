import { useCallback, useEffect, useState } from 'react';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { BUTTONS, COLORS, INPUTS, SPAN } from '../../constants/FromInputs';
import { MLButton } from '../common/MLButton';
import { MLForm } from '../common/MLForm';
import { FormPreview } from './FormPreview';
import { RTAudit } from '../../libs/audit';

export const MLFile = {
	TO: {
		MYLAB: 'MYLAB',
		RAY_CLOUD: 'RAYCLOUD'
	},
	UPTYPE: {
		MYLAB_PATIENTFILES: 'MYLAB-PATIENTFILES'
	},
	MEDIA: {
		IMPORT_TYPE: {
			INNER_BUTTON: 'inner_button',
			DROPDOWN: 'dropdown'
		}
	}
}

const FormRow = ({ codes, control, cancel, callback, isedit = false, controls }) => {

	const [t] = useTranslation(['translation']);
	const [codeTypes, setCodeTypes] = useState([]);
	const [uploadTypes, setUploadTypes] = useState([]);
	const [con, setCon] = useState([]);
	const [data, setData] = useState({});

	useEffect(() => {
		const nList = [...new Set(codes.map(x => x.category))];
		setCodeTypes(nList.map(x => {
			return { value: x, label: x }
		}));
	}, [codes])

	const makeCon = useCallback(() => {
		const tmpCon = control.type === INPUTS.GROUP ? [] : [
			{ type: INPUTS.TEXT, options: { name: "label", label: t("Label"), size: "sm", required: true } },
		];
		if (control.type === INPUTS.GROUP) {
			tmpCon.push({ type: INPUTS.TEXT, options: { name: "grouptitle", label: t("Group Title"), size: "sm" } });
			tmpCon.push({ type: INPUTS.COMBOMULTI, options: { name: "hidedv", label: t("Hide"), size: "sm", required: false, multiple : true, list: codes.filter(x => x.category === 'SRC_TYPE') || [] } });
			tmpCon.push({ type: INPUTS.TOGGLE, options: { name: "horizontal", label: t("Horizontal"), truemsg: "Yes", falsemsg: "No", value: false } });
		}else{
			if(controls.filter(x => x.type === INPUTS.GROUP).length > 0){
				tmpCon.push( {
					type: INPUTS.COMBO,
					options: { name: "groupList", label: "Group", size: "sm", 
						list: controls.filter(x => x.type === INPUTS.GROUP).map(x => ({ value : x.options.name, label : x.options.grouptitle })) }
				});
			}
		}
		if (control.type === INPUTS.COMBO) {
			tmpCon.push({ type: INPUTS.ADDITEMS, options: { name: "list", label: t("Items"), size: "sm", required: true, getKey: "list" } });
			tmpCon.push({ type: INPUTS.TOGGLE, options: { name: "multiple", label: t("Multiple"), truemsg: "Yes", falsemsg: "No", value: false } });
		}
		if (control.type === INPUTS.SINGLEFILE) {
			tmpCon.push({ type: INPUTS.TEXT, options: { name: "uptitle", label: t("Upload Title"), size: "sm" } });
		}
		if (control.type === INPUTS.SINGLEFILEFORORDER) {
			tmpCon.push({ type: INPUTS.TEXT, options: { name: "uptitle", label: t("Upload Title"), size: "sm" } });
			tmpCon.push({
				type: INPUTS.COMBO,
				options: { name: "uploadtype", label: t("Upload Type"), size: "sm", required: true, list: codes.filter(x => x.category === 'UPLOAD_TYPE') || [] }
			});
			tmpCon.push({ type: INPUTS.TEXT, options: { name: "filter", label: t("Accept File Types"), size: "sm", hint: 'ex) .png,.jpg,.stl' } });
			tmpCon.push({ type: SPAN.HR, options: { size: 0 } });
			tmpCon.push({ type: INPUTS.TEXT, options: { name: "uploadpath", label: t("Upload Path"), size: "sm", hint: 'ex) ios' } });
			// tmpCon.push({ type: INPUTS.TEXT, options: { name: "rename", label: t("Rename"), size: "sm" } });
			tmpCon.push({ type: INPUTS.HIDDEN, options: { name: "uptype", value: MLFile.UPTYPE.MYLAB_PATIENTFILES } });
			tmpCon.push({ type: INPUTS.HIDDEN, options: { name: "to", value: MLFile.TO.MYLAB } });
		}
		if (control.type === INPUTS.RADIOBUTTON) {
			tmpCon.push({ type: INPUTS.ADDITEMS, options: { name: "list", label: t("Items"), size: "sm", required: true, getKey: "list" } });
		}
		if (control.type === INPUTS.COMBOBYCODE) {
			tmpCon.push({
				type: INPUTS.COMBO,
				options: { name: "list", label: "Code Category", size: "sm", required: true, list: codeTypes }
			});
			tmpCon.push({ type: INPUTS.TOGGLE, options: { name: "multiple", label: t("Multiple"), truemsg: "Yes", falsemsg: "No", value: false } });
		}
		if (control.type === INPUTS.TEXT) {
			tmpCon.push({ type: INPUTS.TEXT, options: { name: "placeholder", label: t("Placeholder"), size: "sm" } });
		}
		if (control.type === INPUTS.TEXTAREA) {
			tmpCon.push({ type: INPUTS.NUMBER, options: { name: "rows", label: t("Rows"), size: "sm", value: 3 } });
		}
		if (control.type === INPUTS.NUMBER) {
			tmpCon.push({ type: INPUTS.NUMBER, options: { name: "min", label: t("Minimum"), size: "sm", value: 0 } });
			tmpCon.push({ type: INPUTS.NUMBER, options: { name: "max", label: t("Maximum"), size: "sm", value: 100 } });
		}
		if (control.type === INPUTS.TOGGLE) {
			tmpCon.push({ type: INPUTS.TEXT, options: { name: "truemsg", label: t("True message"), size: "sm", value: 0 } });
			tmpCon.push({ type: INPUTS.TEXT, options: { name: "falsemsg", label: t("False message"), size: "sm", value: 100 } });
		}
		if(control.type !== INPUTS.GROUP) {
			tmpCon.push({ type: INPUTS.TEXT, options: { name: "hint", label: t("Hint Message"), size: "sm" } });
			tmpCon.push({ type: SPAN.HR, options: { size: 0 } });
			tmpCon.push({ type: INPUTS.TOGGLE, options: { name: "required", truemsg: "Required", falsemsg: "Not Required", value: false } });
			tmpCon.push({ type: SPAN.HR, options: { size: 1 } });
		}
		tmpCon.push({
			type: BUTTONS.BUTTONS, options: {}, buttons: [{
				type: BUTTONS.FORMSUBMIT,
				options: {
					label: t('save'),
					color: COLORS.PRIMARY,
					needValid: true
				}
			}, {
				type: BUTTONS.NORMALBUTTON,
				options: {
					label: t('cancel'),
					action: cancel
				}
			}]
		});
		setCon(tmpCon);
	}, [cancel, codeTypes, control.type, t, uploadTypes]);

	useEffect(() => {
		makeCon();
	}, [control])

	useEffect(() => {
		if (con.length > 0) {
			setData(control.options || {});
		}
	}, [con, control.options])

	const save = (savedData) => {
		if (control.type === INPUTS.COMBO) {
			control.type = savedData.multiple ? INPUTS.COMBOMULTI : INPUTS.COMBO;
		}
		con.filter(x => x.type === INPUTS.HIDDEN).forEach(x => {
			if (savedData.hasOwnProperty(x.options.name)) {
				savedData[x.options.name] = x.options.value ?? '';
			}
		})
		if (control.type === INPUTS.GROUP) {
			callback({ type: control.type, options: {...data, ...savedData}, controls : [] });
		}else{
			callback({ type: control.type, options: {...data, ...savedData} });
		}
	}

	return <div className=''>
		<MLForm
			title={t(control.type)}
			controls={con}
			data={data}
			submit={save} />
	</div>
}

const FormBuilder = ({ form, controls, updated, cancel, updatedCallback, codes = [] }) => {

	const [t] = useTranslation(['translation']);
	const [editIdx, setEditIdx] = useState(-1);
	const [ctrl, setCtrl] = useState({});
	const [ctrls, setCtrls] = useState(controls || []);
	const [info, setInfo] = useState({});
	const [previewLock, setPreviewLock] = useState(true);
	const dispatch = useDispatch();

	useEffect(() => {
		setCtrls(controls);
	}, [controls]);

	useEffect(() => {
		setInfo(form.info || {});
	}, [form]);

	const AddItem = (type) => {
		setEditIdx(-1);
		setCtrl({ type: type });
		RTAudit({ lvl: 5, msg: 'Adding an Item to Form' });
	}

	const updateRow = (upItem) => {
		console.log(upItem);
		upItem = { ...upItem, options: { ...upItem.options, name: upItem.options.name || 'k' + new Date().valueOf() } };
		console.log(upItem.options.name);
		if (editIdx === -1) {
			if (ctrls.filter(x => x.options.name === upItem.options.name).length > 0) {
				return;
			} else {
				updated({ ...form, controls: upItem.options.groupList ? 
					ctrls.map(x => x.options.name === upItem.options.groupList ? {...x, controls : [...x.controls, upItem]} : x) : 
					[...ctrls, upItem] });
			}
		} else {
			if(upItem.type === INPUTS.GROUP){
				updated({ ...form, controls: 
					ctrls.map(x => x.options.name === upItem.options.name ? {...x, options : upItem.options } : x)
				});
			}else{
				updated({ ...form, controls: upItem.options.groupList ? 
					ctrls.map(x => x.options.name === upItem.options.groupList ? 
						{...x, controls : x.controls.map((n, idx) => idx === editIdx ? upItem : n)} : 
						x) : 
					ctrls.map(x => x.options.name === upItem.options.name ?  upItem : x)
				});
			}
		}
		setEditIdx(-1);
		setCtrl({});
	}

	const updateOrder = (items) => {
		updated({ ...form, controls: items });
	}

	const delRow = ({collection, index}) => {
		if(index >= 0){
			if(collection === 0){
				updated({ ...form, controls: controls.filter((x, idx) => idx !== index) });
			}else{
				const ncont = form.controls.filter(x => x.type === INPUTS.GROUP)[collection - 1]
				updated({ ...form, 
					controls: controls.map(x => x.options.name === ncont.options.name ? 
						{...x, controls : x.controls.filter((x, idx) => idx !== index)}	:
						x)
				});
			}
		}else{
			const ncont = form.controls.filter(x => x.type === INPUTS.GROUP)[collection - 1]
			updated({ ...form, controls: controls.filter(x => x.options.name !== ncont.options.name) });
		}
	}

	const onChangeInfo = (fInfo) => {
		setInfo({...info, ...fInfo});
	}

	const updateInfo = () => {
		updatedCallback({ ...form, info: info });
		RTAudit({ lvl: 4, msg: '[' + info._id + '] Update Form'});
	}

	const selRow = ({collection, index}) => {
		if(index >= 0){
			setEditIdx(index);
			setCtrl(collection === 0 ? 
				ctrls.filter(x => x.type !== INPUTS.GROUP)[index] : 
				ctrls.filter(x => x.type === INPUTS.GROUP)[collection - 1].controls[index]
			);
		}else{
			setEditIdx(collection - 1);
			setCtrl(ctrls.filter(x => x.type === INPUTS.GROUP)[collection - 1]);
		}
	}

	const AddItems = [
		{ type: INPUTS.GROUP, label: t('group') },
		{ type: INPUTS.TEXT, label: t('addtext') },
		{ type: INPUTS.COMBO, label: t('addcombo') },
		{ type: INPUTS.SINGLEFILE, label: t('addsinglefile') },
		{ type: INPUTS.SINGLEFILEFORORDER, label: t('addsinglefilefororder') },
		{ type: INPUTS.HEADPHOTO8, label: t('addhead8setphoto') },
		{ type: INPUTS.COMBOBYCODE, label: t('addcomboset') },
		{ type: INPUTS.RADIOBUTTON, label: t('addradiobutton') },
		{ type: INPUTS.DATE, label: t('adddate') },
		{ type: INPUTS.TEXTAREA, label: t('addtextarea') },
		{ type: INPUTS.NUMBER, label: t('addnumber') },
		{ type: INPUTS.EMAIL, label: t('addemail') },
		{ type: INPUTS.TOGGLE, label: t('addtoggle') }
	]

	const infoControls = [
		{
			type: INPUTS.TEXT,
			options: { name: "title", label: t("Title"), required: true },
		},
		{
			type: INPUTS.COMBO,
			options: { name: "type", label: t("Form Type"), required: true, list: codes.filter(x => x.category === 'FORM_TYPE') },
		},
		{
			type: INPUTS.TEXTAREA,
			options: { label: t("description"), name: "description", rows: 3 },
		},
	]

	return <>
		<div className='d-flex flex-wrap flex-stack pb-2'>
			<div className='d-flex flex-wrap align-items-center my-1'>
				<DropdownButton as={ButtonGroup} title={t("Add Item")} className='me-1'>
					{AddItems.map((x, idx) => <Dropdown.Item onClick={() => AddItem(x.type)} key={"ddi" + idx}>{x.label}</Dropdown.Item>)}
				</DropdownButton>
				<MLButton options={{
					label: info._id ? t('updateform') : t('addform'),
					color: COLORS.SUCCESS,
					action: (e) => updateInfo(e)
				}} css="me-1" />
				<MLButton options={{
					color: COLORS.WARNING,
					label: previewLock ? t('locked') : t('unlocked'),
					action: () => setPreviewLock(!previewLock)
				}} css="me-1" />
				<MLButton options={{
					color: COLORS.DARK,
					label: t('cancel'),
					action: cancel
				}} />
			</div>
		</div>
		<div className='row'>
			<div className='col'>
				<div className='card'>
					<div className='card-body'>
						<MLForm
							nocard={true}
							readonly={false}
							controls={infoControls}
							onChanged={(d) => onChangeInfo(d)}
							data={info} />
						{ctrls.length > 0 && <>
							<div className='h4 mt-2'>{t('Controls')}</div>
							<FormPreview
								usecollapse={true}
								lock={previewLock}
								selRow={selRow}
								delRow={delRow}
								controls={ctrls}
								update={updateOrder}
								data={{}} />
						</>}
					</div>
				</div>
			</div>
			{ctrl.type && <div className='col'>
				<FormRow
					codes={codes}
					control={ctrl}
					controls={controls}
					isedit={editIdx !== -1}
					callback={(e) => updateRow(e)}
					cancel={() => setCtrl({})} />
			</div>}
		</div>
	</>
}

export default FormBuilder;