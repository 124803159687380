import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

const getCheckNullView = (val) => {
	return val ?
		<p className='fs-16'>{nl2br(val)}</p> : 
		<p><i className='fs-16'>N/A</i></p>;
}

const nl2br = (text) => {
	var html = [];
	if(text){
		text.split('\n').map( (line, idx) => {
			html.push(<React.Fragment key={idx}>{line}<br/></React.Fragment>);
		})
	}else{
		return "N/A";
	}
	return html;
}

const MLComboSingleByCode = ({ codes, options = {}, handleChanged, readonly = false, css = "" }) => {

	const [focus, setFocus] = useState(false);
	const [val, setVal] = useState("");
	const [list, setList] = useState([]);
	const [t] = useTranslation(['translation']);

	useEffect(() => {
		setList(codes.filter(x => x.category === options.list).map(x => { return { value : x.code, label : x.title } }));
	}, [codes])

	useEffect(() => {
		if(list)
			setVal(list.filter(x => x.value == options.value)[0]);
	}, [list, options])

	const onChanged = (e) =>{
		setVal(e.value);
		handleChanged && handleChanged({ target : { name : options.name, value : e.value }});
	} 

	return <div className={"mb-5" + 
			(css && " " + css) +
			(focus ? " focused" : "") +
			(options.err ? " has-error" : "") }>
		{(!options.readonly && !readonly) && <>
			{options.label && <label className={options.required ? 'form-label required' : 'form-label'}>{options.label}<span/></label>}
			<Select 
				name={options.name} 
				onFocus={() => setFocus(true)}
				onBlur={() => setFocus(false)}
				value={val} 
				options={list}
				onChange={onChanged} />
			{options.err && <label className="error" for={options.name}>{options.errmsg}</label>} 
			{options.hint && <label className="help" for={options.name}>{options.hint}</label>} 
		</>}
		{(options.readonly || readonly) && <>
			<label className='form-label'>{options.label || options.name}<span/></label>
			{val && getCheckNullView(val.label)}
		</>}
	</div>
}

const mapState = (state) => {
	const codes = state.CodeReducer.items;
	return { codes };
};

export default connect(mapState)(MLComboSingleByCode);