import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Select from 'react-select';
import { MLText } from '../components/common/MLInput';
import { COUNTRIES } from '../constants/Country';
import { MLPanelLoading } from '../components/common/MLPanel';
import MLTable from '../components/common/MLTable';
import { MLButton, MLRefreshButton, MLChartButton } from '../components/common/MLButton';
import { AuthConstant } from '../redux/reducers/AuthReducer';
import { fetchData, countData } from '../libs/db';
import { RMIconList, RMIconChart } from '../components/icon/MLIcon';
import { ProgramConstant } from '../redux/reducers';

import GroupStatCard from '../components/group/GroupStatCard';
import MLFilter, { CreateScanFilter } from '../components/common/MLFilter';
import MLFilterList from '../components/common/MLFilterList';
import { InfoAction } from '../redux/actions';
import { useTranslation } from 'react-i18next';

const GroupPage = ({ groups, user, region, licenses, loginuser, pagestat, page, filters }) => {
	const [t] = useTranslation(['translation']);
	const dispatch = useDispatch();
	const [search, setSearch] = useState("");
	const [filter, setFilter] = useState(pagestat?.filter || [
        { code : "countryCode", value : "", list : [] },
    ]);
	const [items, setItems] = useState([]);
	const [loading, setLoading] = useState(false);
	const [fs, setFs] = useState([]);
	const [showFilter, setShowFilter] = useState(false);
    const mgroup = JSON.parse(localStorage.getItem("mgroup"));

	useEffect(() => {
		dispatch({ type : ProgramConstant.SET_PAGE, page : { 
			code : "groups", 
			view : "summary", 
			title : "Group", 
			small : t("기관 정보"), 
			options : [
			]
		}})
	}, []);

	useEffect(() => {
		dispatch(InfoAction.GetUserFilters("groups"));
	}, [page]);

	useEffect(() => {
		getData();
	}, [filters]);

	useEffect(() => {
		genData();
        dispatch({ type: ProgramConstant.PAGESTAT, item: { grouplist : { search, filter, 
            order : pagestat.order, 
            desc : pagestat.desc 
         }} });
	}, [search, filter, groups, pagestat.order, pagestat.desc]);

	useEffect(() => {
        if(groups)
            genFilter();
	}, [groups]);

    const headers = [
        { link : "_id", linkpath : "/GroupInfo", value : "name", label : t("Group"), css : "text-start", style : { width : 350 } },
		{ value : "countryCode", label : t("Country"), type : "COUNTRY"},
        { value : "address", label : t("Address"), css : "text-start" },
		{ value : "grouptype", label : t("Type"), css : "text-start", type : "ARRAY" },
		{ value : "created", label : t("Created"), type : "DATESHORT", style : { width : 140 }, css : "text-center" },
    ]

    const fLabel = (code, value) => {
        if(value === true || value === false){
            return value === true ? "Active" : "Expired";
        }else if(code === "countryCode"){
            return COUNTRIES.find(x => x.countryCode === value)?.name;
        }
        return value;
    }

	const genFilter = () => {
        setFilter( filter.map(x => {
            const list = [...new Set(groups.map(m => m[x.code]))];
            return {...x, list : [{ value : "", label : t("ALL") }, ...list.map(n => ({ 
                value : n, 
                label : fLabel(x.code, n)
            }))] }
        }));
	}

    const getData = async () => {
		setLoading(true);
		try{
            let ScanFilter = {
				sk: {
					ComparisonOperator: "EQ",
					AttributeValueList: ["info"],
				},
				isDel : {
					ComparisonOperator: "EQ",
					AttributeValueList: [false],
				},
			}
            if(mgroup?.countries?.length > 0){
                ScanFilter = {...ScanFilter,
                    countryCode : {
                        ComparisonOperator: "IN",
                        AttributeValueList: mgroup.countries?.map(x=> x.countryCode)
                    }
                }
            }
            const pageFilter = filters.find(x => x.page === page.code);
            if(pageFilter){
                pageFilter.fs.map(x => CreateScanFilter(ScanFilter, x));
            }
			const ret = await fetchData("rayteams-group", ScanFilter);
			dispatch({ type : AuthConstant.GET_ALL_GROUPS, items : ret.Items.filter(x => x.hasMember)});
		}catch{
			dispatch({ type : AuthConstant.GET_ALL_GROUPS, items : []});
		}
		setLoading(false);
    }

    const filterCheck = (item) => {
        var tf = true;
        filter.map(x => {
            if(tf)
                tf = (item[x.code] === x.value || x.value === "");
        });
        return tf;
    }

	const genData = () => {
        if(groups){
            setItems(groups
                .filter(x => search ? (x.name.indexOf(search) > -1) : true)
                .filter(x => filterCheck(x))
                .sort((a, b) => pagestat.desc === "desc" ? (a[pagestat.order] > b[pagestat.order] ? -1 : 1) : (b[pagestat.order] > a[pagestat.order] ? -1 : 1))
            );
        }
	}
	
    const goLink = (to, title) => {
		dispatch({ type : ProgramConstant.GO_LINK, to, title });
	}

	const handleFilter = (e, item) => {
        setFilter( filter.map(x => x.code === item.code ? {...x, value : e.value } : x));
	}

	const headerClick = () => {
		dispatch({ type: ProgramConstant.PAGESTAT, item: { grouplist : { search, filter, 
            order : pagestat.order, 
            desc : (pagestat.desc === "desc" ? "asc" : "desc") }} });
	}

  	return <div className='p-4'>
		{(process.env.REACT_APP_COMPANY === 'ray' || !process.env.REACT_APP_COMPANY) && <div className='mb-4'>
            <GroupStatCard items={items}/>
        </div>}
		<div className="card">
			<div className="card-header d-flex justify-content-between align-items-center">
				<div className="card-label fw-bold text-gray-800 fs-5">{items.length}</div>
				<div className="d-flex">
					<MLFilterList page={page} filter={fs} />
					<MLButton options={{
						label :  t("Filter"),
						color : "primary",
						action : () => setShowFilter(!showFilter)
					}} css="me-1" />
                    <MLText options={{
                        placeholder : t("검색어 입력"),
                        size : "sm",
                        value : search,
                    }} handleChanged={(e) => setSearch(e.target.value)} css="mb-0" />
                </div>
            </div>
			<MLFilter show={showFilter} page={page} callback={() => setShowFilter(false)} />
            {!loading && <MLTable 
                headers={headers} 
                items={items} 
                headerClick={headerClick}
                order={pagestat?.order || "created"} 
                desc={pagestat?.desc || "desc"} 
                noheader={true} />}
            {loading && <MLPanelLoading />}
		</div>
  	</div>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const groups =  state.AuthReducer.groups;
	const region =  state.AuthReducer.region;
	const licenses =  state.AuthReducer.licenses;
	const loginuser = state.AuthReducer.loginuser;
	const pagestat = state.ProgramReducer.pagestat["grouplist"] || {};
	const page = state.ProgramReducer.page;
	const filters = state.ProgramReducer.filters;

	return { region, user, groups, licenses, loginuser, pagestat, page, filters };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, null)(GroupPage);
