import axios from 'axios';
import { connect, useDispatch } from 'react-redux';
import RMContentInfoPaenl from '../components/board/RMContentInfoPaenl';
import RMUserContentList from '../components/board/RMUserContentList';
import RMBoardInfoPaenl from '../components/board/RMBoardInfoPaenl';
import RMBoardTabs from '../components/board/RMBoardTabs';

const ContentInfoPage = ({ user, board, contents }) => {

	const dispatch = useDispatch();

  	return (
    	<div className='p-4'>
			<RMBoardInfoPaenl />
			<RMContentInfoPaenl />
			{board?.type !== 'MANAGER_NOTICE' && board?.type !== 'MANUALDOC' && <RMUserContentList />}
    	</div>
  	);
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const region =  state.AuthReducer.region;
	const board =  state.BoardReducer.board;
	const contents =  state.BoardReducer.contents;
	return { region, user, board, contents };
};

const mapDispatch = (dispatch) => ({
});

export default connect(mapState, mapDispatch)(ContentInfoPage);