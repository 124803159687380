import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { MLText } from '../../components/common/MLInput';
import { MLPanelLoading } from '../../components/common/MLPanel';
import MLTable from '../../components/common/MLTable';
import { AuthConstant, getAPI, getHeader } from '../../redux/reducers/AuthReducer';
import { fetchData } from '../../libs/db';
import { MLButton } from '../../components/common/MLButton';
import { history } from '../../history';
import { COLORS } from '../../constants/FromInputs';
import { RMIconList, RMIconChart } from '../../components/icon/MLIcon';
import { MLPanelNoData } from '../../components/common/MLPanel';
import { ProgramConstant, ManagerGroupConstant } from '../../redux/reducers';
import { AWSUserListAdmin } from '../../libs/aws';
import { useTranslation } from 'react-i18next';

const ManagerGroupPage = ({ managerGroups, page }) => {
	const dispatch = useDispatch();
	const [search, setSearch] = useState("");
	const [items, setItems] = useState([]);
	const [loading, setLoading] = useState(false);
	const mgroup = JSON.parse(localStorage.getItem("mgroup"));
	const [t] = useTranslation(['translation']);

	useEffect(() => {
		dispatch({ type : ProgramConstant.SET_PAGE, page : { 
			code : "managergroups", 
			view : "summary", 
			title : "Manager Groups", 
			small : t("Manager Groups 정보 및 관리"), 
			options : [
			]
		}})
	}, []);
	
	useEffect(() => {
		getData();
		getAdminUserData();
	}, []);

	useEffect(() => {
		genData();
	}, [search, managerGroups])

    const headers = [
		{ link : "_id", linkpath : "/ManagerGroupInfo", value : "name", label : t("Group Name"), css : "text-start", style : { width : 150 } },
		// { value : "adminname", label : "Admin Name", css : "text-start", style : { width : 250 } },
		// { value : "adminemail", label : "Admin Email", css : "text-start", style : { width : 250 } },
		// { value : "tel", label : "Tel.", css : "text-start", style : { width : 150 } },
		// { value : "created", label : "Created", css : "text-end", type: "DATE" },
    ]

	const getAdminUserData = async () => {
        try {
            var list = [];
            var ret = await AWSUserListAdmin("ap-northeast-2");
			const uRet = await fetchData("rayteams-manager-user", {sk: { ComparisonOperator: "EQ", AttributeValueList: ["info"]}});

            ret.Users.map((x) => {
                list.push({
                    sub : x.Attributes.filter(y => y.Name == 'sub')[0].Value,
                    name : x.Attributes.filter(y => y.Name == 'name')[0].Value,
                    email : x.Attributes.filter(y => y.Name == 'email')[0].Value,
                    region : x.Attributes.filter(y => y.Name == 'custom:regions')[0]?.Value,
                    type : x.Attributes.filter(y => y.Name == 'custom:type')[0]?.Value,
					managergroupid : uRet.Items.find(f => f._id === x.Attributes.filter(y => y.Name == 'sub')[0].Value)?.managergroupid
                })
            })

            dispatch({ type : AuthConstant.GET_ADMIN_USERS, items : list });
        } catch {
            dispatch({ type : AuthConstant.GET_ADMIN_USERS, items : [] });
        }
        setLoading(false);
    }

	const getData = async () => {
		setLoading(true);
		try {
			let ScanFilter = {
				sk: {
					ComparisonOperator: "EQ",
					AttributeValueList: ["info"],
				},
			}
	
			if (mgroup.countries.length > 0) {
				ScanFilter = {...ScanFilter,
					_id : {
						ComparisonOperator: "EQ",
						AttributeValueList: [mgroup._id],
					}
				}
			}

			const ret = await fetchData("rayteams-manager-group", ScanFilter);
			dispatch({ type: ManagerGroupConstant.GET_MANAGER_GROUPS, items: ret.Items });
		} catch(error) {
			console.log(error);
		}

		setLoading(false);
	}

	const genData = async () => {
		setItems(search ? managerGroups.filter(x => x.name.indexOf(search) > -1) : (managerGroups || []));
	}

  	return <div className='p-4'>
		<div className="card">
			<div className="card-header d-flex justify-content-between align-items-center">
				<div className="card-label fw-bold text-gray-800 fs-5">{items.length}</div>
				<div className="d-flex">
					<MLButton options={{
						code : "mgr-addgroup",
						label : t("Add a Group"),
						size : "sm",
						color : COLORS.PRIMARY,
						action : () => history.push("/ManagerGroupAdd")
					}} css="me-2" />
					<MLText options={{
						placeholder : t("검색어 입력"),
						size : "sm",
						value : search,
					}} handleChanged={(e) => setSearch(e.target.value)} css="mb-0" />
				</div>
			</div>
			{items.length > 0 && <> {!loading && <MLTable headers={headers} items={items} noheader={true} />} </>}
			{items.length === 0 && <MLPanelNoData />}
			{loading && <MLPanelLoading />}
        </div>
    </div>
}

const mapState = (state) => {
	const managerGroups = state.ManagerGroupReducer.managerGroups;
	const page = state.ProgramReducer.page;

	return { managerGroups, page };
};

export default connect(mapState, null)(ManagerGroupPage);