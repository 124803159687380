import axios from 'axios';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { MLText, MLToggle } from '../common/MLInput';
import { MLPanelLoading } from '../common/MLPanel';
import MLTable from '../common/MLTable';
import { AuthConstant, getAPI, getHeader } from '../../redux/reducers/AuthReducer';
import { isEmpty } from 'lodash';
import { fetchData } from '../../libs/db';

const RMRayTeamsApp = ({ group, region, rayteams, section }) => {
	const dispatch = useDispatch();
	const [search, setSearch] = useState("");
	const [search_remoteIp, setSearch_RemoteIp] = useState("");
	const [items, setItems] = useState([]);
	const [loading, setLoading] = useState(false);
	const [isOn, setIsOn] = useState(false);
	const [isGroup, setIsGroup] = useState(false);
	const [isRemoteIP, setIsRemoteIP] = useState(false);
	const [remoteIPList, setRemoteIPList] = useState(false);
	const [isRegion, setIsRegion] = useState(false);

	useEffect(() => {
        getData_App();
	}, [region]);

	useEffect(() => {
		if (rayteams && !section) {
			genData();
		} else {
			genData_Region();
		}
	}, [search, rayteams, isOn, isGroup]);

	useEffect(() => {
		if (rayteams && isRemoteIP)
			genData_isGroup();
	}, [isRemoteIP]);

	const getData_App = async () => {
		setLoading(true);
		try{
			const ret = await fetchData("rayteams-info");
			dispatch({ type : AuthConstant.GET_ALL_RAYTEAMS, items : ret.Items})
		}catch{
			dispatch({ type : AuthConstant.GET_ALL_RAYTEAMS, items : []})
		}
		setLoading(false);
    }

    const headers = [
        { value : "isOn", label : "Live", type : "TFVALUE", css : "text-center", style : { width : 50 } },
        { link : "_id", linkpath : "/RAYTeamsInfo", value : "raylinkAppID", label : "RAYTeams", css : "text-start", style : { width : 450 } },
        { value : "remoteIP", label : "Remote IP", css : "text-start" },
        { value : "localIP", label : "Local IP", css : "text-start" },
		{ value : "group.name", label : 'Group Name', css : "text-start", type : "ARRAYVALUE" },
    ]

	const headers_remoteip = [
		{ value : "remoteIP", label : "Remote IP", css : "text-start" },
        { link : "_id", linkpath : "/RAYTeamsInfo", linkMultivalue: true, value : "_id", label : "RAYTeams", css : "text-start" },
        { value : "localIP", label : "Local IP", css : "text-start" },
	]

	const genData = () => {
		if(group?._id) {
			setItems(
				search ? rayteams.filter(x => x.raylinkAppID?.indexOf(search) > -1 || x.remoteId?.indexOf(search) > -1)
				.filter(x=> x?.group?._id == group._id)
				.filter(x => {
					if((isOn ? x.isOn === true : true) && (isGroup ? !isEmpty(x?.group?._id) : true)) return true;
				}) : (rayteams.filter(x => x?.group?._id == group._id).filter(x => {
					if((isOn ? x.isOn === true : true) && (isGroup ? !isEmpty(x?.group?._id) : true)) return true;
				}) || []));
		} else {
			setItems(search ? rayteams.filter(x => x.raylinkAppID?.indexOf(search) > -1 || x.remoteId?.indexOf(search) > -1)
			.filter(x => {
				if((isOn ? x.isOn === true : true) && (isGroup ? !isEmpty(x?.group?._id) : true)) return true;
			}) : 
			(rayteams.filter(x => {
				if((isOn ? x.isOn === true : true) && (isGroup ? !isEmpty(x?.group?._id) : true)) return true;
			}) || []));
		}
	}

	const genData_Region = () => {
		if(group?._id) {
			setItems(
				search ? rayteams.filter(x => x.raylinkAppID?.indexOf(search) > -1 || x.remoteId?.indexOf(search) > -1)
				.filter(x => x?.group?._id == group._id)
				.filter(x => x?.group?.region) : (rayteams.filter(x => x?.group?._id == group._id)
				.filter(x => x?.group?.region) || [])
				);
		} else {
			setItems(
				search ? rayteams.filter(x => x.raylinkAppID?.indexOf(search) > -1 || x.remoteId?.indexOf(search) > -1)
				.filter(x => x?.group?.region === region) : 
				(rayteams.filter(x => { 
					if(section && x?.group?.region === region && (isOn ? x.isOn === true : true)) return true
				}) || []));
		}
	}

	const genData_isGroup = () => {
		const remoteIpList = [];
		const outputObj = rayteams.filter(x => x?.group?._id === group?._id).reduce((accum, { _id, remoteIP }) => {
			if (!accum[remoteIP]) {
				accum[remoteIP] = _id;
			} else {
				accum[remoteIP] += ' , ' + _id;
			}
			return accum;
		}, {});

		const output = Object.entries(outputObj).map(([ remoteIP, _id ]) => ({ remoteIP, _id }));

		output.map((x) => {
			if(group?._id) {
				remoteIpList.push({ _id: x._id, remoteIP: x.remoteIP, localIP: rayteams.filter(x => x?.group?._id == group._id).filter(y => y.remoteIP === x.remoteIP).map(m => m.localIP).join(', ')})
			} else {
				remoteIpList.push({ _id: x._id, remoteIP: x.remoteIP, localIP: rayteams.filter(y => y.remoteIP === x.remoteIP).map(m => m.localIP).join(', ')})
			}
		});

		setRemoteIPList(remoteIpList);
	}

  	return (<>
		<div className="d-flex flex-column flex-column-fluid">
			<div className="card card-flush h-md-100">
				<div className="card-header ps-4">
					<div className='d-flex'>
						<h3 className="card-title align-items-start flex-column">
							<span className="card-label fw-bold text-gray-800 mb-1">{search ? search : "RAYTeams App"}
								<span className="badge badge-secondary ms-2">
									{items && items.length}
								</span>
							</span>
						</h3>
						<div className='row align-items-center'>
							<MLToggle
								options={{
									label: 'IP',
									name: 'isRemoteIP',
									value: isRemoteIP,
									readonly: false,
									inline: true,
								}}
								handleChanged={(e) => { setIsRemoteIP(e.target.value); console.log(e); }} />
						</div>
					</div>
					<div className="card-toolbar">
						{!loading && !isRemoteIP && <>
						<MLToggle
							options={{
								label: 'Live',
								name: 'isOn',
								value: isOn,
								readonly: false,
								inline: true,
							}} 
							handleChanged={(e) => { setIsOn(e.target.value); console.log(e); }} />
						<MLToggle
							options={{
								label: 'Group',
								name: 'isGroup',
								value: isGroup,
								readonly: false,
								inline: true,
							}} 
							handleChanged={(e) => { setIsGroup(e.target.value); console.log(e); }} css="ps-5" />
						<MLText options={{
								placeholder : "검색어 입력",
								size : "sm",
								value : search,
							}} handleChanged={(e) => !isRemoteIP ? setSearch(e.target.value) : setSearch_RemoteIp(e.target.value)} css="w-200px ps-5" />
						</>}
						{!loading && isRemoteIP && <>
						<MLText options={{
							placeholder : "검색어 입력",
							size : "sm",
							value : search,
						}} handleChanged={(e) => setSearch(e.target.value)} css="w-200px" />
						</>}
					</div>
				</div>
				{!loading && !isRemoteIP && <>
					<MLTable headers={headers} items={items} noheader={true} />
				</>}
				{!loading && isRemoteIP && <>
					<MLTable headers={headers_remoteip} items={remoteIPList} noheader={true} />
				</>}
				{loading && <MLPanelLoading />}
			</div>
		</div>
	
		{loading && <MLPanelLoading />}
    	</>
  	);
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const region =  state.AuthReducer.region;
	const rayteams =  state.AuthReducer.rayteams;
	return { region, user, rayteams };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, null)(RMRayTeamsApp);