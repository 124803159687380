import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import MLTable from '../common/MLTable';
import { AuthConstant, getAPI, getHeader } from '../../redux/reducers/AuthReducer';
import { MLPanelLoading } from '../common/MLPanel';
import { queryData } from '../../libs/db';
import { fetchData } from '../../libs/db';
import { aws_con } from '../../libs/db';
import { COGNITO } from '../../libs/cogInfo';
import { COGNITO_GRAPHY } from '../../libs/cognito-graphy';

const RMUserList = ({ group }) => {
	const dispatch = useDispatch();
	const [ items, setItems ] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if(group._id) {
			getGroupUserData();
		}
	}, [group]);

	const getGroupUserData = async () => {
		setLoading(true);
		try {
			const queryFilter = {
				_id : {
					ComparisonOperator: "EQ",
            		AttributeValueList: [group._id],
				},
				sk : {
					ComparisonOperator: "EQ",
					AttributeValueList: ["info"]
				}
			}
			const retGroup = await queryData("rayteams-group", queryFilter, group?.region == "ap-east-1" && group.region);

			if(retGroup.Count > 0) {
				const scanFilter = {
					sk: {
						ComparisonOperator: "EQ",
						AttributeValueList: ["info"],
					},
					groupId: {
						ComparisonOperator: "EQ",
						AttributeValueList: [group._id]
					}
				}
				const retUsers = await fetchData("rayteams-user", scanFilter, retGroup.Items[0]?.region == "ap-east-1" && retGroup.Items[0]?.region);
				const result = [];
				aws_con();
				AWS.config.update({ region: retGroup.Items[0]?.region == "ap-east-1" ? "ap-northeast-2" : retGroup.Items[0]?.region});
				var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
				for (const user of retUsers.Items) {
					try{
						const paramsCognito = {
						  UserPoolId: (process.env.REACT_APP_COMPANY === 'graphy' ?  COGNITO_GRAPHY[retGroup.Items[0]?.region].poolId : COGNITO[retGroup.Items[0]?.region].poolId),
						  Username: user._id,
						};
						const item = await cognitoidentityserviceprovider.adminGetUser(paramsCognito).promise();
						var attrs = {};
						item?.UserAttributes?.map((x) => {
						  attrs[x.Name.replace("custom:", "")] = x.Value;
						});
						result.push({
						  ...attrs,
						  ...user,
						  Enabled: item?.Enabled,
						  UserStatus: item?.UserStatus,
						  UserCreateDate: item?.UserCreateDate,
						});
					  dispatch({ type : AuthConstant.GET_GROUP_USERS, items : result });
					}catch{}
				}
				setItems(result);
			}

		} catch (error) {
			console.log(error);
			setItems([]);
		}
		setLoading(false);
	}

    const headers = [
        { link : "_id", linkpath : "/UsersInfo", value : "email", label : "Email", style : { width : 300 }, css : "text-start", type: "XEmail" },
        { value : "name", label : "User Name", css : "text-start", type: "XName" },
        { value : "userType", label : "Type", style : { width : 200 }, css : "text-center" },
        { value : "UserStatus", label : "Status", type : "SPAN", style : { width : 240 }, css : "text-center" },
        { value : "UserCreateDate", label : "Created", type : "DATE2", style : { width : 240 }, css : "text-center" },
        { value : "Enabled", label : "Enabled", type : "TFVALUE", style : { width : 140 }, css : "text-center" },
    ]

  	return <>
		{loading && <MLPanelLoading noborder={true} />}
		{!loading && <MLTable headers={headers} items={items} nocard={true} noheader={true} /> }
	</>
}

const mapState = (state) => {
};

export default connect(null)(RMUserList);
