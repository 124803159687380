import { S3Client } from '@aws-sdk/client-s3';
import listBucketObjects from './commands/list-bucket-objects';

class S3SyncClient {
    constructor(options = {}) {
        const { client = new S3Client(options) } = options;
        this.client = client;
        this.listBucketObjects = this.listBucketObjects.bind(this);
    }
}
S3SyncClient.prototype.listBucketObjects = listBucketObjects;

export default S3SyncClient;