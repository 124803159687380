import axios from 'axios';
import { useEffect, useState } from 'react';
import { BiHelpCircle } from 'react-icons/bi';
import { connect, useDispatch } from 'react-redux';
import { MLPanelLoading, MLPanelNoData } from '../common/MLPanel';
import MLTable from '../common/MLTable';
import { AppCategoryConstant } from '../../redux/reducers';
import { LicenseConstant } from '../../redux/reducers';
import { fetchData, fetchData_prod, putData } from '../../libs/db';
import { RMIconChart, RMIconList } from '../icon/MLIcon';
import { MLNumber, MLText, MLToggle } from '../common/MLInput';
import { Modal } from 'react-bootstrap';
import { BUTTONS, COLORS, INPUTS, SPAN } from '../../constants/FromInputs';
import { MLComboSingle, MLTextArea } from '../common/MLInput';
import { ProgramConstant } from '../../redux/reducers';
import { MLButton } from '../common/MLButton';
import { RMLICENSETYPE } from '../../constants/RMConstants';
import { useTranslation } from 'react-i18next';
import { RTAudit } from '../../libs/audit';
import { getApiUrl } from '../../redux/reducers/AuthReducer';
import { JWT } from '../../config';

const RMLicenseManagedAppList = ({ managedApps, page }) => {
	const dispatch = useDispatch();
	const [t] = useTranslation(['translation']);
	const [loading, setLoading] = useState(true);
	const [help, setHelp] = useState(window.localStorage.getItem("helpmsg") === "Y" ? true : false);
	const [items, setItems] = useState([]);
	const [search, setSearch] = useState("");
	const [addApp, setAddApp] = useState({ isray: false });
	const [applist, setAppList] = useState([]);
	const [modalData, setModalData] = useState({
		show: false,
		title: t("Add Managed App")
	});
	const { v4 } = require("uuid");
	const mgroup = JSON.parse(localStorage.getItem("mgroup"));
	const partner = process.env.REACT_APP_COMPANY || 'ray';

	useEffect(() => {
		dispatch({
			type: ProgramConstant.SET_PAGE, page: {
				code: "licenses",
				view: "summary",
				title: "License",
				small: t("License 관련 정보"),
				options: [
				]
			}
		})
	}, []);

	useEffect(() => {
		getData();
	}, []);

	useEffect(() => {
		genData();
	}, [search, managedApps]);

	useEffect(() => {
		modalData.show && getApplicationData();
	}, [modalData.show])

	const getApplicationData = async () => {
		const ScanFilter = {
			type: {
				ComparisonOperator: "EQ",
				AttributeValueList: ["app"],
			},
			is_active: {
				ComparisonOperator: "EQ",
				AttributeValueList: [true],
			},
			licenseId: {
				ComparisonOperator: "NOT_NULL",
				Exists: true,
			}
		}
		try {
			const ret = await fetchData_prod("rayrnd-applications", ScanFilter);
			dispatch({ type: LicenseConstant.GET_ALL_APPLICATIONS, items: ret.Items });

			const removeItems = [];
			ret.Items.map((x) => {
				if (!(!!managedApps.find(f => f._id == x.licenseId))) {
					removeItems.push({ value: x.licenseId, label: x.title })
				}
			})
			setAppList(removeItems);
		} catch (error) {
			console.log(error);
		}
	}

	const getData = async () => {
		setLoading(true);
		try {
			
			let params = {
				TableName: "rayteams-license",
				FilterExpression: mgroup?.countries?.length > 0 ? "begins_with(#sk, :sk)" : "#sk = :sk",
				ExpressionAttributeNames: {"#sk": "sk"},
				ExpressionAttributeValues: {
					":sk": mgroup?.countries?.length > 0 ? `info:${mgroup?._id}` : "info"
				}
			}
			
			if (partner != "ray") {
				params = {
					...params,
					FilterExpression: params.FilterExpression + " and #b2b = :b2b",
					ExpressionAttributeNames: {
						...params.ExpressionAttributeNames,
						"#b2b": "b2b"
					},
					ExpressionAttributeValues: {
						...params.ExpressionAttributeValues,
						":b2b": partner
					}
				}
			}
			const sData = {
				type: "scan",
				mgroup: mgroup || [],
				b2b: partner,
				params
			}

			const ret = await axios.post(getApiUrl("license") + "/license/getlicenseinfo", {data: sData}, JWT());
			const retResult = [];
			ret.data.data && ret.data.data.map(x => {
				let lTotal = 0;
				let lUsed = 0;
				RMLICENSETYPE.map(f => {
					lTotal += x.hasOwnProperty(`t_${f.value}`) ? parseInt(x?.[`t_${f.value}`]) : 0;
					lUsed += x.hasOwnProperty(`u_${f.value}`) ? parseInt(x?.[`u_${f.value}`]) : 0;
				})

				retResult.push({
					title: x.title,
					_id: x._id,
					total: lTotal,
					used: lUsed,
				});
			});
			console.log("retResult : =====", retResult);
			dispatch({ type: AppCategoryConstant.GET_ALL_MANAGED_APP, items: retResult });

		} catch (err) {
			console.log(err);
		}
		setLoading(false);
	}

	const genData = () => {
		setItems(search ? managedApps.filter(x => x._id.indexOf(search) > -1 || x.title.indexOf(search) > -1) : (managedApps || []));
	}

	const save = async () => {
		const infoItem = {
			_id: addApp.poolid,
			sk: "info",
			title: addApp.title,
			isray: addApp.isray ? true : false,
			total: 0,
			used: 0,
		}

		const ret = await putData("rayteams-license", infoItem);
		dispatch({ type: AppCategoryConstant.CREATE_MANAGED_APP, item: infoItem });
		RMLICENSETYPE.map(async (x) => {
			const tItem = {
				_id: addApp.poolid,
				sk: "type:" + x.value,
				period: addApp?.[x.value] || (x.value == "normal" && 365) || (x.value == "trial" && 7) || (x.value == "demo" && 7) || (x.value == "development" && 365),
				licensetype: x.value
			}
			const ret = await putData("rayteams-license", tItem);
		})

		try {
			const productId = v4();
			const productItem = {
				_id: productId,
				sk: "info",
				title: addApp.title,
				appitems: [addApp.poolid],
				isdraft: false,
				buytype: "buy",
				sells: 0,
				created: new Date().getTime(),
				defaultprice: 0
			}

			const ret = await putData("rayteams-product", productItem);

			const priceItem = {
				_id: productId,
				sk: "price:ray:KR",
				originalprice: 0,
				realprice: 0,
				created: new Date().getTime()
			}

			const retPrice = await putData("rayteams-product", priceItem);
			
			RTAudit({ lvl : 7, msg : 'Add Managed App' }, JSON.stringify({ subject : productItem }))
		} catch (e) {
			console.log(e);
		}
		setModalData({
			show: false,
			title: "Add Managed App",
		});
	}

	const updateProduct = async () => {
		try {
			const item = {
				title: data?.title,
				appitems: data?.appitems,
				isdraft: data?.isdraft,
				buytype: data?.buytype,
				sells: 0,
				created: new Date().getTime()
			}
			if (data?.buytype == "sub") {
				item.subtype = data?.subtype;
				item.subperiod = data?.subperiod + "" + data.subperiodtype;
			}
			if (data?.defaultprice > 0) {
				item.defaultprice = data?.defaultprice;
			}

			const ret = await updateData("rayteams-product", { _id: _id, sk: "info" }, item);
		} catch (err) {
			console.log(err);
		}
	}

	const createProduct = async () => {
		try {
			const productId = v4();
			const item = {
				_id: productId,
				sk: "info",
				title: addApp.title,
				appitems: addApp.poolid,
				isdraft: false,
				buytype: "buy",
				sells: 0,
				created: new Date().getTime(),
				defaultprice: 0
			}

			const ret = await putData("rayteams-product", item);

			const priceItems = {
				_id: productId,
				sk: "price:ray:KR",
				originalprice: 0,
				realprice: 0,
				created: new Date().getTime()
			}

			const retPrice = await putData("rayteams-product", priceItems);
		} catch (e) {
			console.log(e);
		}
	};

	const showAdd = () => {
		setAddApp({
			poolid: "",
			desc: ""
		})
		setModalData({
			show: true,
			title: t("Add Managed App"),
		});
	}

	const headers = [
		{ link: "_id", value: "title", linkpath: "/LicenseAppList", label: t("Title"), style: { width: 350 } },
		{ value: "_id", label: t("App ID") },
	]

	const goLink = () => {

	}

	return <div className='p-4'>
		{!loading && <div className="card">
			<div className="card-header d-flex justify-content-between align-items-center">
				<div className="card-label fw-bold text-gray-800 fs-5">{t("All Managed App")}</div>
				<div className="d-flex align-items-center">
					<BiHelpCircle size="24" className="me-2 text-primary cursor-pointer" onClick={() => setHelp(!help)} />
					<MLButton options={{
						code: "lic-addmanapp",
						label: t("Add Managed App"),
						color: COLORS.PRIMARY,
						action: () => showAdd()
					}} />
					<div className="col-sm-auto ms-6 pt-1 btn-tool-view" style={{ display: "none" }}>
						<a className="btn btn-bg-secondary btn-sm mb-2 btn-view-select active" title="View as a list">
							<RMIconList></RMIconList>
						</a>
						<a className="btn btn-bg-secondary btn-sm mb-2 btn-view-select" title="View as a dashboard">
							<RMIconChart onClick={() => goLink("/UsersDashBoardPage", "Users")}></RMIconChart>
						</a>
					</div>
				</div>
			</div>
			{help && <div className="card-body bg-light p-4">
				{t("App은")} <a href="https://developer.rayteams.com" target="_blank" className='text-dark'><u>RAYTeams Developer</u></a>{t("에 관리되는 Software를 말합니다.")}<br />
				{t("Ray에서 개발한 대분이 등록되어 있고, 앞으로도 등록/관리될 예정입니다.")}<br />
				{t("이러한 Software중에 Managed App에 등록되면, 해당 Software는 License 없이 사용이 불가합니다.")}<br />
			</div>}
			{items.length > 0 && <MLTable
				headers={headers}
				items={items}
				nocard={true}
				noheader={true} />}
			{items.length === 0 && <MLPanelNoData />}
		</div>}
		{loading && <MLPanelLoading />}
		<Modal show={modalData.show} size={"lg"}>
			<Modal.Header>
				<div className='col-xl-12'>
					<div className='d-flex justify-content-between'>
						<h4>{t("Add Managed App")}</h4>
						<div className='d-flex align-items-center'>
							RAY
							<MLToggle
								options={{
									name: 'isray',
									value: addApp.isray,
									readonly: false,
								}}
								handleChanged={(e) => { console.log(e); setAddApp({ ...addApp, isray: e.target.value }) }}
								css={"ms-3"} />
						</div>
					</div>
				</div>
			</Modal.Header>
			{help && <div className="p-4">
				{t("Title은 별칭입니다. App을 쉽게 식별가능한 것으로 등록합니다.")}<br />
				{t("License는 Normal(정식 버전), Trial, Demo, Development 버전으로 나누어집니다.")}<br />
				{t("각각 만료일수를 등록할 수 있습니다.")} (<b className='text-danger'>{t("이는 변경이 불가합니다.")}!!!</b>)<br />
			</div>}
			<div className='alert alert-dark p-4' style={{ borderRadius: 0 }}>
				<div>{t("Managed App에 등록되면")}</div>
				<div>{t("등록된 App은 무료로 사용 할 수 없게 됩니다.")}</div>
			</div>
			<Modal.Body className='flex-fill formcard'>
				<div className='form-group'>
					<label className='label required fw-bold'>{t("Title")}</label>
					<MLText options={{
						value: addApp.title
					}} handleChanged={(e) => setAddApp({ ...addApp, title: e.target.value })} />

					<label className='label required fw-bold'>{t("Select Managed App")}</label>
					<MLComboSingle options={{
						value: addApp.poolid,
						name: "App Name",
						list: applist
					}} handleChanged={(e) => setAddApp({ ...addApp, poolid: e.target.value })} />

					<label className='label required py-2 fw-bold'>{t("License Type 별 만료 기간(일수)")}</label>
					{RMLICENSETYPE.map((x, idx) => {
						return (
							<div className="d-flex justify-content-between pt-2 align-items-center" id={`${idx}-${x.value}`} >
								<label className="form-label">{" - " +  t(x.label)}</label>
								<MLNumber options={{
									value: addApp?.[x.value] || (x.value == "normal" && 365) || (x.value == "trial" && 7) || (x.value == "demo" && 7) || (x.value == "development" && 365)
								}}
									handleChanged={(e) => { setAddApp({ ...addApp, [x.value]: e.target.value }); }} />
								{/* handleChanged={({ target: { value } }) => {setLicenseType(prev => ({...prev, [x.value]: value }));console.log(selectedLicenseType)}} css="w-20"  /> */}
							</div>
						)
					})}
				</div>
				<div className='pt-5 d-flex justify-content-end'>
					<MLButton
						options={{ label: t("Cancel"), color: COLORS.DEFAULT, action: () => setModalData({ ...modalData, show: false }) }}
						css={'me-1'} />
					<MLButton
						valid={!!addApp.poolid && !!addApp.title}
						options={{ label: t("Add Managed App"), needValid: true, color: COLORS.PRIMARY, action: () => save() }}
						css={'me-1'} />
				</div>
			</Modal.Body>
		</Modal>
	</div>
}

const mapState = (state) => {
	const managedApps = state.AppCategoryReducer.managedApps;
	const page = state.ProgramReducer.page;
	return { managedApps, page };
};

const mapDispatch = (dispatch) => ({
});

export default connect(mapState, null)(RMLicenseManagedAppList);
