import { connect, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BUTTONS, COLORS, INPUTS, SPAN } from '../../constants/FromInputs';
import { AlertConstant } from '../../redux/reducers';
import { MLForm } from '../common/MLForm';
import { AuthConstant } from '../../redux/reducers/AuthReducer';
import { AdminDisableUser, AWSAdminDisableUser, AWSAdminEnableUser, AWSAdminLoginPasswordUpdate, AWSLoginPasswordUpdate, AWSUserDelete, GetUserInfo } from '../../libs/aws';
import { Loader } from '../common/Loader';
import { updateData, idData,queryData } from '../../libs/db';
import { RTAudit } from '../../libs/audit';
import { useTranslation } from 'react-i18next';
import { MLButton } from '../common/MLButton';
import { history } from '../../history';
import { InfoAction } from '../../redux/actions';

const UserManagement = ({ _id, allusers, callback }) => {
	const dispatch = useDispatch();
	const [t] = useTranslation(['translation']);
    const [user, setUser] = useState({ });
    const [data, setData] = useState({ password : "" });
	const [loading, setLoading] = useState(false);
	const [confirmDel, setConfirmDel] = useState(false);
	const [confirmDeactive, setConfirmDeactive] = useState(false);
	const [confirmActive, setConfirmActive] = useState(false);
	const [passConfirmErr, setPassConfirmErr] = useState('');
	const [apiLoading, setApiLoading] = useState(false);

	useEffect(() => {
        if(_id){
            getUser();
		}
	}, [_id]);

	const getUser = async () => {
		const u = await GetUserInfo(_id);
		setUser(u);
	}

	const updatePassword = async () => {
		setLoading(true);
		await AWSLoginPasswordUpdate(user.region, user.email, data.password);
		RTAudit({ lvl : 7, msg : '[' + user.name + '] password updated by manager' });
		dispatch({ type: AlertConstant.NOTICE, msg: t('Password 변경 완료'), show: true });
		setLoading(false);
	}
	
	const activate = async () => {
		if(confirmActive){
			await AWSAdminEnableUser(user.region, user.sub);
			RTAudit({ lvl : 9, msg : '[' + user.name + '] account activated' });
			getUser();
			callback && callback();
		}else{
			setConfirmActive(true);
		}
	}

	const deactivate = async () => {
		if(confirmDeactive){
			if (apiLoading) {
				return;
			}
			setApiLoading(true)
			await AWSAdminDisableUser(user.region, user.sub);
			RTAudit({ lvl : 9, msg : '[' + user.name + '] account deactivated' });
			getUser();
			setApiLoading(false);
			callback && callback();
		}else{
			setConfirmDeactive(true);
		}
	}
    
	const del = async () => {
		if(confirmDel){
			if (apiLoading) {
				return;
			}
			setApiLoading(true)
			const { _id, region, email } = user;
			const result = await InfoAction.UnregisterClientUser({ _id, region, email });
			if (result) {
				RTAudit({ lvl : 9, msg : '[' + user.name + '] account removed' });
				history.push('/UsersPage');
			}
			setApiLoading(false);
		}else{
			setConfirmDel(true);
		}
	}

    const controls = [
		{
			type : INPUTS.PASSWORD,
			options : {
				label : t('패스워드'),
				name : 'password',
				required : true,
				useToggle : true,
				isValidPassword: true
			}
		},
		{
			type : INPUTS.PASSWORD,
			options : {
				name: 'repassword',
				label: t('패스워드 확인'),
				err: passConfirmErr,
				required: true,
				useToggle: true,
				isMatch: true,
				isValidPassword: true,
				originPassword: data?.password,
			}
		},
		{
			type : BUTTONS.BUTTONS, 
			options : { },
			buttons : [{
				type : BUTTONS.NORMALBUTTON, 
				options : { 
					label : t('암호변경'), 
					color : COLORS.PRIMARY,
					needValid : true,
                    action : () => updatePassword(),
				}
			}]
		}
	];
	
	return <div className=''>
		{loading && <Loader/>}
        {!loading && <div className='card'>
			<div className='card-body p-4'>
				<div className='mb-4'>
					<h5>{t('Update password')}</h5>
					<MLForm nocard={true} controls={controls.filter(x => !x.perm)} data={data} onChanged={(e) => { console.log(e);setData(e);}} />
				</div>
				<hr className='mt-4' />
				<div>
					<h5>{t('Danger zone')}</h5>
					<div>
						{user.Enabled && <>
							<MLButton options={{
								label : confirmDeactive ? t('Are you sure to deactivate user?') : t('Deactivate user'),
								size : 'sm',
								color : 'danger',
								action : () => deactivate()
							}} />
							<div className='label'>{t('Deactivated user are not allowed to log in and use.')}</div>
						</>}
						{!user.Enabled && <>
							<MLButton options={{
								label : confirmActive ? t('Are you sure to activate user?') : t('Activate user'),
								size : 'sm',
								color : 'primary',
								action : () => activate()
							}} />
							<div className='label'>{t('Activated user can login and use')}</div>
						</>}
					</div>
					<div className='mt-4'>
						<MLButton options={{
							label : confirmDel ? t('Are you sure to delete user?') : t('Delete user'),
							size : 'sm',
							color : 'danger',
							action : () => del()
						}} />
						<div className='label'>{t('Delete user. This action cannot be undone.')}</div>
					</div>
				</div>
			</div>
		</div>}
	</div>
}

const mapState = (state) => {
    const adminUsers = state.AuthReducer.adminUsers;
	const allusers =  state.ProgramReducer.allusers || [];
	const user = state.AuthReducer.user;
	return { adminUsers, user  };
};

const mapDispatch = dispatch => ({
})

export default connect(mapState, mapDispatch)(UserManagement);
