import { uniqBy } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { InfoAction } from '../../redux/actions';

const USDTOKRW = 1300;

const RMWidgetSmallCount = ({ options }) => {
    const [val, setVal] = useState(0);

    useEffect(() => {
        makeCount()
    }, [options]);

    const makeCount = async () => {
        const cached = localStorage.getItem(options.cacehname);
        if(cached){
            const cachedto = localStorage.getItem(options.cacehname + "-to");
            if(cachedto){
                if(parseInt(cachedto) < moment().valueOf() + (3600 * 1000)){
                    setVal(cached);
                    return;
                }
            }
        }
        
        if(options.filter){
            const ret = await InfoAction.callDB(options.filter);
            if(options.sum){
                let sum = ret.reduce((a, obj) => {
                    if(obj[options.sumkey]){
                        if(options.iscurrency){
                            if(options.iscurrency === "USD"){
                                return a + parseInt((obj[options.sumkey] * USDTOKRW));
                            }else{
                                return a + parseInt(obj[options.sumkey]);
                            }
                        }else{
                            return a + parseInt(obj[options.sumkey]);
                        }
                    }else{
                    }
                }, 0);
                if(sum){
                    setVal(sum.toLocaleString());
                    localStorage.setItem(options.cacehname, sum.toLocaleString() + "원");
                }
            }else{
                setVal(ret.length.toLocaleString());
                localStorage.setItem(options.cacehname, ret.length.toLocaleString());
            }
            localStorage.setItem(options.cacehname + "-to", moment().valueOf() + (3600 * 1000));
        }
    }

    return <>
        <div className="d-flex align-items-center justify-content-between">
            <div className={'badge bg-label-' + options.color + ' rounded-pill'} style={{ padding:'0.75rem' }}>
                <i className={"ti ti-" + options.icon + ' ti-sm'}></i>
            </div>
            <div className='w-100 ms-3'>
                <div className='h5 mb-0'>{val}</div>
                <div className='text-disabled text-sm'>{options.title}</div>
            </div>
        </div>
    </>
}

export default RMWidgetSmallCount;
