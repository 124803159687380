const initialState = { 
	applist : [],
	logs : [],
	dailylogs : [],
	statslog : [],
	selectedlogs : [],
	stats: [],
};

export const UsageConstant = {
	GET_RAW : "GET_RAW",
	GET_DAILY : "GET_DAILY",
	GET_APPLIST: "GET_APPLIST",
	GET_STATS: "GET_STATS",
	GET_SELECTED_LOGS: "GET_SELECTED_LOGS",
};

export const UsageReducer = (state = initialState, action) => {
	switch (action.type) {
		case UsageConstant.GET_RAW:
			return {...state, logs : action.items };
		case UsageConstant.GET_DAILY:
			return {...state, dailylogs : action.items };
		case UsageConstant.GET_APPLIST:
			return {...state, applist : action.items };
		case UsageConstant.GET_STATS:
			return {...state, stats : action.items };
		case UsageConstant.GET_SELECTED_LOGS:
			return {...state, selectedlogs : action.items };
		default:
			return state;
	}
};
