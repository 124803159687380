import axios from "axios";
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from "react";
import { BUTTONS, COLORS, INPUTS, SPAN } from '../../constants/FromInputs';
import { MLButton } from '../common/MLButton';
import { UploadFileS3, UploadS3 } from "../../libs/s3";
import { MLComboSingle, MLText } from "../common/MLInput";
import { MLForm } from "../common/MLForm";
import MLTable from "../common/MLTable";
import { ProgramConstant } from "../../redux/reducers";
import { Link, useParams } from 'react-router-dom';
import { InfoAction } from "../../redux/actions";
import { Store } from "../../store";

const getEnv = () => {
    if (location.href.indexOf("localhost") > -1 || location.href.indexOf("127.0.0.1") > -1) {
        return "dev-local";
    } else if (location.href.indexOf("raydevelop") > -1) {
        return "development";
    }
    return "production";
}

const ServiceConfigEmailTemplate = ({ UpdateApplication }) => {
    const [t] = useTranslation(['translation']);
    const dispatch = useDispatch();
    const partnerKey = localStorage.getItem('partnerKey') || '';
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(false);
    const [contents, setContents] = useState([]);
    const partner = process.env.REACT_APP_COMPANY || 'ray';
    const { _id = process.env.REACT_APP_COMPANY } = useParams();
    const emailTemplateCodes = useSelector(state => state.ProgramReducer.codes.filter(x => x.category === 'EMAIL_TEMP'));

    useEffect(() => {
        getConfig();
        getConsent();
    }, []);

    const getConfigPath = () => {
        const now = new Date().getTime();
        if (getEnv() === "production") {
            return "https://ray-data.s3.ap-northeast-2.amazonaws.com/rayrnd/rayteams-service/" + _id + "/config.json?t=" + now;
        } else {
            return "https://ray-data-development.s3.ap-northeast-2.amazonaws.com/rayrnd/rayteams-service/" + _id + "/config.json?t=" + now;
        }
    }

    const getConfig = async () => {
        try {
            const conf = await axios.get(getConfigPath());
            setData(conf?.data || {});
            dispatch({ type: ProgramConstant.SET_B2B_SERVICE, item: conf?.data || {} });
        } catch {
            setData({});
        }
    }

    const getConsent = async () => {
        try {
            const ret = await InfoAction.callDB({
                type: "scan", region: "ap-northeast-2", params: {
                    TableName: "rayteams-manager-contents",
                    FilterExpression: "#sk = :sk and #type = :type",
                    ExpressionAttributeNames: { "#sk": "sk", "#type": "type" },
                    ExpressionAttributeValues: { ":sk": 'info', ":type": 'EMAIL_TEMPLATE' },
                }
            });
            setContents(ret);
        } catch {
            setContents([]);
        }
    }

    const update = async (key, val) => {
        setLoading(true);
        const conf = await axios.get(getConfigPath());
        const ori_config = conf?.data || {};
        let newData = { ...data };
        await UploadS3(
            getEnv() === "production" ? "ray-data" : "ray-data-development",
            "rayrnd/rayteams-service/" + _id + "/config.json",
            { ...ori_config, em: { ...(ori_config.em || {}), [key]: val } }
        );
        await getConfig();
        setLoading(false);
    }

    const getVal = (val) => {
        if (data?.em) {
            return data?.em[val];
        }
        return '';
    }

    return <div className='p-4'>
        {emailTemplateCodes.map((x, idx) => {
            const _value = getVal(x.value);
            const _contentId = data?.em ? data?.em[x.value] : null;
            const _content = contents?.find(y => y._id === _contentId);
            const openCMS = () => window.open(`${window.location.origin}/Content/${_content?.bid}/${_content?._id}`);
            return <div key={idx} className="my-2">
                <div>
                    <div className="fw-bolder fs-14">{t(x.label)}</div>
                    <MLComboSingle options={{
                        list: contents.map(c => ({ value: c._id, label: c.title })),
                        value: _value,
                        isClearable: true,
                        disabled: loading
                    }} handleChanged={(e) => update(x.value, e.target.value)} />
                </div>
                {_content?._id && _content?.bid && <>
                    <div className="d-flex align-items-center justify-content-end">
                        <div className="d-inline-block text-primary cursor-pointer" onClick={openCMS}>
                            {t("CMS 확인")}
                        </div>
                    </div>
                </>}
            </div>
        })}
    </div>
}

export default ServiceConfigEmailTemplate;
