import axios from 'axios';
import { Modal } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BUTTONS, COLORS, INPUTS, SPAN } from '../../constants/FromInputs';
import { MLPanelLoading } from '../common/MLPanel';
import { MLComboSingle, MLToggle } from '../common/MLInput';
import { AuthConstant, getAPI, getHeader } from '../../redux/reducers/AuthReducer';
import { fetchData, queryData, idData, putData, updateData, delData } from '../../libs/db';
import { LicenseConstant, AppCategoryConstant } from '../../redux/reducers';
import { MLButton } from '../common/MLButton';
import { RMLICENSETYPE, RMNOTY, RMLICENSETYPE_MOCKUP } from '../../constants/RMConstants';
import { RTButton } from '../../libs/buttons';
import { getApiUrl } from '../../redux/reducers/AuthReducer';
import { JWT } from '../../config';
import { emailRegex } from '../../libs/regexs';
import { uniqBy } from 'lodash';
import { LicenseAction } from '../../redux/actions';
import { useTranslation } from 'react-i18next';
import { RTAudit } from '../../libs/audit';
import { InfoAction } from '../../redux/actions';

const RMModalAddApp = ({ modalData, loginuser, callbackClose, pools, lcwhitelist, lctypemanagergroup, lcblacklist }) => {
	const dispatch = useDispatch();
	const [t] = useTranslation(['translation']);
	const [ help, setHelp ] = useState(window.localStorage.getItem("helpmsg") === "N" ? false : true);
	const [data, setData] = useState({ appname : "", expiredPeriod : 365, lcCount : 5, region : "" , groupid : "", type : "pool", licensetype : "", reason : "" });
	const [loading, setLoading] = useState(false);
	const [productInfo, setProductInfo] = useState({});
	const [licenseType, setLicenseType] = useState([]);
	const [remainTypes, setRemainTypes] = useState([]);
	const [lError, setlError] = useState(false);
	const [userList, setUserList] = useState([]);
	const [email, setEmail] = useState("");
	const { _id } = useParams();
	const mgroup = JSON.parse(localStorage.getItem("mgroup"));
	const RMLCTYPES = RMLICENSETYPE;//_id && _id.includes("DigitalSmileDesignInAppExportMockUp") ? RMLICENSETYPE_MOCKUP : RMLICENSETYPE;

	const partner = process.env.REACT_APP_COMPANY || 'ray';

	useEffect(() => {
		mgroup && GetManagerGroupLcTypes();
	}, [])

	useEffect(() => {
		if(modalData?.title) {
			getProductId();
			getTypePeriod();
			getLcWhiteList();
			getLcBlackList();
		}
		setUserList([]);
		setlError(false);
	}, [_id, modalData.show]);

	useEffect(() => {
		setUserList([]);
		setEmail("");
	}, [data.licensetype]);

	const GetUserByEmail = async (data) => {
		const API_URL = getApiUrl('user');
		console.log("Get User bY email : ======", API_URL);
		const ret = await axios.post(API_URL + '/getuserbyemail', data, JWT());
		if (ret.data.status === 'success') {
			return ret.data.data;
		}else{
			return {};
		}
	}

	const getGroup = async (groupid, userRegion) => {
		//setLoading(true);
		try{
			const ScanFilter = {
				_id: {
					ComparisonOperator: "EQ",
					AttributeValueList: [groupid],
				},
				sk: {
					ComparisonOperator: "EQ",
					AttributeValueList: ["info"],
				},
			}
			const ret = await fetchData("rayteams-group", ScanFilter, userRegion);
			return ret.Items[0];
		}catch{
			return [];
		}
		//setLoading(false);
    }

	const checkUser = async (chk) => {
        var ret = await GetUserByEmail({ email : chk });
		if (ret.groupId) {
			data.groupid = ret.groupId
			ret = {...ret, group : await getGroup(ret.groupId, ret.region == "ap-east-1" && ret.region)};
			if (mgroup?.countries?.length > 0) {
				const te = mgroup.countries.map(x => {
				 	return x.countryCode == ret?.group?.countryCode && true
				});

				console.log(te.indexOf(true) > -1);
				if (!(te.indexOf(true) > -1)) {
					setUserList(prev => prev.map(x => x.email === chk ? {...ret, checking : false, res : false, reason : "Other country" } : x));
					return;
				}
			};
			setUserList(prev => prev.map(x => x.email === chk ? {...ret, checking : false, res : true} : x));
			return ret;
		} else {
			setUserList(prev => prev.map(x => x.email === chk ? {...x, checking : false, res  : false, reason : "Not exist user"} : x));
		}
    }

	const getLcWhiteList = async () => {
		dispatch(LicenseAction.GetLcWhiteList(_id));
	};

	const getLcBlackList = async () => {
		dispatch(LicenseAction.GetLcBlackList(_id));
	};

	const GetManagerGroupLcTypes = async () => {
		dispatch(LicenseAction.GetManagerGroupLcTypes(_id));
	};

	const checkEmailStart = async () => {
		const demoWhiteList = lcwhitelist && lcwhitelist.find(f => f.sk.split(":")[2] == data.licensetype)?.list || [];
		const normalBlackList = lcblacklist && lcblacklist.find(f => f.sk.split(":")[1] == _id && f.sk.split(":")[2] == data.licensetype)?.list || [];
		if ((data.licensetype == "demo" || data.licensetype == "development") && !demoWhiteList.includes(email.trim().split("@")[1])) {
			alert(t("해당 도메인 사용자에게는 Demo, Development license를 부여 할 수 없습니다."));
			setEmail("");
		} else if (data.licensetype == "normal" && _id == "App-RAYFusion" && normalBlackList.includes(email.trim().split("@")[1])) {
			alert(t("해당 도메인 사용자에게는 Normal license를 부여 할 수 없습니다."));
			setEmail("");
		} else {
			setUserList([...userList, { email, checking : true }]);
			const cRet = await checkUser(email.trim());
			const lcUserInfo = pools.filter(f => f._id == _id && f.sk.indexOf("lcuse:") > -1 && f.sk.indexOf(data.licensetype) > -1 && f.sk.indexOf(cRet?._id) > -1);
			let checkPass = true;
			if (lcUserInfo) {
				for (const lcItem of lcUserInfo) {
					if(lcItem?.expired && lcItem.expired >= new Date().getTime()) {
						checkPass = false;
					}
				}
			}
			if (cRet && !checkPass) {
				setUserList(prev => prev.map(x => x.email === email.trim() ? {...x, res  : false, reason : "Already registered"} : x));	
			}
			setEmail("");

			if(partner == "ray") {
				// 해당 라이선스 타입이 No Re Subscription 이면
				const sData = {
					appId: _id,
					userId: cRet?._id,
					licenseType: data?.licensetype,
					countryCode: cRet?.group?.countryCode,
					groupId: cRet?.group?._id,
				}

				console.log("sData : ========", sData);

				const ret = await axios.post(getApiUrl("license") + "/license/resubcheck", {data: sData}, getHeader());
				const eResult = ret.data?.error;
				if(eResult) {
					alert(t("해당 라이선스 타입의 사용자는 재구독 할 수 없습니다."));
				}
			}
		}
    }

	const onKeyDown = (e) => {
		if(e.key === "Enter"){
            checkEmailStart();
            e.preventDefault();
        }
    }

	const getTypePeriod = async () => {
		setLoading(true);
		try{
			let params = {
				TableName: "rayteams-license",
				KeyConditionExpression: "#_id = :_id and begins_with(#sk, :sk)",
				ExpressionAttributeNames: { "#_id": "_id", "#sk": "sk" },
				ExpressionAttributeValues: { ":_id": _id, ":sk": "type" }
			}
	
			if (partner != "ray") {
				params = {
					...params,
					FilterExpression: "#b2b = :b2b",
					ExpressionAttributeNames: {
						...params.ExpressionAttributeNames,
						"#b2b": "b2b"
					},
					ExpressionAttributeValues: {
						...params.ExpressionAttributeValues,
						":b2b": partner
					}
				}
			}
			const sData = {
				type: "query",
				b2b: partner,
				params
			}

			const ret = await axios.post(getApiUrl("license") + "/license/getlicenseinfo", {data: sData}, JWT());

			setLicenseType(ret?.data?.data.filter(f => partner == "ray" ? !(!!f.sk.split(":")[2]) : !!f.sk.includes(partner)))
			const lcTypes = pools.filter(f => !f?.assigned && f.sk.includes("pl:") && (partner == "ray" ? (typeof f?.b2b == "undefined" || f?.b2b == partner ) : f?.b2b == partner)).map(m => { 
				return { value: m.sk.split(":")[1], label: (m._id === "App-RAYFusion" && m.period === 365 && m.sk.split(":")[1] === "normal") ? "Annual" : RMLCTYPES.find(f => f.value == m.sk.split(":")[1])?.label } 
			});

			let uniqueArray = uniqBy(lcTypes, "value");

			// 법인에 부여 된 라이선스 확인 필요.
			if (lctypemanagergroup.length > 0) {
				uniqueArray = RMLCTYPES.map(x => {
					return (lctypemanagergroup[0].hasOwnProperty(`t_${x.value}`) && {value: x.value, label: x.label})
				}).filter(f => f != false)
			} else {
				// App-RAYFusion 의 기존 normal period 365 의 갯수가 전체 소진 되면 licensetype에 annual 을 추가 해서 보여 준다.
				if (_id == "App-RAYFusion" && !uniqueArray.find(f => f.value == "normal"))
				{
					uniqueArray.push({
						value: "annual",
						label: "Annual"
					});
				}
			}

			if (_id == "DigitalSmileDesignInAppExportMockUp" || _id == "App-DigitalSmileDesignInAppExportMockUp") {
				uniqueArray = uniqueArray.filter(f => f.value != "normal").filter(t => t.value != "trial");
			}

			if (_id == "App-RAYFusion" && !uniqueArray.find(f => f.value == "demo"))
			{
				uniqueArray.push({
					value: "demo",
					label: "Demo"
				});
			}


			

			console.log("uniqueArray : =======", uniqueArray);

			uniqueArray = uniqueArray.filter(f  => f.value != "trial");

			setRemainTypes(uniqueArray);
		}catch(err){
			console.log(err);
		}
		setLoading(false);
	}

	const getProductId = async () => {
		console.log("getProductId : ====", modalData);
		const scanFilter = {
			appitems : {
				ComparisonOperator: "CONTAINS",
				AttributeValueList: [_id]
			},
			title : {
				ComparisonOperator: "BEGINS_WITH",
				AttributeValueList: [modalData.title]
			}
		}
		const ret = await fetchData("rayteams-product", scanFilter);
		const pRet = await idData("rayteams-product", ret.Items[0]?._id);
		setProductInfo(pRet.Items.filter(f => f.sk.indexOf(`price:${partner}`) > -1 ));
	}

	const addSub_lambda = async () => {
		setLoading(true);
		const notyarr = [];
		var hasKey = true;
		for (let index = 0; index < userList.length; index++) {
			const userInfo = userList[index];
			if(userInfo?._id && userInfo?.res === true && userInfo?.group) {
				const userData = [{
					_id: userInfo._id,
					name: userInfo.name,
					email: userInfo.email,
					valid: true,
					email_verified: userInfo?.email_verified,
					region: userInfo?.region,
					notyMethods: notyarr,
					reason: data?.reason
				}]
				const sData = {
					appId: _id,
					user: {
						_id: localStorage.getItem("usersub"),
						name: loginuser.name,
						email: loginuser.email,
						managergroupid: mgroup.countries.length > 0 ? mgroup._id : ""
					},
					group: {
						_id: userInfo.group._id,
						countryCode: userInfo.group.countryCode
					},
					product: {
						_id: productInfo[0]._id,
						title: modalData.title,
						realprice: productInfo[0]?.realprice || 0,
						punit: productInfo[0]?.punit || "USD",
						licenseType: data?.licensetype,
						status: "sub",
						period: parseInt(licenseType.find(f => f.licensetype === data.licensetype)?.period || 0), // product 생성 시 period
						count: 1,
						dueDate: parseInt(licenseType.find(f => f.licensetype === data.licensetype)?.dueDate || 0),
					},
					toUsers: userData
				}


				console.log("sData : ========", sData);

				const ret = await axios.post(getApiUrl("license") + `/license/${partner == "ray" ? "addsubscribebyray" : "addsubscribebyb2b"}`, {data: sData}, getHeader());
				const eResult = ret.data?.error;
				if(eResult) {
					hasKey = false;
				}
			}
		}

		if (hasKey) {
			getData();
			getData_pool();
			setLoading(false);
			setlError(false);
			callbackClose && callbackClose();
		} else {
			setlError(true);
			setLoading(false);
		}
		RTAudit({ lvl : 7, msg : 'Granting a License by ray' }, JSON.stringify({ subject : data }))
	};

	const addSub_lambda_b2b = async ()  => {
		setLoading(true);
		const notyarr = [];
		var hasKey = true;
		for (let index = 0; index < userList.length; index++) {
			const userInfo = userList[index];
			if(userInfo?._id && userInfo?.res === true && userInfo?.group) {
				const userData = [{
					_id: userInfo._id,
					name: userInfo.name,
					email: userInfo.email,
					valid: true,
					email_verified: userInfo?.email_verified,
					region: userInfo?.region,
					notyMethods: notyarr,
					reason: data?.reason
				}]
				const sData = {
					appId: _id,
					user: {
						_id: localStorage.getItem("usersub"),
						name: loginuser.name,
						email: loginuser.email,
						managergroupid: mgroup?.countries.length > 0 ? mgroup?._id : ""
					},
					group: {
						_id: userInfo.group._id,
						countryCode: userInfo.group.countryCode
					},
					product: {
						_id: productInfo[0]._id,
						title: modalData.title,
						realprice: productInfo[0]?.realprice || 0,
						punit: productInfo[0]?.punit || "USD",
						licenseType: data?.licensetype,
						status: "sub",
						period: parseInt(licenseType.find(f => f.licensetype === data.licensetype)?.period || 0), // product 생성 시 period
						count: 1,
						dueDate: parseInt(licenseType.find(f => f.licensetype === data.licensetype)?.dueDate || 0),
					},
					toUsers: userData,
					b2b: partner
				}
				const time = new Date().getTime();
				const ret = await axios.post(getApiUrl("license") + `/license/addsubscribebyb2b`, {data: sData}, getHeader());
				// 리턴 받은 라이선스 정보를 graphy rayteams-user 에 업데이트.
				await addSubscribeInfoToUser(time, userInfo, sData.group, sData.product, ret.data?.data[0], partner);
				const eResult = ret.data?.error;
				if(eResult) {
					hasKey = false;
				}
			}
		}

		if (hasKey) {
			getData();
			getData_pool();
			setLoading(false);
			setlError(false);
			callbackClose && callbackClose();
		} else {
			setlError(true);
			setLoading(false);
		}
		RTAudit({ lvl : 7, msg : 'Granting a License by b2b' }, JSON.stringify({ subject : data }))
	}

	const addSubscribeInfoToUser = async (time, user, group, product, assign, b2b) => {
		const appsLcuse = [];
		let retobjs = [];
		retobjs.push(assign);
		retobjs.map(x => {
			const lcs = x.find(y => y.data.sk.indexOf("lcuse:") > - 1);
			const pl = x.find(y => y.data.sk.indexOf("pl:") > - 1);
			if(lcs && pl){
				appsLcuse.push({
					app : lcs.data._id,
					lcKey : lcs.data.sk,
					sk: pl.data.sk
				});
			}
		});

		const ret = await InfoAction.callDB({ type : "update", region : "ap-northeast-2", params : {
			TableName: "rayteams-user",
			UpdateExpression: 'SET #title = :title, #started = :started, #lastrenew = :lastrenew, #expired = :expired, #ccode = :ccode, #punit = :punit, #realprice = :realprice, #count = :count, #appsLcuse = :appsLcuse, #canceled = :canceled, #lastrenewdata = :lastrenewdata, #status = :status, #statustype = :statustype, #created = :created, #b2b = :b2b',
			ExpressionAttributeNames: { 
				'#title' : 'title', 
				'#started' : 'started', 
				'#lastrenew' : 'lastrenew', 
				'#expired' : 'expired', 
				'#ccode' : 'ccode', 
				'#punit' : 'punit', 
				'#realprice' : 'realprice', 
				'#count' : 'count', 
				'#appsLcuse' : 'appsLcuse', 
				'#canceled' : 'canceled', 
				'#lastrenewdata' : 'lastrenewdata', 
				'#status' : 'status', 
				'#statustype' : 'statustype', 
				'#created' : 'created', 
				'#b2b' : 'b2b'
			},
			ExpressionAttributeValues: { 
				':title' : product.title, 
				':started' : time, 
				':lastrenew' : time, 
				':expired' : time + (product.period * 24 * 3600 * 1000), 
				':ccode' : group.countryCode, 
				':punit' : product?.punit || "USD", 
				':realprice' : parseInt(product?.realprice || 0), 
				':count' : parseInt(product?.count || 1), 
				':appsLcuse' : appsLcuse, 
				':canceled' : 0, 
				':lastrenewdata' : {_id : user._id, product, pg: "GRAPHY"}, 
				':status' : product?.status || "sub", 
				':statustype' : product?.licenseType || "normal", 
				':created' : time, 
				':b2b' : b2b
			},
			Key: { _id: user._id, sk: `product:${product._id}:${time}` }
		}});

		return ret;
	};

	useEffect(() => {
		setData({ appname : "", expiredPeriod : 365, lcCount : 5, region : "" , groupid : "", type : "pool", licensetype : "" });
		if(modalData?.appinfo?._id) {
			setData({...modalData?.appinfo, appname : modalData?.appinfo?._id});
		}
	}, [modalData.show]);

	const getData = async () => {
		setLoading(true);
		try{
			const sData = {
				type: "licenseDataQuery",
				_id: _id,
				sktype: "lcuse",
				mgroup: mgroup || [],
				b2b: process.env.REACT_APP_COMPANY || "ray"
			}
			const ret = await axios.post(getApiUrl("license") + "/license/getlicenseinfo", {data: sData}, JWT());
			dispatch({ type: AppCategoryConstant.GET_ALL_APP, items: [] });
			dispatch({ type: AppCategoryConstant.GET_ALL_APP, items: ret?.data?.data });
			
		}catch(err){
			console.log(err);
		}
		setLoading(false);
    }

	const getData_pool = async () => {
		setLoading(true);
		try {
			const sData = {
				type: "licenseDataScan",
				_id: _id,
				mgroup: mgroup || [],
				b2b: process.env.REACT_APP_COMPANY || "ray"
			}
			const ret = await axios.post(getApiUrl("license") + "/license/getlicenseinfo", {data: sData}, JWT());

			dispatch({ type: LicenseConstant.GET_ALL_POOL, items: [] });
			dispatch({ type: LicenseConstant.GET_ALL_POOL, items: ret?.data?.data });
		} catch (err) {
			console.log(err)
		}
		setLoading(false);
	}

	const getReSub = (tp) => {
		const resub = pools?.find(x => x.sk === "type:" + tp)?.noresub;
		return resub ? "No ReSubscription" : "";
	}

	const getAutoSub = (tp) => {
		const autosub = pools?.find(x => x.sk === "type:" + tp)?.autosub;
		return autosub ? "Auto Subscription" : "";
	}

	return <>
	<div className=''>
		<Modal show={modalData.show} size={"lg"} style={{ backgroundColor: "#42476c !important"}}>
			<Modal.Header className='modal-header header-bg'>
				{modalData.userChange ? <h4>License 변경
					<small className={"ms-2 fs-7 fw-normal opacity-50 "}>{t("해당 License 를 선택한 사용자에게 추가할 수 있습니다.")}</small>
				</h4> : <h4>License 부여
					<small className={"ms-2 fs-7 fw-normal opacity-50 "}>{t("선택한 사용자에 License 를 부여 할 수 있습니다.")}</small>
					{getReSub(data?.licensetype) == "" ? "" : <span className="badge bg-secondary ms-3">{getReSub(data?.licensetype)}</span>}
					{getAutoSub(data?.licensetype) == "" ? "" : <span className="badge bg-secondary ms-3">{getAutoSub(data?.licensetype)}</span>}
				</h4>}
			</Modal.Header>
			<Modal.Body className='flex-fill pt-0 formcard'>
                {help && <div className="card bg-light text-dark mb-2">
                    <div className="card-body p-4">
                        <b>{t("License Type")}</b> : {t("사용자에게 부여할 License Type을 지정합니다.")}<br />
                        <b>{t("사용자")}</b> : {t("License를 부여할 사용자들을 선택합니다.")} {t("(복수 가능)")}<br />
						<b>{t("검색")}</b> : {t("Email 로 사용자 를 검색 합니다.")}<br />
                        <span className="fw-bolder text-primary">{t("Add")}</span> : {t("선택된 사용자에게 1개씩 Stock에 있는 License를 부여해줍니다.")}<br />
                    </div>
                </div>}
				{remainTypes && data?.licensetype && <div className="card bg-light mb-2">
						<div className="card-body" >
							{/* <div className="text-dark fs-3">
								이곳은 사용자에게 부여된 License 목록을 보여주는 곳입니다.<br />
							</div> */}
							{(_id === "App-RAYFusion" && data?.licensetype == "normal") ? RMLCTYPES.find(f => f.value === "annual")?.desc : RMLCTYPES.find(f => f.value === data.licensetype)?.desc}
						</div>
					</div>}
				{loading && <MLPanelLoading contents={t("라이선스를 부여하고 있습니다.")} />}
				{!loading && lError && <>
					<div className="d-flex align-items-center w-100 text-center">
						<div className='mx-auto'>
							<div className='mb-2'>
								<h2>Error</h2>
								<div className='text-comment'>{t("라이선스 부여 중 문제가 발생했습니다. 관리자에게 문의 해주시기 바랍니다.")}</div>
							</div>
						</div>
					</div>
				</>
				}
				{!loading && !lError && <>

				<form className="form pt-1">
					<div className='fs-6 my-2 required'>
						License Type
					</div>
					<div className="card-body px-0 pt-0 pb-0">
					{remainTypes && <MLComboSingle options={{
								value: data ? data.licensetype : [],
								list : remainTypes,
								showalloption: false
						}} handleChanged={(e) => {setData({...data, licensetype: e.target.value}); console.log(e.target.value); console.log(_id);} } css="w-100 me-2"  />
					}</div>
					<div className='fs-6 my-2 pt-4 required'>
						{t("사용자")}
					</div>
					<div className="card-body px-0 pt-0 pb-0">
						<div className="input-group mb-3 w-100">
							<input type="email" 
								onKeyDown={onKeyDown} 
								placeholder={'Enter email address'} 
								autoComplete={'off'}
								onChange={(e) => setEmail(e.target.value)} 
								value={email} 
								className="form-control form-control-sm me-2" required="" 
							/>
							<div className="input-group-append">
								<MLButton options={{
									label: t("검색"),
									color: COLORS.PRIMARY,
									action: checkEmailStart,
									needValid: true,
								}} valid={emailRegex.test(email.trim())} />
							</div>
						</div>
					</div>
					<ul className='list-group'>
						{userList.map((x, idx) => <li className='list-group-item  d-flex justify-content-between align-items-center' key={idx}>
							{x.checking && <>
								{x.email}
								<div className="list-btn circle">
									<div className="spinner" style={{ cursor: "initial" }} title="check">
										<span className="spinner-border spinner-border-lg align-middle"></span>
									</div>
								</div>
							</>}
							{!x.checking && <>
								{x.res && <>
									[{x.group?.name}] {x?.name}({x.email}) 
									<span className='btn-xs btn text-danger shadow-none' onClick={() => setUserList(userList.filter(u => u._id !== x._id))}>{t("remove")}</span>
								</>}
								{!x.res && <>
									<span className='text-danger'>{x.email}</span>
									<span className='text-muted'>{t(x.reason)}</span>
								</>}
							</>}
						</li>)}
					</ul>
					<div className='fs-6 my-2 pt-1 required'>
						{t("사유")} &nbsp; {`(${data?.reason?.length || 0} / 12 자 이상 작성 해 주세요.)`}
					</div>
					<div className="card-body px-0 pt-0 pb-0">
						<div className="input-group mb-1 w-100" style={{height: 40}}>
							<input type="text" 
								placeholder={t("Enter reason")} 
								autoComplete={'off'}
								onChange={(e) => setData({...data, reason:e.target.value})}
								value={data?.reason} 
								className="form-control form-control-sm" required="" 
							/>
						</div>
						<div className='fs-6 text-muted'><p>
							&nbsp; - &nbsp; '구체적으로 자세한 내용을 작성해주세요. 길이 제한 없음'<br></br>
							&nbsp; - &nbsp; 예시) 병원에서 검증을 위해서 사용해보고자 함(미결제), OOO 전시회에서 시연을 위함 등'
							</p>
						</div>
					</div>
				</form>
				</>}
				<div className='mt-4 text-end'>
					<MLButton
						options={{ label : t("Cancel"), size : "md", color : COLORS.DEFAULT, action : () => callbackClose()}}
						css={'me-1'} />
					{!lError && <RTButton options={{
						label : loading ? t('Please wait...') : t('Add'),
						size : 'MD',
						ing : loading,
						needValid : true,
						action : () => partner == "ray" ? addSub_lambda() : addSub_lambda_b2b(), //addSub_stepfunc(),
						disabled : (userList.filter(x => x.res).length === 0 || !data?.licensetype || !data?.reason || data?.reason?.length < 12)
					}} css="me-1" />
					}
				</div>
			</Modal.Body>
		</Modal>
	</div>
	</>
}

const mapState = (state) => {
	const loginuser = state.AuthReducer.loginuser;
	const users =  state.AuthReducer.users;
	const groups =  state.AuthReducer.groups;
	const pools = state.LicenseReducer.pools;
	const managedApp = state.AppCategoryReducer.managedApp;
	const lcwhitelist = state.LicenseReducer.lcwhitelist;
	const lctypemanagergroup = state.LicenseReducer.lctypemanagergroup;
	const lcblacklist = state.LicenseReducer.lcblacklist;
	return { loginuser, users, groups, pools, managedApp, lcwhitelist, lctypemanagergroup, lcblacklist };
};

const mapDispatch = dispatch => ({
	
})

export default connect(mapState, null)(RMModalAddApp);
