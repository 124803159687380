import AWS from 'aws-sdk';
const { S3Client, PutObjectCommand } = require("@aws-sdk/client-s3");
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import { Store } from '../store';
import { Buffer } from 'buffer';
import { COGADMIN, COGNITO } from './cogInfo';
import { COGADMIN_GRAPHY, COGNITO_GRAPHY } from './cognito-graphy';

const getAWSCredential = async () => {
    const user = Store.getState().AuthReducer.user;
    if (!user?.pc) {
        return null;
    }
    const pc = Buffer.from(user.pc, "base64").toString('utf8');
    const cp = pc.split("|rayteams|");

    if (cp.length !== 2) {
        return null;
    }
    return {
        accessKeyId: cp[0],
        secretAccessKey: cp[1],
        region: "ap-northeast-2",
    };
};

export const UploadS3 = async (bucket, keypath, data) => {
    const s3 = new AWS.S3(await getAWSCredential());
    const ret = await s3.upload({
        Bucket: bucket,
        Key: keypath,
        Body: JSON.stringify(data),
        ACL: 'public-read'
    }).promise();

    return ret;
}

export const ObjectList = async (bucket, prefix) => {
    const s3 = new AWS.S3(await getAWSCredential());
    const ret = await s3.listObjectsV2({
        Bucket: bucket,
        Prefix: prefix
    }).promise();

    return ret.Contents;
}
export const UploadFileS3 = async (bucketName, file, key) => {
    try {
        const cgAdmin = process.env.REACT_APP_COMPANY === 'graphy' ? COGADMIN_GRAPHY : COGADMIN;
        const cred = fromCognitoIdentityPool({
            clientConfig: { region : "ap-northeast-2" },
            identityPoolId : cgAdmin.cogPoolId,
            logins: { [cgAdmin.cogId]: localStorage.getItem("token") }
        })
        const s3Client = new S3Client({
            region : "ap-northeast-2",
            credentials: cred
        });
        const uploadParams = {
            Bucket: bucketName,
            Key: key,
            Body: file,
            ACL: 'public-read'
        };
        await s3Client.send(new PutObjectCommand(uploadParams));
        console.log(`File uploaded successfully. s3://${bucketName}/${key}`);
        return true;
    } catch (err) {
        console.error("Error uploading file: ", err);
        return false;
    }
}