import axios from 'axios';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { TbDental } from 'react-icons/tb';
import { MLPanelLoading } from '../components/common/MLPanel';
import { AuthConstant, getAPI, getHeader } from '../redux/reducers/AuthReducer';
import { history } from '../history';
import { COUNTRIES } from '../constants/Country';
import RMUserList from '../components/user/RMUserList';
import RMProjectList from '../components/project/RMProjectList';
import RMRayteamsApp from '../components/rayteams/RMRayteamsApp';
import RMRayteamsDevice from '../components/rayteams/RMRayteamsDevice';
import RMGroupConfig from '../components/group/RMGroupConfig';
import { axiosGlobal } from '../libs/axiosConfig';
import { idData, queryData } from '../libs/db';
import { useTranslation } from 'react-i18next';
import { MLForm } from '../components/common/MLForm';
import { Store } from '../store';

const GroupInfoPage = ({ group }) => {

	const [t] = useTranslation(['translation']);
	const dispatch = useDispatch();
	const { _id, tab = "user" } = useParams();
	const [loading, setLoading] = useState(true);
	const perms = Store.getState().AuthReducer?.loginuser?.perms || [];
	const tabs = useMemo(() => {
		let _tabs = [
			{ value: "user", label: "Users" },
			{ value: "project", label: "Cases", code: "ins-cases" },
			{ value: "cfg", label: "Config", code: "ins-config" },
			//{value : "lic", label : "Licenses"},
			//{value : "contract", label : "Contract"},
			//{value : "app", label : "App"},
			{ value: "device", label: "Device", code: "ins-device" },
		];
		const isRay = process.env.REACT_APP_COMPANY === 'ray' || !process.env.REACT_APP_COMPANY;
		if (!isRay) {
			const allowB2bTabs = ["user", "project"];
			_tabs = _tabs.filter(x => allowB2bTabs.includes(x.value));
		}
		if (group?.extraForm) {
			_tabs.push({ value: "signupForm", label: "Extra Info", code: "ins-extra" });
		}
		return _tabs;
	}, [group?.extraForm]);

	useEffect(() => {
		getData();
	}, [_id]);

	const getData = async () => {
		try {
			const ret = await idData("rayteams-group", _id);
			const relatedGroups = [];
			const extra = [];
			let item;

			if (ret.Count == 0) {
				const cnRet = await queryData("rayteams-group", {
					_id: {
						ComparisonOperator: "EQ",
						AttributeValueList: [_id]
					},
					sk: {
						ComparisonOperator: "EQ",
						AttributeValueList: ["info"]
					}
				}, "ap-east-1");

				for (const row of cnRet.Items) {
					if (row.sk === "info") {
						item = row;
						continue;
					}

					if (row.sk.startsWith("related:")) {
						const _gid = row.sk.replace("related:", "");
						relatedGroups.push(_gid);
						continue;
					}

					extra.push(row);
				}
				if (item?._id) {
					item.relatedGroups = relatedGroups;
					item.extra = extra;
				}
			} else {
				for (const row of ret.Items) {
					if (row.sk === "info") {
						item = row;
						continue;
					}

					if (row.sk.startsWith("related:")) {
						const _gid = row.sk.replace("related:", "");
						relatedGroups.push(_gid);
						continue;
					}

					extra.push(row);
				}
				if (item?._id) {
					item.relatedGroups = relatedGroups;
					item.extra = extra;
				}
			}

			dispatch({ type: AuthConstant.GET_GROUP, item: item });
		} catch {
			dispatch({ type: AuthConstant.GET_GROUP, item: {} });
		}
		setLoading(false);
	}

	return <div className='p-4'>
		{loading && group?._id && <>
			<MLPanelLoading />
		</>}
		{!loading && (group?._id === _id) && <>
			<div className='card'>
				<div className='card-header pb-0'>
					<div className='d-flex justify-content-between'>
						<h2 className='mb-2'>
							{group.name ? group.name : "Group Info"}
							{group?.grouptype?.length > 0 && <span className="text-muted fs-5 ms-2">{group?.grouptype.map(x => x.toUpperCase()).join(", ")}</span>}
							{group?.grouptype?.length === 0 && <span className="text-muted fs-5 ms-2">{t('Not defined group type')}</span>}
						</h2>
						<div>
							<span className='ms-2 text-muted'>{t('Registered')} : {moment(group.created).format('LL')}</span>
						</div>
					</div>
					<div>
						<span className='badge bg-dark me-2'>
							{COUNTRIES.find(x => x.countryCode === group.countryCode)?.name}
						</span>
						{group?.address}
					</div>

					<ul className="nav nav-tabs nav-stretch border-top mt-4 pt-2" style={{ borderRadius: "0" }}>
						{tabs.map((x, idx) => {
							if (x.value === "user" || perms.includes(x.code)) {
								return <li className="nav-item" key={idx} onClick={() => history.push("/GroupInfo/" + group._id + "/" + x.value)}>
									<Link to="#" className={"nav-link " + (tab === x.value ? 'active' : '')}>{t(x.label)}</Link>
								</li>
							}
							return null;
						})}
					</ul>
				</div>
				<div className="card-body p-0">
					{tab === "user" && <RMUserList group={group} />}
					{tab === "project" && <RMProjectList group={group} />}
					{tab === "cfg" && <RMGroupConfig group={group} />}
					{/*tab === "lic" && <RMLicenseListGroup group={group} /> */}
					{/*tab === "contract" && <RMGroupContractList group={group} /> */}
					{/*tab === "app" && <RMRayteamsApp group={group} /> */}
					{tab === "device" && <RMRayteamsDevice group={group} section={false} />}
					{tab === "signupForm" && group?.extraForm && <MLForm controls={group?.extraForm?.controls || []} data={group?.extraFormData || {}} readonly={true} />}
				</div>
			</div>
		</>}
	</div>
}

const mapState = (state) => {
	const group = state.AuthReducer.group;
	return { group };
};

const mapDispatch = (dispatch) => ({

});

export default connect(mapState)(GroupInfoPage);
