import { Modal } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BUTTONS, COLORS, INPUTS, SPAN } from '../../constants/FromInputs';
import { MLButton } from '../../components/common/MLButton';
import { AuthConstant, getAPI, getHeader, getAdminCreateHeader } from '../../redux/reducers/AuthReducer';
import { MLRadioButton, MLToggle } from '../../components/common/MLInput';
import { RMMENUS, RMBTNS } from '../../constants/RMConstants';
import { updateData, idData, queryData, fetchData } from '../../libs/db';
import { RTAudit } from '../../libs/audit';
import { useTranslation } from 'react-i18next';
const RMPerms = ({ uData, user, loginuser }) => {
    const userdata = JSON.parse(localStorage.getItem("userdata"));
    const usersub = localStorage.getItem("usersub");
    const dispatch = useDispatch();
    const { _id } = useParams();
    const [loading, setLoading] = useState(false);
    const [selectedMenu, setMenu] = useState([]);
    const [selectedButton, setButton] = useState([]);
    const [t] = useTranslation(['translation']);


    useEffect(() => {
        if (_id) {
            setMenu([]);
            getUserRole(_id);
        }
    }, [_id]);

    const updateRole = async () => {
        setLoading(true);
        let okmenus = [];
        Object.keys(selectedMenu).map(x => {
            if (selectedMenu[x])
                okmenus.push(x);
        });
        let okbtns = [];
        Object.keys(selectedButton).map(x => {
            if (selectedButton[x])
                okbtns.push(x);
        });
        try {
            const ret = await updateData("rayteams-manager-user", {
                _id: _id,
                sk: "info"
            }, {
                menu: okmenus,
                perms: okbtns,
            });
        } catch (err) {
            console.log(err);
        }
        if (_id === usersub) {
            dispatch({ type: AuthConstant.GET_LOGINUSER_ROLE, items: { menu: okmenus, perms: okbtns } })
        }
        RTAudit({ lvl: 7, msg: '[' + uData.name + '] permission updated' }, JSON.stringify({ menu: okmenus, perms: okbtns }))
        setLoading(false);
    }

    const getUserRole = async () => {
        setLoading(true);
        try {
            const ret = await queryData("rayteams-manager-user", {
                _id: {
                    ComparisonOperator: "EQ",
                    AttributeValueList: [_id]
                },
                sk: {
                    ComparisonOperator: "EQ",
                    AttributeValueList: ["info"]
                }
            });
            if (ret.Items[0]?.menu) {
                ret.Items[0]?.menu.map((x) => {
                    setMenu(pMenu => ({ ...pMenu, [x]: true }))
                })
            }
            if (ret.Items[0]?.perms) {
                ret.Items[0]?.perms.map((x) => {
                    setButton(pMenu => ({ ...pMenu, [x]: true }))
                })
            }
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    }

    return <div className=''>
        <div className="card-body">
            {RMMENUS.filter(x => (!process.env.REACT_APP_COMPANY || process.env.REACT_APP_COMPANY === 'ray') ? true : !x.onlyray).filter(x => x.page !== "-").map((x, idx) => {
                return (
                    <div id={"m-" + idx}>
                        <div className="py-2">
                            <MLToggle
                                options={{
                                    label: x.title,
                                    name: x.code,
                                    value: selectedMenu[x.code],
                                    readonly: false,
                                    inline: true
                                }}
                                handleChanged={({ target: { name, value } }) => { setMenu(prev => ({ ...prev, [name]: value })); }}
                                css="me-4" />
                        </div>
                        {(selectedMenu[x.code] && RMBTNS.filter(b => b.scope === x.code).length > 0) && <ul className="list-group">
                            {RMBTNS.filter(b => b.scope === x.code && ((!process.env.REACT_APP_COMPANY || process.env.REACT_APP_COMPANY === 'ray') ? true : !b.onlyray)).map(b => <li className="list-group-item">
                                <div className="d-flex align-items-center">
                                    <MLToggle
                                        options={{
                                            label: b.title,
                                            name: b.code,
                                            value: selectedButton[b.code],
                                            labelCss: "ms-4",
                                            labelStyle: { width: '200px' },
                                            readonly: false,
                                            inline: true
                                        }} css={{}} handleChanged={({ target: { name, value } }) => { setButton(prev => ({ ...prev, [name]: value })); }} />
                                    <span className="">{b.comment}</span>
                                </div>
                            </li>)}
                        </ul>}
                    </div>
                )
            })}
        </div>
        <div className="card-footer">
            <MLButton options={{
                code: "ac-editperm",
                label: t('Apply'),
                color: COLORS.INFO,
                action: () => updateRole()
            }} css="me-1" />
        </div>
    </div>
}

const mapState = (state) => {
    const adminUsers = state.AuthReducer.adminUsers;
    const user = state.AuthReducer.user;
    const loginuser = state.AuthReducer.loginuser
    return { adminUsers, user, loginuser };
};

const mapDispatch = dispatch => ({
})

export default connect(mapState, mapDispatch)(RMPerms);
