import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { MLButton, MLRefreshButton, MLChartButton } from '../../components/common/MLButton';
import { AuthConstant } from '../../redux/reducers';
import { GrBarChart } from 'react-icons/gr';
import { FaRegListAlt } from "react-icons/fa";
import { ProgramConstant } from '../../redux/reducers';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { RTAudit } from '../../libs/audit';
import { useTranslation } from 'react-i18next';
import { history } from '../../history';
const RMToolbar = ({ loginuser, user, page }) => {

	const [t] = useTranslation(['translation']);
	const dispatch = useDispatch();
	const [uInfo, setUInfo] = useState({});

	const logout = () => {
		RTAudit({ lvl : 5, msg : 'Logged out.' });
		localStorage.clear();
		dispatch({ type : AuthConstant.LOGOUT });
    }

	useEffect(() => {
		const uinfo = localStorage.getItem("userdata");
		if(uinfo){
			try{
				setUInfo(JSON.parse(uinfo));
			}catch{
				logout();
			}
		}
	}, []);

	useEffect(() => {
		if(!loginuser){
			logout();
		}
	}, [loginuser])

	const pageOptionClick = () => {
		dispatch({ type : ProgramConstant.SET_PAGE, page : {...page, view : (page.view === "summary" ? "list" : "summary" )}})
	}

	const managerUserInfo = () => {
		console.log("loginuser info : ====", loginuser);
		history.push("/ManagerUserInfo/" + loginuser?.sub);
	}

    return <nav className='layout-navbar navbar navbar-expand-xl align-items-center mb-n3 shadow-none'>
		<div className='navbar-nav-right d-flex align-items-center ms-4'>
			<h4 className='mb-0'>{page.title}</h4>
			{page.small && <small className='mt-1 ms-2'>{page.small}</small>}
		</div>
		<div className='navbar-nav-right d-flex align-items-center me-4'>
			<ul className='navbar-nav flex-row align-items-center ms-auto'>
				{page?.options?.filter(x => x.view !== page.view).map((x, idx) => <a id={`${idx}-${x.view}`} key={`${idx}-${x.view}`} href="#" className={"btn pulse shadow-none btn-md btn-dark"} onClick={pageOptionClick} >
					{x.view === "summary" && <><i className="ti ti-chart-histogram fs-5 me-2"></i> {t("그래프로 보기")}</>}
					{x.view === "list" && <><i className="ti ti-list-details fs-5 me-2"></i> {t("목록으로 보기")}</>}
					{!(x.view === "summary" || x.view === "list") && <span>{x.view}</span>}
				</a>)}
				<NavDropdown title={`${loginuser?.name}`} className="ms-3 text-dark fw-bold">
					<NavDropdown.Item onClick={managerUserInfo}>{t("My Info.")}</NavDropdown.Item>
					<NavDropdown.Divider />
					<NavDropdown.Item onClick={logout}>{t("Log out")}</NavDropdown.Item>
				</NavDropdown>
			</ul>
		</div>
    </nav>
}

const mapState = (state) => {
	const region =  state.AuthReducer.region;
	const regions =  state.AuthReducer.regions;
	const loginuser = state.AuthReducer.loginuser;
	const user = state.AuthReducer.user;
	const page = state.ProgramReducer.page;
	return { region, regions, loginuser, user, page };
};

const mapDispatch = (dispatch) => ({ });

export default connect(mapState, mapDispatch)(RMToolbar);
