import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { MLPanelLoading } from '../../components/common/MLPanel';
import MLTable from '../../components/common/MLTable';
import { ProgramConstant } from '../../redux/reducers';
import { RMLICENSETYPE, RMLICENSETYPE_MOCKUP } from '../../constants/RMConstants';
import moment from 'moment';
import LicenseExportWrapper from '../../pages/licenses/LicenseExportWrapper';

const LicenseStatList = ({ licenseStatData, pagestat, pools, config }) => {
	const dispatch = useDispatch();
	const [items, setItems] = useState([]);
    const [trialItems, setTrialItems] = useState([]);
	const [normalItems, setNormalItems] = useState([]);
    const [reluCredit, setReluCredit] = useState([]);
	const [loading, setLoading] = useState(true);
    const RMLCTYPES = RMLICENSETYPE;
    const partner = process.env.REACT_APP_COMPANY || 'ray';
    const reluCreditValue = 50000;
    const monthlySubReluCreditValue = 26;
	const yearlySubReluCreditValue = 248;
	const exRate = 1494.834;

	const reluCreditInfo = [
		{ minValue: 0,      maxValue: 25000,   monthFee: 26, yearFee: 248, discount: 0,  punit: "EUR" },
		{ minValue: 25001,  maxValue: 50000,   monthFee: 23, yearFee: 223, discount: 10, punit: "EUR" },
		{ minValue: 50001,  maxValue: 100000,  monthFee: 20, yearFee: 198, discount: 20, punit: "EUR" },
		{ minValue: 100001, maxValue: 200000,  monthFee: 18, yearFee: 173, discount: 30, punit: "EUR" },
		{ minValue: 200001, maxValue: 1000000, monthFee: 15, yearFee: 148, discount: 40, punit: "EUR" },
	]

	const { _id } = useParams();

	useEffect(() => {
		if(licenseStatData && licenseStatData.length > 0) {
			genData();
			getReluCreditData();
		} else {
			setLoading(false);
		}
	}, [licenseStatData]);

	const headers =  [
        { value: "date", label: "년/월", css: "text-start" },
        { value: "newUsers", label: "신규(무료)", css: "text-end" },
        { value: "cumulativeSum", label: "누적 무료 가입자", css: "text-end" },
        { value: "cancelUsers", label: "구독 취소", css: "text-end" },
        { value: "cumulativeCancelSum", label: "누적 구독 취소", css: "text-end" },
		{ value: "resubUsers", label: "재구독", css: "text-end" },
		{ value: "cumulativeReSub", label: "누적 재구독", css: "text-end" },
        { value: "renewUsers", label: "유료 전환", css: "text-end" },
        { value: "cumulativeRenewSum", label: "누적 유료 전환", css: "text-end" },
    ];

	const headers_trial = [
		{ value : "userid", label: "사용자", type : "USER", style: { width: 200 }, hide: typeof(config?.showalludata) === "boolean" ? !config?.showalludata : true },
		{ value : "sk", type: "HIDE", style: {width: 0} },
        { value : "ccode", label: "COUNTRY", type: "COUNTRY"},
		{ value : "groupid", label : "Group", type : "GROUP" },
		{ value : "assigned", label: "Assigned", type : "DATESHORT", style : { width : 150 }},
		{ value : "activated", label: "Activated", type : "DATESHORT", style : { width : 150 }},
        { value : "expired", label : "Expired", style : { width : 150 } },
    ];
	
	const headers_commercial = [
		{ value : "userid", label: "사용자", type : "USER", style: { width: 200 }, hide: typeof(config?.showalludata) === "boolean" ? !config?.showalludata : true },
		{ value : "sk", type: "HIDE", style: {width: 0} },
        { value : "ccode", label: "COUNTRY", type: "COUNTRY"},
		{ value : "groupid", label : "Group", type : "GROUP" },
		{ value : "assigned", label: "Assigned", type : "DATESHORT", style : { width : 150 }},
		{ value : "activated", label: "Activated", type : "DATESHORT", style : { width : 150 }},
        { value : "expired", label : "Expired", style : { width : 150 } },
	];

	//{ minValue: 200001, maxValue: 1000000, monthFee: 15, yearFee: 148, discount: 40 },
    const headers_ReluCreditInfo = [
		{ value: "discount", label: "Discount (%)", css: "text-start" },
		{ value: "minValue", label: "Credits Min", type: "CUR", curtype: "punit", css: "text-end" },
		{ value: "maxValue", label: "Credits Max", type: "CUR", curtype: "punit", css: "text-end" },
		{ value: "monthFee", label: "Monthly Fee", type: "CUR", curtype: "punit", css: "text-end" },
		{ value: "yearFee", label: "Annual Fee", type: "CUR", curtype: "punit", css: "text-end" },		
	];

    const headers_Relu = [
        { value: "date", label: "년/월", css: "text-start" },
        { value: "monthlyRenewUsers", label: "Month 갯수", css: "text-end" },
		{ value: "monthlyFee", label: "Month 단가", type: "CUR", curtype: "punit", css: "text-end" },
		{ value: "monthlySumCredit", label: "Month 차감액", type: "CUR", curtype: "punit", css: "text-end" },

		{ value: "yearlyRenewUsers", label: "Year 갯수", css: "text-end" },
		{ value: "yearlyFee", label: "Year 단가", type: "CUR", curtype: "punit", css: "text-end" },
		{ value: "yearlySumCredit", label: "Year 차감액", type: "CUR", curtype: "punit", css: "text-end" },

		{ value: "totalSumCredit", label: "월 갯수", css: "text-end" },
		{ value: "totalSumCreditFee", label: "월 차감액", type: "CUR", curtype: "punit", css: "text-end" },
		
		{ value: "cumulativeCredit", label: "누적 갯수", css: "text-end" },
		{ value: "cumulativeCreditFee", label: "누적 차감액", type: "CUR", curtype: "punit", css: "text-end" },
        { value: "remainCredit", label: "Credit 잔액", type: "CUR", curtype: "punit", css: "text-end" }
    ];

	const genData = () => {
        setItems(licenseStatData.sort((a, b) => new Date(a.date) - new Date(b.date)));
        genData_Pool();
	};

    const getReluCreditData = () => {
        const reluData = [];
		console.log("getReluCreditData : getReluCreditData : getReluCreditData : getReluCreditData : ", licenseStatData);
        licenseStatData.map(x => {
			const remainCredits = reluCreditValue - ((x.cumulativeMonthlyPayed * monthlySubReluCreditValue) + (x.cumulativeYearlyPayed * yearlySubReluCreditValue));
            reluData.push({
                date: x.date,
                monthlyRenewUsers: x.monthlyPayedUsers,
				yearlyRenewUsers: x.yearlyPayedUsers,
                monthlySumCredit: x.monthlyPayedUsers * monthlySubReluCreditValue,
				yearlySumCredit: x.yearlyPayedUsers * yearlySubReluCreditValue,
                remainCredit: remainCredits,
				totalSumCreditFee: (x.monthlyPayedUsers * monthlySubReluCreditValue) + (x.yearlyPayedUsers * yearlySubReluCreditValue),
				totalSumCredit: x.monthlyPayedUsers + x.yearlyPayedUsers,
				punit: "EUR"
            })
        });

		// 누적 값을 계산
        const resultArray = [];
        let cumulativeCreditFee = 0;
		let cumulativeCredit = 0;
		let cumulativeMonthlyCreditFee = 0;
		let cumulativeMonthlyCredit = 0;
		let cumulativeYearlyCreditFee = 0;
		let cumulativeYearlyCredit = 0;

        Object.keys(reluData).sort().forEach(i => {
            const { date, monthlyRenewUsers, yearlyRenewUsers, monthlySumCredit, yearlySumCredit, remainCredit, totalSumCredit, totalSumCreditFee, punit } = reluData[i];

            // 누적 값 업데이트
            cumulativeCreditFee += (monthlySumCredit + yearlySumCredit);
			cumulativeCredit += (monthlyRenewUsers + yearlyRenewUsers);
			cumulativeMonthlyCreditFee += monthlySumCredit;
			cumulativeMonthlyCredit += monthlyRenewUsers;
			cumulativeYearlyCreditFee += yearlySumCredit;
			cumulativeYearlyCredit += yearlyRenewUsers;
            
            resultArray.push({
                date,
                monthlyRenewUsers,
				monthlySumCredit,
				yearlyRenewUsers,
				yearlySumCredit,
				remainCredit,
				punit,
				cumulativeCredit,
				cumulativeCreditFee,
				cumulativeMonthlyCredit,
				cumulativeMonthlyCreditFee,
				cumulativeYearlyCredit,
				cumulativeYearlyCreditFee,
				monthlyFee: 26,
				yearlyFee: 248,
				totalSumCredit,
				totalSumCreditFee
            });
        });

		setReluCredit(resultArray);
    };

    const genData_Pool = () => {
		const poolListData = [];
		pools && pools.filter(f => f.sk.includes("lcuse:") && (partner == "ray" ? (f?.b2b == partner || typeof f?.b2b == "undefined") : (f?.b2b == partner) ) ).map(x => {
			var lcSkInfo = x.sk.split(":");
			var plData = pools.find(p => p.sk === "pl:" + lcSkInfo[lcSkInfo.length - 2] + ":" + lcSkInfo[lcSkInfo.length - 1]);
			var plNewType = (plData?.sk.split(":")[1] == "normal" && plData?.period == 30) ? "commercial" : ((plData?.sk.split(":")[1] == "normal" && plData?.period == 365) ? "annual" : lcSkInfo[4]);
			var returnvalue = true;
			if(returnvalue && (plNewType === "trial" || plNewType === "commercial") && (_id === "App-RAYFusion" ? plData?.relu_license_id : true)) {
				poolListData.push({
					_id: x._id,
					sk: x?.sk?.split(":")[5],
					groupid: lcSkInfo[2],
					userid: lcSkInfo[3],
					assigned: x.created,
					activated: plData?.activated || 0,
					created: x.created,
					expired: <>
                        {x.expired === 1 && "취소함"}
                        {x.expired !== 1 && x.expired <= moment().valueOf() && <>{"만료됨"}<br/><small>({moment(x.expired).format("YYYY/MM/DD")})</small></>}
                        {x.expired > moment().valueOf() && <>{moment(x.expired).fromNow()}<br/><small>({moment(x.expired).format("YYYY/MM/DD")})</small></>}
                    </>,
					licenseName : RMLCTYPES.find(f => f.value === plNewType)?.label,
					licenseType : RMLCTYPES.find(d => d.value === plNewType)?.value,
					used : x.used,
					ccode: lcSkInfo[1],
					creator: x.creator,
					expiredNumber: x.expired,
					pid: x.pid,
					lcSk: x.sk,
				})
			}
		})
		setTrialItems(poolListData.filter(f => f.licenseType === "trial"));

		const sortedCommercialItems = poolListData.filter(f => f.licenseType === "commercial").sort((a, b) => {
			// id를 먼저 비교
			if (a.userid < b.userid) return -1;
			if (a.userid > b.userid) return 1;
		
			// id가 같으면 date를 비교
			return new Date(a.assigned) - new Date(b.assigned);
		});
		setNormalItems(sortedCommercialItems);
		setLoading(false);
	}

	return <div className='mb-4'>
		<div className="card mb-4">
			<div className="card-header d-flex justify-content-between align-items-center">
				<div className="card-label fw-bold text-gray-800">
					월별 현황
				</div>
				<div className='card-label text-gray-800'>
					* 유료 전환은 취소 와 상관 없이 해당 월에 첫 결제한 사용자를 말합니다.
				</div>
			</div>
			{!loading && <MLTable
				headers={headers}
				items={items && items.sort((a, b) => new Date(b.date) - new Date(a.date))}
				order={pagestat?.order || "date"}
				desc={pagestat?.desc || "desc"}
				noheader={true} />}
			{loading && <MLPanelLoading />}
		</div>
        {_id === "App-RAYFusion" && <>
		<div className="card mb-4">
			<div className="card-header d-flex justify-content-between align-items-center">
				<div className="card-label fw-bold text-gray-800">
					{`Relu Credit 정보`}
				</div>
				
			</div>
			<MLTable
				headers={headers_ReluCreditInfo}
				items={reluCreditInfo}
				noheader={true} />
		</div>
		<div className="card mb-4">
			<div className="card-header d-flex justify-content-between align-items-center">
				<div className="card-label fw-bold text-gray-800">
					{`Relu Credit 현황 ( Activation - 사용자의 라이선스 [trial, commercial] 의 최초 Activation 기준,  - YEAR : Activated 30일 후에 차감 )`}
				</div>
				<div className='card-label fw-bold text-gray-800'>
					<LicenseExportWrapper
						list={reluCredit.sort((a, b) => new Date(b.date) - new Date(a.date))}
						buttonProps={{ css: "ms-1" }}
					/>
				</div>
			</div>
			{!loading && <MLTable
				headers={headers_Relu}
				items={reluCredit && reluCredit.sort((a, b) => new Date(b.date) - new Date(a.date))}
				order={pagestat?.order || "date"}
				desc={pagestat?.desc || "asc"}
				noheader={true} />}
			{loading && <MLPanelLoading />}
		</div></>}
        <div className="card mb-4">
			<div className="card-header d-flex justify-content-between align-items-center">
				<div className="card-label fw-bold text-gray-800">
                    {`누적 무료 가입자 (${trialItems && trialItems.length})`}
				</div>
			</div>
			{!loading && trialItems && <MLTable
				headers={headers_trial}
				items={trialItems && trialItems.sort((a, b) => new Date(b.assigned) - new Date(a.assigned))}
				noheader={true} />}
			{loading && <MLPanelLoading />}
		</div>
		<div className="card mb-4">
			<div className="card-header d-flex justify-content-between align-items-center">
				<div className="card-label fw-bold text-gray-800">
                    {`누적 유료 가입자 (${normalItems && normalItems.length})`}
				</div>
			</div>
			{!loading && normalItems && <MLTable
				headers={headers_commercial}
				items={normalItems}
				noheader={true} />}
			{loading && <MLPanelLoading />}
		</div>
	</div>
}

const mapState = (state) => {
	const loginuser = state.AuthReducer.loginuser;
	const pagestat = state.ProgramReducer.pagestat["userlist"] || {};
	const page = state.ProgramReducer.page;
	const filters = state.ProgramReducer.filters;
	const config = state.ProgramReducer.config;
	return { loginuser, pagestat, page, filters, config};
};

const mapDispatch = (dispatch) => ({

});

export default connect(mapState, null)(LicenseStatList);
