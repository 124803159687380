import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { MLText } from '../../components/common/MLInput';
import { MLPanelLoading } from '../../components/common/MLPanel';
import { MLFileSize } from '../../components/common/MLCommon';
import Select from 'react-select';
import MLTable from '../../components/common/MLTable';
import { AuthConstant, getAPI, getHeader } from '../../redux/reducers/AuthReducer';
import { fetchData } from '../../libs/db';
import WidgetBarChartAppUsage from '../../components/widgets/dashboard/WidgetBarChartAppUsage';
import RMWidgetBarChart from '../../components/widgets/RMWidgetBarChart';
import RMWidgetLineChart from '../../components/widgets/RMWidgetLineChart';
import SimpleDayChart from '../../components/widgets/SimpleDayChart';
import { COUNTRIES } from '../../constants/Country';
import { MLButton, MLRefreshButton } from '../../components/common/MLButton';
import { history } from '../../history';
import { COLORS } from '../../constants/FromInputs';
import { RMIconList, RMIconChart } from '../../components/icon/MLIcon';
import { MLPanelNoData } from '../../components/common/MLPanel';

import { ProgramConstant, PaymentConstant } from '../../redux/reducers';
import { useTranslation } from 'react-i18next';

const ProjectChart = ({ items }) => {
	const dispatch = useDispatch();
    const [t] = useTranslation(['translation']);
    const [ptype, setPType] = useState([]);
    const [groups, setGroups] = useState([]);
    const [lgroups, setLGroups] = useState([]);
    const remainDay = 30;
    const remain = new Date().getTime() + (remainDay * 24 * 3600 * 1000);

	useEffect(() => {
        getGroups();
	}, [items]);

    const getGroups = () => {
        let pList = [];
        let list = [];
        let Llist = [];
        items.map(x => {
            const ex = list.find(l => l.groupId === x.ownerGroupId);
            if(ex){
                list = list.map(l => l.groupId === ex.groupId ? {...l, count : l.count + 1, size : l.size + x.size} : l); 
            }else{
                list.push({ groupname : x.groupname, groupId : x.ownerGroupId, countryCode : x.countryCode, count : 1, size : x.size});
            }

            const lex = Llist.find(l => l.groupId === x.labId);
            if(lex){
                Llist = Llist.map(l => l.groupId === lex.groupId ? {...l, count : l.count + 1, size : l.size + x.size} : l); 
            }else{
                Llist.push({ groupname : x.groupname, groupId : x.labId, countryCode : x.countryCode, count : 1, size : x.size});
            }

            const pex = pList.find(l => l.projecttype === x.projecttype);
            if(pex){
                pList = pList.map(l => l.projecttype === pex.projecttype ? {...l, count : l.count + 1} : l); 
            }else{
                pList.push({ projecttype : x.projecttype, count : 1});
            }
        });
        setPType(pList);
        setGroups(list);
        setLGroups(Llist);
    }

    const ptypeheaders = [
		{ value : "projecttype", label : "Type"},
		{ value : "count", label : "Count", css : "text-end" },
		{ value : "count", label : "", type : "BAR",  css : "text-end", style : { width : '60%' } },
    ]

    const headers = [
		{ link : "groupId", linkpath : "/GroupInfo", value : "groupId", type : "GROUP", label : "Group", css : "text-start", style : { width : 200 } },
		{ value : "countryCode", label : "Country", type : "COUNTRY"},
		{ value : "size", label : "Size", type : "SIZE", css : "text-end" },
		{ value : "count", label : "Count", css : "text-end" },
		{ value : "count", label : "", type : "BAR",  css : "text-end", style : { width : '30%' } },
    ]

  	return (<>
        {items && <div>
            <div className='row mb-4'>
                <div className='col'>
                    <SimpleDayChart data={items.filter(x => x.created > (new Date().getTime() - (30 * 24 * 3600 * 1000)))} title={t("Cases 생성 추이")} period={"days"} target={"created"} max={30} />
                </div>
            </div>
            <div className='row mb-4'>
                <div className='col'>
                    <RMWidgetBarChart 
                        data={items} 
                        type={"Country"} 
                        title={t("국가별 Cases(Top 10)")} 
                        target={"countryCode"} 
                        height={235}
                        max={10} />
                </div>
                <div className='col'>
                    <div className="card">
                        <div className="card-header">
                                <h5 className='card-title mb-0'>{t("Source Types")}</h5>
                        </div>
                        <MLTable 
                            headers={ptypeheaders} 
                            maxval={ptype.sort((a, b) => a.count > b.count ? -1 : 1).slice(0,1)[0]?.count} 
                            items={ptype.sort((a, b) => a.count > b.count ? -1 : 1).slice(0,5)} 
                            noheader={true} />
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <div className="card">
                        <div className="card-header">
                                <h5 className='card-title mb-0'>{t("Clinic Case 수(Top 5)")}</h5>
                        </div>
                        <MLTable 
                            headers={headers} 
                            maxval={groups.sort((a, b) => a.count > b.count ? -1 : 1).slice(0,1)[0]?.count} 
                            items={groups.sort((a, b) => a.count > b.count ? -1 : 1).slice(0,5)} 
                            noheader={true} />
                    </div>
                </div>
                <div className='col'>
                    <div className="card">
                        <div className="card-header">
                                <h5 className='card-title mb-0'>{t("Lab Case 수(Top 5)")}</h5>
                        </div>
                        <MLTable 
                            headers={headers} 
                            maxval={lgroups.sort((a, b) => a.count > b.count ? -1 : 1).slice(0,1)[0]?.count} 
                            items={lgroups.sort((a, b) => a.count > b.count ? -1 : 1).slice(0,5)} 
                            noheader={true} />
                    </div>
                </div>
            </div>
        </div>}
    </>
  	);
}

const mapState = (state) => {
	const region = state.AuthReducer.region;
	return { region };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, null)(ProjectChart);
