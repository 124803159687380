import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import MLTable from '../common/MLTable';
import { MLPanelLoading } from '../common/MLPanel';
import { fetchData } from '../../libs/db';
import { MLText } from '../common/MLInput';
import { MLFileSize } from '../common/MLCommon';
import { Link } from 'react-router-dom';
import { ProjectConstant } from '../../redux/reducers/ProjectReducer';
import RMWidgetBarChart from '../../components/widgets/RMWidgetBarChart';
import RMProjectTopRate from './RMProjectTopRate';
import RMWidgetCount from '../widgets/RMWidgetCount';
import RMWidgetList from '../widgets/RMWidgetList';
import { MLComboSingle } from '../common/MLInput';
import { TbLayoutDashboard, TbList } from 'react-icons/tb';
import { ProgramConstant } from '../../redux/reducers';
import { SEARCHPERIOD } from '../../constants/RMConstants';
import moment from 'moment';
import { RMIconList, RMIconChart } from '../icon/MLIcon';

const RMProjectDashBoard = ({ groups, projects }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [search, setSearch] = useState("");
    const [period, setPeriod] = useState("days");
    const periodlist = SEARCHPERIOD;
    // Project 건수
    // 국가별, 그룹별
    // complete, trash, enabled 건수
    // TbList
    // TbLayoutDashboard

    useEffect(() => {
		if (!projects[0]?._id)
			getData_dProjects();
	}, [])

	useEffect(() => {
        if(projects[0]?._id)
		    genData();
	}, [projects])

	useEffect(() => {
		genData();
	}, [search, period]);

	const genData = () => {
		if(projects) {
            var lists = projects
			.filter(x => {
				if(period == "all") {
					return true;
				} else {
					if(x.created >= moment().add(SEARCHPERIOD.filter(x => x.value == period)[0]?.duration , SEARCHPERIOD.filter(x => x.value == period)[0]?.mcode).valueOf()) return true;
				} 
			})
			setItems(search ? lists.filter(x => x.ownergroup?.indexOf(search) > -1 || x.lab?.indexOf(search) > -1) : lists || []);
		}
	};

	const getData_dProjects = async () => {
		setLoading(true);
		const ScanFilter = {
			sk: {
				ComparisonOperator: "EQ",
				AttributeValueList: ["project"],
			},
		}

		try {
			const ret = await fetchData("rayteams-project", ScanFilter);
			var list = [];
			list = ret.Items.map(x => { 
				return {...x, 
					isowner : x.ownerGroupId === groups._id,
					patientname : x.subject?.name || "",
					ownergroup: groups.filter(y => y._id === x?.ownerGroupId)[0]?.name || "",
					lab: groups.filter(y => y._id === x?.labId)[0]?.name || "",
                    countryCode: groups.filter(y => y._id === x?.ownerGroupId)[0]?.countryCode || "",
					filesize: MLFileSize(x?.size)
				}
			});
			dispatch({ type : ProjectConstant.GET_ALL_PROJECTS, items : list });
		} catch (e) {
			dispatch({ type : ProjectConstant.GET_ALL_PROJECTS, items : [] });
		}
		setLoading(false);
	}

    const goLink = (to, title) => {
		dispatch({ type : ProgramConstant.GO_LINK, to, title });
	}

    return <>
        <div className="header align-items-stretch d-block" style={{position: "initial"}}>
			<div className="d-flex w-100 justify-content-between p-5 align-items-center">
				<div className="d-flex align-items-center text-dark fw-bolder fs-2 my-1">Projects</div>
				<div className="d-flex align-items-center">&nbsp;</div>
			</div>
		</div>
        <div className="d-flex flex-column flex-column-fluid">
			<div className="post p-7">
                <div className='col-xl-12 mb-3'>
                    <div className='d-flex justify-content-end mb-2'>
                        <div className='d-flex'>
                            <div className='col-sm-auto'>
                                <MLText options={{
                                    placeholder : "Search Owner, Related",
                                    size : "sm",
                                    value : search,
                                }} handleChanged={(e) => setSearch(e.target.value)} css="w-200px" />
                            </div>
                            <div className='col-sm-auto'>
                                <MLComboSingle options={{
                                    name : "period",
                                    value : period,
                                    list : periodlist,
                                }} css={"ms-2 w-150px"} handleChanged={(e) => setPeriod(e.target.value) } />
                            </div>

                            <div className="col-sm-auto ms-6 btn-tool-view">
                                <a className="btn btn-bg-secondary btn-sm mb-1 btn-view-select" title="View as a list">
                                    <RMIconList onClick={() => goLink("/ProjectsPage", "Projects")}></RMIconList>
                                </a>
                                <a className="btn btn-bg-secondary btn-sm mb-1 btn-view-select active" title="View as a dashboard">
                                    <RMIconChart></RMIconChart>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {!loading && <>
                <div className='row mb-5'>
                    <div className='col-3'>
                        <RMWidgetCount data={items} title={"Projects Count"} />
                    </div>
                    <div className='col-3'>
                        <RMWidgetCount data={items} title={"Complete"} options={{filter: "isarchive", filterValue: true}} />
                    </div>
                    <div className='col-3'>
                        <RMWidgetCount data={items} title={"Trash"} options={{filter: "istrash", filterValue: true}} />
                    </div>
                    <div className='col-3'>
                        <RMWidgetCount data={items} title={"Deleted"} options={{filter: "isDel", filterValue: true}} />
                    </div>
                </div>

                <div className='row mb-5'>
                    <div className='col-2'>
                        {/* <RMProjectTopRate items={items} type={"Project"} /> */}
                        {/* <RMProjectTopRate items={items} title={"Top Rate"} type={"ownergroup"} period={periodlist.find(x => x.value == period).duration} /> */}
                        <RMWidgetList data={items} title={"Country"} target={"countryCode"} />
                    </div>
                    <div className='col-10 mb-5'>
                        <RMWidgetBarChart data={items} type={"Project"} title={periodlist.find(x => x.value == period).label + " Projects"} target={"created"} top={false} period={periodlist.find(x => x.value == period).value} />
                    </div>
                </div>
                </>}
                {loading && <MLPanelLoading />}
            </div>
        </div>
    </>
};

const mapState = (state) => {
	const region =  state.AuthReducer.region;
	const projects = state.ProjectReducer.projects;
	return { region, projects };
};

export default connect(mapState)(RMProjectDashBoard);