import { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { pkData, fetchData, queryDataWithIndex } from '../../libs/db';
import MLTable from '../../components/common/MLTable';
import { FaHome, FaPhoneAlt, FaWindowClose } from 'react-icons/fa';
import { RiCloseFill } from 'react-icons/ri';
import Devices from '../../components/partner/devices';
import { history } from '../../history';
import { MLButton } from '../../components/common/MLButton';
import { COLORS } from '../../constants/FromInputs';
import { ProgramConstant, ApplicationConstant } from '../../redux/reducers';
import { ApplicationAction } from '../../redux/actions';
import PartnerUserList from '../../components/partner/partneruserlist';
import CompApplicationInfo from '../../components/application/ComApplicationInfo';
import CompApplicationVersions from '../../components/application/ComApplicationVersions';
import CompApplicationConfig from '../../components/application/ComApplicationConfig';
import { Store } from '../../store';

const ApplicationInfoPage = ({ applications, page }) => {

	const dispatch = useDispatch();
	const { _id, tab = "info" } = useParams("info");
	const [t] = useTranslation(['translation']);
	const [info, setInfo] = useState({});
	const [items, setItems] = useState([]);
	const [list, setList] = useState([]);
	const [process, setProcess] = useState(false);
	const [err, setErr] = useState("");
	const [edit, setEdit] = useState(false);
	const [search, setSearch] = useState("");
	const [related, setRelated] = useState([]);
	const perms = Store.getState().AuthReducer?.loginuser?.perms || [];
	useEffect(() => {
		dispatch({
			type: ProgramConstant.SET_PAGE, page: {
				code: "softwares",
				view: "summary",
				title: "Softwares",
				small: "Software 관련 정보",
				options: [
				]
			}
		})
	}, []);

	useEffect(() => {
		if (applications.length === 0) {
			getApps();
		}
	}, []);

	useEffect(() => {
		if (_id) {
			getitems(_id);
			setPage();
		}
	}, [_id, applications]);

	const getApps = async () => {
		dispatch(ApplicationAction.GetApplications());
	}

	const setPage = () => {
	}

	const getitems = async (_id) => {
		const item = applications.find(g => g.name === _id);
		if (!item)
			return;
		setInfo(item);
		dispatch(ApplicationAction.GetApplicationVersions(_id));
	}

	const tabs = [
		{ value: "info", label: "Info." },
		{ value: "ver", label: "Versions", code: "sw-ver" },
		{ value: "cfg", label: "Configuration", code: "sw-config" },
	]

	return <div className='p-4'>
		<div className="card">
			<div className="card-header pb-0">
				<div className='d-flex justify-content-between'>
					<h3 className="mb-0">{info.title}  <small>Application Info.</small></h3>
					<div className="d-flex align-items-center">
						<MLButton options={{
							label: "List",
							color: COLORS.DARK,
							action: () => history.push("/Applications")
						}} />
					</div>
				</div>
				<ul className="nav nav-tabs nav-stretch border-top mt-4 pt-2" style={{ borderRadius: "0" }}>
					{tabs.map((x, idx) => {
						if (x.value === "info" || perms.includes(x.code)) {
							return <li className="nav-item" key={idx} onClick={() => history.push("/Application/" + _id + "/" + x.value)}>
								<Link to="#" className={"nav-link " + (tab === x.value ? 'active' : '')}>{x.label}</Link>
							</li>
						}
						return null;
					})}
				</ul>
			</div>
			<div className="card-body p-0">
				{tab === "info" && <CompApplicationInfo application={info} title={"Information"} />}
				{tab === "ver" && <CompApplicationVersions application={info} title={"Information"} />}
				{tab === "cfg" && <CompApplicationConfig application={info} title={"Information"} />}
			</div>
		</div>
	</div>
}

const mapState = (state) => {
	const user = state.AuthReducer.user;
	const allgroups = state.ProgramReducer.allgroups;
	const applications = state.ApplicationReducer.applications;
	const page = state.ProgramReducer.page;

	return { user, allgroups, applications, page };
};

const mapDispatch = (dispatch) => ({

});

export default connect(mapState, null)(ApplicationInfoPage);
