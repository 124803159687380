import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { COLORS } from '../../constants/FromInputs';
import { history } from '../../history';
import { RegionList } from '../../libs/cogInfo';
import { AuthConstant } from '../../redux/reducers';
import { MLButton } from '../common/MLButton';
import { MLComboSingle } from '../common/MLInput';

const RMFooter = ({ region, regions }) => {

	const dispatch = useDispatch();

	useEffect(() => {
	}, []);

    return <footer className="content-footer footer bg-footer-theme">
		<div className='container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between'>
			<div className="text-dark order-2 order-md-1">
				<span className="text-muted fw-bold me-1">2022©</span>
				<a href="" target="_blank" className="text-gray-800 text-hover-primary">RAYTeams</a>
			</div>
			<ul className="menu menu-gray-600 menu-hover-primary fw-bold order-1">
				<li className="menu-item">
					<a href="https://rsp.rayteams.com" target="_blank" className="menu-link px-2">RSP</a>
				</li>
				<li className="menu-item">
					<a href="https://www.rayteams.com" target="_blank" className="menu-link px-2">Homepage</a>
				</li>
			</ul>
		</div>
    </footer>
}

const mapState = (state) => {
	const region =  state.AuthReducer.region;
	const regions =  state.AuthReducer.regions;
	return { region, regions };
};

const mapDispatch = (dispatch) => ({ });

export default connect(mapState, null)(RMFooter);
