import axios from 'axios';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { MLPanelLoading } from '../common/MLPanel';
import MLTable from '../common/MLTable';
import { AppAction } from '../../redux/actions';
import { JWT } from '../../config';
import { UsageConstant } from '../../redux/reducers';

const RMUsageList = ({ applist }) => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);
	
    useEffect(() => {
		getData();
	}, []);

    const getData = async () => {
		setLoading(true);
		try{
			const ret = await axios.get(AppAction.getAPI() + "/actionlog/applist", JWT());
			if (ret.data.status === "success") {
				dispatch({ type: UsageConstant.GET_APPLIST , items: ret.data.data });
			}
		}catch(err){
			console.log(err);
		}
		setLoading(false);
    }

    const headers = [
        { value : "_id", label : "App", type : "TEXT", style : { width : 200 } },
		{ value : "sk", label : 'sk'},
    ]

  	return <>
		{!loading && <div className='card'>
			<div className='card-body'>
				<MLTable 
					items={applist} 
					headers={headers} 
					notableheader={true}
					rowkey={"_id"} 
					nocard={true} 
					noheader={true} />
			</div>
		</div>}
		{loading && <MLPanelLoading />}
	</>
}

const mapState = (state) => {
	const applist =  state.UsageReducer.applist;
	return { applist };
};

const mapDispatch = (dispatch) => ({
});

export default connect(mapState, null)(RMUsageList);