import axios from 'axios';
import { JWT } from '../../config';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { AppAction } from '../../redux/actions';
import WidgetBarChartAppUsage from '../../components/widgets/dashboard/WidgetBarChartAppUsage';
import WidgetBarChartAppFuncUsage from '../../components/widgets/dashboard/WidgetBarChartAppFuncUsage';
import WidgetPieChartAppFuncUsage from '../../components/widgets/dashboard/WidgetPieChartAppFuncUsage';
import WidgetListAppUsage from '../../components/widgets/dashboard/WidgetListAppUsage';
import { MLComboSingle } from '../../components/common/MLInput';
import { UsageConstant } from '../../redux/reducers';

const UsageInfoPage = ({data, appname, type, selectedlogs, stats}) => {
	const dispatch = useDispatch();
    const { _app } = useParams();
    const { _type} = useParams();
    const { _period} = useParams();
    
    const [period, setPeriod] = useState("Day");
    const periodlist = [
        { value : "Day", label : "Last 7 Days" },
        { value : "Week", label : "Last 2 Weeks" },
        { value : "Month", label : "Last 6 Months" }
    ]
    const [appTotalCount, setAppTotalCount] = useState(0);
    const [appLastDate, setAppLastDate] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        //getData()
    }, [period])
    // Function 에 대한 상세한 통계를 열람할 수 있다.
    // - App 이름.... : action log,  from 컬럼에 app 이름
    // - license 정보 : appname, groupid(groupid 를 특정 할 수 있나?)
    // - 누적 Call Count 해당 actionlog called sum
    // - 가장 최근 call date : actionlog act: 로 검색 

    useEffect(() => {
        if(_app) {
            getTotalCalledCount();
            getLastCalledDate();
            if(_period) {
                setPeriod(_period)
            }
        }
    }, [_app])

    const getTotalCalledCount = async () => {
        try {
            const ret = await axios.get(AppAction.getAPI() + "/getlogs?act=M", JWT());
            if (ret.data.status === "success") {
                const result = [];
                ret?.data?.data.filter(x => x?._id === _app).reduce(function(res, value) {
                    if (!res[value._id]) {
                        res[value._id] = { _id: value._id, called: 0 };
                        result.push(res[value._id])
                    }
                    res[value._id].called += value.called;
                    return res;
                }, {});
                
                setAppTotalCount(result[0].called);
            }
        } catch(e) {

        }
    }

    const getLastCalledDate = async () => {
        try {
            const ret = await axios.get(AppAction.getAPI() + "/getlogs?_id=" + _app , JWT());
            if (ret.data.status === "success") {
                setAppLastDate(ret.data.data.sort((a, b) => a.created > b.created ? -1 : 1)[0]?.created);
            }
        } catch(e) {

        }
    }

  	return (
        <>
        <div className='row'>
			<div className='col-xl-12 mb-5'>
                <div className='d-flex justify-content-between align-items-end mb-2'>
                    <h2 className='mb-2'><small className='ms-2'>{_app}</small></h2>
                    <MLComboSingle options={{
                        name : "period",
                        value : period,
                        list : periodlist,
                    }} css={"ms-2 w-150px"} handleChanged={(e) => setPeriod(e.target.value) } />
                </div>
            </div>
        </div>
        {_type == "app" && 
        <>
        <div className='row'>
            <div className='col-sm mb-5'>
                <WidgetBarChartAppUsage period={period} type={"app"} title={"App" + " " + period} appname={_app}/>
            </div>
        </div>
        <div className='row'>
            <div className='col-sm mb-5'>
                <WidgetBarChartAppFuncUsage period={period} type={"func"} title={"Function" + " " + period} appname={""} />
            </div>
            <div className='col-sm mb-5'>
                <WidgetPieChartAppFuncUsage period={period} type={"func"} title={"Function " + period } appname={""} />
            </div>
        </div>
        <div className='row'>
            <div className='col-sm mb-5'>
                <WidgetListAppUsage title={"Function Top 5"} groupType={"Function"} type={"func"} period={period} appname={_app} appfunc={true} />
            </div>
            <div className='col-sm mb-5'>
                <WidgetListAppUsage title={"Country Top 5"} groupType={"Country"} type={"App"} period={period} appname={_app} />
            </div>
            <div className='col-sm mb-5'>
                <WidgetListAppUsage title={"Group Top 5"} groupType={"Group"} type={"App"} period={period} appname={_app} />
            </div>
            
        </div>
        </>}
        {_type == "func" && 
        <>
            <div className='row'>
                <div className='col-sm mb-5'>
                    <div className='card'>
                        {stats && <>
                        <div className='card-header'>
                            <div className='card-title'>{_app} Info</div>
                        </div>
                        <div className='card-body'>
                            <div className='card mt-5'>				
                                <hr className='my-0'/>
                                <div className='card-body p-8'>
                                    <div className='pb-4'>
                                        <div className='label fw-bolder'>App Name</div>
                                        <div className=''><span>{stats.filter(x => x._id === _app)[0]?.from}</span></div>
                                    </div>
                                    <div className='pb-4'>
                                        <div className='label fw-bolder'>Total Called Count</div>
                                        <div className=''><span>{appTotalCount}</span></div>
                                    </div>
                                    <div className='pb-4'>
                                        <div className='label fw-bolder'>Last Called Date</div>
                                        <div className=''><span>{moment.utc(appLastDate).local().fromNow()}</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </> }
		            </div>
                </div>
                <div className='col-sm mb-5'>
                    <WidgetBarChartAppUsage period={period} type={"func"} title={"Function" + " " + period} appname={_app}/>
                </div>
            </div>
            <div className='row'>
                <div className='col-sm mb-5'>
                    <WidgetListAppUsage title={"Country Top 5"} groupType={"Country"} type={"func"} period={period} appname={_app} />
                </div>
                <div className='col-sm mb-5'>
                    <WidgetListAppUsage title={"Group Top 5"} groupType={"Group"} type={"func"} period={period} appname={_app} />
                </div>
                
            </div>
        </>
        }

        </>
  	);
}

const mapState = (state) => {
    const logs = state.UsageReducer.logs;
	const dailylogs =  state.UsageReducer.dailylogs;
    const stats = state.UsageReducer.stats;
    const selectedlogs = state.UsageReducer.selectedlogs;
	return { logs, dailylogs, stats, selectedlogs };
};

export default connect(mapState)(UsageInfoPage);