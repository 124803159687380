import { useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { MLPanelLoading } from '../common/MLPanel';

const RMProjectTopRate = ({ period, type, title, appname = "", items }) => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);
	const [topRate, setTopRate] = useState([]);

	useEffect(() => {
		if(items) {
			generate(items, type, period, appname);
		}
	}, [items]);

	const generate = (items, type, period) => {
		const result = [];
		console.log(items
			);
		items.reduce(function(res, value) {
			if (!res[value?.[type]]) {
				res[value?.[type]] = { name: value?.[type], count: 0 };
				result.push(res[value?.[type]])
			}
			res[value?.[type]].count += 1;
			return res;
		}, {});

        const total = result.reduce((a, i) => a + i.count, 0)
        const topList = result.map(x => ({...x, percentage: Math.round(x.count * 100 / total)}))
        setTopRate(topList);        
		setLoading(false);
	}

  	return <>
		{!loading && <> <div className="card" style={{minHeight: "255px"}}>
        	<div className='card-header'>
            	<h3 className='card-title'>{title}</h3>
        	</div>
        	<div className='card-body align-middle text-center fs-20'>
			{topRate.map((x, idx) => { return <>
				<div key={idx}>
					<div className='d-flex align-items-center'>
						<div className="ms-2"> {x?.percentage && <>
							<div className="d-flex align-items-end">
								<h2 className="mb-1 me-3" style={{width: "40px"}}>{x?.percentage}%</h2><span className="fs-1 fw-semi-bold text-900">{x?.name}</span>
							</div>
							<p className="text-800 fs--1 mb-10">{title}</p>
							</>}
						</div>
					</div>
				</div></>
			})}
			</div>
    	</div>
		</>}
		{loading && <MLPanelLoading />}
	</>
}

const mapState = (state) => {    
	return { };
};

export default connect(mapState)(RMProjectTopRate);