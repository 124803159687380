import moment from 'moment';
import { useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { MLPanelLoading } from '../../components/common/MLPanel';
import { Bar, Line, getElementAtEvent } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ChartDays, ChartMonths } from '../common/ChartDays';

const LicenseChart = ({ items }) => {
	const dispatch = useDispatch();
    const remainDay = 30;
    const remain = new Date().getTime() + (remainDay * 24 * 3600 * 1000);
    const [ chartData, setChartData ] = useState([]);
	const [ loading, setLoading ] = useState(true);
	const [ stacked, setStacked ] = useState(false);
	const [ lineChart, setLineChart ] = useState(false);
	const chartTypeList = [
        { value : false, label : "Bar Chart" },
        { value : true, label : "Stack Chart" },
    ]
	const chartRef = useRef();

	useEffect(() => {
		if(items && items.length > 0) {
        console.log("LicenseChart : ======", items);
		const sortCartData = items.sort((a, b) => new Date(a.date) - new Date(b.date));
		const barChartData = {
			labels: sortCartData.map(item => item.date), // x축 라벨
			datasets: [
				{
					label: '신규(무료)', // 새 사용자
					data: sortCartData.map(item => item.newUsers), // 각 월별 새 사용자 수
					backgroundColor: 'rgba(75, 192, 192, 0.6)', // 바 색상
				},
				{
					label: '구독 취소', // 취소한 사용자
					data: sortCartData.map(item => item.cancelUsers), // 각 월별 취소한 사용자 수
					backgroundColor: 'rgba(255, 99, 132, 0.6)', // 바 색상
				},
				{
					label: '유료 전환', // 갱신한 사용자
					data: sortCartData.map(item => item.renewUsers), // 각 월별 갱신한 사용자 수
					backgroundColor: 'rgba(153, 102, 255, 0.6)', // 바 색상
				},
				{
					label: '누적 사용자', // 누적 사용자
					data: sortCartData.map(item => item.cumulativeSum),
					backgroundColor: 'rgba(255, 206, 86, 0.6)', // 바 색상
				}
			],
		};

        setChartData(barChartData);
	}
        setLoading(false);
	}, [items]);

  	return (<>
        {!loading && <div><div className='card mb-4'>
			{chartData && <>
			<div className='card-header header-elements'>
                <h5 className='card-title mb-0'>현황</h5>
                <div className='card-action-element ms-auto py-0'></div>
			</div>
			<div className='card-body'>
				{lineChart &&
				<Line
					height={300}
					data={chartData}
					options={{
						responsive: true,
						maintainAspectRatio: false,
						cutout: 0,
						animation: { animateScale: true },
						plugins: { legend: { display : true, position: "right" }, },
						scales: {
							y: { grid: { display: false } },
							x: { grid: { display: false } }
						}					
					}}
				/>
				}
				{!lineChart &&
				<Bar 
					ref={chartRef}
					height={300}
					data={chartData}
					plugins={[ChartDataLabels]}
					options={{
						indexAxis: 'x',
						responsive: true,
						maintainAspectRatio: false,
						cutout: 0,
						animation: { 
							animateScale: true,
						},
						plugins: { 
							legend: { display : true, position: "right" },
							datalabels: {
								formatter: function (value) {
								  	return value == 0 ? "" : value;
								},
								display: true,
								color: "gray",
								anchor: 'end',
								align: 'end',
								font: {
								  	weight: 'bold',
								  	size: 13,
								},
							},
						},
						scales: {
							y: { ticks: { beginAtZero: true, min : 10, stepSize : 10 }, grid: { display: false } },
							x: { ticks: { color: '#666060', beginAtZero: true, callback: function(value, index, ticks) {
								return this.getLabelForValue(value);
							} }, grid: { display: false } },
						},
					}}
				/>
				}
			</div>
			</> }
		</div></div>}
		{loading && <MLPanelLoading />}
    </>
  	);
}

const mapState = (state) => {
	const region = state.AuthReducer.region;
	return { region };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, null)(LicenseChart);
