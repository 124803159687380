import axios from 'axios';
import { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { history } from '../../history';
import { InfoAction, ProductAction, WFAction } from '../../redux/actions';
import { GetCodeConfig, GetConfig } from '../../libs/appConfig';
import { BUTTONS, COLORS, INPUTS, SPAN } from '../../constants/FromInputs';
import { UploadFileS3, UploadS3 } from "../../libs/s3";
import { ProgramConstant } from "../../redux/reducers";
import { MLForm } from '../common/MLForm';
import { PRICEUNIT } from '../../constants/RMConstants';
import { useTranslation } from 'react-i18next';
import { RTAudit } from '../../libs/audit';
import { COUNTRIES } from '../../constants/Country';
const LabProductAdd = ({ readonly = false, callBackInfo, callBackCancel, callbackUpdate}) => {
	const dispatch = useDispatch();
	const [t] = useTranslation(['translation']);
	const { _id } = useParams("");
	const [help, setHelp] = useState(window.localStorage.getItem("helpmsg") === "N" ? false : true);
	const [data, setData] = useState({
		priceType : 'negotiable',
		priceUnit : 'USD'
	});
	const [docs, setDocs] = useState([]);
	const [wfs, setWfs] = useState([]);
	const [codes, setCodes] = useState([]);
	const [category, setCategory] = useState([]);

	useEffect(() => {
		init();
	}, []);

	const init = async () => {
		setCodes(await GetCodeConfig());
		const retWf = await WFAction.GetAll();
		setWfs(retWf);

		const retCategory = await InfoAction.callDB({ type : "scan", region : "ap-northeast-2", params : {
			TableName: "rayteams-product",
			FilterExpression: "#sk=:sk ",
			ExpressionAttributeNames: { "#sk": "sk" },
			ExpressionAttributeValues: { ":sk": "category" },
		}});
		setCategory(retCategory.map(x => ({ value : x._id, label : x.title})))

		
		const retDocs = await InfoAction.callDB({ type : "scan", region : "ap-northeast-2", params : {
			TableName: "rayteams-manager-contents",
			FilterExpression: "#type=:type and #sk = :sk",
			ExpressionAttributeNames: { "#type": "type", "#sk": "sk" },
			ExpressionAttributeValues: { ":type": "PRODUCT_DESC", ":sk": "info" },
		}});
		setDocs(retDocs.map(x => ({ value : x._id, label : x.title})));
		if(_id){
			getData();
		}
	}

	const getData = async () => {
		const ret  = await InfoAction.callDB({ type : "query", region : "ap-northeast-2", params : {
			TableName: "rayteams-product",
			KeyConditionExpression: "#_id = :_id and #sk = :sk",
			ExpressionAttributeNames: { "#_id": "_id", "#sk": "sk" },
			ExpressionAttributeValues: { ":_id": _id, ":sk": "info" }
		}});
		if(ret.length > 0){
			setData(ret[0]);
			callBackInfo && callBackInfo(ret[0])
		}
	}

	const getEnv = () => {
		if(location.href.indexOf("localhost") > -1 || location.href.indexOf("127.0.0.1") > -1){
			return "dev-local";
		}else if(location.href.indexOf("raydevelop") > -1){
			return "development";
		}
		return "production";
	}

	const update = async () => {
        let prd_img = data.img;
        const dt = new Date().getTime();
        const bucket = getEnv() === "production" ? 'ray-data' : 'ray-data-development';
        if(data.img?.path){
            if(await UploadFileS3(bucket, data.img, 'rayrnd/rayteams-service/' + process.env.REACT_APP_COMPANY + '/prd-img-' + dt +'.' + data.img.path.split('.').pop())){
				prd_img = 'https://' + bucket + '.s3.ap-northeast-2.amazonaws.com/rayrnd/rayteams-service/' 
						+ process.env.REACT_APP_COMPANY 
						+ '/prd-img-' + dt +'.' + data.img.path.split('.').pop();	
			}
        }
		const wf = await WFAction.Get(data.wf);
		if(_id){
			const UpdateExpression = `SET #title = :title, #type = :type, #flows = :flows, #wf = :wf, #pubcountry = :pubcountry, 
				#delivery = :delivery, #price = :price, #priceType = :priceType, #priceUnit = :priceUnit, #category = :category, #img = :img, #desc = :desc, 
				#ispublished = :ispublished, #updated = :updated, #order = :order`;
			await InfoAction.callDB({ type : "update", region : "ap-northeast-2", params : {
				TableName: "rayteams-product",
				UpdateExpression,
				ExpressionAttributeNames: { 
					'#img' : 'img', 
					'#title' : 'title', 
					'#type' : 'type', 
					'#wf' : 'wf', 
					'#desc' : 'desc', 
					'#flows' : 'flows', 
					'#delivery' : 'delivery', 
					'#price' : 'price', 
					'#category' : 'category', 
					'#priceType' : 'priceType', 
					'#pubcountry' : 'pubcountry',
					'#priceUnit' : 'priceUnit', 
					'#ispublished' : 'ispublished', 
					'#updated' : 'updated',
					"#order": "order",
				},
				ExpressionAttributeValues: { 
					':img' : prd_img, 
					':title' : data.title, 
					':type' : data.type, 
					':wf' : data.wf, 
					':desc' : data.desc,
					':flows' : wf.flows, 
					':category' : data.category, 
					':delivery' : data.delivery, 
					':price' : data.price, 
					':pubcountry' : data.pubcountry, 
					':priceType' : data.priceType, 
					':priceUnit' : data.priceUnit, 
					':ispublished' : data.ispublished || false, 
					':updated' : new Date().getTime(),
					":order": isNaN(data?.order) ? 0 : Number(data?.order),
				},
				Key: { _id, sk: 'info' }
			}});
			callbackUpdate && callbackUpdate();
			getData();
			RTAudit({ lvl: 7, msg: '[' + data.title + '] Update Product' }, JSON.stringify({ subject: data }))
		}else{
			const newId = v4();
			const UpdateExpression = `SET #title = :title, #type = :type, #flows = :flows, #wf = :wf, #pubcountry = :pubcountry, 
				#delivery = :delivery, #price = :price, #priceType = :priceType, #priceUnit = :priceUnit, #category = :category, #img = :img, #desc = :desc, 
				#ispublished = :ispublished, #created = :created, #order = :order`;
			await InfoAction.callDB({ type : "update", region : "ap-northeast-2", params : {
				TableName: "rayteams-product",
				UpdateExpression,
				ExpressionAttributeNames: { 
					'#img' : 'img', 
					'#title' : 'title', 
					'#type' : 'type', 
					'#wf' : 'wf', 
					'#flows' : 'flows', 
					'#desc' : 'desc', 
					'#delivery' : 'delivery', 
					'#price' : 'price', 
					'#category' : 'category', 
					'#priceType' : 'priceType', 
					'#pubcountry' : 'pubcountry', 
					'#priceUnit' : 'priceUnit', 
					'#ispublished' : 'ispublished', 
					'#created' : 'created',
					"#order": "order",
				},
				ExpressionAttributeValues: { 
					':img' : prd_img, 
					':title' : data.title, 
					':type' : data.type, 
					':wf' : data.wf, 
					':desc' : data.desc, 
					':flows' : wf.flows, 
					':delivery' : data.delivery, 
					':price' : data.price, 
					':category' : data.category, 
					':pubcountry' : data.pubcountry, 
					':priceType' : data.priceType, 
					':priceUnit' : data.priceUnit, 
					':ispublished' : false, 
					':created' : new Date().getTime(),
					":order": isNaN(data?.order) ? 0 : Number(data?.order),
				},
				Key: { _id: newId, sk: 'info' }
			}});
			history.push('/LabProductInfoPage/' + newId)
			RTAudit({ lvl: 7, msg: '[' + data.title + '] Add Product' }, JSON.stringify({ subject: data }))
		}
	}

	const controls = [
		{
			type: INPUTS.SINGLEFILE,
			options: {
				label: t('Product image'),
				name: 'img',
				value : data.img,
				hint : '정사각형(권장 사이즈 : 640x640)',
			}
		},
		{
			type: INPUTS.TEXT,
			options: {
				label: t('제목'),
				name: 'title',
				required: true
			}
		},
		{
			type: INPUTS.COMBO,
			options: {
				label: t('상품 타입'),
				name: 'type',
				list: codes.filter(x => t(x.category) === 'PRD_TYPE'),
				required: true
			},
		},
		{
			type: INPUTS.COMBO,
			options: {
				label: t('상품 분류'),
				name: 'category',
				list: category,
				required: true
			},
		},
		{
			type: INPUTS.COMBO,
			options: {
				label: t('상품 설명'),
				name: 'desc',
				list: docs,
				required: true
			},
		},
		{
			type: INPUTS.COMBO,
			options: {
				label: t('상품 프로세스'),
				name: 'wf',
				list: wfs.map(x => ({ value : x._id, label : t(x.title) })),
				required: true
			},
		},
		{
			type: INPUTS.TOGGLE,
			options: {
				label: t('배송 여부'),
				name: 'delivery',
			},
		},
		{
			type: INPUTS.COMBO,
			options: {
				label: t('가격 타입'),
				name: 'priceType',
				list: [
					{ value : 'negotiable', label : t('협의') },
					{ value : 'fixedprice', label : t('고정 가격') }
				],
				required: true
			},
		},
		{
			type: data.priceType === 'fixedprice' ? INPUTS.NUMBER : INPUTS.HIDDEN,
			options: {
				label: t('가격'),
				name: 'price',
			},
		},
		{
			type: data.priceType === 'fixedprice' ? INPUTS.COMBO : INPUTS.HIDDEN,
			options: {
				label: t('화폐 단위'),
				name: 'priceUnit',
				list: PRICEUNIT,
			},
		},
		{
			type: _id ? INPUTS.TOGGLE : INPUTS.HIDDEN,
			options: {
				label: t('출시'),
				name: 'ispublished'
			},
		},
		{
			type: INPUTS.COMBOMULTI,
			options: {
				label: t('출시 국가'),
				name: 'pubcountry',
				list : COUNTRIES.map(x => ({ value : x.countryCode, label : x.name })),
				hint : '주문 가능 국가(설정 안하면 전세계에서 가능)',
			},
		},
		{
			type: INPUTS.NUMBER,
			options: {
				label: t('노출 순서'),
				name: 'order',
				hint : 'Web에서 노출되는 상품의 순서 (오름차순)',
			},
		},
		{
			type: SPAN.VERTICAL,
			options: { size: 10 }
		},
		{
			type: BUTTONS.BUTTONS,
			options: {},
			buttons: [{
				type: BUTTONS.NORMALBUTTON,
				options: {
					label: t('Cancel'),
					action: () => callBackCancel ? callBackCancel() : history.push('/LabProductPage')
				}
			}, {
				type: BUTTONS.NORMALBUTTON,
				options: {
					label: _id ? t('Update') : t('Add'),
					color: COLORS.PRIMARY,
					action: () => update()
				}
			}]
		}
	];

	return <MLForm 
		controls={controls} 
		data={data} 
		readonly={readonly}
		onChanged={(e) => setData(e)} />
}

const mapState = (state) => {
	return {  };
};

const mapDispatch = (dispatch) => ({

});

export default connect(mapState, null)(LabProductAdd);
