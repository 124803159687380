const initialState = { 
	board : {},
	boards : [],
	content : {},
	contents : [],
	usercontent : {},
	usercontents : [],
};

export const BoardConstant = {
	GET_ALL_BOARDS : "GET_ALL_BOARDS",
	GET_BOARD : "GET_BOARD",
	CREATE_BOARD : "CREATE_BOARD",
	UPDATE_BOARD : "UPDATE_BOARD",
	GET_ALL_CONTENTS : "GET_ALL_CONTENTS",
	GET_CONTENT : "GET_CONTENT",
	CREATE_CONTENT : "CREATE_CONTENT",
	UPDATE_CONTENT : "UPDATE_CONTENT",
	DELETE_CONTENT : "DELETE_CONTENT",
	CREATE_USER_CONTENT : "CREATE_USER_CONTENT",
	UPDATE_USER_CONTENT : "UPDATE_USER_CONTENT",
	DELETE_USER_CONTENT : "DELETE_USER_CONTENT",
};

export const BoardReducer = (state = initialState, action) => {
	switch (action.type) {
		case BoardConstant.GET_ALL_BOARDS:
			return {...state, boards : action.items };
		case BoardConstant.GET_BOARD:
			return {...state, board : action.item };
		case BoardConstant.CREATE_BOARD:
			return {...state, 
				board : action.item,
				boards : [...state.boards, action.item]
			};
		case BoardConstant.UPDATE_BOARD:
			return {...state, 
				board : action.item,
				boards : state.boards.map(x => x._id === action.item._id ? action.item : x)
			};
		case BoardConstant.GET_ALL_CONTENTS:
			return {...state, contents : action.items };
		case BoardConstant.GET_CONTENT:
			return {...state, 
				content : action.item.filter(x => x.sk === "info")[0],
				usercontents : action.item,
			};
		case BoardConstant.CREATE_CONTENT:
			return {...state, 
				content : action.item,
				contents : [...state.contents, action.item]
			};
		case BoardConstant.UPDATE_CONTENT:
			return {...state, 
				content : action.item,
				contents : state.contents.map(x => x._id === action.item._id ? action.item : x)
			};
		case BoardConstant.DELETE_CONTENT:
			return {...state, 
				content : {},
				contents : state.contents.filter(x => x._id !== action.item._id)
			};
		case BoardConstant.CREATE_USER_CONTENT:
			return {...state, 
				usercontent : action.item,
				usercontents : [...state.usercontents, action.item]
			};
		case BoardConstant.UPDATE_USER_CONTENT:
			const exist = state.usercontents.filter(x => (x._id === action.item._id && x.sk === "lang:" + action.item.lang)).length > 0;
			return {...state, 
				usercontent : action.item,
				usercontents : exist ? 
					state.usercontents.map(x => (x._id === action.item._id && x.sk === "lang:" + action.item.lang) ? action.item : x) : 
					[...state.usercontents, action.item]
			};
		case BoardConstant.DELETE_USER_CONTENT:
			return {...state, 
				usercontent : {},
				usercontents : state.usercontents.filter(x => !(x._id === action.item.cid && x.sk === "lang:" + action.item.lang) )
			};
		default:
			return state;
	}
};
