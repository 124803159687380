import { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import { connect, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { history } from '../../history';
import { MLPanelLoading, MLPanelNoData } from '../../components/common/MLPanel';
import MLTable from '../../components/common/MLTable';
import { AppCategoryConstant, LicenseConstant } from '../../redux/reducers';
import { fetchData, idData, putData, delData, updateData, updateData_withExpression, queryData } from '../../libs/db';
import RMLicenseAppInfoPanel from '../../components/license/RMLicenseAppInfoPanel';
import { RMLICENSETYPE, RMLICENSETYPE_MOCKUP } from '../../constants/RMConstants';
import RMManagerGroup from '../../components/common/RMManagerGroup';
import { MLComboSingle } from '../../components/common/MLInput';
import { ProgramConstant } from '../../redux/reducers';
import Select from 'react-select';
import { COUNTRIES } from '../../constants/Country';
import { useTranslation } from 'react-i18next';
import { getApiUrl, getHeader } from '../../redux/reducers/AuthReducer';
import { Modal } from 'react-bootstrap';
import { MLButton } from '../../components/common/MLButton';
import { RTButton } from '../../libs/buttons';
import { COLORS } from '../../constants/FromInputs';
import MLUser from '../../components/common/MLUser';
import { RTAudit } from '../../libs/audit';
import { JWT } from '../../config';
import { InfoAction } from '../../redux/actions';
import LicenseChart from '../../components/license/LicenseChart';
import LicenseStatChart from '../../components/license/LicenseStatChart';


const LicenseAppListPage = ({ pools, grouplicenses, loginuser, pagestat, page, allusers, managedApp, config }) => {
	const dispatch = useDispatch();
	const [t] = useTranslation(['translation']);
	const [ help, setHelp ] = useState(window.localStorage.getItem("helpmsg") === "Y" ? true : false);
	const [more, setMore] = useState(true);
	const [loading, setLoading] = useState(true);
	const [poolItems, setPoolItems] = useState([]);
	const [poolSearch, setPoolSearch] = useState("");
	const [mgroupList, setMgroupList] = useState([]);
	const [selectedMgroup, setSelectedMgroup] = useState("");
    const { _id } = useParams();
	const [ tab, setTabs ] = useState("used");
	const mgroup = JSON.parse(localStorage.getItem("mgroup"));
	const tabs = process.env.REACT_APP_COMPANY === 'graphy' ? [
		{value : "used", label : t("Used License")}
	] :  [
		{value : "used", label : t("Used License")},
		{value : "groups", label : t("Manager Group")},
	];
	const [orderItem, setOrder] = useState({order: "order", desc: "desc"});
	const [filter, setFilter] = useState(pagestat?.filter || [
        { code : "licenseType", value : "", list : [] },
		{ code : "ccode", value : "", list : [] }
    ]);
	const MAX_TIME_STAMP = 99999999999999;
	const [holePageWhenUpdate, setHolePageWhenUpdate] = useState(true);
	const [modalData, setModalData] = useState({ show: false, data: {}});
	const [productInfo, setProductInfo] = useState({});
	const [licenseType, setLicenseType] = useState([]);
	const [lError, setlError] = useState(false);
	//const RMLCTYPES = _id && _id.includes("DigitalSmileDesignInAppExportMockUp") ? RMLICENSETYPE_MOCKUP : RMLICENSETYPE;
	const RMLCTYPES = RMLICENSETYPE;
	const [mgroupNoLic, setMgroupNoLic] = useState(false);
	const [modalRevoke, setModalRevoke] = useState({ show: false, data: {}});

	const partner = process.env.REACT_APP_COMPANY || 'ray';

	useEffect(() => {
		
		dispatch({ type : ProgramConstant.SET_PAGE, page : { 
			code : "licenses", 
			view : "summary", 
			title : "License", 
			small : `${managedApp && managedApp?.title} 관련 정보`, 
			options : [
				{ view: "summary" },
				{ view: "list" }
			]
		}})
	}, []);

    useEffect(() => {
		if(_id) {
			console.log("managedApp : managedApp : managedApp :", managedApp);
			getData_pool();
		}
	}, []);

	useEffect(() => {
        window.localStorage.setItem("helpmsg", help ? "Y" : "N");
	},[help]);

	useEffect(() => {
		dispatch(InfoAction.GetUserFilters("users"));
		if (page.view)
			setMore(page.view === "list");
	}, [page]);

	useEffect(() => {
		if(pools){
			genData_Pool();
			dispatch({ type: ProgramConstant.PAGESTAT, item: { licenselist : { 
				selCol : pagestat.selCol,
				order : pagestat.order,
				order2 : pagestat.order2,
				desc : pagestat.desc,
				desc2: pagestat.desc2,
			}} });
		}
	}, [poolSearch, selectedMgroup, pagestat.order, pagestat.desc, pagestat.order2, pagestat.desc2, filter]);

	useEffect(() => {
		if(pools)
			genFilter();
	}, [pools]);

	useEffect(async () =>{
		if(managedApp && modalData.show) {
			await getTypePeriod();
			getProductId();
		}
	}, [managedApp, modalData.show])

	const getLicenseBasicData = async (sk) => {
		if(_id) {
			try {
				let params = {
					TableName: "rayteams-license",
					KeyConditionExpression: "#_id = :_id and begins_with(#sk, :sk)",
					ExpressionAttributeNames: { "#_id": "_id", "#sk": "sk" },
					ExpressionAttributeValues: { ":_id": _id, ":sk": sk }
				}
		
				if (partner != "ray") {
					params = {
						...params,
						FilterExpression: "#b2b = :b2b",
						ExpressionAttributeNames: {
							...params.ExpressionAttributeNames,
							"#b2b": "b2b"
						},
						ExpressionAttributeValues: {
							...params.ExpressionAttributeValues,
							":b2b": partner
						}
					}
				}
				const sData = {
					type: "query",
					b2b: partner,
					params
				}
				const ret = await axios.post(getApiUrl("license") + "/license/getlicenseinfo", {data: sData}, JWT());
				return ret?.data?.data.length > 0 ? ret.data.data : [];
			} catch (e) {
				return [];
			}
		} else {
			return [];
		}
	}

	const getData_pool = async () => {
		setLoading(true);
		if(_id) {
			try {
				let params = {
					TableName: "rayteams-license",
					FilterExpression: "#_id = :_id",
					ExpressionAttributeNames: { "#_id": "_id" },
					ExpressionAttributeValues: { ":_id": _id }
				}
		
				if (partner != "ray") {
					params = {
						...params,
						FilterExpression: params.FilterExpression + " and #b2b = :b2b",
						ExpressionAttributeNames: {
							...params.ExpressionAttributeNames,
							"#b2b": "b2b"
						},
						ExpressionAttributeValues: {
							...params.ExpressionAttributeValues,
							":b2b": partner
						}
					}
				}
				const sData = {
					type: "scan",
					b2b: partner,
					params
				}
				const ret = await axios.post(getApiUrl("license") + "/license/getlicenseinfo", {data: sData}, JWT());
				dispatch({ type: LicenseConstant.GET_ALL_POOL, items: ret?.data?.data || [] });

				if(mgroup?.countries?.length > 0) {
					dispatch({ type: AppCategoryConstant.GET_MANAGED_APP, item: ret.data.data.find(x => x.sk === `info:${mgroup._id}`) || [] });
					dispatch({ type: LicenseConstant.GET_GROUP_LICENSES, items: ret.data.data.filter(x => x.sk === `info:${mgroup._id}` ) });
				} else {
					dispatch({ type: AppCategoryConstant.GET_MANAGED_APP, item: ret.data.data.find(x => x.sk === "info") || [] });
					dispatch({ type: LicenseConstant.GET_GROUP_LICENSES, items: ret.data.data.filter(x => x.sk.includes("info:"))});
					await getMangerGroupData();
				}

			} catch (err) {
				console.log(err)
			}
		}
		setLoading(false);
	}

	const genData_Pool = () => {
		const poolListData = [];
		var lcCountry = [];
		if(mgroup?.countries?.length > 0) {
			lcCountry = mgroup.countries.map(x => { return x.countryCode })
		}

		if(selectedMgroup){
			lcCountry = mgroupList.find(f => f._id === selectedMgroup).countries.map(x => { return x.countryCode});
		}

		pools && pools.filter(f => f.sk.includes("lcuse:") && (partner == "ray" ? (f?.b2b == partner || typeof f?.b2b == "undefined") : (f?.b2b == partner) ) ).map(x => {
			var groupid = x.sk.split(":");
			var plData = pools.find(p => p.sk === "pl:" + groupid[groupid.length - 2] + ":" + groupid[groupid.length - 1]);
			//var plNewType = (plData?.sk.split(":")[1] == "normal" && plData?.period == 30) ? "commercial" : ((plData?.sk.split(":")[1] == "normal" && plData?.period == 365) ? "annual" : groupid[4]);
			var plNewType = (plData?.sk.split(":")[1] == "normal" && plData?.period == 30) ? "commercial" : groupid[4];
			//var plNewType = (plData?.sk.split(":")[1] == "normal" && plData?.period == 30) ? "monthly" :
			var returnvalue = true;
			if(lcCountry.length > 0) {
				returnvalue = pools.filter(p => p.sk.includes("lcuse:")).find(f => lcCountry.includes(f.sk.split(":")[1]) && f.sk.includes(x.sk.split(":")[2]))
			}

			if(returnvalue) {
				poolListData.push({
					_id: x._id,
					sk: x?.sk?.split(":")[5],
					groupid: groupid[2],
					userid: groupid[3],
					deadline: x.deadline === MAX_TIME_STAMP ? 0 : x.deadline,
					assigned: x.created,
					activated: plData?.activated || 0,
					created: x.created,
					expired: <>
                        {x.expired === 1 && t("취소함")}
                        {x.expired !== 1 && x.expired <= moment().valueOf() && <>{t("만료됨")}<br/><small>({moment(x.expired).format("YYYY/MM/DD")})</small></>}
                        {x.expired > moment().valueOf() && <>{moment(x.expired).fromNow()}<br/><small>({moment(x.expired).format("YYYY/MM/DD")})</small></>}
                    </>,
					licenseName : RMLCTYPES.find(f => f.value === plNewType)?.label,
					licenseType : RMLCTYPES.find(d => d.value === plNewType)?.value,
					used : x.used,
					ccode: groupid[1],
					creator: x.creator,
					reason: x?.reason || "",
					expiredNumber: x.expired,
					pid: x.pid,
					lcSk: x.sk,
					renewal: x?.renewal
					//mgroup: pools.filter(p => p.sk.includes("lcuse:")).find(f => f.sk.includes(x.sk.split(":")[2]))?.sk?.split(":")[1],
				})
			}
		})

		setPoolItems(
			poolSearch ? poolListData && poolListData : 
			poolListData.filter(f => filterCheck(f))
			.sort((a, b) => pagestat?.[`${orderItem.desc}`] === "desc" ? (a[pagestat?.[`${orderItem.order}`]] > b[pagestat?.[`${orderItem.order}`]] ? -1 : 1) : (b[pagestat?.[`${orderItem.order}`]] > a[pagestat?.[`${orderItem.order}`]] ? -1 : 1)) || []
		);
	}

	const fLabel = (code, value) => {
        if(value === true || value === false) {
            return value === true ? t("Active") : t("Expired");
        } else if(code === "ccode") {
            return COUNTRIES.find(x => x.countryCode === value)?.name;
        } else if (code === "licenseType") {
			return RMLCTYPES.find(x => x.value === value)?.label;
		}
        return value;
    };

	const genFilter = () => {
		const filterItems = pools.filter(f=>f.sk.includes("pl:") && f?.used?.length > 0).map(x=> { 
			//var plNewType = (x.sk.split(":")[1] == "normal" && x.period == 30) ? "commercial" : ((x.sk.split(":")[1] == "normal" && x.period == 365) ? "annual" : x.sk.split(":")[1]);
			var plNewType = (x.sk.split(":")[1] == "normal" && x.period == 30) ? "commercial" : x.sk.split(":")[1];
			return {
				...x,
				ccode: pools.filter(p => p.sk.includes("lcuse:")).find(f => f.sk.includes(x.sk.split(":")[2]))?.sk?.split(":")[1],
				licenseType:  RMLCTYPES.find(f => f.value === plNewType)?.value
			}
		});
        setFilter( filter.map(x => {
            const list = [...new Set(filterItems.map(m => m[x.code]))];
            return {...x, list : [{ value : "", label : "ALL" }, ...list.map(n => ({ 
                value : n, 
                label : fLabel(x.code, n)
            }))] }
        }));
	};

	const getMangerGroupData = async () => {
		setLoading(true);
		try {
			const sData = {
				type: "managerGroupScan",
				mgroup: mgroup || [],
				b2b: process.env.REACT_APP_COMPANY || "ray"
			}
			const ret = await axios.post(getApiUrl("license") + "/license/getlicenseinfo", {data: sData}, getHeader());
			setMgroupList(ret?.data?.data || []);

		} catch(error) {
			console.log(error);
		}

		setLoading(false);
	};

	const delClick = async (item) => {
		setLoading(true);
		const time = new Date().getTime();
		const hisItem = {
			type: "putLicenseInfo",
			params: {
				_id : item._id,
				sk : "pllog:" + item.licenseType?.toLowerCase() + ":" + item.sk + ":" + time,
				created: time,
				creator: localStorage.getItem("usersub"),
				type: "revoke",
				data: {used: item?.used || []}
			}
		}
		await axios.post(getApiUrl("license") + "/license/getlicenseinfo", {data: hisItem}, getHeader());
		
		// lcuse 삭제
		const lcuseSK = pools.find((f) => f._id == item._id && f.sk.includes("lcuse:") && f.sk.includes(item.licenseType?.toLowerCase() + ":" + item.sk))
		const sDelParam = {
			type: "deleteLicenseInfo",
			params: {
				_id: _id,
				lcKey: lcuseSK.sk
			}
		}
		await axios.post(getApiUrl("license") + "/license/getlicenseinfo", {data: sDelParam}, getHeader());

		// pl update, remove
		const sData = {
			type: "updateLicenseInfo",
			params : {
				TableName: "rayteams-license",
				Key: {
					_id : _id, 
					sk : "pl:" + item.licenseType?.toLowerCase() + ":" + item.sk
				},
				UpdateExpression: "SET #used = :used, #expired = :expired REMOVE activated, assigned",
				ExpressionAttributeNames: {
					"#used": "used",
					"#expired": "expired"
				},
				ExpressionAttributeValues: {
					":used": "",
					":expired": MAX_TIME_STAMP
				},
				ReturnValues : "ALL_NEW"
			}
		}
		await axios.post(getApiUrl("license") + "/license/getlicenseinfo", {data: sData}, getHeader());

		const paramsUpdateInfo = {
			type: "updateLicenseInfo",
			params: {
				TableName: "rayteams-license",
				Key: {
					_id: _id,
					sk: "info",
				},
				UpdateExpression:
					"SET #used = #used - :num",
				ExpressionAttributeValues: {
					":num": 1,
				},
				ExpressionAttributeNames: {
					"#used": `u_${item.licenseType?.toLowerCase()}`,
				},
				ReturnValues : "ALL_NEW"
			}
		};
		await axios.post(getApiUrl("license") + "/license/getlicenseinfo", {data: paramsUpdateInfo}, getHeader());
		const ret  = await InfoAction.callDB({ type : "query", region : "ap-northeast-2", params : {
			TableName: "rayteams-user",
			KeyConditionExpression: "#_id = :_id and begins_with(#sk, :sk)",
			ExpressionAttributeNames: { "#_id": "_id", "#sk": "sk" },
			ExpressionAttributeValues: { ":_id": lcuseSK?.sk.split(":")[3], ":sk": `product:${lcuseSK?.pid}` }
		}});
		if(ret.length > 0){
			const userProductKey = ret.find(f => {
				return f.appsLcuse.some(subItem => subItem?.lcKey == lcuseSK?.sk)
			});

			const payload = {
                type: "update",
                region: "ap-northeast-2",
                params: {
                    TableName: "rayteams-user",
                    Key: {
                        _id: lcuseSK?.sk.split(":")[3],
                        sk: userProductKey?.sk,
                    },
                    UpdateExpression: "SET #canceled = :canceled, #status = :status, #statustype = :statustype",
                    ExpressionAttributeNames: {
                        "#canceled": "canceled",
						"#status": "status",
						"#statustype": "statustype"
                    },
                    ExpressionAttributeValues: {
                        ":canceled": time,
						":status": "revoke",
						":statustype": "revoke",
                    },
                },
            };
            await InfoAction.callDB(payload);
		}

		if(mgroup && mgroup?.countries.length > 0){
			const paramsBranch = {
				type: "updateLicenseInfo",
				params: {
					TableName: "rayteams-license",
					Key: {
						_id: _id,
						sk: `info:${mgroup._id}`,
					},
					UpdateExpression:
						"SET #used = #used - :num",
					ExpressionAttributeValues: {
						":num": 1,
					},
					ExpressionAttributeNames: {
						"#used": `u_${item.licenseType?.toLowerCase()}`,
					},
					ReturnValues : "ALL_NEW"
				}
			}
			await axios.post(getApiUrl("license") + "/license/getlicenseinfo", {data: paramsBranch}, getHeader());
		}

		//localStorage.getItem("usersub")
		RTAudit({ lvl : 7, msg : `[${_id}] Revoke License` }, JSON.stringify({ subject : item }))
		setLoading(false);
		setModalRevoke({ show: false, data: {} });
		getData_pool();
	};

	const checkRevoke = async (item) => {
		if(_id) {
			setModalRevoke( { show: true, data: item });
		}
	};

	const checkMgroup = async (item) => {
		const mgroupId = mgroup.countries.length > 0 ? mgroup._id : "";
		console.log("mgroupId : ", mgroupId);
		if(mgroupId != "") {
			const queryFilter = {
				_id: {
					ComparisonOperator: "EQ",
					AttributeValueList: [_id],
				  },
				sk: {
					ComparisonOperator: "EQ",
					AttributeValueList: [`info:${mgroupId}`],
				},
			}
			try{
				const ret = await queryData("rayteams-license", queryFilter);
				if( ret.Items[0]?.t_normal <= ret.Items[0]?.u_normal ) {
					alert("보유 한 라이선스 수량이 없습니다. 본사에 문의 주세요.")
				} else {
					setModalData({ show: true, data: item });
				}
			}catch(err){
				console.log(err);
			}
			setLoading(false)
		} else {
			setModalData({ show: true, data: item });
		}
	}

    const headers_rayfusion = [
		{ value : "userid", label: t("User"), type: "PUSER" },
		{ value : "sk", type: "HIDE", style: {width: 0} },
		{ value : "groupid", label : "Group", type : "GROUP" },
		{ value : "licenseName", label: t("Type") },
		{ value : "assigned", label: t("Assigned"), type : "DATE", style : { width : 150 }, orderNo: "order", descNo: "desc" },
		{ value : "activated", label: t("Activated"), type : "DATE", style : { width : 150 }, orderNo: "order2", descNo: "desc2" },
        { value : "expired", label : t("Expired"), style : { width : 180 } },
		{ value : "creator", label : t("Creator"), type: "MUSER", noemail: true },
		{ value : "reason", label : t("Reason") },
		{ label : t("Del"), btncss : "danger", code : "lic-dellc", type : "BUTTON2", 
			btnClick: async (item) => {
				await delClick(item);
			} 
		},
    ];
	
	const headers = [
		{ value : "userid", label: t("User"), type : "USER", style: { width: 200 }, hide: typeof(config?.showalludata) === "boolean" ? !config?.showalludata : true },
		// { value : "userid", label: t("User"), type : "USER", style: { width: 200 }, hide: false },
		{ value : "sk", type: "HIDE", style: {width: 0} },
		{ value : "groupid", label : "Group", type : "GROUP" },
		{ value : "licenseName", label: t("Type") },
		{ value : "assigned", label: t("Assigned"), type : "DATE", style : { width : 150 }, orderNo: "order", descNo: "desc" },
		{ value : "activated", label: t("Activated"), type : "DATE", style : { width : 150 }, orderNo: "order2", descNo: "desc2" },
        { value : "expired", label : t("Expired"), style : { width : 150 } },
		{ value : "creator", label : t("Creator"), type: "MUSER", noemail: true },
		{ value : "reason", label : t("Reason") },
		{ label : t("Management"), btncss : "danger", code : "lic-dellc", type : "BUTTON2", b2b: partner, buttonLabel: "회수",
			btnClick: async (item) => {
				await checkRevoke(item);
			} 
		},
    ];

	const GetUserById = async (data) => {
		const user = allusers.find(x => x._id === data && x.email);
		return user;
	}

	const getGroup = async (groupid, userRegion) => {
		try{
			const ScanFilter = {
				_id: {
					ComparisonOperator: "EQ",
					AttributeValueList: [groupid],
				},
				sk: {
					ComparisonOperator: "EQ",
					AttributeValueList: ["info"],
				},
			}
			const ret = await fetchData("rayteams-group", ScanFilter, userRegion);
			return ret.Items[0];
		}catch{
			return [];
		}
    }

	const getProductId = async () => {
		const scanFilter = {
			_id: {
				ComparisonOperator: "EQ",
				AttributeValueList: [modalData.data.pid]
			},
			appitems : {
				ComparisonOperator: "CONTAINS",
				AttributeValueList: [_id]
			},
		}
		const ret = await fetchData("rayteams-product", scanFilter);
		const pRet = await idData("rayteams-product", ret.Items[0]?._id);
		setProductInfo(pRet.Items.filter(f => f.sk.indexOf("price:ray") > -1 ));
	}

	const getTypePeriod = async () => {
		setLoading(true);
		try{
			// const sData = {
			// 	type: "licenseDataQuery",
			// 	_id: _id,
			// 	sktype: "type",
			// 	mgroup: mgroup || [],
			// 	b2b: process.env.REACT_APP_COMPANY || "ray"
			// }
			// const ret = await axios.post(getApiUrl("license") + "/license/getlicenseinfo", {data: sData}, getHeader());

			let params = {
				TableName: "rayteams-license",
				KeyConditionExpression: "#_id = :_id and begins_with(#sk, :sk)",
				ExpressionAttributeNames: { "#_id": "_id", "#sk": "sk" },
				ExpressionAttributeValues: { ":_id": _id, ":sk": "type" }
			}
	
			if (partner != "ray") {
				params = {
					...params,
					FilterExpression: "#b2b = :b2b",
					ExpressionAttributeNames: {
						...params.ExpressionAttributeNames,
						"#b2b": "b2b"
					},
					ExpressionAttributeValues: {
						...params.ExpressionAttributeValues,
						":b2b": partner
					}
				}
			}
			const sData = {
				type: "query",
				b2b: partner,
				params
			}

			const ret = await axios.post(getApiUrl("license") + "/license/getlicenseinfo", {data: sData}, JWT());

			setLicenseType(ret?.data?.data);
		}catch(err){
			console.log(err);
		}
		setLoading(false);
	}

	const headerClick = (e) => {
		setHolePageWhenUpdate(true);
		setOrder({ order : e.orderNo, desc: e.descNo });
		dispatch({
			type: ProgramConstant.PAGESTAT,
			item: {
				licenselist : {
					selCol : e.value,
					order : pagestat.order,
					order2 : pagestat.order2,
					[`${e.descNo}`] : (pagestat?.[`${e.descNo}`] === "desc" ? "asc" : "desc")
				}
			}
		});
	};

	const filterCheck = (item) => {
        var tf = true;
        filter.map(x => {
            if(tf)
                tf = (item[x.code]?.toLowerCase() === x.value?.toLowerCase() || x.value === "");
        });
        return tf;
    };

	const handleFilter = (e, item) => {
		setHolePageWhenUpdate(false);
        setFilter( filter.map(x => x.code === item.code ? {...x, value : e.value } : x));
	};

  	return <div className='p-4'>
		{!loading && more && <div className='mb-4'>
			<LicenseStatChart items={pools} />
		</div>}
		{!loading && !more && <>
			{/*<BiHelpCircle size="24" className="me-2 text-danger cursor-pointer" onClick={() => setHelp(!help)} />*/}
			<div className="card">
				<div className='card-header pb-0'>
					<RMLicenseAppInfoPanel callbackok={() => {getData_pool();}}></RMLicenseAppInfoPanel>
					{partner == "ray" && <ul id='ulpoolitems' className="nav nav-tabs nav-stretch border-top mt-4 pt-2" key="ulpoollist" style={{ borderRadius: "0" }}>
						{tabs.map((x, idx) => {
							return <li className="nav-item" role='presentation' key={`li-${x.value}${idx}`} onClick={() => setTabs(x.value)}>
								<Link to="#" className={"nav-link " + (tab === x.value ? 'active' : '')} key={`lk-${x.value}${idx}`} style={{ height:"-webkit-fill-available" }}>
									{t(x.label)} {x.value === "used" && <span className="badge bg-secondary ms-2">{poolItems && poolItems.length}</span>}
								</Link>
							</li>
						})}
					</ul>}
					<div className='d-flex'>
						{filter.map((x, idx) => <div className='pt-3 mb-3' style={{ minWidth: 130 }}> <Select
								key={"f-" + x.code + idx}
								onChange={(e) => handleFilter(e, x)}
								value={x.list.filter(y => y.value === x.value)}
								options={x.list}
								data-dropdown-css-className="w-200px"
								className="form-select-transparent me-2 w-150px" /></div>)}
						{mgroup?.countries?.length == 0 && <div className='pt-3'>
							<MLComboSingle options={{
								value: selectedMgroup,
								list: mgroupList.map( x => {
									return (
										{ 
											value : x._id,
											label : x.name
										}
									)
								})
							}} handleChanged={(e) => setSelectedMgroup(e.target.value)}/>
						</div>}
					</div>
				</div>
				{tab === "used" && <>
					{ poolItems.length > 0 && <MLTable 
							headers={_id == "App-RAYFusion" ? headers_rayfusion : headers} 
							rowkey={'sk'}
							items={poolItems}
							headerClick={headerClick}
							order={pagestat?.order || "assigned"}
							order2={pagestat?.order2 || "activated"}
							desc={pagestat?.desc || "desc"}
							desc2={pagestat?.desc2 || "desc"}
							selectedColumn={pagestat?.selCol}
							noheader={true} 
							holePageWhenUpdate={holePageWhenUpdate}/>}
					{ poolItems.length === 0 && <MLPanelNoData /> }
				</>}
				{tab === "groups" && <div className="bg-light">
					<div className="tab-content" id="">
						<div className="tab-pane fade show active">
							<div className="header align-items-stretch d-block" style={{position: "initial"}}>
								<div className="d-flex w-100 justify-content-between align-items-center">
									<div className="d-flex align-items-center text-dark fw-bolder fs-3 mb-4">{t("Licenses 사용현황")}</div>
									<div className="d-flex align-items-center">
									</div>
								</div>
							</div>
							<div className="flex-wrap flex-stack mt-6">
								<div className="row">
								{grouplicenses && grouplicenses.map((x, idx) => { 
									let usedCnt = 0;
									usedCnt = RMLCTYPES.map(m => x.hasOwnProperty(`u_${m.value}`) ? parseInt(x?.[`u_${m.value}`]) : 0).reduce((prev, curr) => prev + curr, 0);
									let totalCnt = 0;
									totalCnt = RMLCTYPES.map(m => x.hasOwnProperty(`t_${m.value}`) ? parseInt(x?.[`t_${m.value}`]) : 0 ).reduce((prev, curr) => prev + curr, 0);
									let p = 0;
									p = (isNaN(usedCnt) ? 0 : usedCnt) / (isNaN(totalCnt) ? 0: totalCnt) * 100;
									return ( <div className="col" title={x._id} style={{ minWidth: "270px" }}>
									<div className="card card-flush mb-5 mb-xl-10">
										<div className="card-header bg-muted pb-0">
											<div className="card-title d-flex flex-column">
												<div className="d-flex align-items-center">
													<span className="fs-4 pt-1 fw-bold lh-1 ls-n2"><RMManagerGroup _id={x.sk.split(":")[1]} /></span>
												</div>
											</div>
										</div>
										<div className="card-body pt-2 pb-8 d-flex flex-wrap align-items-center">
											<div className="d-flex flex-column content-justify-center flex-row-fluid">
												<div className="d-flex align-items-center flex-column mb-8 w-100">
													<div className="d-flex justify-content-between fw-bold fs-5 w-100 mt-auto mb-2">
														<span>{t("사용량")}</span>
														<span>
															{isNaN(usedCnt) ? 0 : usedCnt} / {totalCnt}
														</span>
													</div>
													<div className="h-8px mx-3 w-100 bg-secondary rounded">
														<div className="bg-info rounded h-8px" role="progressbar" style={{width: "" + p + "%" }}></div>
													</div>
												</div>
												{RMLCTYPES.map(r => {
													return( x.hasOwnProperty(`t_${r.value}`) && <>
													<div className="d-flex fw-semibold align-items-center my-1" id={r._id}>
														<div className="bullet w-8px h-3px rounded-2 bg-gray me-3"></div>
														<div className="fs-6 flex-grow-1 me-4">{r.label}</div>
														<div className="fw-bolder fs-5 text-info ">
															{x.hasOwnProperty(`u_${r.value}`) ? x?.[`u_${r.value}`] : 0}
															<span className="text-muted fs-5 fw-normal"> / {x.hasOwnProperty(`t_${r.value}`) ? x?.[`t_${r.value}`] : 0}
															</span>
														</div>
													</div>
													</>
													)
												})}
											</div>
										</div>
									</div>
									</div>
								)})}
								</div>
							</div>
						</div>
					</div>
				</div>}
			</div>
		</>}
        
		{loading && <MLPanelLoading />}
		<Modal show={modalRevoke.show && modalRevoke?.data} size={"lg"} style={{ backgroundColor: "#42476c !important"}}>
			<Modal.Header className='modal-header header-bg'>
				<h4>License Revoke
					<small className={"ms-2 fs-7 fw-normal opacity-50 "}>{modalRevoke.data?._id}</small>
				</h4>
			</Modal.Header>
			<Modal.Body className='flex-fill pt-0 formcard'>
				<div className="card bg-light text-dark mb-2">
                    <div className="card-body p-4">
                        <b>{<MLUser _id ={modalRevoke.data?.userid} />}</b>{t(" 사용자의 라이선스를 회수 합니다.")}<br />
                    </div>
                </div>

				{loading && <MLPanelLoading contents={t("라이선스를 회수하고 있습니다.")} />}
				{!loading && lError && <>
					<div className="d-flex align-items-center w-100 text-center">
						<div className='mx-auto'>
							<div className='mb-2'>
								<h2>Error</h2>
								<div className='text-comment'>{t("라이선스 회수 중 문제가 발생했습니다. 관리자에게 문의 해주시기 바랍니다.")}</div>
							</div>
						</div>
					</div>
				</>
				}
				{!loading && !lError && <>

				<form className="form pt-1">

				</form>
				</>}
				<div className='mt-4 text-end'>
					<MLButton
						options={{ label : t("Cancel"), size : "md", color : COLORS.DEFAULT, action : () => setModalRevoke({ show: false, data: {} })}}
						css={'me-1'} />
					{!lError && <RTButton options={{
						label : loading ? t('Please wait...') : t('Revoke'),
						size : 'MD',
						ing : loading,
						needValid : true,
						action : () => delClick(modalRevoke?.data),
					}} css="me-1" />
					}
				</div>
			</Modal.Body>
		</Modal>
	</div>
}

const mapState = (state) => {
	const pools = state.LicenseReducer.pools;
	const grouplicenses = state.LicenseReducer.grouplicenses;
	const loginuser = state.AuthReducer.loginuser;
	const pagestat = state.ProgramReducer.pagestat["licenselist"] || {};
	const page = state.ProgramReducer.page;
	const allusers =  state.ProgramReducer.allusers;
	const managedApp = state.AppCategoryReducer.managedApp;
	const config = state.ProgramReducer.config;

	return { pools, grouplicenses, loginuser, pagestat, page, allusers, managedApp, config };
};

const mapDispatch = (dispatch) => ({
    
});

export default connect(mapState, null)(LicenseAppListPage);
