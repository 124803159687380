import { useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import { fetchData, countData } from '../libs/db';
import { ProgramConstant } from '../redux/reducers';
import { InfoAction } from '../redux/actions';
import RMWidgetBarChart2 from '../components/widgets/RMWidgetBarChart2.';
import RMBigChart from '../components/common/RMBigChart';
import RMWidgetPieChart2 from '../components/widgets/RMWidgetPieChart2';
import RMStatChart from '../components/common/RMStatChart';
import { useTranslation } from 'react-i18next';

const StatPagesV2 = ({users, user, page}) => {
	const dispatch = useDispatch();
    const [t] = useTranslation(['translation']);
    const [loading, setLoading] = useState(false);
    const [allgroups, setAllGroups] = useState([]);
    const [period, setPeriod] = useState("c");
    const [chartData, setChartData] = useState({ list : [], xOptions : { xperiod : "days", dformat : "YYYY-MM-DD" } });

	useEffect(() => {
		dispatch({ type : ProgramConstant.SET_PAGE, page : { 
			code : "stats", 
			view : "summary", 
			title : "Statistics", 
			small : "", 
			options : [
			]
		}})
	}, []);

    return <div>
        {/*
        */}
        <RMStatChart title={t('Ray Virtual Smile 생성')} query={{ type : "scan", region : "ap-northeast-2", params : {
            TableName: "rayteams-project",
            FilterExpression: "#sk=:sk and #status=:status",
            ExpressionAttributeNames: { "#sk": "sk", "#status": "status" },
            ExpressionAttributeValues: { ":sk": "qr:u", ":status" : "CONFIRMED" },
            ProjectionExpression : "#sk, ownerGroupId"
        }}} datekey={'updated'} groups={'countryCode'} />
    </div>
}

const mapState = (state) => {
    const users = state.AuthReducer.users;
    const user = state.AuthReducer.user;
	const page = state.ProgramReducer.page;

	return { users, user, page };
};

export default connect(mapState, null)(StatPagesV2);
