import axios from 'axios';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { MLButton, MLRefreshButton, MLChartButton } from '../../components/common/MLButton';
import { MLText } from '../../components/common/MLInput';
import { MLPanelLoading } from '../../components/common/MLPanel';
import MLTable from '../../components/common/MLTable';
import { history } from '../../history';
import { COLORS } from '../../constants/FromInputs';
import { RTProductConstant } from '../../redux/reducers';
import { RMIconChart, RMIconList } from '../icon/MLIcon';
import { fetchData } from '../../libs/db';
import { getApiUrl, getHeader } from '../../redux/reducers/AuthReducer';
import { JWT } from '../../config';
import ProductStatCard from '../product/ProductStatCard';
import ProductChart from '../product/ProductChart';
import { ProgramConstant } from '../../redux/reducers';
import { useTranslation } from 'react-i18next';

const RTProductMainList = ({ RTproducts, page }) => {
	const dispatch = useDispatch();
	const [t] = useTranslation(['translation']);
	const [more, setMore] = useState(true);
	const [loading, setLoading] = useState(true);
	const [items, setItems] = useState([]);
	const [search, setSearch] = useState("");

	useEffect(() => {
		dispatch({ type : ProgramConstant.SET_PAGE, page : { 
			code : "product", 
			view : "summary", 
			title : "Product", 
			small : t("소프트웨어 제품 관련 정보"), 
			options : [
				{ view : "summary" }, 
				{ view : "list" } 
			]
		}})
	}, []);

	useEffect(() => {
        if(page?.view)
            setMore(page.view === "summary");
	}, [page]);

	useEffect(() => {
        getData();
	}, []);

	useEffect(() => {
		genData();
	}, [search, RTproducts])

    const getData = async () => {
		try{
			const ret = await fetchData("rayteams-product", {});
			dispatch({ type: RTProductConstant.GET_ALL_RTPRODUCTS, items: ret.Items });
		}catch(err){
			console.log(err);
		}
		setLoading(false);
    }

	const genData = async () => {
		setItems(search ? RTproducts.filter((x) => x.sk === 'info').filter(x => (x?.title?.indexOf(search) > -1 || x?.type?.indexOf(search) > -1)) : (RTproducts.filter((x) => x.sk === 'info') || []));
	}

	const testCloudWatch = async () => {
		const bodyData = {
			data: {
				userId: "testuserid",
				logType: "manager",
				menu: "product"
			}
		}
		const ret = await axios.post(getApiUrl("license") + "/managerlog/putlog", bodyData, JWT());

	}

    const headers = [
        { link : "_id", linkpath : "/RTProductInfo", value : "title", label : t("Title"), css : "text-start", style : { width : 250 } },
		{ value : "appitems", label: t("Applications"), type: "RTAPP" },
        { value : "isdraft", label: t("IsDraft"), css: "text-center", type : "TFVALUE" },
        { value : "defaultprice", label: t("Default Price"), css: "text-end", type: "CUR" },
        { value : "sells", label: t("Sells"), css : "text-center" },
        { value : "subperiod", label: t("Sub Period") },
		{ value : "created", label : t("Created"), css : "text-end", type : "DATE" },
    ]

  	return <div className='p-4'>
		<div className='mb-4'>
			<ProductStatCard items={RTproducts}/>
		</div>
        {more && <ProductChart items={RTproducts}/>}
		{!more && <div className="card">
			<div className="card-header d-flex justify-content-between align-items-center">
				<div className="card-label fw-bold text-gray-800 fs-5">{items.length}</div>
				<div className="d-flex">
					<MLButton options={{
						color : COLORS.PRIMARY,
						size : "sm",
						label : t("Product 등록"),
						action : () => history.push("/RTProductAdd")
					}} css="me-2"/>
					<MLText options={{
						placeholder : t("검색어 입력"),
						size : "sm",
						value : search,
					}} handleChanged={(e) => setSearch(e.target.value)} css="mb-0" />
				</div>
			</div>
			{!loading && <MLTable 
				headers={headers} 
				items={items} noheader={true} />}
			{loading && <MLPanelLoading />}
    	</div>}
  	</div>
}

const mapState = (state) => {
    const RTproducts = state.RTProductReducer.RTproducts;
	const page = state.ProgramReducer.page;
	return { RTproducts, page };
};

const mapDispatch = (dispatch) => ({

});

export default connect(mapState, null)(RTProductMainList);