
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { getRandomColors } from '../../libs/colors';
import { MLFileSize } from '../common/MLCommon';
import { BsStickies, BsServer } from 'react-icons/bs';
import MLBarChart from '../widgets/charts/MLBarChart';
import { ProgramConstant } from '../../redux/reducers';
import { MLPanelNoData } from '../common/MLPanel';
import { getS3KeyInfo } from '../../libs/aws';


const WidgetStorageUsage = ({ group, widgetcache }) => {
    const [t] = useTranslation(['translation']);
	const [totalInfo, setTotalInfo] = useState({
		count : 0,
		size : 0,
	});
	const [loading, setLoading] = useState(true);
    const [chartData, setChartData] = useState(null);
	const dispatch = useDispatch();

    useEffect(() => {
		if(group._id){
			setLoading(true);
			getData();
		}
    }, [widgetcache, group]);

    const getData = async () => {
		const cache = widgetcache.find(x => x.key === "STORAGE_INFO-" + group._id + "");
		var getnew = true;
		if(cache){
			getnew = cache.expired < new Date().getTime();
		}
		var raw;
		if(getnew){
            var ret = await getS3KeyInfo(group.region,group._id);
            console.log("storage ret");
            console.log(ret);
			setLoading(false);
			raw = ret;
			dispatch({ type : ProgramConstant.WIDGET_CACHE_UPDATE, item : {
				key : "STORAGE_INFO-" + group._id + "",
				data : raw,
				expired : new Date().getTime() + (3600 * 1000)
			}});
		}else{
			raw = cache.data;
		}
		//raw.sort((a, b) => a.size > b.size ? -1 : 1);
		const colorSet = getRandomColors(Object.keys(raw).length, 0.8);
		var list = [];
		Object.keys(raw).map(x => { list.push({ label : x, size : raw[x].size / 1024 / 1024, count : raw[x].count}) })
		var showlist = [];
		var etc = { label : "ETC", size : 0 };
		list.sort((a, b) => a.size > b.size ? -1 : 1).map((x, idx) => {
			if(idx < 5){
				showlist.push(x);
			}else{
				etc.size += x.size;
			}
		})
		if(etc.size !== 0)
			showlist.push(etc);

		setTotalInfo({
			count : list.reduce((partialSum, a) => partialSum + a.count, 0),
			size : list.reduce((partialSum, a) => partialSum + a.size, 0)
		});

		setChartData({
			labels: showlist.map(x => {
				return x.label
			}),
			datasets: [{
				data: showlist.map(x => Math.round(x.size, 2)),
				barThickness: 28,
				backgroundColor: colorSet.map(x => x.opacityColor)
			}]
		});
    }

    return <div className="card">
		<div className='card-header'>
            <h3 className='card-title'>
				<span className='card-label fs-5 fw-bolder'>Storage</span>
				<span></span>
			</h3>
			<div className='card-toolbar text-muted fs-6'>
				<span className='badge badge-secondary me-2'>
					<BsStickies style={{ width : 14, height : 14}} /> {totalInfo.count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
				</span>
				<span className='badge badge-secondary'>
					<BsServer style={{ width : 14, height : 14}} /> {MLFileSize(totalInfo.size * 1024 * 1024)}
				</span>
			</div>
		</div>
		<div className='pt-0 card-body' style={{ height : chartData?.labels?.length > 0 ? chartData?.labels?.length * 56 : 'initial'}}>
			{chartData?.labels?.length > 0 && <MLBarChart data={chartData} chartOptions={{
				showLabels: false,
				hideGideLines: true,
			}} legendLocation='bottom' />}
			{chartData?.labels?.length == 0 && <MLPanelNoData />}
		</div>
    </div>
}

const mapState = (state) => {
    const auth = state.AuthReducer.user;
    const widgetcache = state.ProgramReducer.widgetcache;
    return { auth, widgetcache };
};

const mapDispatch = dispatch => ({
});

export default connect(mapState, null)(WidgetStorageUsage);