const initialState = { 
	feedback : {},
	feedbacks : [],
};

export const FeedBackConstant = {
	GET_ALL_FEEDBACKS : "GET_ALL_FEEDBACKS",
	GET_FEEDBACK : "GET_FEEDBACK",
	UPDATE_FEEDBACK : "UPDATE_FEEDBACK",
};

export const FeedBackReducer = (state = initialState, action) => {
	switch (action.type) {
		case FeedBackConstant.GET_ALL_FEEDBACKS:
			return {...state, feedbacks : action.items };
		case FeedBackConstant.GET_FEEDBACK:
			return {...state, feedback : action.item };
		case FeedBackConstant.UPDATE_FEEDBACK:
			return {...state, 
				feedback : action.item,
				feedbacks : state.feedbacks.map(x => x._id === action.item._id ? action.item : x)
			};
		default:
			return state;
	}
};
