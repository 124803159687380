import { Modal } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { BUTTONS, COLORS, INPUTS, SPAN } from '../../constants/FromInputs';
import { MLButton } from '../common/MLButton';
import { MLForm } from '../common/MLForm';
import { AuthConstant, getAPI, getHeader, getAdminCreateHeader } from '../../redux/reducers/AuthReducer';
import moment from 'moment';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { MLRadioButton, MLToggle } from '../common/MLInput';
import { RegionList } from '../../libs/cogInfo';
import { MLPanelLoading } from '../../components/common/MLPanel';
import { Loader } from '../common/Loader';
import { RTAudit } from '../../libs/audit';
const env = (location.href.indexOf("localhost") > -1 || location.href.indexOf(".raydevelop.com") > -1) ? "development" : "production";
import { useTranslation } from 'react-i18next';
import { AppConfig } from '../../config';
const RMModalAddAdmin = ({group, modalData, callbackClose, adminUsers, user }) => {
	const dispatch = useDispatch();
	const [t] = useTranslation(['translation']);

    const [data, setData] = useState({});
	const [selectedRegion, setRegions] = useState({});
	const [selectedRegionErr, setSelectedRegionErr] = useState(true);
	const [loading, setLoading] = useState(false);
	const [selectedType, setType] = useState(false);
	const [passConfirmErr, setPassConfirmErr] = useState('');
	const [emailErr, setEmailErr] = useState('');
	const [isErrMsg, setIsErrMsg] = useState(false);
	const [errMsg, setErrMsg] = useState('');

	useEffect(() => {
		setData({});
		setRegions([]);
		setType(false);
	}, [callbackClose]);

	useEffect(() => {
		const pRegionList = [];
		Object.keys(selectedRegion).map( (key) => {
			selectedRegion[key] === true && pRegionList.push(key);
		});
		if(!selectedType && pRegionList.length === 0) {
			setSelectedRegionErr(true);
		} else {
			setSelectedRegionErr(false);
		}
	}, [selectedRegion]);

	const addSub = async () => {
		setLoading(true);
		const pRegionList = [];

		if(adminUsers.filter(x => x.email == data.email)[0]?.email) {
			// 이미 존재하는 이메일
			setEmailErr(t("An account with the given email already exists."));
			setLoading(false);
			return;
		}

		const params = {
			email : data.email,
			name : data.name,
			password : data.password,
			isadmin : true,
			group : 'manager',
			regions : pRegionList,
			type : selectedType ? 'manager' : 'user',
			pwchange : "no"
		}

		try {
			const ret = await axios.post("https://" + 
                (env === "production" ? "api-manager-user." + AppConfig().domain : "api-manager-user." + AppConfig().domain) + 
                "/createmanageraccount", params, getAdminCreateHeader());

			callbackClose && callbackClose();
		} catch (e) {
			setIsErrMsg(true);
			setErrMsg(e.message);
		}
		RTAudit({ lvl : 7, msg : '[' + data.name + '] Account Created' })
	}
    
    const controls = [
		{
			type : INPUTS.EMAIL,
			options : {
				label : t('이메일'),
				name : 'email',
				required : true,
				isValidEmail : true,
				err: emailErr,
				errmsg: emailErr
			}
		},
		{
			type : INPUTS.TEXT,
			options : {
				label : t('이름'),
				name : 'name',
				required : true
			}
		},
		{
			type : INPUTS.PASSWORD,
			options : {
				label : t('패스워드'),
				name : 'password',
				required : true,
				useToggle : true,
				isValidPassword: true
			}
		},
		{
			type : INPUTS.PASSWORD,
			options : {
				name: 'repassword',
				label: t('패스워드 확인'),
				err: passConfirmErr,
				required: true,
				useToggle: true,
				isMatch: true,
				originPassword: data?.password
			}
		},
		{ 
			type : SPAN.VERTICAL, 
			options : { val : 20 } 
		},
		{
			type : INPUTS.COMPONENT,
			options : { },
			component : <>
				<tr>
					<td>
						<MLToggle
							options={{
								label: t('관리자'),
								name: 'type',
								value: selectedType,
								readonly: false,
							}} 
							handleChanged={(e) => { console.log(e); setType(e.target.value)}} 
						/>
					</td>
				</tr>
			</>
		},
		{ 
			type : SPAN.VERTICAL, 
			options : { val : 20 } 
		},
		{
			type : BUTTONS.BUTTONS, 
			options : { },
			buttons : [{
				type : BUTTONS.NORMALBUTTON, 
				options : { 
					label : t('Add User'), 
					color : COLORS.PRIMARY,
					needValid : true,
                    action : () => addSub()
				}
			},{
				type : BUTTONS.NORMALBUTTON, 
				options : { 
					label : t('Cancel'), 
					color : COLORS.SECONDARY,
                    action : () => callbackClose()
				}
			}]
		}
	];
	
	return <div className=''>
		<Modal show={modalData.show} size={"lg"} style={{ backgroundColor: "#42476c !important"}}>
			<Modal.Header className='modal-header header-bg'>
				<h2>{t("Admin User 추가")}
					<small className={"ms-2 fs-7 fw-normal opacity-50 "}></small>
				</h2>
			</Modal.Header>
			<Modal.Body className='flex-fill pt-0 formcard pt-6'>
				{loading && <Loader />}
				{!loading && 
					<MLForm controls={controls} nocard={true} data={data} onChanged={(e) => { console.log(e);setData(e);}} />
				}
				{isErrMsg && <label className="text-danger mb-2">{errMsg}</label>}
			</Modal.Body>
		</Modal>
	</div>
}

const mapState = (state) => {
	const adminUsers = state.AuthReducer.adminUsers;
	const user =  state.AuthReducer.user;
	return { adminUsers, user };
};

const mapDispatch = dispatch => ({
})

export default connect(mapState, null)(RMModalAddAdmin);
