import axios from "axios";
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from "react";
import { Link, useParams } from 'react-router-dom';
import { LANGUAGE } from '../../constants/RMConstants';
import { MLButton } from '../common/MLButton';
import { UploadS3 } from "../../libs/s3";
import { MLComboSingle, MLText } from "../common/MLInput";
import { ConstructionOutlined } from "@mui/icons-material";
import { MLForm } from "../common/MLForm";
import MLTable from "../common/MLTable";
import { ProgramConstant } from "../../redux/reducers";
import { useDispatch } from "react-redux";


const VIEW_TYPE = {
    DEFAULT: 'default',
    LANGKEYS: 'lang_keys',
    TRANS: 'trans',
    TRANSUSER : 'transuser'
};

const getEnv = () => {
    if(location.href.indexOf("localhost") > -1 || location.href.indexOf("127.0.0.1") > -1){
        return "dev-local";
    }else if(location.href.indexOf("raydevelop") > -1){
        return "development";
    }
    return "production";
}

const ServiceConfigLang = ({ UpdateApplication }) => {
    const dispatch = useDispatch();
    const [t] = useTranslation(['translation']);
    const partnerKey = localStorage.getItem('partnerKey') || '';
    const [langs, setLangs] = useState([]);
    const [data, setData] = useState({});
    const [nowlang, setNowLang] = useState("en");
    const [search, setSearch] = useState("");
    const [all, setAll] = useState(false);
    const { _id = process.env.REACT_APP_COMPANY } = useParams();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getConfig();
    }, []);

    const getConfigPath = () => {
        const now = new Date().getTime();
        return "https://" + (getEnv() === "production" ? "ray-data" : "ray-data-development") + 
            ".s3.ap-northeast-2.amazonaws.com/rayrnd/rayteams-service/"+ _id + "/config.json" + `?t=${now}`;
    }

    const getLangPath = (ln) => {
        const now = new Date().getTime();
        return "https://ray-data" + 
            ".s3.ap-northeast-2.amazonaws.com/rayrnd/rayteams-service/ray/translation/" + ln + ".json" + `?t=${now}`;
    }
    const getPubLangPath = (ln) => {
        const now = new Date().getTime();
        return "https://ray-data" + 
            ".s3.ap-northeast-2.amazonaws.com/rayrnd/rayteams-service/"+ _id + "/translation/" + ln + "-pub.json" + `?t=${now}`;
    }

    const getConfig = async () => {
        try{
            const conf = await axios.get(getConfigPath());
            setLangs(conf?.data.languages || []);
            setNowLang("");
        }catch{
            setLangs([]);
            setNowLang("");
        }
    }

    const getLang = async (ln) => {
        setLoading(true);
        let reportedData = {};
        let publishedData = {};
        try{
            const conf = await axios.get(getLangPath(ln));
            reportedData = conf?.data || {};
        }catch{
            //
        }
        try{
            const pubConf = await axios.get(getPubLangPath(ln));
            publishedData = pubConf?.data || {};
        }catch{
            //
        }
        const _data = { ...reportedData, ...publishedData };
        // console.log(_data);
        if (Object.keys(_data)?.length > 0) {
            setData(_data);
            setNowLang(ln);
        } else {
            setData({});
            setNowLang("");
        }
        setLoading(false);
    }

    const del = async (key) => {
        let langPayload = {...data};
        delete langPayload[key];
        await UploadS3(
            "ray-data",
            "rayrnd/rayteams-service/"+ _id + "/translation/" + nowlang + "-pub.json",
            langPayload);
        await UploadS3(
            "ray-data",
            "rayrnd/rayteams-service/"+ _id + "/translation/" + nowlang + ".json",
            langPayload);
        await getLang(nowlang);
    }

    const update = async () => {
        const wrappers = window.document.getElementsByClassName("language-input");
        let list = [];
        for (const wrapper of wrappers) {
            const _elInput = wrapper.getElementsByTagName("input")[0];
            _elInput && list.push(_elInput);
        }
        let langPayload = {};
        for(const elInput of list){
            langPayload[elInput.name] = elInput.value;
        }

        let publishedData = {};
        try {
            const pubConf = await axios.get(getPubLangPath(nowlang));
            publishedData = pubConf?.data || {};
        } catch (error) {
            console.log("ERROR[@getPubLangPath]", nowlang, error?.response?.data || error?.toString());
            const confirm = window.confirm(t("원본 파일을 확인하지 못했습니다. 처음 입력하는 경우라면 계속 진행해주세요."));
            if (!confirm) {
                return;
            }
        }
        console.log("All Words Count", Object.keys(publishedData)?.length);
        console.log("Override Words Count", Object.keys(langPayload)?.length);
        publishedData = { ...publishedData, ...langPayload };
        
        await UploadS3(
            "ray-data",
            "rayrnd/rayteams-service/"+ _id + "/translation/" + nowlang + "-pub.json",
            publishedData);
        await getLang(nowlang);
    }

    return <div className='p-4'>
        <div className="d-flex justify-content-between mb-2">
            <h3>Language</h3>
            <div className="card-toolbar text-end d-flex align-items-center">
                {nowlang && <MLText options={{
                    name : 'search',
                    value : search,
                    placeholder : t('검색어')
                }} css='mb-0' handleChanged={(e) => setSearch(e.target.value) } /> }
                {/*<MLButton options={{
                    label : all ? t("전체") : t("번역 할 것만"),
                    color : "light",
                    action : () => setAll(!all)
                }} css="ms-1" />*/}
                <MLComboSingle options={{
                    list : langs.map(x => ({ value : x, label : LANGUAGE.find(l => l.code === x)?.label })),
                    value : nowlang
                }} css='mb-0 ms-2' handleChanged={(e) => getLang(e.target.value) } />
                {nowlang && <MLButton options={{
                    label : t("적용"),
                    color : "primary",
                    action : () => update()
                }} css='ms-1' />}
            </div>
        </div>
        {!loading && <>
            {search.length >= 2 && Object.keys(data).filter(x => x.indexOf(search) > -1 || data[x].indexOf(search) > -1 ).map((x, idx) => <div 
                key={x} 
                className={"mb-3"}
            >
                <div className="fw-bolder">
                    {x}
                        <MLButton options={{
                        label : t("삭제"),
                        color : "danger",
                        size : "xs",
                        action : () => del(x)
                    }} css='ms-2 mb-1' />
                </div>
                <div>
                    <MLText 
                        options={{
                            name : x,
                            value : data[x]
                        }} 
                        css='mb-0 lang language-input' 
                    />
                </div>
            </div>)}
        </>}
        {/*<table className="table table-bordered">
            <thead>
                <tr>
                    <th>{t("Key")}</th>
                    <th>{t("Value")}</th>
                </tr>
            </thead>
            <tbody>
                {Object.keys(data).map((x, idx) => <tr key={idx}>
                    <td>{x}</td>
                    <td>
                        <MLText options={{
                            name : x,
                            value : data[x]
                        }} css='mb-0 lang' />
                    </td>
                </tr>)}
            </tbody>
        </table>*/}
    </div>
}

export default ServiceConfigLang;
