import axios from 'axios';
import { JWT } from '../../config';
import { Store } from '../../store';
import { LicenseConstant, getAPI } from '../reducers/LicenseReducer';
import { AppCategoryConstant } from '../reducers/AppCategoryReducer';

const API_URL = getAPI("manager-license");

const partner = process.env.REACT_APP_COMPANY || 'ray';

const GetAllLicense = () => async (dispatch) => {
    const ret = await axios.get(API_URL + "/getAlllicense", JWT());
    if (ret.data.status === "success") {
        dispatch({ type: LicenseConstant.GET_ALL_LICENSE, items: ret.data.data });
    }
};

const GetLicenseByGroupId = (_id) => async (dispatch) => {
    const ret = await axios.get(API_URL + "/getbygroupid/" + _id, JWT());
    if (ret.data.status === "success") {
        dispatch({ type : LicenseConstant.GET_LICENSE, items : ret.data.data });
    }
};

const CreateLicense = (info, extraInfo) => async (dispatch) => {
    var body = {
            data : {
            lctype: extraInfo.lctype,
            license: info.license,
            sk: extraInfo.appname + ":" + extraInfo.sk,
            region: extraInfo.region,
        }
    }
    const ret = await axios.post(API_URL + "/admincreatelicenselist", body, JWT());
    if (ret.data.status === "success") {
        dispatch({ type : LicenseConstant.CREATE_LICENSE, items : ret.data.data });
    }
};

const CreateLicenseInternal = (info) => async (dispatch) => {
    var body = {
        data : {
            appname: extraInfo.appname,
            region: extraInfo.region,
        }
    }
    const ret = await axios.post(API_URL + "/createlicenseinternal", body, JWT());
    if (ret.data.status === "success") {
        dispatch({ type : LicenseConstant.CREATE_LICENSE, items : ret.data.data });
    }
}

const RegistLicense = (_id,info) => async (dispatch) => {
    var body = {
        data : {
            appname: info.appname,
            region: info.region,
            groupid: info.groupid,
        }
    }
    const ret = await axios.post(API_URL + "/license/" + _id + "/registLicense", body, JWT());
    if (ret.data.status === "success") {
        dispatch({ type : LicenseConstant.REGIST_LICENSE, items : ret.data.data})
    }
}

const DeleteLicense = (_id, appname) => async (dispatch) => {
    var body = {
        data : {
            appname: appname,
        }
    }
    const ret = await axios.post(API_URL + "/license/" + _id + "/admindeletelicense", body, JWT());
    if (ret.data.status === "success") {
        dispatch({ type : LicenseConstant.DEL_LICENSE, items : ret.data.data})
    }
}

const UpdateLicense = (_id, expired, appname) => async (dispatch) => {
    var body = {
        data : {
            expired: expired,
            appname: appname
        }
    }
    const ret = await axios.post(API_URL + "/license/" + _id + "/adminupdatelicense", body, JWT());
    if (ret.data.status === "success") {
        dispatch({ type : LicenseConstant.UPDATE_LICENSE, items : ret.data.data})
    }
};

const getDynamoAPI = () =>{
    const env = (location.href.indexOf("localhost") > -1 || location.href.indexOf(".raydevelop.com") > -1) ? "development" : "production";
	if(env === "production"){
		return "https://api-teams-info.rayteams.com/common/dynamo";
	}else{
		return "https://api-teams-info.raydevelop.com/common/dynamo";
	}
};

const callDB = async (data) => {
    const ret = await axios.post(getDynamoAPI(), { data : data }, JWT());
    return ret?.data;
};

const GetLcWhiteList = (_id) => async (dispatch) => {
    let params = {
        TableName: "rayteams-license",
        KeyConditionExpression: "#_id = :_id and begins_with(#sk, :sk)",
        ExpressionAttributeNames: { "#_id": "_id", "#sk": "sk" },
        ExpressionAttributeValues: { ":_id": "domains", ":sk": `whitelist:${_id}` }
    }

    if (partner != "ray") {
        params = {
            ...params,
            FilterExpression: "#b2b = :b2b",
            ExpressionAttributeNames: {
                ...params.ExpressionAttributeNames,
                "#b2b": "b2b"
            },
            ExpressionAttributeValues: {
                ...params.ExpressionAttributeValues,
                ":b2b": partner
            }
        }
    }
    const sData = {
        type: "query",
        b2b: partner,
        params
    }

    const ret = await axios.post(API_URL + "/license/getlicenseinfo", {data: sData}, JWT());

    if (ret.data?.data) {
        console.log("GetLcWhiteList : =======", ret.data?.data);
        dispatch({ type : LicenseConstant.GET_LC_WHITELIST, items : ret.data?.data || [] });
    }
};

const GetLcBlackList = (_id) => async (dispatch) => {
    let params = {
        TableName: "rayteams-license",
        KeyConditionExpression: "#_id = :_id and begins_with(#sk, :sk)",
        ExpressionAttributeNames: { "#_id": "_id", "#sk": "sk" },
        ExpressionAttributeValues: { ":_id": "domains", ":sk": `blacklist:${_id}` }
    }

    if (partner != "ray") {
        params = {
            ...params,
            FilterExpression: "#b2b = :b2b",
            ExpressionAttributeNames: {
                ...params.ExpressionAttributeNames,
                "#b2b": "b2b"
            },
            ExpressionAttributeValues: {
                ...params.ExpressionAttributeValues,
                ":b2b": partner
            }
        }
    }
    const sData = {
        type: "query",
        b2b: partner,
        params
    }

    const ret = await axios.post(API_URL + "/license/getlicenseinfo", {data: sData}, JWT());

    if (ret.data?.data) {
        console.log("GetLcBlackList : =======", ret.data?.data);
        dispatch({ type : LicenseConstant.GET_LC_BLACKLIST, items : ret.data?.data || [] });
    }
};

const GetManagerGroupLcTypes = (_id) => async (dispatch) => {
    var mgId = Store.getState().AuthReducer.loginuser?.managergroupid;
    let params = {
        TableName: "rayteams-license",
        KeyConditionExpression: "#_id = :_id and #sk = :sk",
        ExpressionAttributeNames: { "#_id": "_id", "#sk": "sk" },
        ExpressionAttributeValues: { ":_id": _id, ":sk": `info:${mgId}` }
    }

    if (partner != "ray") {
        params = {
            ...params,
            FilterExpression: "#b2b = :b2b",
            ExpressionAttributeNames: {
                ...params.ExpressionAttributeNames,
                "#b2b": "b2b"
            },
            ExpressionAttributeValues: {
                ...params.ExpressionAttributeValues,
                ":b2b": partner
            }
        }
    }
    const sData = {
        type: "query",
        b2b: partner,
        params
    }

    const ret = await axios.post(API_URL + "/license/getlicenseinfo", {data: sData}, JWT());

    if (ret.data?.data) {
        console.log("GetManagerGroupLcTypes : =======", ret.data?.data);
        dispatch({ type : LicenseConstant.GET_LC_MG_TYPES, items : ret?.data.data || [] });
    }
};

export const LicenseAction = {
    GetAllLicense,
    GetLicenseByGroupId,
    CreateLicense,
    RegistLicense,
    DeleteLicense,
    UpdateLicense,
    CreateLicenseInternal,
    GetLcWhiteList,
    GetManagerGroupLcTypes,
    GetLcBlackList,
};