import { Modal } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BUTTONS, COLORS, INPUTS, SPAN } from '../../constants/FromInputs';
import { useTranslation } from 'react-i18next';
import { MLButton } from '../common/MLButton';
import { MLForm } from '../common/MLForm';
import { MLToggle } from '../common/MLInput';
import { RMSUBTYPES } from '../../constants/RMConstants';
import { AuthConstant, getAPI, getHeader } from '../../redux/reducers/AuthReducer';
import moment from 'moment';
import axios from 'axios';
import { ProductAction, WFAction } from '../../redux/actions';
import { COUNTRIES } from '../../constants/Country';
import { JWT } from '../../config';
import { WFConstant } from '../../redux/reducers';

const RMModalAddProductDetail = ({ region, modalData, callbackClose, workflows }) => {
    const [t] = useTranslation(['translation']);
	const dispatch = useDispatch();
    const { _id } = useParams();
    const [data, setData] = useState({ productname : "" });

	useEffect(() => {
		getWF();
	}, []);

	useEffect(() => {
		if(modalData.show){
			setData({ productname : modalData.productname,  });
		}
	}, [modalData.show]);

	const getWF = async () => {
		try{
			// const ret = await axios.get(WFAction.getAPI() + "/workflow", JWT());
			// if (ret.data.status === "success") {
			// 	dispatch({ type: WFConstant.GETALL, items: ret.data.data });
			// }
		}catch(err){
			console.log(err);
		}
	}

    const controls = [
		{ 
			type : INPUTS.TEXT, 
			options : { 
				label : '이름',
				name : 'productname',
				required : true
			},
		},
        {
			type: INPUTS.COMBO,
			options : { 
				label : 'Country',
				name : 'country',
				list : COUNTRIES.map(x => ({ value : x.countryCode, label : x.name })) ,
				required : true
			},
		},
        {
            type : INPUTS.TEXT,
            options : {
                label : "통화",
                name : 'currency',
                required : true,
            }
        },
        {
            type: INPUTS.NUMBER,
            options: {
                label: '상품 기본 가격',
                name: 'price',
            }
        },
        {
            type: INPUTS.COMBO,
            options: {
                label: 'Workflow',
				name : 'workflowId',
				list : workflows ? workflows.map(x => ({ value : x._id, label : x.title }))  : [],
				required : true
            }
        },
        {
            type: INPUTS.NUMBER,
            options: {
                label: '할인율 (%)',
                name: 'discount',
            }
        },
        {
            type: INPUTS.COMPONENT,
            component: <MLToggle options={{
                label : 'Published',
                name : 'ispublished',
                inline : true,
                value : data.ispublished,
            }} handleChanged={(e) => setData({...data, ispublished : e.target.value})} css="mb-8 mt-6  text-end" />,
            options: {
                label: 'Published',
                name : 'ispublished'
            }
        },
        {
			type : SPAN.VERTICAL,
			options : { size : 10 }
		},
	];

	const addProductDetail = async () => {
		try{
            dispatch(ProductAction.CreateProductDetail(_id, data));
		}catch(err){
			console.log(err);
		}
		callbackClose && callbackClose();
	}

	return <div className=''>
		<Modal show={modalData.show} size={"lg"} style={{ backgroundColor: "#42476c !important"}}>
			<Modal.Header className='modal-header header-bg'>
				<h2>Product Detail 추가</h2>
			</Modal.Header>
			<Modal.Body className='flex-fill pt-2 formcard'>
				<MLForm controls={controls} data={data} nocard={true} onChanged={(e) => { console.log(e);setData(e);}} />
				<div className='text-end my-4'>
					<MLButton options={{
						label : '생성', 
						color : COLORS.INFO,
						action : () => addProductDetail()
					}} css="me-1" />
					<MLButton options={{
						label : '취소', 
						color : COLORS.LIGHT,
						action : callbackClose
					}} />
				</div>
			</Modal.Body>
		</Modal>
	</div>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const region =  state.AuthReducer.region;
    const workflows = state.WFReducer.items;
	return { region, user, workflows };
};

const mapDispatch = dispatch => ({
})

export default connect(mapState, mapDispatch)(RMModalAddProductDetail);