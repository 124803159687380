import moment from 'moment';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { history } from '../../history';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { putData, delData } from '../../libs/db';
import { FaSave, FaEdit, FaWindowClose, FaCheckCircle, FaStopCircle } from 'react-icons/fa';
import { ProgramConstant } from '../../redux/reducers';
import { ApplicationAction } from '../../redux/actions';
import Clinic from '../partner/clinic';
import { COLORS } from '../../config/color';
import { RTButton } from '../../libs/buttons';
import { fetchData } from '../../libs/db';
import RMGroup from '../manager/RMGroup';
import MLScope from '../common/MLScope';
import { MLText } from '../common/MLInput';
import { MLButton } from '../common/MLButton';
import ComAppConfig from './ComAppConfig';
import { RTAudit } from '../../libs/audit';
const CompApplicationConfig = ({ user, title, application, applications, applicationConfigs }) => {

	const dispatch = useDispatch();

	const [t] = useTranslation(['translation']);
	const [ items, setItems ] = useState([]);
	const [ isSim, setIsSim ] = useState(false);
	const [ simKey, setSimKey ] = useState([]);
	const [ edit, setEdit ] = useState(false);
	const [ cfg, setCfg ] = useState([]);
	const [ vals, setVals ] = useState({});
	const [ showd, setShowD ] = useState(false);
	const [ cfgKey, setCfgKey ] = useState("g");
	const [ custom, setCustom ] = useState([]);
	const [ list, setList ] = useState([]);
	const [ data, setData ] = useState({});
	const [ process, setProcess ] = useState(false);

	useEffect(() => {
		if(application?.name){
            getBaseConf();
            getCustomConfg();
		}
	}, [application]);

    const getCustomConfg = () => {
        dispatch(ApplicationAction.GetApplicationConfigs(application.name))
    }

    const getBaseConf = async () => {
        const env = (location.href.indexOf("localhost") > -1 || location.href.indexOf(".raydevelop.com") > -1) ? "development" : "production";
        const ret = await axios("https://ray-data" + (env === "production" ? "" : "-development")
            + ".s3.ap-northeast-2.amazonaws.com/rayrnd/applications/" 
            + application.name + "/applicationConfig.json");
        setCfg(ret.data ? Object.keys(ret.data).map(x => ({ 
            key : x, value : ret.data[x]
        })) : []);
    }

    const create = async () => {
        if(cfgKey === "c:" || cfgKey === "g:" || cfgKey === "u:" || custom.length === 0)
            return;
        const verifiedCustom = custom.filter(x => !!x.value?.trim());
        await putData("rayrnd-applications", {
            name : application.name,
            type : "cfg:" + cfgKey,
            values : verifiedCustom,
        });
        getCustomConfg();
        setEdit(false);
        RTAudit({ lvl: 4, msg: 'Create customized config'});
    }

    const deleteCustom = async () => {
        if(!vals.type)
            return;
        await delData("rayrnd-applications", {
            name : application.name,
            type : vals.type
        });
        getCustomConfg();
        setEdit(false);
        RTAudit({ lvl: 4, msg: 'Delete customized config'});
    }

    const updateCustom = (key, val) => {
        console.log(key, val);
        if(custom.find(x => x.key === key)){
            setCustom(custom.map(x => x.key === key ? { key : key, value : val } : x));
        }else{
            setCustom([...custom, { key : key, value : val }]);
        }
        RTAudit({ lvl: 4, msg: 'Update customized config'});
    }

    const clickEdit = (c) => {
        console.log(c);
        setVals(c);
        setCustom(c.values);
        setEdit(true);
    }

    const createNew = () => {
        setVals("");
        setCustom([]);
        setEdit(true);
    }

	return <div className='p-4'>
        {isSim && <>
            <div className="d-flex justify-content-between align-items-center">
                <h5>{t("Config. Simulation")}</h5>
                <div>
                    <MLButton options={{
                        label : t("Exit Simulation"),
                        color : COLORS.SECONDARY,
                        action : () => setIsSim(false)
                    }} css="me-1"/>
                </div>
            </div>
            <MLScope handleChanged={e => setSimKey(e)} edit={true} sim={true} />
            <ComAppConfig application={application} cfg={cfg} scope={simKey}  />
        </>}
        {!isSim && <>
            {!edit && <>
                <div className="fs-3 d-flex justify-content-between align-items-center mb-4">
                    <div>{t("Application Config.")}</div>
                    <div>
                        <MLButton options={{
                            label : showd ? t("Hide default config") : t("Show default config"),
                            color : COLORS.SECONDARY,
                            size : "md",
                            action : () => setShowD(!showd)
                        }} css="me-1"/>
                        <MLButton options={{
                            label : t("Simulation"),
                            color : COLORS.SUCCESS,
                            size : "md",
                            action : () => setIsSim(true)
                        }} css="me-1"/>
                        <MLButton options={{
                            code: "sw-customizedconfig",
                            label : t("Create customized config"),
                            color : COLORS.PRIMARY,
                            size : "md",
                            action : () => createNew()
                        }} />
                    </div>
                </div>
                {showd && <>
                    <h5>{t("Default configuration")}</h5>
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th className="fw-bolder w-300px">{t("Key")}</th>
                                <th className="fw-bolder">{t("Default")}</th>
                            </tr>
                        </thead>
                        <tbody>
                        {cfg?.map(x => <tr key={x.key}>
                            <td className="fw-bolder">{x.key}</td>
                            <td style={{ wordWrap: "break-word", minWidth: "200px", maxWidth: "200px" }}>{x.value}</td>
                        </tr>)}
                        </tbody>
                    </table>
                </>}
                {!showd && <>
                    <h5>{t("Customized Settings")}</h5>
                    {applicationConfigs.length > 0 && <div className="list-group">
                        {applicationConfigs.map(x => <div className="list-group-item" key={x.type}>
                            <MLScope handleChanged={e => setCfgKey(e)} edit={false} value={x} clickEdit={clickEdit} />
                        </div>)}
                    </div>}
                    {applicationConfigs.length === 0 && <div>{t("No customized config")}</div>}
                </>}
            </>}
            {edit && <>
                
                <div>
                    <h4>{t("Scope 설정")}</h4>
                    <MLScope handleChanged={e => setCfgKey(e)} edit={edit} value={vals}/>
                </div>
                <hr />
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th className="fw-bolder w-300px">{t("Key")}</th>
                            <th className="fw-bolder">{t("Default")}</th>
                            <th className="fw-bolder">{t("Custonmized")}</th>
                        </tr>
                    </thead>
                    <tbody>
                    {cfg?.map(x => <tr key={x.key}>
                        <td className="fw-bolder">{x.key}</td>
                        <td style={{ wordWrap: "break-word", minWidth: "200px", maxWidth: "200px" }}>{x.value}</td>
                        <td>
                            <MLText options={{
                                name : x.key,
                                value : custom?.find(c => c.key === x.key)?.value
                            }} handleChanged={(e) => updateCustom(x.key, e.target.value)} css="p-0" />
                        </td>
                    </tr>)}
                    </tbody>
                </table>
                <div className="fs-3 mt-4 text-end">
                    {/* <div>Customizing Setting</div> */}
                    <div>
                        <MLButton options={{
                            label : t("Cancel"),
                            color : COLORS.DEFAULT,
                            action : () => setEdit(false)
                        }} css="me-1" />
                        {vals && <MLButton options={{
                            label : t("Remove"),
                            color : COLORS.DANGER,
                            action : () => deleteCustom()
                        }} css="me-1" />}
                        <MLButton options={{
                            label : t("Update"),
                            color : COLORS.PRIMARY,
                            action : () => create()
                        }} />
                    </div>
                </div>
            </>}
        </>}
    </div>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
    const applications = state.ApplicationReducer.applications;
    const applicationConfigs = state.ApplicationReducer.applicationConfigs;
	return { user, applications, applicationConfigs };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(CompApplicationConfig);
