import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { Store } from '../../store';
import { connect, useDispatch } from 'react-redux';
import MLCountryCombo from './MLCountryCombo';
import { DEVICE_FILTER, GROUP_FILTER, PROJECT_FILTER, RMBTNS, USERTYPE, USER_FILTER } from '../../constants/RMConstants';
import { FaRegTrashAlt } from "react-icons/fa";
import { MLComboSingle, MLDate, MLNumber, MLText } from './MLInput';
import { ProgramConstant } from '../../redux/reducers';
import { MLButton } from './MLButton';
import { MLSpinner } from './Loader';
import { MLFilterDate } from './MLFilterDate';
import { InfoAction } from '../../redux/actions';
import { delData } from '../../libs/db';
import { useTranslation } from 'react-i18next';

export const CreateScanFilter = (ScanFilter, filter) => {
	console.log(filter);
	if (filter.type === "DATE") {
		if (filter.value.opt === "a") {
			ScanFilter[filter.key] = {
				ComparisonOperator: "GT",
				AttributeValueList: [moment().add(-1 * filter.value.value, filter.value.period).utc().valueOf()]
			}
		}
		if (filter.value.opt === "b") {
			ScanFilter[filter.key] = {
				ComparisonOperator: "BETWEEN",
				AttributeValueList: [
					moment(moment(filter.value.start).format("yyyy-MM-DD") + " 00:00:00").valueOf(),
					moment(moment(filter.value.end).format("yyyy-MM-DD") + " 23:59:59").valueOf()
				]
			}
		}
	} else if (filter.type === "NUMBER") {
		if (filter.value?.indexOf("-") > -1) {
			ScanFilter[filter.key] = {
				ComparisonOperator: filter.value.split("-")[1],
				AttributeValueList: [parseInt(filter.value.split("-")[0])]
			}
		}
	} else {
		ScanFilter[filter.key] = {
			ComparisonOperator: filter.match,
			AttributeValueList: [filter.value]
		}
	}
	return ScanFilter;
}

export const CreateScanFilterExpression = (params, filter) => {
	console.log("params.FilterExpression : : : =====", params);
	const filterExpression = params?.FilterExpression ? params.FilterExpression + " and " : "";
	if (filter.type === "DATE") {
		if (filter.value.opt === "a") { // GT
			params.FilterExpression = filterExpression + `#${filter.key} >= :${filter.key} `;
			params.ExpressionAttributeNames = {
				...params.ExpressionAttributeNames,
				[`#${filter.key}`] : `${filter.key}`
			};
			params.ExpressionAttributeValues = {
				...params.ExpressionAttributeValues,
				[`:${filter.key}`] : moment().add(-1 * filter.value.value, filter.value.period).utc().valueOf()
			}
		}
		if (filter.value.opt === "b") { // between
			params.FilterExpression = filterExpression + `#${filter.key} :start and :end `;
			params.ExpressionAttributeNames = {
				...params.ExpressionAttributeNames,
				[`#${filter.key}`] : `${filter.key}`
			};
			params.ExpressionAttributeValues = {
				...params.ExpressionAttributeValues,
				":start": moment(moment(filter.value.start).format("yyyy-MM-DD") + " 00:00:00").valueOf(),
				":end": moment(moment(filter.value.end).format("yyyy-MM-DD") + " 23:59:59").valueOf()
			};
		}
	} else if (filter.type === "NUMBER") {
		if (filter.value?.indexOf("-") > -1) {
			params.FilterExpression = filterExpression + `#${filter.key} ${getFilterExpression(filter.value.split("-")[1])} :${filter.key}`
			params.ExpressionAttributeNames = {
				...params.ExpressionAttributeNames,
				[`#${filter.key}`] : `${filter.key}`
			}
			params.ExpressionAttributeValues = {
				...params.ExpressionAttributeValues,
				[`:${filter.key}`] : parseInt(filter.value.split("-")[0])
			}
		}
	} else { // EQ | NE | LE | LT | GE | GT | NOT_NULL | NULL | CONTAINS | NOT_CONTAINS | BEGINS_WITH | IN | BETWEEN
		console.log("params.FilterExpression : : : ", params?.FilterExpression);
		params.FilterExpression = filterExpression + getFilterExpression(filter);
		params.ExpressionAttributeNames = {
			...params.ExpressionAttributeNames,
			[`#${filter.key}`] : `${filter.key}`
		};
		params.ExpressionAttributeValues = {
			...params.ExpressionAttributeValues,
			[`:${filter.key}`] : `${filter.value}`
		}
	}
	return params;
}

const getFilterExpression = (filter) => {
	if (filter.match == "EQ")
		return ` #${filter.key} = :${filter.key} `;
	else if (filter.match == "NE")
		return ` #${filter.key} <> :${filter.key} `;
	else if (filter.match == "NULL")
		return ` attribute_not_exists(${filter.key}) or #${filter.key} = :${filter.key} `;
	else if (filter.match == "NOT_NULL")
		return ` attribute_exists(${filter.key}) `;
	else if (filter.match == "CONTAINS")
		return ` contains(#${filter.key}, :${filter.key}) `;
	else if (filter.match == "NOT_CONTAINS")
		return ` not contains(#${filter.key}, :${filter.key}) `;
	else if (filter.match == "BEGINS_WITH")
		return ` begins_with(#${filter.key}, :${filter.key}) `;
	else if (filter == "GT")
		return " >= ";
	else if (filter == "LT")
		return " <= ";
	// https://stackoverflow.com/questions/40283653/how-to-use-in-statement-in-filterexpression-using-array-dynamodb
}

const MLFilter = ({ filters, filterlist, page, show, groups, isLive, callback }) => {

	const dispatch = useDispatch();
	const [disabled, setDisabled] = useState(false);
	const [fs, setFs] = useState([]);
	const [fsItem, setFsItem] = useState({});
	const [filterKeys, setFilterKeys] = useState([]);
	const [nowSet, setNowSet] = useState("");
	const [t] = useTranslation(['translation']);

	useEffect(() => {
		setPageFilter();
	}, [filters]);

	useEffect(() => {
		createFilterKeys();
	}, [page.code]);

	useEffect(() => {
		if (isLive) {
			updatePageFilter();
		}
	}, [fs]);

	const createFilterKeys = () => {
		switch (page.code) {
			case "users":
				setFilterKeys(USER_FILTER);
				break;
			case "projects":
				setFilterKeys(PROJECT_FILTER);
				break;
			case "groups":
				setFilterKeys(GROUP_FILTER);
				break;
			case "devices":
				setFilterKeys(DEVICE_FILTER);
				break;
			default:
				setFilterKeys([])
				break;
		}
	}

	const setPageFilter = () => {
		const pageFilter = filters.find(x => x.page === page.code);
		if (pageFilter) {
			setFsItem(pageFilter?.fsItem?.sk ? pageFilter?.fsItem : {})
			setFs(pageFilter.fs)
		} else {
			setFsItem({})
			setFs([])
		}
	}

	const clearPageFilter = () => {
		setFs([]);
		setActiveIndex(null);
		setSelectedFilterLabel("필터 저장 (선택사항)");
		dispatch({
			type: ProgramConstant.SET_FILTER, item: {
				page: page.code,
				updated: new Date().getTime(),
				fsItem: {},
				fs: []
			}
		})
	}

	const updatePageFilter = () => {
		dispatch({
			type: ProgramConstant.SET_FILTER, item: {
				page: page.code,
				updated: new Date().getTime(),
				fsItem,
				fs: [...fs]
			}
		})
	}

	const updateFs = (e, x) => {
		if (fs.find(f => f.key === x.key)) {
			setFs(fs.map(f => f.key === x.key ? { ...f, value: e.target.value, valuelabel: e.target.valuelabel || "" } : f));
		} else {
			setFs([...fs, {
				...x,
				value: e.target.value,
				opt: e.target.opt,
				valuelabel: e.target.valuelabel || ""
			}]);
		}
	}

	const saveFilter = async () => {
		var n = Store.getState().AuthReducer.usersub;
		console.log("saveFilter : ====", Store.getState().AuthReducer);
		const dt = new Date().getTime();
		await InfoAction.callDB({
			type: "update",
			region: "ap-northeast-2",
			params: {
				TableName: "rayteams-manager-user",
				UpdateExpression: "SET #filter = :filter, #title = :title, #updated = :updated",
				ExpressionAttributeNames: { "#filter": "filter", "#title": "title", "#updated": "updated" },
				ExpressionAttributeValues: { ":filter": [...fs.map(x => JSON.parse(JSON.stringify(x)))], ":title": fsItem.title, ":updated": dt },
				Key: { _id: n, sk: (fsItem.sk ? fsItem.sk : "ft:" + page.code + ":" + dt) }
			}
		});
		dispatch(InfoAction.GetUserFilters(page.code));
	}

	// const removeFilter = async () => {
	// 	var n = Store.getState().AuthReducer.usersub;
	// 	await delData("rayteams-manager-user", { _id: n, sk: fsItem.sk });
	// 	dispatch(InfoAction.GetUserFilters(page.code));
	// 	dispatch({
	// 		type: ProgramConstant.SET_FILTER, item: {
	// 			page: page.code,
	// 			updated: new Date().getTime(),
	// 			fsItem: {},
	// 			fs: []
	// 		}
	// 	})
	// }

	const removeFilter = async (x) => {
		const n = Store.getState().AuthReducer.usersub;
		await delData("rayteams-manager-user", { _id: n, sk: x.sk });
		dispatch(InfoAction.GetUserFilters(page.code));
		dispatch({
			type: ProgramConstant.SET_FILTER, item: {
				page: page.code,
				updated: new Date().getTime(),
				fsItem: {},
				fs: []
			}
		});
	};

	const [activeIndex, setActiveIndex] = useState(null);
	const [selectedFilterLabel, setSelectedFilterLabel] = useState("필터 저장 (선택사항)");
	const goFilterList = (index) => {
		const reversedIndex = filterlist.length - 1 - index;
		const clickedFilter = filterlist[reversedIndex];
		setFsItem(clickedFilter);
		dispatch({
			type: ProgramConstant.SET_FILTER,
			item: {
				page: page.code,
				updated: new Date().getTime(),
				fsItem: clickedFilter,
				fs: clickedFilter.filter || []
			}
		});
		setActiveIndex(reversedIndex);
		setSelectedFilterLabel("선택된 필터");
	}
	useEffect(() => {
		const selectedIndex = filterlist.findIndex(filter => filter.sk === fsItem.sk);
		setActiveIndex(selectedIndex);
	}, [fsItem.sk, filterlist]);

	return <>
		{!show && fs.length > 0 && <div className='border-top py-2 px-3'>
			{fs.map((x, idx) => <span className='badge bg-info me-1' key={idx}>
				{x.label} = {x?.valuelabel || x?.value}
			</span>)}
		</div>}
		{show && <div className='border-top p-4 bg-light'>
			<div className='card'>
				<div className='card-body my-0 py-0'>
					<div className='row'>
						<div className='col-4 pt-3'>
							<label className='form-label fs-6 fw-bold'>{t("필터 항목을 선택하세요.")}</label>
							<div className='list-group mb-3'>
								{filterKeys.map((x, idx) => <div key={idx} className={"list-group-item cursor-pointer " + (x.key === nowSet.key ? "active" : "")} onClick={() => setNowSet(x)}>
									<div className='d-flex justify-content-between'>
										{fs?.find(f => f.key === x.key) && <>
											<div>{t(x.label)}</div>
											<div>
												{fs.find(f => f.key === x.key)?.valuelabel || fs.find(f => f.key === x.key)?.value}
												<MLButton options={{
													label: t("지우기"),
													size: "sm",
													color: "dark",
													action: () => setFs(fs.filter(f => f.key !== x.key))
												}} css="ms-2" />
											</div>
										</>}
										{!fs.find(f => f.key === x.key) && <>
											<div>{t(x.label)}</div>
										</>}
									</div>
								</div>)}
							</div>
							{/* <div className='d-flex justify-content-end'>
								<MLButton options={{ label: "닫기", color: "light", action: () => callback() }} />
								<MLButton css='ms-1' options={{ label: "초기화", color: "light", action: () => clearPageFilter() }} />
								<button className="btn btn-primary ms-1 shadow-none" type="button" disabled="" onClick={() => updatePageFilter()}>
									<MLSpinner title='필터 적용' />
								</button>
							</div> */}
						</div>
						{nowSet && <div className='col-5 py-3 d-flex row align-content-md-between'>
							<div className="mb-4">
								{nowSet.type === "COUNTRY" && <MLCountryCombo options={{
									label: nowSet.label,
									name: nowSet.key,
									value: fs.find(f => f.key === nowSet.key)?.value
								}} handleChanged={(e) => updateFs(e, nowSet)} />}
								{nowSet.type === "GROUP" && <MLComboSingle options={{
									label: nowSet.label,
									name: nowSet.key,
									list: groups.map(g => ({ value: g._id, label: g.name })),
									value: fs.find(f => f.key === nowSet.key)?.value || ""
								}} handleChanged={(e) => updateFs(e, nowSet)} />}
								{nowSet.type === "USERTYPE" && <MLComboSingle options={{
									label: nowSet.label,
									name: nowSet.key,
									list: USERTYPE,
									value: fs.find(f => f.key === nowSet.key)?.value || ""
								}} handleChanged={(e) => updateFs(e, nowSet)} />}
								{nowSet.type === "NUMBER" && <MLNumberCompare
									fitem={nowSet}
									value={fs.find(f => f.key === nowSet.key)?.value}
									callback={(e) => updateFs(e, nowSet)} />}
								{nowSet.type === "DATE" && <MLFilterDate
									fitem={nowSet}
									value={fs.find(f => f.key === nowSet.key)?.value}
									callback={(e) => updateFs(e, nowSet)} />}
								{nowSet.type === "STRING" && <MLText options={{
									label: nowSet.label,
									name: nowSet.key,
									value: fs.find(f => f.key === nowSet.key)?.value || ""
								}} handleChanged={(e) => updateFs(e, nowSet)} />}
							</div>
							<div className='d-flex justify-content-end'>
								<MLButton options={{ label:  t("닫기"), color: "light", action: () => callback() }} />
								<MLButton css='ms-1' options={{ label:  t("초기화"), color: "light", action: () => clearPageFilter() }} />
								<button className="btn btn-primary ms-1 shadow-none" type="button" disabled="" onClick={() => updatePageFilter()}>
									<MLSpinner title={t("필터 적용")} />
								</button>
							</div>

						</div>}
						<div className='col-3 py-3' style={{ borderLeft: "1px solid #dbdade" }}>
							{<div className='mb-3'>
								<label className='form-label'>{selectedFilterLabel}</label>
								<div className='input-group shadow-none'>
									<input type="text" className="form-control text-dark" value={fsItem?.title || ""} onChange={(e) => setFsItem({ ...fsItem, title: e.target.value })} placeholder={t("현재 조건을 저장할 필터명을 입력하세요.")}></input>
									{fsItem.sk && <button className="btn btn-dark" onClick={() => removeFilter(fsItem)}>
										<MLSpinner title={t("삭제")} />
									</button>}
									<button className="btn btn-primary" disabled={fsItem.title && fs.length > 0 ? "" : "disabled"} onClick={() => saveFilter()}>
										<MLSpinner title={fsItem.sk ?  t("업데이트") :  t("필터 저장")} />
									</button>
								</div>
							</div>}
							<label className='form-label'>{t("저장된 필터 리스트")}</label>
							<ul class="list-group" style={{ maxHeight: 420, overflowY: "scroll" }}>
								{filterlist.length === 0 ? (
									<li className="text-center p-5">
										{t("저장된 필터가 없습니다.")}
									</li>
								) : (
									filterlist.slice().reverse().map((x, index) => (
										<li key={index}
											className={`list-group-item d-flex justify-content-between align-items-center ${activeIndex === filterlist.length - 1 - index ? 'active' : ''}`}
											style={{ cursor: 'pointer' }}
											onClick={() => goFilterList(index)}>
											<div>{x.title}</div>
											<button className="btn btn-dark btn-sm" onClick={() => removeFilter(x)}>
												{t("삭제")}
											</button>
										</li>
									))
								)}
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>}
	</>
}

const mapState = (state) => {
	const filters = state.ProgramReducer.filters;
	const filterlist = state.ProgramReducer.filterlist;
	const groups = state.AuthReducer.groups;
	return { filters, filterlist, groups };
};

export default connect(mapState, null)(MLFilter);