import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import RMProjectDashBoard from '../../components/project/RMProjectDashBoard';

const ProjectsDashBoardPage = ({ region, adminUsers, admintype, groups }) => {
    
    useEffect(() => {
        //getData();
    }, [region]);

    return (
        <>
    	<div>
            <RMProjectDashBoard groups={groups}></RMProjectDashBoard>
    	</div>
        </>
    )
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
    const admintype = state.AuthReducer.admintype;
    const adminUsers = state.AuthReducer.adminUsers;
	const groups =  state.AuthReducer.groups;
    const region =  state.AuthReducer.region;
    
	return { user, region, adminUsers, groups, admintype };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, null)(ProjectsDashBoardPage);