import axios from "axios";
import { connect, useDispatch } from 'react-redux';
import { useEffect, useState } from "react";
import { BUTTONS, COLORS, INPUTS, SPAN } from '../../constants/FromInputs';
import { MLButton } from '../../components/common/MLButton';
import { v4 } from 'uuid';
import { UploadS3 } from "../../libs/s3";
import { MLText } from "../../components/common/MLInput";
import { MLForm } from "../../components/common/MLForm";
import MLTable from "../../components/common/MLTable";
import { ProgramConstant } from "../../redux/reducers";
import { InfoAction } from "../../redux/actions";
import { AWSGetUserById } from "../../libs/aws";
import { history } from "../../history";
import { AppConfig, JWT } from "../../config";
import MLModal from "../../components/common/MLModal";
import { useTranslation } from 'react-i18next';
import { RTAudit } from '../../libs/audit';
const VIEW_TYPE = {
    DEFAULT: 'default',
    LANGKEYS: 'lang_keys',
    TRANS: 'trans',
    TRANSUSER : 'transuser'
};

const getEnv = () => {
    if(location.href.indexOf("localhost") > -1 || location.href.indexOf("127.0.0.1") > -1){
        return "dev-local";
    }else if(location.href.indexOf("raydevelop") > -1){
        return "development";
    }
    return "production";
}

const MailingPage = ({ }) => {
    const dispatch = useDispatch();
    const [t] = useTranslation(['translation']);
    const [data, setData] = useState([]);
    const [rec, setRec] = useState([]);
    const [modal, setModal] = useState({
        show : false,
        description : '',
    });
    const [shownew, setShowNew] = useState(false);
    const [newitem, setNewItem] = useState({
        key : "",
        value : "",
    });
    const env = (location.href.indexOf("localhost") > -1 || location.href.indexOf(".raydevelop.com") > -1) ? "development" : "production";
    const url = process.env.REACT_APP_COMPANY === 'graphy' ? 'https://api-manager-user.' + AppConfig().domain + '/sendemail' : ( env === 'production' ?
        'https://api-manager-user.rayteams.com/sendemail' : 
        'https://api-manager-user.raydevelop.com/sendemail' )
        
    useEffect(() => {
		dispatch({ type : ProgramConstant.SET_PAGE, page : { 
			code : "mailing", 
			view : "summary", 
			title : "Mailing", 
			small : t("사용자에게 Mailing 발송"), 
			options : [
			]
		}})
        getData();
        getReceivers();
    }, []);

    const getData = async () => {
        try{
            const ret = await InfoAction.callDB({ type : "scan", region : "ap-northeast-2", params : {
                TableName: "rayteams-info",
                FilterExpression: "#sk=:sk",
                ExpressionAttributeNames: { "#sk": "sk" },
                ExpressionAttributeValues: { ":sk": 'dm' },
            }});
            setData(ret.sort((a, b) => a.sent > b.sent ? -1 : 1) || []);
        }catch{
            setData([]);
        }
    }

    const getReceivers = async () => {
        try{
            const ret = await InfoAction.callDB({ type : "scan", region : "ap-northeast-2", params : {
                TableName: "rayteams-user",
                FilterExpression: "#marketingAgree=:marketingAgree",
                ExpressionAttributeNames: { "#marketingAgree": "marketingAgree" },
                ExpressionAttributeValues: { ":marketingAgree": true },
            }});
            setRec(ret);
        }catch(e){
            console.log(e);
            setRec([]);
        }
    }


    const sendemail = async () => {

        let received = 0;
        for(const r of rec){
            const u = await AWSGetUserById(r.region, r._id);
            const uEmail = u.UserAttributes.find(x => x.Name === 'custom:uEmail');
            const email = u.UserAttributes.find(x => x.Name === 'email');
            console.log(uEmail);
            console.log(email);
            if(uEmail){
                const sentRet = await axios.post(url, { subject : newitem.subject, content : newitem.content, toEmail : uEmail.Value }, JWT())
                received++;
            }else{
                const sentRet2 = await axios.post(url, { subject : newitem.subject, content : newitem.content, toEmail : email.Value }, JWT())
                received++;
            }
        }
        await InfoAction.callDB({ type : "update", region : "ap-northeast-2", params : {
            TableName: "rayteams-info",
            UpdateExpression: 'SET #subject = :subject, #content = :content, #sent = :sent, #received = :received',
            ExpressionAttributeNames: { 
                '#subject' : 'subject', 
                '#received' : 'received', 
                '#content' : 'content', 
                '#sent' : 'sent', 
            },
            ExpressionAttributeValues: { 
                ':subject' : newitem.subject, 
                ':received' : received, 
                ':content' : newitem.content, 
                ':sent' : new Date().getTime(), 
            },
            Key: { _id : v4(), sk: 'dm' }
        }});
        getData();
        setShowNew(false);
       
    }

    const sendtest = async () => {
        if(!newitem.subject || !newitem.content || !newitem.testemail){
            setErr("모든 값을 입력해야합니다.");
            return;
        }
        await axios.post(url, { subject : newitem.subject, content : newitem.content, toEmail : newitem.testemail }, JWT())
        alert('Sent!');
    }

    const send = async () => {
        if(!newitem.subject || !newitem.content){
            setErr("모든 값을 입력해야합니다.");
            return;
        }
        await sendemail();
        RTAudit({ lvl : 8, msg : 'Sent email(DM), Recipients : ' + rec.length + '' }, JSON.stringify({ subject : newitem.subject }));
    }

    const controls = [
		{
			type : INPUTS.EMAIL,
			options : {
				label : t('Test email'),
				name : 'testemail',
				placeholder : t('Enter test address'),
			}
		},
		{
			type : INPUTS.TEXT,
			options : {
				label : t('Subject'),
				name : 'subject',
				required : true
			}
		},
		{
			type : INPUTS.HTMLEDITOR,
			options : {
				label : t('Content'),
				name : 'content',
				required : true
			}
		},
		{
			type : SPAN.VERTICAL,
			options : {
				size : 20,
			}
		},
		{
			type : BUTTONS.BUTTONS, 
			options : { },
			buttons : [{
				type : BUTTONS.NORMALBUTTON, 
				options : { 
					label : t('Send'), 
					color : COLORS.PRIMARY,
					needValid : true,
                    action : () => send()
				}
			},{
				type : BUTTONS.NORMALBUTTON, 
				options : { 
					label : t('Send Test'), 
					color : COLORS.SUCCESS,
					needValid : true,
                    action : () => sendtest()
				}
			},
            {
				type : BUTTONS.NORMALBUTTON, 
				options : { 
					label : t('Cancel'), 
					color : COLORS.SECONDARY,
                    action : () => setShowNew(!shownew)
				}
			}]
		}
    ]

	const headers = [
		{ value : "subject", label : t("Subject"), css : "text-start", type : 'TEXTBUTTON', clicked : (item) => { setModal({
            show : true,
            body : <div style={{overflowWrap: "anywhere"}}><div dangerouslySetInnerHTML={ {__html: item.content} }></div></div>
        }) } },
                    
        { value : "received", label : t("Recipient"), css : "text-start" },
		{ value : "sent", label : t("Sent"), css : "text-start", type : "DATE" },
        /*
        { label : "", code : "ptn-delsn", btncss : "btn-danger", css : "text-center", type : "BUTTON2", btnClick : async (item) => {
			await delKey(item);
		} },
        */
	]

    return <div className='p-4'>
        <div className="d-flex justify-content-between mb-2">
            <h3>
            </h3>
            <div className="card-toolbar">
                {!shownew && <MLButton options={{
                    
                    code: "mail-sendnew",
                    label: t("Send new email"),
                    color : COLORS.PRIMARY,
                    action : () => history.push('/MailingSendPage')
                }} />}
            </div>
        </div>
        {shownew && <div>
            <div>{t("예상 수신 목록")} : {rec.length}</div>
            <MLForm title={'Send Email'} controls={controls} data={newitem} onChanged={(e) => { setNewItem(e) }} />
        </div>}
        {!shownew && <MLTable
            headers={headers} 
            items={data} />}
        <MLModal modalData={modal} footerbuttons={[{
            options : {
                label : t('Close'),
                color : 'light',
                action : () => setModal({...modal, show : false})
            }
        } ]} />
    </div>
}

export default MailingPage;
