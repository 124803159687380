import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { WFAction } from '../../redux/actions';
import { COLORS } from '../../constants/FromInputs';
import { MLForm } from '../../components/common/MLForm';
import { INPUTS, BUTTONS } from '../../constants/FromInputs';
import { history } from '../../history';
import { WFConstant } from '../../redux/reducers';

const MLWFAdd = ({ _id, Create, Update, item, callback, callbackList, auth, save, workflow,
	itemstatus, site, readonly=false, title }) => {

	const [t] = useTranslation(['translation']);
	const dispatch = useDispatch();
	const [data, setData] = useState({});

	useEffect(() => {
		if(!itemstatus)
			return;
		if(itemstatus === WFConstant.UPDATED || itemstatus === WFConstant.CREATED){
			dispatch({ type : WFConstant.STATUSCLEAR });
			if(site === "admin"){
				history.push('/Admin/AdminWFInfo/' + item._id);
			}else{
				callback && callback(item);
			}
		}
	},[itemstatus]);

	useEffect(() => {
		if(item._id)
			setData(item);
	},[item]);

	useEffect(() => {
		if(workflow?._id)
			setData(workflow);
	},[workflow]);

	const goList = () => {
		if (_id) {
			if(site === "admin"){
				history.push('/Admin/AdminWFList');
			}else{
				callback && callback();
			}
		} else {
			if (callbackList) {
				callbackList()
			} else {
				history.goBack()
			}
		}
		
	}

	const controls = [
		{ 
			type : INPUTS.TEXT, 
			options : { 
				label : t("name"),
				name : "name",
				required : true
			},
		},
		{ 
			type : INPUTS.TEXTAREA, 
			options : { 
				label : t("description"),
				name : "description"
			},
		},
		{
			type : BUTTONS.BUTTONS, 
			options : { },
			buttons : [{
				type : BUTTONS.FORMSUBMIT, 
				options : { 
					label : _id ? t('update') : t('save'), 
					color : COLORS.PRIMARY,
					needValid : true
				} 
			},
			{
				type : BUTTONS.NORMALBUTTON, 
				options : { 
					label : _id ? t('cancel') : t('list'), 
					action : goList
				} 
			}]
		}
	]

	return <MLForm 
			css='mb-3'
			title={title} 
			readonly={readonly} 
			controls={controls} 
			data={_id ? data : (site === "admin" ? {} : { orgId : "orgId" })} 
			updateKey={_id} 
			submit={_id ? Update : Create} />
}

const mapState = (state) => {
	const auth = state.AuthReducer.user;
	const item = state.WFReducer.item;
	const itemstatus = state.WFReducer.status;
	const site = state.ProgramReducer.site;
	return { item, itemstatus, site, auth };
};

const mapDispatch = dispatch => ({
	Get : (_id) => dispatch(WFAction.Get(_id)),
	Create : (data) => dispatch(WFAction.Create(data)),
	Update : (data) => dispatch(WFAction.Update(data))
})

export default connect(mapState, mapDispatch)(MLWFAdd);