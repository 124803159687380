import { connect, useDispatch } from 'react-redux';
import RMProductDetailList from '../components/product/RMProductDetailList';

const ProductInfoPage = ({ user, product }) => {
  	return (
    	<div>
			<div className="header align-items-stretch d-block" style={{position: "initial"}}>
                <div className="d-flex w-100 justify-content-between p-5 align-items-center">
                    <div className="d-flex align-items-center text-dark fw-bolder fs-2 my-1"></div>
                    <div className="d-flex align-items-center"></div>
                </div>
            </div>
			<div className='post p-7'>
			<RMProductDetailList />
			</div>
    	</div>
  	);
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const region =  state.AuthReducer.region;
    const product = state.ProductReducer.product;
	return { region, user, product };
};

const mapDispatch = (dispatch) => ({
});

export default connect(mapState, null)(ProductInfoPage);