import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { callDB } from '../../libs/db';
import { MLButton } from '../common/MLButton';
import { COLORS } from '../../constants/FromInputs';
import { history } from '../../history';
import RMMenuComponent from './RMMenuComponent';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
const RMContentInfoPaenl = ({ board, usercontents, user, hidebutton = false, small = false  }) => {

	const { _bid, _cid, _sk } = useParams();
	const [content, setContent] = useState({});
	const [t] = useTranslation(['translation']);
	useEffect(() => {
        getData();
	}, [_cid]);

    const getData = async () => {
		try{
			const ret = await callDB({
				type : "query",
				region : "ap-northeast-2",
				params : {
					TableName: "rayteams-manager-contents",
					KeyConditionExpression: "#_id = :_id and #sk = :sk",
					ExpressionAttributeNames: { "#_id": "_id", "#sk": "sk" },
					ExpressionAttributeValues: { ":_id" : _cid, ":sk": "info" }
				},
				filter : {}
			});
			if(ret.length > 0){
				setContent(ret[0]);
			}else{
				setContent({});
			}
		}catch(err){
			console.log(err);
		}
    }
	
	const permHQ = () => {
		if(user?.email.indexOf('@rayteams.com'))
			return true;

		return false;
	}

	return <div className='card w-100 mt-4'>
		<div className='card-body'>
			<div className='d-flex justify-content-between'>
				<div>
					<label className='small fw-bolder'>{(content.type === 'MANAGER_NOTICE' || content.type === 'MANUALDOC') ? 'Subject' : 'Alias'}</label>
					<div className='fs-4 mb-2'>{content?.title} {content?.israyteams && <>
							<span className="badge bg-success">RAYTeams Published</span>
						</>} </div>
					<label className='small fw-bolder'>Menu</label>
					<div className='fs-4 mb-2'><RMMenuComponent menusk={content?.menu} _bid={content?.bid} /></div>
				</div>
				<div className='text-end'>
					{content.type !== 'MANAGER_NOTICE' && content.type !== 'MANUALDOC' && <div className='my-2'>
						<MLButton options={{
							code: "cm-editalias",
							label : t('Edit Alias'),
							color : COLORS.INFO,
							size : 'md',
							action : () =>  history.push('/UserContentAdd/' + board._id + "/" + _cid + "/info"),
						}} css="ms-1" />
						<MLButton options={{
							code: "cm-adduc",
							label : t('Add User Content'),
							color : COLORS.PRIMARY,
							size : 'md',
							action : () =>  history.push('/UserContentAdd/' + board._id + "/" + _cid),
						}} css="ms-1" />
					</div>}
					{(content.type === 'MANAGER_NOTICE' || content.type === 'MANUALDOC') && permHQ() && <div className='my-2'>
						<MLButton options={{
							label : '편집',
							color : COLORS.INFO,
							size : 'md',
							action : () =>  history.push('/UserContentAdd/' + board._id + "/" + _cid + "/info"),
						}} css="ms-1" />
					</div>}
				</div>
			</div>
		</div>
		{content?.content && <div className='p-4' dangerouslySetInnerHTML={{ __html: content.content }}></div>}
	</div>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const region =  state.AuthReducer.region;
	const board =  state.BoardReducer.board;
	const usercontents =  state.BoardReducer.usercontents;
	return { region, user, board, usercontents };
};

const mapDispatch = dispatch => ({
})

export default connect(mapState, mapDispatch)(RMContentInfoPaenl);