import axios from 'axios';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import { connect, useDispatch } from 'react-redux';
import { COUNTRIES } from '../../constants/Country';
import { MLText } from '../../components/common/MLInput';
import { MLButton, MLRefreshButton, MLChartButton } from '../../components/common/MLButton';
import { COLORS } from '../../constants/FromInputs';
import { MLPanelLoading } from '../../components/common/MLPanel';
import MLTable from '../../components/common/MLTable';
import { AuthConstant, getAPI, getHeader } from '../../redux/reducers/AuthReducer';
import { fetchData } from '../../libs/db';
import WidgetBarChartAppUsage from '../../components/widgets/dashboard/WidgetBarChartAppUsage';
import RMWidgetBarChart from '../../components/widgets/RMWidgetBarChart';
import RMWidgetLineChart from '../../components/widgets/RMWidgetLineChart';
import { TbLayoutDashboard } from 'react-icons/tb';
import { ProgramConstant } from '../../redux/reducers';
import { RMIconList, RMIconChart } from '../../components/icon/MLIcon';
import queryString from 'query-string';
import RayteamsStatCard from '../../components/rayteams/RayteamsStatCard';
import RayteamsChart from '../../components/rayteams/RayteamsChart';
import { history } from '../../history';
import { useTranslation } from 'react-i18next';

const RayteamsPage = ({ groups, region, regionusers, allrayteams, pagestat, page, loginuser }) => {
    const dispatch = useDispatch();
    const [t] = useTranslation(['translation']);
	const [more, setMore] = useState(true);
	const [search, setSearch] = useState(queryString.parseUrl(location.href).query?.rip || "");
	const [filter, setFilter] = useState(pagestat?.filter || [
        { code : "countryCode", value : "", list : [] },
        { code : "app", value : "", list : [], array : true },
        { code : "version", value : "", list : [], hide: true }
    ]);
	const [items, setItems] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		dispatch({ type : ProgramConstant.SET_PAGE, page : { 
			code : "rayteams", 
			view : "summary", 
			title : "RAYTeams Client", 
			small : t("RAYTeams Client 관련 정보"), 
			options : [
			]
		}})
	}, []);

	useEffect(() => {
		if(loginuser?.menurole){
			if(!loginuser?.menurole?.find(f => f === page.code)){
				history.push("/");
			}else{
                getData();
            }
		}
	}, [loginuser]);

	useEffect(() => {
        getData();
	}, []);

	useEffect(() => {
        if(allrayteams)
            genFilter();
	}, [allrayteams]);

	useEffect(() => {
		genData();
        dispatch({ type: ProgramConstant.PAGESTAT, item: { rayteamslist : { search, filter, 
            order : pagestat.order, 
            desc : pagestat.desc 
         }} });
	}, [search, allrayteams, filter, pagestat.order, pagestat.desc]);

	const goLink = (to, title) => {
		dispatch({ type : ProgramConstant.GO_LINK, to, title });
	}

    const headers = [
		{ value : "remoteIP", label : t("IP"), css : "text-start", css : "w-200px" },
        { value : "localIP", label : t("Local IP"), css : "text-start" },
        { value : "version", label : t("Ver."), css : "text-center", css : "w-150px" },
        { value : "pver", label : t("Apps"), type : "APPARRAY", css : "text-start" },
        //{ value : "groupId", label : "Owner", css : "text-center", type : "CLINIC" },
        { value : "groupId", label : t("Owner"), css : "text-center", type : "RAYTEAMSOWNER" },
        { value : "updated", label : t("Updated"), css : "text-center", type : "DATESHORT", style : { width : 140 } },
	]

	// country 선택
	const getData = async () => {
		setLoading(true);
		const ScanFilter = {
			sk: {
				ComparisonOperator: "EQ",
				AttributeValueList: ["info"],
			},
		}
		try {
			const ret = await fetchData("rayteams-info", ScanFilter);
            console.log(groups);
			var list = [];
			ret.Items.map(x => {
				list.push({
					...x, 
                    app : x.pver?.map(x => x.name) || [],
                    apps : x.pver?.map(x => x.name + "-" + x.version) || [],
                    created : x.updated,
                    //groupId : x.group?._id || "",
                    groupId : x.group?._id + ":" + x.group?.name + ":" + x.group?.countryCode,
                    countryCode : x.group?.countryCode || "UNKNOWN"
				})
			});
			dispatch({ type: ProgramConstant.ALLRAYTEAMS, items : list });
		} catch (e) {
			dispatch({ type: ProgramConstant.ALLRAYTEAMS, items : [] });
			console.log(e);
		}
		setLoading(false);
	}

    const fLabel = (code, value) => {
        if(value === true || value === false){
            return value === true ? "Active" : "Expired";
        }else if(code === "countryCode"){
            return COUNTRIES.find(x => x.countryCode === value)?.name || "UNKNOWN";
        }
        return value;
    }

	const genFilter = () => {
        setFilter( filter.map(x => {
            let list = [];
            if(x.array){
                allrayteams.map(m => m[x.code]?.map(n => { 
                    if(!list.includes(n))
                        list.push(n) 
                }));
            }else{
                list = [...new Set(allrayteams.map(m => m[x.code]))];
            }
            console.log(list);
            return {...x, list : [{ value : "", label : "ALL" }, ...list.map(n => ({ 
                value : n, 
                label : fLabel(x.code, n)
            }))] }
        }));
	}

    const filterCheck = (item) => {
        var tf = true;
        filter.map(x => {
            if(tf && x.value){
                if(x.array){
                    tf = (item[x.code].includes(x.value));
                }else{
                    tf = (item[x.code] === x.value || x.value === "");
                }
            }
        });
        return tf;
    }

	const genData = () => {
		setItems(allrayteams
            .filter(x => search ? (x.remoteIP?.indexOf(search) > -1) : true)
            .filter(x => filterCheck(x))
			.sort((a, b) => pagestat.desc === "desc" ? (a[pagestat.order] > b[pagestat.order] ? -1 : 1) : (b[pagestat.order] > a[pagestat.order] ? -1 : 1))
        )
	}

	const handleFilter = (e, item) => {
        setFilter( filter.map(x => x.code === item.code ? {...x, value : e.value } : x));
        if (item.code == "app"){
            let list = [];
            list = allrayteams.filter(m => m.app.includes(e.value)).map(n => ({ value: n.version, label : fLabel("version", n.version)}));
            list = [...new Set(list.map(x => x["value"]))];
            list = [{value : "", label: "ALL"}, ...list.map(n => ({value: n, label: fLabel("version", n)}))];
            setFilter(filter.map(x => x.code === "version" ? {...x, value: "", list : list, hide: item.code === "app" && e.value != "" && false} : (x.code === item.code ? {...x, value : e.value} : x)));
        }
	}

	const headerClick = () => {
		dispatch({ type: ProgramConstant.PAGESTAT, item: { rayteamslist : { search, filter, 
            order : pagestat.order, 
            desc : (pagestat.desc === "desc" ? "asc" : "desc") }} });
	}

  	return <div className='p-4'>
		<div className='mb-4'>
            <RayteamsStatCard items={allrayteams}/>
		</div>
		<div className="card">
			<div className="card-header d-flex justify-content-between align-items-center">
				<div className="card-label fw-bold text-gray-800 fs-5">{items.length}</div>
				<div className="d-flex">
                    {filter.map((x, idx) => !x.hide && <Select
                        key={"f-" + x.code + idx}
                        onChange={(e) => handleFilter(e, x)}
                        value={x.list.filter(y => y.value === x.value)}
                        options={x.list}
                        data-dropdown-css-className="w-200px"
                        className="form-select-transparent me-2 w-150px" />)}
                    <MLText options={{
                           placeholder : t("검색어 입력"),
                            size : "sm",
                            value : search,
                        }} handleChanged={(e) => setSearch(e.target.value)} />
                    <div className="col-sm-auto ms-6 pt-1 btn-tool-view" style={{display: "none"}}>
                        <a className="btn btn-bg-secondary btn-sm mb-1 btn-view-select active" title="View as a list">
                            <RMIconList></RMIconList>
                        </a>
                        <a className="btn btn-bg-secondary btn-sm mb-1 btn-view-select" title="View as a dashboard">
                            <RMIconChart onClick={() => goLink("/UsersDashBoardPage", "Users")}></RMIconChart>
                        </a>
                    </div>
				</div>
			</div>
            {!loading && <MLTable 
                headers={headers} 
                items={items} 
                headerClick={headerClick}
                order={pagestat?.order || "created"} 
                desc={pagestat?.desc || "desc"} 
                noheader={true} />}
            {loading && <MLPanelLoading />}
		</div>
    </div>
}

const mapState = (state) => {
	const groups =  state.AuthReducer.groups;
	const region = state.AuthReducer.region;
	const regionusers = state.AuthReducer.regionusers;
    const allrayteams = state.ProgramReducer.allrayteams;
	const pagestat = state.ProgramReducer.pagestat["rayteamslist"] || {};
	const loginuser = state.AuthReducer.loginuser;
	const page = state.ProgramReducer.page;

	return { groups, region, regionusers, allrayteams, pagestat, page, loginuser };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, null)(RayteamsPage);
