import axios from "axios";
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { useEffect, useState } from "react";
import ServiceConfigEmailSetting from "./ServiceConfigEmailSettingy";

const VIEW_TYPE = {
    DEFAULT: 'default',
    LANGKEYS: 'lang_keys',
    TRANS: 'trans',
    TRANSUSER : 'transuser'
};

const getEnv = () => {
    if(location.href.indexOf("localhost") > -1 || location.href.indexOf("127.0.0.1") > -1){
        return "dev-local";
    }else if(location.href.indexOf("raydevelop") > -1){
        return "development";
    }
    return "production";
}

const ServiceConfigEmail = ({}) => {
	const [t] = useTranslation(['translation']);
	const dispatch = useDispatch();

    useEffect(() => {
    }, []);

    return <div className='p-4'>
        <div className="mb-3">
            <ServiceConfigEmailSetting />
        </div>
    </div>
}

export default ServiceConfigEmail;
