import { useEffect, useState } from 'react';
import { history } from '../../history';
import { connect, useDispatch } from 'react-redux';
import Clinic from '../../components/partner/clinic';
import MLTable from '../../components/common/MLTable';
import { MLText } from '../../components/common/MLInput';
import { Modal } from 'react-bootstrap';
import { MLButton } from '../../components/common/MLButton';
import { fetchData, delpkAllData, insertData, pkData, idData, putData, delData, updateData } from '../../libs/db';
import MLModal from '../common/MLModal';
import { COLORS } from '../../constants/FromInputs';
import { MLComboSingle, MLTextArea } from '../common/MLInput';

const Devices = ({ user, allgroups, allpartners, alldevices, partner, callbackUpdate }) => {

	const dispatch = useDispatch();
	const [ items, setItems ] = useState([]);
	const [ list, setList ] = useState([]);
	const [ process, setProcess ] = useState(false);
	const [ err, setErr ] = useState("");
	const [ addD, setAddD ] = useState({});
	const [ modalData, setModalData ] = useState({
		show : false,
		title : "Add Devices",
		body : <div>
		</div>,
		footerbuttons : [
			{ label : "Cancel", size : "sm", color : COLORS.SECONDARY, action : () => setModalData({...modalData, show : false})},
			{ label : "Add Devices", size : "sm", color : COLORS.PRIMARY, action : () => save()}
		]
	});
	const [ search, setSearch ] = useState("");
	
	useEffect(() => {
		//getitems();
	},[])
	
	const nFormat = (s) => {
		return s.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	useEffect(() => {
		if(search){
			setList(makeItems(alldevices.filter(x => x?.sn.indexOf(search) > -1)));
		}else{
			setList(makeItems(alldevices));
		}
	},[search, alldevices])

	const deviceLab = (device) => {
		const ret = [];
		allgroups.filter(g => device.labs?.includes(g._id)).map(x => {
			ret.push(<span onClick={() => history.push("/GroupInfo/" + x._id)} className='me-1 badge badge-success cursor-pointer'>{x.name}</span>);
		});
        if(ret.length === 0)
            ret.push(<div className="font-italic text-gray-500">All Labs</div>);
		return ret;
	}

	const makeItems = (showitems) => {
		if(!showitems)
			return;
		return showitems.map(x => {
			return {...x, 
				partnername : <span className='me-1 badge badge-success'>
					{allpartners?.find(g => g._id === x.partnerId)?.name}
				</span>, 
				labname : deviceLab(x), 
				clinicname : <Clinic cid={x.ownerId} />, 
			};
		});
	}

	const save = async () => {
		const list = addD.sns.split(/\n/);
		for(let i = 0; i < list.length; i++) {		// for 문
			if(list[i].trim()){
                const ScanFilter = {
                    sn: {
                        ComparisonOperator: "EQ",
                        AttributeValueList: [list[i].trim()],
                    },
                    partnerKey: {
                        ComparisonOperator: "EQ",
                        AttributeValueList: [partner.partnerKey],
                    },
                }
                const d = await fetchData("rayteams-device", ScanFilter);
                if(d.Count > 0)
                    continue;
                await updateData("rayteams-device", {
					sn : list[i].trim(),
					sk : "info"
                },{
					labs : [],
					isactive : true,
					issharable : true,
					DeviceName : addD.devicetype,
					partnerKey : partner.partnerKey,
					partnerId : partner._id,
					registered : new Date().getTime(),
					updated : new Date().getTime(),
				});
			}
		}
		setModalData({
			show : false,
			title : "Add Devices",
		});
		callbackUpdate && callbackUpdate();
	}

	const showAdd = () => {
		setAddD({
			devicetype : "",
			sns : ""
		})
		setModalData({
			show : true,
			title : "Add Devices",
		});
	}

	const delClick = async (item) => {
        //await delpkAllData("rayteams-device", "sn", item.sn);
        const ScanFilter = {
            sn: {
                ComparisonOperator: "EQ",
                AttributeValueList: [item.sn],
            },
            sk: {
                ComparisonOperator: "EQ",
                AttributeValueList: ["info"],
            },
        }
        const d = await fetchData("rayteams-device", ScanFilter);
        if(d.Count > 0){
            const delItem = d.Items[0];
            delete delItem.labs;
            delete delItem.isactive;
            delete delItem.issharable;
            delete delItem.partnerKey;
            delete delItem.partnerId;
            await putData("rayteams-device", {...delItem });
        }
		callbackUpdate && callbackUpdate();
	}

	const headers = [
		{ link : "sn", value : "sn", label : "SN", css : "text-start", linkpath : "/device" },
        { value : "DeviceName", label : "DeviceName", css : "text-start" },
        { value : "clinicname", label : "Clinic", css : "text-start" },
        //{ value : "partnername", label : "Partner", css : "text-start" },
        { value : "labname", label : "Lab(s)", css : "text-start" },
        //{ value : "isactive", label : "Active", css : "text-center", type : "TFVALUE" },
        { value : "issharable", label : "Sharable", css : "text-center", type : "TFVALUE" },
        { value : "activated", label : "Activated", css : "text-center", type : "DATESHORT", nodatamsg : "Not activated" },
        { value : "registered", label : "Registered", css : "text-center", type : "DATESHORT" },
        { label : "", code : "ptn-delsn", btncss : "btn-danger", css : "text-center", type : "BUTTON2", btnClick : async (item) => {
			await delClick(item);
		} },
	]
	
  	return <div className="p-4">
		<div className="d-flex justify-content-end align-items-center">
			<div className="d-flex">
                <MLButton options={{
                    code : "ptn-adddevice",
                    label : "Add Devices",
                    size : "sm",
                    action: () => showAdd()
                }} css="me-2" />
				<MLText options={{
					placeholder : "검색어 입력",
					size : "sm",
					value : search,
				}} handleChanged={(e) => setSearch(e.target.value)} css="mb-0" />
			</div>
		</div>
		<MLTable 
			rowkey={"sn"}
			noheader={true} 
			headers={headers} 
			items={list.sort((a, b) => a.sn > b.sn ? 1 : -1)} 
			loading={process} />
		<Modal show={modalData.show} size={"lg"} style={{ backgroundColor: "#42476c !important"}}>
			<Modal.Header className='modal-header header-bg'>
				<h2>Add Devices</h2>
			</Modal.Header>
			<Modal.Body className='flex-fill pt-0 formcard pt-6'>
				<div>
					<div className='fs-6 my-2 fw-bolder'>Select device type</div>
					<MLComboSingle options={{
						value : addD.devicetype,
						name : "devicetype",
						list : [
							{ value : "RAYFace", label : "RAYFace" },
							{ value : "RAYiOS", label : "RAYiOS" },
						]
					}} handleChanged={(e) => setAddD({...addD, devicetype : e.target.value})}/>
					<div className='fs-6 mt-4 mb-2'>
                        <strong>Add Serial No.(한줄에 1개의 SN 작성)</strong><br />
                        중복 검사하여 신규의 장비만 등록합니다.
                    </div>
					<div>
						<MLTextArea options={{
							name : "sns",
							value : addD.sns,
							rows : 10,
						}} handleChanged={(e) => setAddD({...addD, sns : e.target.value})}/>
					</div>
				</div>
				<div className='mt-4 text-end'>
					<MLButton
						options={{ label : "Cancel", size : "md", color : COLORS.DEFAULT, action : () => setModalData({...modalData, show : false})}}
						css={'me-1'} />
					<MLButton
						valid={addD.devicetype && addD.sns}
						options={{ label : "Add Devices", size : "md", needValid : true,  color : COLORS.PRIMARY, action : () => save()}}
						css={'me-1'} />
				</div>
			</Modal.Body>
		</Modal>
	</div>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;

	return { user };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(Devices);
