import axios from 'axios';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import RMBoardInfoPaenl from './RMBoardInfoPaenl';
import RMModalAddBoard from './RMModalAddBoard';
import { MLButton } from '../common/MLButton';
import MLTable from '../common/MLTable';
import { COLORS, SETTING } from '../../constants/FromInputs';
import { BoardAction } from '../../redux/actions';
import { AuthConstant, BoardConstant } from '../../redux/reducers';
import { history } from '../../history';
import { JWT } from '../../config';
import { MLPanelLoading } from '../common/MLPanel';
import { callDB } from '../../libs/db';
import RMBoardMenuComponents from './RMBoardMenuComponents';

const RMBoardIContentList = ({ board, content, usercontents, tp }) => {

	const dispatch = useDispatch();
	const { _cid } = useParams();
	const [loading, setLoading] = useState(true);
	const [menu, setMenu] = useState({ });

	useEffect(() => {
        getData();
	}, [_cid]);

    const getData = async () => {
		setLoading(true);
		try{
			const ret = await callDB({
				type : "query",
				region : "ap-northeast-2",
				params : {
					TableName: "rayteams-manager-contents",
					KeyConditionExpression: "#_id = :_id",
					ExpressionAttributeNames: { "#_id": "_id" },
					ExpressionAttributeValues: { ":_id" : _cid }
				},
				filter : {}
			});
            const list = ret.map(x => ({...x,
				read : x?.read || 0,
				lang : x.sk === "info" ? "info" : x.sk.replace("lang:", ""),
                title : x?.isDel > 0 ? <strike>삭제된 글입니다.</strike> : x.title,
                content : x?.isDel > 0 ? <strike>삭제된 글입니다.</strike> : x.content,
            }));
			console.log("RMUserContentList list : ======", list);
			dispatch({ type: BoardConstant.GET_CONTENT, item: list });
		}catch(err){
			console.log(err);
		}
		setLoading(false);
    }

    const headers = [
        { value : "lang", label : "Language", style : { width : 200 }, type : "LANGUAGE" },
        { link : ["_id", "lang"], linkpath : "/UserContent", value : "title", label : "Title", css : "text-start" },
        { value : "read", label : "Read", type : "NUMBER", css : "text-end", style : { width : 150 } },
		//{ value : "israyteams", label: "RAYTeams", type : "TFVALUE", css : "text-end", style : { width : 100} },
        { value : "updator", label : "Author", type : "MUSER", css : "text-end", style : { width : 250 }, noemail : true },
        { value : "updated", label : "Updated", type : "DATE", css : "text-start", style : { width : 200 } },
    ]

  	return <div className='mt-4'>
		{!loading && <>
			<MLTable 
				delKey={"isDel"} 
				headers={headers} 
				items={usercontents.filter(x => x.sk !== "info")} noheader={true} />
		</>}
		{loading && <MLPanelLoading />}
	</div>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const region =  state.AuthReducer.region;
	const board =  state.BoardReducer.board;
	const content =  state.BoardReducer.content;
	const usercontents =  state.BoardReducer.usercontents;
	return { region, user, usercontents, content, board };
};

const mapDispatch = (dispatch) => ({
});

export default connect(mapState, mapDispatch)(RMBoardIContentList);
