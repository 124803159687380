import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import RMWidgetCount from '../widgets/RMWidgetCount';
import { useTranslation } from 'react-i18next';

const RayteamsStatCard = ({ items }) => {
	const dispatch = useDispatch();
    const [t] = useTranslation(['translation']);
    const remainDay = 30;
    const remain = new Date().getTime() + (remainDay * 24 * 3600 * 1000);

	useEffect(() => {
	}, []);

  	return <>
        {items && <div className='row'>
            <div className='col'>
                <RMWidgetCount title={t("전체 Case")} sub={t("누적")} value={items.length} iconName="checkup-list" />
            </div>
            <div className='col'>
                <RMWidgetCount title={t("로그인 수")} sub={t("RT에 가입하고 사용하는 수")} value={items.filter(x => x.groupId)?.length} iconName="user-check" />
            </div>
            <div className='col'>
                <RMWidgetCount title={t("사용 국가수")} sub={t("누적")} value={[...new Set(items.map(x => x.countryCode))]?.length} iconName="world-pin" />
            </div>
            <div className='col'>
                <RMWidgetCount title={t("사용 기관수")} sub={t("누적")} value={[...new Set(items.map(x => x.remoteIP))]?.length} iconName="building" />
            </div>
            <div className='col'>
                <RMWidgetCount title={t("오늘 사용")} sub={t("오늘 이용중")} value={items.filter(x => moment(x.updated).format("MMMMYYYY") === moment().format("MMMMYYYY")).length} iconName="24-hours" />
            </div>
        </div>}
    </>
}

const mapState = (state) => {
	const region = state.AuthReducer.region;
	return { region };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, null)(RayteamsStatCard);
