import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { history } from '../../history';
import { MLPanelLoadingOverlay } from '../common/MLPanel';
import FormBuilder from './FormBuilder';
import { FormAction } from '../../redux/actions/FormAction';
import { GetCodeConfig, GetConfig } from '../../libs/appConfig';

const RTFormsAdd = ({ Get, item }) => {

	const dispatch = useDispatch();
	const { _id } = useParams();
	const [data, setData] = useState({
		controls : []
	});
	const { v4 } = require("uuid");
	const [loading, setLoading] = useState(false);
	const [codes, setCodes] = useState([]);

	useEffect(() => {
		getData();
	}, [_id])

	useEffect(() => {
		console.log(item);
		if(_id && item?.find(x => x.sk === 'form')?._id === _id){
			getnData();
		}
	}, [item])

	const getData = async () => {
		setCodes(await GetCodeConfig())
		if(_id){
			setLoading(true);
			await Get(_id);
			setLoading(false);
		}
	}

	const getnData = () => {
		console.log(item);
		const ret = {...item.find(x => x.sk === 'form')};
		console.log(ret);
		const ret_controls = ret.controls;
		delete ret.controls;
		setData({ info : ret, controls : ret_controls });
	}

	const updated = async (callbackData) => {
		setData(callbackData);
	}

	const updatedCallback = async (callbackData) => {
		if(callbackData.info?._id){
			await dispatch(FormAction.Update({...callbackData.info,
				controls : callbackData.controls
			}));
		}else{
			await dispatch(FormAction.Create({_id : v4(), sk : 'form', ...callbackData.info,
				controls : callbackData.controls
			}));
		}
		history.push('/Forms');
	}
	console.log(codes);

	return <>
		{loading && <MLPanelLoadingOverlay position="absolute" opacity={0} />}
		<div className="p-4">
			<FormBuilder
				codes={codes}
				controls={data.controls}
				form={data}
				cancel={() => {}}
				updateKey={{}}
				updatedCallback={updatedCallback}
				updated={updated} />
		</div>
	</>
}

const mapState = (state) => {
	const user = state.AuthReducer.user;
	const region = state.AuthReducer.region;
	const item = state.RTFormReducer.item;
	return { region, user, item };
};

const mapDispatch = dispatch => ({
	Get: (_id) => dispatch(FormAction.Get(_id))
})

export default connect(mapState, mapDispatch)(RTFormsAdd);