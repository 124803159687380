import React, { useCallback, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SiMicrosoftexcel } from "react-icons/si";
import { MLButton } from "../../components/common/MLButton";
import { MLToolTip } from "../../components/common/MLToolTip";
import LicenseExport from "./LicenseExport";

const LicenseExportWrapper = ({ list, buttonProps }) => {
    const [t] = useTranslation();
    const [show, setShow] = useState(false);
    const listSize = useMemo(() => list?.length ?? 0, [list]);
    const tooltipText = useMemo(() => {
        if (listSize === 0) {
            return t("There's no data to export.");
        }
        if (listSize > 1) {
            return t("You can export {{cnt}} data entries.", { cnt: listSize });
        }
    }, [listSize, t]);

    const openModal = useCallback(() => {
        setShow(true);
    }, []);

    const closeModal = useCallback(() => {
        setShow(false);
    }, []);

    return <>
        <MLToolTip tooltip={tooltipText}>
            <div>
                <MLButton
                    {...buttonProps}
                    options={{
                        icon: <SiMicrosoftexcel className="d-flex align-items-center" size={20} />,
                        color: "primary",
                        action: openModal,
                        needValid: true
                    }}
                    valid={listSize > 0}
                />
            </div>
        </MLToolTip>
        <Modal
            show={show}
            size="xl"
            centered={true}
            scrollable={true}
            onHide={closeModal}
        >
            <Modal.Header closeButton={true}>
                <Modal.Title>
                    {t("Export Relu Credit List")} 
                    {listSize > 0 && <small className="ms-2">{t("Total {{cnt}}", { cnt: listSize })}</small>}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LicenseExport list={list} callbackClose={closeModal} />
            </Modal.Body>
        </Modal>
    </>
};

export default LicenseExportWrapper;