import axios from "axios";
import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { AppConfig } from "../config";
import { GetConfig } from "./appConfig";

const env = location.href.indexOf("rayteams.com") > -1 ? "prod" : "dev";

const s3Path = `https://ray-data.s3.ap-northeast-2.amazonaws.com/rayrnd/rayteams-service/${process.env.REACT_APP_COMPANY}`;
const apiURL = `https://api-teams-info.rayteams.com/translations`;

var nokeylangs = {}
var _cfg = {}
const resources = {}

const init = async () => {
    _cfg = await GetConfig();
    for(const l of (_cfg?.languages || ['en', 'ko'])){
        try{
            const ln = await axios.get(`${s3Path}/translation/${l}-pub.json?t=${new Date().getTime()}`);
            resources[l] = { translation: ln.data }
        }catch(err){
            resources[l] = { translation: { }}
        }
    }
    const options = {
        resources,
        fallbackLng: "en",
        interpolation: { escapeValue: false },
        react: { useSuspense: false },
        missingKeyHandler : (lngs, ns, key) => {
            nokeylangs[key] = key;
        },
        saveMissing: true,
        crossDomain: true,
        requestOptions: {
            mode: 'cors'
        },
    };

    i18n.use(initReactI18next).init(options);
}

setInterval(async () => {
    const sendata = {...nokeylangs};
    nokeylangs = {};
    if(Object.keys(sendata).length > 0 && _cfg.languages){
        axios.post(`${apiURL}?sw=ray&langs=${_cfg['languages']}`, sendata);
    }
}, 10000);

export {
    init
};