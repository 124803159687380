import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { MLPanelNoData, MLPanelNoDataLayout } from "../common/MLPanel";
import { MLIconOrderAsc, MLIconOrderDesc } from "../icon/MLIcon";
import RMProjectPosts from "./RMProjectPosts";

const ProjectMemo = ({ projectId, project, memotype, typeId, typeIds, css = "", hideIcon = false }) => {
    const [t] = useTranslation(['translation']);
    const [visible, setVisible] = useState(false);
    const [oldestFirst, setOldestFirst] = useState(true);
    const [memoList, setMemoList] = useState([]);

    useEffect(() => {
        if (!visible || !project?.memo || !Array.isArray(project.memo)) return;
        const getIncludesTypeId = (item) => {
            if (!typeId && (!typeIds || typeIds?.length === 0) && !memotype) {
                return true;
            }
            let isInclude = false;
            if (!!memotype) {
                isInclude = item.memotype === memotype;
            }
            if (!!typeId) {
                isInclude = item.typeId === typeId;
            }
            if (!isInclude && typeIds && typeIds?.length > 0) {
                isInclude = typeIds.includes(item.typeId);
            }
            return isInclude;
        }
        const _list = [...project.memo]
            .filter(getIncludesTypeId)
            .sort((a, b) => a.created > b.created ? (!oldestFirst ? -1 : 1) : (!oldestFirst ? 1 : -1));
        const displayListArr = [];
        let lastDate = '';
        for (const item of _list) {
            let itemDate = item.sk.replace('memo:', '');
            if (!itemDate || isNaN(itemDate)) {
                displayListArr.push(item);
                continue;
            }
            itemDate = moment(Number(itemDate)).format('LL');
            let isInsert = false

            if (!lastDate) {
                lastDate = itemDate;
                isInsert = true;
            }

            if (lastDate !== itemDate) {
                lastDate = itemDate;
                isInsert = true;
            }
            if (oldestFirst) {
                isInsert && displayListArr.push({
                    _type: 'date_divider',
                    date: itemDate,
                    uk: itemDate
                });
                displayListArr.push(item);
            } else {
                isInsert && displayListArr.push({
                    _type: 'date_divider',
                    date: itemDate,
                    uk: itemDate
                });
                displayListArr.push(item);
            }
        }
        setMemoList(displayListArr)
    }, [visible, oldestFirst, project?.memo, typeId, typeIds])


    useEffect(() => {
        setVisible(projectId && project?._id && projectId === project?._id)
    }, [project?._id, projectId])

    const handlerClickOrderIcon = useCallback(() => {
        setOldestFirst(!oldestFirst);
    }, [oldestFirst]);
    
    return <>
        {project?._id && <div className={`position-relative ml-container ${css}`}>
            <div className="d-flex flex-column" >
                <div className="d-flex justify-content-between align-items-center">
                    <div />
                    <div>
                        {memoList.length > 1 && <div className="text-muted raycur" onClick={handlerClickOrderIcon}>
                            {oldestFirst && <>
                                <span className="me-2">Oldest first</span>
                                <MLIconOrderDesc size={20} />
                            </>}
                            {!oldestFirst && <>
                                <span className="me-2">Newest first</span>
                                <MLIconOrderAsc size={20} />
                            </>}
                        </div>}
                    </div>
                </div>
            </div>
            <div>
                {visible && memoList.map((m) => <React.Fragment key={m.uk}>
                    {m._type && m._type === 'date_divider' && <div className="text-muted mb-4 d-flex">
                        <div className="flex-fill back-divider-line"></div>
                        <div className="px-3">{m.date}</div>
                        <div className="flex-fill back-divider-line"></div>
                    </div>}
                    {!m._type && <RMProjectPosts
                        type="memo"
                        project={project}
                        post={m}
                        hideIcon={hideIcon}
                        isRoot={true}
                        useFooter={true}
                        replyable={true}
                    />}
                </React.Fragment>)}
            </div>
            {memoList.length === 0 && <MLPanelNoData/>}
            
        </div>}
    </>
};

const mapState = (state) => {
    const auth = state.AuthReducer.user;
    return { auth };
};

export default connect(mapState, null)(ProjectMemo);