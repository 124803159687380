import { Modal } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { v4 } from 'uuid'
import { BUTTONS, COLORS, INPUTS, SPAN } from '../../constants/FromInputs';
import { MLButton } from '../common/MLButton';
import { MLForm } from '../common/MLForm';
import { BoardConstant } from '../../redux/reducers';
import { callDB } from '../../libs/db';
import { Store } from '../../store';
import { useTranslation } from 'react-i18next';
import { RTAudit } from '../../libs/audit';

const RMModalAddBoard = ({ modalData, callbackClose }) => {
	const [t] = useTranslation(['translation']);
	const dispatch = useDispatch();
	const [data, setData] = useState({ title: "" });

	useEffect(() => {
		if (modalData.show) {
			setData({ title: "" });
		}
	}, [modalData.show]);

	const permHQ = (x) => {
		if(x.value === 'MANUALDOC'){
			if(process.env.REACT_APP_COMPANY === 'ray'){
				return true;
			}
			return false;
		}
		return true;
	}

	const addBoard = async () => {
		const usersub = localStorage.getItem("usersub");
		const upItem = {
			_id: v4(),
			sk: "info",
			title: data.title,
			type: data.type,
			created: new Date().getTime(),
			creator: usersub,
		}
		
		const ret = await callDB({
			type : "update",
			region : "ap-northeast-2",
			params : {
				TableName: "rayteams-manager-boards",
				Key: {
					_id: v4(),
					sk: "info",
				},
				UpdateExpression:
					"SET #title = :title, #type = :type, #created = :created, #creator = :creator ",
				ExpressionAttributeNames: {
					"#title": "title",
					"#type" : "type",
					"#created": "created",
					"#creator": "creator"
				},
				ExpressionAttributeValues: {
					":title": data.title,
					":type" : data.type,
					":created": new Date().getTime(),
					":creator": usersub
				},
			},
			filter : {}
		});
		RTAudit({ lvl: 4, msg: 'Add Pool'}, JSON.stringify({ subject: data }));
		dispatch({ type: BoardConstant.CREATE_BOARD, item: upItem });
		callbackClose && callbackClose();
	}

	const controls = [
		{
			type: INPUTS.TEXT,
			options: {
				label: t('제목'),
				name: 'title',
				required: true
			}
		},
		{
			type: INPUTS.COMBO,
			options: {
				label: t('타입'),
				name: 'type',
				list: Store.getState().ProgramReducer.codes.filter(x => x.category === 'CMS_TYPE' && permHQ(x)),
				required: true
			},
		},
		{
			type: SPAN.VERTICAL,
			options: { size: 10 }
		},
		{
			type: BUTTONS.BUTTONS,
			options: {},
			buttons: [{
				type: BUTTONS.NORMALBUTTON,
				options: {
					label: t('Cancel'),
					// color : COLORS.SECONDARY,
					action: () => callbackClose()
				}
			}, {
				type: BUTTONS.NORMALBUTTON,
				options: {
					label: t('Add'),
					color: COLORS.PRIMARY,
					action: () => addBoard()
				}
			}]
		}
	];

	return <div className=''>
		<Modal show={modalData.show} size={"lg"} style={{ backgroundColor: "#42476c !important" }}>
			<Modal.Header className='modal-header header-bg'>
				<h3>Pool 추가</h3>
			</Modal.Header>
			<Modal.Body className='flex-fill pt-0 formcard pt-6'>
				<MLForm controls={controls} data={data} nocard={true} onChanged={(e) => { console.log(e); setData(e); }} />
			</Modal.Body>
		</Modal>
	</div>
}

export default RMModalAddBoard;
