import { Modal } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { history } from '../../history';
import { fetchData, idData,queryData } from '../../libs/db';
import { useTranslation } from 'react-i18next';

const RMNotice = ({ }) => {
    const dispatch = useDispatch();
    const [t] = useTranslation(['translation']);
	const [loading, setLoading] = useState(false);
	const [content, setContent] = useState({});
	useEffect(() => {
        getData();
	}, []);

	const getData = async () => {
        const ret = await fetchData("rayteams-manager-contents", {
            sk: {
                ComparisonOperator: "EQ",
                AttributeValueList: ["info"],
            },
            type: {
                ComparisonOperator: "EQ",
                AttributeValueList: ["MANAGER_NOTICE"],
            }            
        });
		try{
            if(ret.Count > 0){
                const con = ret.Items.filter(x => x.sk === "info" && !x.isDel).sort((a, b) => a.created > b.created ? -1 : 1)[0];
                setContent(con);
            }else{
                //alert("Not exist");
            }
		}catch(err){
			console.log(err);
		}
	}
	
	return <>
        {content.content && <div className='card bg-primary cursor-pointer' onClick={() => history.push("/Content/" + content.bid + '/' + content._id)} style={{ height : 230 }}>
            <div className="card-body text-white">
                <div className='mb-2 text-white h5'>
                    {t("Notice")} 
                    <span className='ms-2 small'>{moment(content?.created).format("LLL")}</span>
                </div>
                <div className='text-white' style={{
                    height: "150px",
                    textOverflow: "ellipsis",
                    overflow: "hidden"
                }} dangerouslySetInnerHTML={ {__html: content.content.replace(/<p>/gi, '').replace(/<\/p>/gi, ' ')} }></div>
            </div>
        </div>}
    </>
}

const mapState = (state) => {
    const adminUsers = state.AuthReducer.adminUsers;
	const user = state.AuthReducer.user;
	const loginuser = state.AuthReducer.loginuser
	return { adminUsers, user, loginuser };
};

const mapDispatch = dispatch => ({
})

export default connect(mapState, mapDispatch)(RMNotice);
