import { connect, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import RMBoardContentList from '../components/board/RMBoardContentList';
import RMBoardInfoPaenl from '../components/board/RMBoardInfoPaenl';
import RMBoardMenu from '../components/board/RMBoardMenu';
import RMBoardTabs from '../components/board/RMBoardTabs';
import { history } from '../history';

const BoardInfoPage = ({ user, board, contents }) => {

	const { _bid, _tab = "list" } = useParams();
	const dispatch = useDispatch();

  	return <div className='p-4'>
		<RMBoardInfoPaenl />
		{_tab === "list" && <RMBoardContentList /> }
		{_tab === "menu" && <RMBoardMenu /> }
	</div>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const region =  state.AuthReducer.region;
	const board =  state.BoardReducer.board;
	const contents =  state.BoardReducer.contents;
	return { region, user, board, contents };
};

const mapDispatch = (dispatch) => ({
});

export default connect(mapState, null)(BoardInfoPage);
