import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { MLText } from '../../components/common/MLInput';
import { MLPanelLoading } from '../../components/common/MLPanel';
import Select from 'react-select';
import MLTable from '../../components/common/MLTable';
import { AuthConstant, getAPI, getHeader } from '../../redux/reducers/AuthReducer';
import { fetchData } from '../../libs/db';
import WidgetBarChartAppUsage from '../../components/widgets/dashboard/WidgetBarChartAppUsage';
import RMWidgetBarChart from '../../components/widgets/RMWidgetBarChart';
import RMWidgetLineChart from '../../components/widgets/RMWidgetLineChart';
import SimpleDayChart from '../../components/widgets/SimpleDayChart';

import { COUNTRIES } from '../../constants/Country';
import { MLButton, MLRefreshButton } from '../../components/common/MLButton';
import { history } from '../../history';
import { COLORS } from '../../constants/FromInputs';
import { RMIconList, RMIconChart } from '../../components/icon/MLIcon';
import { MLPanelNoData } from '../../components/common/MLPanel';

import { ProgramConstant, PaymentConstant } from '../../redux/reducers';
import { useTranslation } from 'react-i18next';
const SubChart = ({ items }) => {
	const dispatch = useDispatch();
    const [t] = useTranslation(['translation']);
    const [groups, setGroups] = useState([]);
    const remainDay = 30;
    const remain = new Date().getTime() + (remainDay * 24 * 3600 * 1000);
	
  	return (<>
        {items && <div className="d-flex flex-column flex-column-fluid">
            <div className="post p-7">
                <div className='row mb-5'>
                    <div className='col mb-5'>
                        <SimpleDayChart data={items} title={t("가입 현황 (일별 신규)")} period={"days"} target={"created"} max={30} />
                    </div>
                    <div className='col'>
                        <SimpleDayChart data={items} title={t("가입 현황 (일별 누적)")} period={"days"} target={"created"} max={30} dataType={"stack"} />
                    </div>
                </div>
            </div>
        </div>}
    </>
  	);
}

const mapState = (state) => {
	const region = state.AuthReducer.region;
	return { region };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, null)(SubChart);
