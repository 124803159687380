import { Modal } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { FaArrowRight, FaArrowDown } from 'react-icons/fa'
import AWS from 'aws-sdk'
import { useTranslation } from 'react-i18next';

const S3_BUCKET ='rayteams-pub-data';
const REGION ='ap-northeast-2';

AWS.config.update({
    accessKeyId: 'AKIAV6JBTAHFEDIZCXUA',
    secretAccessKey: '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'
})
const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET},
    region: REGION,
})
const RMContentUpload = ({ callback }) => {

    const [progress , setProgress] = useState(0);
    const [selectedFile, setSelectedFile] = useState(null);
    const [keyName, setKeyName] = useState("");
	const [show, setShow] = useState(false);
	const [t] = useTranslation(['translation']);

    const handleFileInput = (e) => {
        setSelectedFile(e.target.files[0]);
    }

	useEffect(() => {
		if(selectedFile?.name)
			setKeyName('rayteams-contents/' + new Date().getTime() + "." + selectedFile.name.split('.').pop() );
	},[selectedFile])

	useEffect(() => {
		if(progress === 100){
			callback && callback(S3_BUCKET, keyName);
			setSelectedFile(null);
			setProgress(0);
		}
	},[progress])

    const uploadFile = (file) => {
        const params = {
            Body: file,
            Bucket: S3_BUCKET,
            Key: keyName
        };
        myBucket.putObject(params).on('httpUploadProgress', (evt) => {
			setProgress(Math.round((evt.loaded / evt.total) * 100))
		})
		.send((err) => {
			if (err) console.log(err)
		})
    }

    return <div className='mb-4 p-2' style={{ border : "2px solid #eaeaea" }}>
		<div className='align-items-center' onClick={() => setShow(!show)}>
			<label className="form-label me-2 raycur">{t("이미지 첨부")}<span></span></label>
			{!show && <FaArrowRight className="raycur" />}
			{show && <FaArrowDown className="raycur" />}
		</div>
		{show && <>
			{progress > 0 && <div>{t("Upload...")} {progress}%</div>}
			<input type="file" onChange={handleFileInput} className="form-control mb-2" />
			<button onClick={() => uploadFile(selectedFile)} className="btn btn-sm btn-primary"> {t("Upload")}</button>
		</>}
    </div>
}

export default RMContentUpload;