import { useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import { MLPanelLoading } from '../common/MLPanel';
import { Bar, Line, getElementAtEvent } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { getRandomColors } from '../../libs/colors';
import { slice, uniq, uniqBy } from 'lodash';
import { history } from '../../history';
import { UsageConstant } from '../../redux/reducers';
import { ChartDays, ChartMonths } from '../common/ChartDays';
import { SEARCHPERIOD } from '../../constants/RMConstants';
import { MLComboSingle } from '../common/MLInput';
import { TbChartLine } from 'react-icons/tb';
import { MLToggle } from '../common/MLInput';

const SimpleDayChart = ({ data, type, title, target, chartType, horizental = false, top = true, period, max = 14, dataType }) => {
	const dispatch = useDispatch();
	const [ chartData, setChartData ] = useState([]);
	const [ loading, setLoading ] = useState(true);
	const [ stacked, setStacked ] = useState(false);
	const [ lineChart, setLineChart ] = useState(false);
	const chartTypeList = [
        { value : false, label : "Bar Chart" },
        { value : true, label : "Stack Chart" },
    ]
	const chartRef = useRef();
	//let minY = 10;

    useEffect(() => {
		if (data && dataType == "stack") {
			generateStack(data, period, type, target);
		} else {
			generate(data, period, type, target);
		}
    }, [data]);

	// status = 'sub'
    // started 
    // KST 오전 9시 기준 (제거)
	const generateStack = async (list, period, type, target) => {
		// 한국 표준시 9시
		// const now = new Date();
		// now.toLocaleString('en-US', { timeZone: 'Asia/Seoul' });
		// now.setHours(9,0,0,0);

		let cumulativeValue = 0;
		const clist = [];
		const barDataset = [];
		

		ChartDays(max, period, "YYYY-MM-DD").map((x, idx) => {
			clist.push( {name: x, count: 0 });
		});

		const lists = list.sort((a, b) => a[target] > b[target] ? 1 : -1);
		lists.filter(x => x[target]).map(x => {
			var foundIndexStack = clist.findIndex(y => y.name == moment(x[target]).format("YYYY-MM-DD"));
			if (foundIndexStack > -1) {
				clist[foundIndexStack].count = cumulativeValue + 1;
				cumulativeValue += 1;
			}
		});

		const colorSet = getRandomColors(clist.length, 0.8);

		barDataset.push(
			{
				data: clist.sort((a, b) => a.name > b.name ? 1 : -1).map((x) => x.count),
				barThickness: 28,
				backgroundColor: colorSet.map(x => x.opacityColor),
				borderColor: colorSet.map(x => x.color),
			}
		)

		setChartData({
			labels: clist.sort((a, b) => a.name > b.name ? 1 : -1).map((x) => x.name === "" ? type + " 없어요" : x.name ),
			datasets: barDataset,
			minY: clist.reduce((max, obj) => (obj.count > max ? obj.count : max), clist[0].count)
		});

		
		console.log("minY : ====", 		chartData
		);
		setLoading(false);
	}

	const generate = async (list, period, type, target) => {
		// const now = new Date();
		// now.toLocaleString('en-US', { timeZone: 'Asia/Seoul' });
		// now.setHours(9,0,0,0);

		const clist = [];
		const barDataset = [];
		

        ChartDays(max, period, "YYYY-MM-DD").map((x, idx) => {
            clist.push( {name: x, count: 0});
        })
        list.sort((a, b) => a[target] > b[target] ? 1 : -1).filter(x => x[target]).map(x => {
            var foundIndex = clist.findIndex(y => y.name == moment(x[target]).format("YYYY-MM-DD"));
            if(foundIndex > -1)
                clist[foundIndex].count += 1;
        });

		const colorSet = getRandomColors(clist.length, 0.8);

        barDataset.push(
            {
                data: clist.sort((a, b) => a.name > b.name ? 1 : -1).map((x) => x.count),
				backgroundColor: "#887feb",
				borderRadius: 10
            }
        )

		setChartData({
            labels: clist.sort((a, b) => a.name > b.name ? 1 : -1).map((x) => x.name === "" ? type + " 없어요" : x.name ),
            datasets: barDataset,
			minY: clist.reduce((max, obj) => (obj.count > max ? obj.count : max), clist[0].count)
        });

		//minY = clist.reduce((max, obj) => (obj.count > max ? obj.count : max), clist[0].count);
		setLoading(false);
	}

  	return <>
		{!loading && <div><div className='card'>
			{chartData && <>
			<div className='card-header header-elements'>
                <h5 className='card-title mb-0'>{title}</h5>
                <div className='card-action-element ms-auto py-0'></div>
			</div>
			<div className='card-body'>
				{lineChart &&
				<Line
					height={300}
					data={chartData}
					options={{
						responsive: true,
						maintainAspectRatio: false,
						cutout: 0,
						animation: { animateScale: true },
						plugins: { legend: { display : true, position: "right" }, },
						scales: {
							y: { grid: { display: false } },
							x: { grid: { display: false } }
						}					
					}}
				/>
				}
				{!lineChart &&
				<Bar 
					ref={chartRef}
					height={300}
					data={chartData} 
					plugins={[ChartDataLabels]}
					options={{
						indexAxis: 'x',
						responsive: true,
						maintainAspectRatio: false,
						cutout: 0,
						animation: { animateScale: true },
						plugins: { 
							legend: { display : false, position: "right" }, 
							datalabels: {
								formatter: function (value) {
								  return value == 0 ? "" : value;
								},
								display: true,
								color: "gray",
								anchor: 'end',
								align: 'end',
								font: {
								  weight: 'bold',
								  size: 13,
								},
							},
						},
						scales: {
							y: { ticks: { beginAtZero: true, min : 10, stepSize : 10 }, grid: { display: false } },
							x: { ticks: { color: '#666060', beginAtZero: true, callback: function(value, index, ticks) {
								return this.getLabelForValue(value).substring(5,10).replace("-", "/");
							} }, grid: { display: false } },
						},
					}}/>
				}
			</div>
			</> }
		</div></div>}
		{loading && <MLPanelLoading />}
	</>
}

const mapState = (state) => {
    const logs = state.UsageReducer.logs;
	const dailylogs =  state.UsageReducer.dailylogs;
	const selectedlogs = state.UsageReducer.selectedlogs;
	const stats = state.UsageReducer.stats;
	
	return { logs, dailylogs, stats, selectedlogs };
};

const mapDispatch = (dispatch) => ({
});

export default connect(mapState)(SimpleDayChart);
