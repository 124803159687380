
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import MLCountryCombo from './MLCountryCombo';
import { DATELATEST, RMBTNS, USERTYPE, USER_FILTER } from '../../constants/RMConstants';
import { FaRegTrashAlt } from "react-icons/fa";
import { MLComboSingle, MLDate, MLNumber, MLText } from './MLInput';
import { ProgramConstant } from '../../redux/reducers';
import { MLButton } from './MLButton';
import { MLSpinner } from './Loader';

export const MLFilterDate = ({ fitem, value, callback }) => {

	const dispatch = useDispatch();
	const [dVal, setDVal] = useState({ type : "a" });
	const [aOptions, setAOption] = useState({ 
		opt : "a",
		value : 7, 
		period : "day", 
		valuelabel : "최근 7일" 
	});
	const [bOptions, setBOption] = useState({ 
		opt : "b",
		start : moment().add(-7, "days").format("yyyy-MM-DD"), 
		end : moment().format("yyyy-MM-DD"), 
		valuelabel : moment().add(-7, "days").format("yyyy-MM-DD") + " ~ " + moment().format("yyyy-MM-DD") 
	});

	useEffect(() => {
		upVals();
	}, [value]);

	const upVals = () => {
		if(value?.opt){
			setDVal({...dVal, type : value.opt});
			if(value.opt === "a")
				setAOption(value)
			if(value.opt === "b")
				setBOption(value)
		}
	}

	const apply = () => {
		callback({
			target : {
				name : fitem.key,
				valuelabel : dVal.type === "a" ? aOptions.valuelabel : bOptions.valuelabel,
				value : dVal.type === "a" ? aOptions : bOptions
			}
		})
	}

	return <>
		<label className={'form-label'}>{fitem.label}<span/></label>
		<div className='border p-4'>
			<div class="form-check d-flex align-items-center mb-3">
				<input className="form-check-input" type="radio" onClick={() => setDVal({...dVal, type : "a" })} checked={dVal.type === "a" ? "checked" : ""} />
				<div className="me-1 ms-2">최근</div>
				<input type={"number"} 
					className="form-control me-1" 
					value={aOptions.value} 
					onChange={(e) => setAOption({...aOptions, value : e.target.value, valuelabel : "최근 " + e.target.value + DATELATEST.find(n => n.value === aOptions.period).label})} 
					style={{ width : "100px"}} />
				<MLComboSingle 
					options={{ list : DATELATEST, value : aOptions.period }} 
					handleChanged={(e) => setAOption({...aOptions, period : e.target.value, valuelabel : "최근 " + aOptions.value + DATELATEST.find(n => n.value === e.target.value).label})} 
					css="mb-0 me-1"/>
				<div>이내</div>
			</div>
			<div class="form-check d-flex align-items-center mb-3">
				<input className="form-check-input" type="radio" onClick={() => setDVal({...dVal, type : "b" })} checked={dVal.type === "b" ? "checked" : ""} />
				<MLDate 
					options={{ value : moment(bOptions?.start).toDate() }} 
					handleChanged={(e) => setBOption({...bOptions, start : moment(e.target.value), valuelabel : moment(e.target.value).format("yyyy-MM-DD") + " ~ " + bOptions.end.format("yyyy-MM-DD") }) } 
					css="me-1 ms-2" /> 과
				<MLDate 
					options={{ value : moment(bOptions?.end).toDate() }} 
					handleChanged={(e) => setBOption({...bOptions, end : moment(e.target.value), valuelabel : moment(bOptions.start).format("yyyy-MM-DD") + " ~ " + moment(e.target.value).format("yyyy-MM-DD") }) } 
					css="me-1 ms-2" /> 사이
			</div>
			{/*<div class="form-check d-flex align-items-center">
				<input className="form-check-input" type="radio" onClick={() => setDVal({...dVal, type : "c" })} checked={dVal.type === "c" ? "checked" : ""} />
				<MLText options={{ value : "-4w" }} handleChanged={(e) =>  {}} css="me-1 ms-2" /> ~ 
				<MLText options={{ value : "-7d" }} handleChanged={(e) =>  {}} css="me-1 ms-2" /> 까지 범위에서
			</div>*/}
			<MLButton options={{
				label : "적용",
				color : "primary",
				action : () => apply()
			}} />
		</div>
	</>
}