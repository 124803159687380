import React from 'react';
import { Handle } from 'react-flow-renderer';
//import MaterialIcon from '@material/react-material-icon';
import MaterialIcon from '@material/react-material-icon';

export const WFNodeAction = (({ data, isConnectable }) => {
	const isValidConnection = (connection) => {
		console.log(connection.target);
		return connection.target === 'B';
	}
	return <>
			<Handle
				type="target"
				position="top"
				style={{ background: '#555' }}
				onConnect={(params) => console.log('handle onConnect', params)}
				isValidConnection={isValidConnection}
				isConnectable={isConnectable}
			/>
				<div className='wf_node_box'>
					<MaterialIcon icon={"play_circle"} />
					<span>{ data.title }</span>
				</div>
			<Handle
				type="source"
				position="bottom"
				id="ah1"
				isConnectable={isConnectable}
			/>
			<Handle
				type="source"
				position="bottom"
				id="ah3"
				isConnectable={isConnectable}
			/>
			<Handle
				type="source"
				position="bottom"
				id="ah4"
				isConnectable={isConnectable}
			/>
		</>
});

export const WFNodeStatus = (({ data,  isConnectable }) => {
	const isValidConnection = (connection) => {
		return connection.target === 'B';
	}

	return <>
			<Handle
				type="target"
				position="top"
				style={{ background: '#555' }}
				onConnect={(params) => console.log('handle onConnect', params)}
				isValidConnection={isValidConnection}
				isConnectable={isConnectable}
			/>
				<div className='wf_node_box'>
					<span>{ data.title }</span>
				</div>
			<Handle
				type="source"
				position="bottom"
				id="b"
				isConnectable={isConnectable}
			/>
		</>
});