import { connect, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import RMBoardContentList from '../../components/board/RMBoardContentList';
import RMBoardInfoPaenl from '../../components/board/RMBoardInfoPaenl';
import RMBoardMenu from '../../components/board/RMBoardMenu';
import { history } from '../../history';
import { MdSubdirectoryArrowRight } from "react-icons/md";

const RMBoardTabs = ({ user, bid, contents }) => {

	const { _bid, _tab = "list" } = useParams();

	const tabs = [
		{value : "list", label : "Contents"},
		{value : "menu", label : "Menu"},
	]

  	return <div className="card mb-5">
		<div className="card-header">
			<ul className="nav nav-stretch">
				{tabs.map((x, idx) => {
					return <li className="nav-item" key={idx} onClick={() => history.push("/Board/" + (bid || _bid) + "/" + x.value)}>
						<Link to="#" className={"nav-link " + (_tab === x.value ? 'fw-bolder text-white bg-gray-800 ' : '')}>{x.label}</Link>
					</li>
				})}
			</ul>
		</div>
	</div>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const region =  state.AuthReducer.region;
	const contents =  state.BoardReducer.contents;
	return { region, user, contents };
};

const mapDispatch = (dispatch) => ({
});

export default connect(mapState, mapDispatch)(RMBoardTabs);
