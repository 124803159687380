import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import RTFormsAdd from '../../components/forms/RTFormsAdd';

const RTProductAddPage = ({ }) => {

    return (<>
        <RTFormsAdd/>
    </>);
};


const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const region =  state.AuthReducer.region;

	return { region, user };
};

const mapDispatch = (dispatch) => ({

});

export default connect(mapState, null)(RTProductAddPage);