import axios from 'axios';
import { JWT } from '../../config';
import { RTFormConstant } from '../reducers/RTFormReducer';
import { InfoAction } from './InfoAction';

const GetAll = () => async (dispatch) => {
    dispatch({ type: RTFormConstant.GET_ALL_LOADING, item : true });
    const ret  = await InfoAction.callDB({ type : "scan", region : "ap-northeast-2", params : {
        TableName: "rayteams-product",
        FilterExpression: "#sk=:sk",
        ExpressionAttributeNames: { "#sk": "sk" },
        ExpressionAttributeValues: { ":sk": "form" },
    }});
    dispatch({ type: RTFormConstant.GET_ALL, items : ret });
}

const Get = (_id) => async (dispatch) => {
    const ret  = await InfoAction.callDB({ type : "scan", region : "ap-northeast-2", params : {
        TableName: "rayteams-product",
        FilterExpression: "#_id=:_id",
        ExpressionAttributeNames: { "#_id": "_id" },
        ExpressionAttributeValues: { ":_id": _id },
    }});
    dispatch({ type: RTFormConstant.GET, item : ret });
}

const Create = (data) => async (dispatch) => {
    const keys = Object.keys(data).filter(x => x !== '_id' && x !== 'sk').map(x => '#' + x + '=:' + x);
    let exnames = { '#created' : 'created' };
    let exvalues = { ':created' : new Date().getTime() };
    for(const n of Object.keys(data).filter(x => x !== '_id' && x !== 'sk')){
        exnames['#' + n] = n;
        exvalues[':' + n] = data[n];
    }
    const ret  = await InfoAction.callDB({ type : "update", region : "ap-northeast-2", params : {
        TableName: "rayteams-product",
        UpdateExpression: 'SET ' + keys.join(', ') + ', #created = :created',
        ExpressionAttributeNames: exnames,
        ExpressionAttributeValues: exvalues,
        Key: { _id: data._id, sk: data.sk }
    }});
    dispatch({ type: RTFormConstant.CREATE, item : ret });
}

const Update = (data) => async (dispatch) => {
    const keys = Object.keys(data).filter(x => x !== 'updated' && x !== '_id' && x !== 'sk').map(x => '#' + x + '=:' + x);
    let exnames = { '#updated' : 'updated' };
    let exvalues = { ':updated' : new Date().getTime() };
    for(const n of Object.keys(data).filter(x => x !== '_id' && x !== 'sk')){
        exnames['#' + n] = n;
        exvalues[':' + n] = data[n];
    }
    const ret  = await InfoAction.callDB({ type : "update", region : "ap-northeast-2", params : {
        TableName: "rayteams-product",
        UpdateExpression: 'SET ' + keys.join(', ') + ', #updated = :updated',
        ExpressionAttributeNames: exnames,
        ExpressionAttributeValues: exvalues,
        Key: { _id: data._id, sk: data.sk }
    }});
    dispatch({ type: RTFormConstant.UPDATE, item : ret });
}

const Remove = (_id, sk) => async (dispatch) => {
    const dt = new Date().getTime();
    const ret  = await InfoAction.callDB({ type : "update", region : "ap-northeast-2", params : {
        TableName: "rayteams-product",
        UpdateExpression: 'SET #removed=:removed, #updated = :updated',
        ExpressionAttributeNames: { '#removed' : 'removed', '#updated' : 'updated' },
        ExpressionAttributeValues: { ':removed' : dt, ':updated' : dt },
        Key: { _id: _id, sk: sk }
    }});
    dispatch({ type: RTFormConstant.UPDATE, item : ret });
}

export const FormAction = {
    GetAll,
    Get,
    Create,
    Update,
    Remove,
};
