import axios from 'axios';
import { useEffect, useState, useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import MLTable from '../common/MLTable';
import { AuthConstant, getAPI, getHeader } from '../../redux/reducers/AuthReducer';
import { MLPanelNoDataLayout, MLPanelLoading, MLPanelNoData } from '../common/MLPanel';
import WidgetProjectSummary from './WidgetProjectSummary';
import WidgetStorageUsage from './WidgetStorageUsage';
import { MLIconOrderAsc, MLIconOrderDesc } from "../icon/MLIcon";
import ProjectHistoryItem from './ProjectHistoryItem';

const LS_PROJECT_HISTORY_ORDER_KEY = '_project_history_order';

const RMProjectHistory = ({ group, projectId, project }) => {
    const [visible, setVisible] = useState(false);
    const [oldestFirst, setOldestFirst] = useState(true);
    const [historyList, setHistoryList] = useState([]);
	const dispatch = useDispatch();
	const [ items, setItems ] = useState([]);
	const [loading, setLoading] = useState(false);

    useEffect(() => {
        let _orderKey = window?.localStorage?.getItem(LS_PROJECT_HISTORY_ORDER_KEY);
        if (_orderKey) {
            _orderKey = JSON.parse(_orderKey);
            setOldestFirst(_orderKey);
        }
    }, []);

    useEffect(() => {
        if (!visible || !project?.histories || !Array.isArray(project.histories)) return;
        const _list = [...project.histories].filter(x => !!x.updateType).sort((a, b) => a.crested < b.created ? (!oldestFirst ? -1 : 1) : (!oldestFirst ? 1 : -1));
        setHistoryList(_list)
    }, [visible, oldestFirst, project?.histories])

    useEffect(() => {
        setVisible(projectId && project?._id && projectId === project?._id)
    }, [project?._id, projectId])

    const handlerClickOrderIcon = useCallback(() => {
        window?.localStorage?.setItem(LS_PROJECT_HISTORY_ORDER_KEY, !oldestFirst)
        setOldestFirst(!oldestFirst);
    }, [oldestFirst]);

  	return <>
		{loading && <MLPanelLoading />}
        {project?._id && <div className="ml-container pt-4">
            <div className={visible && historyList.length === 0 ? "" : "d-flex flex-column"}>
                <div className="d-flex justify-content-between align-items-center">
                    <div/>
                    <div>
                        {historyList.length > 1 && <div className="text-muted raycur" onClick={handlerClickOrderIcon}>
                            {oldestFirst && <>
                                <span className="me-2">Oldest first</span>
                                <MLIconOrderDesc size={20} />
                            </>}
                            {!oldestFirst && <>
                                <span className="me-2">Newest first</span>
                                <MLIconOrderAsc size={20} />
                            </>}
                        </div>}
                    </div>
                </div>
                {visible && historyList.map(x => <ProjectHistoryItem item={x} key={x.sk} />)}
                {visible && historyList.length === 0 && <MLPanelNoData/>}
            </div>
        </div>}
	</>
}


export default connect(null)(RMProjectHistory);