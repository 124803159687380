import { useEffect, useState } from 'react';
import moment from 'moment';
import { Link, useParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { idData, fetchData, putData, updateData } from '../../libs/db';
import MLTable from '../../components/common/MLTable';
import { FaHome, FaPhoneAlt, FaWindowClose } from 'react-icons/fa';
import { history } from '../../history';
import { MLButton } from '../../components/common/MLButton';
import { MLComboSingle, MLText } from '../../components/common/MLInput';
import { BUTTONS, COLORS } from '../../constants/FromInputs';
import { ProgramConstant, PaymentConstant } from '../../redux/reducers';
import { Modal } from 'react-bootstrap';
import { RTButton } from '../../libs/buttons';
import MLModal from '../../components/common/MLModal';

const PaymentGatewayInfoPage = ({ paymentgateway, paymentgateways, page }) => {
    const dispatch = useDispatch();
    const { _id } = useParams("");
    const [data, setData] = useState({});
    const [branchData, setBranchData] = useState({});
    const [help, setHelp] = useState(window.localStorage.getItem("helpmsg") === "N" ? false : true);
    const [pgs, setPGs] = useState([]);
    const [allBranch, setBranch] = useState([]);
    const [err, setErr] = useState("");
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [branchShow, setBranchShow] = useState(false);
    const [branchEdit, setBranchEdit] = useState(false);
    const [modalConfirm, setModalConfirm] = useState({
        show: false,
        title: <div className='fs-3' style={{ marginBottom: -30 }}>Publish 하시겠습니까?</div>,
        body: <div className='fs-5 pb-4'>Publish 되면 해당 Branch에 Payment Gateway가 등록됩니다.</div>
    });

    useEffect(() => {
        dispatch({
            type: ProgramConstant.SET_PAGE, page: {
                code: "paymentgateway",
                view: "summary",
                title: "Payment Gateway(PG)",
                small: "PG 관련 설정 및 관리",
                options: [
                ]
            }
        })
    }, []);

    useEffect(() => {
        if (_id)
            init();
    }, [_id, paymentgateways]);

    useEffect(() => {
        window.localStorage.setItem("helpmsg", help ? "Y" : "N");
    }, [help]);

    const init = async () => {
        if (paymentgateways && paymentgateways.length > 0) {
            dispatch({ type: PaymentConstant.PG_GET, item: paymentgateways.find(f => f._id === _id && f.sk === "info") });
        } else {
            const ret = await idData('rayteams-paymentgateway', _id);
            dispatch({ type: PaymentConstant.PG_GET_ALL, items: ret.Items })
            dispatch({ type: PaymentConstant.PG_GET, item: ret.Items.find(f => f._id === _id && f.sk === "info") });
        }

        await callbackPGInfoUpdate();
    }

    const callbackPGInfoUpdate = async () => {
        setLoading(true);
        let rets = [];
        const ScanFilter = {
            sk: {
                ComparisonOperator: "EQ",
                AttributeValueList: ["info"],
            },
        };

        try {
            const ret = await fetchData("rayteams-manager-group", ScanFilter);
            rets = ret.Items;
            setBranch(ret.Items);
        } catch (e) {
            console.log(e);
        }

        const selPgs = [];
        paymentgateways.map(x => {
            //if(x._id === _id && x.sk.includes("token")) {
            if (x._id === _id && x.sk != "info") {
                selPgs.push({
                    branch: x.sk, //rets.find(f => f._id === x.sk.split(":")[1])?.name || "Dev",
                    cid: x?.cid || "",
                    skey: x?.skey || "",
                    ispublished: x?.ispublished || false,
                    created: x.created,
                    sk: x.sk
                })
            }
        })

        setPGs(selPgs);
        setLoading(false);
    }

    const createBranch = async () => {
        setLoading(true);
        const item = {
            _id: _id,
            sk: `token:${branchData.branch}`,
            cid: branchData.cid || "",
            skey: branchData.skey || "",
            ispublished: branchData.ispublished || false,
            created: new Date().getTime(),
        }

        const ret = await putData("rayteams-paymentgateway", item);
        dispatch({ type: PaymentConstant.CREATE_PG, item: item });
        const selPgs = [];
        paymentgateways.map(x => {
            if (x._id === _id && x.sk.includes("token")) {
                selPgs.push({
                    branch: allBranch?.find(f => f._id === x.sk.split(":")[1])?.name || "Dev",
                    cid: x?.cid || "",
                    skey: x?.skey || "",
                    ispublished: x?.ispublished || false,
                    created: x.created,
                    sk: x.sk
                })
            }
        })

        setPGs(selPgs);
        setLoading(false);
        setData({});
        setShow(false);
    }

    const modifyPGInfo = async () => {
        setShow(true); setBranchShow(false);
        setData({ _id: _id, sk: "info", desc: paymentgateway?.desc || "" });
    }

    const updatePGInfo = async () => {
        if (branchEdit) {
        } else {
            try {
                const ret = await updateData("rayteams-paymentgateway", {
                    _id: _id,
                    sk: "info"
                }, {
                    desc: data?.desc || "",
                });
                dispatch({ type: PaymentConstant.PG_GET, item: ret });
            } catch (err) {
                console.log(err);
            }
        }
        setShow(false);
    }

    const modifyClick = async (item) => {
        setShow(true); setBranchShow(true); setBranchEdit(true);
        setBranchData({});
        setBranchData({ _id: _id, sk: "token" + item.branch, cid: item.cid || "", skey: item.skey || "", ispublished: item.ispublished });
    };

    const pricePublish = async (item) => {
        setModalConfirm({ ...modalConfirm, show: true, id: _id, sk: pgs.find(f => f.cid == item.cid).sk })
    };

    const confirmPublish = async () => {
        try {
            const ret = await updateData("rayteams-paymentgateway", {
                _id: _id,
                sk: modalConfirm.sk
            }, {
                ispublished: true,
            });
            dispatch({ type: PaymentConstant.UPDATE_PG, item: ret });
        } catch (err) {
            console.log(err);
        }
        setModalConfirm({ ...modalConfirm, show: false });
    };

    const labHeaders = [
        { value: "branch", label: "Branch Name", css: "text-start", style: { width: 150 } },
        { value: "ispublished", label: "Publish", type: "TFVALUE" },
        { value: "cid", label: "cid", type: "XName", style: { width: 150 } },
        { value: "skey", label: "skey", type: "XName", style: { width: 150 } },
        { value: "created", label: "Created", css: "text-end", type: "DATESHORT" },
        {
            label: "Modify", btncss: "primary", code: "rtp-pricemodify", type: "BUTTON2", btnClick: async (item) => {
                await modifyClick(item);
            }
        },
        {
            label: "Publish", btncss: "success", code: "rtp-pricepublish", type: "BUTTON2", btnClick: async (item) => {
                await pricePublish(item);
            }
        }
    ]

    return <div className='p-4'>
        <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
                <div className="card-label fw-bold text-gray-800">
                    <h3>{paymentgateway.title}</h3>
                    <span className='fs-7'>Registered : {moment(paymentgateway.created).format('LL')}</span>
                    {paymentgateway?.desc && <span className='me-4 d-flex align-items-center'>{paymentgateway.desc}</span>}
                    {paymentgateway?.website && <span className='text-white me-4 d-flex align-items-center'>
                        <FaHome className='me-1' />
                        <a href={paymentgateway?.website} className="text-light-primary" target="_blank">{paymentgateway?.website}</a>
                    </span>}
                    {paymentgateway?.tel && <span className='text-white me-2 d-flex align-items-center'>
                        <FaPhoneAlt className='me-1' />
                        {paymentgateway?.tel}
                    </span>}
                </div>
                <div className="d-flex">
                    <MLButton options={{
                        code: "pg-addbranch",
                        color: COLORS.PRIMARY,
                        action: () => { setShow(true); setBranchShow(true); setBranchData({}); }
                    }} css="me-1" />
                    <MLButton options={{
                        code: "pg-editinfo",
                        action: () => modifyPGInfo(),
                    }} css="me-1" />
                    <MLButton options={{
                        label: "List",
                        color: COLORS.DARK,
                        action: () => history.push("/PaymentGateways"),
                    }} />
                </div>
            </div>
            <MLTable headers={labHeaders} noheader={true} items={pgs} />
        </div>
        <Modal show={show} size={"lg"} style={{ backgroundColor: "#42476c !important" }}>
            <Modal.Header className='modal-header header-bg'>
                <h3>{!branchShow ? "Edit" : "Add"} Branch Payment Gateway</h3>
            </Modal.Header>
            <Modal.Body className='flex-fill pt-0 formcard'>
                {!branchShow && <div>
                    <div className='fs-5 my-2'>
                        Payment Gateway Description
                    </div>
                    <div>
                        <MLText options={{
                            name: "desc",
                            value: data.desc,
                        }} handleChanged={(e) => setData({ ...data, desc: e.target.value })} />
                    </div>
                </div>}
                {branchShow && <div>
                    <div className='fs-6 my-2'>Branch</div>
                    <div>
                        <MLComboSingle options={{
                            name: "branch",
                            value: branchData.branch || "",
                            list: allBranch.map(x => {
                                return ({
                                    label: x.name,
                                    value: x._id,
                                });
                            }),
                        }} handleChanged={(e) => setBranchData({ ...branchData, branch: e.target.value })} />
                    </div>
                    <div className='fs-6 my-2'>Payment Cid</div>
                    <div>
                        <MLText options={{
                            name: "cid",
                            value: branchData.cid,
                        }} handleChanged={(e) => setBranchData({ ...branchData, cid: e.target.value })} />
                    </div>
                    <div className='fs-6 my-2'>Payment sKey</div>
                    <div>
                        <MLText options={{
                            name: "skey",
                            value: branchData.skey,
                        }} handleChanged={(e) => setBranchData({ ...branchData, skey: e.target.value })} />
                    </div>
                </div>}
                <div className='mt-4 text-end'>
                    <MLButton
                        options={{ label: "Cancel", color: COLORS.DEFAULT, action: () => { setShow(false); setData({}); } }}
                        css={'me-1'} />
                    <RTButton options={{
                        label: loading ? 'Please wait...' : branchShow ? 'Add' : 'Edit' + ' Branch Payment Gateway',
                        ing: loading,
                        needValid: true,
                        action: () => { branchShow ? createBranch() : updatePGInfo() }
                    }} css="me-1" />
                </div>
            </Modal.Body>
        </Modal>
        <MLModal
            modalData={modalConfirm}
            footerbuttons={[
                {
                    type: BUTTONS.NORMALBUTTON,
                    options: {
                        label: 'Cancel',
                        color: COLORS.DEFAULT,
                        action: () => setModalConfirm({ ...modalConfirm, show: false })
                    }
                },
                {
                    options: {
                        label: 'Publish',
                        color: COLORS.DANGER,
                        action: () => confirmPublish()
                    }
                }
            ]} />
    </div>
}

const mapState = (state) => {
    const user = state.AuthReducer.user;
    const allgroups = state.ProgramReducer.allgroups;
    const paymentgateways = state.PaymentReducer.paymentgateways;
    const paymentgateway = state.PaymentReducer.paymentgateway;
    const page = state.ProgramReducer.page;

    return { user, allgroups, paymentgateways, paymentgateway, page };
};

const mapDispatch = (dispatch) => ({

});

export default connect(mapState, null)(PaymentGatewayInfoPage);
