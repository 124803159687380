import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { MLPanelLoading } from '../components/common/MLPanel';
import MLTable from '../components/common/MLTable';
import { AWSUserListAdmin } from '../libs/aws';
import { AuthConstant, getAPI, getHeader } from '../redux/reducers/AuthReducer';
import RMModalAddAdmin from '../components/admin/RMModalAddAdmin';
import RMModalAdminUpdate from '../components/admin/RMModalAdminUpdate';
import { MLButton } from '../components/common/MLButton';
import { BUTTONS, COLORS, INPUTS, SPAN } from '../constants/FromInputs';
import { ProgramConstant } from '../redux/reducers';
import { useTranslation } from 'react-i18next';
import { MLText } from '../components/common/MLInput';
import { history } from '../history';
import { callDB } from '../libs/db';

const AdminUserPage = ({ adminUsers, admintype, loginuser }) => {
    const dispatch = useDispatch();
    const [t] = useTranslation(['translation']);
    const [search, setSearch] = useState("");
	const [items, setItems] = useState([]);
    const [showAll, setShowAll] = useState(true);
    const [loading, setLoading] = useState(true);
    const [modalAdd, setModalAdd] = useState({ show : false });
    const [modalUpdate, setModalUpdate] = useState({ show : false });

    const region = "ap-northeast-2";
    
    useEffect(() => {
        if(location.href.indexOf(".rayteams.com") > -1 ){
            if( loginuser?.sub !== '92b61348-7e38-4ecc-9f1a-7ef298816126'){
                history.push('/');
            }
        }
    }, [loginuser?.sub]);

    useEffect(() => {
		dispatch({ type : ProgramConstant.SET_PAGE, page : { 
			code : "account", 
			view : "summary", 
			title : "Administrator", 
			small : t("관리 계정 정보"), 
			options : [
			]
		}})
        getData();
    }, []);

    useEffect(() => {
        genData();
    }, [search, adminUsers, showAll]);

    const headers = [
        { link : "sub", value : "name", linkpath : "/AdminUserInfo", label : t("Name"), css : "text-start", style : { width : 200 } },
        { value : "email", label : t("Email"), css : "text-start" },
        { value : "memo", label : t("Memo"), css : "text-start" },
        { value : "showuser", label : t("개인정보 열람"), css : "text-center", type : "TFVALUE", style : { width : 140 }  },
        { value : "privacy", label : t("개인정보 관련자"), css : "text-center", type : "TFVALUE" , style : { width : 140 } },
        { value : "lastlogged", label : t("최근 로그인일시"), css : "text-center", type : "DATE", style : { width : 200 } }
    ]

    const getData = async () => {
        try {
            var list = [];
            var ret = await AWSUserListAdmin(region);
            ret.Items.map((x) => {
                list.push({
                    sub : x.Attributes.filter(y => y.Name == 'sub')[0].Value,
                    name : x.Attributes.filter(y => y.Name == 'name')[0].Value,
                    email : x.Attributes.filter(y => y.Name == 'email')[0].Value,
                    region : x.Attributes.filter(y => y.Name == 'custom:regions')[0]?.Value,
                    type : x.Attributes.filter(y => y.Name == 'custom:type')[0]?.Value === 'manager',
                })
            })
            const userDB = await getUserDB();
            const newList = list.map(u => {
                const ud = userDB.find(x => x._id === u.sub);
                if(ud){
                    return {...u, ...ud, showuser : ud.menu?.includes('users')};
                }
                return u;
            })
            dispatch({ type : AuthConstant.GET_ADMIN_USERS, items : newList });
        } catch {
            dispatch({ type : AuthConstant.GET_ADMIN_USERS, items : [] });
        }
        setLoading(false);
    }

    const genData = () => {
        if(adminUsers?.length > 0){
            setItems(search ? adminUsers
                .filter(x => x.name.indexOf(search) > -1 || x.email.indexOf(search) > -1)
                .filter(x => !showAll ? x.privacy : true) : adminUsers.filter(x => !showAll ? x.privacy : true));
        }else{
            setItems([]);
        }
    }

    const getUserDB = async () => {
		try{
			const ret = await callDB({
				type : "scan",
				region : "ap-northeast-2",
				params : {
					TableName: "rayteams-manager-user",
					FilterExpression: "#sk = :sk",
					ExpressionAttributeNames: { "#sk": "sk" },
					ExpressionAttributeValues: { ":sk" : 'info' }
				},
				filter : {}
			});
            return ret;
		}catch(err){
			console.log(err);
		}
        return [];
    }
        
	const showUpdate = (u) => {
		setModalUpdate({
            ...modalUpdate,
			show: true,
            regionList: u?.region,
            name: u?.name,
            email: u?.email
		});
	}

    return <div className='p-4'>
        <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
				<div className="card-label fw-bold text-gray-800 fs-5">{items && items?.length}</div>
				<div className="d-flex">
                    {<MLButton options={{
                        color : COLORS.PRIMARY,
                        size : "sm",
                        label : showAll ? t("전체") : t("개인정보 관련자만"),
                        action : () => setShowAll(!showAll)
                    }} css="me-1"/>}

                    <MLText options={{
                        placeholder : t("검색어 입력"),
                        size : "sm",
                        value : search,
                    }} handleChanged={(e) => setSearch(e.target.value)} css="mb-0" />
                
                    {admintype === "manager" && <MLButton options={{
                        code: "ac-addadmin", 
                        color : COLORS.DARK,
                        size : "sm",
                        label : t("Admin User 추가"),
                        action : () => setModalAdd({ show : true })
                    }} css="ms-3"/>}
                
				</div>
			</div>
        </div>
        {!loading && <MLTable 
            headers={headers} 
            items={items} 
            noheader={true} 
            callback={(u) => showUpdate(u)} />}
        {loading && <MLPanelLoading />}
        <RMModalAddAdmin callbackClose={() => {setModalAdd({show : false}); getData();}} modalData={modalAdd} />
        <RMModalAdminUpdate callbackClose={() => {setModalUpdate({show : false}); getData();}} modalData={modalUpdate} />
    </div>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
    const admintype = localStorage.getItem('type');
    const adminUsers = state.AuthReducer.adminUsers;
	const groups =  state.AuthReducer.groups;
	const licenses =  state.AuthReducer.licenses;
    const loginuser = state.AuthReducer.loginuser;
	return { user, adminUsers, groups, licenses, admintype, loginuser };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, null)(AdminUserPage);
