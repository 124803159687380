export const MSGAlarm = {
	RAY_LINK_PROJECT: "RAY_LINK_PROJECT",
	RAY_LINK_PROJECT_CREATED : "RAY_LINK_PROJECT_CREATED",
	RAY_LINK_PROJECT_UPDATED : "RAY_LINK_PROJECT_UPDATED",
	RAY_LINK_PROJECT_DOWNLOAD : "RAY_LINK_PROJECT_DOWNLOAD",
	RAY_LINK_PROJECT_REMOVED : "RAY_LINK_PROJECT_REMOVED",

	RAY_LINK_PROJECT_TRASH : "RAY_LINK_PROJECT_TRASH",
	RAY_LINK_PROJECT_RESTORE : "RAY_LINK_PROJECT_RESTORE",
	RAY_LINK_PROJECT_COMPLETE : "RAY_LINK_PROJECT_COMPLETE",
	RAY_LINK_PROJECT_ACTIVE : "RAY_LINK_PROJECT_ACTIVE",

	RAY_LINK_PROJECT_ADDMEMO : "RAY_LINK_PROJECT_ADDMEMO",
	RAY_LINK_PROJECT_UPDATEMEMO : "RAY_LINK_PROJECT_UPDATEMEMO",
	RAY_LINK_PROJECT_REMOVEMEMO : "RAY_LINK_PROJECT_REMOVEMEMO",
	RAY_LINK_PROJECT_ADDATTACHEMENT : "RAY_LINK_PROJECT_ADDATTACHEMENT",
	RAY_LINK_PROJECT_REMOVEATTACHEMENT : "RAY_LINK_PROJECT_REMOVEATTACHEMENT",

	RAY_LINK_PROJECT_ADDCOMMENT : "RAY_LINK_PROJECT_ADDCOMMENT",
	RAY_LINK_PROJECT_UPDATECOMMENT : "RAY_LINK_PROJECT_UPDATECOMMENT",
	RAY_LINK_PROJECT_REMOVECOMMENT : "RAY_LINK_PROJECT_REMOVECOMMENT",

	RAY_LINK_PROJECT_STATUS: "RAY_LINK_PROJECT_STATUS",
	RAY_LINK_PROJECT_CONFIRM: "RAY_LINK_PROJECT_CONFIRM",

	//order...
	RAY_LINK_ORDER_REMOVED : "RAY_LINK_ORDER_REMOVED",
}