import axios from 'axios';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { MLButton } from '../../components/common/MLButton';
import RMGroup from '../../components/manager/RMGroup';
import UserSub from '../../components/subscriptions/UserSub';
import UserPay from '../../components/payments/UserPay';
import { COLORS } from '../../constants/FromInputs';
import { history } from '../../history';
import { COUNTRIES } from '../../constants/Country';
import { FaPowerOff, FaPlay } from 'react-icons/fa';
import { MLPanelLoading } from '../common/MLPanel';
import MLUser from '../common/MLUser';
import { AuthConstant } from '../../redux/reducers';
import { idData } from '../../libs/db';
import { getAPI } from '../../redux/reducers/AuthReducer';
import { useTranslation } from 'react-i18next';
import ApproveUserModal from './ApproveUserModal';
import UserManagement from './UserManagement';
import { GetUserInfo } from '../../libs/aws';
import { Store } from '../../store';

const RMUserInfo = ({ userid, groups, config }) => {
    const dispatch = useDispatch();
    const [t] = useTranslation(['translation']);
    const { _id, tab = "info" } = useParams();
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState([]);
    const [info, setInfo] = useState({});
    const [showApproveModal, setShowApproveModal] = useState(false);
    const hideUser = useMemo(() => typeof (config?.showalludata) === "boolean" ? !config?.showalludata : true, [config?.showalludata]);
    const perms = Store.getState().AuthReducer?.loginuser?.perms || [];

    const tabs = process.env.REACT_APP_COMPANY === 'graphy' ? [
        {
            value: "info", label: t("Info")
        },
        { value: "danger", label: t("Management"), align: 'right', code: "user-manage" }
    ] : [
        { value: "info", label: t("Info") },
        { value: "subscriptions", label: t("Subscriptions"), code: "user-sub" },
        { value: "paylogs", label: t("Paylogs"), code: "user-pay" },
        { value: "danger", label: t("Management"), align: 'right', code: "user-manage" }
    ]

    useEffect(() => {
        getData_dUsers();
        getUserInfo();
    }, [_id]);

    const getUserInfo = async () => {
        const u = await GetUserInfo(_id);
        setUser(u);
    }

    const getData_dUsers = async () => {
        setLoading(true);
        try {
            const ret = await idData("rayteams-user", _id);
            var list = [];
            ret.Items.map(x => {
                list.push({ ...x, groupname: groups.filter(y => y._id === x.groupId)[0]?.name })
            })
            setInfo(list.find(x => x.sk === "info"));
            dispatch({ type: AuthConstant.GET_REGION_USER, items: list });
        } catch (e) {
            dispatch({ type: AuthConstant.GET_REGION_USER, items: "" });
        }
        setLoading(false);
    }

    const genData = async () => {
        console.log(regionusers.filter(x => x._id === _id)[0])
        setInfo(regionusers.filter(x => x._id === _id)[0])
        setLoading(false)
        // setInfo(rayteam.find(x => x.sk === "info") || {});
        // setDevice(rayteam.find(x => x.sk.indexOf("device:") > -1) || {});
    }

    const handlerClickApproved = () => {
        setShowApproveModal(true);
    };

    const callbackRefresh = useCallback(() => {
        getData_dUsers();
    }, []);

    return <>
        <div className='p-4'>
            {loading && <>
                <MLPanelLoading />
            </>}
            {!loading && (info?._id === _id) && <>
                <div className='card'>
                    <div className='card-header pb-0'>
                        <div className='d-flex justify-content-between'>
                            <div>
                                <h3 className='mb-0'>
                                    <div className="d-flex align-items-center">
                                        {user.Enabled && <span className="me-2 fs-5 badge bg-primary">{t('Activated')}</span>}
                                        {!user.Enabled && <span className="me-2 fs-5 badge bg-warning">{t('Deactivated')}</span>}
                                        <MLUser _id={info?._id} hide={hideUser} />
                                    </div>
                                    <span className='text-muted fs-6'>
                                        {t("Last Logged")}: {moment(info.lastlogged).format('LL')}
                                    </span>
                                </h3>
                                <span className='text-muted fs-6'> {info?._id} </span>
                            </div>
                            <div>
                                <MLButton options={{
                                    label: t("List"),
                                    sm: "sm",
                                    color: COLORS.DARK,
                                    action: () => history.push("/UsersPage"),
                                }} css="me-1" />
                            </div>
                        </div>

                        <ul className="nav nav-tabs nav-stretch border-top mt-4 pt-2" style={{ borderRadius: "0" }}>
                            {tabs.map((x, idx) => {
                                if (x.value === "info" || perms.includes(x.code)) {
                                    return <li className="nav-item" key={idx} onClick={() => history.push("/UsersInfo/" + info?._id + "/" + x.value)}>
                                        <Link to="#" className={"nav-link " + (tab === x.value ? ' active ' : '')}>{t(x.label)}</Link>
                                    </li>
                                }
                                return null;
                            })}
                        </ul>
                        
                    </div>
                    <div className="card-body p-0">
                        {info?._id && <div>
                            {tab === "info" && <div className='p-4'>
                                {config?.approvedaccountonly && <>
                                    <div className='pb-2'>
                                        <div className='label fw-bolder'>{t("Account Approved")}</div>
                                        <div className=''><span>
                                            {!info?.approved && <>
                                                <div>
                                                    <div className='text-danger'>{t("This account is not approved.")}</div>
                                                    <MLButton
                                                        options={{
                                                            code: "user-approvedaccount",
                                                            label: t("To Approved"),
                                                            color: "primary",
                                                            size: "sm",
                                                            action: handlerClickApproved,
                                                        }}
                                                    />
                                                </div>
                                            </>}
                                            {info?.approved && <>
                                                <div className='d-flex align-items-center gap-1'>
                                                    <div>{t("Approved")}</div>
                                                    <div>{moment(info?.approved).format("LLL")}</div>
                                                </div>
                                            </>}
                                        </span></div>
                                    </div>
                                </>}
                                <div className='pb-2'>
                                    <div className='label fw-bolder'>{t("Status")}</div>
                                    <div className=''><span>
                                        {!info?.needConfirm && t("Acitve")}
                                        {info?.needConfirm && t("Wait confirm")}
                                    </span></div>
                                </div>
                                <div className='py-2'>
                                    <div className='label fw-bolder'>{t("Group Name")}</div>
                                    <div className=''><RMGroup groupId={info.groupId} /></div>
                                </div>
                                {info?.grouptype?.length > 0 && <div className='py-2'>
                                    <div className='label fw-bolder'>{t("Institutes")}</div>
                                    <div className=''><span>{info?.grouptype.map(x => x.toUpperCase()).join(", ")}</span></div>
                                </div>}
                                {info?.countryCode && <div className='py-2'>
                                    <div className='label fw-bolder'>{t("Country")}</div>
                                    <div className=''><span>{COUNTRIES.find(x => x.countryCode === info?.countryCode)?.name}</span></div>
                                </div>}
                                <div className='py-2'>
                                    <div className='label fw-bolder'>{t("User Type")}</div>
                                    <div className=''><span>{info?.userType || "Not assgined"}</span></div>
                                </div>
                                <div className='pt-2'>
                                    <div className='label fw-bolder'>{t("Registered")}</div>
                                    <div className=''><span>{moment(info?.created).format("LLL")}</span></div>
                                </div>
                            </div>}
                            {tab === "subscriptions" && <UserSub _id={info?._id} />}
                            {tab === "paylogs" && <UserPay _id={info?._id} />}
                            {tab === "danger" && <UserManagement _id={info?._id} callback={() => getUserInfo()} />}
                        </div>}
                    </div>

                    {/* {tabs.filter(x => perms.includes(x.code)).length == 0 && <div className="card-body text-center fs-4">
                        <div className='py-5'>{t('권한이 없습니다.')}</div>
                    </div>} */}
                </div>
            </>}
        </div>
        <ApproveUserModal
            _id={_id}
            user={info}
            show={showApproveModal}
            setShow={setShowApproveModal}
            callbackRefresh={callbackRefresh}
        />
    </>
}

const mapState = (state) => {
    const regionusers = state.AuthReducer.regionusers;
    const regionuser = state.AuthReducer.regionuser;
    const groups = state.AuthReducer.groups;
    const config = state.ProgramReducer.config;

    return { regionusers, regionuser, groups, config };
};

const mapDispatch = (dispatch) => ({

});

export default connect(mapState, mapDispatch)(RMUserInfo);
