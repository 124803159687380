import { Store } from '../../store';
import { ProgramConstant, AlertConstant } from '../reducers';
import { callDB } from '../../libs/db';

const GetAllDevices = (filter) => async (dispatch) => {
    const groups = Store.getState().AuthReducer.groups;
    try {
        const ret = await callDB({
            type : "scan",
            region : "ap-northeast-2",
            params : {
                TableName: "rayteams-device",
                FilterExpression: "#sk = :sk",
                ExpressionAttributeNames: { "#sk": "sk" },
                ExpressionAttributeValues: { ":sk": "info" }
            },
            filter : filter
        });
        const retP = await callDB({
            type: "scan",
            region: "ap-northeast-2",
            params: {
                TableName: "rayteams-partner",
                FilterExpression: "#sk = :sk",
                ExpressionAttributeNames: { "#sk": "sk" },
                ExpressionAttributeValues: { ":sk": "info" }
            },
            filter: {}
        })
        if (ret) {
            var list = [];
            ret.map(x => {
                list.push({
                    ...x, 
                    partnerKey : x.partnerKey || "ray",
                    labname : groups.find(y => y._id === x.labId)?.name,
                    clinicname : groups.find(y => y._id === x.ownerId)?.name,
                    region : groups.find(y => y._id === x.ownerId)?.region,
                    countryCode : groups.find(y => y._id === x.ownerId)?.countryCode,
                    partnername : retP && retP.find(x => x._id === x.partnerId)?.name,
                })
            });
            dispatch({ type: ProgramConstant.ALLDEVICE, items : list });
        }
    } catch (e) {
        dispatch({ type: AlertConstant.ERROR, msg: e.message, show: true });
        dispatch({ type: ProgramConstant.ALLDEVICE, items : [] });
    }
}

const GetDeviceByID = (_id) => async (dispatch) => {
    try {
        const ret = await callDB({
            type : "query",
            region : "ap-northeast-2",
            params : {
                TableName: "rayteams-device",
                KeyExpression: "#_id = :_id and #sk = :sk",
                ExpressionAttributeNames: { 
                    "#_id": "_id",
                    "#sk": "sk" 
                },
                ExpressionAttributeValues: { 
                    ":_id": _id,
                    ":sk": "info" 
                }
            },
            filter : filter
        });

        if (ret) {
            dispatch({ type: ProgramConstant.GETDEVICE, item : ret });
        }
    } catch (e) {
        dispatch({ type: AlertConstant.ERROR, msg: e.message, show: true });
        dispatch({ type: ProgramConstant.GETDEVICE, items : {} });
    }
}

export const DeviceAction = {
    GetAllDevices,
    GetDeviceByID
}