import axios from 'axios';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import { MLPanelLoading, MLPanelNoData, MLPanelNormalConfirm } from '../common/MLPanel';
import { AppAction } from '../../redux/actions';
import MLTable from '../common/MLTable';
import { JWT } from '../../config';
import { UsageConstant } from '../../redux/reducers';

const RMUsageList = ({ logs }) => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);

    useEffect(() => {
		getData();
	}, []);

    const getData = async () => {
		setLoading(true);
		try{
			const ret = await axios.get(AppAction.getAPI() + "/getlogs", JWT());
			if (ret.data.status === "success") {
				dispatch({ type: UsageConstant.GET_RAW , items: ret.data.data });
			}
		}catch(err){
			console.log(err);
		}
		setLoading(false);
    }

    const headers = [
        { value : "_id", label : "App", type : "TEXT", style : { width : 200 } },
		{ value : "sk", label : 'sk'},
        { value : "created", label : "Called", type : "TIMESPAN", style : { width : 150 } },
    ]

  	return <>
		{!loading && <div className='card'>
			<div className='card-body'>
				<MLTable 
					items={logs} 
					headers={headers} 
					rowkey={"sk"} 
					nocard={true}  />
			</div>
		</div>}
		{loading && <MLPanelLoading />}
	</>
}

const mapState = (state) => {
    const logs = state.UsageReducer.logs;
	const dailylogs =  state.UsageReducer.dailylogs;
	return { logs, dailylogs };
};

export default connect(mapState, null)(RMUsageList);