import axios from 'axios';
import { JWT } from '../../config';
import { WFConstant } from '../reducers/WFReducer';
import { AlertConstant } from '../reducers';
import { fetchData, putData } from '../../libs/db';
import { InfoAction } from './InfoAction';

const { v4 } = require("uuid");

const getAPI = () =>{
	// if(process.env.NODE_ENV === "development"){
	// 	return "https://api-manager-order-development.rayteams.com";
	// }else{
	// 	return "https://api-manager-order.rayteams.com";
	// }
	return "https://api-manager-order-development.rayteams.com";
};

const Create = (data) => async (dispatch) => {
	try {	
		const time = new Date().getTime();
		const Item = {
			_id: v4(),
			sk: "wf",
			...data,
			created: time
		}
		const ret = await putData("rayteams-product", Item);
		dispatch({ type: WFConstant.CREATE, item: Item });
		dispatch({ type: AlertConstant.NOTICE, data: "Success" });
	} catch (error) {
		dispatch({ type: AlertConstant.ERROR, data: error });
	}
};

const Get = async (_id) => {
	try {
		const ret = await InfoAction.callDB({ type : "query", region : "ap-northeast-2", params : {
			TableName: "rayteams-product",
			KeyConditionExpression: "#_id = :_id and #sk = :sk",
			ExpressionAttributeNames: { "#_id": "_id", "#sk": "sk" },
			ExpressionAttributeValues: { ":_id": _id, ":sk": "wf" }
		}});
		return ret.length > 0 ? ret[0] : {};
	} catch (error) {
	}
}

const GetAll = async () => {
	try {
		const ret = await InfoAction.callDB({ type : "scan", region : "ap-northeast-2", params : {
			TableName: "rayteams-product",
			FilterExpression: "#sk=:sk ",
			ExpressionAttributeNames: { "#sk": "sk" },
			ExpressionAttributeValues: { ":sk": "wf" }
		}});
		return ret || [];
	} catch (error) {
	}
}

const Update = (data) => async (dispatch) => {
	console.log(data);
    const ret  = await InfoAction.callDB({ type : "update", region : "ap-northeast-2", params : {
        TableName: "rayteams-product",
        UpdateExpression: 'SET #flows = :flows, #title = :title, #wf = :wf, #updated = :updated',
        ExpressionAttributeNames: { '#flows' : 'flows', '#title' : 'title', '#wf' : 'wf', '#updated' : 'updated' },
        ExpressionAttributeValues: { ':flows' : data.flows, ':title' : data.title, ':wf' : data.wf, ':updated' : new Date().getTime() },
        Key: { _id: data._id, sk: data.sk }
    }});
	if (ret) {
		dispatch({ type: AlertConstant.NOTICE, msg: "Success", show : true });
	} else {
		dispatch({ type: AlertConstant.ERROR, data: ret.data.err, msg : "Error", show : true });
	}
};

export const WFAction = {
    getAPI,
	Get,
	Create,
	Update,
	GetAll
};