import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { GrClose } from 'react-icons/gr';
import { connect } from 'react-redux';
import { MLButton } from './MLButton';

const MLModal = ({ modalData, CloseModal, formvalid, footerbuttons, sizecss = "modal-fullscreen", sidebar, CloseSidebarModal }) => {

	return <Modal show={modalData.show} size={"lg"} style={{ backgroundColor: "#42476c !important"}}>
		<Modal.Header className='modal-header header-bg'>
			{modalData.title && <h2>{modalData.title}
				<small className={"ms-2 fs-7 fw-normal opacity-50 "}>{modalData.description && modalData.description}</small>
			</h2>}
		</Modal.Header>
		<Modal.Body className='flex-fill pt-0 formcard pt-6'>
			{modalData.body}
			{footerbuttons && <div className='mt-4 text-end'>
				{footerbuttons.map((x, idx) => <MLButton
					valid={x.options.needValid ? formvalid : true}
					options={x.options}
					key={idx} css={'me-1'} />)}
			</div>}
		</Modal.Body>
	</Modal>
}

const mapState = (state) => {
	return {  };
};

const mapDispatch = dispatch => ({
})

export default connect(mapState, mapDispatch)(MLModal);