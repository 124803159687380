import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { history } from '../../history';
import { InfoAction, ProductAction, WFAction } from '../../redux/actions';
import { GetConfig } from '../../libs/appConfig';
import { BUTTONS, COLORS, INPUTS, SPAN } from '../../constants/FromInputs';
import { ProgramConstant } from '../../redux/reducers';
import { MLForm } from '../common/MLForm';
import { PRICEUNIT } from '../../constants/RMConstants';
import { FormAction } from '../../redux/actions/FormAction';
import { MLButton } from '../common/MLButton';
import { MLComboSingle } from '../common/MLInput';
import { RTAudit } from '../../libs/audit';
import { MLPanelNoData } from '../common/MLPanel';

const LabProductConsent = ({ readonly = false }) => {
	const dispatch = useDispatch();

	const [t] = useTranslation(['translation']);
	const { _id } = useParams("");
	const [data, setData] = useState([]);
	const [forms, setForms] = useState([]);
	const [addFormShow, setAddFormShow] = useState(false);


	useEffect(() => {
		init();
	}, []);

	const init = async () => {
		const ret = await InfoAction.callDB({
			type: "scan", region: "ap-northeast-2", params: {
				TableName: "rayteams-manager-contents",
				FilterExpression: "#sk = :sk and #type = :type",
				ExpressionAttributeNames: { "#sk": "sk", "#type": "type" },
				ExpressionAttributeValues: { ":sk": 'info', ":type": 'CONSENT' }
			}
		});
		setForms(ret.map(x => ({ ...x, value: x._id, label: x.title })));
		if (_id) {
			getData();
		}
	}

	const getData = async () => {
		const ret = await InfoAction.callDB({
			type: "query", region: "ap-northeast-2", params: {
				TableName: "rayteams-product",
				KeyConditionExpression: "#_id = :_id and #sk = :sk",
				ExpressionAttributeNames: { "#_id": "_id", "#sk": "sk" },
				ExpressionAttributeValues: { ":_id": _id, ":sk": "info" },
			}
		});
		if (ret.length > 0)
			setData(ret[0].consents || []);
	}

	const update = async (formIds) => {
		await InfoAction.callDB({
			type: "update", region: "ap-northeast-2", params: {
				TableName: "rayteams-product",
				UpdateExpression: 'SET #consents = :consents, #updated = :updated',
				ExpressionAttributeNames: {
					'#consents': 'consents',
					'#updated': 'updated'
				},
				ExpressionAttributeValues: {
					':consents': formIds,
					':updated': new Date().getTime()
				},
				Key: { _id: _id, sk: 'info' }
			}
		});
		setData(formIds);
		RTAudit({ lvl: 5, msg: 'Add Consent' })
	}

	return <>
		<div className='d-flex justify-content-between align-items-center mb-2'>
			<div className='fw-bolder'>{t("주문시 동의")}</div>
			{!addFormShow && <MLButton options={{
				code: "prod-addconsent",
				label: t("Add Consent"),
				color: 'primary',
				size: 'sm',
				action: () => setAddFormShow(true),
			}} />}
		</div>
		<div className='card'>
			{addFormShow && <div className='card-body'>
				<MLComboSingle options={{
					list: forms.filter(x => !data.includes(x.value))
				}} handleChanged={(e) => update([...data, e.target.value])} />
				<MLButton options={{
					label: t("취소"),
					color: 'light',
					action: () => setAddFormShow(false)
				}} />
			</div>}
			{data.length > 0 && <ul className='list-group'>
				{data.map((x, idx) => <li className='list-group-item d-flex justify-content-between' key={idx}>
					{forms.find(f => f.value === x)?.label}
					<MLButton options={{
						code: "prod-cancelconsent",
						label: t("취소"),
						size: "sm",
						color: 'danger',
						action: () => update(data.filter(f => f !== x))
					}} />
				</li>)}
			</ul>}
			{data.length === 0 && <MLPanelNoData />}
		</div>
	</>

}

const mapState = (state) => {
	return {};
};

const mapDispatch = (dispatch) => ({

});

export default connect(mapState, null)(LabProductConsent);
