import { useCallback, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { InfoAction } from "../../redux/actions";
import { Loader } from "../common/Loader";
import { MLButton } from "../common/MLButton";
import MLUser from "../common/MLUser";

const ApproveUserModal = ({ _id, user, show, setShow, callbackRefresh }) => {
    const [t] = useTranslation();
    const [loading, setLoading] = useState(false);

    const handlerClickApprove = useCallback(async () => {
        try {
            setLoading(true);
            const payload = {
                type: "update",
                region: user?.region || "ap-northeast-2",
                params: {
                    TableName: "rayteams-user",
                    Key: {
                        _id: user._id,
                        sk: "info",
                    },
                    UpdateExpression: "SET #approved = :approved",
                    ExpressionAttributeNames: {
                        "#approved": "approved",
                    },
                    ExpressionAttributeValues: {
                        ":approved": new Date().getTime(),
                    },
                },
            };
            await InfoAction.callDB(payload);
            setShow(false);
            callbackRefresh && callbackRefresh();
        } catch (error) {
            console.log("ERROR[handlerClickApprove]", error?.response?.data || error?.toString());
        } finally {
            setLoading(false);
        }
    }, [user?.region, user._id]);

    return <>
        <Modal
            show={show}
            size={"lg"}
            onHide={() => setShow & setShow(false)}
            centered={true}
        >
            <Modal.Header className='modal-header header-bg'>
                <h2>{t("유저 승인")} <MLUser _id={user?._id} hide={true} /></h2>
            </Modal.Header>
            <Modal.Body className='flex-fill pt-0 formcard pt-6'>
                {loading && <Loader />}
                {!loading && <>
                    <div>
                        <div>
                            {t("이 유저의 상태를 서비스 사용 상태로 변경하시겠습니까?")}
                        </div>
                    </div>
                </>}
            </Modal.Body>
            <Modal.Footer>
                <MLButton
                    options={{
                        label: t("Cancel"),
                        action: () => setShow(false),
                    }}
                />
                <MLButton
                    options={{
                        label: t("Approve"),
                        color: "primary",
                        action: handlerClickApprove,
                    }}
                />
            </Modal.Footer>
        </Modal>
    </>
};

export default ApproveUserModal;