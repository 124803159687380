import { connect, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { BUTTONS, COLORS, INPUTS, SPAN } from '../constants/FromInputs';
import { MLButton } from '../components/common/MLButton';
import RMContentInfoPaenl from '../components/board/RMContentInfoPaenl';
import { insertData, updateData, fetchData, delOneData, callDB } from '../libs/db';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import RMBoardTabs from '../components/board/RMBoardTabs';
import User from '../components/common/MLUser';
import { Editor } from '@tinymce/tinymce-react';
import { history } from '../history';
import { useTranslation } from 'react-i18next';
import { RTAudit } from '../libs/audit';

const UserContentPage = ({ user, usercontents, callbackClose }) => {
    const [t] = useTranslation(['translation']);
	const dispatch = useDispatch();
	const { _cid, _sk } = useParams();
	const [data, setData] = useState({});
	const [content, setContent] = useState({});
	const [comments, setComments] = useState([]);
	const [board, setBoard] = useState({});
    const usersub = localStorage.getItem("usersub");

	useEffect(() => {
		getData();
	}, []);

	useEffect(() => {
        
		if(content?.bid)
			getBoard();
	}, [content]);

	const getBoard = async () => {
        if(!content.bid)
            return;
        // const _items = await callDB({
        //     type: "query",
        //     region: "ap-northeast-2",
        //     params: {
        //         TableName: "rayteams-manager-boards",
        //         KeyConditionExpression: "#id = :id",
        //         ExpressionAttributeNames: { "#id": "_id" },
        //         ExpressionAttributeValues: {  ":id": content.bid },
        //     }
        // });
        // if (_items?.length > 0) {
        //     setBoard(_items[0]);
        // }
        const ret = await fetchData("rayteams-manager-boards", {
            _id: {
                ComparisonOperator: "EQ",
                AttributeValueList: [content.bid],
            }
        });
        if(ret.Count > 0){
            setBoard(ret.Items[0]);
        }
	}

	const getData = async () => {
        // try {
        //     const targetSk = _sk === "info" ? "info" : "lang:" + _sk;
        //     const _items = await callDB({
        //         type: "query",
        //         region: "ap-northeast-2",
        //         params: {
        //             TableName: "rayteams-manager-contents",
        //             KeyConditionExpression: "#id = :id and #sk = :sk",
        //             ExpressionAttributeNames: { "#id": "_id", "#sk": "sk" },
        //             ExpressionAttributeValues: {  ":id": _cid, ":sk": targetSk },
        //         },
        //     });
        //     if (!_items || _items?.length === 0) {
        //         alert("Not exist");
        //         return;
        //     }
        //     const item = _items[0];
        //     console.log("item", item);
        //     setContent({ ...item })
        //     if (item.isDel) {
        //         setContent(prev => ({
        //             ...prev,
        //             title: <strike>{t("삭제된 글입니다.")}</strike>,
        //             content: <p>{t("삭제된 글입니다.")}</p>
        //         }));
        //     }    
        // } catch (error) {
        //     console.log("ERROR[UserContentPage@getData]", error?.toString());
        // }
        

        const ret = await fetchData("rayteams-manager-contents", {
            _id: {
                ComparisonOperator: "EQ",
                AttributeValueList: [_cid],
            }
        });
		try{
            if(ret.Count > 0){
                const con = ret.Items.filter(x => x.sk === (_sk === "info" ? "info" : "lang:" + _sk))[0];
                setContent(con.isDel > 0 ? {...con,
                    title : con.isDel > 0 ? <strike>{t("삭제된 글입니다.")}</strike> : con.title,
                    content : con.isDel > 0 ? <p>{t("삭제된 글입니다.")}</p> : con.content,
                } : con);
                setComments(ret.Items.filter(x => x.sk.indexOf( "re:") > -1));
            }else{
                alert("Not exist");
            }
		}catch(err){
			console.log(err);
		}
	}

	const edit = () => {
        history.push("/UserContentAdd/" + content.bid + "/" + _cid + "/" + _sk);
        RTAudit({ lvl: 4, msg: 'Edit User Contents'}, JSON.stringify({ subject: content.bid }));
	}

	const addComment = async () => {
        const time = new Date().getTime();
        const newcomment = {...data,
            _id: _cid,
			sk: "re:" + time,
			bid: content.bid,
			created: new Date().getTime(),
			creator: usersub,
        };
        const ret = await insertData("rayteams-manager-contents", newcomment);
        setComments([...comments, newcomment]);
        setData({ content : "" });
        //history.push("/UserContentAdd/" + content._bid + "/" + _cid);
        RTAudit({ lvl: 4, msg: 'Add Comment'}, JSON.stringify({ subject: data }));
	}

    const delComment = async(x) => {
        const ret = await delOneData("rayteams-manager-contents", x._id, x.sk);
        setComments([...comments.filter(n => n.sk != x.sk)]);
        //history.push("/UserContentAdd/" + content._bid + "/" + _cid);
        RTAudit({ lvl: 4, msg: 'Delete Comment'}, JSON.stringify({ subject: data }));
    }

	const del = async () => {
        await delOneData("rayteams-manager-contents", _cid, content.sk);
        history.push("/Content/" + board._id + "/" + _cid);
        RTAudit({ lvl: 4, msg: 'Delete User Contents'}, JSON.stringify({ subject: board._id }));
	}

	return <div className='p-4'>
		<div className="card">
			<div className="card-header d-flex justify-content-between align-items-center">
				<div className="card-label fw-bold text-gray-800">{board.title}</div>
				<div className="d-flex">
                    {usersub && <div className="d-flex align-items-center">
                        {!(window.location.href.indexOf("/UserContentAdd") > -1) && <MLButton options={{
                            label : t('글 등록'),
                            color : COLORS.PRIMARY,
                            size: 'md',
                            action : () => history.push('/UserContentAdd/' + board._id + "/" + _cid),
                        }} />}
                        {(window.location.href.indexOf("/UserContentAdd") > -1) && <MLButton options={{
                            label : t('목록'),
                            color : COLORS.DARK,
                            size : 'md',
                            action : () => history.push('/Content/' + _cid),
                        }} />}
                    </div>}
				</div>
			</div>
        </div>
        <RMContentInfoPaenl />
        <div className='card mt-4'>
            <div className="card-header border-bottom">
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <h3 className='mb-1'>{content.title}</h3>
                        <span className='me-1'>{t("Created")} : {moment(content.created).format("LLL")}</span>
                        {content.updated && <span className='me-1'> / {t("Updated")} : {moment(content.updated).format("LLL")}</span>}
                    </div>
                    <div className="d-flex">
                        {!content.isDel && <MLButton options={{
                            code: "cm-edit",
                            label : t('Edit'), 
                            color : COLORS.INFO,
                            action : () => edit()
                        }} css="me-1" />}
                        {content.sk !== "info" && <MLButton options={{
                            code: "cm-del",
                            label : t('Delete'), 
                            color : COLORS.DANGER,
                            action : () => del()
                        }} css="me-1" />}
                        <MLButton options={{
                            label : t('List'), 
                            color : COLORS.DARK,
                            action : () => history.push('/Content/' + board._id + "/" + _cid),
                        }} />
                    </div>
                </div>
            </div>
            <div className='card-body pt-4'>
                {!content.isDel && <div dangerouslySetInnerHTML={ {__html: content.content} }></div>}
                {content.isDel && <div><strike>{t("삭제된 글입니다.")}</strike></div>}
            </div>
        </div>
    </div>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const region =  state.AuthReducer.region;
	const board =  state.BoardReducer.board;
	const content =  state.BoardReducer.content;
	const usercontents =  state.BoardReducer.usercontents;
	return { region, user, content, board, usercontents };
};

const mapDispatch = dispatch => ({
})

export default connect(mapState, mapDispatch)(UserContentPage);
