import { useEffect, useState } from "react";
import { connect, useDispatch } from 'react-redux';
import { getRandomColors } from "../../libs/colors";
import { RiProjectorLine } from 'react-icons/ri';
import MLBarChart from "../widgets/charts/MLBarChart";

const WidgetProjectSummary = ({ auth, projects }) => {
    const [chartData, setChartData] = useState(null);

    useEffect(() => {
        if(projects[0] && projects[0]._id) {
            getData();
        }
    }, [projects])

    const getData = async () => {
        var list = [];
        list.push({ label : "Active", count : projects.filter(x => !(x.istrash || x.isarchive || x.status?.text)).length });
        const customStatusList = projects.filter(x => x.status?.text && !(x.istrash || x.isarchive)).map(x => x.status?.text);
        const customList = [...new Set(customStatusList)];
        if(customList.length > 0){
            var customs = customList.map(x => { return { label : x, count : 0 }});
            customs.map(x => list.push({...x, count : projects.filter(p => p.status?.text === x.label && !(p.istrash || p.isarchive)).length}));
        }
        list.push({ label : "Completed", count : projects.filter(x => x.isarchive).length });
        list.push({ label : "Trash", count : projects.filter(x => x.istrash).length });
        const colorSet = getRandomColors(list.length, 0.8);

        setChartData({
            labels: list.map(x => {
                return x.label
            }),
            datasets: [{
                data: list.map(x => x.count),
                barThickness: 28,
                backgroundColor: colorSet.map(x => x.opacityColor)
            }]
        });
    }

    return <div className="card">
        <div className='card-header'>
            <h3 className='card-title'>
                <span className='card-label fs-5 fw-bolder'>Project Status</span>
                <span></span>
            </h3>
            <div className='card-toolbar text-muted fs-6'>
                <span className='badge badge-secondary'>
                    <RiProjectorLine style={{ width : 18, height : 18}} /> {projects.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </span>
            </div>
        </div>
        <div className='pt-0 card-body' style={{ height : (chartData?.labels?.length || 0) * 56}}>
            <MLBarChart data={chartData} chartOptions={{
                showLabels: false,
                hideGideLines: true,
            }} legendLocation='bottom' />
        </div>
    </div>
}

const mapState = (state) => {
    const projects = state.AuthReducer.projects;
    return { projects };
};

const mapDispatch = dispatch => ({
});

export default connect(mapState, mapDispatch)(WidgetProjectSummary);