import { connect, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { InfoAction } from '../../redux/actions';
import { DASHBOARD_GRAPTH_TYPES, DASHBOARD_GRAPTH_TYPES_B2B } from '../../constants/RMConstants';
import RMWidgetBarChart2 from '../widgets/RMWidgetBarChart2.';
import { useTranslation } from 'react-i18next';

const RMBigChart = ({ }) => {
    const [t] = useTranslation(['translation']);
	const dispatch = useDispatch();
    const [period, setPeriod] = useState("c");
    const [type, setType] = useState( (process.env.REACT_APP_COMPANY === 'ray' || !process.env.REACT_APP_COMPANY) ? DASHBOARD_GRAPTH_TYPES[0] : DASHBOARD_GRAPTH_TYPES_B2B[0]);
    const [chartData, setChartData] = useState({ list : [], xOptions : { xperiod : "days", dformat : "YYYY-MM-DD" } });

    useEffect(() => {
		updatePeriod();
    }, [period, type]);

    //    {(process.env.REACT_APP_COMPANY === 'ray' || !process.env.REACT_APP_COMPANY) && 
	const updatePeriod = async () => {
        let min, duration, xperiod, dformat;
		if(period === "a"){
			min =  moment().add(-7, "day").utc().valueOf();
            duration = 7;
            xperiod = "days";
            dformat = "YYYY-MM-DD";
		}else if(period === "c"){
			min =  moment().add(-30, "day").utc().valueOf();
            duration = 30;
            xperiod = "days";
            dformat = "YYYY-MM-DD";
		}else if(period === "d"){
			min =  moment().add(-90, "day").utc().valueOf();
            duration = 90;
            xperiod = "days";
            dformat = "YYYY-MM-DD";
		}else if(period === "e"){
			min =  moment().add(-365, "day").utc().valueOf();
            duration = 12;
            xperiod = "month";
            dformat = "YYYY-MM";
		}else if(period === "f"){
			min =  0;
            duration = 12;
            xperiod = "month";
            dformat = "YYYY-MM";
		}
        let list = await getList(min);

        setChartData({
            keyname : getKeyname(),
            list,
            xOptions : {
                duration,
                xperiod,
                dformat
            }
        })
	}

	const getKeyname  = () => {
        if(type.type === "user"){
            return "created";
        }else if(type.type === "case"){
            return "created";
        }else if(type.type === "group"){
            return "created";
        }else if(type.type === "device"){
            return "updated";
        }else if(type.type === "pay"){
            return "when";
        }else if(type.type === "sub"){
            return "started";
        }
    }
	const getList  = async (min) => {
        if(type.type === "user"){
            return await InfoAction.callDB({ type : "scan", region : "ap-northeast-2", params : {
                TableName: "rayteams-user",
                FilterExpression: "#sk=:sk and #created > :n",
                ExpressionAttributeNames: { "#sk": "sk", "#created": "created" },
                ExpressionAttributeValues: { ":sk": "info", ":n": min },
                ProjectionExpression: "sk, created"
            }});
        }else if(type.type === "case"){
            return await InfoAction.callDB({ type : "scan", region : "ap-northeast-2", params : {
                TableName: "rayteams-project",
                FilterExpression: "#sk=:sk and #created > :n",
                ExpressionAttributeNames: { "#sk": "sk", "#created": "created" },
                ExpressionAttributeValues: { ":sk": "project", ":n": min },
                ProjectionExpression: "sk, created"
            }});
        }else if(type.type === "group"){
            return await InfoAction.callDB({ type : "scan", region : "ap-northeast-2", params : {
                TableName: "rayteams-group",
                FilterExpression: "#sk=:sk and #created > :n",
                ExpressionAttributeNames: { "#sk": "sk", "#created": "created" },
                ExpressionAttributeValues: { ":sk": "info", ":n": min },
                ProjectionExpression: "sk, created"
            }});
        }else if(type.type === "device"){
            return await InfoAction.callDB({ type : "scan", region : "ap-northeast-2", params : {
                TableName: "rayteams-info",
                FilterExpression: "#sk=:sk and #updated > :n",
                ExpressionAttributeNames: { "#sk": "sk", "#updated": "updated" },
                ExpressionAttributeValues: { ":sk": "info", ":n": min },
                ProjectionExpression: "sk, updated"
            }});
        }else if(type.type === "pay"){
            return await InfoAction.callDB({ type : "scan", region : "ap-northeast-2", params : {
                TableName: "rayteams-user",
                FilterExpression: "begins_with(#sk, :sk) and #when > :n",
                ExpressionAttributeNames: { "#sk": "sk", "#when": "when" },
                ExpressionAttributeValues: { ":sk": "paylog:", ":n": min },
                ProjectionExpression: "sk, #when"
            }});
        }else if(type.type === "sub"){
            return await InfoAction.callDB({ type : "scan", region : "ap-northeast-2", params : {
                TableName: "rayteams-user",
                FilterExpression: "begins_with(#sk, :sk) and #started > :n",
                ExpressionAttributeNames: { "#sk": "sk", "#started": "started" },
                ExpressionAttributeValues: { ":sk": "product:", ":n": min },
                ProjectionExpression: "sk, started"
            }});
        }
    }

	
    return <div className='card' style={{ height : 500 }}>
		<div className='card-header'>
			<div className="d-flex align-items-center justify-content-between">
				<div className='fw-bolder'>{t("추이 그래프")}</div>
				<div>
					<span onClick={() => setPeriod("a")} className={"badge ms-1 cursor-pointer " + (period === "a" ? " bg-primary " : " bg-light text-gray ")}>{t("최근 7일")}</span>
					<span onClick={() => setPeriod("c")} className={"badge ms-1 cursor-pointer " + (period === "c" ? " bg-primary " : " bg-light text-gray ")}>{t("최근 한달")}</span>
					<span onClick={() => setPeriod("d")} className={"badge ms-1 cursor-pointer " + (period === "d" ? " bg-primary " : " bg-light text-gray ")}>{t("최근 3개월")}</span>
					<span onClick={() => setPeriod("e")} className={"badge ms-1 cursor-pointer " + (period === "e" ? " bg-primary " : " bg-light text-gray ")}>{t("최근 1년")}</span>
					{/*<span onClick={() => setPeriod("f")} className={"badge ms-1 cursor-pointer " + (period === "f" ? " bg-primary " : " bg-light text-gray ")}>전체 누적</span>*/}
				</div>
			</div>
		</div>
		<div className='card-body'>
			<div className='row mb-4'>
                <div className='d-flex'>
                    {((process.env.REACT_APP_COMPANY === 'ray' || !process.env.REACT_APP_COMPANY) ? DASHBOARD_GRAPTH_TYPES : DASHBOARD_GRAPTH_TYPES_B2B).map((x, idx) => <div 
                        key={idx} 
                        onClick={() => setType(x)}
                        style={{ width : 160 }} 
                        className={"border text-center rounded py-3 me-2 " + (x.type === type.type ? " border-primary bg-label-primary" : " cursor-pointer ")}>
                        <div className={x.type === type.type ? "text-primary mb-1" : "mb-1"}>
                            <i className={"ti ti-" + x.icon + " ti-sm"}></i>
                        </div>
                        <p className="mb-0 font-weight-medium">{t(x.label)}</p>
                    </div>)}
                </div>
                <RMWidgetBarChart2 data={chartData} />
            </div>
        </div>
    </div>
}

const mapState = (state) => {
    const adminUsers = state.AuthReducer.adminUsers;
	const user = state.AuthReducer.user;
	const loginuser = state.AuthReducer.loginuser
	return { adminUsers, user, loginuser };
};

const mapDispatch = dispatch => ({
})

export default connect(mapState, mapDispatch)(RMBigChart);
