import { uniqBy } from 'lodash';
import { useEffect, useState } from 'react';
import RMWidgetIcon from '../widgets/RMWidgetIcon';

const RMWidgetCount = ({ data, title, sub = "", options = {}, value, trend = "", iconName }) => {
    const [widgetData, setWidgetData] = useState(null);

    useEffect(() => {
        if (data)
            setWidgetData(data);
    }, [data]);

    useEffect(() => {
        if (data && options?.filter) {
            setWidgetData(data.filter(x => x?.[options?.filter] === options?.filterValue));
        }

        if (data && options?.Deduplication) {
            setWidgetData(uniqBy(data, 'SN'));
        }

        if (data && options?.target) {
            setWidgetData(uniqBy(data, options.target));
        }
    }, [options])

    return <div className='card'>
        <div className='card-body'>
            <div className="d-flex align-items-start justify-content-between">
                <div className="content-left">
                    <h5>{title}</h5>
                    <div className="d-flex align-items-center my-2">
                        {!options?.multiValue && <h3 className="mb-0 me-2">
                            <p className="mb-2 mt-1">{value}</p>
                        </h3>}
                        {trend && <p className="text-success mb-0">({trend})</p>}
                        {options?.multiValue && <h5 className="my-0">
                            <p className="my-0" dangerouslySetInnerHTML={{ __html: value.replace(/\n/g, '<br />') }}></p>
                        </h5>}
                    </div>
                    <p className="mb-0 small text-muted">{sub}</p>
                </div>
                <div className="avatar">
                    <span className="avatar-initial rounded bg-label-primary" style={{ cursor: "default" }}>
                        {iconName ? (
                            <RMWidgetIcon iconName={iconName} />
                        ) : (
                            <RMWidgetIcon iconName="circle-check" />
                        )}
                    </span>
                </div>
            </div>
        </div>
    </div>
}

export default RMWidgetCount;
