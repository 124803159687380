import saveAs from 'file-saver';
import _ from "lodash";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Spreadsheet from "react-spreadsheet";
import { MLButton } from "../../components/common/MLButton";
import { MLPanelLoadingOverlay } from "../../components/common/MLPanel";
import { RTAudit } from "../../libs/audit";
import { InfoAction } from "../../redux/actions";
import { COUNTRIES } from '../../constants/Country';
import html2pdf from 'html2pdf.js';
import { replaceVariablesText } from '../../libs/string';
import { PRICEUNIT } from '../../constants/RMConstants';

const LicenseExport = ({ list, config, callbackClose, allgroups }) => {
    const [t] = useTranslation();
    const [loading, setLoading] = useState(false);
    const [pdfLoading, serPdfLoading] = useState(false);
    const sampleList = useMemo(() => _.chunk(list, 14)[0], list);
    const exRate = 1494.834;
    
    // 월 환율 정보 포함 예정
    const genData = useCallback((_list) => {
        let headerItems = [
            { value: t("년/월"), readOnly: true },   // payid
            { value: t("Month 갯수"), readOnly: true },     // when
            { value: t("Month 단가"), readOnly: true },
            { value: t("Month 차감액"), readOnly: true },
            { value: t("Year 갯수"), readOnly: true },
            { value: t("Year 단가"), readOnly: true },
            { value: t("Year 차감액"), readOnly: true },
            { value: t("월 갯수"), readOnly: true },
            { value: t("월 차감액"), readOnly: true },
            { value: t("누적 갯수"), readOnly: true },
            { value: t("누적 차감액"), readOnly: true },
            { value: t("Credit 잔액"), readOnly: true },
            { value: t("환율"), readOnly: true },
            { value: t("원화금액"), readOnly: true }
        ];
        const _data = [];
        _list.forEach(_item => {
            const row = [];
            row.push({ value: _item.date, readOnly: true });
            row.push({ value: _item?.monthlyRenewUsers || 0, readOnly: true });
            row.push({ value: _item?.monthlyFee || 0, readOnly: true });
            row.push({ value: _item?.monthlySumCredit || 0, readOnly: true });
            row.push({ value: _item?.yearlyRenewUsers || 0, readOnly: true });
            row.push({ value: _item?.yearlyFee || 0, readOnly: true });
            row.push({ value: _item?.yearlySumCredit || 0, readOnly: true });
            row.push({ value: _item?.totalSumCredit || 0, readOnly: true });
            row.push({ value: _item?.totalSumCreditFee || 0, readOnly: true });

            row.push({ value: _item?.cumulativeCredit || 0 && _item.cumulativeCredit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") , readOnly: true });
            row.push({ value: _item?.cumulativeCreditFee || 0 && _item.cumulativeCreditFee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") , readOnly: true })
            row.push({ value: _item?.remainCredit && _item.remainCredit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") , readOnly: true });
            row.push({ value: exRate.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0, readOnly: true });
            row.push({ value: (_item?.totalSumCreditFee * exRate).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","), readOnly: true});
            _data.push(row);
        });
        return [headerItems, ..._data];
    }, []);

    const data = useMemo(() => {
        return genData(sampleList);
    }, [sampleList, genData, loading]);

    const exportData = useCallback(async () => {
        setLoading(true);
        try {

            const sheetData = genData(list);
            let csvContent = "\ufeff";
            sheetData.forEach(function (rowArray) {
                let row = rowArray?.map(x => `"${x.value || ""}"`).join(",");
                csvContent += row + "\r\n";
            });
            const content = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const name = `user_list_${new Date().toISOString()}.csv`;
            saveAs(content, name);
            const auditExtra = {
                fileName: name,
                count: list.length,
                ids: list?.map(x => x?._id) || [],
            };
            await RTAudit({ lvl: 4, msg: 'Download Relu Credit List' }, JSON.stringify(auditExtra));
        } catch (error) {
            console.log("ERROR[exportData]", error.toString());
        } finally {
            setLoading(false);
        }
    }, [list]);

    return <>
        {loading && <MLPanelLoadingOverlay position="absolute" />}
        <div className="pt-1 pb-1" style={{ overflowX: "auto" }}>
            <Spreadsheet
                data={data}
                hideColumnIndicators={true}
                hideRowIndicators={true}
            />
        </div>
        <div className="d-flex align-items-center justify-content-between mt-2" style={{ position: "sticky", bottom: 0 }}>
            <div className="text-muted">
                {t("Examples are displayed with a maximum of 15 items.")}
            </div>
            <div className="d-flex align-items-center gap-2">
                <MLButton
                    options={{
                        label: t("Cancel"),
                        action: callbackClose,
                    }}
                />
                <MLButton
                    options={{
                        label: t("Export"),
                        color: "primary",
                        action: exportData,
                        disabled: loading,
                    }}
                />
            </div>
        </div>
    </>
};

const mapState = (state) => {
    const config = state.ProgramReducer.config;
    return { config };
};

const mapDispatch = (dispatch) => ({
});

export default connect(mapState, null)(LicenseExport);
