import axios from 'axios';
import { Modal } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BUTTONS, COLORS, INPUTS, SPAN } from '../../constants/FromInputs';
import { MLPanelLoading } from '../common/MLPanel';
import { MLComboSingle, MLToggle } from '../common/MLInput';
import { AuthConstant, getAPI, getHeader } from '../../redux/reducers/AuthReducer';
import { fetchData, queryData, idData, putData, updateData, delData } from '../../libs/db';
import { LicenseConstant, AppCategoryConstant } from '../../redux/reducers';
import { MLButton } from '../common/MLButton';
import { RMLICENSETYPE, RMNOTY } from '../../constants/RMConstants';
import { RTButton } from '../../libs/buttons';
import { getApiUrl } from '../../redux/reducers/AuthReducer';
import { JWT } from '../../config';
import { emailRegex } from '../../libs/regexs';

const MLUserSearch = ({ groups, handleChanged, values, onlyone = false }) => {

	const dispatch = useDispatch();
	const [ email, setEmail ] = useState("");
	const [ prgVal, setPrgVal ] = useState(false);
	const [ userList, setUserList ] = useState([]);

	useEffect(() => {
	}, []);

	useEffect(() => {
        handleChanged && handleChanged(userList);
	}, [userList]);

	useEffect(() => {
        if(values){
            setPrgVal(true);
            makeList();
        }
	}, [values]);

	const onKeyDown = (e) => {
		if(e.key === "Enter"){
            checkEmailStart();
            e.preventDefault();
        }
    }

	const GetUserByEmail = async (data) => {
		const API_URL = getApiUrl('user');
		const ret = await axios.post(API_URL + '/getuserbyemail', data, JWT());
		if (ret.data.status === 'success') {
			return ret.data.data;
		}else{
			return {};
		}
	}

	const GetUserById = async (id) => {
		const API_URL = getApiUrl('user');
		const ret = await axios.get(API_URL + '/getuserbyid/' + id, JWT());
		if (ret.data.status === 'success') {
			return ret.data.data;
		}else{
			return {};
		}
	}

	const getGroup = async (groupid, userRegion) => {
		try{
			const ScanFilter = {
				_id: {
					ComparisonOperator: "EQ",
					AttributeValueList: [groupid],
				},
				sk: {
					ComparisonOperator: "EQ",
					AttributeValueList: ["info"],
				},
			}
			const ret = await fetchData("rayteams-group", ScanFilter, userRegion);
			return ret.Items[0];
		}catch{
			return [];
		}
		//setLoading(false);
    }

    const makeList = async () => {
        let users = [];
        for(const u of values.split(",")){
            console.log(u);
            var ret = await GetUserById(u);
            console.log(ret);
            if (ret.groupId) {
                ret = {...ret, group : await getGroup(ret.groupId, ret.region == "ap-east-1" && ret.region)};
                users.push({...ret, checking : false, res : true})
            } else {
                users.push({...ret, checking : false, res  : false, reason : "Not exist user"})
            }
        }
        setPrgVal(false);
        setUserList(users);
    }

	const checkUser = async (chk) => {
        var ret = await GetUserByEmail({ email : chk });
		if (ret.groupId) {
			ret = {...ret, group : await getGroup(ret.groupId, ret.region == "ap-east-1" && ret.region)};
			setUserList(prev => prev.map(x => x.email === chk ? {...ret, checking : false, res : true} : x));
		} else {
			setUserList(prev => prev.map(x => x.email === chk ? {...x, checking : false, res  : false, reason : "Not exist user"} : x));
		}
    }

	const checkEmailStart = () => {
        if(userList.find(x => x.email === email.trim())){
            setEmail("");
            return;
        }
        if(onlyone){
            setUserList([{ email, checking : true }]);
        }else{
            setUserList([...userList, { email, checking : true }]);
        }
        checkUser(email.trim());
        setEmail("");
    }

	return <div>
        <div className="input-group mb-2">
            <input type="email" 
                onKeyDown={onKeyDown} 
                placeholder={'Enter email address'} 
                autoComplete={'off'}
                onChange={(e) => setEmail(e.target.value)} 
                value={email} 
                className="form-control form-control-sm" required="" 
            />
            <div className="input-group-append">
                <MLButton options={{
                    label: '검색',
                    color: COLORS.PRIMARY,
                    action: checkEmailStart,
                    needValid: true,
                }} valid={emailRegex.test(email.trim())} />
            </div>
        </div>
        {prgVal && <div>
            <label>{onlyone ? "Selected user" : "Selected user(s)"}</label>
            <MLPanelLoading />
        </div>}
        {userList.length > 0 && <div>
            <label>{onlyone ? "Selected user" : "Selected user(s)"}</label>
            <ul className='list-group'>
                {userList.map((x, idx) => <li className='list-group-item  d-flex justify-content-between align-items-center' key={idx}>
                    {x.checking && <>
                        {x.email}
                        <div className="list-btn circle">
                            <div className="spinner" style={{ cursor: "initial" }} title="check">
                                <span className="spinner-border spinner-border-lg align-middle"></span>
                            </div>
                        </div>
                    </>}
                    {!x.checking && <>
                        {x.res && <>
                            [{x.group?.name}] {x?.name}({x.email}) 
                            <span className='btn-xs btn text-danger shadow-none' onClick={() => setUserList(userList.filter(u => u._id !== x._id))}>remove</span>
                        </>}
                        {!x.res && <>
                            <span className='text-danger'>{x.email}</span>
                            <span className='text-muted'>{x.reason}</span>
                        </>}
                    </>}
                </li>)}
            </ul>
        </div>}
    </div>
}

const mapState = (state) => {
	return { };
};

const mapDispatch = (dispatch) => ({
});

export default connect(mapState, mapDispatch)(MLUserSearch);
