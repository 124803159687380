import { connect, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BUTTONS, COLORS, INPUTS, SPAN } from '../constants/FromInputs';
import { MLButton } from '../components/common/MLButton';
import { MLForm } from '../components/common/MLForm';
import { MLRadioButton, MLComboSingle, MLToggle } from '../components/common/MLInput';
import RMPerms from '../components/admin/RMPerms';
import RMPasswordUpdate from '../components/admin/RMPasswordUpdate';
import { AWSUserDelete, AWSAdminUserDelete, AWSGetUserById, AWSGetAdminUserById, AWSAdminUserUpdate } from '../libs/aws';
import { Loader } from '../components/common/Loader';
import { history } from '../history';
import MLModal from '../components/common/MLModal';
import { RMMENUS } from '../constants/RMConstants';
import { updateData, fetchData, queryData, idData } from '../libs/db';
import { ProgramConstant } from '../redux/reducers';
import moment from 'moment';
import { RTAudit } from '../libs/audit';
import { useTranslation } from 'react-i18next';

const AdminUserInfoPage = ({group, adminUsers, user, loginuser, modalData, callbackClose }) => {
	const dispatch = useDispatch();
	const [t] = useTranslation(['translation']);

    const { _id } = useParams();
    const [data, setData] = useState({ name : "", email : "", password : "", managergroupid: "" });
	const [selectedRegion, setRegions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [tab, setTab] = useState("info");
    const [selectedType, setType] = useState(false);
	const [modaldel, setModalDel] = useState({
		show : false,
		title : t("해당 계정을 삭제합니까?"),
		body : <div className='fs-4 pb-4'>
			<div>{t("바로 삭제되며 그리고 되돌릴 수 없고, 즉시!!! 삭제됩니다.")}</div>
		</div>
	});
	const [managerGroupList, setManagerGroupList] = useState([]);

    useEffect(() => {
		dispatch({ type : ProgramConstant.SET_PAGE, page : { 
			code : "account", 
			view : "summary", 
			title : "Administrator", 
			small : t("관리 계정 정보"), 
			options : [
			]
		}})
    }, [_id]);

	useEffect(() => {
        setRegions([]);
		getData();
	}, [_id]);

	const getData = async () => {
        if (_id) {
            var ret = await AWSGetAdminUserById(_id);
			console.log(ret);
			if(ret.UserAttributes){
				let d = {};
				for(const attr of ret.UserAttributes){
					d[attr.Name.replace('custom:', '')] = attr.Value;
				}
				
				const retUser = await queryData("rayteams-manager-user", { 
					_id : {
						ComparisonOperator: "EQ",
						AttributeValueList: [_id]
					}, 
					sk : {
						ComparisonOperator: "EQ",
						AttributeValueList: ["info"]
					}
				});
				let dnUser = {};
				if(retUser.Items.length > 0){
					dnUser = retUser.Items[0];
				}
				
				setData({...d, ...dnUser,
					adminType : d.type === 'manager', 
					enable : ret.Enable, 
					created : moment(ret.UserCreateDate).format('LLL'), 
					updated : moment(ret.UserLastModifiedDate).format('LLL'),
					status : ret.UserStatus 
				});
			}
			getManagerGroup();
        };
	}

	const getManagerGroup = async () => {
        setLoading(true);
        try {
            const ret = await fetchData("rayteams-manager-group", { sk: {
                ComparisonOperator: "EQ",
                AttributeValueList: ["info"]
            }});
            if(ret.Items[0]) {
				setManagerGroupList(ret.Items.map(x => {
					return { value: x._id, label: x.name }
				}));
			}
			const uRet = await fetchData("rayteams-manager-user", { _id: {
				ComparisonOperator: "EQ",
				AttributeValueList: [_id]
			},
			sk: {
				ComparisonOperator: "EQ",
				AttributeValueList: ["info"]
			}});
			if(uRet.Items[0]) {
				setData((prevState) => { return {...prevState, managergroupid: uRet.Items[0]?.managergroupid}});
			}
			
        } catch(err) {
            console.log(err);
        }
        setLoading(false);
    }

	const del = async () => {
		if (adminUsers) {
			setLoading(true);
            const adminUserInfo = adminUsers.filter(y => y.sub === _id)[0];
            adminUserInfo.region && adminUserInfo.region.split(",").map(async (x) => {
                await AWSUserDelete(x, adminUserInfo.email)
            });
			AWSAdminUserDelete(adminUserInfo.email);
			RTAudit({ lvl : 9, msg : '[' + adminUserInfo.name + '] Account Deleted' })
			setLoading(false);
			history.push('/AdminUserPage');
        }
	}

	const updateManagerGroup = async () => {
		setLoading(true);
		RTAudit({ lvl : 7, msg : '[' + data.name + ', '+ (data.adminType ? 'manager' : 'user') +'] Account Updated' })
		await AWSAdminUserUpdate(_id, [{
                Name: 'custom:type',
                Value: data.adminType ? 'manager' : 'user'
            },
            {
                Name: 'name',
                Value: data.name
            }
		]);
		try{
			await updateData("rayteams-manager-user", { 
				_id : _id, 
				sk : "info"
			},{
                privacy : data.privacy,
                memo : data.memo,
            });
		}catch(err){
			console.log(err);
		}
		getData();
		setLoading(false);
	}

	console.log(data);
    
    const controls = [
		{
			type : INPUTS.EMAIL,
			options : {
				label : t('이메일'),
				name : 'email',
				required : true,
				readonly : true
			}
		},
		{
			type : INPUTS.TEXT,
			options : {
				label : t('이름'),
				name : 'name',
                value : data?.name,
				required : true
			}
		},
		{
			type : INPUTS.TEXT,
			options : {
				label : t('생성일자'),
				name : 'created',
                value : data?.created,
				readonly : true
			}
		},
		{
			type : INPUTS.TEXT,
			options : {
				label : t('최근 수정일자'),
				name : 'updated',
                value : data?.updated,
				readonly : true
			}
		},
        { 
			type : SPAN.VERTICAL, 
			options : { val : 20 } 
		},
		{
			type : INPUTS.TOGGLE,
			options : { 
				label: t('개인정보 관련자(책임자/담당자/취급자)'),
				name: 'privacy',
				value: data?.privacy,
			}
		},
		{
			type : INPUTS.TOGGLE,
			options : { 
				label: t('관리자'),
				name: 'adminType',
				value: data?.adminType,
			}
		},
		{
			type : INPUTS.COMBO,
			options : {
				label: t('Manager Group'),
				name: 'managergroupid',
				value: data?.managergroupid,
				list: managerGroupList
			}
		},
		{
			type : INPUTS.TEXT,
			options : {
				label: t('Memo'),
				name: 'memo',
				value: data?.memo
			}
		},
		{ 
			type : SPAN.VERTICAL, 
			options : { val : 20 } 
		},
		{
			type : BUTTONS.BUTTONS, 
			options : { },
			buttons : [{
				type : BUTTONS.NORMALBUTTON, 
				options: { 
					code: "ac-editadmin",
					label : t('수정'), 
					color : COLORS.INFO,
                    action : () => updateManagerGroup()
				}
			},{
				type : BUTTONS.NORMALBUTTON, 
				options: { 
					code: "ac-deladmin",
					label : t('삭제'), 
					color : COLORS.DANGER,
					action : () => setModalDel({...modaldel, show : true})
				}
			}]
		}
	];
    return <div className='p-4'>
        {loading && <Loader />}
        {!loading && <>
			<div className="d-flex justify-content-between">
				<h3>
					{data?.name} <small> <span className="text-light ms-1"> </span> </small>
				</h3>
				<div className="card-toolbar">
					<MLButton options={{
						label : t('목록'), 
						color : COLORS.LIGHT,
						action : () => history.push('/AdminUserPage')
					}} />
				</div>
			</div>
			<div className='card'>
				<div className='card-header py-0'>
					<ul className="nav nav-tabs nav-stretch border-top" role="tablist">
						<li className="nav-item cursor-pointer" role="presentation" onClick={() => {setTab("info");}}>
							<a className={"nav-link " + ('info' === tab ? 'active' : '')}><span className='nav-text'>{t("Info.")}</span></a>
						</li>
						<li className="nav-item cursor-pointer" role="presentation" onClick={() => {setTab("perm");}}>
							<a className={"nav-link " + ('perm' === tab ? 'active' : '')}><span className='nav-text'>{t("Perms.")}</span></a>
						</li>
						<li className="nav-item cursor-pointer" role="presentation" onClick={() => {setTab("pass");}}>
							<a className={"nav-link " + ('pass' === tab ? 'active' : '')}><span className='nav-text'>{t("Password.")}</span></a>
						</li>
					</ul>
				</div>
				{tab === "info" && <MLForm controls={controls.filter(x => !x.perm)} data={data} onChanged={(e) => { console.log(e);setData(e);}} />}
				{tab === "perm" && <RMPerms uData={data}/>}
				{tab === "pass" && <RMPasswordUpdate uData={data} />}
			</div>
		</>}
		<MLModal modalData={modaldel} footerbuttons={[{
			options : { 
				label : t('사용자 삭제'),
				color : COLORS.DANGER,
				action : () => del()
			} },
			{
				type : BUTTONS.NORMALBUTTON, 
				options : { 
					label : t('Cancel'), 
					color : COLORS.SECONDARY,
					action : () => setModalDel({...modaldel, show : false})
				}
			}
		]}/>
    </div>
}

const mapState = (state) => {
    const adminUsers = state.AuthReducer.adminUsers;
	const user = state.AuthReducer.user;
	const loginuser = state.AuthReducer.loginuser
	return { adminUsers, user, loginuser };
};

const mapDispatch = dispatch => ({
})

export default connect(mapState, mapDispatch)(AdminUserInfoPage);
