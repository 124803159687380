const colorSet = [
	'#38c1ff',
	'#82ca9c',
	'#ffeb3b',
	'#cb94d9',
	'#cddc39',
	'#6ddbdb',
	'#fad55c',
	'#7da7d9',
	'#f5b2d8',
	'#68c182',
	'#c7b299',
	'#97b4f4',
	'#f2aba1',
	'#8ce7ce',
	'#a186be',
	'#fabd5c',
	'#998675',
	'#8bc34a',
	'#6dcff6',
	'#8781bd',
	'#f69679',
	'#00bcd4',
	'#f49ac1',
	'#fff799',
	'#c69c6d',
	'#c4df9b',
	'#8393ca',
	'#76ccc7',
	'#f5989d',
	'#fdc689',
	'#bd8cbf',
  ];
  
  export const getRTColors = (idx) => {
	return colorSet[idx];
  } 

  export const getRandomColors = (len, opacity = 1) => {
	  if (len < 1) {
		return [];
	  }
	  const result = [];
	  for (let i = 0; i < len; i++) {
		const colorSetIndex = i % colorSet.length;
		result.push({
		  color: colorSet[colorSetIndex],
		  opacityColor: colorSet[colorSetIndex]
		})
	  }
	  return result;
  }