import axios from 'axios';

const getEnv = () => {
    if(location.href.indexOf("localhost") > -1 || location.href.indexOf("127.0.0.1") > -1){
        return "dev-local";
    }else if(location.href.indexOf("raydevelop") > -1){
        return "development";
    }
    return "production";
}

const getCodeConfigPath = () => {
    const now = new Date().getTime();
    const key = process.env.REACT_APP_COMPANY || 'ray';
    if(getEnv() === "production"){
        return "https://ray-data.s3.ap-northeast-2.amazonaws.com/rayrnd/rayteams-service/ray/codeConfig.json?t=" + now;
    }else{
        return "https://ray-data-development.s3.ap-northeast-2.amazonaws.com/rayrnd/rayteams-service/ray/codeConfig.json?t=" + now ;
    }
}

const getConfigPath = () => {
    const now = new Date().getTime();
    const key = process.env.REACT_APP_COMPANY || 'ray';
    if(getEnv() === "production"){
        return "https://ray-data.s3.ap-northeast-2.amazonaws.com/rayrnd/rayteams-service/" + key + "/config.json?t=" + now;
    }else{
        return "https://ray-data-development.s3.ap-northeast-2.amazonaws.com/rayrnd/rayteams-service/" + key + "/config.json?t=" + now;
    }
}

export const GetConfig = async () => {
    const conf = await axios.get(getConfigPath());
    return conf?.data || [];
}

export const GetCodeConfig = async () => {
    const conf = await axios.get(getCodeConfigPath());
    return conf?.data || [];
}
