import * as AWS from 'aws-sdk';
import axios from 'axios';
import { Store } from '../store';
import { Buffer } from "buffer";
import { AppConfig, JWT } from '../config';
import { CONFIG_REGION_SET } from './cogInfo';

const regiondbs = [
    "rayteams-user",
    "rayteams-group",
    "rayteams-project",
]

export const aws_config = (tb, userRegion) => {
    if(!localStorage.getItem('user')){
        return;
    }
    const btoken = localStorage.getItem('user').split('.');
    const bdata = Buffer.from(btoken[1], "base64").toString('utf8');
    const uData = JSON.parse(bdata);
    if(!uData)
        return;
    const be = Buffer.from(uData.pc, "base64").toString('utf8');
    const arr = be.split("|rayteams|");
    const scope = Store.getState().ProgramReducer.scope
    const region = userRegion ? userRegion : ((regiondbs.includes(tb) && scope !== "G") ? 'ap-east-1' : 'ap-northeast-2');
    // console.log({
    //     region,
    //     secretAccessKey: arr[1],
    //     accessKeyId: arr[0]
    //     //region : 'ap-northeast-2',
    //     //secretAccessKey: "Ux0qsDvdDm53vTuHPst9/ePJMIoG0zg/zsV2WLga",
    //     //accessKeyId: "AKIAV6JBTAHFLLEA5APR"
    // });
    return {
        region,
        secretAccessKey: arr[1],
        accessKeyId: arr[0]
        //region : 'ap-northeast-2',
        //secretAccessKey: "Ux0qsDvdDm53vTuHPst9/ePJMIoG0zg/zsV2WLga",
        //accessKeyId: "AKIAV6JBTAHFLLEA5APR"
    }
}

export const aws_con = (tb, userRegion) => {
    AWS.config.update(aws_config(tb, userRegion));
}

export const idData = async (tableName, id) => {
    aws_con(tableName);
    const docClient = new AWS.DynamoDB.DocumentClient();
    var params = {
        TableName: tableName,
        KeyConditions: {
          _id: {
            ComparisonOperator: "EQ",
            AttributeValueList: [id],
          },
        },
        ScanIndexForward: false,
    }
    return await docClient.query(params).promise();
}

export const queryDataWithIndex = async (tableName, qfilter, IndexName, userRegion) => {
    aws_con(tableName, userRegion);
    const docClient = new AWS.DynamoDB.DocumentClient();
    var params = {
        TableName: tableName,
        IndexName,
        KeyConditions: qfilter,
        ScanIndexForward: false,
    }
    return await docClient.query(params).promise();
}

export const queryData = async (tableName, qfilter, userRegion) => {
    aws_con(tableName, userRegion);
    const docClient = new AWS.DynamoDB.DocumentClient();
    var params = {
        TableName: tableName,
        KeyConditions: qfilter,
        ScanIndexForward: false,
    }
    return await docClient.query(params).promise();
}

export const fetchData = async (tableName, sfilter, userRegion) => {
    aws_con(tableName, userRegion);
    const docClient = new AWS.DynamoDB.DocumentClient();
    var params = {
        TableName: tableName,
        ScanFilter : sfilter
    }
    const scanResults = { Items : [], Count : 0 };
    let items;
    do{
        items =  await docClient.scan(params).promise();
        items.Items.forEach((item) => { scanResults.Items.push(item); scanResults.Count++; });
        params.ExclusiveStartKey  = items.LastEvaluatedKey;
    }while(typeof items.LastEvaluatedKey !== "undefined");

    return scanResults;
}

export const fetchData_prod = async (tableName, sfilter) => {
    aws_con(tableName);
    const docClient = new AWS.DynamoDB.DocumentClient();
    var params = {
        TableName: tableName,
        ScanFilter : sfilter
    }
    const scanResults = { Items : [], Count : 0 };
    let items;
    do{
        items =  await docClient.scan(params).promise();
        items.Items.forEach((item) => { scanResults.Items.push(item); scanResults.Count++; });
        params.ExclusiveStartKey  = items.LastEvaluatedKey;
    }while(typeof items.LastEvaluatedKey !== "undefined");

    return scanResults;
}

export const countData = async (tableName, sfilter) => {
    aws_con(tableName);
    const docClient = new AWS.DynamoDB.DocumentClient();
    var params = {
        TableName: tableName,
        ScanFilter: sfilter,
        Select: "COUNT"
    }
    return await docClient.scan(params).promise();
}

export const putData = async (tableName, item) => {
    aws_con(tableName);
    const docClient = new AWS.DynamoDB.DocumentClient();
    var params = {
        TableName: tableName,
        Item: item
    }
    return await docClient.put(params).promise();
}

export const updateData = async (tableName, key, data) => {
    aws_con(tableName);
	var attr = {};
	Object.keys(data).map((x) => {
	  attr[x] = {
		Action: "PUT",
		Value: data[x],
	  };
	});

	const docClient = new AWS.DynamoDB.DocumentClient()

    var params = {
        TableName: tableName,
		Key: key, 
		AttributeUpdates: attr,
		ReturnValues : "ALL_NEW"
    }

    const upData = await docClient.update(params).promise();

	return upData.Attributes;
}

export const fetchData_withExpression = async (scanParams) => {
    aws_con();
    const docClient = new AWS.DynamoDB.DocumentClient()
    var params = scanParams;
    return await docClient.scan(params).promise();
}

export const updateData_withExpression = async (updateParam) => {
    aws_con();
    const docClient = new AWS.DynamoDB.DocumentClient()
    var params = updateParam;
    const upData = await docClient.update(params).promise();
    return upData.Attributes;
}

export const delData = async (tableName, key) => {
    aws_con(tableName);
	const docClient = new AWS.DynamoDB.DocumentClient()
    var params = {
        TableName: tableName,
		Key: key
    }
    await docClient.delete(params).promise();
	return;
}

export const delOneData = async (tableName, id, sk) => {
    aws_con(tableName);
	const docClient = new AWS.DynamoDB.DocumentClient()
    const batchWriteParams = {
        RequestItems : {
            [tableName] : [
                { 
                    DeleteRequest : { 
                        Key : {
                            _id : id,
                            sk,
                        }
                    } 
                }
            ]
        }
    }
    await docClient.batchWrite(batchWriteParams).promise()
	return;
}

export const delpkAllData = async (tableName, key, keyval) => {
    aws_con(tableName);
	const docClient = new AWS.DynamoDB.DocumentClient()
    const res = await docClient.query({
        TableName: tableName,
        KeyConditionExpression: key + ' = :pk',
        ExpressionAttributeValues: { ':pk': keyval } ,
    }).promise()
    if (res.Items && res.Items.length > 0) {
        const batchCalls = chunks(res.Items, 25).map(async (chunk) => {
            const deleteRequests = chunk.map( item => {
                const obj = {};
                obj[key] = keyval;
                obj.sk = item.sk;
                console.log(obj);
                return { DeleteRequest : { Key : obj } }
            });
            const batchWriteParams = {
                RequestItems : {
                    [tableName] : deleteRequests
                }
            }
            await docClient.batchWrite(batchWriteParams).promise()
        })
        await Promise.all(batchCalls)
    }
	return;
}

export const insertData = async (tableName, data) => {
    aws_con(tableName);
	const docClient = new AWS.DynamoDB.DocumentClient()

    var params = {
        TableName: tableName,
		Item : data
    }

    await docClient.put(params).promise();

	return data;
}

export const pkData = async (tableName, key, val) => {
    aws_con(tableName);
	const docClient = new AWS.DynamoDB.DocumentClient()
	const KeyCon = {};
	KeyCon[key] = {
		ComparisonOperator: "EQ",
		AttributeValueList: [val],
	}

    var params = {
        TableName: tableName,
		KeyConditions: KeyCon,
		ScanIndexForward: false,
    }

    return await docClient.query(params).promise();
}

function chunks(inputArray, perChunk) {
    return inputArray.reduce((all,one,i) => {
        const ch = Math.floor(i/perChunk);
        all[ch] = [].concat((all[ch]||[]),one);
        return all
    }, [])
}

const getAPI = () =>{
    const env = (location.href.indexOf("localhost") > -1 || location.href.indexOf(".raydevelop.com") > -1) ? "development" : "production";
	return AppConfig().info + "/common/dynamo";
};

export const callDB = async (data) => {
    let paramsData = data;
    const filterExpression = data?.filter;
    if (data?.type == "scan") {
        if (filterExpression && Object.keys(data?.filter).length > 0) {
            paramsData.params.FilterExpression = paramsData.params.FilterExpression + " and " + filterExpression.FilterExpression;
            paramsData.params.ExpressionAttributeNames = {
                ...paramsData.params.ExpressionAttributeNames,
                ...filterExpression.ExpressionAttributeNames
            };
            paramsData.params.ExpressionAttributeValues = {
                ...paramsData.params.ExpressionAttributeValues,
                ...filterExpression.ExpressionAttributeValues
            }
        }
    }

    const ret = await axios.post(getAPI(), { data : paramsData }, JWT());
    return ret?.data;
}

export const ConfigAWSRegion = (region) => {
    const _region = CONFIG_REGION_SET[region]
        ? CONFIG_REGION_SET[region]
        : region;
    AWS.config.update({
        region: _region,
    });
};