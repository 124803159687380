import axios from 'axios';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { MLText } from '../../components/common/MLInput';
import { MLButton, MLRefreshButton, MLChartButton } from '../../components/common/MLButton';
import { COLORS } from '../../constants/FromInputs';
import { MLPanelLoading } from '../../components/common/MLPanel';
import MLTable from '../../components/common/MLTable';
import MLFilterList from '../../components/common/MLFilterList';
import { InfoAction } from '../../redux/actions';
import MLFilter, { CreateScanFilter, CreateScanFilterExpression } from '../../components/common/MLFilter';
import { ProgramConstant } from '../../redux/reducers';
import { RMIconList, RMIconChart } from '../../components/icon/MLIcon';

import DeviceStatCard from '../../components/device/DeviceStatCard';
import DeviceChart from '../../components/device/DeviceChart';
import { DeviceAction } from '../../redux/actions/DeviceAction';
import { useTranslation } from 'react-i18next';

const DevicesPage = ({ groups, region, regionusers, alldevices, pagestat, page, filters }) => {
	const dispatch = useDispatch();
	const [t] = useTranslation(['translation']);
	const [more, setMore] = useState(true);
	const [search, setSearch] = useState("");
	const [filter, setFilter] = useState(false);
	const [items, setItems] = useState([]);
	const [loading, setLoading] = useState(false);
	const [fs, setFs] = useState([]);
	const [showFilter, setShowFilter] = useState(false);

	useEffect(() => {
		dispatch({ type : ProgramConstant.SET_PAGE, page : { 
			code : "devices", 
			view : "summary", 
			title : "Devices", 
			small : t("Device 관련 정보"), 
			options : [
				{ view : "summary" }, 
				{ view : "list" } 
			]
		}})
	}, []);

	useEffect(() => {
		dispatch(InfoAction.GetUserFilters("devices"));
        if(page?.view)
            setMore(page.view === "summary");
	}, [page]);

	useEffect(() => {
        getData();
	}, [filters]);

	useEffect(() => {
		genData();
        dispatch({ type: ProgramConstant.PAGESTAT, item: { grouplist : { search, filter, 
            order : pagestat.order, 
            desc : pagestat.desc 
         }} });
	}, [search, alldevices, filter, pagestat.order, pagestat.desc]);

	const goLink = (to, title) => {
		dispatch({ type : ProgramConstant.GO_LINK, to, title });
	}

    const headers = [
		{ link : "sn", value : "sn", label : t("SN"), css : "text-start", linkpath : "/device", css : "w-200px" },
        { value : "DeviceName", label : t("DeviceName"), css : "text-center" },
        { value : "ownerId", label : t("Clinic"), css : "text-start", type : "CLINIC" },
        { value : "partnerKey", label : t("Partner"), css : "text-start" },
        //{ value : "labs", label : "Lab(s)", css : "text-start", type : "LABS" },
        //{ value : "isactive", label : "Active", css : "text-center", type : "TFVALUE" },
        //{ value : "issharable", label : "Sharable", css : "text-center", type : "TFVALUE" },
        { value : "activated", label : t("Activated"), css : "text-center", type : "DATESHORT" },
        { value : "registered", label : t("Assigned"), css : "text-center", type : "DATESHORT" },
        //{ label : "", css : "text-center" },
	]

	// country 선택
	const getData = async () => {
		setLoading(true);
		let FilterExpression = {};
		const pageFilter = filters.find(x => x.page === page.code);
		if(pageFilter){
			pageFilter.fs.map(x => CreateScanFilterExpression(FilterExpression, x));
		}
		dispatch(DeviceAction.GetAllDevices(FilterExpression));
		setLoading(false);
	}

    const fLabel = (code, value) => {
        if(value === true || value === false){
            return value === true ? "Active" : "Expired";
        }else if(code === "countryCode"){
            return COUNTRIES.find(x => x.countryCode === value)?.name;
        }
        return value;
    }

	const genFilter = () => {
        setFilter( filter.map(x => {
            const list = [...new Set(alldevices.map(m => m[x.code]))];
            return {...x, list : [{ value : "", label : "ALL" }, ...list.map(n => ({ 
                value : n, 
                label : fLabel(x.code, n)
            }))] }
        }));
	}

	const genDataUsed = (x) => {
        return filter ? x.ownerId : true;
	}

	const genData = () => {
		setItems(alldevices
            .filter(x => genDataUsed(x))
            .filter(x => search ? (x.sn?.indexOf(search) > -1) : true)
			.sort((a, b) => pagestat.desc === "desc" ? (a[pagestat.order] > b[pagestat.order] ? -1 : 1) : (b[pagestat.order] > a[pagestat.order] ? -1 : 1))
        )
	}

	const handleFilter = (e, item) => {
        setFilter( filter.map(x => x.code === item.code ? {...x, value : e.value } : x));
	}

	const headerClick = () => {
		dispatch({ type: ProgramConstant.PAGESTAT, item: { devicelist : { search, filter, 
            order : pagestat.order, 
            desc : (pagestat.desc === "desc" ? "asc" : "desc") }} });
	}

  	return <div className='p-4'>
		<div className='mb-4'>
			<DeviceStatCard items={alldevices}/>
		</div>
        {more && <DeviceChart items={alldevices}/>}
        {!more && <div className="card">
			<div className="card-header d-flex justify-content-between align-items-center">
				<div className="card-label fw-bold text-gray-800 fs-5">{items.length}</div>
				<div className="d-flex">
					<MLFilterList page={page} filter={fs} />
					<MLButton options={{
						label : t("Filter"),
						color : "primary",
						action : () => setShowFilter(!showFilter)
					}} css="me-1" />
					<MLButton options={{
						label : filter ? t("Used(RAYTeams)") : t("전체"),
						size : "sm",
						color : COLORS.PRIMARY,
						action : () => setFilter(!filter)
					}} css="mb-0 me-2" />
					<MLText options={{
							placeholder : t("검색어 입력"),
							size : "sm",
							value : search,
						}} handleChanged={(e) => setSearch(e.target.value)} css="mb-0" />
				</div>
			</div>
			<MLFilter show={showFilter} page={page} callback={() => setShowFilter(false)} />
			{!loading && <MLTable 
				headers={headers} 
				items={items} 
				headerClick={headerClick}
				order={pagestat?.order || "registered"} 
				desc={pagestat?.desc || "desc"} 
				noheader={true} />}
			{loading && <MLPanelLoading />}
		</div>}
    </div>
}

const mapState = (state) => {
	const groups =  state.AuthReducer.groups;
	const region = state.AuthReducer.region;
	const regionusers = state.AuthReducer.regionusers;
    const alldevices = state.ProgramReducer.alldevices;
	const pagestat = state.ProgramReducer.pagestat["devicelist"] || {};
	const page = state.ProgramReducer.page;
	const filters = state.ProgramReducer.filters;

	return { groups, region, regionusers, alldevices, pagestat, page, filters };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, null)(DevicesPage);
