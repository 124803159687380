import axios from 'axios';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { MLText } from '../../components/common/MLInput';
import { MLPanelLoading } from '../../components/common/MLPanel';
import MLTable from '../../components/common/MLTable';
import { AuthConstant, getAPI, getHeader } from '../../redux/reducers/AuthReducer';
import { fetchData } from '../../libs/db';
import WidgetBarChartAppUsage from '../../components/widgets/dashboard/WidgetBarChartAppUsage';
import RMWidgetBarChart from '../../components/widgets/RMWidgetBarChart';
import RMWidgetLineChart from '../../components/widgets/RMWidgetLineChart';
import { TbList } from 'react-icons/tb';
import { ProgramConstant } from '../../redux/reducers';
import RMWidgetCount from '../../components/widgets/RMWidgetCount';
import { RMIconList, RMIconChart } from '../../components/icon/MLIcon';

const PartnerDashBoardPage = ({ groups, region, regionusers, allpartner }) => {
	const dispatch = useDispatch();
	const [search, setSearch] = useState("");
	const [items, setItems] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if(!regionusers)
			getData_dUsers();
	}, [region]);

	useEffect(() => {
		genData();
	}, [search, regionusers]);

    const goLink = (to, title) => {
		dispatch({ type : ProgramConstant.GO_LINK, to, title });
	}

    const headers = [
        { link : "_id", linkpath : "/UsersInfo", value : "_id", label : "ID", css : "text-start", style : { width : 300 } },
		{ link : "groupId", linkpath : "/GroupInfo", value : "groupname", label : "Group", css : "text-start", style : { width : 150 } },
		{ value : "userType", label : "Type"},
		{ value : "email", label : "Email" },
		{ value : "lastlogged", label : "Last Logged", css : "text-end", type: "DATE" },
    ]

	// country 선택
	const getData_dUsers = async () => {
		// 총 사용자 숫자, 
		// 그룹별 사용자 숫자, 
		// User type 별 사용자

		setLoading(true);
		const ScanFilter = {
			sk: {
				ComparisonOperator: "EQ",
				AttributeValueList: ["info"],
			},
		}
		try {
			const ret = await fetchData("rayteams-user", ScanFilter);
			var list = [];
			ret.Items.map(x => {
				list.push({...x, groupname : groups.filter(y => y._id === x.groupId)[0]?.name, region : groups.filter(y => y._id === x.groupId)[0]?.region })
			})

			dispatch({ type: AuthConstant.GET_REGION_USERS, items : list });
		} catch (e) {
			dispatch({ type: AuthConstant.GET_REGION_USERS, items : [] });
			console.log(e);
		}
		setLoading(false);
	}

	const genData = () => {
		setItems(search ? regionusers.filter(x => x.groupname?.indexOf(search) > -1 || x.userType?.indexOf(search) > -1) : (regionusers || []));
	}

  	return ( <>
		<div className="header align-items-stretch d-block" style={{position: "initial"}}>
			<div className="d-flex w-100 justify-content-between p-5 align-items-center">
				<div className="d-flex align-items-center text-dark fw-bolder fs-2 my-1">Users</div>
				<div className="d-flex align-items-center">&nbsp;</div>
			</div>
		</div>
    	<div>
			<div className='row'>
				<div className='col-xl-12 mb-5'>
					<div className='d-flex justify-content-between align-items-end mb-3'>
						<h2 className='mb-4'><small className='ms-2'></small></h2>
						<div className='d-flex'>
							<div className='col-sm-auto'>
								<MLText options={{
									placeholder : "검색어 입력",
									size : "sm",
									value : search,
								}} handleChanged={(e) => setSearch(e.target.value)} css="w-200px" />
							</div>
							<div className="col-sm-auto ms-6 btn-tool-view">
								<a className="btn btn-bg-secondary btn-sm mb-1 btn-view-select" title="View as a list">
									<RMIconList onClick={() => goLink("/UsersPage", "Users")}></RMIconList>
								</a>
								<a className="btn btn-bg-secondary btn-sm mb-1 btn-view-select active" title="View as a dashboard">
									<RMIconChart></RMIconChart>
								</a>
							</div>
						</div>
					</div>
				{!loading && <>
                    <div className='row mb-5'>
                        <div className='col-3'>
                            <RMWidgetCount data={items} title={"User Count"} />
                        </div>
                        <div className='col-9'>
                            <RMWidgetBarChart data={items} type={"Group"} title={"사용자가 많은 그룹 Top 5"} target={"groupname"} />
                            
                        </div>
                    </div>
                    <div className='mb-5'>
                        <RMWidgetBarChart data={items} title={"국가별 사용자 생성일자"} period={"days"} targetdate={"created"} targetname={"countryCode"} chartType={true} />
					</div>
					<div>
						<RMWidgetBarChart data={items} type={"Type"} title={"사용자 Type Top 5"} target={"userType"} />
					</div>
				</>}
				{loading && <MLPanelLoading />}
				</div>
			</div>
    	</div>
		</>
  	);
}

const mapState = (state) => {
	const groups =  state.AuthReducer.groups;
	const region = state.AuthReducer.region;
	const regionusers = state.AuthReducer.regionusers;
    const allpartner = state.ProgramReducer.allpartner;
	return { groups, region, regionusers, allpartner };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, null)(PartnerDashBoardPage);