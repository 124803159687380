import axios from 'axios';
import { AppConfig, JWT } from '../../config';
import { ProductConstant } from '../reducers/ProductReducer';
import { fetchData, putData, updateData } from '../../libs/db';
import { AlertConstant } from '../reducers';

const { v4 } = require("uuid");

const getAPI = () =>{
    const env = (location.href.indexOf("localhost") > -1 || location.href.indexOf(".raydevelop.com") > -1) ? "development" : "production";
	
	if(process.env.REACT_APP_COMPANY === 'graphy'){
        return "https://api-manager-order." + AppConfig().domain;
    }else{
        if(env === "production"){
			return "https://api-manager-order-development.rayteams.com";
		}else{
			return "https://api-manager-order-development.raydevelop.com";
		}
    }
};

const GetAll = () => async (dispatch) => {
	// const ret = await axios.get(AppConfig.API_SERVER_PATH + '/product', JWT());
	// if (ret.data.status == 'success') {
	// 	dispatch({ type: ProductConstant.GETALL, items: ret.data.data });
	// 	dispatch({ type: AlertConstant.NOTICE, data: "Success" });
	// } else {
	// 	dispatch({ type: AlertConstant.ERROR, data: ret.data.err });
	// }

	const plist = [
		{
			_id: 'test-pid1',
			sk: 'info',
			productname: 'test product1'
		},
		{
			_id: 'test-pid2',
			sk: 'info',
			productname: 'test product2'
		},
		{
			_id: 'test-pid3',
			sk: 'info',
			productname: 'test product3'
		},
		{
			_id: 'test-pid1',
			sk: 'cid:kr',
			productname: 'proDetail 1',
			currency: 'KRW',
			discount: '10',
			ispublished: true
		},
		{
			_id: 'test-pid1',
			sk: 'cid:jp',
			productname: 'proDetail 1',
			currency: 'JPY',
			discount: '10',
			ispublished: false
		}
	]
	dispatch({ type: ProductConstant.GET_ALL_PRODUCTS, items: plist });
};

const Get = (_id) => async (dispatch) => {
	const ret = await axios.get(AppConfig.API_SERVER_PATH + '/product/' + _id, JWT());
	if (ret.data.status == 'success') {
		dispatch({ type: ProductConstant.GET_ALL_PRODUCTS, item: ret.data.data });
		dispatch({ type: AlertConstant.NOTICE, data: "Success" });
	} else {
		dispatch({ type: AlertConstant.ERROR, data: ret.data.err });
	}
};

const CreateProductMain = (data) => async (dispatch) => {
	try {
		const time = new Date().getTime();
		const Item = {
			_id: v4(),
			sk: "info",
			...data,
			created: time
		}
		const ret = await putData("rayteams-manager-product", Item);
		dispatch({ type: ProductConstant.CREATE_PRODUCT_MAIN, item: Item });
	} catch (error) {
		dispatch({ type: AlertConstant.ERROR, data: error})
	}
};

const CreateProductDetail = (_id, data) => async (dispatch) => {
	try {
		const time = new Date().getTime();
		const Item = {
			_id: _id,
			...data,
			created: time
		}
		const ret = await putData("rayteams-manager-product", Item);
		dispatch({ type: ProductConstant.CREATE_PRODUCT_DETAIL, item: Item });
	} catch (error) {
		dispatch({ type: AlertConstant.ERROR, data: error });
	}
}

const UpdateProductDetail = (_id, data) => async (dispatch) => {
	try {
		const ret = await updateData("rayteams-manager-product", _id, data);
		dispatch({ type: ProductConstant.UPDATE_PRODUCT_DETAIL, item: ret})
	} catch (error) {
		dispatch({ type: AlertConstant.ERROR, data: error });
	}

	// const ret = await axios.post(getAPI() + '/product/' + _id + '/detailupdate' , { data }, JWT());
	// if (ret.data.status == 'success') {
	//  	dispatch({ type: ProductConstant.UPDATE_PRODUCT_DETAIL, item: ret.data.data });
	// } else {
	//  	dispatch({ type: AlertConstant.ERROR, data: ret.data.err });
	// }
}

export const ProductAction = {
    getAPI,
	GetAll,
	Get,
	CreateProductMain,
	CreateProductDetail,
	UpdateProductDetail
};
