export const XName = (un) => {
    //return un;
    if(!un)
        return "";
    const ln = Math.round(un.length / 2);
    let oo = "";
    for(var n = 0; n < ln; n++){ oo += "*" }
    return "*" + un.substr(ln);
}
export const XEmail = (un) => {
    //return un;
    if(!un)
        return "";
    const id = un.split("@")[0];
    const dom = un.split("@")[1];
    const ln = Math.round(id.length / 2);
    let oo = "";
    for(var n = 0; n < ln; n++){ oo += "*" }
    return "*" + id.substr(ln) + "@" + dom;
}

export const convertToKoreanNumber = (num) => {
    var result = '';
    var digits = ['영','일','이','삼','사','오','육','칠','팔','구'];
    var units = ['', '십', '백', '천', '만', '십만', '백만', '천만', '억', '십억', '백억', '천억', '조', '십조', '백조', '천조'];
    
    var numStr = num.toString();
    var numLen = numStr.length;
    
    for(var i=0; i<numLen; i++) {
      var digit = parseInt(numStr.charAt(i));
      var unit = units[numLen-i-1];

      if(i === numLen-1 && digit === 1 && numLen !== 1) {
        result += '일';
      } else if(digit !== 0) {
        result += digits[digit] + unit;
      } else if(i === numLen-5) {
        result += '만';
      }
    }
    
    return result;
}