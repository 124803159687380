import React from 'react';
import ReactDOM from 'react-dom';
import { init } from './libs/i18n';
import App from './App';
import { Provider } from 'react-redux';
import { Store } from './store';
import * as Sentry from '@sentry/react';
import { AppConstant } from './redux/reducers/AppReducer';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const getEnv = () => {
  if (location.href.indexOf("localhost") > -1 || location.href.indexOf("127.0.0.1") > -1) {
    return "dev-local";
  } else if (location.href.indexOf("raydevelop") > -1) {
    return "development";
  }
  return "production";
}

const start = async () => {
  await init();

  //import './index.css';
  function FallbackComponent() {
    return (
      <div>An error has occured</div>
    )
  }
  Sentry.init({
    dsn: getEnv() === "production" ?
      "https://2a0605ba3c1f46e7915bd9d0390db419@o4504930404925440.ingest.sentry.io/4504930413969409" :
      "",
    release: "1.0.3",
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    environment: getEnv(),
    tracesSampleRate: 1.0,
    beforeSend: (event, hint) => {
      // 401 Unauthorized error를 보고에서 제외
      if (
        hint.originalException &&
        hint.originalException.response &&
        hint.originalException.response.status === 401
      ) {
        return null;
      }

      return event;
    },
  });

  ReactDOM.render(
    <Provider store={Store}>
      <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
        <App />
      </Sentry.ErrorBoundary>
    </Provider>,
    document.getElementById('root')
  );

  try{
    serviceWorkerRegistration.unregister();
  }catch(e){}
}

start();