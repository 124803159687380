import { useTranslation } from 'react-i18next';
import { BallTriangle } from 'react-loader-spinner';

export const Loader = ({msg = "", content = ""}) => {

	const [t] = useTranslation(['translation']);

	return <div className='h-100 w-100 d-flex align-items-center justify-content-center rayloadingpage'>
		<div className='d-flex justify-content-center align-items-center flex-column'>
			<BallTriangle
				heigth="100"
				width="100"
				color="#b2b7db"
				ariaLabel="loading-indicator"
			/>
			{msg && <div className="fs-3 mt-10" style={{ color : "#fff" }}>{msg}</div>}
			<div className="fs-3" style={{ color : "#fff" }}>{content || t('Please wait a second')}</div>
		</div>
	</div>
}

export const MLSpinner = ({title = "", spin = false}) => {

	const [t] = useTranslation(['translation']);

	return <>
		{spin && <span className="spinner-grow me-1" role="status" aria-hidden="true"></span>}
		{title}
	</>
}