import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FaPowerOff, FaPlay } from 'react-icons/fa';
import { MLPanelLoading } from '../common/MLPanel';
import { FeedBackConstant, FeedBackReducer } from '../../redux/reducers';
import { idData } from '../../libs/db';
import { COLORS } from '../../constants/FromInputs';
import { MLButton } from '../common/MLButton';
import { history } from '../../history';

const RMFeedBackInfo = ({ feedback, feedbacks }) => {
	const dispatch = useDispatch();
	const { _id } = useParams();
	const [loading, setLoading] = useState(true);
	const [info, setInfo] = useState({});

	useEffect(() => {
		if(!feedbacks && _id) {
            getData_dFeedBack();
        } else {
            genData();
        }
	}, [_id]);

    useEffect(() => {
        feedback?._id && setInfo(feedback);
    }, [feedback])

	const getData_dFeedBack = async () => {
		setLoading(true);

		try {
			const ret = await idData("rayteams-feedback", _id);

			dispatch({ type: FeedBackConstant.GET_FEEDBACK, item : ret.Items });
		} catch (e) {
			dispatch({ type: FeedBackConstant.GET_FEEDBACK, item : {} });
			console.log(e);
		}
		setLoading(false);
	}

    const genData = async () => {
        setInfo(feedbacks.filter(x => x._id === _id)[0])
        setLoading(false)
    }

    const Accept = async () => {}
    const Deny = async () => {}
    const Update = async () => {}

  	return (
    	<div>
			{loading &&  <>
				<h2 className='mb-4'> FeedBack Info </h2>
				<MLPanelLoading />
			</>}
			{!loading && (info?._id === _id) && <>
				<div className='card'>
                    <div className="card-header d-flex justify-content-between align-items-center">
                        <div className="card-label fw-bold text-gray-800">{info?.title}</div>
                        <div className="d-flex">
                            <MLButton options={{
                                label : 'Accept', 
                                color : COLORS.PRIMARY,
                                action: () => Accept()
                            }} css="me-1" />
                            <MLButton options={{
                                label : 'Deny', 
                                color : COLORS.DANGER,
                                action: () => Deny()
                            }} css="me-1" />
                            <MLButton options={{
                                label : 'Update', 
                                color : COLORS.SUCCESS,
                                action : () => Update()
                            }} css="me-3" />
                            <MLButton options={{
                                label : 'List', 
                                color : COLORS.DARK,
                                action: () => history.push("/FeedBacksPage")
                            }} />
                        </div>
					</div>
					<hr className='my-0'/>
                    <div className='card-body p-8'>
                        <div className='pb-4'>
                            <div className='label fw-bolder'>Create Date</div>
                            <div className=''>
                                <span>{moment(info?.created).format('LL')}</span>
                            </div>
                        </div>
                        <div className='pb-4'>
                            <div className='label fw-bolder'>App Name</div>
                            <div className=''>
                                <span>{info?.appname}</span>
                            </div>
                        </div>
                        <div className='pb-4'>
                            <div className='label fw-bolder'>Version</div>
                            <div className=''>
                                <span>{info?.version}</span>
                            </div>
                        </div>
                        <div className='pb-4'>
                            <div className='label fw-bolder'>Group Name</div>
                            <div className=''>
                                <span>{info?.groupinfo?.name}</span>
                            </div>
                        </div>
                    </div>
					{/* <div className='card-body p-8'>
						{
							Object.keys(info).map((key) => {
								return <div className='pb-4'>
								<div className='label fw-bolder'>{key}</div>
								<div className=''><span>{info?.[key]}</span></div>
							</div>
							})
						}
					</div> */}
				</div>
			</>}
    	</div>
  	);
}

const mapState = (state) => {
	const feedback = state.FeedBackReducer.feedback;
    const feedbacks = state.FeedBackReducer.feedbacks;
	return { feedback, feedbacks };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState)(RMFeedBackInfo);