import moment from "moment";
import { InfoAction } from "../redux/actions";
import { Store } from "../store";
import { JWT } from "../config";
import axios from "axios";
import { replaceVariablesText } from "./string";

const getEnv = () => {
    if (location.href.indexOf("localhost") > -1 || location.href.indexOf("127.0.0.1") > -1) {
      return "dev-local";
    } else if (location.href.indexOf("raydevelop") > -1) {
      return "development";
    }
    return "production";
  }

export const getProductEmailInfo = async (pid) => {
    const list = await InfoAction.callDB({ type : "query", region : "ap-northeast-2", params : {
        TableName: "rayteams-product",
        KeyConditionExpression: "#_id = :_id and #sk = :sk",
        ExpressionAttributeNames: { 
            '#_id': '_id', 
            '#sk': 'sk' 
        },
        ExpressionAttributeValues: {
            ':_id': pid,
            ':sk': "info",
        }
    }});
    const data = {
        Items: list,
    };

    return data?.Items && data.Items.length > 0 ? data.Items[0]?.emailTemplates : [];
};

export const getEmailTemplate = async (cid, lang) => {
    const list = await InfoAction.callDB({ type : "query", region : "ap-northeast-2", params : {
        TableName: "rayteams-manager-contents",
        KeyConditionExpression: "#_id = :_id and #sk = :sk",
        ExpressionAttributeNames: { 
            '#_id': '_id', 
            '#sk': 'sk' 
        },
        ExpressionAttributeValues: {
            ':_id': cid,
            ':sk': `lang:${lang}`,
        }
    }})
    const data = {
        Items: list,
    };

    return data?.Items && data.Items.length > 0 ? data.Items : [];
};

export const getUserPayLogData = async (_id, paylog) => {
    const list = await InfoAction.callDB({ type : "query", region : "ap-northeast-2", params : {
        TableName: "rayteams-user",
        KeyConditionExpression: "#_id = :_id and #sk = :sk",
        ExpressionAttributeNames: {
            "#_id": "_id",
            "#sk": "sk"
        },
        ExpressionAttributeValues: {
            ":_id": _id,
            ":sk": paylog
        }
    }});
    const data = {
        Items: list,
    };
    return data?.Items && data.Items.length > 0 ? data.Items : [];
};

// {{product.title}} - 상품명
// {{user.name}} - 이름
// {{product.refunddate}} - 환불 요청일
export const sendEmail = async (cid = "", lang, contentObject) => {
    const allusers = Store.getState().ProgramReducer.allusers || [];
    const allgroups = Store.getState().AuthReducer.groups || [];
    const userInfo = allusers && allusers.find(f => f._id === contentObject?.user?._id);
    const userGroupInfo = userInfo && allgroups && allgroups.find(g => g._id === userInfo.groupId);
    const emailTemplate = cid != "" ? await getEmailTemplate(cid, lang) : ""
    const payLogData = await getUserPayLogData(userInfo._id, contentObject?.user?.paylog);
    const refundData = {
        paylog: payLogData[0],
        groupInfo: {
            name: userGroupInfo?.name,
            address: userGroupInfo?.address
        },
        user: {
            name: userInfo?.name
        },
        product: {
            title: contentObject?.product?.title || "",
            rfamount: contentObject?.product?.rfamount,
            rfcomment: contentObject?.product?.rfcomment,
            rfdonetime: contentObject?.product?.rfdonetime,
            rfreqtime: contentObject?.product?.rfreqtime
        }
    };
    console.log("refundData : =======", refundData);
    const refundContent = await getGenInvoiceData(refundData);
    console.log("refundContent : =============", refundContent);
    moment.locale(lang || "en");
    const url = "https://api-manager-user." + (getEnv() === 'production' ? "rayteams.com" : "raydevelop.com") + "/sns/notification";
    let email = {
        subject: "",
        contents: "",
        force: true
    };
    if (emailTemplate[0] && emailTemplate[0]?.title) {
        email = {
            subject: replaceVariablesText(emailTemplate[0].title, refundData),
            contents: replaceVariablesText(emailTemplate[0].content, refundData),
            force: true
        };
    }

    email.contents = email.contents.replace("[invoice]", refundContent || "");

    const sendMailParams = {
        data: {
            userId: userInfo._id,
            pushTypes: ["email"],
            email: email,
            emailTemplateCode: "default",
        }
    };
    const sentRet = await axios.post(url, sendMailParams, JWT());
};

export const getGenInvoiceData = async (refundData) => {
    const rfEmailTemplate = await getEmailTemplate("da02de94-cd1e-4337-be28-e561dff841b8", "en");
    const invoiceData = {
        paylog: {
          when: refundData.paylog.when,
          paymentdate: moment.unix(refundData.paylog.when / 1000).format("yyyy-MM-DD HH:mm:ss"),
          serviceduration: moment.unix(refundData.paylog.when / 1000).format("yyyy-MM-DD") + " ~ " + moment.unix((refundData.paylog.when + (30 * 1000 * 60 * 60 * 24)) / 1000).format("yyyy-MM-DD"),
          countryname: refundData.paylog.ccode,
          title: refundData.paylog?.title,
          count: refundData.paylog?.count,
          punit: refundData.paylog?.punit,
          realprice: refundData.paylog?.realprice?.toLocaleString(),
          rfreqtime: moment.unix(refundData.product?.rfreqtime / 1000).format("yyyy-MM-DD"),
          rfamount: refundData.product?.rfamount.toLocaleString(),
          usageCharge: (refundData.paylog?.realprice || 0) - (refundData.product?.rfamount || 0)
        },
        userInfo: {
          groupname: refundData.groupInfo?.name || "",
          address: refundData.groupInfo?.address || "",
          name: refundData.user?.name || ""
        }
    };
    console.log("invoiceData : ===", invoiceData);
    return replaceVariablesText(rfEmailTemplate[0].content, invoiceData);
};