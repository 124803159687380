import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import { MLButton } from '../components/common/MLButton';
import { FaArrowRight, FaArrowDown } from 'react-icons/fa';

import { COLORS } from '../constants/FromInputs';
import { COGNITO, RegionList } from '../libs/cogInfo';

const Setting = ({ user, loginresult }) => {

	const dispatch = useDispatch();
    const [show, setShow] = useState(false);

	useEffect(() => {
	}, []);

    const startLogin = (reg) => {
        localStorage.setItem("requestGetlogin", reg);
        const appUrl = "http://localhost:3000/CogRedirect";
        const cofInfo = COGNITO[reg];
        var url = "https://mylab.auth." + reg + ".amazoncognito.com/login?client_id=";
        url += cofInfo.appId + "&response_type=code&scope=email+openid+phone+profile&redirect_uri=" + appUrl;
        window.open(url);
    }

  	return (
    	<div>
            <div className="header align-items-stretch d-block" style={{position: "initial"}}>
                <div className="d-flex w-100 justify-content-between p-5 align-items-center">
                    <div className="d-flex align-items-center text-dark fw-bolder fs-2 my-1">Setting</div>
                    <div className="d-flex align-items-center">&nbsp;</div>
                </div>
            </div>
            <div className="d-flex flex-column flex-column-fluid">
			    <div className="post p-7">
                    <div className='card'>
                        <div className='card-header'>
                            <h3 className='card-title'>Regions</h3>
                            <div className='card-toolbar'>
                                {!show && <FaArrowRight onClick={() => setShow(!show)} />}
                                {show && <FaArrowDown onClick={() => setShow(!show)} />}
                            </div>
                        </div>
                        {show && <ul className='list-group'>
                            {RegionList.map((x, k) => <li className='d-flex list-group-item justify-content-between' key={k}>
                                <div className='d-flex align-items-center'>
                                    {x.label}({x.value})
                                </div>
                                <div>
                                    {/*<MLButton options={{
                                        label : 'Get Token',
                                        color : COLORS.SUCCESS,
                                        size : "sm",
                                        action : () => startLogin(x.value)
                                    }} />*/}
                                </div>
                            </li>)}
                        </ul>}
                    </div>
                </div>
            </div>
    	</div>
  	);
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;

	return { user };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(Setting);