const initialState = { 
	notification : {},
	notifications : [],
	email : {},
    emails: [],
	contents : [],
	usercontent : {},
	usercontents : [],
};

export const NotifiCationConstant = {
	GET_ALL_NOTIFICATIONS : "GET_ALL_NOTIFICATIONS",
	GET_NOTIFICATION : "GET_NOTIFICATION",
	CREATE_NOTIFICATION : "CREATE_NOTIFICATION",
	UPDATE_NOTIFICATION : "UPDATE_NOTIFICATION",
};

export const NotificationReducer = (state = initialState, action) => {
	switch (action.type) {
		case NotifiCationConstant.GET_ALL_NOTIFICATIONS:
			return {...state, notifications : action.items };
		case NotifiCationConstant.GET_NOTIFICATION:
			return {...state, notification : action.item };
		case NotifiCationConstant.CREATE_NOTIFICATION:
			return {...state, 
				notification : action.item,
				notifications : [...state.notifications, action.item]
			};
		case NotifiCationConstant.UPDATE_NOTIFICATION:
			return {...state, 
				notification : action.item,
				notifications : state.notifications.map(x => x._id === action.item._id ? action.item : x)
			};
		default:
			return state;
	}
};
