import { useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import { MLPanelLoading } from '../../common/MLPanel';
import { ChartDays, ChartMonths } from '../../common/ChartDays';
import { SEARCHPERIOD } from '../../../constants/RMConstants';

const WidgetTopRateUsage = ({ period, type, title, appname = "", stats }) => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);
	const [topRate, setTopRate] = useState({ name: 0, percentage: 0});


	useEffect(() => {
		if(stats && period) {
			generate(stats, type, period, appname);
		}
	}, [period, stats]);

	const generate = (list, type, period) => {
		const clist = [];
		var searchItems = SEARCHPERIOD.find(y => y.value === period);
		if(period === "days" || period === "weeks" || period === "month") {
			ChartDays(searchItems.duration, searchItems.mcode, searchItems.dFormat).map(x => {
				list.filter(y => moment(y.sk?.split(":")[1]).format("YYYY-MM-DD") == x && y?._id?.toLowerCase().includes(appname != "" ? appname?.toLowerCase() : type.toLowerCase())).map(z => {
					if(!clist.filter(y => y.name === z._id && y.label == moment(z.sk?.split(":")[1]).format("YYYY-MM-DD")).length == 0) {
						var objIndex = clist.findIndex((obj => obj.name == z._id && obj.label == moment(z.sk?.split(":")[1]).format("YYYY-MM-DD")));
						clist[objIndex].count += z.called;
					} else
						clist.push({ name : z._id, count: z.called, label: moment(z.sk?.split(":")[1]).format("YYYY-MM-DD") });
				});
			});
		} else if(period === "months" || period === "all") {
			ChartDays(searchItems.duration, searchItems.mcode, searchItems.dFormat).map(x => {
				list.filter(y => moment([y.sk?.split(":")[1].substring(0,4), parseInt(y.sk?.split(":")[1].substring(4) -1)]).format(searchItems.dFormat) == moment(x).format(searchItems.dFormat) && y?._id?.toLowerCase().includes(appname != "" ? appname?.toLowerCase() : type.toLowerCase())).map(z => {
					if(!clist.filter(y => y.name === z._id && y.label == moment([z.sk?.split(":")[1].substring(0,4), parseInt(z.sk?.split(":")[1].substring(4) -1)]).format(searchItems.dFormat)).length == 0) {
						var objIndex = clist.findIndex((obj => obj.name == z._id && obj.label == moment([z.sk?.split(":")[1].substring(0,4), parseInt(z.sk?.split(":")[1].substring(4) -1)]).format(searchItems.dFormat)));
						clist[objIndex].count += z.called;
					} else
						clist.push({ name : z._id, count: z.called, label: moment([z.sk?.split(":")[1].substring(0,4), parseInt(z.sk?.split(":")[1].substring(4) -1)]).format(searchItems.dFormat) });
				});
			});
		} else 
			list.filter(x => x?.sk?.includes("M:") && x?._id?.toLowerCase().includes(type.toLowerCase())).map(x => {
				clist.push({ name : x._id, count: x.called, label: x.sk?.split(":")[1] });
			});
		
		const result = [];
		clist.reduce(function(res, value) {
			if (!res[value.name]) {
				res[value.name] = { name: value.name, count: 0 };
				result.push(res[value.name])
			}
			res[value.name].count += value.count;
			return res;
		}, {});

        const total = result.reduce((a, i) => a + i.count, 0)
        const topList = result.map(x => ({...x, percentage: Math.round(x.count * 100 / total)}))

        setTopRate({ name: topList.sort((a, b) => a.count > b.count ? -1 : 1).slice(0, 1)[0]?.name, percentage: topList.sort((a, b) => a.count > b.count ? -1 : 1).slice(0, 1)[0]?.percentage});

		setLoading(false);
	}

  	return <>
		{!loading && 
            <div className='col-12 col-sm-6 col-md-3 col-lg-6 col-xl-3 col-xxl-12'>
                <div className='d-flex align-items-center'>
                    <div className="ms-2"> {topRate?.percentage && <>
                        <div className="d-flex align-items-end">
                            <h2 className="mb-1 me-3">{topRate?.percentage}%</h2><span className="fs-1 fw-semi-bold text-900">{topRate?.name}</span>
                        </div>
                        <p className="text-800 fs--1 mb-0">{title}</p>
						</>}
                    </div>
                </div>
            </div>
        }
		{loading && <MLPanelLoading />}
	</>
}

const mapState = (state) => {
    const logs = state.UsageReducer.logs;
	const dailylogs =  state.UsageReducer.dailylogs;
	const stats = state.UsageReducer.stats;
	return { logs, dailylogs, stats };
};

export default connect(mapState)(WidgetTopRateUsage);