import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { MLText } from '../../components/common/MLInput';
import { MLPanelLoading } from '../../components/common/MLPanel';
import Select from 'react-select';
import MLTable from '../../components/common/MLTable';
import { AuthConstant, getAPI, getHeader } from '../../redux/reducers/AuthReducer';
import { fetchData } from '../../libs/db';
import WidgetBarChartAppUsage from '../../components/widgets/dashboard/WidgetBarChartAppUsage';
import RMWidgetBarChart from '../../components/widgets/RMWidgetBarChart';
import RMWidgetLineChart from '../../components/widgets/RMWidgetLineChart';
import SimpleDayChart from '../../components/widgets/SimpleDayChart';

import { COUNTRIES } from '../../constants/Country';
import { MLButton, MLRefreshButton } from '../../components/common/MLButton';
import { history } from '../../history';
import { COLORS } from '../../constants/FromInputs';
import { RMIconList, RMIconChart } from '../../components/icon/MLIcon';
import { MLPanelNoData } from '../../components/common/MLPanel';

import { ProgramConstant, PaymentConstant } from '../../redux/reducers';
import { useTranslation } from 'react-i18next';
const UserChart = ({ items }) => {
	const dispatch = useDispatch();
    const [t] = useTranslation(['translation']);
    const [groups, setGroups] = useState([]);
    const remainDay = 30;
    const remain = new Date().getTime() + (remainDay * 24 * 3600 * 1000);


	useEffect(() => {
        getGroups();
	}, [items]);

    const getGroups = () => {
        let list = [];
        items.map(x => {
            const ex = list.find(l => l.groupId === x.groupId);
            if(ex){
                list = list.map(l => l.groupId === ex.groupId ? {...l, count : l.count + 1} : l); 
            }else{
                list.push({ groupname : x.groupname, groupId : x.groupId, countryCode : x.countryCode, count : 1});
            }
        });
        setGroups(list);
    }

    const headers = [
		{ link : "groupId", linkpath : "/GroupInfo", value : "groupname", label : t("Group"), css : "text-start", style : { width : 250 } },
		{ value : "countryCode", label : t("Country"), type : "COUNTRY"},
		{ value : "count", label : t("Count"), css : "text-end" },
    ]

  	return (<>
        {items && <div>
            <div className='row mb-4'>
                <div className='col'>
                    <SimpleDayChart data={items} title={t("가입 현황")} period={"days"} target={"created"} max={30} />
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <RMWidgetBarChart data={items} type={"Country"} title={t("국가별 사용자수(Top 10)")} target={"countryCode"} max={10} />
                </div>
                <div className='col'>
                    <div className="card">
                        <div className="card-header">
                            <h5 className='card-title mb-0'>{t("그룹별 사용자수(Top 5)")}</h5>
                        </div>
                        <MLTable headers={headers} items={groups.sort((a, b) => a.count > b.count ? -1 : 1).slice(0,5)} noheader={true} />
                    </div>
                </div>
            </div>
        </div>}
    </>
  	);
}

const mapState = (state) => {
	const region = state.AuthReducer.region;
	return { region };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, null)(UserChart);
