import axios from 'axios';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { MLComboSingle, MLText, MLTextArea, MLToggle } from '../../components/common/MLInput';
import { MLPanelLoading, MLPanelNoData } from '../../components/common/MLPanel';
import { LANGUAGE } from '../../constants/RMConstants';
import { RTButton } from '../../libs/buttons';
import { COLORS } from '../../config/color';
import { Modal } from 'react-bootstrap';
import { fetchData_prod } from '../../libs/db';
import { MLButton } from '../../components/common/MLButton';
import { S3Download, S3Keys, S3Upload } from '../../libs/aws';
import { AlertConstant } from '../../redux/reducers';
import MLToast from '../../components/common/MLToast';

const LanguagePage = ({ groups, region, regionusers, alldevices }) => {
	const dispatch = useDispatch();

	const langKeyBase = "https://rayapps.s3.ap-northeast-2.amazonaws.com/rayteams-lang/";
	const langKeyPath = "rayteams-lang/";
	const bucket = "rayapps";

	const starts = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "Other"];

	const [showAllkey, setShowAllkey] = useState(false);
	const [curLang, setCurLang] = useState("");
	const [curApp, setCurApp] = useState("");
	const [curChar, setCurChar] = useState("A");
	const [apps, setApps] = useState([]);
	const [curLangData, setCurLangData] = useState({});
	const [langs, setLangs] = useState([]);
	const [search, setSearch] = useState("");
	const [showType, setShowType] = useState("list");
	const [addLang, setAddLang] = useState("");
	const [items, setItems] = useState([]);
	const [loading, setLoading] = useState(false);
	const [addD, setAddD] = useState("");
	const [modalData, setModalData] = useState({
		show: false,
		title: "Add Language Keys"
	});

	useEffect(() => {
		console.log('start');
		getApps();
		console.log('end');
	}, []);

	useEffect(() => {
		donwFile();
	}, [curLang]);

	useEffect(() => {
		//if(curApp){
		setCurLang("");
		setCurLangData({});
		downKeys();
		gen();
		//}
	}, [curApp]);

	useEffect(() => {
		console.log('apps========================================');
		console.log(apps);
	}, [apps]);

	const getApps = async () => {
		console.log("get data");
		try {
			const ScanFilter = {
				type: {
					ComparisonOperator: "EQ",
					AttributeValueList: ["app"],
				}
			}
			const ret = await fetchData_prod("rayrnd-applications", ScanFilter);

			console.log(ret);

			setApps([
				{ value: "rthomepage", label: "RAYTEAMS Homepage" },
				{ value: "rtpartner", label: "RAYTEAMS Partner" },
				{ value: "rtdeveloper", label: "RAYTEAMS Developer" },
				...ret.Items.filter(x => x.title).map(x => ({ value: x.name, label: x.title }))]);
			setCurApp("");
		} catch (err) {
			console.log(err);
		}
	}

	const gen = async () => {
		console.log("get data for app");
		try {
			const ret = await S3Keys(bucket, langKeyPath + curApp + "/");
			if (ret?.Contents) {
				const rlist = ret.Contents.map(x => x.Key.replace(langKeyPath + curApp + "/", "")).filter(x => x !== "keys" && x.includes(".json")).map(x => x.replace(".json", ""));
				setLangs([...rlist.filter(x => LANGUAGE.find(z => x == z.code))]);
			} else {
				setLangs([]);
			}
		} catch (err) {
			console.log(err);
		}
	}

	const saveLang = (l) => {
		var vals = {};
		items.map(x => {
			vals[x] = x
		});
		console.log(langKeyPath + curApp + "/" + l + ".json");
		S3Upload(bucket, langKeyPath + curApp + "/" + l + ".json", JSON.stringify(vals), "application/json");
		dispatch({ type: AlertConstant.NOTICE, msg: "Saved!", show: true });
	}

	const downKeys = async () => {
		if (!curApp)
			return;
		try {
			const ret = await axios(langKeyBase + curApp + "/keys");
			if (ret?.data) {
				setItems(ret.data.split(/\n/));
			} else {
				setItems([]);
			}
		} catch {
			setItems([]);
		}
		setCurLangData([]);
	}

	const donwFile = async () => {
		if (!curLang || !curApp)
			return;
		console.log(langKeyBase + curApp + "/" + curLang + ".json");
		const ret = await axios(langKeyBase + curApp + "/" + curLang + ".json");
		setCurLangData(ret.data);
	}

	const pub = () => {
		S3Upload(bucket, langKeyPath + curApp + "/" + curLang + ".json", JSON.stringify(curLangData), "application/json");
		dispatch({ type: AlertConstant.NOTICE, msg: "Saved!", show: true });
	}

	const importKeys = () => {
		setModalData({
			show: true,
			title: "Add Keys",
		});
	}

	const unItemCount = (list) => {
		const ncv = list?.filter(x => curLangData[x] === x)?.length;
		return ncv === 0 ? <></> : <span className='fw-bolder'>{ncv}/</span>;
	}

	const getItemsChar = (c) => {
		if (c !== "Other") {
			return items.
				filter(x => curChar ? x.toUpperCase().startsWith(c) : true).
				filter(x => search ? x.includes(search) : true);
			//filter(x => showAllkey ? curLangData[x] === x : true);
		} else {
			return items.
				filter(x => !starts.includes(x.toUpperCase().substring(0, 1))).
				filter(x => search ? x.includes(search) : true);
			//filter(x => showAllkey ? curLangData[x] === x : true);
		}
	}

	const delKey = (k) => {
		if (window.confirm("Really?")) {
			var ls = [...items.filter(x => x !== k)];
			S3Upload(bucket, langKeyPath + curApp + "/" + "keys", ls.join("\n"), "text/plain");
			setItems(ls);
		}
	}

	const addLangKeys = () => {
		var ls = [...items];
		addD.split(/\n/).map(x => {
			if (!items.includes(x))
				ls.push(x)
		})
		S3Upload(bucket, langKeyPath + curApp + "/" + "keys", ls.join("\n"), "text/plain");
		setItems(ls);
		setModalData({
			show: false,
			title: "Add Keys",
		});
		setAddD("");
	}

	return (<>
		<div className="header align-items-stretch d-block" style={{ position: "initial" }}>
			<div className="d-flex w-100 justify-content-between p-5 align-items-center">
				<div className="d-flex align-items-center text-dark fw-bolder fs-2 my-1">Language</div>
				<div className="d-flex align-items-center">
					<MLComboSingle options={{
						value: curApp,
						list: apps,
					}} handleChanged={(e) => setCurApp(e.target.value)} css="w-250px me-2" />
				</div>
			</div>
		</div>
		{!curApp && <>
			<div className='d-flex flex-column flex-column-fluid p-4'>
				<div className='post p-7'>
					<MLPanelNoData title='앱 선택' contents='다국어 작업할 앱을 먼저 선택해주세요.' />
				</div>
			</div>
		</>}
		{curApp && <>
			<div className='d-flex flex-column flex-column-fluid p-4'>
				<div className='post p-7'>
					<div className='card'>
						<div className='card-body pb-0'>
							<div className='d-flex justify-content-between'>
								<h2>{apps?.find(x => x.value === curApp)?.label}</h2>
								<div className='d-flex'>
									<MLText options={{
										placeholder: "검색어 입력",
										size: "sm",
										value: search || "",
									}} handleChanged={(e) => setSearch(e.target.value)} css="ms-2" />									
								</div>
							</div>
						</div>
						<hr class="mb-0"></hr>
						<div className='card-header'>
							<ul className='detail-tab nav nav-line-tabs nav-stretch'>
								<li className={'nav-item '}><a className={'nav-link ' + (showType === "list" ? "active" : "")} onClick={() => setShowType("list")}>Keys</a></li>
								<li className={'nav-item '}><a className={'nav-link ' + (showType === "lang" ? "active" : "")} onClick={() => setShowType("lang")}>Language</a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			{showType === "lang" && <div className="d-flex flex-column flex-column-fluid">
				<div className="post p-7">
					<div className="card card-flush h-md-100">
						<div className="card-header pt-5">
							<h3 className="card-title align-items-start flex-column ps-4">
								<span className="card-label fw-bold text-gray-800">{"Supported Language"}</span>
							</h3>
							<div className="card-toolbar">
								<MLComboSingle options={{
									value: addLang,
									list: LANGUAGE.map(x => ({ value: x.code, label: x.label })),
								}} handleChanged={(e) => setAddLang(e.target.value)} css="w-200px" />
								<MLButton options={{
									label: "Add",
									needValid: true,
									size: "sm",
									color: COLORS.PRIMARY,
									action: () => { setLangs([...langs, addLang]); setAddLang(""); }
								}} valid={addLang.trim().length > 0} />
							</div>
						</div>
						{!loading && <>
							<div className='list-group mt-5 border-t'>
								{langs.map(x => <div key={x} className='list-group-item  d-flex justify-content-between align-items-center'>
									<div className='fw-bolder'>
										{LANGUAGE.find(ln => ln.code === x)?.label}
									</div>
									<div>
										<RTButton options={{
											label: "Save",
											size: "sm",
											color: COLORS.INFO,
											action: () => saveLang(x)
										}} css="me-2" />
										<RTButton options={{
											label: "Remove",
											size: "sm",
											color: COLORS.DANGER,
											action: () => setLangs(langs.filter(l => l !== x))
										}} />
									</div>
								</div>)}
							</div>
						</>}
						{loading && <MLPanelLoading />}
					</div>
				</div>
			</div>}
			{showType === "list" && <div className="d-flex flex-column flex-column-fluid p-4">
				<div className="post p-7">

					<div className="card card-flush h-md-100">
						<div className="card-header pt-5">
							<h3 className="card-title align-items-start flex-column">
								<span className="card-label fw-bold text-gray-800">
									<span className='text-primary fw-bolder me-2 fs-1'>
										{LANGUAGE.find(x => x.code === curLang)?.label}
									</span>
									{search ? search : "All Keys"}
									<span className="badge badge-secondary ms-2">
										{items && items.filter(x => search ? x.includes(search) : true).length}
									</span>
								</span>
							</h3>
							<div className="card-toolbar">
								<MLComboSingle options={{
									value: curLang,
									placeholder: "언어 선택",
									list: langs.map(x => ({ value: x, label: LANGUAGE.find(c => c.code == x)?.label || "" })),
								}} handleChanged={(e) => setCurLang(e.target.value)} css="w-200px ms-2" />
								{curLang && curApp && <RTButton options={{
									label: "Save",
									size: "sm",
									color: COLORS.PRIMARY,
									action: () => pub()
								}} />}
							</div>
						</div>
						{!loading && <>
							<div className='card-body pt-8'>
								{starts.map(x => {
									const ncAll = getItemsChar(x);
									const nc = ncAll.length;
									if (nc) {
										return <MLButton options={{
											label: <>{x}<span className="ms-1">{unItemCount(ncAll)}{nc}</span></>,
											size: "sm",
											color: curChar === x ? COLORS.PRIMARY : COLORS.SECONDARY,
											action: () => setCurChar(x)
										}} css="me-1 p-2" />
									}
								})}
							</div>
							<div className='card-body pt-8'>
								{getItemsChar(curChar).map(x => <div className='mb-7'>
									<label className='fw-bolder fs-2'> {x} </label>
									<a onClick={() => delKey(x)} className="cursor-pointer ms-5 text-danger fs-8 text-end">DEL</a>
									{curLang && <MLText options={{
										size: "lg",
										value: curLangData[x] || x,
									}} handleChanged={(e) => setCurLangData({ ...curLangData, [x]: e.target.value })} css={curLangData[x] !== x ? "" : "red"} />}
								</div>)}
							</div>
						</>}
						{loading && <MLPanelLoading />}
					</div>
				</div>
			</div>}
		</>}
		<Modal show={modalData.show} size={"lg"} style={{ backgroundColor: "#42476c !important" }}>
			<Modal.Header className='modal-header header-bg'>
				<h2>Add Language Keys</h2>
			</Modal.Header>
			<Modal.Body className='flex-fill pt-0 formcard pt-6'>
				<div>
					<div className='fs-5 mb-2'>Add Language Keys(한줄에 1개의 Key 작성)</div>
					<div>
						<MLTextArea options={{
							name: "addD",
							value: addD,
							rows: 10,
						}} handleChanged={(e) => setAddD(e.target.value)} />
					</div>
				</div>
				<div className='mt-4'>
					<MLButton
						options={{ label: "Cancel", size: "sm", color: COLORS.SECONDARY, action: () => setModalData({ ...modalData, show: false }) }}
						css={'me-1'} />
					<MLButton
						valid={addD.length > 0}
						options={{ label: "Add Keys", size: "sm", needValid: true, color: COLORS.PRIMARY, action: () => addLangKeys() }}
						css={'me-1'} />
				</div>
			</Modal.Body>
		</Modal>
	</>
	);
}

const mapState = (state) => {
	const groups = state.AuthReducer.groups;
	const region = state.AuthReducer.region;
	const regionusers = state.AuthReducer.regionusers;
	const alldevices = state.ProgramReducer.alldevices;
	return { groups, region, regionusers, alldevices };
};

const mapDispatch = (dispatch) => ({

});

export default connect(mapState, null)(LanguagePage);
