import { Modal } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { BUTTONS, COLORS, INPUTS, SPAN } from '../../constants/FromInputs';
import { MLButton } from '../common/MLButton';
import { MLForm } from '../common/MLForm';
import { RMSUBTYPES } from '../../constants/RMConstants';
import { AuthConstant, getAPI, getHeader } from '../../redux/reducers/AuthReducer';
import moment from 'moment';
import axios from 'axios';
import { BoardAction } from '../../redux/actions';

const RMModalAddContent = ({ board, modalData, callbackClose }) => {

	const dispatch = useDispatch();
    const [data, setData] = useState({ title : "" });

	useEffect(() => {
		if(modalData.show){
			setData({ title : "" });
		}
	}, [modalData.show]);

	const addBoard = async () => {
		console.log(board);
		try{
			dispatch(BoardAction.CreateContentInBoard(board._id, data));
		}catch(err){
			console.log(err);
		}
		callbackClose && callbackClose();
	}

    const controls = [
		{ 
			type : INPUTS.TEXT, 
			options : { 
				label : '제목',
				name : 'title',
				required : true
			},
		},
		{ 
			type : INPUTS.TEXTAREA, 
			options : { 
				label : '간단한 내용',
				name : 'description',
				rows : 5,
			},
		},
		{
			type : SPAN.VERTICAL,
			options : { size : 10 }
		},
		{
			type : BUTTONS.BUTTONS, 
			options : { },
			buttons : [{
				type : BUTTONS.NORMALBUTTON, 
				options : { 
					label : 'Add', 
					color : COLORS.PRIMARY,
                    action : () => addBoard()
				}
			},{
				type : BUTTONS.NORMALBUTTON, 
				options : { 
					label : 'Cancel', 
					color : COLORS.SECONDARY,
                    action : () => callbackClose()
				}
			}]
		}
	];

	return <div className=''>
		<Modal show={modalData.show} size={"lg"} style={{ backgroundColor: "#42476c !important"}}>
			<Modal.Header className='modal-header header-bg'>
				<h2>Content 추가</h2>
			</Modal.Header>
				<div className='alert alert-info p-5 fw-bolder' style={{ borderRadius : 'initial' }}>
					<div>Content는 사용자에게 보여지는 내용이 아닙니다.</div>
					<div>사용자에게 보여질 User Content는 Content를 생성한 후에 작성가능합니다.</div>
				</div>
			<Modal.Body className='flex-fill pt-2 formcard'>
				<MLForm controls={controls} data={data} nocard={true} onChanged={(e) => { console.log(e);setData(e);}} />
			</Modal.Body>
		</Modal>
	</div>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const region =  state.AuthReducer.region;
	const board =  state.BoardReducer.board;
	return { region, user, board };
};

const mapDispatch = dispatch => ({
})

export default connect(mapState, mapDispatch)(RMModalAddContent);