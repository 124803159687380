import axios from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { MLButton } from '../../components/common/MLButton';
import { MLPanelLoading } from '../../components/common/MLPanel';
import { COLORS, SETTING } from '../../constants/FromInputs';
import MLTable from '../../components/common/MLTable';
import { InfoAction } from '../../redux/actions';
import { ProgramConstant } from '../../redux/reducers';
import { history } from '../../history';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
const LabProductCategoryPage = ({ user, region, products }) => {
	const [t] = useTranslation(['translation']);
	const [items, setItems] = useState([]);
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch({ type : ProgramConstant.SET_PAGE, page : { 
			code : "b2bproductcategory", 
			view : "summary", 
			title : "Product Category", 
			small : t("Product Category 관련 정보"), 
			options : [
			]
		}})
        getData();
	}, []);

    const getData = async () => {
		setLoading(true);
		let ret = await InfoAction.callDB({ type : "scan", region : "ap-northeast-2", params : {
			TableName: "rayteams-product",
			FilterExpression: "#sk=:sk",
			ExpressionAttributeNames: { "#sk": "sk" },
			ExpressionAttributeValues: { ":sk": "category" },
		}});
		ret = _.orderBy(ret, ["visible", "order"], ["desc", "asc"]);
		setItems(ret);
		setLoading(false);
    };

	const headers = useMemo(() => {
		return [
			{ link : "_id", linkpath : "/LabProductCategoryInfoPage", value : "title", label : t("Title"), css : "text-start", style : { width : 450 } },
			{ value : "visible", label : t("Visible"), type: "TFVALUE", css : "text-center" },
			{ value : "order", label : t("Order"), css : "text-center" },
			{ value : "created", label : t("Created"), type : "DATE", css : "text-end" },
		];
	}, [t]);

	return <div className='p-4'>
		<div className="card">
			<div className="card-header d-flex justify-content-between align-items-center">
				<div className="card-label fw-bold text-gray-800 fs-5">{items.length}</div>
				<div className="d-flex">
						<MLButton options={{
						code: "prodcate-add",
						color : COLORS.PRIMARY,
						size : "sm",
						label : t("Add Product Category"),
						action : () => history.push("/LabProductCategoryAddPage"), //setModal({ show : true })
					}} css="me-2"/>
				</div>
			</div>
			{!loading && <MLTable 
				headers={headers} 
				items={items} noheader={true} />}
			{loading && <MLPanelLoading />}
		</div>
	</div>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const region =  state.AuthReducer.region;
    const products = state.ProductReducer.products;
	return { region, user, products };
};

const mapDispatch = (dispatch) => ({
});

export default connect(mapState, mapDispatch)(LabProductCategoryPage);