import { connect, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { history } from '../../history';
import { BUTTONS, COLORS, INPUTS, SPAN } from '../../constants/FromInputs';
import { MLButton } from '../common/MLButton';
import { BoardAction } from '../../redux/actions';
import { Link, useParams } from 'react-router-dom';
import { BoardConstant, ProgramConstant } from '../../redux/reducers';
import { callDB } from '../../libs/db';
import { useTranslation } from 'react-i18next';

const RMBoardInfoPaenl = ({ board, notab = false, user }) => {

	const [t] = useTranslation(['translation']);
	const dispatch = useDispatch();
	const { _bid, _cid, _tab = "list" } = useParams();
	const [loading, setLoading] = useState(true);
	const tabs = [
		{value : "list", label : "Contents"},
		{value : "menu", label : "Menu", enduser : true},
	]

	useEffect(() => {
		if(_bid)
        	getData();
	}, []);

	useEffect(() => {
		dispatch({ type : ProgramConstant.SET_PAGE, page : { 
			code : "contentsmanager", 
			view : "", 
			title : board?.title, 
			small : t("Content manager"), 
			options : [
			]
		}})
	}, [board?._id]);

    const getData = async () => {
		setLoading(true);
		try{
			const ret = await callDB({
				type : "query",
				region : "ap-northeast-2",
				params : {
					TableName: "rayteams-manager-boards",
					KeyConditionExpression: "#_id = :_id and #sk = :sk",
					ExpressionAttributeNames: { "#_id": "_id", "#sk": "sk" },
					ExpressionAttributeValues: { ":_id": _bid,  ":sk": "info" }
				},
				filter : {}
			});
			dispatch({ type: BoardConstant.GET_BOARD, item: ret[0] });
		}catch(err){
			console.log(err);
		}
		setLoading(false);
    }

    const userdata = localStorage.getItem("userdata");
	
	const goList = () => {
		if(board.sk.indexOf("spt:") > -1){
			if(_cid){
				history.push('/SupportContent/' + board._id + (_cid ? "/" + _cid : ""));
			}else{
				history.push('/SupportInfo/' + board._id);
			}
		}else{
			history.push('/Board/' + board._id);
		}
	}

	const permHQ = () => {
		if(user?.email.indexOf('@rayteams.com'))
			return true;

		if(board?.type === 'MANUALDOC'){
			if(process.env.REACT_APP_COMPANY === 'ray'){
				return true;
			}
			return false;
		}
		return true;
	}

	console.log(process.env.REACT_APP_COMPANY);
	console.log(board);
	console.log(user?.email.indexOf('@rayteams.com'));

	const showFilter = (x) => {

	}

	return <div className="card">
		<div className={notab ? "card-header" : "card-header pb-0"}>
			<div className="d-flex justify-content-between align-items-center">
				<div className="card-label fw-bold text-gray-800 fs-5">{board?.title} <small className='text-muted ms-2'>{board?.type}</small></div>
				<div className="d-flex">
					{userdata && <div className="d-flex align-items-center">
						{!(window.location.href.indexOf("/UserContentAdd") > -1) && permHQ() && <MLButton options={{
							
                            code: "cm-adduc",
							label : t('글 등록'),
							color : COLORS.PRIMARY,
							size : 'md',
							action : () => board.sk.indexOf("spt:") > -1 ? 
								history.push('/SupportUserContentAdd/' + board._id) : 
								history.push('/UserContentAdd/' + board._id),
						}} css="ms-1" />}
						{<MLButton options={{
							label : t('목록'),
							color : COLORS.DARK,
							size : 'md',
							action : () => goList() 
						}} css="ms-1" />}
					</div>}
				</div>
			</div>
			{!notab && <ul className="nav nav-tabs nav-stretch border-top mt-4 pt-2" style={{ borderRadius: "0" }}>
				{tabs.map((x, idx) => {
					return <li className="nav-item" key={idx} onClick={() => history.push("/Board/" + (_bid) + "/" + x.value)}>
						<Link to="#" className={"nav-link " + (_tab === x.value ? ' active ' : '')}>{x.label}</Link>
					</li>
				})}
			</ul>}
		</div>
	</div>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const region =  state.AuthReducer.region;
	const board =  state.BoardReducer.board;
	return { region, user, board };
};

const mapDispatch = dispatch => ({
})

export default connect(mapState, mapDispatch)(RMBoardInfoPaenl);
