import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import RMProjectsList from '../../components/project/RMProjectsList';
import Select from 'react-select';
import { MLPanelLoading } from '../../components/common/MLPanel';
import { COUNTRIES } from '../../constants/Country';
import { RMIconList, RMIconChart } from '../../components/icon/MLIcon';
import { MLComboSingle } from '../../components/common/MLInput';
import { MLButton, MLRefreshButton, MLChartButton } from '../../components/common/MLButton';
import { TbLayoutDashboard } from 'react-icons/tb';
import { ProgramConstant } from '../../redux/reducers';
import { fetchData } from '../../libs/db';
import { SEARCHPERIOD } from '../../constants/RMConstants';
import { MLText } from '../../components/common/MLInput';
import { MLFileSize } from '../../components/common/MLCommon';
import MLTable from '../../components/common/MLTable';
import { ProjectConstant } from '../../redux/reducers/ProjectReducer';

import ProjectStatCard from '../../components/project/ProjectStatCard';
import ProjectChart from '../../components/project/ProjectChart';
import MLFilter, { CreateScanFilter } from '../../components/common/MLFilter';
import MLFilterList from '../../components/common/MLFilterList';
import { InfoAction } from '../../redux/actions';
import { useTranslation } from 'react-i18next';
import ProjectPaidColumn from '../../components/project/ProjectPaidColumn';

const ProjectsPage = ({ projects, groups, pagestat, page, filters, config }) => {
	const [t] = useTranslation(['translation']);
	const dispatch = useDispatch();
	const [more, setMore] = useState(true);
	const [search, setSearch] = useState(pagestat?.search || "");
	const [filter, setFilter] = useState(pagestat?.filter || [
        { code : "countryCode", value : "", list : [] },
    ]);
	const [showFilter, setShowFilter] = useState(false);
	const [ items, setItems ] = useState([]);
	const [ loading, setLoading ] = useState(false);
	const [ tabs, setTabs ] = useState("All");
	const [ period, setPeriod ] = useState("days");
	const mgroup = JSON.parse(localStorage.getItem("mgroup"));
	const [fs, setFs] = useState([]);
	const [reloadStack, setReloadStack] = useState(1);

	useEffect(() => {
		dispatch({ type : ProgramConstant.SET_PAGE, page : { 
			code : "projects", 
			view : "list", 
			title : "Cases", 
			small : t("Case 관련 정보"), 
			options : (process.env.REACT_APP_COMPANY === 'ray' || !process.env.REACT_APP_COMPANY) ?  [
				{ view : "summary" }, 
				{ view : "list" } 
			] : []
		}})
	}, []);

	useEffect(() => {
		dispatch(InfoAction.GetUserFilters("projects"));
        if(page?.view)
            setMore(page.view === "summary");
	}, [page]);
	
	useEffect(() => {
		getData();
	}, [filters, reloadStack]);

	useEffect(() => {
        if(projects)
            genFilter();
	}, [projects])

	useEffect(() => {
        if(projects){
            genData();
            dispatch({ type: ProgramConstant.PAGESTAT, item: { caselist : { search, filter, 
                order : pagestat.order, 
                desc : pagestat.desc 
            }} });
        }
	}, [filter, search, tabs, period, pagestat.order, pagestat.desc]);

	const genFilter = () => {
        setFilter( filter.map(x => {
            const list = [...new Set(projects.map(m => m[x.code]))];
            return {...x, list : [{ value : "", label : "ALL" }, ...list.map(n => ({ 
                value : n, 
                label : fLabel(x.code, n)
            }))] }
        }));
	}

    const fLabel = (code, value) => {
        if(value === true || value === false){
            return value === true ? "Active" : "Expired";
        }else if(code === "countryCode"){
            return COUNTRIES.find(x => x.countryCode === value)?.name || "UNKNOWN";
        }
        return value;
    }

	const genData = () => {
		const _items = projects
			.filter(x => search ? (x => x.ownergroup?.indexOf(search) > -1 || x.lab?.indexOf(search) > -1) : true)
			.filter(x => filterCheck(x))
			.sort((a, b) => pagestat.desc === "desc" ? (a[pagestat.order] > b[pagestat.order] ? -1 : 1) : (b[pagestat.order] > a[pagestat.order] ? -1 : 1));
		if (config.useouterpayment && config.outerpaymenturl?.trim()) {
			for (const _item of _items) {
				_item.outerpaidEl = <ProjectPaidColumn 
					project={{ ..._item }} 
					callbackRefresh={() => setReloadStack(prev => prev + 1)} 
				/>
			}
		}
        setItems(_items);
	}

    const filterCheck = (item) => {
        var tf = true;
        filter.map(x => {
            if(tf)
                tf = (item[x.code] === x.value || x.value === "");
        });
        return tf;
    }

	const handleFilter = (e, item) => {
        setFilter( filter.map(x => x.code === item.code ? {...x, value : e.value } : x));
	}

	const getData = async () => {
		setLoading(true);
		let ScanFilter = {
			isDel: {
				ComparisonOperator: "EQ",
				AttributeValueList: [false],
			},
			sk: {
				ComparisonOperator: "EQ",
				AttributeValueList: ["project"],
			},
		}

		const pageFilter = filters.find(x => x.page === page.code);
		if(pageFilter){
			pageFilter.fs.map(x => CreateScanFilter(ScanFilter, x));
		}

		try {
			const ret = await fetchData("rayteams-project", ScanFilter);
			var list = [];
			list = ret.Items.map(x => {
				return {...x,
					patientname : x.subject?.name || "",
					ownergroup: groups.find(y => y._id === x?.ownerGroupId)?.name || "",
					lab: groups.filter(y => y._id === x?.labId)[0]?.name || "",
					countryCode: groups.find(y => y._id === x?.ownerGroupId)?.countryCode || "",
					filesize: MLFileSize(x?.size)
				}
			});
			dispatch({ type : ProjectConstant.GET_ALL_PROJECTS, items : list });
		} catch (e) {
			console.log(e)
			dispatch({ type : ProjectConstant.GET_ALL_PROJECTS, items : [] });
		}
		setLoading(false);
	}

	const getTotalCount = async () => {
		try	{
			const ScanFilter = {
				sk: {
					ComparisonOperator: "EQ",
					AttributeValueList: ["project"],
				}
			}
			const ret = await countData("rayteams-project", ScanFilter);
			return ret.Count;
		} catch {
			return 0;
		}
	}

	const goLink = (to, title) => {
		dispatch({ type : ProgramConstant.GO_LINK, to, title });
	}

	const headers = useMemo(() => {
		const isRay = process.env.REACT_APP_COMPANY === 'ray' || !process.env.REACT_APP_COMPANY;
		let _headers = [
			{ value : "name", label : "Project Name", css : "text-start" },
			{ value : "outerpaidEl", label : "Payment", style : { width : 130 }, },
			{ value : "projecttype", label : "Project Type", type : "BADGE" },
			{ link : "ownerGroupId", linkpath : "/GroupInfo", value : "ownergroup", label : "Clinic", css : "text-start" },
			{ link : "labId", linkpath : "/GroupInfo", value : "lab", label : "Lab", css : "text-start" },
			{ value : "created", label : "Created", type : "DATESHORT", style : { width : 130 }, css : "text-center" },
			{ value : "updated", label : "Updated", type : "DATESHORT", style : { width : 130 }, css : "text-center" }
		];
		if (!isRay) {
			const allowB2bField = [
				"name", "outerpaidEl", "ownerGroupId", "created", "updated",
			];
			_headers = _headers.filter(x => allowB2bField.includes(x.value))
		}
		if (!config.useouterpayment || !config.outerpaymenturl?.trim()) {
			_headers = _headers.filter(x => "outerpaidEl" !== x.value);
		}
		return _headers;
	}, [config?.outerpaymenturl, config.useouterpayment]);

	const headerClick = () => {
		dispatch({ type: ProgramConstant.PAGESTAT, item: { caselist : { search, filter, 
            order : pagestat.order, 
            desc : (pagestat.desc === "desc" ? "asc" : "desc") }} });
	}

  	return <div className='p-4'>
		{(process.env.REACT_APP_COMPANY === 'ray' || !process.env.REACT_APP_COMPANY) && <div className='mb-4'>
			<ProjectStatCard items={projects}/>
		</div>}
        {more && <ProjectChart items={projects}/>}
		{!more && <div className="card">
			<div className="card-header d-flex justify-content-between align-items-center">
				<div className="card-label fw-bold text-gray-800">{projects.length}</div>
				<div className="d-flex">
					{(process.env.REACT_APP_COMPANY === 'ray' || !process.env.REACT_APP_COMPANY) && <>
						<MLFilterList page={page} filter={fs} />
						<MLButton options={{
							label :  t("Filter"),
							color : "primary",
							action : () => setShowFilter(!showFilter)
						}} css="me-1" />
					</>}
					<MLText options={{
						placeholder : t("검색어 입력"),
						size : "sm",
						value : search,
					}} handleChanged={(e) => setSearch(e.target.value)} css="mb-0" />
				</div>
			</div>
			<MLFilter show={showFilter} page={page} callback={() => setShowFilter(false)} />
			{!loading && <MLTable 
				headers={headers} 
				items={items} 
				headerClick={headerClick}
				order={pagestat?.order || "created"} 
				desc={pagestat?.desc || "desc"} 
				noheader={true} />}
			{loading && <MLPanelLoading />}
		</div>}
	</div>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
    const admintype = state.AuthReducer.admintype;
    const adminUsers = state.AuthReducer.adminUsers;
	const groups =  state.AuthReducer.groups;
    const region =  state.AuthReducer.region;
	const projects = state.ProjectReducer.projects;
	const loginuser = state.AuthReducer.loginuser;
	const pagestat = state.ProgramReducer.pagestat["caselist"] || {};
	const page = state.ProgramReducer.page;
	const filters = state.ProgramReducer.filters;
	const config = state.ProgramReducer.config;
    
	return { user, projects, region, adminUsers, groups, admintype, loginuser, pagestat, page, filters, config };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, null)(ProjectsPage);
