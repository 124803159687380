import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import RMWidgetCount from '../widgets/RMWidgetCount';
import { useTranslation } from 'react-i18next';
import _ from "lodash";

const PayStatCard = ({ items, yearItems }) => {
	const dispatch = useDispatch();
    const [t] = useTranslation(['translation']);
    const remainDay = 30;
    const remain = new Date().getTime() + (remainDay * 24 * 3600 * 1000);
    const [cumulative, setCumulative] = useState("");
    const [yearCumulative, setYearCumulative ] = useState("");
    const [mtValue, setMtValue] = useState(false);
    const [yearMtValue, setYearMtValue] = useState(false);

	useEffect(() => {
        let cumulativeList = "";
        const groupedPrices = _.groupBy(items, "punit");
        const result = _.mapValues(groupedPrices, (gitems) => _.sumBy(gitems, (gitem) => parseFloat(gitem.realprice || 0)));
        Object.keys(result).length > 1 ? setMtValue(true) : setMtValue(false);
        Object.keys(result).map((x, idx) => {
            cumulativeList = cumulativeList + ((idx > 0 ? "\n" : "") + `${x} ${result[x].toLocaleString("en")}`)
        })
        setCumulative(cumulativeList);
	}, [items]);

    useEffect(() => {
        let yearCumulativeList = "";
        const yearGroupedPrices = _.groupBy(yearItems.filter(x => moment(x.when).format("YYYY") === moment().format("YYYY")), "punit");
        const result = _.mapValues(yearGroupedPrices, (gitems) => _.sumBy(gitems, (gitem) => parseFloat(gitem.realprice || 0)));
        Object.keys(result).length > 1 ? setYearMtValue(true) : setYearMtValue(false);
        Object.keys(result).map((x, idx) => {
            yearCumulativeList = yearCumulativeList + ((idx > 0 ? "\n" : "") + `${x} ${result[x].toLocaleString("en")}`)
        })
        setYearCumulative(yearCumulativeList);
    }, [yearItems]);

  	return <>
        {items && cumulative && yearCumulative && <div className='row'>
            <div className='col'>
                <RMWidgetCount title={t("전체 결제")} sub={t("누적 건수")} value={items.length} iconName="credit-card" />
            </div>
            <div className='col'>
                <RMWidgetCount title={t("전체 매출")} sub={t("누적 매출")} value={cumulative} options={{multiValue : mtValue}} iconName="currency-dollar" />
            </div>
            <div className='col'>
                <RMWidgetCount title={t("결제자")} sub={t("누적 건수")} value={[...new Set(items.map(x => x._id))].length} iconName="user-dollar" />
            </div>
            <div className='col'>
                <RMWidgetCount title={t("결제 국가")} sub={t("누적")} value={[...new Set(items.map(x => x.ccode))].length} iconName="world-pin" />
            </div>
            <div className='col'>
                <RMWidgetCount title={moment().format("YYYY") + "y"} 
                    sub={yearItems.filter(x => moment(x.when).format("YYYY") === moment().format("YYYY")).length + t("건")} 
                    value={yearCumulative} options={{multiValue : yearMtValue}} iconName="credit-card-pay" />
            </div>
        </div>}
    </>
}

const mapState = (state) => {
	const region = state.AuthReducer.region;
	return { region };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, null)(PayStatCard);
