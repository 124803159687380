import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Navbar, Container, NavDropdown, Nav } from 'react-bootstrap';
import { useEffect } from 'react';
import { useState } from 'react';
import Select from "react-select";
import { history } from '../../history';

const CardItem = ({ item, onClick, selected }) => {

	useEffect(() => {
	}, [])

	const getCss = () => {
		if(selected?.type === item.type){
			return "cliniccard border-3 bg-light-primary border border-primary shadow-sm raycur rounded-3";
		}else{
			return "cliniccard border-3 border-gray-300 border-dotted shadow-sm raycur rounded-3";
		}
	}
	
	return <div className={getCss()}>
		<div className="card-body min-h-100px" onClick={() => onClick(item)}>
			<span className="card-title fw-bolder text-muted text-hover-primary fs-4">{item.title}</span>
			<div className="text-muted my-6">{item.description}</div>
		</div>
	</div>
}

const MLCardSelector = ({ list, selected, onClick }) => {

	useEffect(() => {
	}, [])
	
	return <div className="row">
		{list.map((x, idx) => <div className='col' key={"ccard-" + idx}>
			<CardItem 
				selected={selected}
				onClick={(e) => onClick(e)}
				item={x} />
		</div>)}
	</div>
}

const mapState = (state) => {
	const user = state.AuthReducer.user;
	return { user };
};

const mapDispatch = dispatch => ({
})

export default connect(mapState, mapDispatch)(MLCardSelector);
