export const upperFistChar = ([ first, ...rest ], locale = navigator.language) =>
  first.toLocaleUpperCase(locale) + rest.join('')


export const convertHtmlToTextOnly = (html) => 
  html ? String(html).replace(/(<([^>]+)>)/gi, '').replace(/&nbsp;/g, '') : html;


export const replaceVariablesText = (doc, dataSet) => {
  if (!doc || typeof doc !== "string" || typeof dataSet !== "object") {
      return doc;
  }
  const valList = [...(new Set(doc.match(/({{.*?}})/g)))];
  for (const val of valList) {
      const targetVal = _.get(dataSet, val.replace(/[{|}]/g, "").trim());
      if (["string", "number"].includes(typeof(targetVal)) || targetVal === null) {
          doc = doc.replace(new RegExp(val, "g"), targetVal === null ? "" : targetVal);
      }
  }
  return doc;
};