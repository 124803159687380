
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Store } from '../../store';
import { DATELATEST, RMBTNS, USERTYPE, USER_FILTER } from '../../constants/RMConstants';
import { FaRegTrashAlt } from "react-icons/fa";
import { MLComboSingle, MLDate, MLNumber, MLText } from './MLInput';
import { ProgramConstant } from '../../redux/reducers';
import { MLButton } from './MLButton';
import { MLSpinner } from './Loader';
import { InfoAction } from '../../redux/actions';
import { useTranslation } from 'react-i18next';

const MLFilterList = ({ page, filters, callback, filterlist }) => {
	
	const [t] = useTranslation(['translation']);
	const dispatch = useDispatch();
	const [list, setList] = useState([]);
	//const [nowItem, setNowItem] = useState({});
	const [fList, setFList] = useState([]);
	const [fsItem, setFsItem] = useState({});
	const [isNew, setIsNew] = useState(false);

	useEffect(() => {
		if(filterlist)
			getData();
	}, [filterlist]);

	useEffect(() => {
		const pageFilter = filters.find(x => x.page === page.code);
		if(pageFilter){
			setFsItem(pageFilter?.fsItem?.sk ? pageFilter?.fsItem : { })
		}
	}, [filters]);

	const apply = async () => {
	}

	const getData = async () => {
		setFList(filterlist);
		setList([
			{ value : "", label : "모두"}, 
			...filterlist.map(x => ({ value : x.sk, label : x.title }))
		]);
	}

	const goSwitch = (e) => {
		if(e.target.value === "all"){
			setFsItem({ sk : "all" });
			dispatch({ type : ProgramConstant.SET_FILTER, item : { 
				page : page.code, 
				updated : new Date().getTime(), 
				fsItem : {},
				fs : []
			}})
			return;
		}else{
			setFsItem(fList.find(x => x.sk === e.target.value));
			dispatch({ type : ProgramConstant.SET_FILTER, item : { 
				page : page.code, 
				updated : new Date().getTime(), 
				fsItem : fList.find(x => x.sk === e.target.value) || {},
				fs : fList.find(x => x.sk === e.target.value)?.filter || []
			}})
		}
	}

	return <>
		{!isNew && <>
			<div className='me-1'>
				<MLComboSingle options={{ 
					list : list, 
					value : fsItem?.sk || "",
				}} handleChanged={(e) => goSwitch(e)} css="mb-0" />
			</div>
			{/*fsItem.sk && fsItem.sk !== "isnew" && fsItem.sk !== "all" && <div className='me-1'>
				<div className='input-group'>
					<input type="text" className="form-control" value={fsItem.title} onChange={(e) => setFsItem({...fsItem, title : e.target.value})} placeholder="필터 이름(자주 사용하는 필터는 저장할 수 있습니다.)"></input>
					<button className="btn btn-primary btn-sm waves-effect waves-light" disabled={fsItem.title ? "" : "disabled"} onClick={() => updateFilter()}>
						<MLSpinner title='필터 업데이트' />
					</button>
				</div>
			</div>*/}
		</>}
		{isNew && <div className='me-1'>
			<div className='input-group'>
				<input type="text" className="form-control" onChange={(e) => setFsItem({...fsItem, title : e.target.value})} placeholder={t("필터 이름(자주 사용하는 필터는 저장할 수 있습니다.)")}></input>
				<button className="btn btn-light btn-sm waves-effect waves-light" onClick={() => setIsNew(false)}>
					{t("취소")}
				</button>
				<button className="btn btn-primary btn-sm waves-effect waves-light" disabled={fsItem.title ? "" : "disabled"} onClick={() => saveFilter()}>
					<MLSpinner title={t("필터 저장")} />
				</button>
			</div>
		</div>}
	</>
}

const mapState = (state) => {
	const filters = state.ProgramReducer.filters;
	const filterlist = state.ProgramReducer.filterlist;
	const groups =  state.AuthReducer.groups;
	return { filters, groups, filterlist };
};

export default connect(mapState, null)(MLFilterList);