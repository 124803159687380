import { Modal } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BUTTONS, COLORS, INPUTS, SPAN } from '../constants/FromInputs';
import { MLButton } from '../components/common/MLButton';
import { MLForm } from '../components/common/MLForm';
import { MLToggle } from '../components/common/MLInput';
import { LANGUAGE, RMSUBTYPES } from '../constants/RMConstants';
import { AuthConstant, getAPI, getHeader } from '../redux/reducers/AuthReducer';
import moment from 'moment';
import axios from 'axios';
import { BoardAction, WFAction } from '../redux/actions';
import { useParams } from 'react-router-dom';
import RMContentInfoPaenl from '../components/board/RMContentInfoPaenl';
import { Editor } from '@tinymce/tinymce-react';
import { MLComboSingle, MLText } from '../components/common/MLInput';
import { history } from '../history';
import { CostExplorer } from 'aws-sdk';
import MLCountryCombo from '../components/common/MLCountryCombo';
import { ProductAction } from '../redux/actions';
import { COUNTRIES } from '../constants/Country';
import { JWT } from '../config';
import { WFConstant } from '../redux/reducers';

const ProductDetailPage = ({ products, workflows, callbackClose }) => {
    const [t] = useTranslation(['translation']);
	const dispatch = useDispatch();
	const { _pid, _cid } = useParams();
	const [data, setData] = useState({});

	useEffect(() => {
		getData();
		getWF();
	}, []);

	useEffect(() => {
		if(products.length > 0){
			const d = products.filter((i) => i._id === _pid ).filter((x) => x.sk === _cid)[0];
			setData({...d, country : d.sk.replace("cid:", "")});
		}
	}, [products]);


	const getData = async () => {
		try{
			dispatch(BoardAction.GetContent(_cid, data));
		}catch(err){
			console.log(err);
		}
	}

	const getWF = async () => {
		try{
			const ret = await axios.get(WFAction.getAPI() + "/workflow", JWT());
			if (ret.data.status === "success") {
				dispatch({ type: WFConstant.GETALL, items: ret.data.data });
			}
		}catch(err){
			console.log(err);
		}
	}

	const del = () => {
		// msa 만들어야 함.		
	}

    const addProductDetail = async () => {
		try{
            dispatch(ProductAction.UpdateProductDetail(_pid, data));
		}catch(err){
			console.log(err);
		}
		history.push('/Product/' + data?._id);
	}

    const controls = [
		{ 
			type : INPUTS.TEXT, 
			options : { 
				label : '이름',
				name : 'productname',
				required : true
			},
		},
        {
			type: INPUTS.COMBO,
			options : { 
				label : 'Country',
				name : 'country',
				list : COUNTRIES.map(x => ({ value : x.countryCode, label : x.name })) ,
				required : true
			},
		},
        {
            type : INPUTS.TEXT,
            options : {
                label : "통화",
                name : 'currency',
                required : true,
            }
        },
        {
            type: INPUTS.NUMBER,
            options: {
                label: '상품 기본 가격',
                name: 'price',
            }
        },
        {
            type: INPUTS.COMBO,
            options: {
                label: 'Workflow',
				name : 'workflowId',
				list : workflows ? workflows.map(x => ({ value : x._id, label : x.title }))  : [],
				required : true
            }
        },
        {
            type: INPUTS.NUMBER,
            options: {
                label: '할인율 (%)',
                name: 'discount',
            }
        },
        {
            type: INPUTS.COMPONENT,
            component: <MLToggle options={{
                label : 'Published',
                name : 'ispublished',
                inline : true,
                value : data.ispublished,
            }} handleChanged={(e) => setData({...data, ispublished : e.target.value})} css="mb-8 mt-6  text-end" />,
            options: {
                label: 'Published',
                name : 'ispublished'
            }
        },
        {
			type : SPAN.VERTICAL,
			options : { size : 10 }
		},
	];

	return <>
	<div className="header align-items-stretch d-block" style={{position: "initial"}}>
		<div className="d-flex w-100 justify-content-between p-5 align-items-center">
			<div className="d-flex align-items-center text-dark fw-bolder fs-2 my-1"></div>
			<div className="d-flex align-items-center"></div>
		</div>
	</div>
	<div className='post p-7'>
		<div className='ray-content'>
			<div className='card'>
				<div className='card-header'>
					<h2 className='card-title'>{data.productname}</h2>
					<div className='card-toolbar'>
						<span className='fw-bold raycur me-1' onClick={() => history.push("/Product/" + data._id) }> {data.productname}</span> 
					</div>
				</div>
			</div>
			<div className='card mt-6'>
				<div className='card-body'>
					<MLForm controls={controls} data={data} nocard={true} onChanged={(e) => { console.log(e);setData(e);}} />
				</div>
			</div>
			<div className='text-end my-4'>
				<MLButton options={{
					label : '수정', 
					color : COLORS.INFO,
					action : () => addProductDetail()
				}} css="me-1" />
				<MLButton options={{
					label : '삭제', 
					color : COLORS.DANGER,
					action : () => del()
				}} css="me-1" />
				<MLButton options={{
					label : '목록', 
					color : COLORS.LIGHT,
					action : () => history.push('/Product/' + data?._id)
				}} />
			</div>
		</div>
	</div>
	</>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const region =  state.AuthReducer.region;
    const products = state.ProductReducer.products;
    const workflows = state.WFReducer.items;
	
	return { region, user, products, workflows };
};

const mapDispatch = dispatch => ({
})

export default connect(mapState, mapDispatch)(ProductDetailPage);