import { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import MLTable from '../../components/common/MLTable';
import Devices from '../../components/partner/devices';
import { history } from '../../history';
import { MLButton } from '../../components/common/MLButton';
import { COLORS } from '../../constants/FromInputs';
import PartnerUserList from '../../components/partner/partneruserlist';
import CompDeviceInfo from '../../components/device/deviceinfo';
import { DeviceAction } from '../../redux/actions/DeviceAction';

const DeviceInfoPage = ({ alldevices, device }) => {

	const dispatch = useDispatch();
	const { _id, _tabname } = useParams("overview");

	const [t] = useTranslation(['translation']);
	const [ info, setInfo ] = useState({});
	const [ items, setItems ] = useState([]);
	const [ list, setList ] = useState([]);
	const [ process, setProcess ] = useState(false);
	const [ err, setErr ] = useState("");
	const [ edit, setEdit ] = useState(false);
	const [ search, setSearch ] = useState("");
	
	const [ related, setRelated ] = useState([]);

	useEffect(() => {
		if(_id){
			getitems(_id);
			setPage();
		}
	},[_id]);

	const setPage = () => {
		const item = alldevices.find(g => g.sn === _id);
		if(!item)
			return;
		// dispatch({ type : ProgramConstant.PAGE, item : {
		// 	label : <>{t("Device")} : {item.sn}</>,
		// 	caption : <>
		// 		<span className="me-2 fw-bolder">
		// 			{item.DeviceName}
		// 		</span>
		// 	</>
		// }});
	}

	const getitems = async (_id) => {
		setProcess(true);
		dispatch(DeviceAction.GetDeviceByID(_id));
		setProcess(false);
	}

  	return <div className='p-4'>
		<div className="card">
			<div className="card-header d-flex justify-content-between align-items-center border-bottom">
				<div className="card-label fw-bold text-gray-800 fs-5">{_id}</div>
				<div className="d-flex">
					<MLButton options={{
						label : t("List"),
						color : COLORS.DARK,
						size : "md",
						action : () => history.push("/Devices")
					}} css="me-2" />
				</div>
			</div>
			<div className='pt-2'>
				<CompDeviceInfo device={device ? device : alldevices.find(x => x.sn === _id)} title={"Information"} />
			</div>
		</div>
	</div>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const allgroups =  state.ProgramReducer.allgroups;
	const alldevices =  state.ProgramReducer.alldevices;
	const device = state.ProgramReducer.device;

	return { user, allgroups, alldevices, device };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, null)(DeviceInfoPage);