import axios from 'axios';
import moment from 'moment';
import { AppConfig, JWT } from '../config';
import { v4 } from 'uuid';

const getAPI = () =>{
    const env = (location.href.indexOf("localhost") > -1 || location.href.indexOf(".raydevelop.com") > -1) ? "development" : "production";
	return AppConfig().info + "/common/dynamo";
};

const RTAudit = async (data, extra='') => {
	const userdata = JSON.parse(localStorage.getItem('userdata'));
	const newId = v4();
    const ret = await axios.post(getAPI(), { 
		data : {
			type : "update", 
			region : "ap-northeast-2", 
			params : {
				TableName: "rayteams-audit",
				UpdateExpression: 'SET #who = :who, #lvl = :lvl, #msg = :msg, #extra = :extra, #created = :created, #ttl = :ttl',
				ExpressionAttributeNames: { 
					'#who' : 'who', 
					'#lvl' : 'lvl', 
					'#msg' : 'msg', 
					'#extra' : 'extra', 
					'#created' : 'created', 
					'#ttl' : 'ttl', 
				},
				ExpressionAttributeValues: { 
					':who' : userdata.name, 
					':lvl' : data.lvl, 
					':msg' : data.msg, 
					':extra' : extra, 
					':created' : moment().unix(), 
					':ttl' : moment().add(1, 'years').unix(), 
				},
				Key: { _id: newId, sk: 'rm:' + userdata.sub }
			} 
		} 
	}, JWT());
    return ret?.data;
}
export {
	RTAudit
}