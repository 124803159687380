import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { MLButton } from '../../components/common/MLButton';
import { MLText } from '../../components/common/MLInput';
import { MLPanelLoading } from '../../components/common/MLPanel';
import MLTable from '../../components/common/MLTable';
import { COLORS, SETTING } from '../../constants/FromInputs';
import { ProductAction } from '../../redux/actions';
import { AuthConstant, ProductConstant } from '../../redux/reducers';
import RMModalAddProductMain from './RMModalAddProductMain';
import { RMIconChart, RMIconList } from '../icon/MLIcon';
import { fetchData } from '../../libs/db';
import { history } from '../../history';
import { LAPPRODUCTINFOTYPE } from '../../constants/RMConstants';
import { ProgramConstant } from '../../redux/reducers';

const RMProductMainList = ({ user, region, products, page }) => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);
	const [modal, setModal] = useState({
		show : false
	});
	const [items, setItems] = useState([]);
	const [search, setSearch] = useState("");
	const { _id } = useParams("");

	useEffect(() => {
		dispatch({ type : ProgramConstant.SET_PAGE, page : { 
			code : "labproduct", 
			view : "summary", 
			title : "Lab Product", 
			small : "Lab Default Product 관련 정보", 
			options : [
			]
		}})
	}, []);

	useEffect(() => {
        getData();
	}, []);

	useEffect(() => {
		genData();
	}, [search, products])

    const getData = async () => {
		setLoading(true);
		const ScanFilter = {
			sk: {
				ComparisonOperator: "IN",
				AttributeValueList: LAPPRODUCTINFOTYPE.map(x => x.value),
			},
		}
		try{
			const ret = await fetchData("rayteams-manager-product", ScanFilter);
			dispatch({ type: ProductConstant.GET_ALL_PRODUCTS, items: ret.Items });
		}catch(err){
			console.log(err);
		}
		setLoading(false);
    }

	const genData = async () => {
		setItems(search ? products.filter((x) => x.sk === 'info' && x.productname.indexOf(search) > -1 || x.type.indexOf(search) > -1) : (products || []));
	}

    const headers = [
        { link : "_id", linkpath : "/ProductEdit", value : "title", label : "Title", css : "text-start", style : { width : 450 } },
		{ value : "sk", label: "Use Type" },
		{ value : 'type', label: "Type" },
		{ value : "delivery", label: "Delivery", type : "TFVALUE" },
		{ value : "isPublish", label: "Publish", type : "TFVALUE" },
		{ value : "created", label : "Created", type : "DATE", css : "text-end" },
    ]

  	return <div className='p-4'>
		<div className="card">
			<div className="card-header d-flex justify-content-between align-items-center">
				<div className="card-label fw-bold text-gray-800 fs-5">{items.length}</div>
				<div className="d-flex">
					<MLButton options={{
						color : COLORS.PRIMARY,
						size : "sm",
						label : "Product 등록",
						action : () => _id ? history.push("/ProductEdit/" + _id) : history.push("/ProductAdd/"), //setModal({ show : true })
					}} css="me-2"/>
					<MLText options={{
						placeholder : "검색어 입력",
						size : "sm",
						value : search,
					}} handleChanged={(e) => setSearch(e.target.value)} css="mb-0" />
				</div>
			</div>
			{!loading && <MLTable 
				headers={headers} 
				items={items} noheader={true} />}
			{loading && <MLPanelLoading />}
		</div>
		<RMModalAddProductMain 
			modalData={modal} 
			callbackClose={() => setModal({ show : false })} />
  	</div>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const region =  state.AuthReducer.region;
    const products = state.ProductReducer.products;
	const page = state.ProgramReducer.page;

	return { region, user, products, page };
};

const mapDispatch = (dispatch) => ({

});

export default connect(mapState, null)(RMProductMainList);