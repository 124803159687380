import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import RMWidgetCount from '../widgets/RMWidgetCount';
import { useTranslation } from 'react-i18next';

const GroupStatCard = ({ items }) => {
	const dispatch = useDispatch();
    const [t] = useTranslation(['translation']);
    const remainDay = 30;
    const remain = new Date().getTime() + (remainDay * 24 * 3600 * 1000);

	useEffect(() => {
	}, []);

  	return (<>
        {items && <div className='row'>
            <div className='col'>
                <RMWidgetCount title={t("전체 기관")}  sub={t("누적")} value={items.filter(x => x.hasMember).length} iconName="building" />
            </div>
            <div className='col'>
                <RMWidgetCount title={t("Clinics")} sub={t("누적")} value={items.filter(x => (typeof x.grouptype === "string" || Array.isArray(x.grouptype)) ? x.grouptype?.includes("clinic") : false).length } iconName="building-hospital" />
            </div>
            <div className='col'>
                <RMWidgetCount title={t("Labs")} sub={t("누적")} value={items.filter(x => (typeof x.grouptype === "string" || Array.isArray(x.grouptype)) ? x.grouptype?.includes("lab") : false).length} iconName="building-community" />
            </div>
            <div className='col'>
                <RMWidgetCount title={t("당월 가입")} sub={moment().format("MM") + t("월 가입된 기관수")} value={items.filter(x => x.created && moment(x.created).format("MMMMYYYY") === moment().format("MMMMYYYY")).length} iconName="calendar-month" />
            </div>
        </div>}
    </>
  	);
}

const mapState = (state) => {
	const region = state.AuthReducer.region;
	return { region };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, null)(GroupStatCard);
