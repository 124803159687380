import moment from 'moment';
import { useEffect, useState } from 'react';
import { XName, XEmail } from '../common/MLString';
import { COGADMIN, COGNITO, CONFIG_REGION_SET } from '../../libs/cogInfo'
import { COGADMIN_GRAPHY } from "../../libs/cognito-graphy";
import { queryData, fetchData } from '../../libs/db'
import { FaCrown, FaStore, FaUserTie } from 'react-icons/fa';
import { connect, useDispatch } from 'react-redux';
import { ProgramConstant } from '../../redux/reducers';
import { aws_con } from '../../libs/db';
import AWS from 'aws-sdk';
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";

const ManagerUser = ({ _id, hide = true, allusers, region = "ap-northeast-2", noemail = false }) => {
	const dispatch = useDispatch();
	const [ info, setInfo ] = useState({});
	const [ processes, setProcess ] = useState(false);

	useEffect(() => {
		if(_id){
			getUser();
		}
	},[_id]);

	const getCogAdmin = () => {
		if(process.env.REACT_APP_COMPANY === 'graphy'){
			console.log(COGADMIN_GRAPHY);
			return COGADMIN_GRAPHY;
		}else{
			return COGADMIN;
		}
	}

	const configAWSRegion = (region) => {
		const _region = CONFIG_REGION_SET[region]
		  ? CONFIG_REGION_SET[region]
		  : region;
		AWS.config.update({
		  region: _region,
		});
	};

	const getUser = async () => {

		const user = allusers.find(x => x._id === _id && x.email);
		if(user){
			setInfo(user);
			return;
		}
		setProcess(true);
		aws_con();
		const params = {
			Username: _id,
		};
		const uauth = await fetchData("rayteams-auth", { sub: { ComparisonOperator: "EQ", AttributeValueList: [_id], } });
		if(uauth.Items.length > 0){
            region = uauth.Items[0]?.sk.replace("region:", "");
            configAWSRegion(region);
			params.UserPoolId = process.env.REACT_APP_COMPANY === 'graphy' ? COGNITO_GRAPHY[region].poolId : COGNITO[region].poolId;
        }else{
            params.UserPoolId = getCogAdmin().poolId
			AWS.config.update({ region: region });
        }

		var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
		try {
			var item = await cognitoidentityserviceprovider
				.adminGetUser(params)
				.promise();
			var ret = {};
			item?.UserAttributes.map((x) => {
				ret[x.Name.replace("custom:", "")] = x.Value;
			});
			ret._id = ret.sub;
            const uinfo = await queryData("rayteams-user", {
                _id: {
                    ComparisonOperator: "EQ",
                    AttributeValueList: [ret._id],
                },
                sk: {
                    ComparisonOperator: "EQ",
                    AttributeValueList: ["info"],
                },
            });
            if(uinfo.Items.length > 0){
                setInfo({...ret, ...uinfo.Items[0]});
                dispatch({ type : ProgramConstant.GETUSER, user : {...ret, ...uinfo.Items[0]} });
            }else{
                setInfo(ret);
                dispatch({ type : ProgramConstant.GETUSER, user : ret });
            }
		} catch (err) {
			console.log(err);
		}

		setProcess(false);
	};

  	return <>
		{processes && <div className="d-flex align-items-center fw-bolder fs-4">
			...
		</div>}
		{!processes && <div>
			<strong>{!hide ? info?.name : XName(info?.name)}</strong>
            {!noemail && <span className='ms-2 text-gray-600'>{!hide ? info?.email : XEmail(info?.email)}</span>}
		</div>}
	</>
}

const mapState = (state) => {
	const allusers =  state.ProgramReducer.allusers || [];
	return { allusers };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(ManagerUser);
