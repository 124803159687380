import { useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import { MLPanelLoading } from '../common/MLPanel';
import { Doughnut, getElementAtEvent } from 'react-chartjs-2';
import { ChartDays, ChartMonths } from '../common/ChartDays';
import { InfoAction } from '../../redux/actions';
import { COUNTRIES } from '../../constants/Country';
import { useTranslation } from 'react-i18next';

const RMWidgetPieChart2 = ({ typename, title, height = 200 }) => {
	const [t] = useTranslation(['translation']);
	const [ chartData, setChartData ] = useState(null);
	const [ cData, setCData ] = useState([]);
	const [ bgColorNames, setbgColorNames ] = useState(["primary", "success", "warning", "info"]);
	const [ bgColors, setbgColors ] = useState(["#7367F0", "#28C76F", "#FF9F43", "#00CFE8"]);
	const chartRef = useRef();

	useEffect(() => {
		return () => {
			setChartData(null);
			setCData([]);
		};
	}, []);

    useEffect(() => {
		makedata();
    }, [typename]);

    useEffect(() => {
		generate(cData);
    }, [cData]);

	const makedata = async () => {
		let ret;
		if(typename === "projecttype"){
            ret =  await InfoAction.callDB({ type : "scan", region : "ap-northeast-2", params : {
                TableName: "rayteams-project",
                FilterExpression: "#sk=:sk",
                ExpressionAttributeNames: { "#sk": "sk" },
                ExpressionAttributeValues: { ":sk": "project" },
                ProjectionExpression: "projecttype"
            }});
			setCData(countArray(ret, 3));
		}
		if(typename === "grouptype"){
            ret =  await InfoAction.callDB({ type : "scan", region : "ap-northeast-2", params : {
                TableName: "rayteams-group",
                FilterExpression: "#sk=:sk",
                ExpressionAttributeNames: { "#sk": "sk" },
                ExpressionAttributeValues: { ":sk": "info" },
                ProjectionExpression: "grouptype"
            }});
			setCData(countArray(ret.filter(x => Array.isArray(x.grouptype)).map(x => ({...x, grouptype : getGroupName(x)})), 3));
		}
		if(typename === "countryCode"){
            ret =  await InfoAction.callDB({ type : "scan", region : "ap-northeast-2", params : {
                TableName: "rayteams-project",
                FilterExpression: "#sk=:sk",
                ExpressionAttributeNames: { "#sk": "sk" },
                ExpressionAttributeValues: { ":sk": "project" },
                ProjectionExpression: "countryCode"
            }});
			setCData(countArray(ret.filter(x => x.countryCode), 3));
		}
	}

	const getGroupName = (x) => {
		return x.grouptype.map(n => {
			if(n === "clinic")
				return t("클리닉")
			if(n === "lab")
				return t("기공소")
		}).join(",");
	}

	const countArray = (ret, top) => {
		let tmpData = {};
		ret.forEach(x => {
			if (tmpData[x[typename]]) {
				tmpData[x[typename]]++;
			} else {
				tmpData[x[typename]] = 1;
			}
		});
		let tmp2 = [];
		for (let item in tmpData) {
			tmp2.push({ name: item, count: tmpData[item] });
		}
		const tmptop = tmp2.sort((a, b) => a.count < b.count ? 1 : -1).slice(0, top);
		let sumOfRest = tmp2.sort((a, b) => a.count < b.count ? 1 : -1).slice(0, top).reduce((acc, curr) => acc + curr.count, 0);
		return [...tmptop, { name : "기타", count : tmp2.reduce((acc, curr) => acc + curr.count, 0) - tmptop.reduce((acc, curr) => acc + curr.count, 0) }];
	}

	const generate = (data) => {
		const barDataset = [];

        barDataset.push({
			data: data.sort((a, b) => a.count < b.count ? 1 : -1).map((x) => x.count),
			backgroundColor: bgColors,
		})

		setChartData({
            labels: data.sort((a, b) => a.count < b.count ? 1 : -1).map((x) => x.name === "" ? "없음" : x.name ),
            datasets: barDataset
        });
	}

  	return <div className='card' style={{height}}>
		<div className="d-flex justify-content-between p-4">
			<div className='d-flex flex-column'>
				<div className='fw-bolder'>{t(title)}</div>
				<div className='mt-auto'>
					{cData.map((x, idx) => <div key={idx}>
						<small className={"text-" + bgColorNames[idx]}>
							<i className="ti ti-circle-filled fs-6 me-1"></i>
							{typename === "countryCode" && <>{t(COUNTRIES.find(c => c.countryCode === x.name)?.name) || t("기타")}</>}
							{typename !== "countryCode" && <>{t(x.name) || t("없음")}</>}
						</small>
					</div>)}
				</div>
			</div>
			<div style={{ height : 150, width : 150 }}>
				{chartData && <Doughnut 
					ref={chartRef}
					data={chartData} 
					options={{ 
						responsive: true,
						maintainAspectRatio: true,
						plugins: {
							legend: {
								display : false
							}
						}
					}
				}/>}
			</div>
		</div>
	</div>
}

const mapState = (state) => {
    const logs = state.UsageReducer.logs;
	const dailylogs =  state.UsageReducer.dailylogs;
	const selectedlogs = state.UsageReducer.selectedlogs;
	const stats = state.UsageReducer.stats;
	
	return { logs, dailylogs, stats, selectedlogs };
};

const mapDispatch = (dispatch) => ({
});

export default connect(mapState)(RMWidgetPieChart2);
