import moment from 'moment';

export const ChartDays = (period = 1, daytype = "weeks", dFormat = "YYYY-MM-DD") => {
    const days = [];
    // console.log(period);
    // console.log(daytype);
    // console.log(dFormat);
    const dateStart = daytype == "months" ? moment().add(period, daytype) : moment().add(-1 * period, daytype);
    const dateEnd = moment();
    while (dateEnd.diff(dateStart, daytype) >= 0) {
        days.push(dateStart.format(dFormat));
        dateStart.add(1, daytype);
    };
    return days;
}

export const ChartMonths = (period = 1, daytype = "months") => {
    const months = [];
    const monthStart = moment().add(period, daytype);
    const monthEnd = moment();
    while (monthEnd.diff(monthStart, 'months') >= 0) {
        months.push(monthStart.format("YYYY-MM"));
        monthStart.add(1, 'months');
    };
    return months;
}
