import saveAs from 'file-saver';
import _ from "lodash";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Spreadsheet from "react-spreadsheet";
import { MLButton } from "../../components/common/MLButton";
import { MLPanelLoadingOverlay } from "../../components/common/MLPanel";
import { convertToKoreanNumber, XEmail, XName } from "../../components/common/MLString";
import { RTAudit } from "../../libs/audit";
import { InfoAction } from "../../redux/actions";
import { COUNTRIES } from '../../constants/Country';
import html2pdf from 'html2pdf.js';
import { replaceVariablesText } from '../../libs/string';
import { PRICEUNIT } from '../../constants/RMConstants';

const PaymentsExport = ({ list, allusers, config, callbackClose, CheckUser, allgroups }) => {
    const [t] = useTranslation();
    const [loading, setLoading] = useState(false);
    const [pdfLoading, serPdfLoading] = useState(false);
    const sampleList = useMemo(() => _.chunk(list, 14)[0], list);
    const useAllViewUserData = useMemo(() => config?.showalludata ?? false, [config?.showalludata]);
    const useApproved = useMemo(() => config?.approvedaccountonly ?? false, [config?.approvedaccountonly]);
    const checkedUsersById = useMemo(() => _.keyBy(allusers, "_id"), [allusers]);
    const TAXTYPE = [
        { value: "paypal", label: "영세" },
        { value: "toss", label: "과세" }
    ]
    const genData = useCallback((_list, userObj) => {
        console.log(list);
        let headerItems = [
            { value: t("주문번호"), readOnly: true },   // payid
            { value: t("결제일"), readOnly: true },     // when
            { value: t("구분"), readOnly: true },
            { value: t("상품명"), readOnly: true },
            { value: t("국가"), readOnly: true },
            { value: t("사용만료일"), readOnly: true },
            { value: t("구매자명"), readOnly: true },
            { value: t("판매수량"), readOnly: true },
            { value: t("정산완료일"), readOnly: true },
            { value: t("부가세 과세여부"), readOnly: true },
            { value: t("결제금액(외화)"), readOnly: true },
            { value: t("결제금액(원화)"), readOnly: true },   // 결제일 기준 원화
            { value: t("공급가액"), readOnly: true },
            { value: t("부가세"), readOnly: true },
            { value: t("환율"), readOnly: true },
            { value: t("원화금액"), readOnly: true },
            { value: t("환율기준일"), readOnly: true }
        ];
        if (!useApproved) {
            headerItems = headerItems.filter(x => x.key !== "approved");
        }
        const _data = [];
        const fxRate = _list.filter(f => f?.fxRate).sort((a, b) => new Date(a.when) - new Date(b.when))[0];
        const fxRateDate = moment(fxRate.when).format("YYYY-MM-DD");
        _list.forEach(_item => {
            const row = [];
            const user = userObj[_item?._id];
            const userName = user?.name || "";
            row.push({ value: _item.payid || "", readOnly: true });
            row.push({ value: moment(_item.when).format("YYYY-MM-DD") || "", readOnly: true });
            row.push({ value: _item?.subStatus || "", readOnly: true });
            row.push({ value: _item.title || "", readOnly: true });
            row.push({ value: (_item?.ccode && COUNTRIES.find(f => f.countryCode == _item.ccode)?.name) || "", readOnly: true });
            row.push({ value: moment(_item?.expired).format("YYYY-MM-DD"), readOnly: true });
            row.push({ value: useAllViewUserData ? userName : XName(userName), readOnly: true });
            row.push({ value: _item?.count || 1, readOnly: true });
            row.push({ value: "", readOnly: true });
            row.push({ value: (_item?.pg && TAXTYPE.find(p => p.value === _item.pg)?.label) || "", readOnly: true });
            row.push({ value: _item?.pg === "paypal" ? _item?.realprice && _item.realprice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "", readOnly: true });
            row.push({ value: _item?.pg === "toss" ? _item?.realprice && _item.realprice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "", readOnly: true });
            row.push({ value: _item?.realprice && ((_item?.realprice || 0) / 1.10).toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ","), readOnly: true });
            row.push({ value: _item?.realprice && ((_item?.realprice || 0) - ((_item?.realprice || 0) / 1.10)).toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ","), readOnly: true });
            row.push({ value: _item?.pg === "paypal" ? fxRate.fxRate.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "", readOnly: true });
            row.push({ value: _item?.pg === "paypal" ? ((_item.realprice || 0) * fxRate.fxRate).toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "", readOnly: true });
            row.push({ value: _item?.pg === "paypal" ? fxRateDate : "", readOnly: true });
            _data.push(row);
        });
        return [headerItems, ..._data];
    }, [t, useApproved, useAllViewUserData]);

    const data = useMemo(() => {
        return genData(sampleList, checkedUsersById);
    }, [sampleList, genData, checkedUsersById, loading]);

    const checkAllUsers = useCallback(async () => {
        const _userObj = {}
        const fncList = [];
        for (const _item of list) {
            fncList.push(async () => {
                const _user = await CheckUser(_item?._id);
                if (_user?._id) {
                    _userObj[_user._id] = _user;
                }
            });
        }
        const fncChunk = _.chunk(fncList, 10);
        for (const fncChunkItem of fncChunk) {
            await Promise.all(fncChunkItem.map(async x => await x()));
        }
        return _userObj;
    }, [list, checkedUsersById]);

    const exportData = useCallback(async () => {
        setLoading(true);
        try {
            let allUserObjById = {}
            try {
                allUserObjById = await checkAllUsers();
            } catch (error) {
                //
            }
            const sheetData = genData(list, allUserObjById);
            console.log("sheetData : -=======", sheetData);
            let csvContent = "\ufeff";
            sheetData.forEach(function (rowArray) {
                let row = rowArray?.map(x => `"${x.value || ""}"`).join(",");
                csvContent += row + "\r\n";
            });
            const content = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const name = `payments_list_${new Date().toISOString()}.csv`;
            saveAs(content, name);
            const auditExtra = {
                fileName: name,
                count: list.length,
                ids: list?.map(x => x?._id) || [],
            };

            await RTAudit({ lvl: 4, msg: 'Download Payments List' }, JSON.stringify(auditExtra));
            for (let item of list) {
                if (item?.rfstatus && item.rfstatus === "done") {
                    await exportRfPDF(item, allUserObjById);
                } else {
                    if (item?.ccode === "KR") {
                        await exportPDF(item, allUserObjById, "ko");
                    } else {
                        await exportPDF(item, allUserObjById, "en");
                    }
                    
                }
            }
            
        } catch (error) {
            console.log("ERROR[exportData]", error.toString());
        } finally {
            setLoading(false);
        }
    }, [list]);

    const exportPDF = async (pdfData, userObj, ccode) => {
        serPdfLoading(true);
        const list = await InfoAction.callDB({ type : "query", region : "ap-northeast-2", params : {
            TableName: "rayteams-manager-contents",
            KeyConditionExpression: "#id = :id and begins_with(#sk, :sk)",
            ExpressionAttributeNames: { "#id": "_id", "#sk": "sk" },
            ExpressionAttributeValues: { ":id": "f45ea8ab-64ea-4b31-8f87-70ee44901ae5", ":sk": `lang:${ccode}`},
        }});
        const userGroupInfo = userObj[pdfData?._id] && allgroups && allgroups.find(g => g._id === userObj[pdfData?._id].groupId);
        const invoiceData = {
            paylog:{
                when: pdfData?.when,
                paymentdate: moment.unix(pdfData.when / 1000).format("yyyy-MM-DD HH:mm:ss"),
                serviceduration: moment.unix(pdfData.when / 1000).format("yyyy-MM-DD") + " ~ " + moment.unix((pdfData.when + (30 * 1000 * 60 * 60 * 24)) / 1000).format("yyyy-MM-DD"),
                countryname: COUNTRIES.find(c => c.countryCode == pdfData?.ccode)?.name || "",
                count: pdfData?.count,
                punit: PRICEUNIT.find(f => f.value === pdfData?.punit)?.label || pdfData?.punit, 
                realprice: pdfData?.realprice,
                title: pdfData?.title,
                whenMonth: moment.unix(pdfData?.when / 1000).format("M"),
                whenDay: moment.unix(pdfData?.when / 1000).format("D"),
                realpriceToKr: convertToKoreanNumber(pdfData?.realprice || 0),
                realpriceToLocal: pdfData?.realprice.toLocaleString(),
                realpriceDan: ((pdfData?.realprice || 0) / 1.10).toLocaleString(),
                realpriceGong: ((pdfData?.realprice || 0) / 1.10).toLocaleString(),
                realpriceBoo: ((pdfData?.realprice || 0) - ((pdfData?.realprice || 0) / 1.10)).toLocaleString()
            },
            userInfo: {
                name: userObj[pdfData?._id].name,
                groupname: userGroupInfo?.name,
                address: userGroupInfo?.address,
            }
        }
        
        let generatorPdf = replaceVariablesText(list[0].content, invoiceData);
        generatorPdf = generatorPdf.replace("https://rayteams-pub-data.s3.ap-northeast-2.amazonaws.com/rayteams-contents/1719910980025.png", "/assets/img/Ray_CI_logotype.png");
        const opt = {
            margin: 0.4,
            filename: `PR-${pdfData?.when}`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scrollY: 0, scale: 3, useCORS: true },
            jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait', useCORS: true },
        };
        if (generatorPdf) {
            html2pdf().set(opt).from(generatorPdf).save().then(() => {
                serPdfLoading(false);
            });
        } else {
            serPdfLoading(false);
        }
        await RTAudit({ lvl: 4, msg: 'Export Invoice PDF'});
    };

    const exportRfPDF = async (pdfData, userObj) => {
        serPdfLoading(true);
        const list = await InfoAction.callDB({ type : "query", region : "ap-northeast-2", params : {
            TableName: "rayteams-manager-contents",
            KeyConditionExpression: "#id = :id and begins_with(#sk, :sk)",
            ExpressionAttributeNames: { "#id": "_id", "#sk": "sk" },
            ExpressionAttributeValues: { ":id": "da02de94-cd1e-4337-be28-e561dff841b8", ":sk": "lang:en" },
        }});
        const userGroupInfo = userObj[pdfData?._id] && allgroups && allgroups.find(g => g._id === userObj[pdfData?._id].groupId);
        const invoiceData = {
            paylog:{
                when: pdfData?.when,
                paymentdate: moment.unix(pdfData.when / 1000).format("yyyy-MM-DD HH:mm:ss"),
                serviceduration: moment.unix(pdfData.when / 1000).format("yyyy-MM-DD") + " ~ " + moment.unix((pdfData.when + (30 * 1000 * 60 * 60 * 24)) / 1000).format("yyyy-MM-DD"),
                countryname: COUNTRIES.find(c => c.countryCode == pdfData?.ccode)?.name || "",
                count: pdfData?.count,
                punit: PRICEUNIT.find(f => f.value === pdfData?.punit)?.label || pdfData?.punit, 
                realprice: pdfData?.realprice,
                title: pdfData?.title,
                rfreqtime: moment.unix(pdfData?.rfreqtime / 1000).format("yyyy-MM-DD"),
                rfamount: pdfData?.rfamount.toLocaleString(),
                usageCharge: (pdfData?.realprice || 0) - (pdfData?.rfamount || 0)
            },
            userInfo: {
                name: userObj[pdfData?._id].name,
                groupname: userGroupInfo?.name,
                address: userGroupInfo?.address,
            }
        }
        
        let generatorPdf = replaceVariablesText(list[0].content, invoiceData);
        generatorPdf = generatorPdf.replace("https://rayteams-pub-data.s3.ap-northeast-2.amazonaws.com/rayteams-contents/1719910980025.png", "/assets/img/Ray_CI_logotype.png");
        const opt = {
            margin: 0.4,
            filename: `PR-${pdfData?.when}-R`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scrollY: 0, scale: 3, useCORS: true },
            jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait', useCORS: true },
        };
        if (generatorPdf) {
            html2pdf().set(opt).from(generatorPdf).save().then(() => {
                serPdfLoading(false);
            });
        } else {
            serPdfLoading(false);
        }
        await RTAudit({ lvl: 4, msg: 'Export Invoice PDF'});
    };

    return <>
        {loading && <MLPanelLoadingOverlay position="absolute" />}
        <div className="pt-1 pb-1" style={{ overflowX: "auto" }}>
            <Spreadsheet
                data={data}
                hideColumnIndicators={true}
                hideRowIndicators={true}
            />
        </div>
        <div className="d-flex align-items-center justify-content-between mt-2" style={{ position: "sticky", bottom: 0 }}>
            <div className="text-muted">
                {t("Examples are displayed with a maximum of 15 items.")}
            </div>
            <div className="d-flex align-items-center gap-2">
                <MLButton
                    options={{
                        label: t("Cancel"),
                        action: callbackClose,
                    }}
                />
                <MLButton
                    options={{
                        label: t("Export"),
                        color: "primary",
                        action: exportData,
                        disabled: loading,
                    }}
                />
            </div>
        </div>
    </>
};

const mapState = (state) => {
    const allusers = state.ProgramReducer.allusers || [];
    const allgroups = state.AuthReducer.groups || [];
    const config = state.ProgramReducer.config;
    return { allusers, allgroups, config };
};

const mapDispatch = (dispatch) => ({
    CheckUser: (_id) => dispatch(InfoAction.CheckUser(_id)),
});

export default connect(mapState, mapDispatch)(PaymentsExport);
