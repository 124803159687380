import { useMemo } from 'react';
import Select from 'react-select';

// items - Array item [ ..., { value: -v-, label: -l- }] 
// value - string value "-v-,-v-,-v-"
const MLSelectorTextArray = ({ items, value, callbackOnChange, name, closeMenuOnSelect = true, isMulti = true }) => {
    const val = useMemo(() => {
        if (!value) {
            return []
        }
        const splintedValues = value?.split(",");
        return items?.filter(x => splintedValues?.includes(x.value));
    }, [items, value]);

    const onChanged = (selectedValues) => {
        if (isMulti) {
            callbackOnChange && callbackOnChange(selectedValues?.map(x => x.value).join(","));
        } else {
            callbackOnChange && callbackOnChange(selectedValues.value);
        }
        
    };

    return <>
        <Select
            name={name}
            isMulti={isMulti}
            closeMenuOnSelect={closeMenuOnSelect}
            value={val}
            isClearable={true}
            options={items}
            onChange={onChanged}
        />
    </>
};

export default MLSelectorTextArray;