import axios from 'axios';
import { AppConfig, JWT } from '../../config';
import { Store } from '../../store';
import { ProgramConstant } from '../reducers';
import { ConfigAWSRegion, aws_con, fetchData, queryData } from '../../libs/db';
import { COGNITO_GRAPHY } from '../../libs/cognito-graphy';
import { COGNITO } from '../../libs/cogInfo';
import AWS from 'aws-sdk';

const getAPI = () =>{
    const env = (location.href.indexOf("localhost") > -1 || location.href.indexOf(".raydevelop.com") > -1) ? "development" : "production";
	return AppConfig().info + "/common/dynamo";
};

const callDB = async (data) => {
    // console.log(getAPI());
    const ret = await axios.post(getAPI(), { data : data }, JWT());
    return ret?.data;
}

const GetUserFilters = (code) => async (dispatch) => {
    var n = Store.getState().AuthReducer.usersub;
    const ret = await callDB({
        type : "scan",
        region : "ap-northeast-2",
        params : {
            TableName: "rayteams-manager-user",
            FilterExpression: "#_id=:_id and begins_with(#sk, :sk)",
            ExpressionAttributeNames: { "#_id": "_id", "#sk": "sk" },
            ExpressionAttributeValues: { ":_id": n, ":sk": "ft:" + code + ":" }
        }
    });
    if (ret) {
        dispatch({ type : ProgramConstant.SET_USER_FILTERLIST, items : ret });
    }
};

// Copy as MLUser.js
const CheckUser = (_id) => async (dispatch) => {
    const allusers = Store.getState().ProgramReducer.allusers || [];
    const user = allusers.find(x => x._id === _id && x.email);
    if (user?._id) {
        return user;
    }
    aws_con();
    const uauth = await fetchData("rayteams-auth", { sub: { ComparisonOperator: "EQ", AttributeValueList: [_id], } });
    let region = "";
    if(uauth.Items.length > 0){
        region = uauth.Items[0]?.sk.replace("region:", "");
    }else{
        return {};
    }
    const params = {
        Username: _id,
        UserPoolId: process.env.REACT_APP_COMPANY === 'graphy' ? COGNITO_GRAPHY[region].poolId : COGNITO[region].poolId,
    };
    ConfigAWSRegion(region)
    var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
    try {
        var item = await cognitoidentityserviceprovider
            .adminGetUser(params)
            .promise();
        var ret = {};
        item?.UserAttributes.map((x) => {
            ret[x.Name.replace("custom:", "")] = x.Value;
        });
        ret._id = ret.sub;
        const uinfo = await queryData("rayteams-user", {
            _id: {
                ComparisonOperator: "EQ",
                AttributeValueList: [ret._id],
            },
            sk: {
                ComparisonOperator: "EQ",
                AttributeValueList: ["info"],
            },
        });
        if(uinfo.Items.length > 0){
            const _user = {...ret, ...uinfo.Items[0]};
            dispatch({ type : ProgramConstant.GETUSER, user : _user });
            return _user;
        }else{
            dispatch({ type : ProgramConstant.GETUSER, user : ret });
            return ret;
        }
    } catch (err) {
        console.log("ERROR[CheckUser]", err?.responser?.data || err?.toString());
    }
};

const UnregisterClientUser = async ({ _id, email, region }) => {
    try {
        if (!_id || !email || !region) {
            return false;
        }
        const apiUrl = AppConfig().manager_user + "/user/unregisterclientuser";
        const payload = { data: { _id, email, region }};
        await axios.post(apiUrl, payload, JWT());
        return true;
    } catch (error) {
        console.log("ERROR[InfoAction@DeleteClientUser]", error?.response?.data || error?.toString())        ;
    }
    return false;
};


export const InfoAction = {
    callDB,
    GetUserFilters,
    CheckUser,
    UnregisterClientUser,
};
