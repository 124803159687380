import { useEffect, useState } from 'react';
import { MLComboSingle } from '../../components/common/MLInput';
import { fetchData_prod } from '../../libs/db';
import { LANGUAGE } from '../../constants/RMConstants';
import { COLORS } from '../../constants/FromInputs';
import { MLButton } from '../../components/common/MLButton';
import { MLText } from '../../components/common/MLInput';
import { ObjectList, UploadS3 } from '../../libs/s3';
import axios from 'axios';

const LangPage = () => {
    const bucketName = "ray-data-development";
    const path = "rayrnd/translations";

    const [apps, setApps] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [data, setData] = useState({ app: "", languages: [] });
    const [addLanguageMode, setAddLanguageMode] = useState(false);
    const [addLanguageCode, setAddLanguageCode] = useState('');

    useEffect(() => {
        getApps();
    }, []);

    useEffect(async () => {
        if (data.app) {
            const list = await ObjectList(bucketName, `${path}/${data.app}`);
            const languages = [];

            for (const x of list) {                
                const ret = await axios.get(`https://${bucketName}.s3.ap-northeast-2.amazonaws.com/${x.Key}`);

                languages.push({
                    language: x.Key.replace(`${path}/${data.app}/`, '').split('.')[0],
                    resource: JSON.parse(ret.data)
                })
            }

            setData({ ...data, languages });
        }
    }, [data.app]);

    useEffect(() => { 
        console.log(data);
    }, [data]);

    useEffect(() => {
        console.log(selectedLanguage);
    }, [selectedLanguage]);

    const getApps = async () => {
        try {
            const ScanFilter = {
                type: {
                    ComparisonOperator: "EQ",
                    AttributeValueList: ["app"],
                }
            }
            const ret = await fetchData_prod("rayrnd-applications", ScanFilter);

            setApps([
                { value: "rtmanager", label: "RAYTEAMS Manager" },
                { value: "rthomepage", label: "RAYTEAMS Homepage" },
                { value: "rtpartner", label: "RAYTEAMS Partner" },
                { value: "rtdeveloper", label: "RAYTEAMS Developer" },
                ...ret.Items.filter(x => x.title).map(x => ({ value: x.name, label: x.title }))]);
        } catch (err) {
            console.log(err);
        }
    }

    const addLanguage = async () => {
        await UploadS3(bucketName, `${path}/${data.app}/${addLanguageCode}.json`, {});
        setData({ ...data, languages: [...data.languages, { language: addLanguageCode, resource: {} }] });
        setAddLanguageMode(false);
    }

    return (
        <div className='p-4'>
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <div className="card-label fw-bold text-gray-800 fs-5">{apps?.find(x => x.value === data.app)?.label || '선택된 App이 없습니다.'}</div>
                    <div className="d-flex">
                        <MLComboSingle
                            options={{
                                name: "status",
                                value: data.app,
                                size: "sm",
                                list: apps,
                            }}
                            css={"ms-2"}
                            handleChanged={(e) => setData({ app: e.target.value, languages: [] })}
                        />
                    </div>
                </div>
                <hr class="mt-0 mb-0"></hr>
                <div className='card-header justify-content-between d-flex'>
                    {/* <ul className='detail-tab nav nav-line-tabs nav-stretch'>
                        {
                            data.languages.length > 0 && data.languages.map(x => {
                                return (
                                    <li className={'nav-item'}>
                                        <a className={`nav-link${x.language === selectedLanguage ? ' active' : ''}`} onClick={() => setSelectedLanguage(x.language)}>
                                            {LANGUAGE.find(y => y.code === x.language)?.label}
                                        </a>
                                    </li>
                                )
                            })
                        }                        
                    </ul> */}
                    <div className='d-flex'>
                    {
                            data.languages.length > 0 && data.languages.map(x => {
                                return (
                                    <MLButton
                                        options={{
                                            label: LANGUAGE.find(y => y.code === x.language)?.label,
                                            size: "sm",
                                            color: x.language === selectedLanguage ? COLORS.PRIMARY : COLORS.SECONDARY,
                                            disabled: data.app ? false : true,
                                            action: () => setSelectedLanguage(x.language),
                                        }}
                                        css={'me-1'}
                                    />
                                )
                            })
                        }                 
                    </div>

                    <div className='d-flex'>
                        {
                            !addLanguageMode ?
                                <MLButton
                                    options={{
                                        label: "언어추가",
                                        needValid: true,
                                        size: "sm",
                                        color: COLORS.PRIMARY,
                                        disabled: data.app ? false : true,
                                        action: () => setAddLanguageMode(true)
                                    }} />
                                :
                                <>
                                    <MLComboSingle 
                                        options={{
                                            list: LANGUAGE.map(x => ({ value: x.code, label: x.label })),
                                            value: addLanguageCode,
                                            size: 'sm'
                                        }}
                                        handleChanged={(e) => setAddLanguageCode(e.target.value)}
                                    />
                                    <MLButton options={{ label: "추가", size: "sm", color: COLORS.PRIMARY, action: () => addLanguage() }} />
                                    <MLButton options={{ label: "취소", size: "sm", color: COLORS.SECONDARY, action: () => setAddLanguageMode(false) }} />
                                </>
                        }
                    </div>
                </div>
            </div>

            <div className='card mt-3'>
                <div className='p-3'>
                    <MLButton options={{
                        label: <span className="ms-1">A (100)</span>,
                        size: "sm",
                        color: COLORS.PRIMARY,
                    }} css="me-1 p-2" />

                    <MLButton options={{
                        label: <span className="ms-1">B (10)</span>,
                        size: "sm",
                        color: COLORS.SECONDARY,
                    }} css="me-1 p-2" />

                    <MLButton options={{
                        label: <span className="ms-1">C (10)</span>,
                        size: "sm",
                        color: COLORS.SECONDARY,
                    }} css="me-1 p-2" />
                </div>
            </div>

            <div className='mt-4 d-flex justify-content-end' style={{ height: '35px' }}>
                <MLButton options={{ label: "저장하기", size: "sm", color: COLORS.PRIMARY }} />
            </div>
            <div className='card mt-2'>
                <table className="table table-row-dashed table-row-gray-300 gy-4 gx-4 mb-0 table">
                    <thead>
                        <tr>
                            <th className="fw-bolder w-325px">Key</th>
                            <th className="fw-bolder">Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            // Object.keys(data.languages.resource).length > 0 && Object.keys(data.languages.resource).map(x => {
                            //     return (
                            //         <tr>
                            //             <td className="fw-bolder">{x}</td>
                            //             <td className="fw-bolder">
                            //                 <MLText options={{ name: "name", value: transData[x] }} css="p-0" />
                            //             </td>
                            //         </tr>
                            //     )
                            // })
                        }
                    </tbody>
                </table>
            </div>
            <div className='mt-2 d-flex justify-content-end' style={{ height: '35px' }}>
                <MLButton options={{ label: "저장하기", size: "sm", color: COLORS.PRIMARY }} />
            </div>
        </div>
    )
}

export default LangPage;