import { Component, useEffect, useState } from "react";
import { connect, useDispatch } from 'react-redux';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { AuthConstant } from "./redux/reducers";
import moment from "moment";
import { putData } from "./libs/db";

export const AuthRoute = ({ loginresult, user, component: Component, roles, ...rest}) => {
	const dispatch = useDispatch();
	const location = useLocation();
	useEffect(() => {
		const exp = JSON.parse(localStorage.getItem("userdata"))?.exp;
		const curTime = Math.round(new Date().getTime() / 1000);
		parseInt(exp) <= curTime && dispatch({ type : AuthConstant.LOGOUT });
		//userHistory();
	}, [location])

	const userHistory = async () => {
		//try {
		//	const item = {
		//		_id : localStorage.getItem("usersub"),
		//		sk : "his:" + new Date().getTime(),
		//		pathname : location.pathname,
		//	}

		//	const ret = await putData("rayteams-manager-user", item);
		//} catch (e) {
		//	console.log(e);
		//}
	}
	
    return (
        <Route
			{...rest}
			render={(props) => {
				if (!loginresult) {
					return (
						<Redirect
							to={{
								pathname: "/Login",
								state: { from: props.location },
							}}
						/>
					);
				}else if(Component){
					return <Component {...props} />;
				}else{
					return <Redirect
						to={{
							pathname: "/HomePage",
							state: { from: props.location },
						}}
					/>
				}
			}}
		/>
    )
};

const mapState = (state) => {
	const user = state.AuthReducer.user;
	const loginresult = state.AuthReducer.loginresult;
	return { user, loginresult };
};

const mapDispatch = dispatch => ({
})

export default connect(mapState)(AuthRoute);
