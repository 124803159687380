import { ReactComponent as IconList } from '../../assets/icons/txt/txt009.svg';
import { ReactComponent as IconCard } from '../../assets/icons/layout/lay002.svg';
import { ReactComponent as IconEmail } from '../../assets/icons/communication/com002.svg';
import { ReactComponent as IconRefresh } from '../../assets/icons/arrows/arr029.svg';
import { ReactComponent as IconMap } from '../../assets/icons/map/map004.svg';
import { ReactComponent as IconClinic } from '../../assets/icons/medicine/med001.svg';
import { ReactComponent as IconLab } from '../../assets/icons/ecommerce/ecm008.svg';
import { ReactComponent as IconDelivery } from '../../assets/icons/customs/iconDelivery.svg';
import { ReactComponent as IconAnonymous } from '../../assets/icons/customs/patients_g1.svg';
import { ReactComponent as IconSearch } from '../../assets/icons/customs/search.svg';
import { ReactComponent as IconSearchW } from '../../assets/icons/customs/search_w.svg';
import { ReactComponent as IconPerson } from '../../assets/icons/customs/person.svg';
import { ReactComponent as IconAlarm } from '../../assets/icons/customs/alarm.svg';
import { ReactComponent as IconChat } from '../../assets/icons/customs/chat.svg';
import { ReactComponent as IconArrowUp } from '../../assets/icons/customs/arrowUp.svg';
import { ReactComponent as IconFile } from '../../assets/icons/customs/file.svg';
import { ReactComponent as IconReLoad } from '../../assets/icons/customs/reload.svg';
import { ReactComponent as BIRaylink } from '../../assets/icons/customs/bi_raylink_normal.svg';
import { ReactComponent as IconMoreRight } from '../../assets/icons/customs/more_right.svg';
import { ReactComponent as IconKR } from '../../assets/icons/countries/kr.svg';
import { ReactComponent as IconAU } from '../../assets/icons/countries/au.svg';
import { ReactComponent as IconCA } from '../../assets/icons/countries/ca.svg';
import { ReactComponent as IconDE } from '../../assets/icons/countries/de.svg';
import { ReactComponent as IconFR } from '../../assets/icons/countries/fr.svg';
import { ReactComponent as IconIE } from '../../assets/icons/countries/ie.svg';
import { ReactComponent as IconIN } from '../../assets/icons/countries/in.svg';
import { ReactComponent as IconJP } from '../../assets/icons/countries/jp.svg';
import { ReactComponent as IconSE } from '../../assets/icons/countries/se.svg';
import { ReactComponent as IconUK } from '../../assets/icons/countries/uk.svg';
import { ReactComponent as IconUS } from '../../assets/icons/countries/us.svg';
import { ReactComponent as WorldMap } from '../../assets/icons/countries/world-map.svg';
import { ReactComponent as IconArrow } from '../../assets/icons/customs/btn_arrow.svg';
import { ReactComponent as IconPlus } from '../../assets/icons/customs/plus.svg';
import { ReactComponent as IconUpdate } from '../../assets/icons/customs/update.svg';
import { ReactComponent as IconMinus } from '../../assets/icons/customs/minus.svg';
import { ReactComponent as IconArrowLeft } from '../../assets/icons/customs/arrow_left.svg';
import { ReactComponent as IconOrderAsc } from '../../assets/icons/customs/order_asc.svg';
import { ReactComponent as IconOrderDesc } from '../../assets/icons/customs/order_desc.svg';
import { ReactComponent as IconCloseBox } from '../../assets/icons/customs/close_box.svg';
import { ReactComponent as IconStar } from '../../assets/icons/customs/star.svg';
import { ReactComponent as IconClose } from '../../assets/icons/customs/close.svg';
import { ReactComponent as IconPin } from '../../assets/icons/customs/pin.svg';
import { ReactComponent as IconPeoples } from '../../assets/icons/customs/peoples.svg';
import { ReactComponent as IconSend } from '../../assets/icons/customs/send.svg';
import { ReactComponent as IconPen } from '../../assets/icons/customs/pen.svg';
import { ReactComponent as IconEdit } from '../../assets/icons/customs/edit.svg';
import { ReactComponent as IconFolder } from '../../assets/icons/customs/folder.svg';
import { ReactComponent as IconPlusDark } from '../../assets/icons/customs/plus-dark.svg';
import { ReactComponent as IconAttention } from '../../assets/icons/customs/attention.svg';
import { ReactComponent as IconLike } from '../../assets/icons/customs/like.svg';
import { ReactComponent as IconUnlike } from '../../assets/icons/customs/unlike.svg';
import { ReactComponent as IconSuggestion } from '../../assets/icons/customs/suggestion.svg';
import { ReactComponent as IconSmile } from '../../assets/icons/customs/smile.svg';
import { ReactComponent as IconPenBubble } from '../../assets/icons/customs/bubble_pen.svg';
import { ReactComponent as IconRmList } from '../../assets/icons/customs/rm_list.svg';
import { ReactComponent as IconRmChart } from '../../assets/icons/customs/rm_chart.svg';
import { ReactComponent as IconRmCircle } from '../../assets/icons/customs/rm_circle.svg';
import { ReactComponent as IconRmCheckCircle} from '../../assets/icons/customs/rm_checkcircle.svg';

function setAttributes (props) {
    const result = { ...props };
    if (result?.size) {
        result.height = props.size;
        result.width = props.size;
    }
    return result;
}

export const MLIconList = (props) => <IconList {...setAttributes(props)} />
export const MLIconCard = (props) => <IconCard {...setAttributes(props)} />
export const MLIconEmail = (props) => <IconEmail {...setAttributes(props)} />
export const MLIconRefresh = (props) => <IconRefresh {...setAttributes(props)} />
export const MLIconMap = (props) => <IconMap {...setAttributes(props)} />
export const MLIconClinic = (props) => <IconClinic {...setAttributes(props)} />
export const MLIconLab = (props) => <IconLab {...setAttributes(props)} />
export const MLIconDelivery = (props) => <IconDelivery {...setAttributes(props)} />
export const MLIconAnonymous = (props) => <IconAnonymous {...setAttributes(props)} />
export const MLIconSearch = (props) => <IconSearch {...setAttributes(props)} />
export const MLIconPerson = (props) => <IconPerson {...setAttributes(props)} />
export const MLIconAlarm = (props) => <IconAlarm {...setAttributes(props)} />
export const MLIconChat = (props) => <IconChat {...setAttributes(props)} />
export const MLIconArrowUp = (props) => <IconArrowUp {...setAttributes(props)} />
export const MLIconFile = (props) => <IconFile {...setAttributes(props)} />
export const MLIconReload = (props) => <IconReLoad {...setAttributes(props)} />
export const MLIconSearchW = (props) => <IconSearchW {...setAttributes(props)} />
export const MLBIRayLink = (props) => <BIRaylink {...setAttributes(props)} />
export const MLIconMoreRight = (props) => <IconMoreRight {...setAttributes(props)} />
export const MLIconKR = (props) => <IconKR {...setAttributes(props)} />
export const MLIconAU = (props) => <IconAU {...setAttributes(props)} />
export const MLIconCA = (props) => <IconCA {...setAttributes(props)} />
export const MLIconDE = (props) => <IconDE {...setAttributes(props)} />
export const MLIconFR = (props) => <IconFR {...setAttributes(props)} />
export const MLIconIE = (props) => <IconIE {...setAttributes(props)} />
export const MLIconIN = (props) => <IconIN {...setAttributes(props)} />
export const MLIconJP = (props) => <IconJP {...setAttributes(props)} />
export const MLIconSE = (props) => <IconSE {...setAttributes(props)} />
export const MLIconUK = (props) => <IconUK {...setAttributes(props)} />
export const MLIconUS = (props) => <IconUS {...setAttributes(props)} />
export const MLWorldMap = (props) => <WorldMap {...setAttributes(props)} />
export const MLIconArrow = (props) => <IconArrow {...setAttributes(props)} />
export const MLIconPlus = (props) => <IconPlus {...setAttributes(props)} />
export const MLIconUpdate = (props) => <IconUpdate {...setAttributes(props)} />
export const MLIconMinus = (props) => <IconMinus {...setAttributes(props)} />
export const MLIconArrowLeft = (props) => <IconArrowLeft {...setAttributes(props)} />
export const MLIconOrderAsc = (props) => <IconOrderAsc {...setAttributes(props)} />
export const MLIconOrderDesc = (props) => <IconOrderDesc {...setAttributes(props)} />
export const MLIconCloseBox = (props) => <IconCloseBox {...setAttributes(props)} />
export const MLIconStar = (props) => <IconStar {...setAttributes(props)} />
export const MLIconClose = (props) => <IconClose {...setAttributes(props)} />
export const MLIconPin = (props) => <IconPin {...setAttributes(props)} />
export const MLIconPeoples = (props) => <IconPeoples {...setAttributes(props)} />
export const MLIconSend = (props) => <IconSend {...setAttributes(props)} />
export const MLIconPen = (props) => <IconPen {...setAttributes(props)} />
export const MLIconEdit = (props) => <IconEdit {...setAttributes(props)} />
export const MLIconFolder = (props) => <IconFolder {...setAttributes(props)} />
export const MLIconPlusDark = (props) => <IconPlusDark {...setAttributes(props)} />
export const MLIconAttention = (props) => <IconAttention {...setAttributes(props)} />
export const MLIconLike = (props) => <IconLike {...setAttributes(props)} />
export const MLIconUnlike = (props) => <IconUnlike {...setAttributes(props)} />
export const MLIconSuggestion = (props) => <IconSuggestion {...setAttributes(props)} />
export const MLIconSmile = (props) => <IconSmile {...setAttributes(props)} />
export const MLIconPenBubble = (props) => <IconPenBubble {...setAttributes(props)} />
export const RMIconList = (props) => <IconRmList {...setAttributes(props)} />
export const RMIconChart = (props) => <IconRmChart {...setAttributes(props)} />
export const RMIconCircle = (props) => <IconRmCircle {...setAttributes(props)} />
export const RMIconCheckCircle = (props) => <IconRmCheckCircle {...setAttributes(props)} />