import { useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import { MLPanelLoading } from '../../common/MLPanel';
import { JWT } from '../../../config';
import { Pie, getElementAtEvent, Doughnut } from 'react-chartjs-2';
import { getRandomColors } from '../../../libs/colors';
import { uniqBy } from 'lodash';
import { ChartDays, ChartMonths } from '../../common/ChartDays';
import { SEARCHPERIOD } from '../../../constants/RMConstants';

const WidgetPieChartAppUsage = ({ logs, period, type, title, appname, stats }) => {
	const dispatch = useDispatch();
	const [chartData, setChartData] = useState([]);
	const [loading, setLoading] = useState(true);
	const chartRef = useRef();

    useEffect(() => {
		if(!stats) {
			//getData(period, type, appname)
		}
	}, []);

	useEffect(() => {
		if(stats && period) {
			generate(stats, type, period, appname);
		}
	}, [period, stats]);

	const generate = (list, type, period) => {
		const clist = [];
		const barDataset = [];
		const colorSet = getRandomColors(list.length, 0.8);
		var searchItems = SEARCHPERIOD.find(y => y.value === period);
		if(period === "days" || period === "weeks" || period === "month") {
			ChartDays(searchItems.duration, searchItems.mcode, searchItems.dFormat).map(x => {
				list.filter(y => moment(y.sk?.split(":")[1]).format("YYYY-MM-DD") == x && y?._id?.toLowerCase().includes(appname != "" ? appname?.toLowerCase() : type.toLowerCase())).map(z => {
					if(!clist.filter(y => y.name === z._id && y.label == moment(z.sk?.split(":")[1]).format("YYYY-MM-DD")).length == 0) {
						var objIndex = clist.findIndex((obj => obj.name == z._id && obj.label == moment(z.sk?.split(":")[1]).format("YYYY-MM-DD")));
						clist[objIndex].count += z.called;
					} else
						clist.push({ name : z._id, count: z.called, label: moment(z.sk?.split(":")[1]).format("YYYY-MM-DD") });
				});
			});
		} else if(period === "months" || period === "all") {
			ChartDays(searchItems.duration, searchItems.mcode, searchItems.dFormat).map(x => {
				list.filter(y => moment([y.sk?.split(":")[1].substring(0,4), parseInt(y.sk?.split(":")[1].substring(4) -1)]).format(searchItems.dFormat) == moment(x).format(searchItems.dFormat) && y?._id?.toLowerCase().includes(appname != "" ? appname?.toLowerCase() : type.toLowerCase())).map(z => {
					if(!clist.filter(y => y.name === z._id && y.label == moment([z.sk?.split(":")[1].substring(0,4), parseInt(z.sk?.split(":")[1].substring(4) -1)]).format(searchItems.dFormat)).length == 0) {
						var objIndex = clist.findIndex((obj => obj.name == z._id && obj.label == moment([z.sk?.split(":")[1].substring(0,4), parseInt(z.sk?.split(":")[1].substring(4) -1)]).format(searchItems.dFormat)));
						clist[objIndex].count += z.called;
					} else
						clist.push({ name : z._id, count: z.called, label: moment([z.sk?.split(":")[1].substring(0,4), parseInt(z.sk?.split(":")[1].substring(4) -1)]).format(searchItems.dFormat) });
				});
			});
		} else 
			list.filter(x => x?.sk?.includes("M:") && x?._id?.toLowerCase().includes(type.toLowerCase())).map(x => {
				clist.push({ name : x._id, count: x.called, label: x.sk?.split(":")[1] });
			});
		
		const result = [];
		clist.reduce(function(res, value) {
			if (!res[value.name]) {
				res[value.name] = { name: value.name, count: 0 };
				result.push(res[value.name])
			}
			res[value.name].count += value.count;
			return res;
		}, {});

        barDataset.push(
            {
                label: result.map(x => x.name),
                data: result.map(x => x.count),
                barThickness: 28,
                backgroundColor: colorSet.map(x => x.opacityColor)
            }
        )

		setChartData({
            labels: result.map(x => x.name),
            datasets: barDataset
        });
		setLoading(false);
	}

	const onClick = (event) => {
		const elem = getElementAtEvent(chartRef.current, event);
		console.log(elem);

		var data = chartData.datasets[elem[0].datasetIndex];
		console.log(data);
		console.log(data.label[elem[0].index]);
	}

  	return <>
		{!loading && <div className='card'>
			{chartData && <>
			<div className='card-header'>
				<div className='card-title'>{title} Usages</div>
			</div>
			<div className='card-body'>
				<Doughnut
					ref={chartRef}
					height={300}
					data={chartData} 
					options={{
						responsive: true,
						maintainAspectRatio: false,
						animation: { animateScale: true },
						plugins: { legend: { display : true, position: "right" }, },
					}} 
					onClick={onClick}/>
			</div>
			</> }
		</div>}
		{loading && <MLPanelLoading />}
	</>
}

const mapState = (state) => {
    const logs = state.UsageReducer.logs;
	const dailylogs =  state.UsageReducer.dailylogs;
	const stats = state.UsageReducer.stats;
	return { logs, dailylogs, stats };
};

export default connect(mapState)(WidgetPieChartAppUsage);