import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import { useTranslation } from 'react-i18next';
import { RotatingSquare } from 'react-loader-spinner';
import { BUTTONS, COLORS } from '../../constants/FromInputs';
import { MLButton } from './MLButton';

export const MLPanelNormalConfirm = ({
	bg = 'warning',
	title,
	contents,
	okcancel,
	callbackok,
	callbackcancel,
	buttons = [] }) => {

	const [t] = useTranslation(['translation']);

	return <>
		<div className={'card bg-' + bg }>
			<div className='card-header'>
				<div className='card-title bold fw-3'>{title}</div>
			</div>
			<div className='mt-2 card-body'>
				{contents}
				<div className='btns mt-3'>
					{okcancel && <>
						<MLButton options={{
							label: t("삭제"),
							color: COLORS.PRIMARY,
							action: callbackok
						}} css="me-1" />
						<MLButton options={{
							label: t("취소"),
							color: COLORS.LIGHT,
							action: callbackcancel
						}} />
					</>}
					{buttons.map((y, cdx) => <React.Fragment key={cdx}>
						{y.type === BUTTONS.NORMALBUTTON && <MLButton options={y.options} />}
					</React.Fragment>)}
				</div>
			</div>
		</div>
	</>
}

export const MLPanelDelete = ({
	title,
	contents,
	buttons = [] }) => {
	
	const [t] = useTranslation(['translation']);

	return <>
		<Card bg='danger' border='dark'>
			<Card.Header className='card-header separator'>
				<Card.Title className='card-title bold text-white'>{t(title)}!!!</Card.Title>
			</Card.Header>
			<Card.Body className='text-danger fs-4'>
				{t(contents)}
				<div className='btns mt-3'>
					{buttons.map((y, cdx) => <React.Fragment key={cdx}>
						{y.type === BUTTONS.NORMALBUTTON && <MLButton options={y.options} />}
					</React.Fragment>)}
				</div>
			</Card.Body>
		</Card>
	</>
}

export const MLPanelNoData = ({
	title = "안내",
	notitle = false,
	contents = "데이터가 존재하지 않습니다.",
	noborder = false,
	css = "",
	buttons = [] }) => {

	const [t] = useTranslation(['translation']);
	const [obj, setObj] = useState({
		title: title || "안내",
		contents: contents || "데이터가 존재하지 않습니다."
	});

	return <>
		{!noborder && <div className={css ? 'card ' + css : 'card'}>
			<Card.Body className='text-center py-12'>
				{!notitle && <h4 className='card-title bold'>{t(obj.title)}</h4>}
				<div className='text-center'>{t(obj.contents)}</div>
			</Card.Body>
		</div>}
		{noborder && <div className={css}>
			{obj.title && <div className='p-3 pb-0'>
				<h4 className='text-center'>{t(obj.title)}</h4>
			</div>}
			<div className='text-center pb-3'>
				{t(obj.contents)}
				{buttons.length > 0 && <div className='btns mt-3'>
					{buttons.map((y, cdx) => <React.Fragment key={cdx}>
						{y.type === BUTTONS.NORMALBUTTON && <MLButton options={y.options} />}
					</React.Fragment>)}
				</div>}
			</div>
		</div>}
	</>
}

export const MLPanelLoading = ({
	title = "",
	notitle = false,
	contents = "",
	noborder = false,
	css = "",
	titleColor = "",
	subColor = "",
	buttons = [] }) => {

	const [t] = useTranslation(['translation']);
	const [obj, setObj] = useState({
		title: title || "잠시만요!",
		contents: contents || "데이터를 가져오는 중입니다."
	});

	return <>
		{!noborder && <div className={css ? 'rayloadingbox card ' + css : 'rayloadingbox card'}>
			<Card.Body className='text-center justify-content-center d-flex flex-rows align-items-center'>
				<RotatingSquare
					ariaLabel="rotating-square"
					visible={true}
					color="#000"
					strokeWidth="4"
				/>
				<div className='text-start'>
					<div className='fs-4' style={{ color: titleColor ?? '' }}>{t(obj.title)}</div>
					<div className={!subColor ? 'text-gray-600' : ''} style={{ color: subColor ?? '' }}>{t(obj.contents)}</div>
				</div>
			</Card.Body>
		</div>}
		{noborder && <div className={css + " rayloadingbox "}>
			<div className='text-center justify-content-center d-flex flex-rows align-items-center'>
				<RotatingSquare
					ariaLabel="rotating-square"
					visible={true}
					color="#000"
					strokeWidth="4"
				/>
				<div className='text-start'>
					<div className='fs-4' style={{ color: titleColor ?? '' }}>{t(obj.title)}</div>
					<div className={!subColor ? 'text-gray-600' : ''} style={{ color: subColor ?? '' }}>{t(obj.contents)}</div>
				</div>
			</div>
		</div>}
	</>
}

export const MLPanelLoadingOverlay = ({ position = 'fixed', titleColor = 'white', subColor = '#8e92b9', noborder = false }) => {
	return <div
		className='d-flex align-items-center justify-content-center'
		style={{ position: position, left: 0, top: 0, right: 0, bottom: 0, zIndex: 1000, backgroundColor: 'rgba(11, 13, 32, 0.75)' }}>
		<MLPanelLoading titleColor={titleColor} subColor={subColor} noborder />
	</div>
}

export const MLPanelNoDataForRayLink = ({
	title = "",
	notitle = false,
	contents = "",
	noborder = false,
	css = "",
	buttons = [] }) => {

	const [t] = useTranslation(['translation']);
	const [obj, setObj] = useState({});

	useEffect(() => {
		setObj({
			title: title || t("No Records"),
			contents: contents || t("Not found any records")
		});
	}, [title, contents, t])

	return <div className="no-result overflow-hidden" style={{ userSelect: 'none' }}>
		<div className="row">
			<div className="col-xxl-6 no-result-txt">
				<h2>{t(obj.title)}</h2>
				<p>{t(obj.contents)}</p>
			</div>
			<div className="col-xxl-6 no-result-img">
				<img src="/img/img_noresult_1.png" />
			</div>
		</div>
	</div>
}

export const MLPanelNoDataLayout = ({
	title = "",
	notitle = false,
	showimg = true,
	contents = "",
	noborder = false,
	top = 0,
	hgt = "90",
	buttons = [] }) => {

	const [t] = useTranslation(['translation']);
	const [obj, setObj] = useState({});

	useEffect(() => {
		setObj({
			title: title || t("No Records"),
			contents: contents || t("Not found any records")
		});
	}, [title, contents, t])

	return <div className="d-flex align-items-center w-100 text-center" style={{ 
			userSelect: 'none',
			height : top ? "initial" :  hgt + 'vh' ,
			paddingTop : top
		}}>
		<div className='mx-auto'>
			<div className='mb-2'>
				<h2>{t(obj.title)}</h2>
				<div className='text-comment'>{t(obj.contents)}</div>
			</div>
		</div>
	</div>
}
