import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { history } from '../../history';
import { connect, useDispatch } from 'react-redux';
import { fetchData } from '../../libs/db';
import MLTable from '../../components/common/MLTable';

const PartnerUserList = ({ partner }) => {

	const dispatch = useDispatch();

	const [t] = useTranslation(['translation']);
	const [ items, setItems ] = useState([]);
	const [ list, setList ] = useState([]);
	const [ process, setProcess ] = useState(false);
	const [ err, setErr ] = useState("");
	const [ search, setSearch ] = useState("");
	const [ partnerOnly, setPartnerOnly ] = useState("p");
	
	useEffect(() => {
		if(partner.partnerKey)
			getData();
	}, []);

	const getData = async () => {
		const ScanFilter = {
			partnerKey: {
				ComparisonOperator: "EQ",
				AttributeValueList: [partner.partnerKey],
			}
		}
		try {
			const ret = await fetchData("rayteams-user", ScanFilter);
			var list = ret.Items;
			console.log(list);
			setItems(list);
		} catch (e) {
			console.log(e);
		}
	}


	const headers = [
		{ value : "_id", label : "Name", css : "text-start", type : "PUSER" },
        { value : "partnerKey", label : "Partner Key", css : "text-start" },
        { value : "created", label : "Created", css : "text-center", type : "DATE" }
	]
	
  	return <>
		<MLTable
			rowkey={"_id"}
			headers={headers} 
			items={items} 
			noheader={true} />
	</>

}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const allusers =  state.ProgramReducer.allusers;

	return { user, allusers };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(PartnerUserList);
