import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { MLText } from '../../components/common/MLInput';
import { MLPanelLoading } from '../../components/common/MLPanel';
import { MLFileSize } from '../../components/common/MLCommon';
import Select from 'react-select';
import MLTable from '../../components/common/MLTable';

import { fetchData } from '../../libs/db';
import WidgetBarChartAppUsage from '../../components/widgets/dashboard/WidgetBarChartAppUsage';
import RMWidgetBarChart from '../../components/widgets/RMWidgetBarChart';
import RMWidgetLineChart from '../../components/widgets/RMWidgetLineChart';
import SimpleDayChart from '../../components/widgets/SimpleDayChart';
import { COUNTRIES } from '../../constants/Country';
import { useTranslation } from 'react-i18next';

const ProductChart = ({ items }) => {
    const [pList, setPlist] = useState([]);
    const [cList, setClist] = useState([]);
    const [pInfoList, setPinfoList] = useState([]);

    const remainDay = 30;
    const remain = new Date().getTime() + (remainDay * 24 * 3600 * 1000);
    const [t] = useTranslation(['translation']);

	useEffect(() => {
        //getProducts();
        getProductsData();
        // 법인별 판매 건수
        // 법인별 가격 등록 건수
        // product별 판매 건수
	}, [items]);
    
    const getProductsData = () => {
        let pList = [];
        let cList = [];
        let pInfoList = [];

        items.map(x => {
            if(x.sk.includes("sell") && x.sk.split(":").length === 4) {
                if(x.sk.split(":")[1] !== "ray") {
                    for (let index = 0; index < x.sells; index++) {
                        pList.push({
                            product: x._id,
                            created: x.sk.replace("sell:", "").replaceAll(":", "-"),
                        });
                    }
                }
            };

            if(x.sk.includes("sell") && COUNTRIES.find(c => c.countryCode === x.sk.split(":")[2]) && x.sk.split(":").length == 4) {
                for (let index = 0; index < x.sells; index++) {
                    cList.push({
                        countryCode: x.sk.split(":")[2]
                    });
                };
            };
        });
        setPlist(pList);
        setClist(cList);
        
        items.filter(f => f.sk.includes("info")).map(x => {
            pList.find(l => l.product === x._id) &&
            pInfoList.push({
                product: x.title,
                amount: items.filter(c => c.sk.includes("sell:") && c.sk.split(":").length == 2 && c._id === x._id).map(d => d.amount).reduce((sum, a) => sum + a, 0),
                sells: items.filter(c => c.sk.includes("sell:") && c.sk.split(":").length == 2 && c._id === x._id).map(d => d.sells).reduce((sum, a) => sum + a, 0),
            })
        });
        setPinfoList(pInfoList);
    }

  	return (<>
        {items && <div className="d-flex flex-column flex-column-fluid">
            <div className="post p-7">
                <div className='row mb-5'>
                    <div className='col'>
                        <SimpleDayChart data={pList} title={t("Product 판매 추이")} period={"days"} target={"created"} max={30} />
                    </div>
                </div>
                <div className='row mb-5'>
                    <div className='col'>
                        <RMWidgetBarChart data={pList} title={t("월별 Product 판매 추이")} period={"months"} target={"created"} top={false} height={235}
                            max={10} />
                    </div>
                    <div className='col'>
                        <RMWidgetBarChart 
                            data={cList} 
                            type={"Country"} 
                            title={t("국가별 Sells (Top 10)")}
                            target={"countryCode"} 
                            height={235}
                            max={10} />
                    </div>
                    <div className='col'>
                        <RMWidgetBarChart
                            data={pInfoList}
                            type={"Product"}
                            title={t("Product별 Sells (Top 10)")}
                            target={"sum"}
                            height={235}
                            max={10}/>
                    </div>
                </div>
            </div>
        </div>}
    </>
  	);
}

const mapState = (state) => {
	const region = state.AuthReducer.region;
	return { region };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, null)(ProductChart);
