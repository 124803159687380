import axios from 'axios';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { MLComboSingle, MLText } from '../../components/common/MLInput';
import { MLPanelLoading } from '../../components/common/MLPanel';
import MLTable from '../../components/common/MLTable';
import { FeedBackConstant } from '../../redux/reducers';
import { fetchData } from '../../libs/db';
import { ProgramConstant, PaymentConstant } from '../../redux/reducers';
import { AlertConstant } from '../../redux/reducers';
import { FEEDBACKSTAUS } from '../../constants/RMConstants';

const FeedBacksPage = ({ groups, region, regionusers, feedbacks, page }) => {
	const dispatch = useDispatch();
	const [search, setSearch] = useState("");
	const [items, setItems] = useState([]);
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState();

	useEffect(() => {
		dispatch({
			type: ProgramConstant.SET_PAGE, page: {
				code: "feedbacks",
				view: "summary",
				title: "Payments",
				small: "사용자 피드백에 대한 관리",
				options: []
			}
		})
	}, []);

	useEffect(() => {
		getData_dUsers();
	}, [region]);

	useEffect(() => {
		genData();
	}, [search, feedbacks, status]);

	const headers = [
		{ link: "_id", linkpath: "/FeedBacksInfo", value: "_id", label: "ID", css: "text-start", style: { width: 300 } },
		{ link: "groupinfo._id", linkpath: "/GroupInfo", value: "groupinfo.name", label: "Group", css: "text-start", style: { width: 150 }, type: 'LINKARRAYVALUE' },
		{ value: "appname", label: "App Name" },
		{ value: "title", label: "Title" },
		{ value: "status", label: "Status" },
		{ value: "version", label: "Version" },
		{ value: "created", label: "Create Date", css: "text-end", type: "DATE" },
	]

	// country 선택
	const getData_dUsers = async () => {
		// 총 사용자 숫자, 
		// 그룹별 사용자 숫자, 
		// User type 별 사용자

		setLoading(true);
		const ScanFilter = {
			sk: {
				ComparisonOperator: "BEGINS_WITH",
				AttributeValueList: ["f:"],
			},
		}
		try {
			const ret = await fetchData("rayteams-feedback", ScanFilter);
			dispatch({ type: FeedBackConstant.GET_ALL_FEEDBACKS, items: ret.Items });
		} catch (e) {
			dispatch({ type: AlertConstant.ERROR, msg: e.message, show: true });
			dispatch({ type: FeedBackConstant.GET_ALL_FEEDBACKS, items: [] });
		}
		setLoading(false);
	}

	const genData = () => {
		setItems(search ? feedbacks
			.filter(x => x.groupinfo.name?.indexOf(search) > -1 || x.appname?.indexOf(search) > -1)
			.filter(x => {
				if (!status) return true
				if (status && x.status === status) return true
			})
			: (feedbacks.filter(x => {
				if (!status) return true
				if (status && x.status === status) return true
			}) || []));
	}

	return <div className='p-4'>
		<div className="card">
			<div className="card-header d-flex justify-content-between align-items-center">
				<div className="card-label fw-bold text-gray-800 fs-5">{items.length}</div>
				<div className="d-flex">
					<MLText options={{
						placeholder: "검색어 입력",
						size: "sm",
						value: search,
					}} handleChanged={(e) => setSearch(e.target.value)} />
					<MLComboSingle options={{
						name: "status",
						value: status,
						size: "sm",
						list: FEEDBACKSTAUS,
					}} css={"ms-2 w-150px"} handleChanged={(e) => setStatus(e.target.value)} />
				</div>
			</div>
			{!loading && <MLTable headers={headers} items={items.sort((a, b) => a.created > b.created ? -1 : 1)} noheader={true} />}
			{loading && <MLPanelLoading />}
		</div>
	</div>
}

const mapState = (state) => {
	const groups = state.AuthReducer.groups;
	const feedbacks = state.FeedBackReducer.feedbacks;
	const page = state.ProgramReducer.page;

	return { groups, feedbacks, page };
};

const mapDispatch = (dispatch) => ({

});

export default connect(mapState, null)(FeedBacksPage);
