import { useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import RMNotice from "../components/admin/RMNotice";
import { ProgramConstant } from '../redux/reducers';
import { fetchData } from "../libs/db";
import { uniq, uniqBy } from 'lodash';
import RMWidgetBarChart from '../components/widgets/RMWidgetBarChart';
import RMCountPanel from '../components/common/RMCountPanel';
import RMBigChart from '../components/common/RMBigChart';
import RMWidgetPieChart2 from '../components/widgets/RMWidgetPieChart2';
import { useTranslation } from 'react-i18next';

const HomePage = ({users, user, page}) => {
	const dispatch = useDispatch();
    const [t] = useTranslation(['translation']);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();

	useEffect(() => {
		dispatch({ type : ProgramConstant.SET_PAGE, page : { 
			code : "dashboard", 
			view : "summary", 
			title : "Dashboard", 
			small : t("Dashboard 관련 정보"), 
			options : [
			]
		}})
	}, []);

    useEffect(() => {
    }, []);
    
    const getDeviceData = async () => {
        setLoading(true)
        const ScanFilter = {
			sk: {
				ComparisonOperator: "EQ",
				AttributeValueList: ["info"],
			},
		}

        try {
            const ret = await fetchData("rayteams-device", ScanFilter)
            setData(ret.Items);

        } catch(e) {

            setLoading(false);
        }

        setLoading(false);
    }

    return <div>
        <div className="p-4 pb-0 row">
            <div className='col-8'>
                <RMCountPanel />
            </div>
            <div className='col-4'>
                <RMNotice />
            </div>
        </div>
        <div className="p-4 pb-0 row">
            <div className='col-12'>
                <RMBigChart />
            </div>
        </div>
        {(process.env.REACT_APP_COMPANY === 'ray' || !process.env.REACT_APP_COMPANY) && <div className="p-4 row">
            <div className='col-4'>
                <RMWidgetPieChart2 typename={"projecttype"} title={t("프로젝트 유형")} height={200} />
            </div>
            <div className='col-4'>
                <RMWidgetPieChart2 typename={"grouptype"} title={t("기관 타입")} height={200} />
            </div>
            <div className='col-4'>
                <RMWidgetPieChart2 typename={"countryCode"} title={t("국가별 케이스")} height={200} />
            </div>
        </div>}
    </div>
}

const mapState = (state) => {
    const users = state.AuthReducer.users;
    const user = state.AuthReducer.user;
	const page = state.ProgramReducer.page;

	return { users, user, page };
};

export default connect(mapState, null)(HomePage);
