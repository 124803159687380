import { Modal } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { BUTTONS, COLORS, INPUTS, SPAN } from '../../constants/FromInputs';
import { MLButton } from '../common/MLButton';
import { MLForm } from '../common/MLForm';
import { AuthConstant, getAPI, getHeader, getAdminCreateHeader } from '../../redux/reducers/AuthReducer';
import moment from 'moment';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { MLRadioButton, MLToggle } from '../common/MLInput';
import { RegionList } from '../../libs/cogInfo';
import { AWSUserDelete, AWSRMUserUpdate } from '../../libs/aws';
import { Loader } from '../common/Loader';
import { useTranslation } from 'react-i18next';
import { RTAudit } from '../../libs/audit';
const RMModalAdminUpdate = ({group, modalData, callbackClose }) => {
	const dispatch = useDispatch();
	const [t] = useTranslation(['translation']);
    const [data, setData] = useState({ name : "", email : "", password : "" });
	const [selectedRegion, setRegions] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
        setRegions([]);
        setData({ name : modalData.name, email : modalData.email, password : "" });
        modalData?.regionList && modalData.regionList.split(",").map((x) => {
            setRegions(pRegion => ({ ...pRegion, [x]: true }))
        })
	}, [modalData]);

	const createAdminUserReionList = async (regionlist) => {
		for (const r of regionlist) {
			const data2 = {
				email : data.email,
				name : data.name,
				isadmin : false,
				group : 'manager',
				region : r
			}
	
			try {
				const ret = await axios.post("https://auth.raysmiler.co.kr/admincreate", data2, getAdminCreateHeader());
			} catch {

			}
		}

		setLoading(false);
	}

	const updateSub = async () => {
        const pRegionList = [];
		Object.keys(selectedRegion).map( async (fkey) => {
			selectedRegion[fkey] === false && await AWSUserDelete(fkey, data.email);
            selectedRegion[fkey] === true && pRegionList.push(fkey);
		});

		const ret = await AWSRMUserUpdate("ap-northeast-2", pRegionList.join(","), data.email);
        await createAdminUserReionList(pRegionList);
		callbackClose && callbackClose();
		RTAudit({ lvl: 4, msg: 'Updated User'}, JSON.stringify({ subject: data }));
	}
    
    const controls = [
		{
			type : INPUTS.EMAIL,
			options : {
				label : t('이메일'),
				name : 'email',
				required : true
			}
		},
		{
			type : INPUTS.TEXT,
			options : {
				label : t('이름'),
				name : 'name',
                value : data?.name,
				required : true
			}
		},
		{
            type : INPUTS.COMPONENT,
            options : { },
            component : <>
				<tr>
					{RegionList.map((x, idx) => {
						return (
							<td id={idx}>
							<MLToggle
        					    options={{
        					        label: RegionList.find(y => y.value === x.value).label,
        					        name: x.value,
        					        value: selectedRegion[x.value],
        					        readonly: false,
        					        inline: false
        					    }}
        					    handleChanged={({ target: { name, value } }) => setRegions(prev => ({ ...prev, [name]: value }))}
        					/>
							</td>
						)
					})}
				</tr>
			</>
        },
		{
			type : SPAN.VERTICAL,
			options : { size : 10 }
		},
		{
			type : BUTTONS.BUTTONS, 
			options : { },
			buttons : [{
				type : BUTTONS.NORMALBUTTON, 
				options : { 
					label : t('Update User'), 
					color : COLORS.PRIMARY,
                    action : () => updateSub()
				}
			},{
				type : BUTTONS.NORMALBUTTON, 
				options : { 
					label : t('Cancel'), 
					color : COLORS.SECONDARY,
                    action : () => callbackClose()
				}
			}]
		}
	];
	
	return <div className=''>
		<Modal show={modalData.show} size={"lg"} style={{ backgroundColor: "#42476c !important"}}>
			<Modal.Header className='modal-header header-bg'>
				<h2>{t("Admin User Update")}
					<small className={"ms-2 fs-7 fw-normal opacity-50 "}>{t("선택한 지역에 Admin User를 추가할 수 있습니다.")}</small>
				</h2>
			</Modal.Header>
			<Modal.Body className='flex-fill pt-0 formcard pt-6'>
				{loading && <Loader />}
				{!loading && 
					<MLForm controls={controls} data={data} onChanged={(e) => { console.log(e);setData(e);}} />
				}
			</Modal.Body>
		</Modal>
	</div>
}

const mapState = (state) => {
	return {  };
};

const mapDispatch = dispatch => ({
})

export default connect(mapState, mapDispatch)(RMModalAdminUpdate);