import axios from "axios";
import { connect, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from "react";
import { BUTTONS, COLORS, INPUTS, SPAN } from '../../constants/FromInputs';
import { MLButton } from '../../components/common/MLButton';
import { Modal } from 'react-bootstrap';
import MLTable from "../../components/common/MLTable";
import { useTranslation } from 'react-i18next';
import { Store } from "../../store";
import { MLPanelNoData } from "../../components/common/MLPanel";
import { RTAudit } from '../../libs/audit';
import { fetchData, delpkAllData, insertData, pkData, idData, putData, delData, updateData } from '../../libs/db';
import { MLComboSingle, MLText, MLTextArea } from "../../components/common/MLInput";
import { InfoAction } from "../../redux/actions";

const getEnv = () => {
    if(location.href.indexOf("localhost") > -1 || location.href.indexOf("127.0.0.1") > -1){
        return "dev-local";
    }else if(location.href.indexOf("raydevelop") > -1){
        return "development";
    }
    return "production";
}

const B2BDevicePage = ({ user, allgroups, allpartners, partner, callbackUpdate }) => {
    const dispatch = useDispatch();
    const { _id, _tab = 'info' } = useParams();
	const [t] = useTranslation(['translation']);
	const [ items, setItems ] = useState([]);
	const [ list, setList ] = useState([]);
	const [ process, setProcess ] = useState(false);
	const [ err, setErr ] = useState("");
	const [ addD, setAddD ] = useState({});
	const [ modalData, setModalData ] = useState({
		show : false,
		title : "Add Devices",
		body : <div>
		</div>,
		footerbuttons : [
			{ label : "Cancel", size : "sm", color : COLORS.SECONDARY, action : () => setModalData({...modalData, show : false})},
			{ label : "Add Devices", size : "sm", color : COLORS.PRIMARY, action : () => save()}
		]
	});
    const perms = Store.getState().AuthReducer?.loginuser?.perms || [];
	const [ search, setSearch ] = useState("");
	
	const nFormat = (s) => {
		return s.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

    useEffect(() => {
        getItems();
    }, []);

    const getItems = async () => {
        const ret  = await InfoAction.callDB({ type : "query", region : "ap-northeast-2", params : {
			TableName: "rayteams-device",
            IndexName : "b2bcode-index",
			KeyConditionExpression: "#b2bcode = :b2bcode",
			ExpressionAttributeNames: { "#b2bcode": "b2bcode" },
			ExpressionAttributeValues: { ":b2bcode": _id }
		}});
        console.log("ret", ret);
        setList(ret);
    }

	const save = async () => {
		const list = addD.sns.split(/\n/);
		for(let i = 0; i < list.length; i++) {		// for 문
            await InfoAction.callDB({ type : "update", region : "ap-northeast-2", params : {
				TableName: "rayteams-device",
				UpdateExpression : "SET #DeviceName = :DeviceName, #b2bcode = :b2bcode, #registered = :registered, #updated = :updated",
				ExpressionAttributeNames: { 
					'#DeviceName' : 'DeviceName', 
					'#b2bcode' : 'b2bcode', 
					'#updated' : 'updated',
					'#registered' : 'registered',
				},
				ExpressionAttributeValues: { 
					':DeviceName' : addD.devicetype, 
					':b2bcode' : _id, 
					':registered' : _id ? new Date().getTime() : 0,
					':updated' : _id ? new Date().getTime() : 0,
				},
				Key: { sn : list[i].trim(), sk: 'info' }
			}});
		}
		setModalData({
			show : false,
			title : "Add Devices",
		});
		RTAudit({ lvl : 7, msg : 'Register devices to ' + _id }, JSON.stringify({ sn: list }));
        getItems();
	}

	const showAdd = () => {
		setAddD({
			devicetype : "",
			sns : ""
		})
		setModalData({
			show : true,
			title : "Add Devices",
		});
	}

	const delClick = async (item) => {
        const ScanFilter = {
            sn: {
                ComparisonOperator: "EQ",
                AttributeValueList: [item.sn],
            },
            sk: {
                ComparisonOperator: "EQ",
                AttributeValueList: ["info"],
            },
        }
        const d = await fetchData("rayteams-device", ScanFilter);
        if(d.Count > 0){
            const delItem = d.Items[0];
            delete delItem.isactive;
            delete delItem.issharable;
            delete delItem.b2bcode;
            await putData("rayteams-device", {...delItem });
        }
		RTAudit({ lvl : 7, msg : 'Unregister device(' + _id + ') - ' + item.sn });
        getItems();
	}

	const headers = [
		{ link : "sn", value : "sn", label : "SN", css : "text-start", linkpath : "/device" },
        { value : "DeviceName", label : "DeviceName", css : "text-start" },
        { value : "activated", label : "Activated", css : "text-center", type : "DATESHORT", nodatamsg : "Not activated" },
        { value : "registered", label : "Registered", css : "text-center", type : "DATESHORT" },
        { label : "", code : "ptn-delsn", btncss : "btn-danger", css : "text-center", type : "BUTTON2", btnClick : async (item) => {
			await delClick(item);
		} },
	]

    return <div className='p-4'>
        <div className="d-flex justify-content-between mb-2">
            <h3>Devices <small className="ms-2">{list.length}</small></h3>
            <div className="card-toolbar d-flex align-items-center">
                <MLButton options={{
                    code: "ptn-adddevice",
                    label : t("Add devices"),
                    color : COLORS.PRIMARY,
                    action: () => showAdd()
                }} css='me-1' />
				<MLText options={{
					placeholder : "검색어 입력",
					size : "sm",
					value : search,
				}} handleChanged={(e) => setSearch(e.target.value)} css="mb-0" />
            </div>
        </div>
        <Modal show={modalData.show} size={"lg"} style={{ backgroundColor: "#42476c !important"}}>
            <Modal.Header className='modal-header header-bg'>
                <h3>Add Devices</h3>
            </Modal.Header>
            <Modal.Body className='flex-fill pt-0 formcard pt-6'>
                <div>
                    <div className='fs-6 my-2 fw-bolder'>Select device type</div>
                    <MLComboSingle options={{
                        value : addD.devicetype,
                        name : "devicetype",
                        list : [
                            { value : "RAYFace", label : "RAYFace" },
                            { value : "RAYiOS", label : "RAYiOS" },
                        ]
                    }} handleChanged={(e) => setAddD({...addD, devicetype : e.target.value})}/>
                    <div className='fs-6 mt-4 mb-2'>
                        <strong>Add Serial No.(한줄에 1개의 SN 작성)</strong><br />
                        중복 검사하여 신규의 장비만 등록합니다.
                    </div>
                    <div>
                        <MLTextArea options={{
                            name : "sns",
                            value : addD.sns,
                            rows : 10,
                        }} handleChanged={(e) => setAddD({...addD, sns : e.target.value})}/>
                    </div>
                </div>
                <div className='mt-4 text-end'>
                    <MLButton
                        options={{ label : "Cancel", size : "md", color : COLORS.DEFAULT, action : () => setModalData({...modalData, show : false})}}
                        css={'me-1'} />
                    <MLButton
                        valid={addD.devicetype && addD.sns}
                        options={{ label : "Add Devices", size : "md", needValid : true,  color : COLORS.PRIMARY, action : () => save()}}
                        css={'me-1'} />
                </div>
            </Modal.Body>
        </Modal>
        {perms.includes('ptn-showdevice') && <MLTable 
            rowkey={"sn"}
            noheader={true} 
            headers={headers} 
            items={list.filter(x => x?.sn.indexOf(search) > -1)?.sort((a, b) => a.sn > b.sn ? 1 : -1)} 
            loading={process} />}
        {!perms.includes('ptn-showdevice') && <MLPanelNoData noborder={true} contents={t('데이터를 볼 권한이 없습니다.')} />}
    </div>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;

	return { user };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(B2BDevicePage);
