/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BsArrowDown, BsArrowUp, BsX } from 'react-icons/bs';
import { BUTTONS, INPUTS, SPAN } from '../../constants/FromInputs';
import { Store } from '../../store';
import { MLButton, MLSubmit } from './MLButton';
import MLComboSingleByCode from './MLComboSingleByCode';
import { MLFileSingle } from './MLFileInputs';
import { MLAddItems, MLComboMulti, MLComboSingle, MLDate, MLEmail, MLHidden, MLHR, MLHTMLEditor, MLNumber, MLPassword, MLRadioButton, MLSpanV, MLText, MLTextArea, MLToggle } from './MLInput';
import { MLPanelNoData } from './MLPanel';

export const MLForm = ({ controls, data = {}, submit = null, append = {}, checkUpload, medias,
	usecollapse = false, useclose = false, css = '', nocard = false, cardBodyCss,
	onChanged, readonly = false, onlyhasval = false, title = "", disabled = false }) => {

	const [t] = useTranslation(['translation']);
	const [collapse, setCollapse] = useState(false);
	const [closecard, setClosecard] = useState(false);
	const [fileList, setFileList] = useState([]);
	const [formData, setFormData] = useState({});
	const [fromControls, setFormControls] = useState([]);

	useEffect(() => {
		makeData();
	}, [data, controls]);

	useEffect(() => {
		makeControls();
	}, [formData]);

	const valid = () => {
		var ret = 0;
		fromControls.map(x => (x.options.required && !x.options.value) && ret++)
		return ret === 0;
	}

	const makeControls = () => {
		var convertedControls = [];
		controls.forEach(x => {
			if (x.options.hasOwnProperty("name")) {
				if (x.type === INPUTS.TOGGLE) {
					convertedControls.push({
						...x, options: {
							...x.options,
							value: formData[x.options.name] || false
						}
					});
				} else {
					convertedControls.push({
						...x, options: {
							...x.options,
							value: formData[x.options.name]
						}
					});
				}
			} else {
				convertedControls.push({ ...x });
			}
		})
		setFormControls(convertedControls);
	}

	const makeData = () => {
		var obj = {};
		controls.forEach(x => {
			if (x.options.hasOwnProperty("name")) {
				if (data[x.options.name] === false) {
					obj[x.options.name] = false;
				} else {
					if (x.type === INPUTS.TOGGLE) {
						obj[x.options.name] = data[x.options.name] || false;
					} else if (x.type === INPUTS.NUMBER) {
						obj[x.options.name] = data[x.options.name] || 0;
					} else if (x.type === INPUTS.RADIOBUTTON) {
						obj[x.options.name] = data[x.options.name] || x.options.list[0].value;
					} else {
						obj[x.options.name] = data[x.options.name] || (x.options?.defaultValue ?? '');
					}
				}
			}
		})
		if (data._id)
			obj["_id"] = data._id;

		setFormData(obj);
	}

	const handleChanged = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
		onChanged && onChanged({ ...formData, [name]: value });
	}

	useEffect(() => {
		if (checkUpload) {
			checkUploadStart();
		}
	}, [checkUpload])

	useEffect(() => {
		if (fileList.length > 0) {
			setFileList([{ file: data.attachment }])
		}
	}, [fileList])

	const doneProc = (doneFiles) => {
		var dList = {};
		controls.forEach(x => {
			if (x.type === INPUTS.SINGLEFILE || x.type === INPUTS.SINGLEFILEFORORDER || x.type === INPUTS.HEADPHOTO8) {
				dList[x.options.name] = doneFiles.find(f => f.controlname === x.options.name) || formData[x.options.name];
			}
		})
	}

	const checkUploadStart = (isSubmit, action) => {
		var fList = []
		controls.forEach(x => {
			if (x.type === INPUTS.SINGLEFILE || x.type === INPUTS.SINGLEFILEFORORDER || x.type === INPUTS.HEADPHOTO8) {
				var file = formData[x.options.name];
				if (file && !file?.path?.fileId && typeof file !== 'string') {
					const fileUploaded = file;
					fileUploaded.controlname = x.options.name;
					fList.push(fileUploaded);
				}
			}
		})

		if (fList.length > 0) {
			const uploadModalData = {
				isSubmit: isSubmit,
				action: action,
				title: t('upload'),
				fileList: fList || [],
				description: t('waitasec'),
				fileinfo: {}
			}
			action && action({ ...formData, ...append });
			//uploadModalShow(uploadModalData, doneProc);
		} else {
			if (isSubmit) {
				submit && submit({ ...formData, ...append });
			} else {
				action && action({ ...formData, ...append });
			}
		}
	}

	const callbackSubmit = (isSubmit, action) => {
		if (formData.hasOwnProperty("password") && formData.hasOwnProperty("repassword")) {
			if (formData.password !== formData.repassword) {
				var nf = [...fromControls];
				nf.forEach(x => {
					if (x.options.name === "repassword") {
						x.options.err = t('doestnotmatchpassword');
					}
				});
				setFormControls(nf);
				return;
			}
		}
		checkUploadStart(isSubmit, action);
	}

	const showItem = (x, type) => {
		if (readonly && x.type === INPUTS.HIDDEN) {
			return false;
		} else if (onlyhasval) {
			return x.type === type && x.options.value;
		}
		return x.type === type;
	}

	return <>
		{!closecard && <div className={nocard ? (css ? css : '') : (css ? 'card mb-1 ' + css : 'card mb-1')}>
			{title && <div className={(!collapse ? 'card-header separator' : 'card-header') + (usecollapse ? " raycur" : "")} onClick={() => { usecollapse && setCollapse(!collapse) }}>
				<Card.Title className='card-title bold'>
					<span className='card-label fw-bolder text-dark'>{title}</span>
				</Card.Title>
				<div className="card-toolbar">
					{usecollapse && <a data-toggle="collapse" className="card-collapse" onClick={() => setCollapse(!collapse)}>
						{!collapse && <BsArrowDown size={18} />}
						{collapse && <BsArrowUp size={18} />}
					</a>}
					{useclose && <a data-toggle="close" className="card-close" onClick={() => setClosecard(!closecard)}>
						<BsX size={18} />
					</a>}
				</div>
			</div>}
			{!collapse && <>
				{fromControls.length > 0 && <div className={nocard ? '' : ('card-body ' + (cardBodyCss ? cardBodyCss : ''))}>
					<div role="form" aria-label="Form of order">
						{fromControls.map((x, idx) => <React.Fragment key={idx}>
							{showItem(x, INPUTS.HIDDEN) && <MLHidden options={x.options} obj={x} handleChanged={handleChanged} readonly={readonly} />}
							{showItem(x, INPUTS.TEXT) && <MLText options={x.options} obj={x} handleChanged={handleChanged} enterSubmit={() => submit && submit({ ...formData, ...append })} readonly={readonly} />}
							{showItem(x, INPUTS.TOGGLE) && <MLToggle options={x.options} obj={x} handleChanged={handleChanged} readonly={readonly} />}
							{showItem(x, INPUTS.HTMLEDITOR) && <MLHTMLEditor options={x.options} obj={x} handleChanged={handleChanged} readonly={readonly} />}
							{showItem(x, INPUTS.NUMBER) && <MLNumber options={x.options} obj={x} handleChanged={handleChanged} readonly={readonly} />}
							{showItem(x, INPUTS.DATE) && <MLDate options={x.options} obj={x} handleChanged={handleChanged} readonly={readonly} />}
							{showItem(x, INPUTS.RADIOBUTTON) && <MLRadioButton options={x.options} obj={x} handleChanged={handleChanged} readonly={readonly} />}
							{showItem(x, INPUTS.COMBO) && <MLComboSingle options={x.options} obj={x} handleChanged={handleChanged} readonly={readonly} />}
							{showItem(x, INPUTS.COMBOBYCODE) && <MLComboSingleByCode options={x.options} obj={x} handleChanged={handleChanged} readonly={readonly} />}
							{showItem(x, INPUTS.TEXTAREA) && <MLTextArea options={x.options} obj={x} handleChanged={handleChanged} readonly={readonly} />}
							{showItem(x, INPUTS.SINGLEFILE) && <MLFileSingle options={x.options} obj={x} handleChanged={handleChanged} readonly={readonly} />}
							{showItem(x, INPUTS.EMAIL) && <MLEmail options={x.options} obj={x} handleChanged={handleChanged} readonly={readonly} />}
							{showItem(x, INPUTS.PASSWORD) && <MLPassword options={x.options} obj={x} handleChanged={handleChanged} enterSubmit={() => submit && submit({ ...formData, ...append })} readonly={readonly} />}
							{showItem(x, INPUTS.COMBOMULTI) && <MLComboMulti options={x.options} obj={x} handleChanged={handleChanged} readonly={readonly} />}
							{showItem(x, INPUTS.ADDITEMS) && <MLAddItems options={x.options} obj={x} handleChanged={handleChanged} readonly={readonly} />}
							{showItem(x, INPUTS.COMPONENT) && x.component} {/* 상용으로 사용할 수 없는 form item의 경우에 사용, formData 동기화는 컴포넌트 내에서 해결. */}

							{!readonly && x.type === BUTTONS.NORMALBUTTON && <MLButton options={x.options} obj={x} valid={valid()} formaction={x.options.needValid ? () => callbackSubmit(false, x.options.action) : null} />}
							{!readonly && x.type === BUTTONS.FORMSUBMIT && <MLSubmit options={x.options} obj={x} valid={valid()} disabled={disabled} callback={() => callbackSubmit(true)} />}
							{!readonly && x.type === SPAN.VERTICAL && <MLSpanV options={x.options} obj={x} />}
							{!readonly && x.type === SPAN.HR && <MLHR options={x.options} obj={x} />}
							{!readonly && x.type === BUTTONS.BUTTONS && <div className={'btns text-end' + (x.options.align ? x.options.align : "")}>
								{x.buttons.map((y, cdx) => <React.Fragment key={cdx}>
									{y.type === BUTTONS.NORMALBUTTON && <MLButton css='ms-1' options={y.options} obj={x} valid={valid()} formaction={y.options.needValid ? () => callbackSubmit(false, y.options.action) : null} />}
									{y.type === BUTTONS.FORMSUBMIT && <MLSubmit options={y.options} obj={x} valid={valid()} callback={() => callbackSubmit(true)} />}
								</React.Fragment>)}
							</div>}
						</React.Fragment>)}
					</div>
				</div>}
				{fromControls.length === 0 && <MLPanelNoData title={t('norecord')} noborder={true} />}
			</>}
		</div>}
	</>
}