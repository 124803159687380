import { Modal } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { BUTTONS, COLORS, INPUTS, SPAN } from '../../constants/FromInputs';
import { MLButton } from '../common/MLButton';
import { MLForm } from '../common/MLForm';
import { RMSUBTYPES } from '../../constants/RMConstants';
import { AuthConstant, getAPI, getHeader } from '../../redux/reducers/AuthReducer';
import moment from 'moment';
import axios from 'axios';
import { ProductAction } from '../../redux/actions';

const RMModalAddProductMain = ({ modalData, callbackClose }) => {

	const dispatch = useDispatch();
    const [data, setData] = useState({ productname : "" });

	useEffect(() => {
		if(modalData.show){
			setData({ productname : "" });
		}
	}, [modalData.show]);

	const addProductMain = async () => {
		try{
            dispatch(ProductAction.CreateProductMain(data));
		}catch(err){
			console.log(err);
		}
		callbackClose && callbackClose();
	}

    const controls = [
		{ 
			type : INPUTS.TEXT, 
			options : { 
				label : '이름',
				name : 'productname',
				required : true
			},
		},
		{ 
			type : INPUTS.TEXT, 
			options : { 
				label : '종류',
				name : 'type',
				required : true
			},
		},
		{
			type : SPAN.VERTICAL,
			options : { size : 10 }
		},
		{
			type : BUTTONS.BUTTONS, 
			options : { },
			buttons : [{
				type : BUTTONS.NORMALBUTTON, 
				options : { 
					label : 'Add', 
					color : COLORS.PRIMARY,
                    action : () => addProductMain()
				}
			},{
				type : BUTTONS.NORMALBUTTON, 
				options : { 
					label : 'Cancel', 
					color : COLORS.SECONDARY,
                    action : () => callbackClose()
				}
			}]
		}
	];

	return <div className=''>
		<Modal show={modalData.show} size={"lg"} style={{ backgroundColor: "#42476c !important"}}>
			<Modal.Header className='modal-header header-bg'>
				<h2>Product Main 추가</h2>
			</Modal.Header>
			<Modal.Body className='flex-fill pt-2 formcard'>
				<MLForm controls={controls} data={data} nocard={true} onChanged={(e) => { console.log(e);setData(e);}} />
			</Modal.Body>
		</Modal>
	</div>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const region =  state.AuthReducer.region;
	return { region, user };
};

const mapDispatch = dispatch => ({
})

export default connect(mapState, mapDispatch)(RMModalAddProductMain);