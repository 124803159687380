import { useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import { MLPanelLoading } from '../common/MLPanel';
import { Bar, Line, getElementAtEvent } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { getRandomColors, getRTColors } from '../../libs/colors';
import { slice, uniq, uniqBy } from 'lodash';
import { history } from '../../history';
import { UsageConstant } from '../../redux/reducers';
import { ChartDays, ChartMonths } from '../common/ChartDays';
import { SEARCHPERIOD } from '../../constants/RMConstants';
import { COUNTRIES } from '../../constants/Country';
import { MLComboSingle } from '../common/MLInput';
import { TbChartLine } from 'react-icons/tb';
import { MLToggle } from '../common/MLInput';
import { MLFileSize } from '../common/MLCommon';

const RMWidgetBarChart2 = ({ data, height = 300, groups, labeltype = '' }) => {
	const dispatch = useDispatch();
	const [ chartData, setChartData ] = useState([]);
	const [ loading, setLoading ] = useState(true);
	const [ stacked, setStacked ] = useState(false);
	const chartRef = useRef();

    useEffect(() => {
		generate(data);
    }, [data, groups]);

	const getCList = () => {
		let clist = [];
		ChartDays(data.xOptions.duration, data.xOptions.xperiod, data.xOptions.dformat).map((x, idx) => {
			clist.push( {name: x, count: 0});
		});
		return clist;
	}

	const match = (x, g) => {
		if(groups === 'countryCode'){
			return x.countryCode === g;
		}else if(Array.isArray(x[groups])){
			return x[groups].join(',') === g;
		}else{
			return x[groups] === g;
		}
	}

	const generate = async (data) => {
		// console.log("generate: =======", data);
		let clist = [];
		const barDataset = [];
		ChartDays(data.xOptions.duration, data.xOptions.xperiod, data.xOptions.dformat).map((x, idx) => {
			clist.push( {name: x, count: 0});
		});

		if(groups){
			const labels = [];
			let tmplabels = [...new Set(data.list.map(x => Array.isArray(x[groups]) ? x[groups].join(',') : x[groups] ))];
			console.log(tmplabels);
			console.log(groups);
			let idx = 0;
			for(const g of tmplabels){
				const rtcolor = getRTColors(idx);
				const nclist = getCList();
				console.log(nclist);
				const cname = groups === 'countryCode' ? (COUNTRIES.find(x => x.countryCode === g)?.name || '???') : g;
				labels.push(cname)
				data.list.filter(x => match(x, g) ).map(x => {
					var foundIndex = nclist.findIndex(y => y.name === moment(x[data.keyname]).format(data.xOptions.dformat));
					if(nclist[foundIndex]){
						if(data.cal){
							nclist[foundIndex].count+=x[data.cal];
						}else{
							nclist[foundIndex].count++;
						}
					}
				});
				barDataset.push({
					label : cname,
					data: [...(nclist.sort((a, b) => a.name > b.name ? 1 : -1).map((n) => n.count))],
					borderRadius: 10,
					backgroundColor : rtcolor,
					stack : '1'
				})
				idx++;
			}
			console.log(labels);
			console.log(barDataset);
	
			setChartData({
				labels: clist.sort((a, b) => a.name > b.name ? 1 : -1).map((x) => x.name === "" ? type + " 없어요" : x.name ),
				datasets: barDataset
			});
		}else{
			data.list.map(x => {
				var foundIndex = clist.findIndex(y => y.name === moment(x[data.keyname]).format(data.xOptions.dformat));
				if(clist[foundIndex]){
					if(data.cal){
						clist[foundIndex].count+=x[data.cal];
					}else{
						clist[foundIndex].count++;
					}
				}
			});
			barDataset.push({
				data: clist.sort((a, b) => a.name > b.name ? 1 : -1).map((x) => labelsz(x.count)),
				borderRadius: 10,
				backgroundColor : getRTColors(0),
			})
			setChartData({
				labels: clist.sort((a, b) => a.name > b.name ? 1 : -1).map((x) => x.name === "" ? type + " 없어요" : x.name ),
				datasets: barDataset
			});
		}

		setLoading(false);
	}

	const labelsz = (m) => {
		if(labeltype === "size"){
			return Math.round((m / 1024 / 1024 / 1024) * 100) / 100;
		}
		return m
	}

	const totalizer = {
		id: 'totalizer',
		beforeUpdate: chart => {
		  	let totals = {}
		  	let utmost = 0
		  	chart.data.datasets.forEach((dataset, datasetIndex) => {
				if (chart.isDatasetVisible(datasetIndex)) {
			  		utmost = datasetIndex
			  		dataset.data.forEach((value, index) => {
						totals[index] = (totals[index] || 0) + value
			  		})
				}
		  	})
		  	chart.$totalizer = {
				totals: totals,
				utmost: utmost
		  	}
		}
	};

  	return <div>
		{!loading && <div className='pt-3'>
			<Bar 
				ref={chartRef}
				height={height}
				data={chartData}
				plugins={[ChartDataLabels, totalizer]}
				options={{
					indexAxis: 'x',
					responsive: true,
					maintainAspectRatio: false,
					cutout: 0,
					animation: { animateScale: true },
					plugins: { 
						legend: { display : false, position: "right" }, 
						datalabels: {
							formatter: (value, ctx) => {
								const total = ctx.chart.$totalizer.totals[ctx.dataIndex];
								return total;
							},
							display: true,
							color: "gray",
							anchor: 'end',
							align: 'end',
							font: {
							  	weight: 'bold',
							  	size: 13,
							},
							display: function(ctx) {
								return ctx.datasetIndex === ctx.chart.$totalizer.utmost
							}
						},
					},
					scales: {
						y: { ticks: { beginAtZero: true, min : 10, stepSize : 10 }, grid: { display: false } },
						x: { ticks: { color: '#666060', beginAtZero: true, callback: function(value, index, ticks) {
							return this.getLabelForValue(value).substring(5,10).replace("-", "/");
						} }, grid: { display: false } },
					},
				}}/>
		</div>}
		{loading && <div className='mt-5'><MLPanelLoading noborder={true} /></div>}
	</div>
}

const mapState = (state) => {
    const logs = state.UsageReducer.logs;
	const dailylogs =  state.UsageReducer.dailylogs;
	const selectedlogs = state.UsageReducer.selectedlogs;
	const stats = state.UsageReducer.stats;
	
	return { logs, dailylogs, stats, selectedlogs };
};

const mapDispatch = (dispatch) => ({
});

export default connect(mapState)(RMWidgetBarChart2);
