import { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';
import { COUNTRIES } from '../../constants/Country';

const MLCountryCombo = ({ options = {}, handleChanged, disabled = false, css = "" }) => {
	const [val, setVal] = useState("");
	const [optionList] = useState(COUNTRIES.map(x => ({ value: x.countryCode, label: x.name, ...x })));
	const [components, setComponents] = useState({});

	useEffect(() => {
		if (options?.readonly) {
			setComponents(prev => ({ ...prev, DropdownIndicator: () => null, IndicatorSeparator: () => null }))
		} else {
			setComponents(prev => {
				const _prev = { ...prev };
				delete _prev.DropdownIndicator;
				delete _prev.IndicatorSeparator;
				return _prev;
			})
		}
	}, [options?.readonly])

	useEffect(() => {
		setVal(optionList.find(x => x.countryCode === options.value) || '')
	}, [options, optionList])

	const onChanged = useCallback((e) => {
		setVal(optionList.find(x => x.countryCode === e.value) || '')
		handleChanged && handleChanged({ target : { name : options.name, value : e.value, valuelabel : optionList.find(x => x.countryCode === e.value).label || '' }});
	}, [optionList, handleChanged, options?.name]);

	return <div className={(css ? css : '') + (options.err ? " has-error" : "")}>
		{options.label && <label className={options.required ? 'form-label required' : 'form-label'}>{options.label}<span/></label>}
		<Select
			size={"sm"}
			name={options.name} 
			value={val || ''} 
			options={optionList}
			isDisabled={disabled}
			isSearchable={!options.readonly}
			menuIsOpen={options.readonly ? false : undefined}
			styles={options?.customStyles}
			placeholder={options?.placeholder ?? 'Select...'}
			classNamePrefix='ml-combo'
			className='ml-combo'
			onChange={onChanged}
			components={components}
		/>
		{options.err && <label className="error" for={options.name}>{options.errmsg}</label>} 
		{options.hint && <label className="help" for={options.name}>{options.hint}</label>} 
	</div>
}

export default MLCountryCombo;
