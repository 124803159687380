import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { useEffect, useState } from 'react';
import { Doughnut, Pie, Bar } from 'react-chartjs-2';
ChartJS.register(ArcElement, Tooltip, Legend);

const MLBarChart = ({ data, legendLocation = 'top', chartTitle = '', isVertical = false }) => {
    const [chartData, setChartData] = useState(null);
    const [options, setOptions] = useState({});

    useEffect(() => {
        setOptions({ 
            indexAxis: isVertical ? 'x' : 'y',
            responsive: true,
            maintainAspectRatio: false,
            cutout: 0,
            animation: {
                animateScale: true
            },
            plugins: {
                legend: {
                    display : false
                },
                title: {
                    display: !!chartTitle,
                    text: chartTitle
                }
            },
            scales: {
                y: { ticks: { color: '#666060', beginAtZero: true } },
                x: { ticks: { color: '#666060', beginAtZero: true } },
            }
        })
        setChartData(data);
    }, [data, legendLocation, chartTitle]);


    return <>
        {chartData && <Bar 
            data={chartData} 
            options={options} />}
    </>
}

export default MLBarChart;
