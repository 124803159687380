export const JWT = (type) => {
    return { 
        headers : { 
            token : localStorage.getItem("token") 
        }
    };
};

export const AppConfig = (scope) => {
    const env = location.href.indexOf("rayteams.com") > -1 ? "prod" : "dev";
    //const env = "prod";
    let ret = {};
    if(process.env.REACT_APP_COMPANY !== 'ray'){
        ret['lang_tran'] = `https://api-teams-info.rayteams-${process.env.REACT_APP_COMPANY}.com/translations`;
        ret['manager_user'] = `https://api-manager-user.rayteams-${process.env.REACT_APP_COMPANY}.com`;
        ret['info'] = `https://api-teams-info.rayteams-${process.env.REACT_APP_COMPANY}.com`;
        ret['domain'] = `rayteams-${process.env.REACT_APP_COMPANY}.com`;
    }else{
        ret['lang_tran'] = `https://api-teams-info.${env === 'prod' ? "rayteams" : "raydevelop"}.com/translations`;
        ret['manager_user'] = `https://api-manager-user.${env === 'prod' ? "rayteams" : "raydevelop"}.com`;
        ret['info'] = `https://api-teams-info.${env === 'prod' ? "rayteams" : "raydevelop"}.com`;
        ret['domain'] = `${env === 'prod' ? "rayteams" : "raydevelop"}.com`;
    }
    return ret;
};
