import axios from 'axios';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { BiHelpCircle } from 'react-icons/bi';
import { idData, fetchData, putData, updateData } from '../../libs/db';
import MLTable from '../../components/common/MLTable';
import { MLComboMulti, MLComboSingle, MLNumber, MLText, MLTextArea, MLToggle } from '../../components/common/MLInput';
import { history } from '../../history';
import { MLButton } from '../../components/common/MLButton';
import { COLORS } from '../../constants/FromInputs';
import { ProgramConstant } from '../../redux/reducers';
import { COUNTRIES } from '../../constants/Country';
import RTProductAppInfo from '../../components/rayteamsproduct/RTProductAppInfo';
import { PRICEUNIT, SUBPERIOD } from '../../constants/RMConstants';
import { PayPalGetCatalogProduct, PayPalCreateSubscription, PayPalCreateCatalogProduct, StripeSearchProduct, StripeCreateProduct, StripeCreatePrice } from '../../libs/pgSubscription';
import { RMIconCheckCircle, RMIconCircle } from '../../components/icon/MLIcon';
import { getAdminCreateHeader } from '../../redux/reducers/AuthReducer';
import { MLPanelLoading } from '../../components/common/MLPanel';
import { useTranslation } from 'react-i18next';
import { RTAudit } from '../../libs/audit';
const env = (location.href.indexOf("localhost") > -1 || location.href.indexOf(".raydevelop.com") > -1) ? "development" : "production";

const RTProductInfoPage = ({ allpartner, page }) => {
	const dispatch = useDispatch();
	const [t] = useTranslation(['translation']);
	const { _id, tab = "branch" } = useParams("");
	const [ help, setHelp ] = useState(window.localStorage.getItem("helpmsg") === "N" ? false : true);
	const [ partnerYN, setPartnerYN ] = useState(false);
    const [ allgroups, setAllGroups ] = useState([]);
    const [ productinfo, setProductInfo ] = useState({});
    const [ allproducts, setAllProducts ] = useState([]);
	const [ allbranch, setAllbranch ] = useState([]);
    const [ addP, setAddP ] = useState({});
    const [ modalData, setModalData] = useState({
        show : false,
		title : "Add Price",
		edit : false
    });
	const [ publishData, setPublishData] = useState({
		show : false,
		title : "Publish",
	})

    const [ loading, setLoading ] = useState(false);
	const mgroup = JSON.parse(localStorage.getItem("mgroup"));
	const [selectedTax, setSelectedTax] = useState({});
	const [selectedTaxes, setSelectedTaxes] = useState([]);
	const [lError, setlError] = useState(false);

	useEffect(() => {
		dispatch({ type : ProgramConstant.SET_PAGE, page : { 
			code : "product", 
			view : "summary", 
			title : "Product", 
			small : t("소프트웨어 제품 관련 정보"), 
			options : [
				{ view : "summary" }, 
				{ view : "list" } 
			]
		}})
	}, []);

	useEffect(() => {
		if(_id) {}
			init();
	},[_id]);
	
	useEffect(() => {
        window.localStorage.setItem("helpmsg", help ? "Y" : "N");
	},[help])

	const init = async () => {
		setLoading(true);
		const ret = await idData('rayteams-product', _id);
		if(ret.Items.length > 0){
			const productInfos = ret.Items.find(f => f.sk === "info");
            const productPrice = ret.Items.find(f => f.sk === "price:ray");
			setProductInfo({...productInfos, 
                originalprice: productPrice?.originalprice || productPrice?.realprice, 
                realprice: productPrice?.realprice});
			
			let productPriceBranch = [];

			if(mgroup?.countries?.length > 0) {
				productPriceBranch = ret.Items.filter(f => f.sk.includes("price:") && f.sk != "price:ray");
				productPriceBranch = productPriceBranch.filter(f => mgroup.countries.map(x => x.countryCode).find(y => y === f.sk.split(":")[2]));
			} else {
				productPriceBranch = ret.Items.filter(f => f.sk.includes("price:") && f.sk != "price:ray");
			}

            setAllProducts(productPriceBranch.map(x => ({...x,
                partner: x.sk.split(":")[1],
                countryCode: x.sk.split(":")[x.sk.split(":").length - 1],
                groupId: x.sk.split(":")[3],
				paypal: x?.pg?.find(f => f.name === "paypal")?.isactive || false,
				//stripe: x?.pg?.find(f => f.name === "stripe")?.isactive || false,
				toss: x?.pg?.find(f => f.name === "toss")?.isactive || false
            })))

            getGroupData();
            getPartnerData();
			getBranchData();
		}else{
			
		}
		setLoading(false);
	}

    const getGroupData = async () => {
        try {    
            const ScanFilter = {
                sk: {
                    ComparisonOperator: "EQ",
                    AttributeValueList: ["info"],
                }
            }
            const retGroups = await fetchData("rayteams-group", ScanFilter);
            setAllGroups(retGroups.Items);
        } catch(err) {
            setAllGroups([]);
        }
    }

    const getPartnerData = async () => {
		const ScanFilter = {
			sk : {
				ComparisonOperator: "EQ",
				AttributeValueList: ["info"],
			},
		}

		const retPartner = await fetchData("rayteams-partner", ScanFilter);
		dispatch({ type: ProgramConstant.GETPARTNER, items: retPartner.Items});
	}

	const getBranchData = async () => {
		try {
			// const ScanFilter = {
			// 	sk : {
			// 		ComparisonOperator: "EQ",
			// 		AttributeValueList: ["info"],
			// 	},
			// }
			// const retBranch = await fetchData("rayteams-manager-group", ScanFilter);
			const ScanFilter = {
				otype: {
					ComparisonOperator: "EQ",
					AttributeValueList: ["Branch"],
				},
				prntId: {
					ComparisonOperator: "EQ",
					AttributeValueList: [""]
				},
			}
			const retBranch = await fetchData("rayteams-partner", ScanFilter);
			setAllbranch(retBranch.Items);
		} catch (err) {
			setAllbranch([]);
			console.log(err);
		}
	}
	// price:partner/ray:country/hq:groupid:userid
    const createPrice = async () => {
        setLoading(true);
		if (addP.countries) {
			for (let index = 0; index < addP.countries.length; index++) {
				const element = addP.countries[index];
				try {
					let item = {
						_id : _id,
						sk : `price:${addP.partner ? addP.partner : (addP.branchId ? addP.branchId.replace("scp:", "") : "ray")}${addP.countries[index] ? (":" + addP.countries[index]) : ""}${addP.groupId ? (":" + addP.groupId) : ""}`,
						originalprice: parseInt(addP.originalprice || 0),
						realprice: parseInt(addP.realprice || 0),
						punit: addP?.punit || "USD",
						created : new Date().getTime(),
					}
					if (addP?.useTrial) {
						item = {
							...item, 
							useTrial: addP.useTrial, 
							trialperiod: addP.trialperiod, 
							trialperiodtype: addP.trialperiodtype,
							trialunit: addP?.trialunit || "USD",
							trialprice: addP?.trialprice || 0,
						};
					}
					if (selectedTaxes?.length > 0) {
						item = {
							...item,
							taxes: selectedTaxes,
						};
					}

					const ret = await putData("rayteams-product", item);
					setAllProducts([...allproducts, {...item,
						partner: item.sk.split(":")[1],
						countryCode: item.sk.split(":")[2],
						groupId: item.sk.split(":")[3],
					}])
				} catch (e) {
					console.log(e);
				}
			}
		} else  {
			try {

				let pSk = `price:${addP.partner ? addP.partner : (addP.branchId ? addP.branchId : "ray")}`;
				if (addP?.useGlobal) {
					pSk = "price:global";
				} else if (addP?.raydent) {
					pSk = "price:raydent:KR";
				}
				let item = {
					_id : _id,
					sk : pSk,
					originalprice: parseInt(addP.originalprice || 0),
					realprice: parseInt(addP.realprice || 0),
					punit: addP?.punit || "USD",
					created : new Date().getTime()
				}

				if (addP?.useTrial) {
					item = {
						...item, 
						useTrial: addP.useTrial, 
						trialperiod: addP.trialperiod, 
						trialperiodtype: addP.trialperiodtype,
						trialunit: addP?.trialunit || "USD",
						trialprice: addP?.trialprice || 0,
					};
				}
				if (selectedTaxes?.length > 0) {
					item = {
						...item,
						taxes: selectedTaxes,
					};
				}

				console.log("parmas ===========", JSON.stringify(item, null, 2));

				const ret = await putData("rayteams-product", item);
				setAllProducts([...allproducts, {...item,
					partner: item.sk.split(":")[1],
					countryCode: item.sk.split(":")[2],
					groupId: item.sk.split(":")[3],
				}])
			} catch (e) {
				console.log(e);
			}
		}
		setLoading(false);
		init();
		setAddP({});
		setModalData({ show: false });
		RTAudit({ lvl: 4, msg: 'Create Price'}, JSON.stringify({ subject: addP }));
    }

    const showAdd = () => {
		setSelectedTaxes([]);
        setModalData({show: true})
    }

	const editPrice = async () => {
        const updateKeyParams = {
            _id : _id,
            sk : addP.sk
        }

		let updateItems = {
			punit: addP?.punit || "USD", 
			originalprice: addP.originalprice, 
			realprice: addP.realprice
		}

		if (selectedTaxes?.length > 0) {
			updateItems = {
				...updateItems,
				taxes: selectedTaxes,
			};
		}

        try {
            const ret = await updateData("rayteams-product", updateKeyParams, updateItems);
			init();
			setModalData({ show: false, edit: false });
        } catch(err) {
            console.log(err);
		}
		RTAudit({ lvl: 4, msg: 'Edit Price'}, JSON.stringify({ subject: data }));
	}
    
	const modifyClick = async (item) => {
		console.log("allproducts : ============= ", allproducts.find(f => f.sk === item.sk)?.taxes);
		console.log("item.sk : =========", item.sk);
		setAddP({...addP, sk: item.sk, punit: item.punit, originalprice: item.originalprice, realprice: item.realprice});
		setSelectedTaxes(allproducts.find(f => f.sk === item.sk)?.taxes || []);
		setModalData({ show: true, edit: true });
		RTAudit({ lvl: 4, msg: 'Modify Price'}, JSON.stringify({ subject: data }));
	}

	const pricePublish = async (item) => {
		const pItem = allproducts.find(f => f.sk === item.sk);
		console.log("pricePublish : ==============", pItem);
		setAddP({
			...addP, 
			sk: pItem.sk, 
			punit: pItem.punit, 
			originalprice: pItem.originalprice, 
			realprice: pItem.realprice, 
			pg: pItem?.pg, 
			trial: pItem?.useTrial || false,
			trialperiod: pItem?.trialperiod || 0,
			trialperiodtype: pItem?.trialperiodtype || "d",
			trialunit: pItem?.trialunit || "USD",
			trialprice: pItem?.trialprice || 0,
		});
		setPublishData({show: true});
		setlError(false);
		RTAudit({ lvl: 4, msg: 'Publish Price'}, JSON.stringify({ subject: addP }));
	}

	const sendTossPublish = async () => {
		setLoading(true);
		await updateProductPG("toss");
		setLoading(false);
		RTAudit({ lvl: 4, msg: 'Send Toss Publish'}, JSON.stringify({ subject: "Send Toss Publish" }));
	}

	const sendPayPalPublish = async () => {
		setLoading(true);
		const pParams = {
			data: {
				_id: _id,
				sk: addP.sk,
				cc: addP.sk.split(":")[addP.sk.split(":").length -1],
				subperiod: productinfo.subperiod,
				realprice: addP.realprice,
				originalprice: addP?.originalprice,
				punit: addP.punit,
				title: productinfo.title,
			  }
		}

		if (addP.trial) {
			pParams.data = { 
				...pParams.data, 
				trial: true, 
				trialperiod: addP.trialperiod, 
				trialperiodtype: addP.trialperiodtype,
				trialunit: addP.trialunit,
				trialprice: addP.trialprice,
			}
		}

		try {
			const ret = await axios.post("https://" + 
                (env === "production" ? "api-manager-license.rayteams.com" : "api-manager-license.raydevelop.com") + 
                "/payment/paypal/createplan", pParams, getAdminCreateHeader());
			
			let priceinfo = [];
			const pgInfo = ret?.data?.data?.pg;
			pgInfo && pgInfo.map(x => {
				priceinfo.push({name: x.name, withTrialPlanID: x?.withTrialPlanID, withoutTrialPlanID: x?.withoutTrialPlanID, isactive: x.isactive, created: x.created})
			})
			
			setAddP({...addP, pg: priceinfo});
			init();
		} catch (e) {
			setlError(true);
			console.log("createPlan Error : ", e);
		}
		setLoading(false);
		RTAudit({ lvl: 4, msg: 'Send PayPal Publish'}, JSON.stringify({ subject: pParams }));
	}

	const sendPayPalPublish_old = async () => {
		setLoading(true);
		const pParams = {
			_id: _id,
			title: productinfo.title
		}

		const gRet = await PayPalGetCatalogProduct();
		if(!(!!gRet.Items.find(f => f.id === _id))) {
			const ret = await PayPalCreateCatalogProduct(pParams);
			console.log("PayPalCreateCatalogProduct", ret);
		}

		let sParams = {
			_id: _id,
			interval_unit : SUBPERIOD.find(f => f.value === productinfo.subperiod.replace(/[0-9]/g,"")).elabel,
			interval_count : productinfo.subperiod?.replace(/[a-z]/gi,"") || 1,
			realprice : addP.realprice,
			originalprice: addP?.originalprice,
			punit: addP.punit,
			title: productinfo.title
		}

		const pRRet = await PayPalCreateSubscription(sParams);

		if (addP.trial) {
			sParams = { 
				...sParams, 
				trial: true, 
				trialperiod: addP.trialperiod, 
				trialperiodtype: addP.trialperiodtype,
				trialunit: addP.trialunit,
				trialprice: addP.trialprice,
			}
		}

		const pTRet = await PayPalCreateSubscription(sParams);

		await updateProductPG("paypal", pRRet.data.id, pTRet.data.id);
		setLoading(false);
	}

	const sendStripePublish = async () => {
		try {
			const pParams = {
				_id : _id,
				title : productinfo.title
			}
			const gRet = await StripeSearchProduct(_id);
			if(!(!!gRet.data.find(f => f.id === _id))) {
				const ret = await StripeCreateProduct(pParams);
			}
			
			const prParams = {
				realprice: addP?.realprice,
				punit: addP?.punit,
				interval_unit: SUBPERIOD.find(f => f.value === productinfo.subperiod.replace(/[1-9]/g,"")).elabel
			}
			const retp = await StripeCreatePrice(_id, prParams);
			await updateProductPG("stripe", retp.id);
		} catch (err) {
			console.log(err);
		}
		RTAudit({ lvl: 4, msg: 'Send Stripe Publish'}, JSON.stringify({ subject: data }));
	}

	const updateProductPG = async (pgName, withoutTrialPlanID, withTrialPlanID) => {
		const updateKeyParams = {
            _id : _id,
            sk : addP.sk
        }
		console.log("updateKeyParams ============", updateKeyParams);
		let priceinfo = [];
		if(pgName == "paypal") {
			addP?.pg && addP?.pg.map(x => {
				priceinfo.push({name: x.name, isactive: x.isactive, created: x.created, withoutTrialPlanID: x?.withoutTrialPlanID, withTrialPlanID: x?.withTrialPlanID, })
			})
			
			priceinfo.push({name: pgName, isactive: true, created: new Date().getTime(), withoutTrialPlanID: withoutTrialPlanID, withTrialPlanID: withTrialPlanID })
		} else if (pgName == "toss") {
			addP?.pg && addP?.pg.map(x => {
				priceinfo.push({name: x.name, isactive: x.isactive, created: x.created })
			})
			
			priceinfo.push({name: pgName, isactive: true, created: new Date().getTime() })
		}

		const pgParams = {
			pg : priceinfo
		}

        try {
            const ret = await updateData("rayteams-product", updateKeyParams, pgParams);
			setAddP({...addP, pg: priceinfo});
			init();
        } catch(err) {
            console.log(err);
		}
		RTAudit({ lvl: 4, msg: 'Update Product PG'}, JSON.stringify({ subject: addP }));
	}

    const branchHeaders = [
        { value : "countryCode", label: t("Country"), type: "COUNTRY" },
        { value : "groupId", label: t("Group"), type: "GROUP"},
		{ value : "punit", label: t("Currency")},
        { value : "originalprice", label: t("Original Price"), type: "CUR" },
        { value : "realprice", label: t("Real Price"), type: "CUR" },
        { value : "sells", label: t("Sells")},
		{ value : "paypal", label: t("PayPal"), type: "TFVALUE" },
		//{ value : "stripe", label: "Stripe", type: "TFVALUE" },
		{ value : "toss", label: t("Toss"), type: "TFVALUE" },
		{ value : "useTrial", label: t("Trial"), type: "TFVALUE" },
		{ value : "trialperiod", type: "HIDE" },
		{ value : "trialperiodtype", type: "HIDE" },
        { value : "created", label : t("Created"), type: "DATE" },
		{ label : t("Modify"), btncss : "primary", code : "rtp-pricemodify", type : "BUTTON2", btnClick: async (item) => {
			await modifyClick(item);
		}},
		{ label : t("Publish"), btncss : "success", code : "rtp-pricepublish", type : "BUTTON2", btnClick: async (item) => {
			await pricePublish(item);
		}}
    ]
	
  	return <div className='p-4'>
		<div className="card">
			<div className="card-header d-flex justify-content-between align-items-center">
				<div className="card-label fw-bold text-gray-800">
					<h3>
						{productinfo.appitems && productinfo.appitems.map(x => { return(<RTProductAppInfo aid={x} icon={true}/>) })}
						{productinfo.title}
					</h3>
					<div>
						<span>{t("Registered")} : {moment(productinfo.created).format('LL')}</span>
					</div>
					<div>
						<span>{t("Default Price")} : {PRICEUNIT.find(f => f.value === (productinfo?.punit || "USD"))?.label } {productinfo.defaultprice}</span> /
						<span>{t("Original Price")} : {PRICEUNIT.find(f => f.value === (productinfo?.punit || "USD"))?.label } {productinfo?.originalprice}</span> /
						<span>{t("Realprice Price")} : {PRICEUNIT.find(f => f.value === (productinfo?.punit || "USD"))?.label } {productinfo?.realprice}</span> /
						<span>{t("Sells")} : {productinfo.sells}</span>
					</div>
				</div>
				<div className="d-flex">
					<MLButton options={{
						label : t("상품 가격 등록"),
						size : "md",
						color : COLORS.PRIMARY,
						action: () => showAdd()
					}} css="me-1" />
					<MLButton options={{
						label : t("수정"),
						sm : "md",
						color : COLORS.SECONDARY,
						action : () => history.push("/RTProductAddEdit/" + _id),
					}} css="me-1" />
					<MLButton options={{
						label : t("목록"),
						sm : "md",
						color : COLORS.DARK,
						action : () => history.push("/RTProduct"),
					}} />
				</div>
			</div>
			{tab === "branch" && <MLTable headers={branchHeaders} noheader={true} items={allproducts}/>}
		</div>
        <Modal show={modalData.show} size={"lg"} style={{ backgroundColor: "#42476c !important"}}>
			<Modal.Header className='modal-header header-bg'>
				<h3>{t("Add Price")}</h3>
			</Modal.Header>
			<Modal.Body className='flex-fill pt-0 formcard'>
				<div>
					{!modalData.edit && <>
					{partnerYN && <> <div className='fs-5 my-2'>{t("Select Partner")}</div>
					<MLComboSingle options={{
						value : addP.partner,
						name : "partner",
						list : allpartner && allpartner.map(x => {
                            return (
                                { value: x._id, label: x.name }
                            )
                        })
					}} handleChanged={(e) => setAddP({...addP, partner : e.target.value})}/>
					</>}
					<div className='mb-4 mt-0'>
						<table className='w-100'>
							<tbody>
								<tr>
									<td>
										<div className='fs-5 my-2'>
											<MLToggle
												options={{
													label: t('Trial'),
													name: 'trial',
													value: addP.useTrial || false,
													readonly: false,
												}} 
												handleChanged={(e) => setAddP({...addP, useTrial: e.target.value })}/>
										</div>
									</td>
									<td>
										<div className='fs-5 my-2'>
											<MLToggle
												options={{
													label: t('Global'),
													name: 'global',
													value: addP.useGlobal || false,
													readonly: addP.raydent || false,
												}} 
												handleChanged={(e) => setAddP({...addP, useGlobal: e.target.value })}/>
										</div>
									</td>
									<td>
										<div className='fs-5 my-2'>
											<MLToggle
												options={{
													label: t('RAY'),
													name: 'raydent',
													value: addP.raydent || false,
													readonly: false,
												}} 
												handleChanged={(e) => setAddP({...addP, raydent: e.target.value })}/>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					{addP?.useTrial &&
						<div className="mb-5 mt-10">
							<label className="form-label fs-6">{t("Trial Period")}</label>
							<div className="d-flex" style={{marginBottom: "0px !important"}}>
							<MLNumber options={{
								value: addP?.trialperiod && addP?.trialperiod.replace(/[a-z]/gi,"") || 0,
							}} handleChanged={(e) => setAddP({...addP, trialperiod : e.target.value})} css="w-25" />
							<MLComboSingle options={{
								value: _id ? (addP?.trialperiodtype ? addP?.trialperiodtype : addP?.trialperiod ? addP?.trialperiod.replace(/[1-9]/g,"") : "") : (addP?.trialperiodtype || ""),
								list : SUBPERIOD.filter((f) => f.value != "y")
							}} handleChanged={(e) => setAddP({...addP, trialperiodtype : e.target.value})} css="w-25 ms-2"  />
							</div>
							<div className='fs-6 my-2 mt-2'>{t("Trial Currency")}</div>
							<MLComboSingle options={{
								value: addP?.trialunit || "USD",
								name: "trialunit",
								list: PRICEUNIT
							}} handleChanged={(e) => setAddP({...addP, trialunit : e.target.value})} />
							<div className='fs-6 my-2 mt-2'>{t("Trial Price")}</div>
							<MLNumber options={{
								value: addP?.trialprice || 0,
								name: "trialprice",
							}} handleChanged={(e) => setAddP({...addP, trialprice : e.target.value })} />
						</div>
					}
						
					{!addP?.useGlobal && <>
					{!addP?.raydent && <>
					<div className='fs-5 my-2 mt-10'>{t("Branch")}</div>
					<MLComboSingle options={{
						value: addP.branchId,
						list: allbranch.map(x => {
							return (
								{ value: x.sk, label: x.nm }
							)
						})
					}} handleChanged={(e) => {setAddP({...addP, branchId : e.target.value, countries : allbranch.find(f => f.sk === e.target.value)?.cc.map(x => x)}); console.log(allbranch.find(f => f.sk === e.target.value)?.cc.map(x => x)); }} />
					</>}
					<div className='fs-6 my-2 mt-2'>{t("Country")}</div>
					<MLComboMulti options={{
						value: addP?.raydent ? "KR" : (addP?.countries || []),
						list : addP?.branchId ? allbranch.find(f => f.sk === addP.branchId)?.cc.map(x => {
							return (
								{ value : x, label: COUNTRIES.find(f => f.countryCode == x).name }
							)
						}) : COUNTRIES.map(x => {
							return (
								{ value: x.countryCode, label: x.name }
							)
						}),
						showalloption: false
					}} handleChanged={(e) => {setAddP({...addP, countries: e.target.value}); console.log(addP.countries);}} css="w-100 me-2"  />
					{addP?.countries && <> <div className='fs-6 my-2 mt-2'>{t("Group")}</div>
                    <MLComboSingle options={{
                        value: addP.groupId,
                        name: "groupid",
                        list: allgroups.filter(f => addP?.countries.find(y => y === f.countryCode)).map(x => {
                            return (
                                { value: x._id, label: x.name }
                            )
                        })
                    }} handleChanged={(e) => setAddP({...addP, groupId : e.target.value })} />
					</>}
					</>}

					</>}
					


					<div className='fs-6 my-2'>{t("Currency")}</div>
					<MLComboSingle options={{
						value: addP.punit || "USD",
						name: "punit",
						list: PRICEUNIT
					}} handleChanged={(e) => setAddP({...addP, punit : e.target.value})} />
                    <div className='fs-6 my-2 mt-2'>{t("Original Price")}</div>
                    <MLNumber options={{
                        value: addP.originalprice,
                        name: "originalprice",
                    }} handleChanged={(e) => setAddP({...addP, originalprice : e.target.value })} />
                    <div className='fs-6 my-2 mt-2'>{t("Real Price")}</div>
                    <MLNumber options={{
                        value: addP.realprice,
                        name: "realprice",
                    }} handleChanged={(e) => setAddP({...addP, realprice : e.target.value })} />
					<div className='fs-6 my-2 mt-2'>{t("Taxes")}</div>
					<div className="card-body">
						<div>
							<div className='d-flex justify-content-between'>
								<div className='d-flex justify-content-between'>
									<MLText options={{
										value: selectedTax?.type || ""
										}} handleChanged={(e) => setSelectedTax({ ...selectedTax, type: e.target.value })}
										css='me-1' />
									<MLNumber options={{
										value: selectedTax?.taxValue || 0,
										name: "taxValue",
									}} handleChanged={(e) => setSelectedTax({...selectedTax, taxValue : e.target.value })} />
								</div>
								<div>
									<a href="#" title="Add" onClick={ () => setSelectedTaxes([...selectedTaxes, {[`${selectedTax.type}`]: selectedTax.taxValue}]) }>
										<span className="svg-icon svg-icon-2hx svg-icon-info">
											<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
												<rect fill="currentColor" rx="5" height="20" width="20" y="2" x="2" opacity="0.3"></rect>
												<rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"></rect>
												<rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"></rect>
											</svg>
										</span>
									</a>
								</div>
							</div>
						</div>
						<div>
							<ul className='list-group mt-2 mb-4 form-group'>
								{selectedTaxes.length > 0 && selectedTaxes.map((x, idx) => <li className="d-flex justify-content-between align-items-center mt-3 form-control" style={{background: "#f2f1f5", borderColor: "#f2f1f5" }} key={idx}>
									{<>
										{x && <> 
											[{Object.keys(x)}] - {x?.[`${Object.keys(x)}`] + " %"}
											<a href="#" title="Remove" onClick={ () => {console.log("deleted tax items : ===", Object.keys(x)[0]); setSelectedTaxes(selectedTaxes.filter(u => Object.keys(u)[0] !== Object.keys(x)[0]))}}>
												<span className="svg-icon svg-icon-muted svg-icon-2hx">
													<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
														<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"></rect>
														<rect x="7" y="15.3137" width="12" height="2" rx="1" transform="rotate(-45 7 15.3137)" fill="currentColor"></rect>
														<rect x="8.41422" y="7" width="12" height="2" rx="1" transform="rotate(45 8.41422 7)" fill="currentColor"></rect>
													</svg>
												</span>
											</a>
										</>}
									</>}
								</li>)}
							</ul>
						</div>
					</div>
				</div>
				<div className='mt-7 text-end'>
					<MLButton
						options={{ label : t("Cancel"), size : "md", color : COLORS.DEFAULT, action : () => setModalData({...modalData, show : false})}}
						css={'me-1'} />
					<MLButton
						valid={addP.originalprice && addP.realprice}
						options={{ label : modalData.edit ? t("Edit Price") : t("Add Price"), size : "md", needValid : true,  color : COLORS.PRIMARY, action : () => modalData.edit ? editPrice() : createPrice()}}
						css={'me-1'} />
				</div>
			</Modal.Body>
		</Modal>

		<Modal show={publishData.show} size={"lg"} style={{ backgroundColor: "#42476c !important"}}>
			<Modal.Header className='modal-header header-bg'>
				<h3>{t("Publish Price")}</h3>
			</Modal.Header>
			<Modal.Body className='flex-fill pt-0 formcard pt-6'>
				<div>
					<h5>{productinfo.title} {t("상품을 PG 사에 등록 합니다.")}</h5>
					<div className="card-body pt-7">
						{loading && <MLPanelLoading contents={t("상품을 PG 사에 등록 하고 있습니다.")} />}
						{!loading && lError && <>
							<div className="d-flex align-items-center w-100 text-center">
								<div className='mx-auto'>
									<div className='mb-2'>
										<h2>{t("Error")}</h2>
										<div className='text-comment'>{t("상품 등록 중 문제가 발생했습니다. 관리자에게 문의 해주시기 바랍니다.")}</div>
									</div>
								</div>
							</div>
						</>
						}
						{!loading && !lError && <>
						{addP?.sk && !addP.sk.includes(":KR") && <>
						<div className='pb-5 pt-2'>
							<div className='d-flex justify-content-between'>
								<span className='fs-5'>{t("PayPal")}</span>
								{addP?.pg && (addP?.pg?.find(f => f.name === "paypal")?.isactive) ? <RMIconCheckCircle/> : <RMIconCircle/>}
								<MLButton
									valid={addP?.pg && (!addP?.pg?.find(f => f.name === "paypal")?.isactive)}
									options={{ label :  t("Publish Price"), size : "md", needValid : true,  color : COLORS.PRIMARY, action : async () => sendPayPalPublish()}}
									css={'me-1'} />
							</div>
						</div>
						<div style={{display:"none"}}>
							<div className='d-flex justify-content-between'>
								<span className='fs-5'>{t("Stripe")}</span>
								{addP?.pg && (addP?.pg?.find(f => f.name === "stripe")?.isactive) ? <RMIconCheckCircle/> : <RMIconCircle/>}
								<MLButton
									valid={addP?.pg && (!addP?.pg?.find(f => f.name === "stripe")?.isactive)}
									options={{ label :  t("Publish Price"), size : "md", needValid : true,  color : COLORS.PRIMARY, action : async () => sendStripePublish()}}
									css={'me-1'} />
							</div>
						</div>
						</>}
						{addP?.sk && addP.sk.includes(":KR") && <>
						<div className='pb-5 pt-2'>
							<div className='d-flex justify-content-between'>
								<span className='fs-5'>{t("Toss")}</span>
								{addP?.pg && (addP?.pg?.find(f => f.name === "toss")?.isactive) ? <RMIconCheckCircle/> : <RMIconCircle/>}
								<MLButton
									valid={addP?.pg && (!addP?.pg?.find(f => f.name === "toss")?.isactive)}
									options={{ label :  t("Publish Price"), size : "md", needValid : true,  color : COLORS.PRIMARY, action : async () => sendTossPublish()}}
									css={'me-1'} />
							</div>
						</div>
						</>}

						</>}
					</div>
				</div>
				<div className='mt-7 text-end'>
					<MLButton
						options={{ label : t("Cancel"), size : "md", color : COLORS.DEFAULT, action : () => setPublishData({...publishData, show: false})}}
						css={'me-1'} />
				</div>
			</Modal.Body>
		</Modal>
	</div>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const allpartner =  state.ProgramReducer.allpartner;
	const page = state.ProgramReducer.page;
	return { user, allpartner, page };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, null)(RTProductInfoPage);
