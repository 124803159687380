import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { BUTTONS, COLORS, INPUTS, SPAN } from '../../constants/FromInputs';
import { MLComboMulti, MLComboSingle, MLNumber, MLToggle } from '../common/MLInput';
import { MLButton } from '../common/MLButton';
import { BUYTYPE, SUBTYPE, SUBPERIOD, PRICEUNIT } from '../../constants/RMConstants';
import RTProductAppInfo from './RTProductAppInfo';
import { idData, fetchData_prod, putData, updateData, fetchData } from '../../libs/db';
import { LicenseConstant, RTProductConstant, ProgramConstant } from '../../redux/reducers';
import { history } from '../../history';
import { COUNTRIES } from '../../constants/Country';
import { MLPanelLoadingOverlay } from '../common/MLPanel';
import { useTranslation } from 'react-i18next';
import { RTAudit } from '../../libs/audit';
const RTProductAdd = ({ applications, RTproduct, RTproducts, allpartner }) => {
	const [t] = useTranslation(['translation']);
	const dispatch = useDispatch();
	const { _id } = useParams();
	const [data, setData] = useState({ title: "", isdraft: true });
	const [priceTempData, setPriceTempData] = useState({});
	const [priceData, setPriceData] = useState([]);
	const { v4 } = require("uuid");
	const [loading, setLoading] = useState(false);
	const [process, setProcess] = useState(false);
	const [feedbacks, setFeedbacks] = useState([]);
	const [emailTemplates, setEmailTemplates] = useState([]);

	useEffect(() => {
		getApplicationData();
		getPartnerData();
		getFeedbacks();
		getEmailTemplate();
	}, [])

	useEffect(() => {
		if (_id) {
			getProductData();
		} else {
			dispatch({ type: RTProductConstant.GET_RTPRODUCT_DETAIL, item: [] });
		}
	}, [_id]);

	const getProductData = async () => {
		setLoading(true);
		if (RTproducts.length > 0) {
			dispatch({ type: RTProductConstant.GET_RTPRODUCT_DETAIL, item: RTproducts.find(x => x._id === _id) });
			setData(RTproducts.find(x => x._id === _id && x.sk === "info"));
			setPriceData(RTproducts.filter(x => x._id === _id && x.sk.includes("price:")));
		} else {
			try {
				const ret = await idData("rayteams-product", _id);
				if (ret.Items.length > 0) {
					dispatch({ type: RTProductConstant.GET_RTPRODUCT_DETAIL, item: ret.Items.find(x => x._id === _id && x.sk === "info"), items: ret.Items });
					setData(ret.Items.find(x => x._id === _id && x.sk === "info"));
					setPriceData(ret.Items.filter(x => x._id === _id && x.sk.includes("price:")));
				}
			} catch (err) {
				console.log(err);
			}
		}
		setLoading(false);
	}

	const getApplicationData = async () => {
		const ScanFilter = {
			type: {
				ComparisonOperator: "EQ",
				AttributeValueList: ["app"],
			},
			rayteams_apps: {
				ComparisonOperator: "EQ",
				AttributeValueList: [true],
			},
			licenseId: {
				ComparisonOperator: "NOT_NULL",
				Exists: true,
			}
		}
		const retApplications = await fetchData('rayrnd-applications', ScanFilter);
		dispatch({ type: LicenseConstant.GET_ALL_APPLICATIONS, items: retApplications.Items });
	}

	const getPartnerData = async () => {
		const ScanFilter = {
			sk: {
				ComparisonOperator: "EQ",
				AttributeValueList: ["info"],
			},
		}

		const retPartner = await fetchData("rayteams-partner", ScanFilter);
		dispatch({ type: ProgramConstant.GETPARTNER, items: retPartner.Items });
	}

	const updateProduct = async () => {
		setLoading(true);
		try {
			const item = {
				title: data?.title,
				appitems: data?.appitems,
				isdraft: data?.isdraft,
				buytype: data?.buytype,
				fbcancel: data?.fbcancel,
				emailTemplates: data?.emailTemplates,
				punit: data?.punit || "USD",
				defaultprice: parseInt(data?.defaultprice || 0),
				created: new Date().getTime()
			}
			if (data?.buytype == "sub") {
				item.subtype = data?.subtype || "";
				item.subperiod = data?.subperiod?.replace(/[a-z]/gi, "") + "" + data.subperiodtype || "d";
			}
			if (data?.defaultprice > 0) {
				item.defaultprice = data?.defaultprice;
			}
			if (data?.exceptCc) {
				item.exceptCc = data?.exceptCc;
			}

			console.log("update product", JSON.stringify(item, null, 2));

			const priceItem = {
				originalprice: parseInt(data?.originalprice || 0),
				realprice: parseInt(data?.originalprice || 0),
				punit: data?.punit || "USD"
			}

			console.log("update product", JSON.stringify(priceItem, null, 2));

			const ret = await updateData("rayteams-product", { _id: _id, sk: "info" }, item);

			const retPrice = await updateData("rayteams-product", { _id: _id, sk: "price:ray" }, priceItem)
		} catch (err) {
			console.log(err);
		}
		setLoading(false);
		RTAudit({ lvl: 4, msg: '[' + data.title + '] Update Product'}, JSON.stringify({ subject: data }));
	}

	const createProduct = async () => {
		setLoading(true);
		try {
			const productId = v4();
			const item = {
				_id: productId,
				sk: "info",
				title: data?.title,
				appitems: data?.appitems,
				isdraft: data?.isdraft,
				buytype: data?.buytype,
				fbcancel: data?.fbcancel,
				emailTemplates: data?.emailTemplates,
				punit: data?.punit || "USD",
				sells: 0,
				created: new Date().getTime()
			}

			if (data?.buytype == "sub") {
				item.subtype = data?.subtype || "";
				item.subperiod = data?.subperiod + "" + data.subperiodtype || "d";
			}
			if (data?.defaultprice > 0) {
				item.defaultprice = data?.defaultprice;
			}

			if (data?.exceptCc) {
				item.exceptCc = data?.exceptCc;
			}

			const ret = await putData("rayteams-product", item);
			const priceItems = {
				_id: productId,
				sk: "price:ray", // default price
				originalprice: data?.originalprice,
				realprice: data?.realprice,
				punit: data?.punit || "USD",
				created: new Date().getTime()
			}

			const retPrice = await putData("rayteams-product", priceItems);
		} catch (e) {
			console.log(e);
		}
		setLoading(false);
		history.push("/RTProduct");
		RTAudit({ lvl: 4, msg: 'Create Product'}, JSON.stringify({ subject: data }));

	};

	const delClick = async (item) => {
		setPriceData(priceData.filter(x => x?.created != item.created))
	};

	const getEnv = () => {
		if (location.href.indexOf("localhost") > -1 || location.href.indexOf("127.0.0.1") > -1) {
			return "dev-local";
		} else if (location.href.indexOf("raydevelop") > -1) {
			return "development";
		}
		return "production";
	}

	const getFeedbacks = async () => {
		try {
			const ret = await fetchData("rayteams-manager-contents", {
				sk: {
					ComparisonOperator: "BEGINS_WITH",
					AttributeValueList: ["mf:"],
				},
			});
			console.log(ret.Items);
			setFeedbacks(ret.Items.filter(x => x.sk.split(":").length === 2));
		} catch (e) {
		}
	}

	const getEmailTemplate = async () => {
		try {
			const ret = await fetchData("rayteams-manager-contents", {
				sk: {
					ComparisonOperator: "EQ",
					AttributeValueList: ["info"],
				},
				bid: {
					ComparisonOperator: "EQ",
					AttributeValueList: [getEnv() === "production" ? "75b045ee-3961-499a-bb32-18612d28b03c" : "8e5e6c8e-f136-4bba-b979-bcf0bef18221"],
				},
			});
			console.log(ret.Items);
			setEmailTemplates(ret.Items);
		} catch (e) {
		}
	}

	return <>
		{loading && <MLPanelLoadingOverlay position="absolute" opacity={0} />}
		<div className='p-4'>
			<div className="header align-items-stretch d-block" style={{ position: "initial" }}>
				<div className="d-flex w-100 justify-content-between align-items-center">
					<div className="d-flex align-items-center text-dark fw-bolder fs-3 pb-4 pt-2">{t("RAYTeams Product")}</div>
					<div className="d-flex align-items-center"></div>
				</div>
			</div>
			<div className=''>
				<div className="card mb-2">
					<form>
						<div className="card-body row">
							<h4>
								{t("Product Information")}
							</h4>
							<div className={"col-6"}>

								<div className="mb-5">
									<label className="form-label required">{t("Title")}</label>
									<input
										type="text"
										className="form-control form-control-solid"
										placeholder={t("Enter Product title")}
										value={data?.title}
										onChange={(e) => setData({ ...data, title: e.target.value })} />
								</div>
								<div className="mb-5">
									<label className="form-label required">{t("Buy Type")}</label>
									<MLComboSingle options={{
										value: data ? data?.buytype : "",
										list: BUYTYPE
									}} handleChanged={(e) => setData({ ...data, buytype: e.target.value })} css="w-100 me-2" />
								</div>
								{data && data?.buytype === "sub" && <>
									<div className="mb-5">
										<label className="form-label">{t("Sub Type")}</label>
										<MLComboSingle options={{
											value: data ? data?.subtype : "",
											list: SUBTYPE
										}} handleChanged={(e) => setData({ ...data, subtype: e.target.value })} css="w-100 me-2" />
									</div>
									<div className="mb-5">
										<label className="form-label">{t("Sub Period")}</label>
										<div className="d-flex" style={{ marginBottom: "0px !important" }}>
											<MLNumber options={{
												value: data?.subperiod && data?.subperiod.replace(/[a-z]/gi, "") || 0,
											}} handleChanged={(e) => setData({ ...data, subperiod: e.target.value })} css="w-25" />
											<MLComboSingle options={{
												value: _id ? (data?.subperiodtype ? data?.subperiodtype : data?.subperiod ? data?.subperiod.replace(/[1-9]/g, "") : "") : (data?.subperiodtype || ""),
												list: SUBPERIOD
											}} handleChanged={(e) => setData({ ...data, subperiodtype: e.target.value })} css="w-25 ms-2" />
										</div>
									</div>
								</>}
								<div className="mb-5">
									<label className="form-label">{t("Currency")}</label>
									<MLComboSingle options={{
										value: data?.punit || "USD",
										list: PRICEUNIT
									}} handleChanged={(e) => setData({ ...data, punit: e.target.value })} />
								</div>
								<div className="mb-5">
									<label className="form-label">{t("Default Price")}</label>
									<MLNumber options={{
										value: data?.defaultprice || 0
									}} handleChanged={(e) => setData({ ...data, defaultprice: e.target.value })} css="w-100" />
								</div>
								<div className="mb-5">
									<label className="form-label">{t("Original Price")}</label>
									<MLNumber options={{
										value: data?.originalprice || 0
									}} handleChanged={(e) => setData({ ...data, originalprice: e.target.value })} css="w-100" />
								</div>
								<div className="mb-5">
									<label className="form-label">{t("Real Price")}</label>
									<MLNumber options={{
										value: data?.realprice || 0
									}} handleChanged={(e) => setData({ ...data, realprice: e.target.value })} css="w-100" />
								</div>

								<div className="mb-12">
									<label className="form-label required">{t("Draft")}</label>
									<MLToggle
										options={{
											name: 'isdraft',
											value: data ? data?.isdraft : true,
											readonly: false,
										}}
										handleChanged={(e) => { console.log(e); console.log(data); setData({ ...data, isdraft: e.target.value }) }}
									/>
								</div>
							</div>
							<div className="col-6">
								<div className="mb-5 pt-15">
									<label className="form-label required">{t("Applications")}</label>
									<MLComboMulti options={{
										value: data ? data?.appitems : [],
										list: applications && applications.map(x => {
											return (
												{ value: x.licenseId, label: x.title }
											)
										}),
										showalloption: false
									}} handleChanged={(e) => setData({ ...data, appitems: e.target.value })} css="w-100 me-2" />
								</div>
								{data?.appitems && data?.appitems.map(x => {
									return <>
										<div className="mb-5">
											<RTProductAppInfo aid={x} icon={false} />
										</div>
									</>
								})}

								<div className="mb-5 pt-12">
									<label className='form-label'>Except Country</label>
									<MLComboMulti options={{
										value: data?.exceptCc ? data?.exceptCc : [],
										list: COUNTRIES.map(x => {
											return (
												{ value: x.countryCode, label: t(x.name) }
											)
										}),
										showalloption: false
									}} handleChanged={(e) => { setData({ ...data, exceptCc: e.target.value }); console.log(e.target.value); }} css="w-100 me-2" />
								</div>
								<div className="mb-5 pt-12">
									<label className='form-label'>{t("Email Templates")}</label>
									<h6 className='mt-2'>{t("구독 성공시")}</h6>
									<MLComboSingle options={{
										value: data?.emailTemplates?.subSuccess || "",
										list: [{ _id: "", title: "없음(사용 안함)" }, ...emailTemplates].map(x => {
											return ({ value: x._id, label: t(x.title) })
										}),
										showalloption: false
									}} handleChanged={(e) => { setData({ ...data, emailTemplates: { ...data?.emailTemplates, subSuccess: e.target.value } }); console.log(e.target.value); }} css="w-100 me-2" />

									<h6 className='mt-2'>{t("구독 연장시")}</h6>
									<MLComboSingle options={{
										value: data?.emailTemplates?.subRenew || "",
										list: [{ _id: "", title: "없음(사용 안함)" }, ...emailTemplates].map(x => {
											return ({ value: x._id, label: t(x.title) })
										}),
										showalloption: false
									}} handleChanged={(e) => { setData({ ...data, emailTemplates: { ...data?.emailTemplates, subRenew: e.target.value } }); console.log(e.target.value); }} css="w-100 me-2" />

									<h6 className='mt-2'>{t("구독 취소시")}</h6>
									<MLComboSingle options={{
										value: data?.emailTemplates?.subCancel || "",
										list: [{ _id: "", title: "없음(사용 안함)" }, ...emailTemplates].map(x => {
											return ({ value: x._id, label: t(x.title) })
										}),
										showalloption: false
									}} handleChanged={(e) => { setData({ ...data, emailTemplates: { ...data?.emailTemplates, subCancel: e.target.value } }); console.log(e.target.value); }} css="w-100 me-2" />
								</div>
								<div className="pt-12">
									<label className='form-label'>{t("Feedbacks")}</label>
									<h6>{t("구독 취소시")}</h6>
									<MLComboSingle options={{
										value: data?.fbcancel || "",
										list: [{ _id: "", title: "없음(사용 안함)" }, ...feedbacks].map(x => {
											return (
												{ value: x._id, label: t(x.title) }
											)
										}),
										showalloption: false
									}} handleChanged={(e) => { setData({ ...data, fbcancel: e.target.value }); console.log(e.target.value); }} css="w-100 me-2" />
								</div>
							</div>
						</div>
					</form>
					<div className="card-header">
						{/* <div className='card-title'>
					{_id ? "Edit " + data?.title + " RAYTeams Product information" : "Create a RAYTeams Product"}
					<small className='ms-2 text-gray-500'></small>
				</div> */}
						<div className="card-toolbar text-end">
							<MLButton options={{
								label: t("Cancel"),
								color: COLORS.DEFAULT,
								action: () => _id ? history.push("/RTProductInfo/" + _id) : history.push("/RTProduct"),
							}} css="me-2" />
							{!RTproduct?.isdraft &&
								<MLButton options={{
									label: _id ? t("Update") : t("Create"),
									color: COLORS.PRIMARY,
									action: () => _id ? updateProduct() : createProduct(),
									needValid: true
								}} valid={_id ?
									(!!(data.title) && !process) :
									(!!(data.title && data.buytype && data.appitems?.length > 0))} />
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	</>
}


// price:[PARTNER]/[branch]/[ray]:[COUNTRY?]/[hq]:[GROUP?]:[userid?]

// price:ray             //ray에서 제공하는 기본 가격 정보
// price:smedical:JP     //Sheep Medical이라는 partner에서 제공하는 일본 지역 가격
// price:ray:KR          //ray에서 제공하는 한국 가격
// price:ray:KR:GID      //ray에서 제공하는 한국내의 특정 Group의 가격
// price:ray-kr:KR:GID   //ray내의 한국 법인에서 제공하는 한국내의 특정 Group의 가격

const mapState = (state) => {
	const user = state.AuthReducer.user;
	const region = state.AuthReducer.region;
	const applications = state.LicenseReducer.applications;
	const RTproduct = state.RTProductReducer.RTproduct;
	const RTproducts = state.RTProductReducer.RTproducts;
	const allpartner = state.ProgramReducer.allpartner;
	return { region, user, applications, RTproduct, RTproducts, allpartner };
};

const mapDispatch = dispatch => ({
})

export default connect(mapState)(RTProductAdd);