import axios from 'axios';
import { useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import { MLPanelLoading, MLPanelNoData, MLPanelNormalConfirm } from '../common/MLPanel';
import { AppAction } from '../../redux/actions';
import MLTable from '../common/MLTable';
import { JWT } from '../../config';
import Chart from 'chart.js/auto';
import { Bar, Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { getRandomColors } from '../../libs/colors';

const RMChartDailyCall = ({ logs }) => {
	const dispatch = useDispatch();
	const [chartData, setChartData] = useState([]);
	const [loading, setLoading] = useState(true);
	const chartRef = useRef();
    useEffect(() => {
		getData();
	}, []);

    const getData = async () => {
		setLoading(true);
		try{
			const ret = await axios.get(AppAction.getAPI() + "/getlogs?act=D", JWT());
			if (ret.data.status === "success") {
				makeData(ret.data.data);
			}
		}catch(err){
			console.log(err);
		}
		setLoading(false);
    }

	const makeData = (list) => {
		const clist = {};
		for(var i = 13; i >= 0; i--){
			clist[moment().add(-1 * i, 'days').format("MM/DD")] = 0;
		}
		list.map(x => {
			const md = moment(x.sk.replace("D:", ""), "YYYYMMDD").format('MM/DD');
			if(clist[md]){
				clist[md] += x.called;
			}else{
				clist[md] = x.called;
			}
		});
		const colorSet = getRandomColors(1, 0.8);
		setChartData({
			labels: Object.keys(clist),
			datasets: [{
				data: Object.values(clist),
				barThickness: 28,
				backgroundColor: '#ffc700',
				backgroundColor: colorSet.map(x => x.opacityColor)
			}]
		})
	}

  	return <>
		{!loading && <div className='card'>
			{chartData && <>
			<div className='card-header'>
				<div className='card-title'>Daily Usages</div>
			</div>
			<div className='card-body'>
				<Bar 
					ref={chartRef}
					height={300}
					data={chartData} 
					plugins={[ChartDataLabels]}
					options={{
						responsive: true,
						maintainAspectRatio: false,
						cutout: 0,
						animation: { animateScale: true },
						plugins: { 
							legend: { display : false },
							datalabels: {
								formatter: function (value) {
								  return value == 0 ? "" : value;
								},
								display: true,
								color: "gray",
								anchor: 'end',
								align: 'start',
								font: {
								  weight: 'bold',
								  size: 13,
								},
							},
						},
						scales: {
							y: { ticks: { color: '#666060', beginAtZero: true } },
							x: { ticks: { color: '#666060', beginAtZero: true } },
						}
					}} />
			</div>
			</> }
		</div>}
		{loading && <MLPanelLoading />}
	</>
}

const mapState = (state) => {
    const logs = state.UsageReducer.logs;
	const dailylogs =  state.UsageReducer.dailylogs;
	return { logs, dailylogs };
};

const mapDispatch = (dispatch) => ({
});

export default connect(mapState, mapDispatch)(RMChartDailyCall);