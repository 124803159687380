import axios from "axios";
import { useParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { useEffect, useState } from "react";
import { history } from '../../history';
import { useTranslation } from 'react-i18next';
import { ProgramConstant } from "../../redux/reducers";
import CodeConfigPage from "../code/CodeConfigPage";
import ServiceConfigBasic from "../../components/serviceconfig/ServiceConfigBasic";
import ServiceConfigEmail from "../../components/serviceconfig/ServiceConfigEmail";
import ServiceConfigLang from "../../components/serviceconfig/ServiceConfigLang";
import ServiceConfigConsent from "../../components/serviceconfig/ServiceConfigConsent";
import ServiceConfigEmailTemplate from "../../components/serviceconfig/ServiceConfigEmailTemplate";

const getEnv = () => {
    if(location.href.indexOf("localhost") > -1 || location.href.indexOf("127.0.0.1") > -1){
        return "dev-local";
    }else if(location.href.indexOf("raydevelop") > -1){
        return "development";
    }
    return "production";
}

const SiteConfigPage = ({ UpdateApplication }) => {
    const [t] = useTranslation(['translation']);
	const dispatch = useDispatch();
    const { _tab } = useParams();
	const [tab, setTab] = useState(_tab || "info");
    const partnerKey = localStorage.getItem('partnerKey') || '';
    const [item, setItem] = useState({
        title : partnerKey ? partnerKey : "RAY",
        name : partnerKey ? partnerKey : "ray"
    });
    const [data, setData] = useState([]);
    const [err, setErr] = useState("");
    const [shownew, setShowNew] = useState(false);
    const [newitem, setNewItem] = useState({
        key : "",
        value : "",
    });

    useEffect(() => {
        if(_tab)
            setTab(_tab);
    }, [_tab]);

    useEffect(() => {
		dispatch({ type : ProgramConstant.SET_PAGE, page : { 
			code : "conf", 
			view : "summary", 
			title : "Service Configuration", 
			small : t("Service에 대한 설정"), 
			options : [
			]
		}})
        getConfig();
    }, []);

    const getConfigPath = () => {
        const now = new Date().getTime();
        if(getEnv() === "production"){
            return "https://ray-data.s3.ap-northeast-2.amazonaws.com/rayrnd/rayteams-service/ray/codeConfig.json?t=" + now;
        }else{
            return "https://ray-data-development.s3.ap-northeast-2.amazonaws.com/rayrnd/rayteams-service/ray/codeConfig.json?t=" + now;
        }
    }

    const getConfig = async () => {
        try{
            const conf = await axios.get(getConfigPath());
            setData(conf?.data || []);
        }catch{
            setData([]);
            setNoFile(true);
        }
    }

    const tabs = [
        { code  : 'info', label : 'Basic'},
        { code  : 'consent', label : 'User Consents'},
        { code: "emailtemp", label: "Email Templates" },
    ]

    return <div className='p-4'>
        <div className='card'>
            <div className='card-header py-0'>
                <ul className="nav nav-tabs nav-stretch border-top" role="tablist">
                    {tabs.map((x, idx) => <li key={'tab-' + idx} className="nav-item cursor-pointer nav-end" role="presentation" onClick={() => {setTab("info");}}>
                        <a onClick={() => history.push('/SiteConfigPage/' + x.code) }  className={"nav-link " + (tab === x.code ? 'active' : '')}><span className='nav-text'>{t(x.label)}</span></a>
                    </li>)}
                </ul>
            </div>
            {tab === "info" && <ServiceConfigBasic /> }
            {tab === "email" && <ServiceConfigEmail />}
            {tab === "emailtemp" && <ServiceConfigEmailTemplate />}
            {tab === "lang" && <ServiceConfigLang />}
            {tab === "links" && <></>}
            {tab === "consent" && <ServiceConfigConsent />}
            {tab === "code" && <CodeConfigPage />}
        </div>
    </div>
}

export default SiteConfigPage;
