import { Modal } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { BUTTONS, COLORS, INPUTS, SPAN } from '../constants/FromInputs';
import { MLButton } from '../components/common/MLButton';
import { MLForm } from '../components/common/MLForm';
import { LANGUAGE, RMSUBTYPES } from '../constants/RMConstants';
import { AuthConstant, getAPI, getHeader } from '../redux/reducers/AuthReducer';
import { v4 } from 'uuid';
import moment from 'moment';
import axios from 'axios';
import { BoardAction } from '../redux/actions';
import { useParams } from 'react-router-dom';
import RMContentInfoPaenl from '../components/board/RMContentInfoPaenl';
// import { Editor } from '@tinymce/tinymce-react';
import TinyEditor from "../components/common/TinyEditor";
import { MLComboSingle, MLDate, MLText, MLToggle } from '../components/common/MLInput';
import RMContentUpload from '../components/board/RMContentUpload';
import { history } from '../history';
import { CostExplorer } from 'aws-sdk';
import RMBoardInfoPaenl from '../components/board/RMBoardInfoPaenl';
import { idData, fetchData, insertData, updateData } from '../libs/db';
import RMBoardMenuComponents from '../components/board/RMBoardMenuComponents';
import { callDB } from '../libs/db';
import { useTranslation } from 'react-i18next';
import { RTAudit } from '../libs/audit';

const UserContentAddPage = ({ user, usercontents, callbackClose }) => {
    const [t] = useTranslation(['translation']);
    const dispatch = useDispatch();
    const [data, setData] = useState({ title: "" });
    const [content, setContent] = useState({});
    const [contentLangs, setContentLangs] = useState([]);
    const [contentLang, setContentLang] = useState({});
    const [contentLangShow, setContentLangShow] = useState(true);
    const [board, setBoard] = useState({});
    const [cinfo, setCInfo] = useState({});
    const [insertHTML, setInsertHTML] = useState("");
    const { _bid, _cid, _sk } = useParams();
    const usersub = localStorage.getItem("usersub");

    useEffect(() => {
        setData({ title: "" });
    }, []);

    useEffect(() => {
        if (_cid) {
            getCInfoData();
        }
        if (_cid && _sk) {
            getData();
        }
    }, [_cid]);

    useEffect(() => {
        getBoard();
    }, [_bid]);

    const getCInfoData = async () => {
        const ret = await callDB({
            type : "query",
            region : "ap-northeast-2",
            params : {
                TableName: "rayteams-manager-contents",
                KeyConditionExpression: "#_id = :_id",
                ExpressionAttributeNames: { "#_id": "_id" },
                ExpressionAttributeValues: { ":_id": _cid }
            },
            filter : {}
        })
        try {
            if (ret.length > 0) {
                const conLang = ret.filter(x => x.sk.indexOf("lang:") > -1);
                setContentLangs(conLang);
                setContentLang(conLang.length > 0 ? conLang[0] : {});
                setCInfo(ret.find(x => x.sk === "info"));
            } else {
                setCInfo({});
            }
        } catch (err) {
            console.log(err);
        }
    }


    const getData = async () => {
        const ret = await callDB({
            type : "query",
            region : "ap-northeast-2",
            params : {
                TableName: "rayteams-manager-contents",
                KeyConditionExpression: "#_id = :_id",
                ExpressionAttributeNames: { "#_id": "_id" },
                ExpressionAttributeValues: { ":_id": _cid }
            },
            filter : {}
        })

        try {
            if (ret.length > 0) {
                const con = ret.filter(x => x.sk === (_sk === "info" ? "info" : "lang:" + _sk))[0];
                setContent(con.isDel > 0 ? {
                    ...con,
                    title: con.isDel > 0 ? <strike>{t("삭제된 글입니다.")}</strike> : con.title,
                    content: con.isDel > 0 ? <p>{t("삭제된 글입니다.")}</p> : con.content,
                } : con);
                console.log(con);
                setData(con);
            } else {
                alert("Not exist");
            }
        } catch (err) {
            console.log(err);
        }
    }

    const getBoard = async () => {
        const ret = await callDB({
            type : "query",
            region : "ap-northeast-2",
            params : {
                TableName: "rayteams-manager-boards",
                KeyConditionExpression: "#_id = :_id and #sk = :sk",
                ExpressionAttributeNames: { "#_id": "_id", "#sk": "sk" },
                ExpressionAttributeValues: { ":_id": _bid, ":sk": "info" }
            },
            filter : {}
        });
        if (ret.length > 0) {
            console.log(ret[0]);
            setBoard(ret[0]);
        }
    }

    const edit = async () => {
        if (!usersub) {
            dispatch({ type: AuthConstant.LOGOUT });
            return;
        }
        if (!data.title.trim()) {
            return;
        }

        await callDB({
            type: "update",
            region : "ap-northeast-2",
            params: {
                TableName: "rayteams-manager-contents",
                Key: {
                    _id: _cid,
                    sk: _sk === "info" ? "info" : ("lang:" + (_sk ? _sk : data.sk))
                },
                UpdateExpression:
                    "SET #menu = :menu, #bid = :bid, #title = :title, #content = :content, #type = :type, #updated = :updated, #updator = :updator ",
                ExpressionAttributeNames: {
                    "#menu": "menu", 
                    "#bid": "bid",
                    "#title" : "title", 
                    "#type" : "type", 
                    "#content" : "content",
                    "#updated" : "updated",
                    "#updator" : "updator"
                },
                ExpressionAttributeValues: {
                    ":menu": data.menu || cinfo.menu || "",
                    ":bid": _bid,
                    ":title" : data.title,
                    ":type" : board.type,
                    ":content" : data.content || "",
                    ":updated" : new Date().getTime(),
                    ":updator" : usersub
                },
            }
        });
        if (_sk === "info") {
            const langs = await callDB({
                type : "query",
                region : "ap-northeast-2",
                params : {
                    TableName: "rayteams-manager-contents",
                    KeyConditionExpression: "#_id = :_id",
                    ExpressionAttributeNames: { "#_id": "_id" },
                    ExpressionAttributeValues: { ":_id": _cid }
                },
                filter : {}
            })
            for (let i = 0; i < langs.length; i++) {
                if (langs[i].sk !== "info") {
                    await callDB({
                        type: "update",
                        region : "ap-northeast-2",
                        params: {
                            TableName: "rayteams-manager-contents",
                            Key: {
                                _id: _cid,
                                sk: langs[i].sk
                            },
                            UpdateExpression:
                                "SET #menu = :menu, #type = :type",
                            ExpressionAttributeNames: {
                                "#menu": "menu", 
                                "#type": "type", 
                            },
                            ExpressionAttributeValues: {
                                ":menu": data.menu || cinfo.menu || "",
                                ":type": board.type,
                            },
                        }
                    })
                }
            }

            if(data?.israyteams){
                const bidList = await callDB({
                    type : "scan",
                    region : "ap-northeast-2",
                    params : {
                        TableName: "rayteams-manager-contents",
                        FilterExpression: "#bid = :bid and #israyteams = :israyteams",
                        ExpressionAttributeNames: { "#bid": "bid", "#israyteams" : "israyteams" },
                        ExpressionAttributeValues: { ":bid": _bid, ":israyteams" : true }
                    },
                    filter : {}
                })

                for (let i = 0; i < bidList.length; i++) {
                    await callDB({
                        type: "update",
                        region : "ap-northeast-2",
                        params: {
                            TableName: "rayteams-manager-contents",
                            Key: {
                                _id: bidList[i]._id,
                                sk: "info"
                            },
                            UpdateExpression:
                                "SET #israyteams = :israyteams, #type = :type, #updated = :updated, #updator = :updator ",
                            ExpressionAttributeNames: {
                                "#israyteams": "israyteams", 
                                "#type" : "type", 
                                "#updated" : "updated", 
                                "#updator" : "updator"
                            },
                            ExpressionAttributeValues: {
                                ":israyteams" : false,
                                ":type" : board.type,
                                ":updated" : new Date().getTime(),
                                ":updator" : usersub
                            },
                        }
                    });
                }

                await callDB({
                    type: "update",
                    region : "ap-northeast-2",
                    params: {
                        TableName: "rayteams-manager-contents",
                        Key: {
                            _id: _cid,
                            sk: "info"
                        },
                        UpdateExpression:
                            "SET #israyteams = :israyteams, #type = :type, #updated = :updated, #updator = :updator ",
                        ExpressionAttributeNames: {
                            "#israyteams": "israyteams", 
                            "#type": "type", 
                            "#updated" : "updated", 
                            "#updator" : "updator"
                        },
                        ExpressionAttributeValues: {
                            ":type": board.type, 
                            ":israyteams" : data.israyteams,
                            ":updated" : new Date().getTime(),
                            ":updator" : usersub
                        },
                    }
                });
            }
        }
        RTAudit({ lvl: 4, msg: 'Edit Boards'}, JSON.stringify({ subject: data }));
        history.push("/Content/" + _bid + "/" + _cid)
    }

    const getSK = () => {
        if (_cid && _sk) {
            return _sk;
        } else if (_cid) {
            return "lang:" + data.sk
        } else {
            return "info";
        }
    }
    const add = async () => {
        if (!usersub) {
            dispatch({ type: AuthConstant.LOGOUT });
            return;
        }
        if (!data.title.trim()) {
            return;
        }

        const contentId = _cid || v4();
        const putItems = {
            _id: contentId,
            sk: getSK(),
            type: board.type,
            menu: data.menu || cinfo.menu || "",
            bid: _bid,
            updated: new Date().getTime(),
            updator: usersub,
        }

        await callDB({
            type: "update",
            region : "ap-northeast-2",
            params: {
                TableName: "rayteams-manager-contents",
                Key: {
                    _id: contentId,
                    sk: getSK()
                },
                UpdateExpression:
                    "SET #title = :title, #content = :content, #type = :type, #menu = :menu, #bid = :bid, #updated = :updated, #updator = :updator",
                ExpressionAttributeNames: {
                    "#title": "title",
                    "#content": "content",
                    "#type": "type",
                    "#menu": "menu",
                    "#bid": "bid",
                    "#updated": "updated",
                    "#updator": "updator"
                },
                ExpressionAttributeValues: {
                    ":title": data.title,
                    ":content": data.content || "",
                    ":type": board.type,
                    ":menu": data.menu || cinfo.menu || "",
                    ":bid" : _bid,
                    ":updated": new Date().getTime(),
                    ":updator": usersub
                },
            }
        })
        RTAudit({ lvl: 4, msg: 'Add Boards'}, JSON.stringify({ subject: data }));
        history.push("/Content/" + _bid + "/" + contentId);
    }

    const uploadDone = (bucket, key) => {
        setInsertHTML("<div><img src='" + "https://" + bucket + ".s3.ap-northeast-2.amazonaws.com/" + key + "' /></div>");
    }

    return <div className='p-4'>
        <RMBoardInfoPaenl notab={true} />
        <div className='card mt-4'>
            <div className='card-body'>
                {_cid && <>
                    {_sk !== "info" && <>
                        <label class="form-label required">{t("Language")}<span></span></label>
                        {_sk && <div className='mb-4 fw-bolder'>{LANGUAGE.find(x => x.code === _sk).label}</div>}
                        {!_sk && <div className='mb-4'>
                            <MLComboSingle options={{
                                list: LANGUAGE.map(x => ({ value: x.code, label: t(x.label) })),
                                value: data.sk?.replace("lang:", ""),
                            }} handleChanged={(e) => setData({ ...data, sk: e.target.value })} />
                        </div>}
                    </>}
                </>}
                {!(_cid && _sk) && contentLang.sk && <div className='bg-light p-4 mb-2'>
                    <div className='d-flex justify-content-between align-items-center border-bottom mb-4y'>
                        <div>
                            <div className='mb-2'>
                                <span className='me-1' style={{ verticalAlign: "middle" }}>{t("등록된 언어별 글 ")}: </span>
                                {contentLangs.map(x => <span className={'badge me-1 ' + (contentLang.sk === x.sk ? "bg-success" : "bg-info")}
                                    style={{ cursor: "pointer" }} onClick={() => setContentLang(x)}>
                                    {LANGUAGE.find(l => l.code === x.sk.replace("lang:", ""))?.label}
                                </span>)}
                            </div>
                            <h3>
                                {contentLang.title}
                            </h3>
                        </div>
                        <MLButton options={{
                            label: contentLangShow ? t('접기') : t('펼치기'),
                            color: COLORS.SECONDARY,
                            action: () => setContentLangShow(!contentLangShow)
                        }} css="me-1" />
                    </div>
                    {contentLangShow && <div dangerouslySetInnerHTML={{ __html: contentLang.content }}></div>}
                </div>}
                {(_sk === "info" || !_cid) && <div className='bg-dark text-white p-4 mb-4'>
                    {t("해당 글에 대한 실제 사용자에게 노출되는 글을 담기 위한 글을 정의합니다. 사용자에게 노출되지 않습니다.")}
                </div>}
                {_cid && _sk === "info" && <MLToggle options={{
                        label: t("RAYTeams 게시"),
                        name: "israyteams",
                        value: data.israyteams || false,
                        required: true
                    }} handleChanged={(e) => setData({ ...data, israyteams: e.target.value })} css="mb-4" />
                }
                <MLText options={{
                    label: (_sk === "info" || !_cid) ? t("글의 Alias") : t('제목'),
                    name: 'title',
                    value: data.title,
                    required: true
                }} handleChanged={(e) => setData({ ...data, title: e.target.value })} css="mb-4" />
                {(_sk === "info" || !_cid) && <div className='mb-4'>
                    <RMBoardMenuComponents selected={data.menu} callback={(e) => setData({ ...data, menu: e })} isCombo={true} />
                </div>}
                <div className='mb-4'>
                    <RMContentUpload callback={uploadDone} />
                </div>
                <div className='mb-4'>
                    <label className="form-label">{(_sk === "info" || !_cid) ? "Description" : 'Content'}<span></span></label>
                    <TinyEditor
                        originContent={content?.content || ""}
                        callbackOnChange={(content) => setData(prev => ({ ...prev, content }))}
                        insertHTML={insertHTML}
                    />
                </div>
                <div className='text-end mt-4'>
                    <MLButton options={{
                        label: t('Cancel'),
                        action: () => _sk ?
                            history.push('/UserContent/' + _cid + "/" + _sk) :
                            history.push('/Board/' + _bid)
                    }} css="me-2" />
                    <MLButton options={{
                        label: _sk ? t('Update') : t("Add"),
                        color: COLORS.PRIMARY,
                        action: () => _cid ? edit() : add()
                    }} />
                </div>
            </div>

        </div>

    </div>
}

const mapState = (state) => {
    const user = state.AuthReducer.user;
    const region = state.AuthReducer.region;
    const board = state.BoardReducer.board;
    const content = state.BoardReducer.content;
    const usercontents = state.BoardReducer.usercontents;
    return { region, user, usercontents, board, content };
};

const mapDispatch = dispatch => ({
})

export default connect(mapState)(UserContentAddPage);
