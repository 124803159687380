import { connect, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { delData, fetchData, idData, updateData, callDB } from '../../libs/db';
import { history } from '../../history';
import { MdSubdirectoryArrowRight } from "react-icons/md";
import { useEffect, useState } from 'react';

const RMMenuComponent = ({ menusk, _bid }) => {

	const [menu, setMenu] = useState({});
	const [menus, setMenus] = useState([]);

	useEffect(() => {
        getData();
	}, [menusk]);

    const getData = async () => {
		if(!menusk){
			setMenu({ sk : "", title : "없음"})
		}else{
			try{
				const ret = await callDB({
					type : "scan",
					region : "ap-northeast-2",
					params : {
						TableName: "rayteams-manager-contents",
						FilterExpression: "#bid = :bid and begins_with(#sk, :sk)",
						ExpressionAttributeNames: { "#bid": "bid", "#sk": "sk" },
						ExpressionAttributeValues: { ":bid" : _bid, ":sk": "mn:" }
					},
					filter : {}
				});
				console.log(ret.filter(x => !x.lang));
				setMenus(ret.filter(x => !x.lang));
			}catch(err){
				console.log(err);
			}
		}
    }

	const getParent = () => {
		const ret = [<span className=''>ROOT </span>];
		const n = menusk.split(":");
		let item = "";
		console.log(n);
		for(let i = 0; i < n.length - 1; i++){
			ret.push(<span className='me-1'>{menus.find(x => x.sk === (item + n[i]))?.title} &gt; </span>);
			item += n[i] + ":";
		}
		return ret;
	}

  	return <span className="">
		{menusk && <>
			{getParent()}
			{menus.find(x => x.sk === menusk)?.title}
		</>}
		{!menusk && <>Root</>}
	</span>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const region =  state.AuthReducer.region;
	const board =  state.BoardReducer.board;
	const contents =  state.BoardReducer.contents;
	return { region, user, board, contents };
};

const mapDispatch = (dispatch) => ({
});

export default connect(mapState, mapDispatch)(RMMenuComponent);
