import axios from "axios";
import { connect, useDispatch } from 'react-redux';
import { useEffect, useState } from "react";
import { BUTTONS, COLORS, INPUTS, SPAN } from '../../constants/FromInputs';
import { MLButton } from '../../components/common/MLButton';
import { UploadS3 } from "../../libs/s3";
import { MLText } from "../../components/common/MLInput";
import { MLForm } from "../../components/common/MLForm";
import MLTable from "../../components/common/MLTable";
import { ProgramConstant } from "../../redux/reducers";
import { useTranslation } from 'react-i18next';
import { RTAudit } from '../../libs/audit';
const VIEW_TYPE = {
    DEFAULT: 'default',
    LANGKEYS: 'lang_keys',
    TRANS: 'trans',
    TRANSUSER : 'transuser'
};

const getEnv = () => {
    if(location.href.indexOf("localhost") > -1 || location.href.indexOf("127.0.0.1") > -1){
        return "dev-local";
    }else if(location.href.indexOf("raydevelop") > -1){
        return "development";
    }
    return "production";
}

const CodeConfigPage = ({ UpdateApplication }) => {
    const dispatch = useDispatch();
    const [t] = useTranslation(['translation']);
    const partnerKey = localStorage.getItem('partnerKey') || '';
    const [item, setItem] = useState({
        title : partnerKey ? partnerKey : "RAY",
        name : partnerKey ? partnerKey : "ray"
    });
    const [data, setData] = useState([]);
    const [err, setErr] = useState("");
    const [shownew, setShowNew] = useState(false);
    const [newitem, setNewItem] = useState({
        key : "",
        value : "",
    });
	const partner = process.env.REACT_APP_COMPANY || 'ray';

    useEffect(() => {
        getConfig();
    }, []);

    const getConfigPath = () => {
        const now = new Date().getTime();
        if(getEnv() === "production"){
            return "https://ray-data.s3.ap-northeast-2.amazonaws.com/rayrnd/rayteams-service/ray/codeConfig.json?t=" + now;
        }else{
            return "https://ray-data-development.s3.ap-northeast-2.amazonaws.com/rayrnd/rayteams-service/ray/codeConfig.json?t=" + now;
        }
    }

    const getConfig = async () => {
        try{
            const conf = await axios.get(getConfigPath());
            setData(conf?.data || []);
            dispatch({ type : ProgramConstant.SET_CONFIG, items : conf?.data });
        }catch{
            setData([]);
        }
    }

    const addNew = async () => {
        if(!newitem.value || !newitem.label || !newitem.category){
            setErr(t("모든 값을 입력해야합니다."));
            return;
        }
        const newUpData = [...data, newitem ]
        await UploadS3(
            getEnv() === "production" ?  "ray-data" : "ray-data-development",
            "rayrnd/rayteams-service/ray/codeConfig.json",
            newUpData
        );
        setNewItem({ value : '', label : '', category : '' })
        await getConfig();
        RTAudit({ lvl: 4, msg: 'Add Service Configuration Codes'});
    }

    const delKey = async (key) => {
        console.log(key);
        await UploadS3(
            getEnv() === "production" ?  "ray-data" : "ray-data-development",
            "rayrnd/rayteams-service/ray/codeConfig.json",
            data.filter(x => x.value !== key.value)
        );
        await getConfig();
    }

    const controls = [
		{
			type : INPUTS.COMBO,
			options : {
				label : 'Category',
				name : 'category',
				list : [...data.filter(x => x.category === 'CODE_CATEGORY'),
                    { value : 'CODE_CATEGORY', label : 'Code Category'},
                    { value : 'UPLOAD_TYPE', label : 'Upload file type'}
                ],
				required : true
			}
		},
		{
			type : INPUTS.TEXT,
			options : {
				label : 'Value',
				name : 'value',
				required : true
			}
		},
		{
			type : INPUTS.TEXT,
			options : {
				label : 'Label',
				name : 'label',
			}
		},
		{
			type : BUTTONS.BUTTONS, 
			options : { },
			buttons : [{
				type : BUTTONS.NORMALBUTTON, 
				options : { 
					label : t('Add Code'), 
					color : COLORS.PRIMARY,
					needValid : true,
                    action : () => addNew()
				}
			},{
				type : BUTTONS.NORMALBUTTON, 
				options : { 
					label : t('Cancel'), 
					color : COLORS.SECONDARY,
                    action : () => setShowNew(!shownew)
				}
			}]
		}
    ]

	const headers = [
		{ value : "value", label : t("Code"), css : "text-start" },
		{ value : "label", label : t("Label"), css : "text-start" },
        { value : "category", label : t("Code Category"), css : "text-start" },
        /*
        { label : "", code : "ptn-delsn", btncss : "btn-danger", css : "text-center", type : "BUTTON2", btnClick : async (item) => {
			await delKey(item);
		} },
        */
	]

    return <div className='p-4'>
        <div className="d-flex justify-content-between mb-2">
            <h3>Codes</h3>
            <div className="card-toolbar">
                {!shownew && <MLButton options={{
                    code: "ptn-codes",
                    label : t("Add a Code"),
                    color : COLORS.PRIMARY,
                    action : () => setShowNew(!shownew)
                }} />}
            </div>
        </div>
        {shownew && <MLForm title={t('Add a Code')} controls={controls} data={newitem} onChanged={(e) => { setNewItem(e) }} />}
        <MLTable
            options={{ filterCols : [ { col : 'category', val : 'ALL' } ] }}
            headers={headers} 
            items={data} />
    </div>
}

export default CodeConfigPage;
