import moment from 'moment';
import { useTranslation } from 'react-i18next';
import MLTable from '../../components/common/MLTable';
import { history } from '../../history';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { FaSave, FaEdit, FaWindowClose, FaCheckCircle, FaStopCircle } from 'react-icons/fa';
import { ProgramConstant } from '../../redux/reducers';
//import Clinic from '../components/clinic';
import Clinic from '../partner/clinic';
import { COLORS } from '../../config/color';
import { RTButton } from '../../libs/buttons';
import { fetchData } from '../../libs/db';
import { ApplicationAction } from '../../redux/actions';
import RMGroup from '../manager/RMGroup';

const CompApplicationVersions = ({ user, title, application, applicationVersions, allgroups }) => {

	const dispatch = useDispatch();

	const [t] = useTranslation(['translation']);
	const [ items, setItems ] = useState([]);
	const [ edit, setEdit ] = useState(false);
	const [ data, setData ] = useState({});
	const [ process, setProcess ] = useState(false);

	useEffect(() => {
	}, [application]);

	const update = async () => {
		setProcess(true);

		setEdit(false);
	}

    const headers = [
		{ value : "version", label : "Name", css : "text-start", css : "w-200px" },
        { value : "size", label : "Size", css : "text-center" },
        { value : "desc", label : "Description", css : "text-start" },
        { value : "link", label : "Download", css : "text-center" },
        { value : "created", label : "Created", css : "text-center", type : "DATESHORT" },
	]

	return <MLTable 
        headers={headers} 
        items={applicationVersions} 
        order={"created"} 
        noheader={true} />
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
    const applicationVersions = state.ApplicationReducer.applicationVersions;
	return { user, applicationVersions };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(CompApplicationVersions);
